import React from 'react';

import SvgInfoOutLine from '_utils/icons/announcement/InfoOutLine';
import WarningOutLine from '_utils/icons/announcement/WarningOutLine';
import SvgExclamationTriangle from '_utils/icons/announcement/ExclamationTriangle';
import Colors from '_containers/Theme/colors';

import {
  ServiceAnnouncementWrapper,
  IconContainer,
  Icon,
  ContentAnnouncement,
  TitleAnnouncement,
  BodyAnnouncement
} from './StyledServiceAnnouncement';

interface IServiceAnnouncementProps {
  fields: any;
}

export const ANNOUNCEMENT_TYPES = {
  Primary: 'Primary',
  Warning: 'Warning',
  Critical: 'Critical'
};

const ANNOUNCEMENT_DATA_TYPES = {
  Primary: {
    backgroundColor: Colors.blueTint15,
    icon: <SvgInfoOutLine />
  },
  Warning: {
    backgroundColor: Colors.annandaleOrangeTint15,
    icon: <WarningOutLine />
  },
  Critical: {
    backgroundColor: Colors.pottsPointRedTint15,
    icon: <SvgExclamationTriangle />
  }
};

const ServiceAnnouncement: React.FC<IServiceAnnouncementProps> = ({ fields }) => {
  const announcementType = fields?.data?.datasource?.type?.value;
  const announcementTitle = fields?.data?.datasource?.title?.value;
  const announcementDesc = fields?.data?.datasource?.description;

  return announcementType || announcementTitle || announcementDesc ? (
    <ServiceAnnouncementWrapper
      type={{
        backgroundColor: ANNOUNCEMENT_DATA_TYPES[announcementType]
          ? ANNOUNCEMENT_DATA_TYPES[announcementType]?.backgroundColor
          : ANNOUNCEMENT_DATA_TYPES['Primary']?.backgroundColor
      }}
    >
      <IconContainer>
        <Icon>
          {ANNOUNCEMENT_DATA_TYPES[announcementType]
            ? ANNOUNCEMENT_DATA_TYPES[announcementType]?.icon
            : ANNOUNCEMENT_DATA_TYPES['Primary']?.icon}
        </Icon>
      </IconContainer>
      <ContentAnnouncement>
        {announcementTitle && <TitleAnnouncement>{announcementTitle}</TitleAnnouncement>}
        {announcementDesc && <BodyAnnouncement field={announcementDesc} />}
      </ContentAnnouncement>
    </ServiceAnnouncementWrapper>
  ) : null;
};

export default ServiceAnnouncement;
