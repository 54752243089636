import React from 'react';
import {
  Container,
  Title,
  TimelineContainer,
  Line,
  TimelineStage,
  TimelineStageTime,
  TimelineStageTitle,
  TimelineStageContainer,
  TitleText,
  ResultLink,
  RichText
} from './StyledTimeline';
import { compose } from 'ramda';
import { TimelineIcon } from '_utils/components/Corporate/StatusIcon';
import { TimelineNotStarted } from '_utils/icons';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { TimelineProps, TimelineItem, timelineItem } from './definition';
import { Grid } from '_utils/components/Corporate';
import { withBannerCurve } from '_utils/components/Corporate/BannerCurve';
import { BackgroundColors } from '_utils/components/Corporate/Section';
import { useTranslation } from 'react-i18next';

export const Timeline: React.FC<TimelineProps> = ({ fields, sitecoreContext }) => {
  const [t] = useTranslation();
  const timelineTitle =
    fields?.data?.datasource?.status?.jss || sitecoreContext?.route?.fields?.status?.fields?.name;
  const timelineIcon =
    fields?.data?.datasource?.icon?.value ||
    sitecoreContext?.route?.fields?.status?.fields?.icon?.value;
  const timelineDescription =
    fields?.data?.datasource?.description?.jss || sitecoreContext?.route?.fields?.statusDescription;
  const timelines = fields?.data?.datasource?.timeline || [
    timelineItem(
      sitecoreContext?.route?.fields?.projectStartDate?.value,
      t('timeline-open-for-feedback')
    ),
    timelineItem(
      sitecoreContext?.route?.fields?.projectEndDate?.value,
      t('timeline-closed-for-feedback')
    )
  ];

  return (
    <Grid.Container>
      <Grid.Row config={{ gutters: [] }} style={{ flexDirection: "column" }}>
        <Container>
          <Title>
            <TimelineIcon status={timelineIcon} />
            <TitleText field={timelineTitle} tag={'h3'} />
          </Title>
          <RichText field={timelineDescription} />
          {timelines && timelines.length > 0 && (
            <TimelineContainer>
              {timelines.map((item: TimelineItem, idx) => (
                <TimelineStage key={idx}>
                  <TimelineStageContainer>
                    {item?.period?.jss?.value && (
                      <TimelineStageTime field={item.period.jss} tag={'p'} encode={false} />
                    )}
                  </TimelineStageContainer>
                  <TimelineNotStarted />
                  <TimelineStageTitle field={item?.title?.jss} tag={'h3'} />
                  {item?.link?.jss?.value?.href !== '' && <ResultLink field={item?.link?.jss} />}
                </TimelineStage>
              ))}
              {timelines && timelines.length === 1 ? '' : <Line numbers={timelines.length} />}
            </TimelineContainer>
          )}
        </Container>
      </Grid.Row>
    </Grid.Container>
  );
};

export default compose(
  withSitecoreContext(),
  withBannerCurve({
    backgroundColor: BackgroundColors.White,
    renderCurve:  ({ fields }) => {
      return fields?.data?.datasource?.status?.jss?.value !== null ? true : null;
    }
  })
)(Timeline);
