import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';

interface SessionExpiredPopupProps {
  silentSignInUrl: string;
  signOutUrl: string;
  sessionExpiredUrl: string;
}

const REFRESH_LOGIN_TIME = 29; // 29 minutes
const IDLE_TIME = 10; // 10 minutes
const DOCUMENT_EVENTS = ['click', 'scroll', 'mouseover', 'mousedown', 'keydown', 'touchstart'];

const SessionExpiredPopup: React.FC<SessionExpiredPopupProps> = ({
  silentSignInUrl,
  signOutUrl,
  sessionExpiredUrl
}) => {
  const location = useLocation();
  const [refreshLoginTime, setRefreshLoginTime] = useState(0);
  const [idleTime, setIdleTime] = useState(0);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const popupTimeoutRef = useRef(null);
  const currentURL = location.pathname + location.search + location.hash;

  useEffect(() => {
    const idleInterval = setInterval(idleTimeIncrement, 60000); // 1 minute
    const loginInterval = setInterval(refreshLoginTimeIncrement, 60000); // 1 minute
    // listen key and mouse events
    DOCUMENT_EVENTS.forEach((event) => document.addEventListener(event, resetIdleTime));

    return () => {
      // remove listener
      DOCUMENT_EVENTS.forEach((event) => document.removeEventListener(event, resetIdleTime));
      // remove interval
      clearInterval(idleInterval);
      clearInterval(loginInterval);
    };
  }, []);

  // trigger silent login
  useEffect(() => {
    if (refreshLoginTime === REFRESH_LOGIN_TIME) {
      if (idleTime >= IDLE_TIME) {
        setIsOpenPopup(true);
        // end session after 1 minute
        popupTimeoutRef.current = setTimeout(() => {
          redirectToSessionExpiredPage();
        }, 60000);
      } else {
        //silent login
        continueSession();
      }
    }
  }, [refreshLoginTime]);

  // reset idle time
  const resetIdleTime = () => {
    setIdleTime(0);
  };

  // increase idle time
  const idleTimeIncrement = () => {
    setIdleTime((prev) => prev + 1);
  };

  // increase refresh time
  const refreshLoginTimeIncrement = () => {
    setRefreshLoginTime((prev) => prev + 1);
  };

  // handle click end session
  const endSession = () => {
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }
    if (signOutUrl) {
      var cancelIframe = document.getElementById('cancel-iframe') as HTMLIFrameElement;
      cancelIframe.src = decodeURI(signOutUrl);
    }
  };

  // redirect user to session expired page
  const redirectToSessionExpiredPage = () => {
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }
    if (sessionExpiredUrl) {
      var expiredIframe = document.getElementById('expired-iframe') as HTMLIFrameElement;
      expiredIframe.src = decodeURI(sessionExpiredUrl + '?returnUrl=' + currentURL);
    }
  };

  // handle click continue session
  const continueSession = () => {
    setIsOpenPopup(false);
    setRefreshLoginTime(0);
    setIdleTime(0);
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }
    if (silentSignInUrl) {
      var actionIframe = document.getElementById('action-iframe') as HTMLIFrameElement;
      actionIframe.src = decodeURI(silentSignInUrl + '?returnUrl=' + currentURL);
    }
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <iframe id="cancel-iframe" src="" />
        <iframe id="action-iframe" src="" />
        <iframe id="expired-iframe" src="" />
      </div>
      <DialogueBox
        translationKeys={{
          title: 'page-header-with-login-extend-session-title',
          description: 'page-header-with-login-extend-session-description',
          cancel: 'page-header-with-login-extend-session-end-session',
          apply: 'page-header-with-login-extend-session-continue'
        }}
        type="warning"
        actionCallbackFn={continueSession}
        cancelCallbackFn={endSession}
        isFixed={true}
        isOpen={isOpenPopup}
      />
    </>
  );
};

export default SessionExpiredPopup;
