import React, { createContext, useState } from 'react';
import { Optionalize } from '_utils/types';
import { ChildrenPropTypes } from '_types';

export interface IUserProfile {
  userDisplayName?: string;
  userFullName?: string;
  email?: string;
  isAuthenticated: boolean;
  canTrust: boolean;
  silentSignInUrl?: string;
  signOutUrl?: string;
  sessionExpiredUrl?: string;
}

export interface UserProfileContextProps {
  userProfile: IUserProfile;
  setUserProfile: (data: IUserProfile) => void;
}

const USER_DATA_INIT = {
  userDisplayName: '',
  userFullName: '',
  email: '',
  isAuthenticated: false,
  canTrust: false, //true if data got from sitecoreContext after update route
  silentSignInUrl: '',
  signOutUrl: '',
  sessionExpiredUrl: ''
};

const UserProfileContext = createContext<UserProfileContextProps>({
  userProfile: USER_DATA_INIT,
  setUserProfile: null
});

function UserProfileProvider({ children }) {
  // state
  const [userProfile, setUserProfile] = useState<IUserProfile>(USER_DATA_INIT);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        setUserProfile
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}

UserProfileProvider.propTypes = {
  children: ChildrenPropTypes
};

export default UserProfileProvider;

export const UserProfileConsumer = UserProfileContext.Consumer;

export function withUserProfileContext<T extends UserProfileContextProps = UserProfileContextProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withUserProfileContext: React.FC<Optionalize<T, UserProfileContextProps>> = (props) => {
    return (
      <UserProfileContext.Consumer>
        {(value) => <WrappedComponent {...value} {...(props as T)} />}
      </UserProfileContext.Consumer>
    );
  };

  withUserProfileContext.displayName = `withUserProfileContext(${displayName})`;

  return withUserProfileContext;
}
