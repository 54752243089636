import React, { useEffect, useRef, useState } from 'react';
import RatesCalculation from './RatesCalculation';
import RatesInput from './RatesInput';
import {
  getRatesByAssessmentNumber,
  getRatesByPropertyNumber
} from '_services/ratesCalculatorService';

import { RatesCalculationSection, RatesInputSection } from './StyledRatesCalculator';

type RatesCalculatorProps = {
  rendering?: any;
  fields?: any;
};

const RatesCalculator: React.FC<RatesCalculatorProps> = ({ rendering, fields }) => {
  const [calculationData, setCalculationData] = useState(null);
  const [calculated, setCalculated] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [isCalculationSuccess, setIsCalculationSuccess] = useState<boolean>(false);
  const myRef = useRef(null);
  const showNextYearRates = fields?.data?.datasource?.showNextYearRates?.jss?.value || false;

  useEffect(() => {
    if (calculationData) myRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [calculationData]);

  // calculate by address
  const calculateByPropertyNumber = (propertyRatesId: string) => {
    setIsCalculationSuccess(false);
    setError(false);
    getRatesByPropertyNumber(propertyRatesId, showNextYearRates, calculateSuccess, calculateFailure);
  };
  // calculate by assessment number
  const calculateByAssessmentNumber = (assessmentNumber: string) => {
    setIsCalculationSuccess(false);
    setError(false);
    getRatesByAssessmentNumber(assessmentNumber, showNextYearRates, calculateSuccess, calculateFailure);
  };

  // calculate successfully
  const calculateSuccess = (response) => {
    setIsCalculationSuccess(true);
    setCalculationData(response.data);
  };

  // calculate unsuccessfully
  const calculateFailure = () => {
    setIsCalculationSuccess(true);
    setError(true);
  };
  // handle change option
  const handleChangeOption = () => {
    setCalculationData(null);
    setError(false);
  };

  const handleCalculate = (bol: boolean) => {
    setCalculated(bol);
  };

  const handleClear = () => {
    setCalculationData(null);
    setCalculated(false);
    setIsCalculationSuccess(false);
  };

  return (
    <>
      <RatesInputSection>
        <RatesInput
          isCalculationSuccess={isCalculationSuccess}
          isError={error}
          handleClear={handleClear}
          handleCalculate={handleCalculate}
          calculationData={calculationData}
          onChangeOption={handleChangeOption}
          calculateByPropertyNumber={calculateByPropertyNumber}
          calculateByAssessmentNumber={calculateByAssessmentNumber}
          setError={setError}
        />
      </RatesInputSection>
      {calculationData && calculated && (
        <RatesCalculationSection>
          <RatesCalculation
            ref={myRef}
            calculationData={calculationData}
            rendering={rendering}
            isShowNextYearRates={showNextYearRates}
          />
        </RatesCalculationSection>
      )}
    </>
  );
};

export default RatesCalculator;
