import styled, { css } from 'styled-components';
import {
  button,
  buttonReset,
  mq,
  safeFocusRemoval,
  depth,
  hideOnPrint,
  color,
  focusOutline
} from '_utils/styles';
import { spacing } from '_utils/props';

const OpenDropdownCSS = css`
  ${buttonReset};

  &:focus-visible {
    ${safeFocusRemoval};
    ${focusOutline};
  }

  padding: 0 4px;

  & svg {
    fill: ${color('all.deepBlue')};
  }
  ${hideOnPrint};
`;

const LoggedInCSS = css`
  ${buttonReset};
  ${safeFocusRemoval};
  padding: 0 4px;

  & svg {
    fill: ${color('all.deepBlue')};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }

  &:focus-visible {
    ${safeFocusRemoval}
    position: relative;
    outline-color: ${({ theme }) => theme.colors.element.focused};
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
  }
  ${hideOnPrint};
`;

export const UserButtonWrapper = styled.button`
  flex-shrink: 0;
  height: 40px;
  ${({ theme }) => spacing({ theme, mr: 2 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mr: '6px' })};
  }
  ${({ isOpenDropdown }) => (isOpenDropdown ? OpenDropdownCSS : LoggedInCSS)}
  height: 100%;
  display: flex;
  align-items: center;
  /* make user dropdown button can't clickable while menu is opening */
  z-index: ${({ isOpenDropdown }) =>
    isOpenDropdown ? depth.MENU_BUTTON + 2 : depth.MENU_BLOCK - 1};
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const UserNameLabel = styled.span`
  margin-left: 10px;
`;

export const CloseLabel = styled(UserNameLabel)`
  display: none;

  ${mq('lg')} {
    display: block;
  }
`;
