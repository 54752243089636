export function getConsultationSurveyUrl(surveyId, profileId) {
  return `/api/v1/account/consultations/survey?id=${surveyId}&profileId=${profileId}`;
}

export function getConsultationSocialPinpointUrl(socialpinpointId, profileId) {
  return `/api/v1/account/consultations/socialpinpoint?id=${socialpinpointId}&profileId=${profileId}`;
}

export function getNextPageUrl(nextPage, profileId) {
  // validate nextPage value
  if (!nextPage || nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
    return '/404';
  }

  const nextPageParts = nextPage.split('#');

  // add profile id right before # (if it exists)
  if (nextPageParts.length === 2) {
    return `${nextPageParts[0]}?profileId=${profileId}#${nextPageParts[1]}`;
  }

  // add profile id at the end
  return `${nextPage}?profileId=${profileId}`;
}
