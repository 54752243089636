import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { withEditMode } from '_containers/BaseComponent';
import { BackgroundColors } from '_utils/components/Corporate/Section';
import SingleColumnFrame from './SingleColumnFrame';
import { SingleColumnCurvedBorderSection } from './StyledSingleColumnCurvedBorder';

type SingleColumnCurvedBorderProps = {
  rendering?: any;
  params?: any;
  editMode?: boolean;
};

const SingleColumnCurvedBorder: React.FC<SingleColumnCurvedBorderProps> = ({
  rendering,
  params,
  editMode
}) => {
  // Default
  let backgroundColor = BackgroundColors.White;
  let isShowCurve = false;

  const isNarrow = Boolean(rendering?.params?.isNarrow !== '0' && rendering?.params?.isNarrow);

  const children = rendering?.placeholders?.['jss-single-column-curved'] || [];

  const isEmpty = !editMode && children.length === 0;

  try {
    backgroundColor = BackgroundColors[params.backgroundColour];
    isShowCurve = params.showCurvedBorder === '1' ? true : false;
  } catch {}

  return (
    <SingleColumnCurvedBorderSection
      className={isEmpty ? 'is-empty' : ''}
      isShowCurve={isShowCurve}
      sectionTheme={backgroundColor}
    >
      <SingleColumnFrame
        isNarrow={isNarrow}
        ChildComponent={<Placeholder name="jss-single-column-curved" rendering={rendering} />}
        showCurved={isShowCurve}
      />
    </SingleColumnCurvedBorderSection>
  );
};

export default withEditMode(SingleColumnCurvedBorder);
