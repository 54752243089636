import React, { useState, useEffect, SyntheticEvent } from 'react';
import { find, propEq, prop } from 'ramda';

import SvgChevronDown from '_utils/icons/PaymentsDueList/ChevronDown';
import Radio from '_utils/components/Corporate/Radio';
import ClickAwayListener from '_utils/components/CityConnect/ClickAwayListener';

import {
  PDL_SortContainer,
  PDL_SortButton,
  PDL_SortButtonIconLeft,
  PDL_SortButtonLabel,
  PDL_SortButtonIconRight,
  PDL_SortPanel,
  PDL_SortItem
} from './StyledPaymentsSort';

type PaymentsSortProps = {
  data: any;
  onChangeSort: (link: string) => void;
};

const PaymentsSort: React.FC<PaymentsSortProps> = ({ data, onChangeSort }) => {
  const [isOpenSortPanel, setIsOpenSortPanel] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState(data[0].value);

  useEffect(() => {
    if (selectedSortValue) {
      const selectedOption = find(propEq('value', selectedSortValue))(data);
      if (selectedOption?.link) {
        onChangeSort(selectedOption.link);
      }
    }
  }, [selectedSortValue]);

  // handle click on sort button
  const openSortPanel = (event: SyntheticEvent) => {
    setIsOpenSortPanel(true);
  };

  // handle click on sort option
  const selectOption = (value: string) => {
    setSelectedSortValue(value);
    setIsOpenSortPanel(false);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      openSortPanel(e);
    }
  };

  const handleSelectByEnter = (e, value) => {
    if (e.key === 'Enter') {
      selectOption(value);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpenSortPanel(false)}>
      <PDL_SortContainer>
        <PDL_SortButton
          onClick={openSortPanel}
          onKeyPress={(e) => handleEnter(e)}
          tabIndex={0}
          role='button'
          aria-haspopup='menu'
        >
          <PDL_SortButtonIconLeft>
            {prop('icon', find(propEq('value', selectedSortValue))(data))}
          </PDL_SortButtonIconLeft>
          <PDL_SortButtonLabel>
            {prop('label', find(propEq('value', selectedSortValue))(data))}
          </PDL_SortButtonLabel>
          <PDL_SortButtonIconRight>
            <SvgChevronDown />
          </PDL_SortButtonIconRight>
        </PDL_SortButton>
        <PDL_SortPanel showPanel={isOpenSortPanel}>
          {data.map((sortItem, index) => (
            <PDL_SortItem
              key={index}
              onClick={() => selectOption(sortItem.value)}
              onKeyPress={(e) => handleSelectByEnter(e, sortItem.value)}
            >
              <Radio
                id={'sort-item-' + index}
                name={`PDL_Sort-${index}`}
                value={sortItem.value}
                onChange={() => {}}
                checked={selectedSortValue === sortItem.value}
              >
                <div>{sortItem.label}</div>
              </Radio>
            </PDL_SortItem>
          ))}
        </PDL_SortPanel>
      </PDL_SortContainer>
    </ClickAwayListener>
  );
};

export default PaymentsSort;
