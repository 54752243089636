import React from 'react';

import { buildSearchUrl } from '_utils/helpers';

import { Container, Tag } from './StyledTagList';
import NavItem from '../NavItem';

type TagData = {
  displayName: {
    value: string;
  };
  id: string;
  friendlyName: string;
  parent: {
    id: string;
    name: string;
    identifier: {
      value: string;
    };
  };
};

type TagListProps = {
  tags: TagData[];
};

export const validTag = (tag) => {
  if (!tag) {
    return false;
  }

  const { displayName, id, friendlyName, parent } = tag;

  return Boolean(displayName?.value && id && friendlyName && parent?.id && parent?.identifier?.value);
};

const TagList: React.FC<TagListProps> = ({ tags }) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <Container>
      {tags.filter(validTag).map(
        ({
          displayName,
          id,
          friendlyName,
          parent: {
            identifier: { value: tagId }
          }
        }) => {
          return (
            <NavItem
              key={id}
              StyledLink={Tag}
              link={{
                url: buildSearchUrl({ page: 1, [tagId]: friendlyName }),
                type: 'internal'
              }}
            >
              {displayName?.value}
            </NavItem>
          );
        }
      )}
    </Container>
  );
};

export default TagList;
