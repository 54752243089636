import React from 'react';
import { withSvg } from '../Svg';

const SvgForm = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5001 10C17.2791 10 17.0671 10.0878 16.9108 10.2441C16.7545 10.4004 16.6667 10.6124 16.6667 10.8334V15.8334C16.6667 16.0544 16.579 16.2663 16.4227 16.4226C16.2664 16.5789 16.0544 16.6667 15.8334 16.6667H4.16675C3.94573 16.6667 3.73377 16.5789 3.57749 16.4226C3.42121 16.2663 3.33341 16.0544 3.33341 15.8334V4.1667C3.33341 3.94568 3.42121 3.73372 3.57749 3.57744C3.73377 3.42116 3.94573 3.33336 4.16675 3.33336H9.16675C9.38776 3.33336 9.59972 3.24557 9.756 3.08929C9.91228 2.93301 10.0001 2.72104 10.0001 2.50003C10.0001 2.27902 9.91228 2.06706 9.756 1.91077C9.59972 1.75449 9.38776 1.6667 9.16675 1.6667H4.16675C3.50371 1.6667 2.86782 1.93009 2.39898 2.39893C1.93014 2.86777 1.66675 3.50366 1.66675 4.1667V15.8334C1.66675 16.4964 1.93014 17.1323 2.39898 17.6011C2.86782 18.07 3.50371 18.3334 4.16675 18.3334H15.8334C16.4965 18.3334 17.1323 18.07 17.6012 17.6011C18.07 17.1323 18.3334 16.4964 18.3334 15.8334V10.8334C18.3334 10.6124 18.2456 10.4004 18.0893 10.2441C17.9331 10.0878 17.7211 10 17.5001 10ZM5.00008 10.6334V14.1667C5.00008 14.3877 5.08788 14.5997 5.24416 14.756C5.40044 14.9122 5.6124 15 5.83341 15H9.36675C9.47642 15.0007 9.58514 14.9796 9.68667 14.9382C9.7882 14.8967 9.88055 14.8356 9.95841 14.7584L15.7251 8.98336L18.0917 6.6667C18.1699 6.58923 18.2319 6.49706 18.2742 6.39551C18.3165 6.29396 18.3382 6.18504 18.3382 6.07503C18.3382 5.96502 18.3165 5.8561 18.2742 5.75455C18.2319 5.653 18.1699 5.56083 18.0917 5.48336L14.5584 1.90836C14.4809 1.83026 14.3888 1.76826 14.2872 1.72595C14.1857 1.68365 14.0768 1.66187 13.9667 1.66187C13.8567 1.66187 13.7478 1.68365 13.6463 1.72595C13.5447 1.76826 13.4526 1.83026 13.3751 1.90836L11.0251 4.2667L5.24175 10.0417C5.16451 10.1196 5.10341 10.2119 5.06194 10.3134C5.02047 10.415 4.99945 10.5237 5.00008 10.6334V10.6334ZM13.9667 3.67503L16.3251 6.03336L15.1417 7.2167L12.7834 4.85836L13.9667 3.67503ZM6.66675 10.975L11.6084 6.03336L13.9667 8.3917L9.02508 13.3334H6.66675V10.975Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgForm);
