import React from 'react';
import { compose } from 'ramda';
import { convertSrcJss, createSrcSet } from '../../../media';

import { withEditMode } from '_containers/BaseComponent';

import { ImageWrapper, Image, EditorImage } from './StyledListingImage';
import { ListingImageProps } from './definitions';

const ListingImage: React.FC<ListingImageProps> = ({
  image,
  imageType,
  imageAspect = '',
  editMode
}) => {
  let imageComponent;

  if (editMode) {
    imageComponent = <EditorImage field={image} />;
  } else {
    imageComponent = (
      <Image
        data-src={convertSrcJss(image.value.src) + '?mw=640'}
        data-srcset={createSrcSet(image.value.src, [640, 1200, 1600])}
        className={`lazyload ${imageAspect}`}
        alt={image?.value?.alt}
      />
    );
  }

  return <ImageWrapper imageType={imageType}>{imageComponent}</ImageWrapper>;
};

ListingImage.defaultProps = {
  imageType: 'simple'
};

export default compose(withEditMode)(ListingImage);
