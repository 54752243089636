import styled from 'styled-components';
import theme from '_containers/Theme/theme';
import { heading_l, mq, depth, hideOnPrint } from '_utils/styles';

export const Wrapper = styled.div`
  background-color: ${theme.colors.element.primary};
  position: fixed;
  z-index: ${depth.QUICK_CLOSE};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 24px 0;
  ${mq('md')} {
    padding: 22px 0;
  }
  ${hideOnPrint}
`;

export const Link = styled.a`
  color: #fff;
  ${() => heading_l}

  &:hover {
    text-decoration: none;
  }
`;
