import React, { useState, useEffect } from 'react';
import { compose } from 'ramda';
import { useTimer } from '_utils/hooks';
import { useTranslation } from 'react-i18next';

import UserIcon from '_utils/icons/User';
import CloseIcon from '_utils/icons/Close';
import { withMenuContext, MenuContextProps } from '_containers/MenuContext';
import { withUserDropdownContext, UserDropdownContextProps } from '_containers/UserDropdownContext';
// import style
import { UserButtonWrapper, IconContainer, CloseLabel, UserNameLabel } from './StyledUserButton';

export interface UserButtonProps extends MenuContextProps, UserDropdownContextProps {
  userDisplayName: string;
  isAuthenticated: boolean;
}

const TRANSITION_DURATION = 200;

const UserButton: React.FC<UserButtonProps> = ({
  userDisplayName,
  isAuthenticated,
  menuActive,
  setMenuActive,
  userDropdownActive,
  setUserDropdownActive
}) => {
  const [t] = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const { setTimer, cancelTimer } = useTimer();

  // clean up
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cancelTimer(), []);

  const handleClickUserButton = () => {
    if (!enabled) {
      return;
    }

    setUserDropdownActive(!userDropdownActive);

    if (menuActive) {
      setMenuActive(false);
    }

    setEnabled(false);

    setTimer(() => {
      setEnabled(true);
    }, TRANSITION_DURATION + 10);
  };

  const handleFocusUserButton = () => {
    if (!enabled) {
      return;
    }
    if (userDropdownActive) {
      setEnabled(false);
      setUserDropdownActive(false);
    }

    setTimer(() => {
      setEnabled(true);
    }, TRANSITION_DURATION + 10);
  };

  return (
    <UserButtonWrapper
      isLogged={isAuthenticated}
      isOpenDropdown={userDropdownActive}
      onClick={handleClickUserButton}
      onFocus={handleFocusUserButton}
      id="user-button"
      aria-expanded={isAuthenticated}
      aria-labelledby="user-button-label"
    >
      <IconContainer>{userDropdownActive ? <CloseIcon /> : <UserIcon />}</IconContainer>
      {userDropdownActive ? (
        <CloseLabel> {t('menu-close-label')} </CloseLabel>
      ) : isAuthenticated ? (
        <UserNameLabel>{userDisplayName}</UserNameLabel>
      ) : (
        <UserNameLabel>{t('page-header-with-login-log-in-or-register')}</UserNameLabel>
      )}
    </UserButtonWrapper>
  );
};

export default compose(withMenuContext, withUserDropdownContext)(UserButton);
