import getTypeFromValue from '_utils/helpers/getTypeFromValue';

// note! enum values match keys sent by server
export enum ProfileType {
    email = 'email',
    suburb = 'suburb',
    relationships = 'relationships',
    gender = 'gender',
    birthYear = 'birthYear',
    languageSpokenAtHome = 'languageSpokenAtHome',
    organisationName = 'organisationName',
    organisationABNorACN = 'organisationABNorACN',
    organisationType = 'organisationType',
    role = 'role',
    phone = 'phone',
    address = 'address'
}

/**
 * @param target either a string representation of a content type or an object containing a content type
 * @param key optional used to retrieve contentType value (if passing an object)
 */
export const getProfileType = (value: string | object, key: string = 'type'): ProfileType => {
    return getTypeFromValue<ProfileType>(ProfileType, value, key);
};