import React from 'react';
import i18n from 'i18next';
import { Helmet } from 'react-helmet';
import {
  isExperienceEditorActive,
  dataApi,
  withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import {
  ComponentConsumerProps,
  WithSitecoreContextHocProps
} from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext';
// import { RouteComponentProps } from 'react-router';
import { dataFetcher } from './dataFetcher';
import config from './temp/config';
import LayoutFactory from './LayoutFactory';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

export interface RouteHandlerProps extends ComponentConsumerProps {
  route: any; // Route<CustomRouteProps>;
  ssrRenderComplete: boolean;
  setSsrRenderComplete: (ssrRenderComplete: any) => void;
}

type RouteHandlerState = {
  notFound: boolean;
  defaultLanguage: string;
  loading: boolean;
};

class RouteHandler extends React.Component<RouteHandlerProps, RouteHandlerState> {
  public state: RouteHandlerState = {
    notFound: true,
    defaultLanguage: config.defaultLanguage || 'en',
    loading: false
  };

  private componentIsMounted: Readonly<boolean> = false;
  private languageIsChanging: Readonly<boolean> = false;

  constructor(props: RouteHandlerProps) {
    super(props);

    const routeData = this.extractRouteData();

    // route data from react-router - if route was resolved, it's not a 404
    if (routeData !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.

    if (routeData && routeData.sitecore.context && routeData.sitecore.context.language) {
      this.state.defaultLanguage = routeData.sitecore.context.language;
    }

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    const routeData = this.extractRouteData();

    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData || this.props.ssrRenderComplete) {
      this.updateRouteData();
    }

    // once we initialize the route handler, we've "used up" the SSR data,
    // if it existed, so we want to clear it now that it's in react state.
    // future route changes that might destroy/remount this component should ignore any SSR data.
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
    // (once to find GraphQL queries that need to run, the second time to refresh the view with
    // GraphQL query results)
    // We test for SSR by checking for Node-specific process.env variable.
    if (
      typeof window !== 'undefined' &&
      !this.props.ssrRenderComplete &&
      this.props.setSsrRenderComplete
    ) {
      this.props.setSsrRenderComplete(true);
    }

    this.componentIsMounted = true;
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  extractRouteData = () => {
    if (!this.props.sitecoreContext) return null;

    const { route, ...context } = this.props.sitecoreContext;

    return {
      sitecore: {
        route,
        context
      }
    };
  };

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    if (!(sitecoreRoutePath.substr(0, 1) === '/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const language = this.props.route.match.params.lang || this.state.defaultLanguage;

    this.setState(
      {
        loading: true
      },
      () => {
        // get the route data for the new route
        getRouteData(sitecoreRoutePath, language).then((routeData) => {
          if (routeData) {
            if (routeData.sitecore && routeData.sitecore.route) {
              // set the sitecore context data and push the new route
              if (this.props.updateSitecoreContext) {
                this.props.updateSitecoreContext({
                  route: routeData.sitecore.route,
                  itemId: routeData.sitecore.route.itemId,
                  ...routeData.sitecore.context
                });
              }
              this.setState({ notFound: false, loading: false });
            } else if (routeData.status) {
              // render the 404 or 401 page
              const statusCode = parseInt(routeData.status);
              if (statusCode === 401 || statusCode === 404) {
                getRouteData(`/${statusCode}`, language).then((routeData) => {
                  this.setState({ notFound: false, loading: false }, () => {
                    if (this.props.updateSitecoreContext) {
                      this.props.updateSitecoreContext({
                        route: routeData.sitecore.route,
                        itemId: routeData.sitecore.route.itemId,
                        ...routeData.sitecore.context
                      });
                    }
                  });
                });
              }
            }
          }
        });
      }
    );
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.props.route.match.params.lang || this.state.defaultLanguage;

    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true;

      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isExperienceEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
  }

  render() {
    const { notFound, loading } = this.state;
    const routeData = this.extractRouteData();
    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering
    // know how to send 404 status codes).

    // Should never really get here since updateRouteHandler has been modified to request the
    // server 404 layout instead of setting notFound = true and rendering 'NotFound' component
    // Left this in because notFound can still be set in the constructor (for SSR)?

    if (notFound && routeData) {
      return (
        <>
          <Helmet>
            <title>{i18n.t('Page not found')}</title>
          </Helmet>
          <h1>Page not found</h1>
        </>
      );
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData || this.languageIsChanging) {
      return null;
    }

    // Don't render anything if loading state is true.
    if (loading) {
      return null;
    }

    // Don't render anything if the sitecoreContext haven't updated yet.
    if(!this.props.sitecoreContext.pageEditing){
      const sitecoreRoute = new URL(this.props.sitecoreContext.canonicalUrl);
      if (sitecoreRoute.pathname !== "/404" && this.props.route.match.url !== sitecoreRoute.pathname) {
        return null;
      }
    }

    // Get layout to render for route
    const Layout = LayoutFactory.resolveLayout(routeData.sitecore.route);

    if (!Layout) {
      return (
        <p>
          Could not find layout, routeData.sitecore.route:
          <br />
          <pre>{JSON.stringify(routeData.sitecore.route, null, 2)}</pre>
        </p>
      );
    }

    // Render the app's root structural layout
    return (
      <Layout
        context={routeData.sitecore.context}
        route={routeData.sitecore.route}
        loading={loading}
      />
    );
  }
}

// FIXME workaround sitecore bug https://github.com/Sitecore/jss/pull/347
const RouteHandlerWithSitecoreContext: any = withSitecoreContext({ updatable: true })(RouteHandler);

export default RouteHandlerWithSitecoreContext;

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
  const querystringParams = {
    sc_lang: language,
    sc_apikey: config.sitecoreApiKey
  };

  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: querystringParams,
    fetcher: dataFetcher
  };

  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      return {
        ...error.response.data,
        status: error.response.status
      };
    }

    console.error('Route data fetch error', error, error.response);

    return null;
  });
}