import { BackgroundColors } from '_utils/components/Corporate/Section';
import {
  color,
  focusOutline,
  heading_xs,
  link,
  mq,
  safeFocusRemoval,
  svg,
  text_s
} from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';
import { handleSectionTheme } from '_utils/styles/section';
import { NavLink } from 'react-router-dom';

// INFO: Card Wrapper, Content and Footer
export const PaymentsDueCardWrapper = styled.div`
  width: 100%;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  overflow: hidden;
`;

export const CardSection = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => spacing({ theme, p: 3 })};
  ${() => handleSectionTheme(BackgroundColors['Light beige'])};
`;

export const CardFooter = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: 1px solid ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, px: 3, pt: 3, pb: 0 })};
  ${() => handleSectionTheme('white')};

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const CardSectionHeader = styled.div`
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mb: 3 })};
  }
`;

// INFO: Payment Card Title
export const TitleWrapper = styled.div`
  color: inherit;
  display: inline-block;

  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }
  &:hover {
    text-decoration: none;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    font-family: Helvetica Now Display;
    ${({ theme }) => spacing({ theme, m: 0 })};

    ${mq('md')} {
      font-size: 32px;
      line-height: 36px;
    }
  }
`;

// INFO: Payment Due Count
export const DueCount = styled(NavLink)`
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid ${color('all.green')};
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0 })};
  text-decoration: none;
  color: ${color('all.deepBlue')};

  &:visited {
    color: ${color('all.darlinghurstPurple')};
  }

  &:hover {
    border: 0;
    background: ${color('all.green')};
    color: ${color('all.deepBlue')};
  }

  &:focus {
    border: 0;
    background: ${color('all.green')};
    color: ${color('all.white')};
    ${safeFocusRemoval}
    ${focusOutline}
  }
`;

// INFO: All styles of Payment Due Items (Date, Reference Text and Amount)
export const ListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItems = styled.div`
  ${({ theme }) => spacing({ theme, pt: 2, pb: 1 })};

  &:not(:last-child) {
    border-bottom: 1px dashed #dddee1;
  }

  &:first-child {
    ${({ theme }) => spacing({ theme, pt: 0, pb: 1 })};
  }
`;

export const PaymentDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: none;
  ${({ theme }) => spacing({ theme, mb: 1 })};

  ${mq('lg')} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 4px;
  }
`;

// INFO: All styles of text in Payment due block
const DateTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mr: 2 })};

  ${mq('md')} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
  }
`;

const ReferenceText = styled.div`
  font-weight: bold;
  font-family: Helvetica Now Micro;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${color('all.deepBlueTint75')};
  margin-bottom: 4px;
`;

export const Link = styled.a`
  ${link};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${color('all.deepBlue')};

  ${mq('md')} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const DescriptionLink = styled(NavLink)`
  text-decoration: none;
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: ${color('all.deepBlue')};

  ${mq('md')} {
    font-size: 16px;
    line-height: 22px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${color('all.deepBlue')};
  }
  &:focus {
    text-decoration: underline;
  }
`;

export const PaymentAmountWrapper = styled.div`
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => spacing({ theme, mt: 1 })};

  ${mq('lg')} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }
`;

const Amount = styled.div`
  max-width: 100%;
  word-break: break-word;
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${color('all.deepBlue')};
  float: right;
  font-family: 'Helvetica Now Display';
  ${({ theme }) => spacing({ theme, mr: 2, mt: 0 })};

  ${mq('md')} {
    font-size: 24px;
    line-height: 28px;
  }

  ${mq('lg')} {
    min-width: 143px;
    max-width: 160px;
    ${({ theme }) => spacing({ theme, mr: 0 })};
    margin-top: 20px;
  }
`;

export const PaymentButton = styled.a`
  ${heading_xs()}
  display: flex !important;
  align-items: center;
  width: fit-content;
  padding: 6px 16px !important;
  border: none !important;

  ${mq.lessThan('md')} {
    line-height: 18px !important;
    padding: 7px 16px !important;
  }

  ${mq('lg')} {
    margin-top: 16px !important;
  }

  &:focus {
    outline: none;
    background: #005a0b;
  }

  &:focus:before {
    left: -4px !important;
    top: -4px !important;
    width: calc(100% + 8px) !important;
    height: calc(100% + 8px) !important;
  }
`;

// Normal text of this card (like No content... or Loading...)
const Normal = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${color('all.deepBlueTint75')};
`;

export const PaymentText = {
  DateTitle,
  ReferenceText,
  Link,
  Description,
  DescriptionLink,
  Amount,
  Normal
};

// INFO: Payment Quick Link styles
export const QuickLinkWrapper = styled.div`
  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => spacing({ theme, mr: 2, mb: 3 })};

  &:hover,
  & a:focus-visible {
    svg {
      path {
        fill: #188838;
      }
    }
  }

  & a {
    display: flex;
  }
`;

export const SvgWrapper = styled.span`
  display: ${({ isInLine }) => (isInLine ? 'flex' : 'inline')};
  text-align: center;
  align-items: center;
  ${({ theme }) => spacing({ theme, ml: 1 })};
  ${svg(20, 20)}
  width: 20px;
  height: 20px;
  path {
    fill: ${({ isInLine }) => (isInLine ? '#4E5A5F' : '#FFF')};
  }
  ${mq.lessThan('md')} {
    width: ${({ isSlim }) => (isSlim ? '20px' : '18px')};
    height: ${({ isSlim }) => (isSlim ? '20px' : '18px')};
  }
`;

export const QuickLink = styled(NavLink)`
  cursor: pointer;
  margin-left: 12px;
  font-size: 14px;
  line-height: 18px;
  color: ${color('all.deepBlue')};
  text-decoration-line: underline;

  &:hover {
    color: ${color('all.greenTint110')} !important;
  }
  &:visited {
    color: ${color('all.darlinghurstPurple')};
  }
  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 0;
    color: ${color('all.greenTint110')};
    ${safeFocusRemoval}
    ${focusOutline}
  }

  ${mq('md')} {
    font-size: 16px;
    line-height: 22px;
  }

  svg {
    margin: 0;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshWrapper = styled.span`
  margin-left: 8px;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.card.action};
  }
  svg {
    ${RotateCss};
    overflow: visible;
    ${mq('md')} {
      width: 24px;
    }
  }
`;
