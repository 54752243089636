import styled from 'styled-components';
import { spacing } from '_utils/props';

// INFO: Tab wrapper styling
export const RegistrationsTabWrapper = styled.div`
  padding: 0;
`;

export const ShowMoreContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const ContentSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const UnAvailableSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;