import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import theme from '_containers/Theme/theme';
import { spacing } from '_utils/props';
import { buttonColors, heading_xs, mq } from '_utils/styles/';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${spacing({ theme, mt: 2, ml: -1 })}
`;

export const Tag = styled(NavLink)`
  ${heading_xs}
  ${buttonColors(theme.colors.button.tag)};

  ${spacing({ theme, px: 2, py: '5px', m: '4px' })}

  ${mq('md')} {
    ${spacing({ theme, px: 2, py: '4px', m: '4px' })}
  }

  outline: 0 !important;
  font-weight: bold;
  border-radius: 4px;
  text-decoration: none;
  position: relative;

  /* psuedo focus outline */
  body:not(.safe-focus-removal) &:focus {
    &:before {
      position: absolute;
      left: -6px;
      top: -6px;
      content: '';
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 8px;
    }
  }

  @media print {
    width: 100%;
    margin: 0px;
    padding-left: 10px;
    :before {
      content: '•';
      padding-right: 10px;
    }
  }
`;
