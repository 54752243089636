import React from 'react';
import { withSvg } from '../Svg';

const SvgDeclined = () => (
  <path
    d="M10 1.66667C8.35185 1.66667 6.74068 2.15541 5.37027 3.07109C3.99986 3.98677 2.93176 5.28826 2.30103 6.81098C1.6703 8.33369 1.50527 10.0092 1.82681 11.6258C2.14836 13.2423 2.94203 14.7271 4.10747 15.8926C5.27291 17.058 6.75776 17.8517 8.37427 18.1732C9.99078 18.4948 11.6663 18.3297 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.9289 14.6298C17.8446 13.2593 18.3334 11.6482 18.3334 10C18.3334 8.90566 18.1178 7.82202 17.699 6.81098C17.2802 5.79993 16.6664 4.88127 15.8926 4.10745C15.1188 3.33363 14.2001 2.7198 13.1891 2.30101C12.178 1.88222 11.0944 1.66667 10 1.66667ZM10 16.6667C8.23191 16.6667 6.53622 15.9643 5.28598 14.7141C4.03574 13.4638 3.33336 11.7681 3.33336 10C3.33152 8.51946 3.82754 7.08129 4.74169 5.91667L14.0834 15.2583C12.9187 16.1725 11.4806 16.6685 10 16.6667ZM15.2584 14.0833L5.91669 4.74167C7.08131 3.82752 8.51948 3.3315 10 3.33334C11.7681 3.33334 13.4638 4.03572 14.7141 5.28596C15.9643 6.5362 16.6667 8.2319 16.6667 10C16.6685 11.4806 16.1725 12.9187 15.2584 14.0833Z"
    fill="#B00020"
  />
);

export default withSvg(20, 20)(SvgDeclined);
