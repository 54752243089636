import styled, { css } from 'styled-components';
import {
  button,
  color,
  heading_l,
  heading_m,
  heading_s,
  heading_xs,
  mq,
  safeFocusRemoval,
  svg,
  text_m
} from '_utils/styles';
import { BackgroundColors } from '_utils/components/Corporate/Section';
import { spacing } from '_utils/props';

export const PermitWrapper = styled.div`
  background-color: ${BackgroundColors.White};
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;

  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 4 })};
  }

  .permit-detail-card-document-name {
    color: inherit;

    &:hover {
      color: ${color('all.green')};
    }
  }
`;

const StyledDot = css`
  &:after {
    content: '.';
    font-weight: bold;
    position: absolute;
    top: -4px;
    left: -8px;
  }
`;

// INFO: Card header

export const PermitHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${({ theme }) => spacing({ theme, p: 3 })};
  background-color: ${color('all.deepBlueTint03')};

  ${mq('md')} {
    flex-direction: row;
  }

  .permit-detail-status-tag {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

export const PermitHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => spacing({ theme, mb: 3 })};

  ${mq('md')} {
    flex-grow: 1;
    ${({ theme }) => spacing({ theme, mb: 0, pr: 1 })};
  }
`;

export const PermitHeaderRight = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('md')} {
    align-items: flex-end;
    ${({ theme }) => spacing({ theme, pl: 1 })};
  }
`;

export const PermitTitle = styled.h3`
  ${heading_m}
  ${({ theme }) => spacing({ theme, mt: 0 })};
`;

export const PermitDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;

  & > .permit-detail-metadata {
    ${({ theme }) => spacing({ theme, mt: 1 })};
  }

  & > .permit-detail-metadata:not(:first-child) {
    position: relative;

    ${mq('sm')} {
      ${StyledDot}
    }
  }

  ${mq('sm')} {
    flex-direction: row;

    & > .permit-detail-metadata {
      margin-right: 16px;
    }
  }
`;

export const PermitUpdatedTime = styled.div`
  font-family: Helvetica Now Micro;
  font-size: 10px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

// INFO: Card content
export const PermitContent = styled.div`
  ${({ theme }) => spacing({ theme, p: 3 })};

  & > *:first-child {
    padding-top: 0;
  }

  & > *:last-child {
    padding-bottom: 0;
  }
`;

export const ActivityDescription = styled.div`
  ${heading_l}
  font-weight: normal;
  border-bottom: 1px solid ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, pb: 3 })};

  &:last-child {
    border-bottom: none;
  }
`;

export const WhatYouNeedToDo = styled.div`
  ${({ theme }) => spacing({ theme, pt: 3 })};

  .request-tracker-card-document-name {
    color: ${color('all.deepBlue')};
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const WhatYouNeedToDoTitle = styled.div`
  ${heading_s}
  ${({ theme, haveDescription }) => spacing({ theme, m: 0, mb: haveDescription ? 2 : 3 })};
`;

export const WhatYouNeedToDoDescription = styled.div`
  ${text_m}
  ${({ theme, haveActions }) => spacing({ theme, m: 0, mb: haveActions ? 3 : 0 })};

  p {
    ${({ theme }) => spacing({ theme, m: 0, mb: 2 })};

    ${mq.lessThan('md')} {
      ${({ theme }) => spacing({ theme, mb: 1 })};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ListActionContainer = styled.div`
  display: flex;

  ${mq.lessThan('md')} {
    flex-direction: column;
  }
`;

export const CallToActionContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;

export const ActionContainer = styled.div`
  ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};

  &:first-child {
    ${({ theme }) => spacing({ theme, m: 0 })};
  }

  ${mq('md')} {
    &.row {
      ${({ theme }) => spacing({ theme, m: 0 })};
      display: inline-block;
    }

    & ~ &.row {
      ${({ theme }) => spacing({ theme, ml: 2 })};
    }
  }
`;

const OutlineCss = css`
  outline: 2px solid #4e5a5f;
  outline-offset: -2px;
  color: #4e5a5f;
  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    path {
      fill: #4e5a5f;
    }
  }

  &:hover {
    outline-color: #000000;
    color: #000000;

    svg {
      path {
        fill: #000000;
      }
    }
  }

  ${mq('md')} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CallToAction = styled.button`
  ${({ buttonType }) => button('default', buttonType)};
  display: ${({ isExternal }) => isExternal ? 'inline': 'flex'};
  align-items: center;
  border: none;
  ${heading_s};
  ${({ theme }) => spacing({ theme, m: 0, px: 2, py: 1 })};

  &:hover {
    border: none;
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, py: '10px' })};
  }
  ${({ buttonType }) => (buttonType === 'outline' ? OutlineCss : null)};

  body:not(.safe-focus-removal) &:focus:before {
    content: none;
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -4px;
      top: -4px;
      content: '';
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
`;
