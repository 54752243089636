import React from 'react';
import { withSvg } from '../Svg';

const SvgCarSpace = () => (
  <svg width="66" height="64" viewBox="0 0 66 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8246 41.2965H65.0046C65.0046 27.1636 53.5476 15.7065 39.4146 15.7065C25.2816 15.7065 13.8246 27.1636 13.8246 41.2965Z"
      fill="#F05F00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.916686 41.392C1.36929 41.3294 1.83157 41.2969 2.30143 41.2969H29.4429C29.6673 41.2969 29.8899 41.3043 30.1106 41.3189C30.1106 41.3115 30.1106 41.3042 30.1106 41.2968C30.1106 33.2351 23.5753 26.6997 15.5135 26.6997C7.45174 26.6997 0.916382 33.2351 0.916382 41.2968C0.916382 41.3286 0.916483 41.3603 0.916686 41.392Z"
      fill="#F05F00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4958 37.6332C10.8717 37.6332 9.55506 38.9498 9.55506 40.5739C9.55506 42.198 10.8717 43.5147 12.4958 43.5147C14.1199 43.5147 15.4365 42.198 15.4365 40.5739C15.4365 38.9498 14.1199 37.6332 12.4958 37.6332ZM4.77637 40.5739C4.77637 36.3106 8.23247 32.8545 12.4958 32.8545C16.7591 32.8545 20.2152 36.3106 20.2152 40.5739C20.2152 44.8372 16.7591 48.2934 12.4958 48.2934C8.23247 48.2934 4.77637 44.8372 4.77637 40.5739Z"
      fill="#6009A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0042 37.6105C54.4048 37.6105 53.1082 38.9373 53.1082 40.5739C53.1082 42.2106 54.4048 43.5373 56.0042 43.5373C57.6036 43.5373 58.9002 42.2106 58.9002 40.5739C58.9002 38.9373 57.6036 37.6105 56.0042 37.6105ZM48.4602 40.5739C48.4602 36.3106 51.8378 32.8545 56.0042 32.8545C60.1706 32.8545 63.5482 36.3106 63.5482 40.5739C63.5482 44.8372 60.1706 48.2934 56.0042 48.2934C51.8378 48.2934 48.4602 44.8372 48.4602 40.5739Z"
      fill="#6009A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.37479 41.5326C3.89798 41.4073 4.44408 41.3409 5.00568 41.3409H20.295C20.6996 41.3409 21.0962 41.3754 21.4819 41.4415C21.4823 41.408 21.4824 41.3745 21.4824 41.341C21.4824 36.3401 17.4285 32.2861 12.4276 32.2861C7.42678 32.2861 3.3728 36.3401 3.3728 41.341C3.3728 41.405 3.37347 41.4689 3.37479 41.5326Z"
      fill="#DA1E5B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8866 41.6689C47.4118 41.5433 47.9599 41.4768 48.5236 41.4768H63.8903C64.2964 41.4768 64.6945 41.5113 65.0817 41.5776C65.083 41.5142 65.0837 41.4506 65.0837 41.3868C65.0837 36.3606 61.0092 32.2861 55.983 32.2861C50.9568 32.2861 46.8823 36.3606 46.8823 41.3868C46.8823 41.4812 46.8838 41.5752 46.8866 41.6689Z"
      fill="#DA1E5B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.7064 18.1626C36.7022 18.1626 36.698 18.1626 36.6938 18.1626C29.3369 18.1626 23.8994 22.7241 21.6187 27.626C21.6187 27.6579 21.6189 27.6897 21.6195 27.7214C22.0046 27.6586 22.3999 27.6259 22.8027 27.6259H36.7064V18.1626Z"
      fill="#FADD21"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1104 18.1626C38.1157 18.1626 38.1211 18.1626 38.1264 18.1626C47.8064 18.1626 53.5484 21.6714 57.0573 27.6259H38.1104V18.1626Z"
      fill="#FADD21"
    />
  </svg>
);

export default withSvg(66, 64)(SvgCarSpace);
