import React from 'react';
import { withSvg } from '../Svg';

const SvgBusiness = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.23779" y="18.6636" width="59.7273" height="42.8757" fill="#6009A0" />
    <path d="M52.7763 2.23779H11.4266L2.23779 18.6628H61.9651L52.7763 2.23779Z" fill="#F05F00" />
    <path
      d="M2.23779 18.6636H14.1833V25.0629C14.1833 28.3616 11.5092 31.0357 8.21053 31.0357C4.91188 31.0357 2.23779 28.3616 2.23779 25.0629V18.6636Z"
      fill="#F05F00"
    />
    <path
      d="M14.1832 18.6636H26.1287V25.0629C26.1287 28.3616 23.4546 31.0357 20.156 31.0357C16.8573 31.0357 14.1832 28.3616 14.1832 25.0629V18.6636Z"
      fill="#F05F00"
    />
    <path
      d="M26.1288 18.6636H38.0742V25.0629C38.0742 28.3616 35.4002 31.0357 32.1015 31.0357C28.8029 31.0357 26.1288 28.3616 26.1288 25.0629V18.6636Z"
      fill="#F05F00"
    />
    <path
      d="M38.0742 18.6636H50.0197V25.0629C50.0197 28.3616 47.3456 31.0357 44.0469 31.0357C40.7483 31.0357 38.0742 28.3616 38.0742 25.0629V18.6636Z"
      fill="#F05F00"
    />
    <path
      d="M50.0197 18.6636H61.9651V25.0629C61.9651 28.3616 59.291 31.0357 55.9924 31.0357C52.6937 31.0357 50.0197 28.3616 50.0197 25.0629V18.6636Z"
      fill="#F05F00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4624 38.2871H31.4616V61.5381H31.4616V61.5384H18.6628V42.767H18.6733C18.8309 40.3711 20.7465 38.4553 23.1424 38.2976V38.2871H23.4624Z"
      fill="#FADD21"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.7407 38.2871H32.7415V61.5381H32.7415V61.5384H45.5402V42.767H45.5297C45.3721 40.3711 43.4565 38.4553 41.0606 38.2976V38.2871H40.7407Z"
      fill="#FADD21"
    />
  </svg>
);

export default withSvg(64, 64)(SvgBusiness);
