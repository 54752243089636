import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';
import { color, mq } from '_utils/styles';

const NUMBER_ITEMS_LARGE_VIEWPORT = 8;
const NUMBER_ITEMS_SMALL_VIEWPORT = 4;
const ITEMS_HEIGHT_LARGE_VIEWPORT = 44;
const ITEMS_HEIGHT_SMALL_VIEWPORT = 40;

export const RowContainer = styled.div`
  ${({ theme }) => spacing({ theme, mb: 2 })};
`;

export const SearchAddressContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const SearchPanel = styled.div`
  display: ${({ showPanel }) => showPanel ? 'block' : 'none'};
  position: absolute;
  ${({ theme }) => spacing({ theme, mt: 1, py: 3 })};
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: ${color('all.white')};
  width: 100%;
  z-index: 10;
`;

export const SearchItemContainer = styled.div`
  overflow-y: auto;
  height: auto;
  ${({ theme }) => spacing({ theme, px: 3 })};
  max-height: ${NUMBER_ITEMS_SMALL_VIEWPORT * ITEMS_HEIGHT_SMALL_VIEWPORT}px;

  ${mq('md')} {
    max-height: ${NUMBER_ITEMS_LARGE_VIEWPORT * ITEMS_HEIGHT_LARGE_VIEWPORT}px;
  }
`

export const SearchItem = styled.div`
  height: ${ITEMS_HEIGHT_SMALL_VIEWPORT}px;
  display: flex;
  align-items: center;
  
  ${mq('md')} {
    height: ${ITEMS_HEIGHT_LARGE_VIEWPORT}px;
  }

  &:first-child {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }

  > div {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    font-weight: 700;
    font-family: "Helvetica Now Text", Helvetica, sans-serif;
    cursor: pointer;
  }
`;

export const TextareaInput = styled.input`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    width: 100%;
    height: 48px;
    background: #F9F9F9;
    border: 1px solid #DDDEE1;
    border-radius: 4px;
`