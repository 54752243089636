import styled from 'styled-components';

import { color, spacing, flex, layout, transitions, text } from '_utils/props/';

export default styled.div`
  ${color}
  ${spacing}
  ${flex}
  ${layout}
  ${transitions}
  ${text}
`;
