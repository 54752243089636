import React from 'react';
import { withSvg } from '../Svg';

const SvgYoutube = () => (
  <path
    d="M23 5.70998C23.0495 4.27864 22.7365 2.858 22.09 1.57998C21.6514 1.05558 21.0427 0.701694 20.37 0.579984C17.5875 0.327509 14.7936 0.224028 12 0.269984C9.21667 0.221942 6.43274 0.322084 3.66003 0.569984C3.11185 0.669701 2.60454 0.926826 2.20003 1.30998C1.30003 2.13998 1.20003 3.55998 1.10003 4.75998C0.954939 6.91755 0.954939 9.08242 1.10003 11.24C1.12896 11.9154 1.22952 12.5858 1.40003 13.24C1.5206 13.745 1.76455 14.2123 2.11003 14.6C2.51729 15.0034 3.03641 15.2752 3.60003 15.38C5.75594 15.6461 7.92824 15.7564 10.1 15.71C13.6 15.76 16.67 15.71 20.3 15.43C20.8775 15.3316 21.4112 15.0595 21.83 14.65C22.11 14.3699 22.3191 14.0271 22.44 13.65C22.7977 12.5526 22.9733 11.4041 22.96 10.25C23 9.68998 23 6.30998 23 5.70998ZM9.74003 10.85V4.65998L15.66 7.76998C14 8.68998 11.81 9.72998 9.74003 10.85Z"
    fill="white"
  />
);

export default withSvg(24, 16)(SvgYoutube);
