import React from 'react';
import { withSvg } from '../Svg';

const SvgResidentialHouse = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.084 11.1696L33.6784 0.447754L55.2728 11.1696V41.3988H12.084L12.084 11.1696Z"
      fill="#003474"
    />
    <path d="M0 34.9269L18.6853 24.6152L37.3706 34.9269V63.9998H0L0 34.9269Z" fill="#FADD21" />
    <g clipPath="url(#clip0_14315_103501)">
      <path
        d="M25.4067 18.1914C25.3591 20.6079 23.4305 22.5533 21.0349 22.6013V18.1914H25.4067Z"
        fill="#FADD21"
      />
      <path
        d="M26.5093 18.1914C26.5568 20.6079 28.4898 22.5533 30.881 22.6013V18.1914H26.5093Z"
        fill="#FADD21"
      />
      <path
        d="M30.881 17.3889C30.8335 14.9768 28.9049 13.027 26.5093 12.979V17.3889H30.881Z"
        fill="#FADD21"
      />
      <path
        d="M21.0349 17.3889C21.0825 14.9768 23.0154 13.027 25.4067 12.979V17.3889H21.0349Z"
        fill="#FADD21"
      />
    </g>
    <g clipPath="url(#clip1_14315_103501)">
      <path
        d="M40.6235 18.1914C40.5759 20.6079 38.6473 22.5533 36.2517 22.6013V18.1914H40.6235Z"
        fill="#FADD21"
      />
      <path
        d="M41.7261 18.1914C41.7736 20.6079 43.7065 22.5533 46.0978 22.6013V18.1914H41.7261Z"
        fill="#FADD21"
      />
      <path
        d="M46.0978 17.3889C46.0503 14.9768 44.1217 13.027 41.7261 12.979V17.3889H46.0978Z"
        fill="#FADD21"
      />
      <path
        d="M36.2517 17.3889C36.2993 14.9768 38.2322 13.027 40.6235 12.979V17.3889H36.2517Z"
        fill="#FADD21"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7202 53.5569V63.9998H13.4265V53.5569C13.4265 50.6732 15.7308 48.3354 18.5734 48.3354C21.4159 48.3354 23.7202 50.6732 23.7202 53.5569Z"
      fill="#DA1E5B"
    />
    <ellipse cx="18.5735" cy="39.1609" rx="4.25175" ry="4.25175" fill="#003474" />
    <path d="M37.3706 34.9092H55.2028L64 39.0803V64.0001H37.3706V34.9092Z" fill="#DA1E5B" />
    <g clipPath="url(#clip2_14315_103501)">
      <path
        d="M50.0221 48.625C49.9746 51.0415 48.046 52.9869 45.6504 53.0349V48.625H50.0221Z"
        fill="#FADD21"
      />
      <path
        d="M51.1248 48.625C51.1723 51.0415 53.1052 52.9869 55.4965 53.0349V48.625H51.1248Z"
        fill="#FADD21"
      />
      <path
        d="M55.4965 47.8225C55.4489 45.4104 53.5203 43.4606 51.1248 43.4126V47.8225H55.4965Z"
        fill="#FADD21"
      />
      <path
        d="M45.6504 47.8225C45.698 45.4104 47.6309 43.4606 50.0221 43.4126V47.8225H45.6504Z"
        fill="#FADD21"
      />
    </g>
    <defs>
      <clipPath id="clip0_14315_103501">
        <rect width="9.84615" height="9.62238" fill="white" transform="translate(21.0349 12.979)" />
      </clipPath>
      <clipPath id="clip1_14315_103501">
        <rect width="9.84615" height="9.62238" fill="white" transform="translate(36.2517 12.979)" />
      </clipPath>
      <clipPath id="clip2_14315_103501">
        <rect
          width="9.84615"
          height="9.62238"
          fill="white"
          transform="translate(45.6504 43.4126)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default withSvg(64, 64)(SvgResidentialHouse);
