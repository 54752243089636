import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { spacing } from '_utils/props';
import {
  color,
  heading_m,
  mq,
  text_m,
  text_s,
  text_xs,
  link,
  svg,
  buttonAsLinkReset,
  safeFocusRemoval,
  hideOnPrint,
  iconColorOnPrint,
  linkStyle
} from '_utils/styles';

export const PaymentDueWrapper = styled.div`
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};
  &:nth-child(2n) {
    background-color: #f9f9f9;
    border-top: 1px dashed ${color('all.deepBlueTint15')};
    border-bottom: 1px dashed ${color('all.deepBlueTint15')};
  }

  ${mq('lg')} {
    > div {
      margin-left: -12px;
      margin-right: -12px;
    }
    > div > div {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;

export const PD_DateStatusTagContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PD_StatusTagContainer = styled.div`
  margin-bottom: 4px;
  > div > div:first-child {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PD_Date = styled.div`
  ${text_s};
  color: ${color('all.deepBlueTint75')};
  margin: 0;
`;

export const PD_TypeDescriptionContainer = styled.div`
  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: 1 })};
  }
`;

export const PD_Type = styled.div`
  ${text_xs};
  color: ${color('all.deepBlueTint75')};
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
`;

export const PD_PaymentConfirmation = styled.span`
  display: inline-block;
  padding-left: 18px;
  position: relative;

  &:before {
    content: '.';
    font-weight: bold;
    position: absolute;
    top: -3px;
    left: 7px;
  }
`;

const PD_DescriptionCSS = css`
  ${text_m};
  color: ${color('all.deepBlue')};
  display: block;
  margin: 0;
  margin-top: 4px;
  text-transform: initial;
`;

export const PD_DescriptionText = styled.div`
  ${PD_DescriptionCSS};
`;

export const PD_Description = styled(NavLink)`
  cursor: pointer;
  text-decoration-line: underline;
  ${PD_DescriptionCSS};

  &:visited {
    color: ${color('all.darlinghurstPurple')} !important;
  }
  &:focus {
    color: ${color('all.deepBlue')};
  }
  &:hover {
    color: ${color('all.greenTint110')} !important;
  }
`;

export const PD_AmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, pt: 1 })};
    justify-content: space-between;
  }
`;

export const PD_PayContainer = styled.div`
  ${({ theme }) => spacing({ theme, ml: 1 })};
  display: flex;
  min-width: 56px;

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, ml: 3 })};
    min-width: 60px;
  }
`;

export const SvgWrapper = styled.span`
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 4px;
  ${svg(20, 20)}
  width: 20px;
  height: 20px;
  path {
    fill: #188838;
  }
  
  ${mq.lessThan('md')} {
    width: 18px;
    height: 18px;
  }
`;

export const PD_Amount = styled.div`
  ${heading_m};
  color: ${color('all.deepBlue')};
  font-weight: 700;

  ${mq.lessThan('md')} {
    flex-grow: 1;
    text-align: ${({ isPaymentHistory }) => (isPaymentHistory ? 'right' : 'initial')};
  }
`;

export const PD_PayLink = styled.a`
  display: flex;
  align-items: center;
  ${link};
  ${text_m};
  margin: 0;
`;

export const ShowMoreButton = styled.button`
  ${buttonAsLinkReset}
  ${({ theme }) => linkStyle(theme.colors.link.black)};
  ${safeFocusRemoval}
  }
  ${text_s}
  margin: 0;
  ${hideOnPrint}
`;

export const ArrowWrapper = styled.span`
  ${svg(7, 12)}
  margin-left: 8px;
  width: 7px;
  transform: rotate(90deg);

  ${ShowMoreButton}:focus &,
  ${ShowMoreButton}:hover & {
    path {
      fill: ${({ theme }) => theme.colors.element.white};
      ${iconColorOnPrint}
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.element.primary};
  }
`;;
