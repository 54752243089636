import React from 'react';

import { AttributeRow, AttributeIcon, AttributeValue } from './StyledInstallationPeriod';
import { Calendar } from '_utils/icons';
import { useTranslation } from 'react-i18next';

const InstallationPeriod = ({ installationPeriod }) => {
  const [t] = useTranslation();

  return (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <Calendar ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>
      {t('page-banner-installed')}{' '}
      {installationPeriod}</AttributeValue>
    </AttributeRow>
  );
};

export default InstallationPeriod;
