import styled from 'styled-components';
import { spacing } from '_utils/props';
import { mq } from '_utils/styles';
import color from '_containers/Theme/colors';

export const MetadataThemes = {
  default: {
    name: 'default',
    color: color.deepBlueTint75,
    background: 'transparent'
  },
  blueWhite: {
    name: 'blueWhite',
    color: color.white,
    background: color.blue
  }
};

// Styled Metadata
export const StyledMetadata = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 18px;
  color: ${({ metadataTheme }) => MetadataThemes[metadataTheme].color};
  background-color: ${({ metadataTheme }) => MetadataThemes[metadataTheme].background};

  ${mq('md')} {
    font-size: 16px;
    line-height: 22px;
  }

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    ${({ theme }) => spacing({ theme, mr: 1, mt: { md: '2px' } })};

    path {
      fill: ${({ metadataTheme }) => MetadataThemes[metadataTheme].color};
    }
  }
`;
