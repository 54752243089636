import styled from 'styled-components';
import { spacing } from '_utils/props';
import { svg, heading_l, heading_m, heading_xs, mq, color, text_s } from '_utils/styles';

export const RateTypeGraphic = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color('all.white')};
  border-radius: 8px;
  border: 1px solid ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, p: 2 })};
`;

export const GraphicContainer = styled.div`
  ${svg(48, 48)}
  width: 48px;
  ${({ theme }) => spacing({ theme, mb: 1 })};

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mb: 2 })};
    ${svg(64, 64)}
    width: 64px;
  }
`;

export const RateTypeAddress = styled.div`
  ${heading_l};
  ${({ theme }) => spacing({ theme, m: 0 })};

  ${mq('md')} {
    ${heading_m};
  }
`;

export const RateTypeRatingCategory = styled.div`
  ${heading_xs}
  color: #041C2C;
  ${({ theme }) => spacing({ theme, mt: 1, mr: 1 })}
`;
export const RateTypeRatingCategorySpan = styled.span`
  ${text_s}
  color: #041C2C;
  ${({ theme }) => spacing({ theme, ml: 1 })}
`;
