import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import {
  CategoryLink,
  CategoryLinkContainer,
  Header,
  Description,
  TextWrapper,
  AccountInfoText,
  MetadataWrapper,
  AttributeContainer
} from './StyledBannerContent';
import ContentRichText from '_components/Corporate/ContentRichText';
import Metadata, { MetadataThemeConfig } from '_utils/components/CityConnect/Metadata';

const BannerContent = ({
  link,
  pageTitle,
  message,
  subheading,
  metadata,
  accountInfo,
  withImage,
  isDashboard
}) => {
  return (
    <>
      {link?.jss?.value?.href && (
        <CategoryLinkContainer>
          <CategoryLink to={link?.jss?.value?.href}>
            <Text field={{ value: link?.jss?.value?.text }} editable={false} />
          </CategoryLink>
        </CategoryLinkContainer>
      )}
      {pageTitle && (
        <Header>
          <Text field={pageTitle} />
        </Header>
      )}
      {message?.value && (
        <Description isDashboard={isDashboard}>
          <ContentRichText field={message} />
        </Description>
      )}
      {subheading && (
        <TextWrapper>
          <Text field={{ value: subheading }} editable={false} />
        </TextWrapper>
      )}
      {metadata?.length > 0 && (
        <AttributeContainer>
          {metadata.map((item, index) => {
            return (
              <MetadataWrapper key={index}>
                <Metadata
                  metadataType={item.type}
                  text={item.value}
                  metadataTheme={MetadataThemeConfig.BlueWhite}
                />
              </MetadataWrapper>
            );
          })}
        </AttributeContainer>
      )}
      {accountInfo && (
        <AccountInfoText className={withImage ? 'mb-5' : ''}>
          <Text field={accountInfo} />
        </AccountInfoText>
      )}
    </>
  );
};

export default BannerContent;
