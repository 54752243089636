export default {
  // core
  deepBlue: '#041C2C',
  deepBlueTint75: '#4E5A5F',
  deepBlueTint45: '#959B9E',
  deepBlueTint15: '#DDDEE1',
  deepBlueTint07: '#F0F1F2',
  deepBlueTint03: '#F9F9F9',
  blue: '#0850A1',
  blueTint110: '#003474',
  blueTint75: '#4060FF',
  blueTint45: '#8CA0FF',
  blueTint15: '#D9DFFF',
  blueTint7: '#ECEFFE',
  green: '#2DB84B',
  greenTint120: '#005a0b',
  greenTint115: '#157932',
  greenTint110: '#188838',
  greenTint75: '#25C96F',
  greenTint45: '#8CE0AA',
  greenTint25: '#C1EFD0',
  greenTint15: '#DAF5E4',
  darkBlue: '#001C2E',
  // greenTint110Darken20: chroma('#188838')
  //   .darken(1)
  //   .hex(),
  white: '#FFF',
  white20: '#ffffff33',
  light: '#F2ECE7',
  transparent: '#00000000',
  black: '#000',
  blackTint75: '#404040',
  black07: 'rgba(0,0,0,0.07)',
  black10: 'rgba(0,0,0,0.1)',
  black20: 'rgba(0,0,0,0.2)',
  // secondary
  pottsPointRed: '#FF0303',
  pottsPointRedTint110: '#B00020',
  pottsPointRedTint75: '#FF4242',
  pottsPointRedTint45: '#FF8E8E',
  pottsPointRedTint15: '#ffd8d9',
  darlinghurstPurple: '#6009A0',
  darlinghurstPurpleTint110: '#37266A',
  darlinghurstPurpleTint75: '#8747B9',
  darlinghurstPurpleTint45: '#B790D3',
  darlinghurstPurpleTint15: '#E8DAF1',
  annandaleOrange: '#F05F00',
  annandaleOrangeTint110: '#C33D14',
  annandaleOrangeTint75: '#FF8125',
  annandaleOrangeTint45: '#FFB485',
  annandaleOrangeTint15: '#FFE6D7',
  pyrmontBlue: '#4BC2E4',
  pyrmontBlueTint110: '#005084',
  pyrmontBlueTint75: '#4BC2E4',
  pyrmontBlueTint45: '#9FE5F6',
  pyrmontBlueTint15: '#E0F6FD',
  centennialParkYellow: '#FADD21',
  centennialParkYellowTint110: '#CF9E37',
  centennialParkYellowTint75: '#FBE659',
  centennialParkYellowTint45: '#FCF09A',
  centennialParkYellowTint15: '#FEFADD',
  newtownFuchsia: '#DA1E5B',
  newtownFuchsiaTint110: '#821846',
  newtownFuchsiaTint75: '#E35583',
  newtownFuchsiaTint45: '#EE9AB4',
  newtownFuchsiaTint15: '#F8DDE6',
  newtownFuchsiaTint7: '#FEEDF3',
  theRocksBrown: '#695543',
  theRocksBrownTint110: '#534031',
  theRocksBrownTint75: '#938071',
  theRocksBrownTint45: '#BDB1A7',
  theRocksBrownTint15: '#E9E4E1'
};
