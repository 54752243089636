import React, { useState, useRef } from 'react';
import { Container, OptionWrapper, Option, Select, TextValue } from './StyledDropdown';
import ArrowDown from '_utils/icons/ArrowDown';
import { useTranslation } from 'react-i18next';

interface dropdownProps {
  index?: number;
  data: any;
  value: string;
  type?: string;
  handleChange: any;
  isHavingOtherOption?: boolean;
  handleSelectOption?: any;
}

const DropDown: React.FC<dropdownProps> = ({
  index,
  data,
  handleSelectOption,
  value,
  type,
  isHavingOtherOption = false,
  handleChange
}) => {
  const [selected, setSelected] = useState(value || 'Select');
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [t] = useTranslation();
  const timeoutRef = useRef(null);

  const handleSelect = (value: string) => {
    setSelected(value);
    handleChange(value, type);
    setOpen(false);
    setActive(false);
  };

  const handleClickSelect = () => {
    setOpen(!open);
    setActive(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(false);
  };

  const handleOptionFocus = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleOptionBlur = () => {
    timeoutRef.current = setTimeout(() => {
      handleClose();
    }, 200);
  };

  return (
    <Container index={index}>
      <Select
        onClick={() => handleClickSelect()}
        className={active ? 'active' : ''}
      >
        <TextValue>{selected}</TextValue>
        <ArrowDown />
      </Select>
      {open && (
        <OptionWrapper
        onFocus={() => handleOptionFocus()}
        onBlur={() => handleOptionBlur()}>
          {data.map((item, idx) => (
            <Option
              key={idx}
              onClick={() => {
                handleSelect(item);
                if (isHavingOtherOption) {
                  handleSelectOption(item, type);
                }
              }}
            >
              {item}
            </Option>
          ))}
        </OptionWrapper>
      )}
    </Container>
  );
};

export default DropDown;
