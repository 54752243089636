import { color, mq } from '_utils/styles';
import styled from 'styled-components';
import { spacing } from '_utils/props';

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// INFO: Wrapper Content
export const PDL_SortContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const PDL_SortButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }
`;

export const PDL_SortButtonIconLeft = styled(FlexCenter)`
  width: 20px;
  height: 20px;

  ${mq('md')} {
    width: 24px;
    height: 24px;
  }
`;

export const PDL_SortButtonLabel = styled.div`
  ${({ theme }) => spacing({ theme, mx: 1 })};

  ${mq.lessThan('md')} {
    margin-right: 4px;
  }
`;

export const PDL_SortButtonIconRight = styled(FlexCenter)`
  width: 20px;
`;

export const PDL_SortPanel = styled.div`
  display: ${({ showPanel }) => (showPanel ? 'block' : 'none')};
  position: absolute;
  ${({ theme }) => spacing({ theme, mt: 1, p: 3 })};
  top: calc(100%);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: ${color('all.white')};
  width: max-content;
  z-index: 10;
`;

export const PDL_SortItem = styled.div`
  ${({ theme }) => spacing({ theme, mt: 2 })};
  &:first-child {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }

  > div > label {
    ${({ theme }) => spacing({ theme, ml: 1 })};
    padding-left: 20px;

    ${mq('md')} {
      padding-left: 24px;
    }
  }
  > div > input:checked + label {
    font-weight: 700;
    font-family: 'Helvetica Now Text', Helvetica, sans-serif;
  }
  > div > div:last-child {
    border-color: ${color('all.deepBlueTint45')};

    ${mq.lessThan('md')} {
      width: 20px;
      height: 20px;
    }
  }
`;
