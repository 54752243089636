import React from 'react';
import { withSvg } from './Svg';

const SvgArrowDown = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_22726_6173)">
      <path
        d="M9.90962 13.9292L4.91316 8.92968C4.8351 8.85222 4.77315 8.76006 4.73087 8.65852C4.6886 8.55698 4.66683 8.44807 4.66683 8.33807C4.66683 8.22807 4.6886 8.11916 4.73087 8.01762C4.77315 7.91608 4.8351 7.82393 4.91316 7.74646C4.99057 7.66837 5.08267 7.60638 5.18415 7.56407C5.28563 7.52177 5.39447 7.49999 5.5044 7.49999C5.61434 7.49999 5.72318 7.52177 5.82466 7.56407C5.92614 7.60638 6.01824 7.66837 6.09565 7.74646C7.51073 9.24676 8.96404 10.7116 10.5043 12.0835L14.9061 7.74647C15.0629 7.58956 15.2756 7.50141 15.4973 7.50141C15.7191 7.50141 15.9318 7.58956 16.0886 7.74647C16.2454 7.90337 16.3335 8.11618 16.3335 8.33807C16.3335 8.44794 16.3119 8.55674 16.2698 8.65825C16.2278 8.75976 16.1662 8.85199 16.0886 8.92968L11.0921 13.9292C11.0129 14.005 10.9195 14.0645 10.8173 14.1042C10.6146 14.1875 10.3872 14.1875 10.1844 14.1042C10.0822 14.0645 9.98882 14.005 9.90962 13.9292Z"
        fill="#041C2C"
      />
    </g>
    <defs>
      <clipPath id="clip0_22726_6173">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default withSvg(20, 20)(SvgArrowDown);
