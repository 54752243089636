import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';
import {
  heading_s,
  text_m,
  text_s,
  mq,
  color,
  svg
} from "_utils/styles/";
import { RichText } from '@sitecore-jss/sitecore-jss-react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  & textarea{
    ${({ theme }) => spacing({ theme, mt: 1, mb: 1 })};
    border: 1px solid #DDDEE1;
  }
  
  & .not-listed{
    ${({ theme }) => spacing({ theme, mt: 1 })};
  }
  
  & .suburb{
    ${({ theme }) => spacing({ theme, mb: 1 })};
  }
  
  & .term-condition{
    ${({ theme }) => spacing({ theme, mb: 2 })};
  }
`;

export const BackButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${text_m};
  color: #041C2C;
  & svg {
    ${({ theme }) => spacing({ theme, mr: 1, mb: 1 })};
  }
  ${({ theme }) => spacing({ theme, my: 0 })};
  text-decoration: underline;
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  ${heading_s};
  ${({ theme }) => spacing({ theme, mt: 4 })};
  ${mq.lessThan('md')}{
    flex-wrap: wrap;
    ${({ theme }) => spacing({ theme, mt: 5 })};
  }
`

export const InputWrapper = styled.div`
  .error-validation {
    ${text_s}
    color: ${color('all.pottsPointRedTint110')};
    margin: 0;
    padding-left: 5px;
    ${({ theme }) => spacing({ theme, mt: 1 })};
    display: flex;
    align-items: center;
    & svg {
       ${({ theme }) => spacing({ theme, mr: 1 })};
    }
  }
`

export const Optional = styled.p`
  ${text_s};
  color: #4E5A5F;
  ${({ theme }) => spacing({ theme, m: 0 })};
`

export const Note = styled.p`
  ${text_s};
  color: #4E5A5F;
  font-weight: 400;
  margin: 0;
`

export const CheckboxContainer = styled.div`
  ${({ theme }) => spacing({ theme, p: 3, mt: 1 })};
  border: 1px solid #DDDEE1;
  border-radius: 8px;
`;

export const Input = styled.input`
  ${({ theme }) => spacing({ theme, mt: 1 })};
  font-size: 18px;
  padding: 10px;
  width: 100%;
  background: ${props => props.background ? props.background : ''};
  border: 1px solid #DDDEE1;
  border-radius: 4px;
`;

export const Error = styled.p`
  ${text_s};
  color: ${color('all.pottsPointRedTint110')};
  margin: 0;
  padding-left: 5px;
  ${({ theme }) => spacing({ theme, mt: 1 })};
  display: flex;
  align-items: center;
  & svg {
     ${({ theme }) => spacing({ theme, mr: 1 })};
  }
`

export const RichTextContent = styled(RichText)`
  .policy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${({ theme }) => spacing({ theme, p: 2, mt: 2, mb: 3 })};
    border: 1px solid #DDDEE1;
    border-radius: 8px;
    max-height: 575px;
    overflow: scroll;
    overflow-x: hidden;
    
    ::-webkit-scrollbar{
      width: 16px;
    }
    
    ::-webkit-scrollbar-thumb{
      background: #959B9E;
      border-radius: 8px;
    }
  }
  
  .normal-text {
    ${text_s};
    color: #4E5A5F;
    font-weight: 400;
    ${({ theme }) => spacing({ theme, mb: 2, mt: 0 })};
    &:nth-child(6) {
      ${({ theme }) => spacing({ theme, mb: 0 })};
    }
    ${mq.lessThan('md')}{
        ${({ theme }) => spacing({ theme, mb: 3 })};
    }
  }
  
  .bold-text {
    ${({ theme }) => spacing({ theme, mb: 2 })};
    color: #4E5A5F;
    ${heading_s}
    ${mq.lessThan('md')}{
        ${({ theme }) => spacing({ theme, mb: 3 })};
    }
  }

  .term-condition-link{
    color: #041C2C;
    text-decoration: underline !important;
    box-shadow: none !important;

    &:hover{
      color: #041C2C !important;
      background-color: transparent !important;
    }
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshIcon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  margin-right: 10px;
  ${RotateCss}
  path {
    fill: ${color('all.white')};
  }
`;