import { useState, useEffect } from 'react';

interface LoginLogoutTime {
  loginTime: string | null;
  handleLoginTime: () => void;
}

function useLoginLogoutTime(): LoginLogoutTime {
  const [loginTime, setLoginTime] = useState<string | null>(null);

  const handleLoginTime = () => {
    const currentTime = new Date().toISOString();
    setLoginTime(currentTime);
    localStorage.setItem('loginTime', currentTime);
  };


  //Fetch previously stored login time from localStorage
  useEffect(() => {
    const storedLoginTime = localStorage.getItem('loginTime');
    if (storedLoginTime) {
      setLoginTime(storedLoginTime);
    }

    // Cleanup function to remove loginTime after 3 minutes
    const timeout = setTimeout(() => {
      localStorage.removeItem('loginTime');
      setLoginTime(null);
    }, 3 * 60 * 1000); // 3 minutes in milliseconds

    // Clear the timeout on unmount to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return { loginTime, handleLoginTime };
}

export default useLoginLogoutTime;
