import { color, mq, button, text_s, svg, safeFocusRemoval, text_m, heading_s } from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';

const CssFocus = css`
  &:focus:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    border: 2px solid #8ca0ff;
  }
`;

export const RatesInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  .preference-info:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 3 })};
  }

  .multipleOwnersMsg {
    margin-top: -10px;
  }
`;

export const InputLabel = styled.div`
  ${text_m};
  margin: 0;
`;

export const OptionsContainer = styled.div`
  ${({ theme }) => spacing({ theme, my: 3 })};
`;

export const RadioOption = styled.div`
  ${({ theme }) => spacing({ theme, mt: 2 })};

  &:first-child {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }

  & > div {
    > div {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      top: 50%;
      border-color: #959b9e;

      ${mq('md')} {
        width: 24px;
        height: 24px;
        border-radius: 24px;
      }

      ${CssFocus}
    }

    label {
      padding-left: 28px;

      ${mq('md')} {
        padding-left: 40px;
      }
    }
  }
`;

export const OptionLabel = styled.div`
  ${text_m}
  margin: 0;
  font-weight: ${({ selected }) => (selected ? '700' : '400')};
`;

export const SearchContainer = styled.div``;

export const SearchLabel = styled.div`
  ${text_m}
  margin: 0;
  ${({ theme }) => spacing({ theme, mb: 0 })};
`;

export const SearchLabelDescription = styled.div`
  ${text_s}
  color:#4E5A5F;
  margin: 0;
  ${({ theme }) => spacing({ theme, mb: 1 })};
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  ${({ theme }) => spacing({ theme, mt: 2 })};
`;

export const ErrorIcon = styled.div`
  ${svg(20, 20)}
  width: 20px;
  flex-shrink: 0;
  path {
    fill: ${color('all.pottsPointRedTint110')};
  }
`;

export const ErrorMessage = styled.div`
  ${text_s};
  color: ${color('all.pottsPointRedTint110')};
  ${({ theme }) => spacing({ theme, m: 0, ml: 1 })};
`;

export const SearchInput = styled.input`
  background: #f9f9f9;
  border: 1px solid #dddee1;
  border-radius: 4px;
  padding: 12px 16px;
  height: 48px;
  width: 100%;
  outline: none;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus,
  &:active {
    border: 2px solid ${color('all.green')};
  }

  &:disabled {
    background: #f0f1f2;
    border: 1px solid #dddee1;
    color: ${color('all.deepBlueTint45')};
  }

  &::placeholder {
    ${text_m}
    margin: 0;
    font-style: italic;
    color: ${color('all.deepBlueTint75')};
  }

  &.error {
    background: #feedf3;
    color: ${color('all.pottsPointRedTint110')};
  }

  ${mq('md')} {
    height: 48px;
    padding: 10px 16px;
  }
`;

export const CalculateButtonContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mt: 4 })};
  }
`;

export const CalculateButton = styled.button`
  ${button('default', 'primary')};
  ${safeFocusRemoval}
  ${heading_s};
  ${({ theme }) => spacing({ theme, px: 2, py: 1 })};
  display: flex;
  align-items: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshIcon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  margin-right: 10px;
  ${RotateCss}
  path {
    fill: ${color('all.white')};
  }
`;

export const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => spacing({ theme, mt: 3 })};
`;

export const AddressContainerHeader = styled.p`
  ${heading_s}
  ${({ theme }) => spacing({ theme, mb: 1 })};
`;

export const AddressValueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddressValue = styled.p`
  ${text_m}
  margin: 0;
  color: #4e5a5f;
`;

export const ClearButton = styled.button`
  ${text_s}
  margin: 0;
  color: #000000;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
`;
