import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '_utils/components/Corporate';
import RegistrationItem from './RegistrationItem';
import { DashboardTabsContext } from '..';
import ShowMore from '../../ShowMore';
import TextMessage from '../../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { REGISTRATIONS_TAB } from '../constant';
import TipBox from '_components/CityConnect/RatesCalculator/RatesInput/TipBox';

import {
  RegistrationsTabWrapper,
  ShowMoreContainer,
  ContentSection,
  UnAvailableSection
} from './StyledRegistrationsTab';

type RegistrationProps = {
  tabData: any;
};

const RegistrationsTab: React.FC<RegistrationProps> = ({ tabData }) => {
  const {
    activeTab,
    showMoreData,
    loading,
    error,
    description,
    notificationMsg,
    unavailableMessage,
    handleGetMoreItems
  } = useContext(DashboardTabsContext);

  useEffect(() => {
    if (error && activeTab === REGISTRATIONS_TAB) {
      throw error;
    }
  }, [error]);

  const formatTextMessageFields = (value: string) => {
    return {
      data: {
        datasource: {
          message: {
            jss: {
              value: value
            }
          }
        }
      }
    };
  };

  if (unavailableMessage) {
    return <>
      {/* notification message */}
      {notificationMsg && <TipBox tipDescription={notificationMsg} />}
      <UnAvailableSection>
        {/* Unavailable message */}
        <TextMessage fields={formatTextMessageFields(unavailableMessage)} />
      </UnAvailableSection>
    </>
  }

  return (
    <RegistrationsTabWrapper>
      {/* notification message */}
      {notificationMsg && <TipBox tipDescription={notificationMsg} />}
      {/* main content */}
      {tabData && tabData.length > 0 ? (
        <ContentSection>
          <Grid.Row config={{ gutters: [] }} m={{ xs: '-8px', md: '-12px' }} role='list'>
            {tabData.map((item, index) => {
              return (
                <Grid.Item
                  key={index}
                  config={{ col: { xs: 12, sm: 6, md: 6, lg: 4 }, gutters: [] }}
                  p={{ xs: 1, md: '12px' }}
                  role="listitem"
                >
                  <RegistrationItem data={item} />
                </Grid.Item>
              );
            })}
          </Grid.Row>
          {/* show more */}
          {showMoreData.nextPage && (
            <ShowMoreContainer>
              <ShowMore
                labelTransKeys={{
                  showLabel: 'tab-pagination-showing',
                  ofLabel: 'tab-pagination-of',
                  itemLabel: showMoreData.tabName ? 'tab-pagination-' + showMoreData.tabName : '',
                  showMoreLabel: 'tab-pagination-show-more',
                  loadingLabel: 'tab-pagination-loading'
                }}
                numbers={{
                  showingNumber: String(showMoreData.showRequest),
                  totalNumber: showMoreData.totalItems
                }}
                handleClickShowMore={handleGetMoreItems}
                isLoading={showMoreData.showMoreLoading}
              />
            </ShowMoreContainer>
          )}
        </ContentSection>
      ) : (
        <ContentSection>
          <TextMessage fields={formatTextMessageFields(description)} loading={loading} />
        </ContentSection>
      )}
    </RegistrationsTabWrapper>
  );
};

const RegistrationsTabFallback: React.FC = () => {
  const [t] = useTranslation();
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return <TextMessage fields={errorMessage} />;
};

export default withErrorBoundary(RegistrationsTab, RegistrationsTabFallback);
