import React from 'react';
import { withSvg } from '../Svg';

const SvgBook = () => (
  <path
    d="M28.2266 5.58667C27.2709 5.42205 26.3031 5.33731 25.3333 5.33333C22.0245 5.33062 18.7846 6.27943 16 8.06667C13.2082 6.3029 9.96885 5.37738 6.66665 5.4C5.69684 5.40397 4.72905 5.48871 3.77331 5.65333C3.46025 5.70731 3.17675 5.87128 2.97386 6.11572C2.77097 6.36017 2.66203 6.66902 2.66665 6.98667V22.9867C2.66379 23.1826 2.70415 23.3767 2.78485 23.5553C2.86554 23.7338 2.9846 23.8924 3.13354 24.0197C3.28249 24.147 3.45766 24.2399 3.64659 24.2918C3.83552 24.3437 4.03357 24.3533 4.22665 24.32C6.13734 23.9892 8.09491 24.0441 9.98407 24.4814C11.8732 24.9188 13.6557 25.7298 15.2266 26.8667L15.3866 26.96H15.5333C15.6812 27.0216 15.8398 27.0533 16 27.0533C16.1602 27.0533 16.3188 27.0216 16.4666 26.96H16.6133L16.7733 26.8667C18.3333 25.7044 20.1107 24.8671 22.0002 24.4045C23.8897 23.9418 25.8529 23.8632 27.7733 24.1733C27.9664 24.2067 28.1644 24.197 28.3534 24.1451C28.5423 24.0932 28.7175 24.0003 28.8664 23.873C29.0154 23.7457 29.1344 23.5871 29.2151 23.4086C29.2958 23.23 29.3362 23.0359 29.3333 22.84V6.84C29.3194 6.53621 29.2021 6.24628 29.0008 6.01832C28.7996 5.79035 28.5264 5.63805 28.2266 5.58667ZM14.6666 23.3067C12.1998 22.0089 9.454 21.3316 6.66665 21.3333C6.22665 21.3333 5.78665 21.3333 5.33331 21.3333V8C5.77739 7.97441 6.22257 7.97441 6.66665 8C9.51116 7.99686 12.2936 8.83159 14.6666 10.4V23.3067ZM26.6666 21.3867C26.2133 21.3867 25.7733 21.3867 25.3333 21.3867C22.546 21.3849 19.8001 22.0622 17.3333 23.36V10.4C19.7064 8.83159 22.4888 7.99686 25.3333 8C25.7774 7.97441 26.2226 7.97441 26.6666 8V21.3867Z"
    fill="white"
  />
);

export default withSvg(32, 32)(SvgBook);
