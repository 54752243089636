import React, { useState } from 'react';
import moment from 'moment-mini';
import { useTranslation } from 'react-i18next';

import { Grid } from '_utils/components/Corporate';
import NavItem from '_components/Corporate/NavItem';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import { IPaymentDueProps } from './definitions';
import { SimpleArrow } from '_utils/icons';
// import styled
import {
  PaymentDueWrapper,
  PD_DateStatusTagContainer,
  PD_StatusTagContainer,
  PD_Date,
  PD_TypeDescriptionContainer,
  PD_Type,
  PD_PaymentConfirmation,
  PD_DescriptionText,
  PD_Description,
  PD_AmountContainer,
  PD_PayContainer,
  PD_Amount,
  PD_PayLink,
  SvgWrapper,
  ShowMoreButton,
  ArrowWrapper
} from './StyledPaymentDue';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

const PAYMENT_TYPE = {
  RATES: 'rates'
};

const PaymentDue: React.FC<IPaymentDueProps> = ({ payment, isPaymentHistory }) => {
  const [t] = useTranslation();
  const [numberOfRenderDescription, setNumberOfRenderDescription] = useState(5);

  const renderReferenceTextByNumAndType = (
    refNo: string,
    paymentType: string
  ) => {
    let prefix = '';

    if (
      isPaymentHistory &&
      paymentType === PAYMENT_TYPE.RATES.toLowerCase() &&
      refNo?.split(',').length > 1
    ) {
      return t('payment-history-multiple-assessments');
    }

    switch (paymentType.toLowerCase()) {
      case '':
        prefix = '';
        break;
      case PAYMENT_TYPE.RATES.toLowerCase():
        prefix = t('payments-due-assessment-number');
        break;
      default:
        prefix = t('payments-due-reference-number');
        break;
    }
    return `${prefix} ${refNo}`;
  };
  const handleRenderDescription = (description: Array<string>, renderNumber: number) => {
    if (!description) {
      return null;
    }
    if (description.length <= renderNumber) {
      return description.map((descItem) => <div key={descItem}>{descItem}</div>);
    }
    return (
      <>
        {description.slice(0, renderNumber).map((descItem) => (
          <div key={descItem}>{descItem}</div>
        ))}
        <ShowMoreButton onClick={() => handleLoadMoreDescrption()}>
          <span role="text">
            Show more
            {' '}
            ({payment.description.length - numberOfRenderDescription})
          </span>
        </ShowMoreButton>
        <ArrowWrapper>
          <SimpleArrow ariaHidden={true} />
        </ArrowWrapper>

      </>
    );
  };

  const handleLoadMoreDescrption = () => {
    if (payment.description.length > numberOfRenderDescription) {
      setNumberOfRenderDescription((current) => current + 10);
    }
  };

  return (
    <PaymentDueWrapper>
      <Grid.Row config={{ gutters: ['left', 'right'] }}>
        <Grid.Item config={{ col: { xs: 12, md: 3 }, gutters: ['left', 'right'] }}>
          <PD_DateStatusTagContainer>
            {payment.status && (
              <PD_StatusTagContainer>
                <StatusTag text={payment.status.toLowerCase()} />
              </PD_StatusTagContainer>
            )}
            {!isPaymentHistory
              ? payment.dueDate && (
                <PD_Date>{payment.dueDate}</PD_Date>
              )
              : payment.date && <PD_Date>{moment(payment.date).format('DD MMMM YYYY')}</PD_Date>}
          </PD_DateStatusTagContainer>
        </Grid.Item>
        <Grid.Item config={{ col: { xs: 12, md: 6 }, gutters: ['left', 'right'] }}>
          <PD_TypeDescriptionContainer>
            <PD_Type>
              {payment.referenceNumber &&
                payment.paymentType &&
                renderReferenceTextByNumAndType(
                  payment.referenceNumber,
                  payment.paymentType
                )}
              {payment.receiptNumber && (
                <PD_PaymentConfirmation>
                  {t('payment-history-payment-confirmation')} {payment.receiptNumber}
                </PD_PaymentConfirmation>
              )}
            </PD_Type>
            {payment.description &&
              (payment.link ? (
                <NavItem
                  link={{
                    url: payment.link,
                    type: 'internal'
                  }}
                  StyledLink={PD_Description}
                  description={payment.description[0]}
                >
                  {handleRenderDescription(payment.description, numberOfRenderDescription)}
                </NavItem>
              ) : (
                  <PD_DescriptionText>
                    {handleRenderDescription(payment.description, numberOfRenderDescription)}
                  </PD_DescriptionText>
                ))}
          </PD_TypeDescriptionContainer>
        </Grid.Item>
        <Grid.Item config={{ col: { xs: 12, md: 3 }, gutters: ['left', 'right'] }}>
          <PD_AmountContainer>
            {payment.amount && (
              <PD_Amount isPaymentHistory={isPaymentHistory}> {payment.amount} </PD_Amount>
            )}
            {!isPaymentHistory && (
              <PD_PayContainer>
                {payment.paymentLink && payment.status.toLowerCase() !== 'payment-processing' && (
                  <PD_PayLink
                    href={payment.paymentLink}
                    aria-label={t('payments-due-pay')}
                    target="_blank"
                  >
                    {t('payments-due-pay')}
                    <SvgWrapper>
                      <SvgExternal />
                    </SvgWrapper>
                  </PD_PayLink>
                )}
              </PD_PayContainer>
            )}
          </PD_AmountContainer>
        </Grid.Item>
      </Grid.Row>
    </PaymentDueWrapper>
  );
};

export default PaymentDue;
