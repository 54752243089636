import { spacing } from '_utils/props';
import { svg } from '_utils/styles';
import styled from 'styled-components';

export const StyledStatusTag = styled.div`
  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;

  background: ${({ type }) => type.backgroundColor};
  color: ${({ type }) => type.textColor};

  svg {
    path {
      fill: ${({ type }) => type.textColor};
    }
  }
`;

export const Icon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  ${({ theme }) => spacing({ theme, mr: 1 })}
`;

export const Text = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
`;
