import React from 'react';
import { withSvg } from '../Svg';

const SvgUrl = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8416 9.2C19.8416 9.2 19.8416 9.2 19.8416 9.15834C19.2545 7.55519 18.1891 6.17107 16.7895 5.19322C15.39 4.21537 13.7239 3.69098 12.0166 3.69098C10.3093 3.69098 8.64324 4.21537 7.24372 5.19322C5.8442 6.17107 4.77879 7.55519 4.19163 9.15834C4.19163 9.15834 4.19163 9.15834 4.19163 9.2C3.5359 11.0091 3.5359 12.9909 4.19163 14.8C4.19163 14.8 4.19163 14.8 4.19163 14.8417C4.77879 16.4448 5.8442 17.8289 7.24372 18.8068C8.64324 19.7846 10.3093 20.309 12.0166 20.309C13.7239 20.309 15.39 19.7846 16.7895 18.8068C18.1891 17.8289 19.2545 16.4448 19.8416 14.8417C19.8416 14.8417 19.8416 14.8417 19.8416 14.8C20.4974 12.9909 20.4974 11.0091 19.8416 9.2V9.2ZM5.54996 13.6667C5.26098 12.5743 5.26098 11.4257 5.54996 10.3333H7.09996C6.96665 11.4404 6.96665 12.5596 7.09996 13.6667H5.54996ZM6.23329 15.3333H7.39996C7.59556 16.0765 7.87514 16.7951 8.23329 17.475C7.41604 16.9183 6.73287 16.1867 6.23329 15.3333ZM7.39996 8.66667H6.23329C6.72568 7.81591 7.40012 7.08456 8.20829 6.525C7.85875 7.20604 7.58758 7.92452 7.39996 8.66667V8.66667ZM11.1666 18.4167C10.1431 17.6656 9.42425 16.571 9.14163 15.3333H11.1666V18.4167ZM11.1666 13.6667H8.78329C8.62778 12.561 8.62778 11.439 8.78329 10.3333H11.1666V13.6667ZM11.1666 8.66667H9.14163C9.42425 7.42898 10.1431 6.33444 11.1666 5.58334V8.66667ZM17.7666 8.66667H16.6C16.4044 7.92347 16.1248 7.20495 15.7666 6.525C16.5839 7.08173 17.267 7.81329 17.7666 8.66667ZM12.8333 5.58334C13.8568 6.33444 14.5757 7.42898 14.8583 8.66667H12.8333V5.58334ZM12.8333 18.4167V15.3333H14.8583C14.5757 16.571 13.8568 17.6656 12.8333 18.4167V18.4167ZM15.2166 13.6667H12.8333V10.3333H15.2166C15.3721 11.439 15.3721 12.561 15.2166 13.6667ZM15.7916 17.475C16.1498 16.7951 16.4294 16.0765 16.625 15.3333H17.7916C17.292 16.1867 16.6089 16.9183 15.7916 17.475ZM18.45 13.6667H16.9C16.9677 13.1137 17.0011 12.5571 17 12C17.0009 11.4429 16.9675 10.8863 16.9 10.3333H18.45C18.7389 11.4257 18.7389 12.5743 18.45 13.6667Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgUrl);
