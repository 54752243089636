import { canUseDOM } from 'exenv';

export enum Portals {
  before = 'portal-before-root',
  after = 'portal-after-root'
}

export default (portal: Portals) => {
  if (!canUseDOM) {
    return null;
  }

  return document.getElementById(portal);
};
