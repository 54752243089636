export const isContentful = (url) => {
  if (!url || typeof url !== 'string') {
    return false;
  }

  const matches = /(?:\w+\.)+\w+/.exec(url);

  if (matches && matches.length) {
    return matches[0]?.toLowerCase() === 'images.ctfassets.net';
  }

  return false;
};

export const resizeContentfulImage = (url, options: any = {}) => {
  if (!url || typeof url !== 'string') {
    return url;
  }

  let params = [];
  if (options.width) {
    params.push(`w=${options.width}`);
  }

  if (options.height) {
    params.push(`h=${options.height}`);
  }

  if (!params.length) {
    return url;
  }

  const paramString = params.join('&');

  if (url.indexOf('?') > -1) {
    return `${url}&${paramString}`;
  }

  return `${url}?${paramString}`;
};
