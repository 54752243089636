export const NOT_LISTED_HERE = 'Not listed here';

export const defaultOptions = [
  {
    label: 'An individual',
    value: 'Individual'
  },
  {
    label: 'On behalf of an organisation',
    value: 'Organisation'
  }
];

export const genders = (t: any) => [
  t('community-engagement-profile-gender-option-1'),
  t('community-engagement-profile-gender-option-2'),
  t('community-engagement-profile-gender-option-3'),
  t('community-engagement-profile-gender-option-4'),
  t('community-engagement-profile-gender-option-5'),
  t('community-engagement-profile-input-language-not-listed')
];

export const languages = (t: any) => [
  t('community-engagement-profile-language-option-1'),
  t('community-engagement-profile-language-option-2'),
  t('community-engagement-profile-language-option-3'),
  t('community-engagement-profile-language-option-4'),
  t('community-engagement-profile-language-option-5'),
  t('community-engagement-profile-language-option-6'),
  t('community-engagement-profile-language-option-7'),
  t('community-engagement-profile-language-option-8'),
  t('community-engagement-profile-language-option-9'),
  t('community-engagement-profile-language-option-10'),
  t('community-engagement-profile-language-option-11'),
  t('community-engagement-profile-input-language-not-listed')
];

export const organisationTypes = (t: any) => [
  t('community-engagement-profile-organisation-type-option-1'),
  t('community-engagement-profile-organisation-type-option-2'),
  t('community-engagement-profile-organisation-type-option-3'),
  t('community-engagement-profile-organisation-type-option-4'),
  t('community-engagement-profile-organisation-type-option-5'),
  t('community-engagement-profile-organisation-type-option-6'),
  t('community-engagement-profile-organisation-type-option-7'),
  t('community-engagement-profile-organisation-type-option-other')
];

export const relationshipList = (t: any) => [
  {
    type: t('community-engagement-profile-relationships-option-1'),
    value: t('community-engagement-profile-relationships-option-1')
  },
  {
    type: t('community-engagement-profile-relationships-option-2'),
    value: t('community-engagement-profile-relationships-option-2')
  },
  {
    type: t('community-engagement-profile-relationships-option-3'),
    value: t('community-engagement-profile-relationships-option-3')
  },
  {
    type: t('community-engagement-profile-relationships-option-4'),
    value: t('community-engagement-profile-relationships-option-4')
  },
  {
    type: t('community-engagement-profile-relationships-option-5'),
    value: t('community-engagement-profile-relationships-option-5')
  },
  {
    type: t('community-engagement-profile-relationships-option-6'),
    value: t('community-engagement-profile-relationships-option-6')
  },
  {
    type: NOT_LISTED_HERE,
    value: t('community-engagement-profile-input-language-not-listed')
  }
];

export const errors = (t: any) => {
  return {
    email: t('community-engagement-profile-email-error-message'),
    relationship: t('community-engagement-profile-email-error-relationship'),
    suburb: t('community-engagement-profile-suburb-error-message'),
    birthYear: t('community-engagement-profile-birth-year-error-message'),
    gender: t('community-engagement-profile-dropdown-error-message'),
    genderOther: t('community-engagement-profile-gender-other-error-message'),
    relationshipsOther: t('community-engagement-profile-relationships-other-error-message'),
    languageSpokenAtHome: t('community-engagement-profile-dropdown-error-message'),
    languageSpokenAtHomeOther: t('community-engagement-profile-language-other-error-message'),
    organisationName: t('community-engagement-profile-organisation-name-error-message'),
    organisationType: t('community-engagement-profile-dropdown-error-message'),
    role: t('community-engagement-profile-role-error-message'),
    country: t('community-engagement-profile-overseas-country-error-message'),
    addressLine1: t('community-engagement-profile-overseas-address-error-message'),
    zipOrPostalCode: t('community-engagement-profile-overseas-postcode-error-message')
  };
};

export interface individualObj {
  id: string;
  type: string;
  email: string;
  suburb: string;
  outsideOfAustralia: boolean;
  relationships: string[];
  relationshipsOther?: string;
  gender: string;
  birthYear: string;
  languageSpokenAtHome: string;
  languageSpokenAtHomeOther?: string;
  receiveMonthlyNewsletter: boolean;
}

export interface organisationObj {
  id: string;
  type: string;
  email: string;
  suburb: string;
  zipOrPostalCode: string;
  stateOrProvince: string;
  country: string;
  outsideOfAustralia: boolean;
  organisationName: string;
  organisationABNorACN: string;
  organisationType: string;
  role: string;
  phone: string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  receiveMonthlyNewsletter: boolean;
}

export const individualObject = {
  id: '',
  type: '',
  email: '',
  suburb: '',
  outsideOfAustralia: false,
  relationships: [],
  relationshipsOther: '',
  gender: '',
  genderOther: '',
  birthYear: '',
  languageSpokenAtHome: '',
  languageSpokenAtHomeOther: '',
  receiveMonthlyNewsletter: false
};

export const organisationObject = {
  id: '',
  type: '',
  email: '',
  suburb: '',
  zipOrPostalCode: '',
  stateOrProvince: '',
  country: '',
  outsideOfAustralia: true,
  organisationName: '',
  organisationABNorACN: '',
  organisationType: '',
  role: '',
  phone: '',
  address: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  receiveMonthlyNewsletter: false
};

export interface simpleTabProps {
  handleChange: any;
  selectedValue: string;
  item: any;
}

export interface tabProps {
  item: any;
  selectedValue: string;
  handleChange: any;
  handleClickNext: any;
}

export interface orgProps {
  email: string;
  organisationName: string;
  role: string;
  organisationABNorACN: string;
  phone: string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  organisationType: string;
  errMsg: any;
  handleChange: any;
  stateOrProvince: string;
  zipOrPostalCode: string;
  city?: string;
  country: string;
  isOutsideOfAustralia: boolean;
  setConsultation: React.Dispatch<React.SetStateAction<itemState>>;
  handleGetAddressFinderData?: (address: AddressFinderData) => void;
}

export interface individualProps {
  email: string;
  suburb: string;
  city?: string;
  relationships: any;
  birthYear: string;
  languageSpokenAtHome: string;
  languageSpokenAtHomeOther: string;
  errMsg: any;
  handleChange: any;
  relationshipsOther?: string;
  gender: string;
  genderOther: string;
  isOutsideOfAustralia: boolean;
  addressLine1: string;
  stateOrProvince: string;
  zipOrPostalCode: string;
  country: string;
  handleRelationship: any;
  setConsultation: React.Dispatch<React.SetStateAction<itemState>>;
}

export interface consultationProps {
  handleChange: any;
  item: any;
  handleDialog: any;
  handleUpdate: any;
  type: string;
  surveyId?: string;
}

export interface AddressFinderData {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  suburb: string;
  zipOrPostalCode: string;
  stateOrProvince: string;
  country: string;
  // country: any
}
export interface itemState {
  id: string;
  type: string;
  email?: string;
  relationships?: any;
  birthYear?: string;
  gender?: string;
  genderOther?: string;
  suburb?: string;
  city?: string;
  organisationName?: string;
  organisationType?: string;
  role?: string;
  organisationABNorACN?: string;
  address?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  phone?: string;
  outsideOfAustralia?: boolean;
  relationshipsOther?: string;
  languageSpokenAtHome?: string;
  languageSpokenAtHomeOther?: string;
  receiveMonthlyNewsletter?: boolean;
  country?: string;
  zipOrPostalCode?: string;
  stateOrProvince?: string;
  defaultProfile?: boolean;
}

export interface questionProps {
  id?: string;
  className?: string;
  title?: string;
  value?: string;
  haveNode?: boolean;
  nodeTitle?: string;
  errMsg?: string;
  type?: string;
  handleChange?: any;
  isSelect?: boolean;
  placeHolder?: string;
  isOptional?: boolean;
  disabled?: boolean;
  questionInputTittleStyle?: boolean;
  questionInputTitleStyleCapable?: boolean;
}
