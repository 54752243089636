import { BackgroundColors } from '_utils/components/Corporate/Section';
import { spacing } from '_utils/props';
import { color, mq } from '_utils/styles';
import { handleSectionTheme } from '_utils/styles/section';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

// INFO: Card Wrapper
export const ServiceRequestCardWrapper = styled.div`
  width: 100%;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
  ${({ theme }) => spacing({ theme, mb: 4 })};
  background-color: ${({ isGray }) => (isGray ? color('all.deepBlueTint07') : color('all.white'))};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .card-title {
      color: ${color('all.greenTint110')};
      text-decoration: underline;
    }
  }

  &:focus-within {
    border: 3px solid #4060FF;
  }

  a:focus {
    outline: none;
  }
`;

export const CardSection = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => spacing({ theme, p: 3 })};
  ${() => handleSectionTheme(BackgroundColors['white'])};
`;

// INFO: Main Information
const ReferenceText = styled.div`
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${color('all.deepBlueTint75')};
  text-transform: uppercase;
  ${({ theme }) => spacing({ theme, mb: 1 })};
`;

export const TitleWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => spacing({ theme, mb: 1 })};

  span {
    ${({ theme }) => spacing({ theme, mr: 1 })};
  }

  ${mq('md')} {
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const Title = styled(NavLink)`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-right: 13px;
  color: ${color('all.deepBlue')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${mq('md')} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const StyledDot = css`
  &:after {
    content: '.';
    font-weight: bold;
    position: absolute;
    top: -5px;
    left: -13.5px;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;

  & > .request-type-card__description:first-child {
    ${({ theme }) => spacing({ theme, mb: 1 })};
  }

  & > .request-type-card__description:not(:first-child) {
    position: relative;
    ${({ theme }) => spacing({ theme, mb: 1 })};

    ${mq('lg')} {
      ${StyledDot}
    }
  }

  ${mq('lg')} {
    flex-direction: row;
    & > .request-type-card__description {
      ${({ theme }) => spacing({ theme, mr: 3 })};
    }
  }
`;

const SubmissionTime = styled.span`
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${color('all.deepBlueTint75')};
`;

export const MainInformation = {
  ReferenceText,
  Title,
  Address,
  SubmissionTime
};

// INFO: Sub-items Information
export const SubItemWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  ${({ theme }) => spacing({ theme, mt: 2 })};
`;

export const SubItem = styled.div<{ status: string }>`
  ${({ status }) => css`
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border-radius: 8px;
    ${({ theme }) => spacing({ theme, p: 2 })};
    &:not(:last-child) {
      ${({ theme }) => spacing({ theme, mb: 2 })};
    }

    ${mq('sm')} {
      flex-direction: row;
    }
  `}
`;

export const SubItemTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  ${mq('md')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const SubItemDescription = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;

  ${mq('md')} {
    flex-direction: ${({ isSubItemImg }) => (isSubItemImg ? 'column' : 'row')};
  }

  & > .request-type-card__description:first-child {
    ${({ theme }) => spacing({ theme, mr: 3, mt: 1 })};
  }

  & > .request-type-card__description:not(:first-child) {
    position: relative;
    ${({ theme }) => spacing({ theme, mr: 3, mt: 1 })};

    ${mq('md')} {
      ${({ isSubItemImg }) => (isSubItemImg ? `` : StyledDot)};
    }
  }
`;

export const SubItemImage = styled.img`
  width: 88px;
  height: 88px;
  flex: 0 0 88px;
  border-radius: 50%;
  ${({ theme }) => spacing({ theme, mb: 2, mr: 2 })};

  ${mq('md')} {
    width: 128px;
    height: 128px;
    flex: 0 0 128px;
    ${({ theme }) => spacing({ theme, mb: 0, mr: 2 })};
  }
`;

// Subtype description item
export const SubTypeDescription = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

export const SubTypeUpdatedTime = styled.span`
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1 })};

  ${mq('md')} {
    text-align: right;
  }
`;

export const SubTypeContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => spacing({ theme, mt: 2 })};

  ${mq('md')} {
    align-items: flex-end;
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }
`;
