import { warnInDev } from '../helpers/dev';
import { isContentful } from '../helpers/image';

export const convertSrcJss = (src, width = null) => {
  if (!src) {
    return '';
  }

  let pattern = /^(.*\/-\/)media(\/[^?]*)?.*$/gim;
  let matches = pattern.exec(src);

  if (isContentful(src)) {
    return `${src + (width ? `?w=${width}` : '')}`;
  }

  if (matches && matches.length > 2) {
    return `${matches[1]}jssmedia${matches[2] + (width ? `?mw=${width}` : '')}`;
  }
  return src;
};

export const createSrcSet = (src, widths) => {
  let jssSrc = convertSrcJss(src);
  if (!jssSrc) {
    warnInDev(`Could not get jssSrc for ${src}`);
    return '';
  }
  if (isContentful(src)) {
    return widths.map((width) => `${jssSrc}?w=${width} ${width}w`).join(', ');
  }
  return widths.map((width) => `${jssSrc}?mw=${width} ${width}w`).join(', ');
};
