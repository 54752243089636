import React from 'react';
import { useTranslation } from 'react-i18next';
import { IShowMoreProps } from './definitions';
import { Count, ShowMoreButton, RefreshIcon, TextInfo, Wrapper } from './StyledShowMore';
import SvgRefresh from '_utils/icons/common/Refresh';

const ShowMore: React.FC<IShowMoreProps> = ({
  labelTransKeys,
  numbers,
  isLoading,
  handleClickShowMore,
  className
}) => {
  const [t] = useTranslation();

  const isVisibleButton = Number(numbers.showingNumber) < Number(numbers.totalNumber);

  return (
    <Wrapper className={className}>
      <TextInfo role='alert'>
        {t(labelTransKeys.showLabel)} <Count>{numbers.showingNumber}</Count>{' '}
        {t(labelTransKeys.ofLabel)} <Count>{numbers.totalNumber}</Count>{' '}
        {t(labelTransKeys.itemLabel)}
      </TextInfo>
      {isVisibleButton && (
        <ShowMoreButton
          name="show-more-button"
          value="load-more-items"
          role="button"
          onClick={handleClickShowMore}
        >
          {isLoading && (
            <RefreshIcon>
              <SvgRefresh />
            </RefreshIcon>
          )}
          {t(labelTransKeys.showMoreLabel)}
        </ShowMoreButton>
      )}
    </Wrapper>
  );
};

export default ShowMore;
