import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { canUseDOM } from 'exenv';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { withMenuContext } from '_containers/MenuContext';
import { withUserDropdownContext } from '_containers/UserDropdownContext';
import { withUserProfileContext } from '_containers/UserProfileContext';
import asyncComponent from '_containers/AsyncComponent';
import MainMenu from '_components/Corporate/MainMenu';
import { getPortal, Portals } from '_utils/helpers';
import { HeaderProps } from './definitions';

import MainSearch from './MainSearch';
import MenuButton from './MenuButton';
import UserButton from './UserButton';
import SessionExpiredPopup from './SessionExpiredPopup';
import UserProfileDropdown from '../UserProfileDropdownIframe';
import UserDropdownProvider from '_containers/UserDropdownContext';
import UserProfileProvider from '_containers/UserProfileContext';
// import styled
import {
  HeaderContainer,
  HiddenWrapper,
  HomeButton,
  HomeButtonLogo,
  HomeButtonTitle,
  HomeButtonTitleLogo,
  SearchBar,
  SkipContainer,
  SkipLink,
  MenuContainer,
  DimContent,
  MenuActiveBlockInteraction
} from './StyledHeader';

// import icons
const AsyncLogo: any = asyncComponent(() =>
  import(/* webpackChunkName: "Logo" */ '_utils/icons/Logo')
);
const AsyncLockup: any = asyncComponent(() =>
  import(/* webpackChunkName: "Lockup" */ '_utils/icons/Lockup')
);

const pageHasHomepageBanner = (sitecoreContext): boolean => {
  if (!sitecoreContext) {
    return false;
  }

  const placeholders = sitecoreContext?.route?.placeholders?.['jss-main'];

  if (!placeholders || !Array.isArray(placeholders)) {
    return false;
  }

  return Boolean(placeholders.find(({ componentName }) => componentName === 'HomepageBanner'));
};

export const PageHeaderWithLoginContext = React.createContext(null);

const Header: React.FC<HeaderProps> = ({
  fields,
  sitecoreContext,
  menuActive,
  setMenuActive,
  userProfile,
  userDropdownActive,
  setUserDropdownActive
}) => {
  const { isAuthenticated } = { ...sitecoreContext };
  const [t] = useTranslation();
  const portalRef = useRef<HTMLElement>(null);
  const [skipActive, setSkipActive] = useState(false);
  const [beforeRootSpace, setBeforeRootSpace] = useState(0);
  const [isAbsoluteSearch, setIsAbsoluteSearch] = useState<boolean>();

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    if (portalRef.current === null) {
      portalRef.current = getPortal(Portals.before);
    }

    setBeforeRootSpace(portalRef.current?.getBoundingClientRect().height);
  }, [menuActive]);

  const contextValue = {
    isAbsoluteSearch,
    setIsAbsoluteSearch
  };

  return (
    <PageHeaderWithLoginContext.Provider value={contextValue}>
      <SkipContainer className={skipActive ? 'skipActive' : ''}>
        <SkipLink
          href="#main-content"
          onClick={() => {
            try {
              let mainContent = document.getElementById('main-content');
              mainContent.focus();
            } catch (err) {
              console.warn('SkipToContent: could not element with id main-content');
            }
          }}
          onFocus={() => {
            if (window) {
              window.scrollTo(0, 0);
            }
            setSkipActive(true);
          }}
          onBlur={() => setSkipActive(false)}
        >
          {t('page-header-skip-to-content-label')}
        </SkipLink>
      </SkipContainer>
      <HeaderContainer
        onKeyDown={(e) => {
          // ESCAPE
          if (e.keyCode === 27) {
            if (menuActive) setMenuActive(false);
            if (userDropdownActive) setUserDropdownActive(false);
          }
        }}
      >
        <HomeButton to="/" aria-label={t('page-header-home-link-title')}>
          <HomeButtonLogo>
            <AsyncLogo ariaHidden={true} fill={null} />
          </HomeButtonLogo>
        </HomeButton>
        <HomeButtonTitle to="/" aria-label={t('page-header-home-link-title')}>
          <HomeButtonTitleLogo>
            <AsyncLockup ariaHidden={true} />
          </HomeButtonTitleLogo>
        </HomeButtonTitle>
        {/* search bar */}
        <SearchBar>
          {!pageHasHomepageBanner(sitecoreContext) && (
            <MainSearch
              isLoggedIn={userProfile.canTrust ? userProfile.isAuthenticated : isAuthenticated}
            />
          )}
        </SearchBar>
        {/* user button */}
        <HiddenWrapper className={isAbsoluteSearch ? 'header-hidden' : ''}>
          <UserButton
            userDisplayName={
              userProfile.canTrust ? userProfile.userDisplayName : sitecoreContext.userDisplayName
            }
            isAuthenticated={userProfile.canTrust ? userProfile.isAuthenticated : isAuthenticated}
          />
        </HiddenWrapper>
        {/* menu button */}
        <HiddenWrapper className={isAbsoluteSearch ? 'header-hidden' : ''}>
          <MenuButton />
        </HiddenWrapper>
        {/* menu dropdown */}
        {menuActive && (
          <MenuActiveBlockInteraction
            className={menuActive ? 'menuActive' : ''}
            onClick={() => setMenuActive(false)}
          />
        )}
        <MenuContainer beforeRootSpace={beforeRootSpace} className={skipActive ? 'skipActive' : ''}>
          {menuActive && <DimContent className={menuActive ? 'menuActive' : ''} />}
          <MainMenu fields={fields} />
        </MenuContainer>
        {/* user dropdown */}
        {userDropdownActive && (
          <MenuActiveBlockInteraction
            className={userDropdownActive ? 'userDropdownActive' : ''}
            onClick={() => setUserDropdownActive(false)}
          />
        )}
        <MenuContainer beforeRootSpace={beforeRootSpace} className={skipActive ? 'skipActive' : ''}>
          {userDropdownActive && <DimContent className={userDropdownActive ? 'menuActive' : ''} />}
          <UserProfileDropdown sitecoreContext={sitecoreContext} fields={fields} />
        </MenuContainer>
      </HeaderContainer>
      {/* warning session expired pop-up */}
      {(userProfile.canTrust ? userProfile.isAuthenticated : isAuthenticated) && (
        <SessionExpiredPopup
          silentSignInUrl={
            userProfile.canTrust ? userProfile.silentSignInUrl : sitecoreContext.silentSignInUrl
          }
          signOutUrl={userProfile.canTrust ? userProfile.signOutUrl : sitecoreContext.signOutUrl}
          sessionExpiredUrl={
            userProfile.canTrust ? userProfile.sessionExpiredUrl : sitecoreContext.sessionExpiredUrl
          }
        />
      )}
    </PageHeaderWithLoginContext.Provider>
  );
};

const HeaderWrapped = compose(
  withSitecoreContext(),
  withMenuContext,
  withUserProfileContext,
  withUserDropdownContext
)(Header);

const PageHeaderWithLogin: React.FC = (props) => {
  return (
    <UserProfileProvider>
      <UserDropdownProvider>
        <HeaderWrapped {...props} />
      </UserDropdownProvider>
    </UserProfileProvider>
  );
};

export default PageHeaderWithLogin;
// withSitecoreContext() doesn't work in storybook, so export PageHeaderWithLoginStorybook for storybook
export const PageHeaderWithLoginStorybook = compose(
  withMenuContext,
  withUserProfileContext,
  withUserDropdownContext
)(Header);
