import React from 'react';
import { withSvg } from '../Svg';

const SvgMail = () => (
  <path
    d="M14.1667 9.16671H15C15.2211 9.16671 15.433 9.07891 15.5893 8.92263C15.7456 8.76635 15.8334 8.55439 15.8334 8.33337V7.50004C15.8334 7.27903 15.7456 7.06707 15.5893 6.91079C15.433 6.7545 15.2211 6.66671 15 6.66671H14.1667C13.9457 6.66671 13.7337 6.7545 13.5775 6.91079C13.4212 7.06707 13.3334 7.27903 13.3334 7.50004V8.33337C13.3334 8.55439 13.4212 8.76635 13.5775 8.92263C13.7337 9.07891 13.9457 9.16671 14.1667 9.16671ZM5.00004 10H9.16671C9.38772 10 9.59968 9.91224 9.75596 9.75596C9.91224 9.59968 10 9.38772 10 9.16671C10 8.94569 9.91224 8.73373 9.75596 8.57745C9.59968 8.42117 9.38772 8.33337 9.16671 8.33337H5.00004C4.77903 8.33337 4.56707 8.42117 4.41079 8.57745C4.25451 8.73373 4.16671 8.94569 4.16671 9.16671C4.16671 9.38772 4.25451 9.59968 4.41079 9.75596C4.56707 9.91224 4.77903 10 5.00004 10ZM18.3334 3.33337H1.66671C1.44569 3.33337 1.23373 3.42117 1.07745 3.57745C0.921171 3.73373 0.833374 3.94569 0.833374 4.16671V15.8334C0.833374 16.0544 0.921171 16.2663 1.07745 16.4226C1.23373 16.5789 1.44569 16.6667 1.66671 16.6667H18.3334C18.5544 16.6667 18.7664 16.5789 18.9226 16.4226C19.0789 16.2663 19.1667 16.0544 19.1667 15.8334V4.16671C19.1667 3.94569 19.0789 3.73373 18.9226 3.57745C18.7664 3.42117 18.5544 3.33337 18.3334 3.33337ZM17.5 15H2.50004V5.00004H17.5V15ZM5.00004 13.3334H9.16671C9.38772 13.3334 9.59968 13.2456 9.75596 13.0893C9.91224 12.933 10 12.7211 10 12.5C10 12.279 9.91224 12.0671 9.75596 11.9108C9.59968 11.7545 9.38772 11.6667 9.16671 11.6667H5.00004C4.77903 11.6667 4.56707 11.7545 4.41079 11.9108C4.25451 12.0671 4.16671 12.279 4.16671 12.5C4.16671 12.7211 4.25451 12.933 4.41079 13.0893C4.56707 13.2456 4.77903 13.3334 5.00004 13.3334Z"
    fill="#041C2C"
  />
);

export default withSvg(20, 20)(SvgMail);
