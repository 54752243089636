import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
  svg,
  buttonReset,
  inputReset,
  text_m,
  mq,
  hideOnPrint,
  iconColorOnPrint
} from '_utils/styles';
import { Container as HomepageBannerContainer } from '_components/Corporate/HomepageBanner/StyledHomepageBanner';

export const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.form.input.background};
  border-radius: 8px;
  display: flex;
  position: relative;
  border: 2px solid transparent;
  align-items: center;
  height: 100%;

  &.search--focused {
    border: 2px solid ${({ theme }) => theme.colors.search.focus};
  }
  ${hideOnPrint}

  ${HomepageBannerContainer} & {
    border: 4px solid ${({ theme }) => theme.colors.element.secondary};

    &.search--focused {
      border: 4px solid ${({ theme }) => theme.colors.search.focus};
    }
  }
`;

export const Input = styled.input`
  ${inputReset};
  ${text_m}
  margin: 0;
  flex-grow: 1;
  outline: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;

  ${({ theme }) => spacing({ theme, pl: 2, pr: '50px' })}

  ${HomepageBannerContainer} & {
    ${({ theme }) => spacing({ theme, pr: '58px' })}

    ${mq('md')} {
      ${({ theme }) => spacing({ theme, pr: '64px' })}
      font-size: 22px;
    }
  }
`;

export const Submit = styled.button`
  ${buttonReset}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.search.submit.background};

  position: absolute;
  top: -2px;
  right: -2px;
  width: 42px;
  height: calc(100% + 4px);

  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  outline: 0;

  ${HomepageBannerContainer} & {
    top: 0;
    right: 0;
    height: 100%;
    width: 46px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;

    ${mq('md')} {
      width: 54px;
    }
  }

  /* when input has focus */
  :hover,
  .search--focused & {
    background-color: ${({ theme }) => theme.colors.search.focus};
  }

  /* when button has focus */
  body:not(.safe-focus-removal) &:focus {
    &:before {
      content: '';
      position: absolute;
      left: -4px;
      top: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  body:not(.safe-focus-removal) ${HomepageBannerContainer} &:focus {
    &:before {
      top: -8px;
      width: calc(100% + 12px);
      height: calc(100% + 16px);
    }
  }
`;

export const Icon = styled.span`
  ${svg(17, 17)}
  width: 17px;

  margin: 0 auto;

  /* ie11 cant do margin auto in flex */
  @media all and (-ms-high-contrast: none) {
    margin: 0;
  }

  ${HomepageBannerContainer} & {
    ${mq('md')} {
      width: 22px;
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.element.primary};
  }

  ${Submit}:hover &,
  .search--focused & {
    path {
      fill: ${({ theme }) => theme.colors.element.white};
      ${iconColorOnPrint}
    }
  }
`;
