import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RequestTrackerWrapper,
  RequestTrackerHeader,
  RequestTitle,
  RequestTrackerContent,
  RequestDescription,
  RequestTrackerHeaderLeft,
  RequestTrackerHeaderRight,
  RequestUpdatedTime,
  ActivityDescription,
  Divider,
  WhatYouNeedToDoDescription,
  WhatYouNeedToDoTitle,
  WhatYouNeedToDo,
  ListActionContainer,
  ActionContainer,
  CallToAction,
  PastActivityWrapper,
  PastActivityToggle,
  Icon,
  PastActivityListItems,
  PastActivityItem,
  PastActivityDescription,
  PastActivityStatus,
  PastActivityUpdatedTime,
  ActivityTitle,
  PastActivityStatusWrapper,
  DashTimeline,
  PastActivityItemWrap,
  SvgWrapper
} from './StyledRequestTrackerCard';
import Metadata, { MetadataThemeConfig } from '_utils/components/CityConnect/Metadata';
import StatusTag, { STATUS_CONFIGS } from '_utils/components/CityConnect/StatusTag';
import {
  DownloadSection,
  DownloadWrapper,
  DownloadIcon,
  DownloadFileName,
  DownloadDescription
} from '../WhatYouNeedToDoCard/StyledWhatYouNeedToDoCard';
import SvgDownload from '_utils/icons/Download';
import SvgPDF from '_utils/icons/common/PDF';
import SvgArrowDown from '_utils/icons/ArrowDown';
import SvgDeclined from '_utils/icons/common/Declined';
import { ACTION_LIST } from '../../Registration';
import { TAction } from '../definitions';
import { TRANSLATION_KEYS_SET } from '..';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import { byteConverter, dateConverter } from '_utils/helpers';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

type RequestTrackerCardProps = {
  data: any;
};

const RequestTrackerCard: React.FC<RequestTrackerCardProps> = ({ data }) => {
  const [t] = useTranslation();
  const { title, metadata, history } = data;
  const mainData = history[0];
  const status = mainData?.status?.toLowerCase();
  const pastActivity = history.filter((val, index) => index > 0);

  const [isShowPastActivity, setIsShowPastActivity] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dialogCallbackUrl, setDialogCallbackUrl] = useState<string>();

  const confirmAction = () => {
    setIsOpenDialog(false);
    window.open(dialogCallbackUrl, '_self');
  };

  const renderMetadata = () => {
    return (
      metadata &&
      metadata.map((item, index) => (
        <Metadata
          key={index}
          metadataType={item.type}
          text={item.value}
          metadataTheme={MetadataThemeConfig.Default}
          className="request-tracker-metadata"
        />
      ))
    );
  };

  const handleClickAction = (action: TAction) => {
    if (action.type === ACTION_LIST.cancelApplication) {
      setIsOpenDialog(true);
      setDialogCallbackUrl(action.link);
    } else if (action.type === ACTION_LIST.redirectExternalLink) {
      window.open(action.link, '_blank');
    } else {
      window.open(action.link, '_self');
    }
  };

  const renderCallToAction = (actions: TAction[], isCenter?: boolean) => {
    return actions?.length > 0 ? (
      <ListActionContainer className={isCenter ? 'center' : ''}>
        {actions.map((action: TAction, index: number) => (
          <ActionContainer key={index} className="row">
            <CallToAction
              isExternal={action.type === ACTION_LIST.redirectExternalLink}
              onClick={() => handleClickAction(action)}
              buttonType={action.type === ACTION_LIST.cancelApplication ? 'outline' : 'primary'}
            >
              {action.type === ACTION_LIST.cancelApplication && <SvgDeclined />}
              {action.text}
              {action.type === ACTION_LIST.redirectExternalLink && (
                <SvgWrapper>
                  <SvgExternal />
                </SvgWrapper>
              )}
            </CallToAction>
          </ActionContainer>
        ))}
      </ListActionContainer>
    ) : (
      <></>
    );
  };

  return (
    <RequestTrackerWrapper>
      {/* Request Tracker Card Header  */}
      <RequestTrackerHeader>
        <RequestTrackerHeaderLeft>
          <RequestTitle>{title}</RequestTitle>
          <RequestDescription>{renderMetadata()}</RequestDescription>
        </RequestTrackerHeaderLeft>
        {mainData && (
          <RequestTrackerHeaderRight>
            <div className="request-tracker-status-tag">
              <StatusTag
                text={mainData.status.toLowerCase()}
                displayStatusText={mainData.displayStatus}
              />
            </div>
            <RequestUpdatedTime>{mainData.lastUpdated}</RequestUpdatedTime>
          </RequestTrackerHeaderRight>
        )}
      </RequestTrackerHeader>
      {/* Request Tracker Card Content  */}
      {mainData && (
        <RequestTrackerContent>
          {/* Request Tracker Content Title */}
          {mainData.title && <ActivityDescription>{mainData.title}</ActivityDescription>}
          {mainData.title &&
            (mainData.documents?.length ||
              mainData.subtitle ||
              mainData.description ||
              mainData.action?.link ||
              pastActivity.length > 0) && <Divider />}
          {/* Request Tracker Content What you need to do  */}
          {(mainData.documents?.length > 0 ||
            mainData.subtitle ||
            mainData.description ||
            mainData.actions?.length > 0) && (
            <WhatYouNeedToDo>
              {mainData.subtitle && (
                <WhatYouNeedToDoTitle
                  dangerouslySetInnerHTML={{ __html: mainData.subtitle }}
                  haveDescription={Boolean(mainData.description)}
                />
              )}
              {mainData.description && (
                <WhatYouNeedToDoDescription
                  dangerouslySetInnerHTML={{ __html: mainData.description }}
                  haveActions={Boolean(
                    mainData.actions?.length > 0 || mainData.documents?.length > 0
                  )}
                />
              )}
              {/* Request Tracker Content Call to Action  */}
              {renderCallToAction(mainData.actions)}
              {/* Download documents */}
              {mainData.documents?.length > 0 && (
                <DownloadSection className={mainData.actions?.length > 0 ? 'haveActions' : null}>
                  {mainData.documents?.map((doc, index) => {
                    const { size, unit } = byteConverter(doc.fileSize);
                    const convertedDate = dateConverter(doc.lastModified);
                    return (
                      <DownloadWrapper key={index}>
                        <DownloadIcon>
                          <SvgPDF />
                        </DownloadIcon>
                        <div>
                          <DownloadFileName
                            className="request-tracker-card-document-name"
                            href={doc.url}
                          >
                            {doc.text}
                            <SvgDownload />
                          </DownloadFileName>
                          <DownloadDescription>
                            {doc.type.toUpperCase()} • {`${size}${unit.ShortForm}`} •{' '}
                            {t('request-tracker-last-updated')} {convertedDate?.visualDate}
                          </DownloadDescription>
                        </div>
                      </DownloadWrapper>
                    );
                  })}
                </DownloadSection>
              )}
            </WhatYouNeedToDo>
          )}
          {/* Show Hide previous update */}
          {pastActivity.length > 0 && (
            <PastActivityWrapper className={isShowPastActivity ? 'open' : ''}>
              <PastActivityToggle
                className={isShowPastActivity ? 'open' : ''}
                onClick={() => {
                  setIsShowPastActivity(!isShowPastActivity);
                }}
              >
                {isShowPastActivity
                  ? t('request-tracker-hide-previous-updates')
                  : t('request-tracker-show-previous-updates')}
                <Icon>
                  <SvgArrowDown />
                </Icon>
              </PastActivityToggle>
              {isShowPastActivity && (
                <PastActivityListItems>
                  {pastActivity.map((act, index) => (
                    <PastActivityItem key={index}>
                      <PastActivityItemWrap>
                        <PastActivityDescription>
                          <DashTimeline />
                          <ActivityTitle
                            isMarginBottom={act.actions?.length > 0 || act.documents?.length > 0}
                          >
                            {act.title}
                          </ActivityTitle>
                          {/* Past Activities Call to Action  */}
                          {renderCallToAction(act.actions, true)}
                          {/* Download documents */}
                          {act.documents?.length > 0 && (
                            <DownloadSection
                              className={act.actions?.length > 0 ? 'haveActions' : null}
                            >
                              {act.documents.map((doc, index) => {
                                const { size, unit } = byteConverter(doc.fileSize);
                                const convertedDate = dateConverter(doc.lastModified);
                                return (
                                  <DownloadWrapper key={index}>
                                    <DownloadIcon className="activity-download-icon">
                                      <SvgPDF />
                                    </DownloadIcon>
                                    <div>
                                      <DownloadFileName
                                        className="request-tracker-card-document-name"
                                        href={doc.url}
                                      >
                                        {doc.text}
                                        <SvgDownload />
                                      </DownloadFileName>
                                      <DownloadDescription>
                                        {doc.type.toUpperCase()} • {`${size}${unit.ShortForm}`} •{' '}
                                        {t('request-tracker-last-updated')}{' '}
                                        {convertedDate?.visualDate}
                                      </DownloadDescription>
                                    </div>
                                  </DownloadWrapper>
                                );
                              })}
                            </DownloadSection>
                          )}
                        </PastActivityDescription>
                        <PastActivityStatus>
                          <DashTimeline />
                          <PastActivityStatusWrapper>
                            <StatusTag
                              text={act.status.toLowerCase()}
                              displayStatusText={act.displayStatus}
                            />
                            <PastActivityUpdatedTime>{act.lastUpdated}</PastActivityUpdatedTime>
                          </PastActivityStatusWrapper>
                          <DashTimeline />
                        </PastActivityStatus>
                      </PastActivityItemWrap>
                    </PastActivityItem>
                  ))}
                </PastActivityListItems>
              )}
            </PastActivityWrapper>
          )}
          {/* confirm action dialogue */}
          <DialogueBox
            translationKeys={TRANSLATION_KEYS_SET}
            type="ban"
            actionCallbackFn={confirmAction}
            cancelCallbackFn={() => setIsOpenDialog(false)}
            isOpen={isOpenDialog}
          />
        </RequestTrackerContent>
      )}
    </RequestTrackerWrapper>
  );
};

export default React.memo(RequestTrackerCard);
