import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import { heading_m, mq, text_m, depth, button, color, svg } from '_utils/styles';

// INFO: Dialogue Box

export const DialogueBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${color('all.deepBlue')};
  z-index: ${depth.MODAL};
  opacity: 0.6;
  overflow-x: hidden;
`;

const DIALOGUE_SM_DOWN = 327;
const DIALOGUE_MD_UP = 608;

export const DialogueContainer = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${depth.MODAL + 1};
  border-radius: 8px;
  background-color: ${color('all.white')};
  width: ${DIALOGUE_SM_DOWN}px;
  left: calc((100% - ${DIALOGUE_SM_DOWN}px) / 2);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);

  ${mq('md')} {
    width: ${DIALOGUE_MD_UP}px;
    left: calc((100% - ${DIALOGUE_MD_UP}px) / 2);
  }
`;

export const DialogueBody = styled.div`
  position: relative;
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

export const IconContainer = styled.span`
  ${svg(20, 20)}
  width: 20px;
  position: absolute;
  ${mq('md')} {
    ${svg(34, 34)};
    width: 34px;
    position: absolute;
  }
`;

export const Content = styled.div`
  margin-left: calc(48px - 16px);

  ${mq('md')} {
    margin-left: calc(80px - 24px);
  }
`;

export const Title = styled.div`
  ${heading_m}
`;

export const Description = styled.div`
  ${text_m};
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};
  overflow-y: auto;
  max-height: 168px;

  ${mq('md')} {
    max-height: 264px;
  }
`;

export const DialogueFooter = styled.div`
  display: flex;
  border-top: 1px solid ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, p: 3 })};

  ${mq('md')} {
    padding-left: 72px;
  }
`;

const FooterButton = css`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const CancelButton = styled.button`
  ${button('default', 'outline')};
  ${FooterButton};
`;

export const ApplyButton = styled.button`
  ${button('default', 'primary')};
  margin-left: ${props => (!props.isOnlyOneButton ? 0 : 24)}px;
  ${FooterButton};
`;
