import React from 'react';
import {withSvg} from "../Svg";
import PropTypes from 'prop-types'

const SvgForm = (props) => (
    <>
        <g clipPath="url(#clip0_4809_80162)">
            <path d="M17.5001 10.0003C17.2791 10.0003 17.0671 10.0881 16.9108 10.2444C16.7545 10.4006 16.6667 10.6126 16.6667 10.8336V15.8336C16.6667 16.0546 16.579 16.2666 16.4227 16.4229C16.2664 16.5791 16.0544 16.6669 15.8334 16.6669H4.16675C3.94573 16.6669 3.73377 16.5791 3.57749 16.4229C3.42121 16.2666 3.33341 16.0546 3.33341 15.8336V4.16694C3.33341 3.94593 3.42121 3.73397 3.57749 3.57769C3.73377 3.42141 3.94573 3.33361 4.16675 3.33361H9.16675C9.38776 3.33361 9.59972 3.24581 9.756 3.08953C9.91228 2.93325 10.0001 2.72129 10.0001 2.50027C10.0001 2.27926 9.91228 2.0673 9.756 1.91102C9.59972 1.75474 9.38776 1.66694 9.16675 1.66694H4.16675C3.50371 1.66694 2.86782 1.93033 2.39898 2.39917C1.93014 2.86802 1.66675 3.5039 1.66675 4.16694V15.8336C1.66675 16.4966 1.93014 17.1325 2.39898 17.6014C2.86782 18.0702 3.50371 18.3336 4.16675 18.3336H15.8334C16.4965 18.3336 17.1323 18.0702 17.6012 17.6014C18.07 17.1325 18.3334 16.4966 18.3334 15.8336V10.8336C18.3334 10.6126 18.2456 10.4006 18.0893 10.2444C17.9331 10.0881 17.7211 10.0003 17.5001 10.0003ZM5.00008 10.6336V14.1669C5.00008 14.388 5.08788 14.5999 5.24416 14.7562C5.40044 14.9125 5.6124 15.0003 5.83342 15.0003H9.36675C9.47642 15.0009 9.58514 14.9799 9.68667 14.9384C9.7882 14.8969 9.88055 14.8358 9.95842 14.7586L15.7251 8.98361L18.0917 6.66694C18.1699 6.58947 18.2319 6.4973 18.2742 6.39576C18.3165 6.29421 18.3382 6.18528 18.3382 6.07527C18.3382 5.96526 18.3165 5.85634 18.2742 5.75479C18.2319 5.65324 18.1699 5.56108 18.0917 5.48361L14.5584 1.90861C14.4809 1.8305 14.3888 1.76851 14.2872 1.7262C14.1857 1.68389 14.0768 1.66211 13.9667 1.66211C13.8567 1.66211 13.7478 1.68389 13.6463 1.7262C13.5447 1.76851 13.4526 1.8305 13.3751 1.90861L11.0251 4.26694L5.24175 10.0419C5.16451 10.1198 5.10341 10.2122 5.06194 10.3137C5.02047 10.4152 4.99945 10.5239 5.00008 10.6336ZM13.9667 3.67527L16.3251 6.03361L15.1417 7.21694L12.7834 4.85861L13.9667 3.67527ZM6.66675 10.9753L11.6084 6.03361L13.9667 8.39194L9.02508 13.3336H6.66675V10.9753Z"
                  fill={props.color || "#4E5A5F"}/>
        </g>
        <defs>
            <clipPath id="clip0_4809_80162">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </>
);

SvgForm.prototype = {
    color: PropTypes.string
}
export default withSvg(20, 20)(SvgForm);