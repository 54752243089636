import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withEditMode } from '_containers/BaseComponent';
import Grid from '_utils/components/Corporate/Grid';
import { Section } from '_utils/components/Corporate';
import { BackgroundColors } from '_utils/components/Corporate/Section';

import { Primary } from './StyledSingleColumn';

const SingleColumn = ({ rendering, params, editMode }) => {
  // Default
  let backgroundColor = BackgroundColors.White;

  const isNarrow = Boolean(rendering?.params?.isNarrow !== '0' && rendering?.params?.isNarrow);

  const primaryConfig = {
    gutters: ['left', 'right'],
    ...(isNarrow && { col: { md: 8 }, offset: { md: 2 }, push: { md: 2 } })
  };

  try {
    backgroundColor = BackgroundColors[params.backgroundColour];
  } catch {}

  const children = rendering?.placeholders?.['jss-single-column'] || [];

  const isEmpty = !editMode && children.length === 0;

  return (
    <Section className={isEmpty ? 'is-empty' : ''} sectionTheme={backgroundColor}>
      <Grid.Container>
        <Grid.Row justifyContent="center" config={{ gutters: ['left', 'right'] }}>
          <Primary config={primaryConfig}>
            <Placeholder name="jss-single-column" rendering={rendering} />
          </Primary>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export default withEditMode(SingleColumn);
