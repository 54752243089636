import React, { useContext, useEffect, useRef, useState } from 'react';
import { SvgChevronLeft } from '_utils/icons/DashboardTabs/ChevronLeft';
import { SvgChevronRight } from '_utils/icons/DashboardTabs/ChevronRight';
import DashboardTabNav from '_components/CityConnect/DashboardTabs/DashboardTabsHeader/DashboardTabNav';
import { DashboardTabsContext } from '..';
import { SERVICE_REQUESTS_TAB } from '../constant';
import { IDashboardTabsHeaderProps, ITabApi, ITabNav, ITabNavExtend } from './definitions';
import {
  Container,
  ScrollButtonLeft,
  ScrollButtonRight,
  WrapperInSide,
  WrapperOutSide
} from './styledDashboardTabsHeader';

import { getDashboardTabsNavData } from '_services/dashboardTabsService';

const TABNAV_XS = 224;
const TABNAV_MD = 284;

const DashboardTabsHeader: React.FC<IDashboardTabsHeaderProps> = ({ fields }) => {
  const [moveToRight, setMoveToRight] = useState(0);
  const [widthWrapperOutSide, setWidtWrapperOutSide] = useState(0);
  const [hideScrollButtonRight, setHideScrollButtonRight] = useState(false);
  const [hideScrollButtonLeft, setHideScrollButtonLeft] = useState(true);
  const [step, setStep] = useState(TABNAV_XS);
  const [tabsData, setTabsData] = useState<ITabApi[]>();
  const [error, setError] = useState(null);

  const activeTab = useContext(DashboardTabsContext)?.activeTab || SERVICE_REQUESTS_TAB;

  const wrapperOutSideRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { tabs } = fields?.data?.datasource;

  const handleWindowsResize = () => {
    const width = wrapperOutSideRef?.current?.offsetWidth;

    setMoveToRight(0);
    setWidtWrapperOutSide(width);
    setHideScrollButtonLeft(true);
    setHideScrollButtonRight(false);

    if (window.innerWidth >= 768) {
      setStep(TABNAV_MD);
    } else {
      setStep(TABNAV_XS);
    }

    if (width >= len * TABNAV_MD) {
      setHideScrollButtonLeft(true);
      setHideScrollButtonRight(true);
    }
  };

  useEffect(() => {
    handleWindowsResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowsResize);

    return () => window.removeEventListener('resize', handleWindowsResize);
  }, []);

  // call API to get data
  useEffect(() => {
    getDashboardTabsNavData(
      (data) => setTabsData((prevState) => data?.data?.tabs),
      (data) => setError((prevState) => data?.error)
    );
  }, []);

  const len = tabs?.length ?? 0;
  const widthWrapperInSide = step * len;
  const diff = widthWrapperInSide - (widthWrapperOutSide + moveToRight);
  const flag = diff / step;

  const handleClickScrollButtonRight = () => {
    if (!moveToRight && diff > step) {
      setMoveToRight((prevState) => prevState + (step * 5) / 6);
      setHideScrollButtonLeft(false);
    } else if (flag > 1.2) {
      setMoveToRight((prevState) => prevState + step);
    } else if (flag < 1.2) {
      setMoveToRight((prevState) => prevState + diff);
      setHideScrollButtonRight(true);
      setHideScrollButtonLeft(false);
    }
  };

  const handleClickScrollButtonLeft = () => {
    if (diff <= 0 && moveToRight > step) {
      setMoveToRight((prevState) => prevState - (step * 5) / 6);
      setHideScrollButtonRight(false);
    } else if (moveToRight >= step) {
      setMoveToRight((prevState) => prevState - step);
    } else if (moveToRight < step) {
      setMoveToRight(0);
      setHideScrollButtonLeft(true);
      setHideScrollButtonRight(false);
    }
  };

  const tabsExtend = tabs?.map((tab: ITabNav) => {
    return {
      ...tab,
      active: tab.tabName.value === activeTab,
      count: tabsData?.find((item: ITabApi) => item?.tabName === tab?.tabName?.value)?.total
    };
  });

  const updateScroll = (idx: number) => {
    if ((idx + 1) * step > moveToRight + widthWrapperOutSide) {
      handleClickScrollButtonRight();
    } else if (idx * step < moveToRight) {
      handleClickScrollButtonLeft();
    }
  };

  const renderDashboardTabNavs = (tabs: ITabNavExtend[]) => {
    return tabs?.map((tab: ITabNavExtend, idx) => {
      const { tabName, colour, icon, title, count, active } = tab;
      return (
        <DashboardTabNav
          className="tabNav"
          tabName={tabName.value}
          colour={colour.value}
          icon={icon.value}
          title={title.value}
          count={count}
          active={active}
          key={idx}
          tabIndex={idx}
          updateScroll={updateScroll}
        />
      );
    });
  };

  return (
    <Container>
      <WrapperOutSide
        ref={wrapperOutSideRef}
        hiddenLeft={hideScrollButtonLeft}
        hiddenRight={hideScrollButtonRight}
      >
        <WrapperInSide moveToRight={moveToRight} role='tablist'>
          {renderDashboardTabNavs(tabsExtend)}
        </WrapperInSide>
      </WrapperOutSide>
      <ScrollButtonLeft
        type="button"
        name="buttonLeft"
        role="button"
        aria-label="Previous"
        onClick={handleClickScrollButtonLeft}
        hidden={hideScrollButtonLeft}
      >
        <SvgChevronLeft />
      </ScrollButtonLeft>
      <ScrollButtonRight
        type="button"
        name="buttonRight"
        role="button"
        aria-label="Next"
        onClick={handleClickScrollButtonRight}
        hidden={hideScrollButtonRight}
      >
        <SvgChevronRight />
      </ScrollButtonRight>
    </Container>
  );
};

export default DashboardTabsHeader;
