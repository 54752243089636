import styled from 'styled-components';
import { spacing } from '_utils/props';
import { color, heading_m, mq, svg, text_m, text_s } from '_utils/styles';

// INFO: item styling
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  box-shadow: 0px 3px 12px 0px #00000012;
  ${({ theme }) => spacing({ theme, p: 3 })};
  background-color: ${color('all.white')};

  ${mq.lessThan('sm')} {
    height: auto;
  }

  &:focus {
    border: 3px solid #4060FF;
  }

  .italic {
    font-style: italic;
  }

  .removeStyle {
    background-color: transparent;
    border: none;
    padding: 0;
    ${({ theme }) => spacing({ theme, mt: 3 })};
  }
`;

export const CardTitle = styled.h2`
  ${heading_m};
`;

export const CardDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1 })};

  ${mq('md')} {
    line-height: 22px;
  }

  p {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`;

export const CardSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};
`;

export const DetailSection = styled.div``;

// INFO: Detail Items
const Wrapper = styled.div`
  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 3 })};
  }
  .description-block {
    display: flex;
    align-items: center;
  }
  .editable-block {
    display: flex;
    flex-direction: column;
    margin-top: -8px;

    &.postal-address {
      ${({ theme }) => spacing({ theme, mt: 2 })};
    }
  }
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  flex: 1;
  ${({ theme }) => spacing({ theme, mb: 1 })};
  display: flex;
  align-items: center;

  ${mq('md')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const HelpText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};

  ${mq('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Description = styled.div`
  flex: 1;
  ${text_m};
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, my: 0 })};
`;

const EditButton = styled.a`
  flex: 0 0 30px;
  cursor: pointer;
  text-decoration: underline;
  ${text_s};
  ${({ theme }) => spacing({ theme, ml: 3, my: 0, mr: 0 })};

  &:-webkit-any-link {
    color: inherit;
  }

  &:hover {
    color: ${color('all.greenTint110')};
  }
`;

const TextField = styled.textarea`
  background: #f9f9f9;
  border: 1px solid #dddee1;
  border-radius: 4px;
  padding: 10px 16px;
  height: 96px;
  resize: none;
  width: 100%;
  ${({ theme }) => spacing({ theme, my: 2 })};

  &:focus,
  &:active {
    border: 2px solid #2db84b;
  }

  &:disabled {
    background: #f0f1f2;
    border: 1px solid #dddee1;
    color: ${color('all.deepBlueTint45')};
  }

  &.error {
    background: #feedf3;
    color: ${color('all.pottsPointRedTint110')};
  }

  ${mq('md')} {
    height: 48px;
  }
`;

const ButtonGroups = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ToggleControl = styled.div`
  flex: 0 0 45px;

  ${mq('md')} {
    flex: 0 0 60px;
  }
`;

const ErrorMessage = styled.div`
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};
  color: ${color('all.pottsPointRedTint110')};
  display: flex;
  align-items: center;
`;

export const Icon = styled.span`
  ${svg(20, 20)}
  width: 20px;
  flex-shrink: 0;
  ${({ theme }) => spacing({ theme, mr: 1 })};
`;

export const DetailItem = {
  Wrapper,
  Text,
  HelpText,
  Description,
  EditButton,
  TextField,
  ButtonGroups,
  ToggleControl,
  ErrorMessage,
  Icon
};
