import React from 'react';
import { createPortal } from 'react-dom';
import { compose } from 'ramda';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { getPortal, Portals } from '_utils/helpers';
import { withEditMode } from '_containers/BaseComponent';

import { Wrapper, Link } from './StyledQuickClose';
import { useTranslation } from 'react-i18next';

const quickCloseWindow = (e) => {
  e.preventDefault();
  window.location.replace('https://www.google.com.au');
};

const QuickClose = (props) => {
  const [t] = useTranslation();
  const { sitecoreContext, editMode } = props;

  const sensitiveContent = sitecoreContext?.route?.fields?.containsSensitiveContent.value || null;
  const portal = getPortal(Portals.after);

  if (editMode || !sensitiveContent || portal === null) {
    return null;
  }

  return createPortal(
    <Wrapper>
      <Link href="#" onClick={quickCloseWindow}>
        {t('quick-close-label')}
      </Link>
    </Wrapper>,
    portal
  );
};

export default compose(withSitecoreContext(), withEditMode)(QuickClose);
