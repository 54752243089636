import React from 'react';
import { withSvg } from '../Svg';

const SvgFile = () => (
  <>
    <path
      d="M4.18359 11.3689H3.67989V10.6198H4.18359C4.29364 10.6198 4.38676 10.6526 4.45449 10.7181C4.52221 10.7837 4.56031 10.8726 4.56031 10.9943C4.56031 11.1161 4.52221 11.2097 4.45449 11.2752C4.38676 11.3408 4.29364 11.3689 4.18359 11.3689Z"
      fill="#B00020"
    />
    <path
      d="M6.99737 12.3801H6.54446V10.6198H6.99737C7.15398 10.6198 7.2852 10.6573 7.38678 10.7228C7.48837 10.793 7.56456 10.8913 7.61535 11.0178C7.66615 11.1488 7.69578 11.308 7.69578 11.5C7.69578 11.6966 7.66615 11.8558 7.61535 11.9822C7.56456 12.1133 7.48837 12.2116 7.38678 12.2771C7.2852 12.3473 7.15398 12.3801 6.99737 12.3801Z"
      fill="#B00020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6167 6.22496C13.6412 6.29799 13.658 6.3734 13.6667 6.44996V14.8333C13.6667 15.4963 13.4033 16.1322 12.9344 16.6011C12.4656 17.0699 11.8297 17.3333 11.1667 17.3333H2.83333C2.17029 17.3333 1.5344 17.0699 1.06556 16.6011C0.59672 16.1322 0.333328 15.4963 0.333328 14.8333V3.16663C0.333328 2.50358 0.59672 1.8677 1.06556 1.39886C1.5344 0.930018 2.17029 0.666626 2.83333 0.666626H7.87499C7.97185 0.678579 8.06534 0.709744 8.14999 0.758293C8.17487 0.754759 8.20012 0.754759 8.22499 0.758293C8.31068 0.79836 8.38944 0.851806 8.45833 0.916626L13.4583 5.91663C13.5231 5.98551 13.5766 6.06428 13.6167 6.14996V6.22496ZM10.825 5.66663L8.66666 3.50829V4.83329C8.66666 5.05431 8.75446 5.26627 8.91074 5.42255C9.06702 5.57883 9.27898 5.66663 9.49999 5.66663H10.825ZM11.7559 15.4225C11.9122 15.2663 12 15.0543 12 14.8333V14H1.99999V14.8333C1.99999 15.0543 2.08779 15.2663 2.24407 15.4225C2.40035 15.5788 2.61231 15.6666 2.83333 15.6666H11.1667C11.3877 15.6666 11.5996 15.5788 11.7559 15.4225ZM1.99999 8.99996V3.16663C1.99999 2.94561 2.08779 2.73365 2.24407 2.57737C2.40035 2.42109 2.61231 2.33329 2.83333 2.33329H6.99999V4.83329C6.99999 5.49633 7.26339 6.13222 7.73223 6.60106C8.20107 7.0699 8.83695 7.33329 9.49999 7.33329H12V8.99996H1.99999ZM4.24708 9.83329H2.83333V13.1666H3.67989V12.1554H4.24708C4.60263 12.1554 4.88623 12.0524 5.09364 11.8417C5.29681 11.631 5.40263 11.3501 5.40263 10.9943C5.40263 10.6432 5.29681 10.3623 5.09364 10.1516C4.88623 9.94097 4.60263 9.83329 4.24708 9.83329ZM7.0143 9.83329H5.6979V13.1666H7.0143C7.2725 13.1666 7.50107 13.1292 7.70001 13.0496C7.89895 12.97 8.06403 12.8576 8.19101 12.7078C8.318 12.558 8.41535 12.3848 8.47884 12.1788C8.54233 11.9728 8.5762 11.7481 8.5762 11.5C8.5762 11.2565 8.54233 11.0318 8.47884 10.8258C8.41535 10.6198 8.318 10.4466 8.19101 10.2968C8.06403 10.147 7.89895 10.0346 7.70001 9.95502C7.50107 9.87543 7.2725 9.83329 7.0143 9.83329ZM11.1667 9.83329V10.6666H9.71905V11.102H11.0735V11.8885H9.71905V13.1666H8.87249V9.83329H11.1667Z"
      fill="#B00020"
    />
  </>
);

export default withSvg(14, 17)(SvgFile);
