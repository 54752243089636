import React from 'react';
import { withSvg } from '../Svg';
import colors from '_containers/Theme/colors';

const Word = () => (
  <path
    fill={colors.pyrmontBlueTint110}
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 6.94C15.9896 6.84813 15.9695 6.75763 15.94 6.67V6.58C15.8919 6.47718 15.8278 6.38267 15.75 6.3L9.75 0.3C9.66734 0.222216 9.57282 0.158081 9.47 0.11C9.44015 0.10576 9.40985 0.10576 9.38 0.11C9.27841 0.0517412 9.16622 0.0143442 9.05 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V6.94ZM10 3.41L12.59 6H11C10.7348 6 10.4804 5.89464 10.2929 5.70711C10.1054 5.51957 10 5.26522 10 5V3.41ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V16H14V17ZM2 3V10H14V8H11C10.2044 8 9.44129 7.68393 8.87868 7.12132C8.31607 6.55871 8 5.79565 8 5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3ZM9.49558 12.1517L9.76696 11H11L9.86726 15H8.54572L8.28614 13.8146C8.15634 13.2416 8.06195 12.7584 8.00295 12.3539C7.93215 12.7753 7.83186 13.264 7.71386 13.8146L7.45428 15H6.13274L5 11H6.23304L6.50442 12.1517C6.66962 12.882 6.78761 13.4157 6.85841 13.7528C6.9587 13.1798 7.0649 12.6461 7.17109 12.1517L7.41888 11H8.58112L8.82891 12.1517C8.9351 12.6461 9.0354 13.1798 9.14159 13.7528C9.20649 13.4157 9.32448 12.882 9.49558 12.1517Z"
  />
);

export default withSvg(16, 20)(Word);
