import React, { useState, useEffect, useRef } from 'react';
import { withEditMode, withEditModeNotice } from '_containers/BaseComponent';
import { useTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  CameraWrapper,
  Caption,
  CaptionButton,
  CaptionButtonContainer,
  CaptionButtonInnerContainer,
  CaptionButtonTitle,
  CaptionContainer,
  Circle,
  Container,
  Credit,
  Description,
  Figure,
  Heading,
  Image,
  ImageColumn,
  IntroColumn,
  SearchContainer,
  TextContainer
} from './StyledHomepageBanner';
import MainSearch from '../MainSearch';
import { imageTransparentPlaceholderSrc, imagePlaceholderSrc } from '_utils/styles';

import { usePrevious } from '_utils/hooks';
import { convertSrcJss, createSrcSet } from '_utils/media';

import asyncComponent from '_containers/AsyncComponent';

const AsyncCamera: any = asyncComponent(() =>
  import(/* webpackChunkName: "Camera" */ '_utils/icons/Camera')
);

const EditNoticeText = withEditModeNotice(Text);

let isIE11 = false;

if (typeof window !== 'undefined') {
  isIE11 = !!window.MSInputMethodContext && !!(document as any).documentMode;
}

function HomepageBanner({ rendering, editMode }) {
  const { bannerTitle, bannerDescription, caption, credit, image, title, searchBoxText } =
    rendering?.fields || {};

  const [t] = useTranslation();

  const placeholder = searchBoxText?.value || t('homepage-banner-placeholder-text');
  const [captionActive, setCaptionActive] = useState(false);
  const prevCaptionActive = usePrevious(captionActive);
  const captionButtonRef = useRef();

  useEffect(() => {
    const clickListener = (e) => {
      setCaptionActive(false);
      if (e) {
        e.stopPropagation();
      }
    };

    if (captionActive && !prevCaptionActive) {
      document.body.addEventListener('click', clickListener);
    } else if (!captionActive && prevCaptionActive) {
      document.body.removeEventListener('click', clickListener);
    }

    return () => {
      document.body.removeEventListener('click', clickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captionActive]);

  return (
    <Container>
      <IntroColumn>
        <Circle>
          <img alt={image?.alt} src={imageTransparentPlaceholderSrc} />
        </Circle>
        <TextContainer>
          {bannerTitle && <Heading tag="h1" field={bannerTitle} />}
          <Description field={bannerDescription} />
          <SearchContainer>
            <MainSearch placeholder={placeholder} />
            {editMode && (
              <EditNoticeText
                noticeEnabled
                noticeLevel="info"
                noticeMessage={`Use this field to edit input placeholder text`}
                field={searchBoxText}
                tag="p"
              />
            )}
          </SearchContainer>
        </TextContainer>
      </IntroColumn>
      <ImageColumn>
        <Figure>
          {Boolean(editMode || image?.value) && (
            <Image
              className="lazyload"
              draggable={false}
              field={
                editMode
                  ? image
                  : {
                      value: {
                        src: imagePlaceholderSrc,
                        alt: image?.value?.alt || '',
                        ...(isIE11
                          ? { 'data-src': convertSrcJss(image.value.src) + '?mw=1200' }
                          : null),
                        'data-srcset': createSrcSet(image.value.src, [640, 1200, 1600])
                      }
                    }
              }
            />
          )}
          {Boolean(editMode || title?.value || caption?.value || credit?.value) && (
            <CaptionButtonContainer>
              <CaptionButton
                ref={captionButtonRef}
                onClick={(e) => {
                  if (!captionActive) {
                    setCaptionActive(true);
                  }
                }}
                aria-expanded={captionActive}
                aria-controls="homepage-banner-caption"
                aria-label={t('homepage-banner-accessible-caption-button-label')}
                disabled={!caption?.value && !credit?.value}
              >
                <CaptionButtonInnerContainer>
                  <CameraWrapper>
                    <AsyncCamera ariaHidden={true} />
                  </CameraWrapper>
                  {Boolean(editMode || title?.value) && (
                    <CaptionButtonTitle field={title} tag="span" />
                  )}
                </CaptionButtonInnerContainer>
              </CaptionButton>
            </CaptionButtonContainer>
          )}
          {Boolean(editMode || caption?.value || credit?.value) && (
            <CaptionContainer
              className={captionActive || editMode ? 'active' : ''}
              id="homepage-banner-caption"
            >
              {Boolean(editMode || caption?.value) && <Caption field={caption} />}
              {Boolean(editMode || credit?.value) && <Credit field={credit} tag="p" />}
            </CaptionContainer>
          )}
        </Figure>
      </ImageColumn>
    </Container>
  );
}

export default withEditMode(HomepageBanner);
