import React from 'react';
import { withSvg } from '../Svg';

const SvgDownload = () => (
  <path
    d="M12.8333 13.8333H1.16665C0.945633 13.8333 0.733671 13.9211 0.577391 14.0774C0.421111 14.2337 0.333313 14.4457 0.333313 14.6667C0.333313 14.8877 0.421111 15.0996 0.577391 15.2559C0.733671 15.4122 0.945633 15.5 1.16665 15.5H12.8333C13.0543 15.5 13.2663 15.4122 13.4226 15.2559C13.5788 15.0996 13.6666 14.8877 13.6666 14.6667C13.6666 14.4457 13.5788 14.2337 13.4226 14.0774C13.2663 13.9211 13.0543 13.8333 12.8333 13.8333ZM6.40831 11.925C6.48757 12.0009 6.58102 12.0603 6.68331 12.1C6.78306 12.1441 6.89092 12.1669 6.99998 12.1669C7.10904 12.1669 7.2169 12.1441 7.31665 12.1C7.41894 12.0603 7.51239 12.0009 7.59165 11.925L11.7583 7.75833C11.9152 7.60141 12.0034 7.38858 12.0034 7.16667C12.0034 6.94475 11.9152 6.73192 11.7583 6.575C11.6014 6.41808 11.3886 6.32992 11.1666 6.32992C10.9447 6.32992 10.7319 6.41808 10.575 6.575L7.83331 9.325V1.33333C7.83331 1.11232 7.74551 0.900358 7.58923 0.744078C7.43295 0.587797 7.22099 0.5 6.99998 0.5C6.77897 0.5 6.567 0.587797 6.41072 0.744078C6.25444 0.900358 6.16665 1.11232 6.16665 1.33333V9.325L3.42498 6.575C3.34728 6.4973 3.25504 6.43567 3.15352 6.39362C3.052 6.35157 2.9432 6.32992 2.83331 6.32992C2.72343 6.32992 2.61462 6.35157 2.51311 6.39362C2.41159 6.43567 2.31934 6.4973 2.24165 6.575C2.16395 6.6527 2.10231 6.74494 2.06026 6.84646C2.01821 6.94798 1.99657 7.05678 1.99657 7.16667C1.99657 7.27655 2.01821 7.38536 2.06026 7.48687C2.10231 7.58839 2.16395 7.68063 2.24165 7.75833L6.40831 11.925Z"
    fill="#188838"
  />
);

export default withSvg(14, 15)(SvgDownload);
