import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import {
  mq,
  svg,
  listReset,
  heading_m,
  heading_xs,
  text_s,
  text_xscap,
  buttonSize,
  buttonColors
} from '_utils/styles';
import { Link } from 'react-router-dom';

export const Container1 = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.element.white};
  background-color: ${({ theme }) => theme.colors.footer.container.background};
  ${({ theme }) => spacing({ theme, pb: 6 })};
  ${({ theme }) => spacing({ theme, pt: 2 })};
  @media print {
    display: none;
  }
`;

export const Container2 = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.element.white};
  background-color: ${({ theme }) => theme.colors.footer.container.background};
  ${({ theme }) => spacing({ theme, pb: { xs: 2, md: 0 } })};
  @media print {
    display: none;
  }
`;

export const Container3 = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.element.white};
  background-color: #0850a1;
  ${({ theme }) => spacing({ theme, py: 4 })};
  ${({ theme }) => spacing({ theme, px: 3 })};

  ${mq.lessThan('md')} {
    padding: 16px 20px;
  }

  @media print {
    display: none;
  }
`;

export const LogoWrapper = styled.span`
  ${svg(39, 40)}
  width: 80px;
  flex: none;
  margin: 0 auto;
  transform: translateY(-72px);
  path {
    fill: ${({ theme }) => theme.colors.element.secondary};
  }
  display: none;
  position: relative;

  ${mq('lg')} {
    display: inline-block;
  }
`;

export const LockupWrapper = styled.span`
  ${svg(249, 40)}
  width: 210px;
  flex: none;
  path {
    fill: ${({ theme }) => theme.colors.element.secondary};
  }

  ${mq('lg')} {
    display: none;
  }
`;

export const List = styled.ul`
  ${listReset}
`;

export const ListItem = styled.li`
  ${({ theme }) => spacing({ theme, my: 1 })};
  &:first-child {
    margin-top: 0;
  }
`;

export const TertiaryList = styled.ul`
  ${listReset}

  ${mq('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const TertiaryListItem = styled.li`
  ${({ theme }) => spacing({ theme, my: 1 })};

  ${mq('sm')} {
    ${({ theme }) => spacing({ theme, mx: 1 })};
    ${({ theme }) => spacing({ theme, my: 0 })};
    ${({ theme }) => spacing({ theme, py: '8px' })};
  }
`;

export const SocialListItem = styled.div`
  display: inline-block;
  width: 24px;

  & + & {
    margin-left: 12px;
  }
`;

const linkReset = css`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:visited {
    /* color: inherit; */
    text-decoration: none;
  }
`;

const headinglink = css`
  line-height: 1;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:not([href]) {
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;

export const PrimaryLink = styled(Link)`
  ${linkReset}
  ${heading_m}
  ${headinglink}
`;

export const SecondaryLink = styled(Link)`
  ${linkReset}
  ${text_s}
  ${headinglink}
`;

export const TertiaryLink = styled(Link)`
  ${linkReset}
  ${text_s}
  ${headinglink}
  color: ${({ theme }) => theme.colors.footer.tertiaryItem.color};

  &:not([href]) {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.footer.tertiaryItem.color};
    }
  }
`;

export const SocialLink = styled.a`
  ${linkReset}

  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 'get the latest', 'stay in touch' elems
export const SocialTitle = styled.div`
  ${text_xscap}
  color: ${({ theme }) => theme.colors.footer.tertiaryItem.color};
  margin: 0 0 8px;
`;

export const SubscribeLink = styled(Link)`
  ${linkReset}
  ${heading_xs}
  ${({ theme }) => buttonSize(theme.buttons.default)}
  ${({ theme }) => buttonColors(theme.colors.button.footerOutline)}
  ${({ theme }) => spacing({ theme, mb: 5 })}
  display: inline-block;
  color: white;
  border: 2px solid white;
  border-radius: 9999px;
  padding: 4px 16px;
`;

export const Divider = styled.hr`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.footer.divider};
  margin: 0;
  padding: 0;
  border: none;
`;

export const QuaternaryNavigation = styled.div`
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const QuaternaryNavigationDescription = styled.div`
  font-family: 'Helvetica Now Display', 'Helvetica Now Text', Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  ${mq.lessThan('md')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const quaternaryNavigationlink = css`
  ${text_s}
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  ${({ theme }) => spacing({ theme, pt: 1, m: 0 })};
`;

export const QuaternaryNavigationLink = styled(Link)`
  ${quaternaryNavigationlink}

  &:hover {
    text-decoration: none;
  }

  &:not([href]) {
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;

export const QuaternaryNavigationSpan = styled.div`
  ${quaternaryNavigationlink}
`;
