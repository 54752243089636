import React from 'react';
import {TimelineStageStatusType, StatusType, TimelineStatusType} from '_utils/helpers/statusType';
import {
  OpenForFeedback,
  UnderReview,
  InProgress,
  Completed,
  Planned,
  Closed,
  Book,
  OnExhibition,
  TimelineCompleted,
  TimelineInProgress,
  TimelineNotStarted,
  ReportingBack
} from '_utils/icons';
import SvgApproved from "../../../icons/StatusTag/Approved";

const icons = {
  [StatusType.book]: <Book />,
  [StatusType.underReview]: <UnderReview />,
  [StatusType.open]: <OpenForFeedback />,
  [StatusType.inProgress]: <InProgress />,
  [StatusType.planned]: <Planned />,
  [StatusType.closed]: <Closed />,
  [StatusType.completed]: <Completed />
};

const timelineStatusIcons = {
  [TimelineStatusType.underReview]: <UnderReview color={"#F05F00"} />,
  [TimelineStatusType.openForFeedback]: <OpenForFeedback color={"#188838"}/>,
  [TimelineStatusType.open]: <OpenForFeedback color={"#2DB84B"}/>,
  [TimelineStatusType.inProgress]: <InProgress color={"#B23914"}/>,
  [TimelineStatusType.planned]: <Planned color={"#B23914"}/>,
  [TimelineStatusType.closed]: <Closed color={"#DA1E5B"}/>,
  [TimelineStatusType.completed]: <SvgApproved color={"#188838"}/>,
  [TimelineStatusType.onExhibition]: <OnExhibition />,
  [TimelineStatusType.reportingBack]: <ReportingBack color={"#4BC2E4"}/>
};

const timelineStageStatusIcons = {
  [TimelineStageStatusType.completed]: <TimelineCompleted/>,
  [TimelineStageStatusType.inProgress]: <TimelineInProgress/>,
  [TimelineStageStatusType.notStarted]: <TimelineNotStarted/>,
}

export function StatusIcon({ status }) {
  if (icons[status]) {
    return icons[status];
  }

  return null;
}

export function TimelineIcon({ status }) {
  if (timelineStatusIcons[status]) {
    return timelineStatusIcons[status];
  }

  return null;
}

export function TimelineStageIcon({ status }) {
  if (timelineStageStatusIcons[status]) {
    return timelineStageStatusIcons[status];
  }

  return null;
}
