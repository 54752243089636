import React from 'react';
import {ProfileType} from "../../../helpers/profileType";
import SvgZone from "_utils/icons/Profile/Zone";
import SvgForm from "_utils/icons/Profile/Form";
import SvgInfo from "_utils/icons/Profile/Info";
import SvgBusiness from "_utils/icons/Profile/Business";
import SvgUser from "_utils/icons/Profile/User";
import SvgEmail from "_utils/icons/Profile/Email";
import SvgCalendar from "_utils/icons/Profile/Calendar";
import SvgGlobe from "_utils/icons/Profile/Globe";
import SvgPhone from "_utils/icons/Profile/Phone";
import SvgWorkBag from "_utils/icons/Profile/WorkBag";
import SvgPin from "_utils/icons/Profile/Pin";

const icons = {
    [ProfileType.email]: <SvgEmail />,
    [ProfileType.suburb]: <SvgPin/>,
    [ProfileType.relationships]: <SvgZone />,
    [ProfileType.gender]: <SvgUser />,
    [ProfileType.birthYear]: <SvgCalendar />,
    [ProfileType.languageSpokenAtHome]: <SvgGlobe />,
    [ProfileType.organisationName]: <SvgBusiness />,
    [ProfileType.organisationType]: <SvgForm />,
    [ProfileType.role]: <SvgWorkBag />,
    [ProfileType.organisationABNorACN]: <SvgInfo />,
    [ProfileType.phone]: <SvgPhone />,
    [ProfileType.address]: <SvgPin/>
};

export default function ProfileIcon({ icon }) {
    if (icons[icon]) {
        return icons[icon];
    }

    return null;
}
