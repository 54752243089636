import { get, post } from './genericService';

// SCHEDULED PAYMENTS
export function getScheduledPaymentsData(id, success, failed) {
  return get(
    '/api/v1/account/properties/' + (id || 'xxxx') + '/scheduled-payments',
    success,
    failed
  );
}

export function updateCardExpiryDate(link, data, success, failed, config = {}) {
  return post(link, data, success, failed, config);
}

export function cancelScheduledPayments(link, success, failed, config = {}) {
  return post(link, {}, success, failed, config);
}

export function getScheduledPaymentPage(link, success, failed, config = {}) {
  return get(link, success, failed, config);
}
