import { spacing } from '_utils/props';
import { color, mq, linkInText } from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

export const TextMessageWrapper = styled.div`
  background: #f9f9f9;
  border: 1px solid #9095A1;
  border-radius: 8px;
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

const NormalText = styled(RichText)`
  font-size: 16px;
  line-height: 24px;
  color: ${color('all.deepBlueTint75')};

  br {
    display: block;
    content: '';
    ${({ theme }) => spacing({ theme, mb: 2 })};
  }

  ${mq('md')} {
    font-size: 18px;
    line-height: 28px;

    br {
      display: block;
      ${({ theme }) => spacing({ theme, mb: 1 })};
      content: '';
    }
  }

  ${linkInText()}

  .secondary_link {
    font-size: 14px;
    line-height: 18px;

    ${mq('md')} {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .default_link {
    ${({ theme }) => spacing({ theme, ml: 1 })};
  }
`;

export const Text = {
  NormalText
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshWrapper = styled.span`
  margin-left: 8px;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.card.action};
  }
  svg {
    ${RotateCss};
    overflow: visible;
    ${mq('md')} {
      width: 24px;
    }
  }
`;
