import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';
import { button, buttonReset, color, focusOutline, heading_xs, linkWithoutVisited, mq, safeFocusRemoval, svg, text_m, text_s} from '_utils/styles';

const NUMBER_ITEMS_LARGE_VIEWPORT = 8;
const NUMBER_ITEMS_SMALL_VIEWPORT = 4;
const ITEMS_HEIGHT_LARGE_VIEWPORT = 44;
const ITEMS_HEIGHT_SMALL_VIEWPORT = 40;

// INFO: Detail Items
const Wrapper = styled.div`
  ${({ theme}) => spacing({ theme, mt: 4})}
  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 3 })};
  }
  .description-block {
    display: flex;
    align-items: center;
  }
  .editable-block {
    display: flex;
    flex-direction: column;
    ${({ theme }) => spacing({ theme, mt: 2 })};
  }
`;

export const Optional = styled.p`
  ${text_s};
  color: #4E5A5F;
  ${({ theme }) => spacing({ theme, m: 0 })};
  ${({ theme }) => spacing({ theme, ml: 1 })};
  
`

const Text = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  ${({ theme }) => spacing({ theme, mb: 1 })};
  ${mq('md')} {
  font-size: 18px;
  line-height: 24px;
  }
`;

const HelpText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};

  ${mq('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Description = styled.div`
  flex: 1;
  ${text_m};
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, my: 0 })};
`;

const EditButton = styled.a`
  flex: 0 0 30px;
  cursor: pointer;
  text-decoration: underline;
  ${text_s};
  ${({ theme }) => spacing({ theme, ml: 3, my: 0, mr: 0 })};

  &:-webkit-any-link {
    color: inherit;
  }

  &:hover {
    color: ${color('all.greenTint110')};
  }
`;

const TextField = styled.textarea`
  background: #f9f9f9;
  border: 1px solid #dddee1;
  border-radius: 4px;
  padding: 10px 16px;
  resize: none;
  width: 100%;
  outline: none;
  display: block;
  box-sizing: border-box;
  color: ${color('all.deepBlue')};
  ${text_m};
  ${({ theme }) => spacing({ theme, m: 0 })};  

  &:focus,
  &:active {
    border: 2px solid ${color('all.green')};
  }

  &:disabled {
    background: #f0f1f2;
    border: 1px solid #dddee1;
    color: ${color('all.deepBlueTint45')};
  }

  &::placeholder {
    font-style: italic;
    color: ${color('all.deepBlueTint75')};
  }

  &.error {
    background: #feedf3;
    color: ${color('all.pottsPointRedTint110')};
  }
`;

const TextFieldLabel = styled.div`
  ${text_m};
  ${({ theme }) => spacing({ theme, m: 0, mb: 1 })};
`;

const ErrorMessage = styled.div`
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};
  color: ${color('all.pottsPointRedTint110')};
  display: flex;
  align-items: center;
`;

export const Icon = styled.span`
  ${svg(20, 20)}
  width: 20px;
  flex-shrink: 0;
  ${({ theme }) => spacing({ theme, mr: 1 })};
`;

const TextFieldSubLabel = styled.p`
  ${text_s};
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, m: 0 })};
  ${mq('md')} {
    display: inline;
    ${({ theme }) => spacing({ theme, m: 0, ml: 1 })};
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshIcon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  margin-right: 10px;
  ${RotateCss}
  path {
    fill: ${color('all.white')};
  }
`;

export const LinkButton = styled.button`
  ${buttonReset};    
  box-shadow: inset 0 -2px ${color('all.green')};
  &:focus-visible {
    ${safeFocusRemoval}
    ${focusOutline}
  }
  &:hover {
    ${({ theme }) => linkWithoutVisited({ theme })};
  }
  ${text_m};
  ${({ theme }) => spacing({ theme, m: 0 })};
`;

const ButtonGroups = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ToggleControl = styled.div`
  flex: 0 0 45px;

  ${mq('md')} {
    flex: 0 0 60px;
  }
`;

export const DetailItem = {
  Wrapper,
  Text,
  HelpText,
  Description,
  EditButton,
  TextField,
  TextFieldLabel,
  ErrorMessage,
  Icon,
  TextFieldSubLabel,
  LinkButton,
  ButtonGroups,
  ToggleControl
};

export const ButtonUpdate = styled.button`
  ${button('default', 'primary')};
  ${safeFocusRemoval}
  ${heading_xs};
  ${({ theme }) => spacing({ theme, px: 2, py: 1 })};
  display: flex;
  align-items: center;
`;

export const ButtonCancel = styled.a`
  cursor: pointer;
  text-decoration: underline;
  ${text_s};
  ${({ theme }) => spacing({ theme, ml: 3, my: 0 })};

  &:hover {
    color: ${color('all.greenTint110')};
  }
`;

export const Label = styled.span`
  display: block;
`;

export const Title = styled.span`
  ${text_m}
  margin: 0;

  &.selected {
    font-weight: bold;
  }
`;

export const TextareaInput = styled.input`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    width: 100%;
    height: 48px;
    background: #F9F9F9;
    border: 1px solid #DDDEE1;
    border-radius: 4px;
`