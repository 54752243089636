import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import ServiceAnnouncement, {
  ANNOUNCEMENT_TYPES
} from '_components/CityConnect/ServiceAnnouncement';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import {
  getScheduledPaymentsData,
  cancelScheduledPayments,
  getScheduledPaymentPage
} from '_services/scheduledPaymentsService';
import TextMessage from '../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { compose } from 'ramda';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import icons
import SvgCalender from '_utils/icons/Calendar';
import SvgPaymentCard from '_utils/icons/ScheduledPayments/PaymentCard';
import SvgClock from '_utils/icons/metadata/Clock';
import SvgExpiry from '_utils/icons/ScheduledPayments/Expiry';
import SvgAlarmClock from '_utils/icons/ScheduledPayments/AlarmClock';
import SvgCalculator from '_utils/icons/ScheduledPayments/Calculator';
// import styles
import {
  Container,
  ContentWrapper,
  CreatePaymentDescription,
  CreatePaymentButton,
  CardTitle,
  ReferenceText,
  PaymentDetailsContainer,
  ItemContainer,
  ItemIconContainer,
  ItemIcon,
  ItemContent,
  ItemLabel,
  ItemValue,
  ValueRichText,
  CardBottom,
  BottomDivider,
  LinkButton,
  LinkList,
  SvgWrapper
} from './StyledScheduledPayments';
import {
  IPaymentDetailsProps,
  ScheduledPaymentsProps,
  PAYMENT_STATUSES
} from './definitions';
import { getRefreshToken } from '_services/genericService';
import SvgExternal from '_utils/icons/RequestTracker/External';

const IconList = {
  frequency: <SvgClock />,
  amount: <SvgPaymentCard />,
  term: <SvgAlarmClock />,
  numberOfPayments: <SvgCalculator />,
  startDate: <SvgCalender />,
  endDate: <SvgCalender />,
  cardExpiry: <SvgExpiry />,
  bank: <SvgExpiry />
};

const PaymentDetails: React.FC<IPaymentDetailsProps> = ({
  data,
  isEdit
}) => {
  const [t] = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const listItems = [
    {
      key: 'frequency',
      label: t('scheduled-payments-frequency'),
      value: data.frequency
    },
    {
      key: 'amount',
      label: t('scheduled-payments-amount'),
      value: data.amount
    },
    {
      key: 'term',
      label: t('scheduled-payments-term'),
      value: data.term
    },
    {
      key: 'numberOfPayments',
      label: t('scheduled-payments-number-of-payments'),
      value: data.numberOfPayments
    },
    {
      key: 'startDate',
      label: t('scheduled-payments-start-date'),
      value: data.startDate
    },
    {
      key: 'endDate',
      label: t('scheduled-payments-end-date'),
      value: data.endDate
    },
    {
      key: 'cardExpiry',
      label: t('scheduled-payments-card'),
      value: data.creditCard?.cardNumber ? `<span>${data.creditCard.cardNumber}</span>` : ''
    },
    {
      key: 'bank',
      label: t('scheduled-payments-bsb-and-account'),
      value:
        data.bank?.bsbNumber || data.bank?.accountNumber
          ? `${data.bank?.bsbNumber ? `<span>${data.bank?.bsbNumber}</span>` : ''}${data.bank
            ?.accountNumber || ''}`
          : ''
    }
  ];

  useEffect(() => {
    if (isEdit) {
      const cardExpiryElement = document.getElementById('cardExpiryId');
      if (cardExpiryElement) {
        cardExpiryElement.focus();
        if (!isFocused) setIsFocused(true);
      }
    }
  }, [isEdit]);

  return (
    <PaymentDetailsContainer config={{ gutters: [] }} role='list'>
      {listItems.map(
        (item, index) =>
          item.value && (
            <ItemContainer
              key={index}
              config={{ col: { xs: 12, sm: 6 }, gutters: [] }}
              index={index}
              role='listitem'
            >
              <ItemIconContainer>
                <ItemIcon>{IconList[item.key]}</ItemIcon>
              </ItemIconContainer>
              <ItemContent>
                <ItemLabel>{item.label}</ItemLabel>
                <ItemValue>
                  <ValueRichText field={{ value: item.value }} />
                </ItemValue>
              </ItemContent>
            </ItemContainer>
          )
      )}
    </PaymentDetailsContainer>
  );
};

const ScheduledPayments: React.FC<ScheduledPaymentsProps> = ({ sitecoreContext }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [cardData, setCardData] = useState(null);
  const [error, setError] = useState(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenCancelPaymentDialog, setIsOpenCancelPaymentDialog] = useState<boolean>(false);
  const [isOpenCreateSchedulePaymentError, setIsOpenCreateSchedulePaymentError] = useState<boolean>(false);
  const propertyId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const [currentPathname, setCurrentPathname] = useState<string>(location.pathname);

  useEffect(() => {
    getScheduledPayments();
    const routeListener = history.listen((location) => {
      setCurrentPathname(location.pathname);
    });

    return () => {
      routeListener();
    };
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // get card data
  const getScheduledPayments = () => {
    getScheduledPaymentsData(
      propertyId,
      (res) => setCardData(res?.data?.scheduledPayments),
      (res) => setError(res)
    );
  };

  const confirmCancelPayment = async () => {
    setIsOpenCancelPaymentDialog(false);
    const refreshToken = await getRefreshToken(
      currentPathname,
      sitecoreContext.language,
      history,
      currentPathname
    );
    if (cardData?.cancelLink) {
      cancelScheduledPayments(
        cardData.cancelLink,
        (res) => setCardData(res?.data?.scheduledPayments),
        (res) => setError(res),
        {
          headers: {
            requestVerificationToken: refreshToken
          }
        }
      );
    }
  };

  const confirmCreatePayment = async () => {
    const refreshToken = await getRefreshToken(
      currentPathname,
      sitecoreContext.language,
      history,
      currentPathname
    );
    fetch(cardData.createLink, {
      redirect: "manual",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        requestVerificationToken: refreshToken
      },
    }).then((res) => {
      if (res.type === "opaqueredirect") {
        // redirect to login page
        window.location.href = res.url;
      }
    }).catch(
      res => {
        console.error('error', res);
        setIsOpenCreateSchedulePaymentError(true);
      }
    );
  };

  const renderAnnouncement = (status: string) => {
    if (![PAYMENT_STATUSES.cancellationRequested].includes(status.toLocaleLowerCase()))
      return <></>;

    let title, description, type;
    switch (status.toLowerCase()) {
      case PAYMENT_STATUSES.cancellationRequested:
        title = 'scheduled-payments-cancellation-requested-message-heading';
        description = 'scheduled-payments-cancellation-requested-message-description';
        type = ANNOUNCEMENT_TYPES.Primary;
        break;
    }

    let announcementFields = {
      data: {
        datasource: {
          title: {
            value: t(title)
          },
          description: {
            value: t(description)
          },
          type: {
            value: type
          }
        }
      }
    };

    return <ServiceAnnouncement fields={announcementFields} />;
  };

  const renderCreatePaymentSchedule = () => {
    return (
      <>
        <CreatePaymentDescription>
          {t('scheduled-payments-create-scheduled-payments-instruction')}
        </CreatePaymentDescription>
        <ContentWrapper>
          <CreatePaymentButton onClick={() => confirmCreatePayment()}>
            {t('scheduled-payments-create-scheduled-payments')}
            {/*<SvgWrapper>*/}
            {/*  <SvgExternal />*/}
            {/*</SvgWrapper>*/}
          </CreatePaymentButton>
          <DialogueBox
            translationKeys={{
              title: 'scheduled-payments-currently-unavailable-title',
              description: 'scheduled-payments-currently-unavailable-description',
              apply: 'scheduled-payments-currently-unavailable-close-button'
            }}
            type="warning"
            actionCallbackFn={() => setIsOpenCreateSchedulePaymentError(false)}
            cancelCallbackFn={() => setIsOpenCreateSchedulePaymentError(false)}
            isOpen={isOpenCreateSchedulePaymentError}
          />
        </ContentWrapper>
      </>
    );
  };

  if (!cardData || Object.keys(cardData).length === 0) return <></>;

  return (
    <Container>
      <CardTitle>{t('scheduled-payments-heading')}</CardTitle>
      {(!cardData.status || !Object.values(PAYMENT_STATUSES).includes(cardData.status.toLowerCase())) ? (
        <>
          <ContentWrapper>{renderCreatePaymentSchedule()}</ContentWrapper>
          <BottomDivider />
        </>
      ) : (
          <>
            <ContentWrapper>{renderAnnouncement(cardData.status)}</ContentWrapper>
            <ReferenceText>
              {t('scheduled-payments-reference')} {cardData.referenceNumber}
            </ReferenceText>
            {cardData.status && (
              <ContentWrapper>
                <StatusTag text={cardData.status.toLowerCase()} />
              </ContentWrapper>
            )}
            <PaymentDetails
              data={cardData}
              isEdit={isEdit}
            />
            {(cardData.status?.toLowerCase() !== PAYMENT_STATUSES.cancellationRequested &&
              cardData.status?.toLowerCase() !== PAYMENT_STATUSES.expired) &&
              (cardData.editCardExpiry || cardData.cancelLink) && (
                <CardBottom>
                  <LinkList>
                    {cardData.cancelLink && (
                      <li>
                        <LinkButton onClick={() => setIsOpenCancelPaymentDialog(true)}>
                          {t('scheduled-payments-cancel-scheduled-payments')}
                        </LinkButton>
                        <DialogueBox
                          translationKeys={{
                            title: 'scheduled-payments-cancel-scheduled-payments-title',
                            description: 'scheduled-payments-cancel-scheduled-payments-description',
                            cancel: 'scheduled-payments-cancel-scheduled-payments-cancel-button',
                            apply: 'scheduled-payments-cancel-scheduled-payments-confirm-button'
                          }}
                          type="warning"
                          actionCallbackFn={confirmCancelPayment}
                          cancelCallbackFn={() => setIsOpenCancelPaymentDialog(false)}
                          isOpen={isOpenCancelPaymentDialog}
                        />
                      </li>
                    )}
                  </LinkList>
                </CardBottom>
              )}
            {/* cancellation requested */}
            {(cardData.status?.toLowerCase() === PAYMENT_STATUSES.cancellationRequested ||
              cardData.status?.toLowerCase() === PAYMENT_STATUSES.expired) && (
                <CardBottom>{renderCreatePaymentSchedule()}</CardBottom>
              )}
          </>
        )}
    </Container>
  );
};

const ScheduledPaymentsFallback: React.FC = () => {
  const [t] = useTranslation();

  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <Container>
      <CardTitle>{t('scheduled-payments-heading')}</CardTitle>
      <ContentWrapper>
        <TextMessage fields={errorMessage} textOnly={true} />
      </ContentWrapper>
    </Container>
  );
};

export default compose(withSitecoreContext())(
  withErrorBoundary(ScheduledPayments, ScheduledPaymentsFallback)
);

export const ScheduledPaymentsStorybook = withErrorBoundary(
  ScheduledPayments,
  ScheduledPaymentsFallback
);
