import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import withErrorBoundary from '_containers/ErrorBoundary';
import { ContainerPayment, Header, RichTextDescription } from './StyleRatesPaymentArrangement';
import { getRatesPaymentArrangementData } from '_services/ratesPaymentArrangement';

interface RatesPaymentObj {
  hasPaymentArrangement: boolean;
  financialHardshipDescription: string;
}

const RatesPaymentArrangement: React.FC = () => {
  const location = useLocation();
  const [t] = useTranslation();
  const [data, setData] = useState<RatesPaymentObj>(null);
  const [error, setError] = useState(null);
  const ratesPaymentArrangementId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    getRatesPaymentArrangement();
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const getRatesPaymentArrangement = () => {
    getRatesPaymentArrangementData(
      ratesPaymentArrangementId,
      (res) => setData(res?.data?.paymentArrangement),
      (res) => setError(res)
    );
  };

  return data?.hasPaymentArrangement ? (
    <ContainerPayment>
      <Header>{t('rates-payment-arrangement-heading')}</Header>
      {data && (
        <RichTextDescription
          field={{
            value: data?.financialHardshipDescription
          }}
        ></RichTextDescription>
      )}
    </ContainerPayment>
  ) : null;
};

const RatesPaymentsArrangementFallback: React.FC = () => {
  return <></>;
};

export default withErrorBoundary(RatesPaymentArrangement, RatesPaymentsArrangementFallback);
