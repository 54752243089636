import styled from 'styled-components';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import { section } from '_utils/styles';
import { spacing } from '_utils/props';
import Grid from '_utils/components/Corporate/Grid';

export const SectionWrapper = styled.section`
  ${({ theme, sectionTheme }) => section({ theme, sectionTheme })}

  &.is-empty {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const styleKey = isExperienceEditorActive() ? 'mb' : 'mt';

export const PlaceholderWrapper = styled(Grid.Item)`
  > * {
    ${({ theme, spacingUnit = 7 }) => spacing({ theme, [styleKey]: spacingUnit })}

    &.scEmptyPlaceholder,
    &:first-child {
      ${({ theme }) => spacing({ theme, [styleKey]: 0 })}
    }
  }

  .is-empty & {
    margin: 0 !important;
  }
`;
