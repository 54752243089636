import React from 'react';
import { withSvg } from '../Svg';

const SvgSortAmountDownAlt = () => (
  <path
    d="M12.7392 10H1.00438C0.739166 10 0.484812 10.1054 0.297276 10.2929C0.109739 10.4804 0.00438309 10.7348 0.00438309 11C0.00438309 11.2652 0.109739 11.5196 0.297276 11.7071C0.484812 11.8946 0.739166 12 1.00438 12H12.7392C13.0044 12 13.2587 11.8946 13.4463 11.7071C13.6338 11.5196 13.7392 11.2652 13.7392 11C13.7392 10.7348 13.6338 10.4804 13.4463 10.2929C13.2587 10.1054 13.0044 10 12.7392 10ZM1.00438 7H9.00438C9.26959 7 9.52395 6.89464 9.71148 6.70711C9.89902 6.51957 10.0044 6.26522 10.0044 6C10.0044 5.73478 9.89902 5.48043 9.71148 5.29289C9.52395 5.10536 9.26959 5 9.00438 5H1.00438C0.739166 5 0.484812 5.10536 0.297276 5.29289C0.109739 5.48043 0.00438309 5.73478 0.00438309 6C0.00438309 6.26522 0.109739 6.51957 0.297276 6.70711C0.484812 6.89464 0.739166 7 1.00438 7ZM1.00438 2H6.00438C6.2696 2 6.52395 1.89464 6.71148 1.70711C6.89902 1.51957 7.00438 1.26522 7.00438 1C7.00438 0.734783 6.89902 0.480429 6.71148 0.292892C6.52395 0.105356 6.2696 0 6.00438 0H1.00438C0.739166 0 0.484812 0.105356 0.297276 0.292892C0.109739 0.480429 0.00438309 0.734783 0.00438309 1C0.00438309 1.26522 0.109739 1.51957 0.297276 1.70711C0.484812 1.89464 0.739166 2 1.00438 2Z"
    fill="black"
  />
);

export default withSvg(14, 12)(SvgSortAmountDownAlt);
