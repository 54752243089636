import React from 'react';
import { withSvg } from './Svg';

const SvgCost = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4111 2.5H16.471C17.4086 2.5 18.1354 2.80014 18.6254 3.30916C19.091 3.79286 19.2625 4.38394 19.2625 4.83784V5.5C19.2625 6.05228 18.8136 6.5 18.2597 6.5C17.7059 6.5 17.257 6.05228 17.257 5.5V4.83784C17.257 4.83784 17.2568 4.83827 17.2563 4.83584C17.2533 4.82223 17.2388 4.75675 17.1786 4.69422C17.1338 4.64764 16.977 4.51093 16.5258 4.50062L5.48615 6.03811C5.32977 6.08706 5.07932 6.20669 4.87173 6.4075C4.6633 6.60911 4.50554 6.88054 4.50554 7.28396L4.50713 18.716C4.50713 18.716 4.50713 18.7197 4.50795 18.7272C4.50875 18.7347 4.51024 18.7449 4.51289 18.7574C4.51825 18.7828 4.52772 18.815 4.54343 18.8516C4.57432 18.9235 4.62856 19.0105 4.72226 19.0971C4.89727 19.2587 5.30293 19.4998 6.18744 19.4998H18.2613C18.4735 19.4998 18.8141 19.428 19.0728 19.2423C19.2804 19.0932 19.4945 18.836 19.4945 18.27V10.7295C19.4945 10.5179 19.4226 10.1782 19.2363 9.92021C19.0868 9.71318 18.8289 9.49976 18.2613 9.49976H6.63462C6.08081 9.49976 5.63185 9.05204 5.63185 8.49976C5.63185 7.94747 6.08081 7.49976 6.63462 7.49976H18.2613C19.4825 7.49976 20.3425 8.02958 20.8638 8.7516C21.3483 9.42264 21.5 10.1978 21.5 10.7295V18.27C21.5 19.4878 20.9687 20.3455 20.2447 20.8653C19.5718 21.3485 18.7944 21.4998 18.2613 21.4998H6.18744C4.92549 21.4998 3.9896 21.1462 3.35968 20.5646C2.74479 19.9968 2.50159 19.2815 2.50159 18.716L2.5 7.28396C2.5 5.18636 4.25152 4.28273 5.04829 4.08409L5.10943 4.06885L16.4111 2.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5404 15.2498C16.9557 15.2498 17.2924 14.914 17.2924 14.4998C17.2924 14.0855 16.9557 13.7498 16.5404 13.7498C16.125 13.7498 15.7883 14.0855 15.7883 14.4998C15.7883 14.914 16.125 15.2498 16.5404 15.2498ZM16.5404 16.4998C17.648 16.4998 18.5459 15.6043 18.5459 14.4998C18.5459 13.3952 17.648 12.4998 16.5404 12.4998C15.4327 12.4998 14.5348 13.3952 14.5348 14.4998C14.5348 15.6043 15.4327 16.4998 16.5404 16.4998Z"
    />
  </>
);

export default withSvg(24, 24)(SvgCost);
