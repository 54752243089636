import React from 'react';
import { withSvg } from '../Svg';

const SvgBookmark = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 2H6C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3V21C4.99999 21.1912 5.05477 21.3783 5.15785 21.5393C5.26094 21.7003 5.40801 21.8284 5.58164 21.9083C5.75527 21.9883 5.94819 22.0169 6.13754 21.9906C6.32688 21.9643 6.50473 21.8843 6.65 21.76L12 17.27L17.29 21.71C17.3834 21.8027 17.4943 21.876 17.6161 21.9258C17.7379 21.9755 17.8684 22.0008 18 22C18.1312 22.0034 18.2613 21.976 18.38 21.92C18.5626 21.845 18.7189 21.7176 18.8293 21.5539C18.9396 21.3901 18.999 21.1974 19 21V3C19 2.73478 18.8946 2.48043 18.7071 2.29289C18.5196 2.10536 18.2652 2 18 2V2ZM17 18.86L12.64 15.2C12.4603 15.0503 12.2339 14.9684 12 14.9684C11.7661 14.9684 11.5397 15.0503 11.36 15.2L7 18.86V4H17V18.86Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgBookmark);
