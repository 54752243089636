import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Grid } from '_utils/components/Corporate';
import WhatYouNeedToDoCard from './WhatYouNeedToDoCard';
import RequestTrackerCard from './RequestTrackerCard';
import {
  RequestTrackerListItems,
  ActionContainer,
  CallToAction,
  ButtonGroups,
  SvgWrapper
} from './RequestTrackerCard/StyledRequestTrackerCard';
import { getRequestTracker } from '_services/requestTrackerService';
import SvgDeclined from '_utils/icons/common/Declined';
import SvgExternal from '_utils/icons/PaymentsDueList/External';
import ProfileCardList from './ProfileCardList';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import withErrorBoundary from '_containers/ErrorBoundary';
import TextMessage from '../TextMessage';
import { ACTION_LIST } from '../Registration';
import { TAction } from './definitions';

export const TRANSLATION_KEYS_SET = {
  title: 'request-tracker-cancel-application-title',
  description: 'request-tracker-cancel-application-description',
  cancel: 'request-tracker-cancel-application-no',
  apply: 'request-tracker-cancel-application-yes'
};

const RequestTracker: React.FC = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dialogCallbackUrl, setDialogCallbackUrl] = useState<string>();
  const requestTrackerId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    getRequestTracker(
      requestTrackerId,
      (data) => setData(data.data),
      (err) => setError(err)
    );
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const renderRequestTrackerCard = (listItems: any[]) => {
    return (
      listItems && listItems.map((item, index) => <RequestTrackerCard key={index} data={item} />)
    );
  };

  const handleClickAction = (action: TAction) => {
    if (action.type === ACTION_LIST.cancelApplication) {
      setIsOpenDialog(true);
      setDialogCallbackUrl(action.link);
    } else if(action.type === ACTION_LIST.redirectExternalLink) {
      window.open(action.link, '_blank');
    } else {
      window.open(action.link, '_self');
    }
  };

  const confirmAction = () => {
    setIsOpenDialog(false);
    window.open(dialogCallbackUrl, '_self');
  };

  const renderCallToAction = () => {
    return (
      <>
        {data.serviceRequest.actions.map((action: TAction, index: number) => (
          <ActionContainer key={index}>
            <CallToAction
              isExternal={action.type === ACTION_LIST.redirectExternalLink}
              onClick={() => handleClickAction(action)}
              buttonType={action.type === ACTION_LIST.cancelApplication ? 'outline' : 'primary'}
            >
              {action.type === ACTION_LIST.cancelApplication && <SvgDeclined />}
              {action.text}
              {action.type === ACTION_LIST.redirectExternalLink && (
                <SvgWrapper>
                  <SvgExternal />
                </SvgWrapper>
              )}
            </CallToAction>
          </ActionContainer>
        ))}
        {data.serviceRequest.actions.find(
          (action: TAction) => action.type === ACTION_LIST.cancelApplication
        ) && (
          <DialogueBox
            translationKeys={TRANSLATION_KEYS_SET}
            type="ban"
            actionCallbackFn={confirmAction}
            cancelCallbackFn={() => setIsOpenDialog(false)}
            isOpen={isOpenDialog}
          />
        )}
      </>
    );
  };

  return data ? (
    <Grid.Row config={{ gutters: ['left', 'right'] }}>
      <Grid.Item
        config={{ col: { xs: 12, md: 8, lg: 8 }, gutters: ['left', 'right'] }}
        mb={{ xs: 2, md: 0 }}
      >
        {data && <WhatYouNeedToDoCard data={data?.serviceRequest?.callout} />}
      </Grid.Item>
      <Grid.Item config={{ col: { xs: 12, md: 4, lg: 4 }, gutters: ['left', 'right'] }}>
        {data && <ProfileCardList data={data?.serviceRequest?.help} />}
        {data?.serviceRequest?.actions?.length > 0 && (
          <ButtonGroups>{renderCallToAction()}</ButtonGroups>
        )}
      </Grid.Item>

      <Grid.Item config={{ col: { xs: 12 }, gutters: ['left', 'right'] }}>
        {data && (
          <RequestTrackerListItems>
            {renderRequestTrackerCard(data?.serviceRequest.items)}
          </RequestTrackerListItems>
        )}
      </Grid.Item>
    </Grid.Row>
  ) : null;
};

const RequestTrackerFallback: React.FC = ({}) => {
  const [t] = useTranslation();
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return <TextMessage fields={errorMessage} />;
};

export default withErrorBoundary(RequestTracker, RequestTrackerFallback);
