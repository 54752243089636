import React, { useEffect, useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import {
  Dialog,
  Layout,
  CloseContainer,
  ContentContainer,
  CloseButton,
  CrossWrapper
} from './StyledModal';
import { withModalContext, ModalContextProps } from '_containers/ModalContext';
import { enableBodyScroll, disableBodyScroll } from '_utils/helpers';
import { usePrevious } from '_utils/hooks';
import LargeCross from '_utils/icons/LargeCross';
import { useTranslation } from 'react-i18next';
import ScreenReader from '_utils/components/Corporate/ScreenReader';

type ModalProps = ModalContextProps & {};

const Modal: React.FC<ModalProps> = ({ modal }) => {
  const [t] = useTranslation();

  const { id, content, onClose, dismissable } = modal || {};

  const dialogRef = useRef();

  const contentContainerRef = useRef();

  const prevModalContent = usePrevious(Boolean(content));

  useEffect(
    () => {
      const dialog = dialogRef.current;

      const contentContainer = contentContainerRef.current;

      const clickOutsideListener = (e) => {
        let target: HTMLElement = e.target;

        // iterate through parents until we find menu dialog or no more
        while (target) {
          if (target === contentContainer) {
            // target is in contentContainer, bail
            return;
          }
          // move up to next parent
          target = target.parentNode as HTMLElement;
        }
        // focus outside, close menu
        onClose();
      };

      // add/remove listener when menu opened/closed
      if (content && !prevModalContent) {
        disableBodyScroll(dialog);
        if (dismissable) {
          document.body.addEventListener('mousedown', clickOutsideListener, true);
        }
        // without setImmediate effect clean up runs immediately
      } else if (!content && prevModalContent) {
        if (dismissable) {
          document.body.removeEventListener('mousedown', clickOutsideListener, true);
        }
        enableBodyScroll(dialog);
      }

      // clean up
      return () => {
        if (dismissable) {
          document.body.removeEventListener('mousedown', clickOutsideListener, true);
        }
        enableBodyScroll(dialog);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content]
  );

  if (!content) {
    return null;
  }

  return (
    <Dialog aria-modal={true} ref={dialogRef}>
      <FocusTrap>
        <Layout>
          <CloseContainer>
            <CloseButton onClick={() => onClose()}>
              <ScreenReader>{t('accessibility-close-modal')}</ScreenReader>
              <CrossWrapper>
                <LargeCross titleId={`modal-close-${id}`} ariaHidden={true} />
              </CrossWrapper>
            </CloseButton>
          </CloseContainer>
          <ContentContainer ref={contentContainerRef}>{content}</ContentContainer>
        </Layout>
      </FocusTrap>
    </Dialog>
  );
};

export default withModalContext(Modal);
