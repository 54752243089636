import React, { forwardRef } from 'react';
import { Container, Input, SliderRound, IconContainer, Icon } from './StyledToggle';
import SvgApproved from '_utils/icons/StatusTag/Approved';
import SvgRefresh from '_utils/icons/common/Refresh';

type ToggleProps = {
  id: string;
  name: string;
  label?: string;
  value: any;
  checked: boolean;
  isProcessing?: boolean;
  disabled?: boolean;
  onChange: (e) => void;
};

export type Ref = HTMLElement;

const Toggle = forwardRef<Ref, ToggleProps>(
  ({ id, name, label, value, checked, isProcessing, disabled, onChange }, ref) => (
    <Container>
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        isProcessing={isProcessing}
        disabled={disabled}
        onChange={onChange}
        aria-label={label}
        ref={ref}
      />
      <SliderRound className={`slider round ${checked? 'active': ''}`} disabled={disabled}>
        <IconContainer className={checked ? 'right' : ''}>
          <Icon isProcessing={isProcessing}>
            {isProcessing ? <SvgRefresh /> : checked ? <SvgApproved /> : null}
          </Icon>
        </IconContainer>
      </SliderRound>
    </Container>
  )
);

export default Toggle;
