import { get, post } from './genericService';

export function getRatesNoticeDeliveryData(id, success, failed) {
  return get(
    '/api/v1/account/properties/' + (id || 'xxxx') + '/rates-notice-preference',
    success,
    failed
  );
}

export function updateRatesNoticeDelivery(id, params, success, failed, config = {}) {
  return post(
    '/api/v1/account/properties/' + (id || 'xxxx') + '/rates-notice-preference/update',
    params,
    success,
    failed,
    config
  );
}
