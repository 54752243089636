import React from 'react';
import { withSvg } from '../Svg';

const InProgress = () => (
    <>
        <circle cx="12" cy="12" r="11.5" fill="#2DB84B" stroke="#2DB84B"/>
        <path d="M18.7104 7.21001C18.6175 7.11628 18.5069 7.04189 18.385 6.99112C18.2632 6.94035 18.1324 6.91422 18.0004 6.91422C17.8684 6.91422 17.7377 6.94035 17.6159 6.99112C17.494 7.04189 17.3834 7.11628 17.2904 7.21001L9.84044 14.67L6.71044 11.53C6.61392 11.4368 6.49998 11.3635 6.37512 11.3143C6.25026 11.2651 6.11694 11.2409 5.98276 11.2432C5.84858 11.2456 5.71617 11.2743 5.59309 11.3278C5.47001 11.3813 5.35868 11.4585 5.26544 11.555C5.1722 11.6515 5.09889 11.7655 5.04968 11.8903C5.00048 12.0152 4.97635 12.1485 4.97867 12.2827C4.98099 12.4169 5.00972 12.5493 5.06321 12.6724C5.1167 12.7954 5.19392 12.9068 5.29044 13L9.13044 16.84C9.2234 16.9337 9.334 17.0081 9.45586 17.0589C9.57772 17.1097 9.70843 17.1358 9.84044 17.1358C9.97245 17.1358 10.1032 17.1097 10.225 17.0589C10.3469 17.0081 10.4575 16.9337 10.5504 16.84L18.7104 8.68001C18.8119 8.58637 18.893 8.47272 18.9484 8.34622C19.0038 8.21972 19.0324 8.08312 19.0324 7.94501C19.0324 7.80691 19.0038 7.67031 18.9484 7.54381C18.893 7.41731 18.8119 7.30366 18.7104 7.21001Z" fill="white"/>
    </>
);

export default withSvg(24, 24)(InProgress);