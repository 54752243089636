import React, { FC } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import NavItem from '_components/Corporate/NavItem';
import { getLink } from '_utils/helpers/link';
import SvgKey from '_utils/icons/TilesCard/Key';
import SvgCalendar from '_utils/icons/TilesCard/Calendar';
import { Arrow } from '_utils/icons';
import SvgUser from '_utils/icons/TilesCard/User';
import {
  CardWrapper,
  Description,
  IconWrapperBottom,
  IconWrapperHeader,
  StyledLink,
  Title
} from './styledTilesCard';

interface ITilesCard {
  icon: string;
  title: string;
  description: string;
  link: any;
  className: string;
}

const CARD_HEADER_ICON = {
  user: {
    iconSvg: <SvgUser />,
    config: {
      width: { xs: 20, md: 27 },
      height: { xs: 20, md: 27 }
    }
  },
  key: {
    iconSvg: <SvgKey />,
    config: {
      width: { xs: 20, md: 27 },
      height: { xs: 20, md: 27 }
    }
  },
  calendar: {
    iconSvg: <SvgCalendar />,
    config: {
      width: { xs: 20, md: 27 },
      height: { xs: 20, md: 27 }
    }
  }
};

const TilesCard: FC<ITilesCard> = ({ icon, title, description, link, className }) => {
  const { iconSvg, config } = CARD_HEADER_ICON[icon];
  const linkObj = getLink(link?.value);

  return (
    <NavItem StyledLink={StyledLink} link={linkObj}>
      <CardWrapper className={className}>
        <IconWrapperHeader config={config}>{iconSvg}</IconWrapperHeader>
        <Title>{title}</Title>
        <Description>
          <RichText field={{ value: description }} />
        </Description>
        <IconWrapperBottom>
          <Arrow />
        </IconWrapperBottom>
      </CardWrapper>
    </NavItem>
  );
};

export default TilesCard;
