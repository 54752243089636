import React from 'react';
import { AttributeRow, AttributeIcon, AttributeValue, Link } from './StyledRelatesTo';

import { RelatesTo as RelatesToIcon } from '_utils/icons';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useTranslation } from 'react-i18next';

function RelatedItem({ item, index }) {
  if (Boolean(item?.CoreRelationTitle?.jss) === false) {
    return null;
  }

  const {
    CoreRelationTitle: { jss: coreRelationTitle },
    url
  } = item;
  return (
    <>
      {index > 0 ? ', ' : ''}
      <Link to={url}>
        <Text field={coreRelationTitle} />
      </Link>
    </>
  );
}

function RelatesTo({ items }) {
  const [t] = useTranslation();

  if (!items) {
    return null;
  }

  return (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <RelatesToIcon ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>
        {t('page-banner-related-to')}{' '}
        {items.map((item, index) => (
          <RelatedItem key={`related-item-${index}`} item={item} index={index} />
        ))}
      </AttributeValue>
    </AttributeRow>
  );
}

export default RelatesTo;
