import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { buttonReset, mq, safeFocusRemoval, depth, hideOnPrint } from '_utils/styles';
import { withMenuContext, MenuContextProps } from '_containers/MenuContext';
import { TRANSITION_DURATION } from '../MainMenu';
import { useTimer } from '_utils/hooks';
import { useTranslation } from 'react-i18next';

const Button = styled.button`
  ${buttonReset};
  ${safeFocusRemoval}
  display: flex;
  align-items: center;
  z-index: ${depth.MENU_BUTTON};

  /* &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  } */

  &:focus {
    ${safeFocusRemoval}
    position: relative;
    outline-color: ${({ theme }) => theme.colors.element.focused};
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
  }
  ${hideOnPrint}
`;

const Icon = styled.span`
  margin: 10px;
  width: 20px;
  height: 11px;
  position: relative;
  display: block;
`;

const Bar = styled.span`
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0px;
  top: 0px;
  background-color: ${({ theme }) => theme.colors.element.primary};
  opacity: 1;
  transition: all 0.2s linear;
  border-radius: 1px;

  &:nth-child(1) {
    [aria-expanded='true'] & {
      transform: translate3d(0, 5px, 0) rotate(45deg);
    }
  }
  &:nth-child(2) {
    transform: translate(0, 5px);
    [aria-expanded='true'] & {
      opacity: 0;
    }
  }
  &:nth-child(3) {
    transform: translate3d(0, 10px, 0);

    [aria-expanded='true'] & {
      transform: translate3d(0, 5px, 0) rotate(-45deg);
    }
  }
`;

const Label = styled.span`
  display: none;
  padding-right: 4px;

  ${mq('lg')} {
    display: block;
  }
`;

interface MenuButtonProps extends MenuContextProps {}

const MenuButton: React.FC<MenuButtonProps> = ({ menuActive, setMenuActive }) => {
  const [t] = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const { setTimer, cancelTimer } = useTimer();

  // clean up
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cancelTimer(), []);

  return (
    <Button
      // uncomment to debug 'enabled state'
      // style={{ backgroundColor: !enabled ? 'red' : 'transparent' }}
      id="menu-button"
      aria-expanded={menuActive}
      aria-labelledby="menu-button-label"
      onClick={() => {
        if (!enabled) {
          return;
        }
        if (!menuActive) {
          setMenuActive(true);
        }

        if (menuActive) {
          setMenuActive(false);
        }
        setEnabled(false);

        setTimer(() => {
          setEnabled(true);
        }, TRANSITION_DURATION + 10);
      }}
      onFocus={() => {
        if (!enabled) {
          return;
        }
        if (menuActive) {
          setEnabled(false);
          setMenuActive(false);
        }

        setTimer(() => {
          setEnabled(true);
        }, TRANSITION_DURATION + 10);
      }}
    >
      <Icon>
        <Bar />
        <Bar />
        <Bar />
      </Icon>
      <Label id="menu-button-label">{menuActive ? t('menu-close-label') : t('menu-label')}</Label>
    </Button>
  );
};

export default withMenuContext(MenuButton);
