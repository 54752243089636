import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionWithInput from './QuestionWithInput';
import Checkbox from '_utils/components/Corporate/Checkbox';
import { CheckboxContainer } from './StyledQuestions';
import { itemState } from '../definition';
import InputText from '_utils/components/CommunityEngagement/InputText';
import SelectCountry, {
  ICountry
} from '_components/CityConnect/PersonalInfo/AutoCompleteAddress/SelectCountry';
import { getListCountry } from '_services/communityEngagementProfileService';
import { DEFAULT_COUNTRY } from '_components/CityConnect/PersonalInfo/AutoCompleteAddress/definitions';
import { SuburbSearchResultItem } from '_components/CityConnect/PersonalInfo/AutoCompleteAddress/definitions';
import {
  individualProps,
  relationshipList,
  genders,
  languages,
  NOT_LISTED_HERE
} from '../definition';
import { checkExist } from '_utils/helpers/profileHelper';
import SvgWarning from '_utils/icons/Profile/Warning';
import DropDown from '_utils/components/CommunityEngagement/DropDown';
import {
  RowContainer,
  SearchAddressContainer,
  SearchItem,
  SearchItemContainer,
  SearchPanel
} from './StyledAddressLookup';
import { DetailItem } from './StyledOrganisation';
import ClickAwayListener from '_utils/components/CityConnect/ClickAwayListener';
import TextareaAutoResize from '_utils/components/CityConnect/TextareaAutoResize';
import { getListSuburbByKeyword } from '_services/communityEngagementProfileService';

const Individual: React.FC<individualProps> = ({
  email,
  suburb,
  city,
  relationships,
  birthYear,
  languageSpokenAtHome,
  languageSpokenAtHomeOther,
  errMsg,
  handleChange,
  relationshipsOther,
  gender,
  genderOther,
  handleRelationship,
  isOutsideOfAustralia,
  addressLine1,
  zipOrPostalCode,
  stateOrProvince,
  country,
  setConsultation
}) => {
  const [t] = useTranslation();
  const [listSelected, setListSelected] = useState(relationships);
  const [isOutsideAddress, setIsOutsideAddress] = useState(isOutsideOfAustralia);
  const [active, setActive] = useState('');

  const [handleSelectGender, setHandleSelectGender] = useState(gender);
  const [handleSelectLanguage, setHandleSelectLanguage] = useState(languageSpokenAtHome);

  const [suburbFieldValue, setSuburbFieldValue] = useState(suburb);
  const [suburbSearchResult, setSuburbSearchResult] = useState<SuburbSearchResultItem[]>([]);
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [isSuburbFieldInFocus, setIsSuburbFieldInFocus] = useState<boolean>(false);

  const [listCountry, setListCountry] = useState([]);
  const [countryInput, setCountryInput] = useState<ICountry>();
  const [overseasAddress, setOverseasAddress] = useState<string>(
    isOutsideOfAustralia ? addressLine1 : ''
  );
  const [overseasSuburbTownCity, setOverseasSuburbTownCity] = useState<string>(
    isOutsideOfAustralia ? city : ''
  );
  const [overseasStateProvinceRegion, setOverseasStateProvinceRegion] = useState<string>(
    isOutsideOfAustralia ? stateOrProvince : ''
  );
  const [overseasZipPostalCode, setOverseasZipPostalCode] = useState<string>(
    isOutsideOfAustralia ? zipOrPostalCode : ''
  );

  useEffect(() => {
    getListCountry((res) => {
      const countries = res?.data.filter(
        (countryItem: ICountry) =>
          countryItem.iso3.toLocaleLowerCase() !== DEFAULT_COUNTRY.iso3.toLocaleLowerCase()
      );
      setListCountry(countries);
      if (isOutsideOfAustralia && country) {
        const existingCountry = countries.find(
          (countryItem: ICountry) => countryItem.name === country
        );
        setCountryInput(existingCountry);
      }
    });
  }, []);

  useEffect(() => {
    handleRelationship(listSelected, isOutsideAddress);
  }, [listSelected, isOutsideAddress]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (isSuburbFieldInFocus) {
        searchSuburbs(suburbFieldValue);
      }
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [suburbFieldValue]);

  const handleSelectOption = (value: string, type: string) => {
    if (type === 'gender') {
      setHandleSelectGender(value);
      if (value !== t('community-engagement-profile-input-language-not-listed')) {
        handleChange('', 'genderOther', false);
      }
    } else {
      setHandleSelectLanguage(value);
      if (value !== t('community-engagement-profile-input-language-not-listed')) {
        handleChange('', 'languageSpokenAtHomeOther', false);
      }
    }
  };

  const handleOnOverseasSelect = (e) => {
    const isOverseas = !isOutsideAddress;
    setIsOutsideAddress(isOverseas);

    if (isOverseas) {
      setSuburbFieldValue('');
      setIsSuburbFieldInFocus(false);
      setShowSearchResult(false);
      setSuburbSearchResult([]);
    }
  };

  const handleOnToggleSelect = (value: string) => {
    const existedSelect = checkExist(value, listSelected);
    let newSelect = [];
    if (existedSelect) {
      newSelect = [...listSelected.filter((item) => item !== value)];
    } else {
      newSelect = [...listSelected];
      newSelect.push(value);
    }
    setListSelected([...newSelect]);
  };

  const otherRelationship = listSelected.find((e) => e === NOT_LISTED_HERE);

  // Handle state change of suburb input
  const handleSuburbInputChange = (e) => {
    const value = e.target.value;
    setSuburbFieldValue(value);
    if (suburb !== value && suburb !== '') {
      handleChange('', 'suburb', true);
    }
  };

  // handle focus on input
  const handleSuburbInputFocus = () => {
    setIsSuburbFieldInFocus(true);
    setShowSearchResult(true);
  };

  // hide search result panel after focus out
  const handleSuburbInputBlur = () => {
    setIsSuburbFieldInFocus(false);
    if (showSearchResult) {
      setTimeout(() => setShowSearchResult(false), 200);
    }
  };

  const selectSuburbItem = (suburbItem: SuburbSearchResultItem) => {
    handleChange(suburbItem.suburb, 'suburb', true);
    setSuburbFieldValue(suburbItem.suburb);
    setShowSearchResult(false);
  };

  // calculate search or do not search
  const searchSuburbs = (keyword: string) => {
    if (keyword.trim().length >= 4) {
      setShowSearchResult(true);
      getListSuburbByKeyword(
        keyword,
        processSuburbSuggestionsSuccess,
        processSuburbSuggestionsError
      );
    } else {
      setShowSearchResult(false);
      setSuburbSearchResult([]);
    }
  };

  const processSuburbSuggestionsSuccess = (res: any) => {
    setSuburbSearchResult(res?.data);
  };

  const processSuburbSuggestionsError = (res: any) => {
    setSuburbSearchResult([]);
  };

  const onChangeSetCountry = (selectedCountry) => {
    setCountryInput(selectedCountry);
    setConsultation((prevState: itemState) => {
      return {
        ...prevState,
        country: selectedCountry.name
      };
    });
    handleChange(selectedCountry.name, 'country', true);
  };

  const handleChangeInput = (value: string, cases: string, isCheck?: boolean) => {
    switch (cases) {
      case 'addressLine1':
        setOverseasAddress(value);
        handleChange(value, 'addressLine1', true);
        break;
      case 'city':
        setOverseasSuburbTownCity(value);
        break;
      case 'stateOrProvince':
        setOverseasStateProvinceRegion(value);
        break;
      case 'zipOrPostalCode':
        setOverseasZipPostalCode(value);
        handleChange(value, 'zipOrPostalCode', true);
        break;
      default:
        break;
    }
    setConsultation((prevState: any) => {
      return {
        ...prevState,
        [cases]: value
      };
    });
  };

  return (
    <>
      <QuestionWithInput
        title={t('community-engagement-profile-question-email-address')}
        value={email}
        errMsg={errMsg.email}
        type={'email'}
        haveNode={true}
        nodeTitle={t('community-engagement-profile-question-email-note')}
        handleChange={handleChange}
      />

      <QuestionWithInput title={t('community-engagement-profile-question-suburb')} isSelect={true}>
        <RowContainer>
          <ClickAwayListener onClickAway={() => setShowSearchResult(false)}>
            <SearchAddressContainer>
              <TextareaAutoResize
                id="suburb"
                value={suburbFieldValue}
                onChange={(e) => handleSuburbInputChange(e)}
                onFocus={handleSuburbInputFocus}
                onBlur={handleSuburbInputBlur}
                rows={1}
                disabled={isOutsideAddress}
              />
              {errMsg.suburb && (
                <p className="error-validation">
                  <SvgWarning />
                  {errMsg.suburb}
                </p>
              )}
              {suburbSearchResult && suburbSearchResult.length > 0 && (
                <SearchPanel showPanel={showSearchResult}>
                  <SearchItemContainer>
                    {suburbSearchResult.map((item, index) => (
                      <SearchItem
                        key={index}
                        onClick={() => selectSuburbItem(item)}
                        title={item.suburb}
                      >
                        <div>
                          {item.suburb}, {item.state} {item.postcode}
                        </div>
                      </SearchItem>
                    ))}
                  </SearchItemContainer>
                </SearchPanel>
              )}
            </SearchAddressContainer>
          </ClickAwayListener>
        </RowContainer>
      </QuestionWithInput>

      <Checkbox
        id={'overseas'}
        name="overseas"
        value={'overseas'}
        onChange={handleOnOverseasSelect}
        checked={isOutsideAddress}
      >
        {t('community-engagement-profile-live-overseas')}
      </Checkbox>
      {isOutsideAddress && (
        <>
          <RowContainer></RowContainer>
          <RowContainer>
            <QuestionWithInput isSelect={true}>
              <DetailItem.TextFieldLabel>{t('personal-info-country')}</DetailItem.TextFieldLabel>
              <SelectCountry
                listCountry={listCountry}
                visibility={isOutsideAddress}
                country={countryInput}
                onChangeCountry={(selectedCountry: any) => onChangeSetCountry(selectedCountry)}
              />
              {errMsg.country && (
                <p className="error-validation">
                  <SvgWarning />
                  {errMsg.country}
                </p>
              )}
            </QuestionWithInput>
          </RowContainer>

          <RowContainer>
            <QuestionWithInput
              title={t('personal-info-address')}
              value={overseasAddress}
              type={'addressLine1'}
              handleChange={handleChangeInput}
              questionInputTittleStyle={true}
              errMsg={errMsg.addressLine1}
            />
          </RowContainer>
          {/* INFO: Suburb or Town or City */}
          <RowContainer>
            <QuestionWithInput
              title={t('personal-info-suburb-town-city')}
              value={overseasSuburbTownCity}
              type={'city'}
              handleChange={handleChangeInput}
              questionInputTittleStyle={true}
              questionInputTitleStyleCapable={true}
            />
          </RowContainer>
          {/* INFO: State / Province / Region */}
          <RowContainer>
            <QuestionWithInput
              title={t('personal-info-state-province-region')}
              value={overseasStateProvinceRegion}
              type={'stateOrProvince'}
              handleChange={handleChangeInput}
              questionInputTittleStyle={true}
              questionInputTitleStyleCapable={true}
            />
          </RowContainer>
          {/* INFO: Zip or Postal code */}
          <RowContainer>
            <QuestionWithInput
              title={t('personal-info-zip-or-postal-code')}
              value={overseasZipPostalCode}
              type={'zipOrPostalCode'}
              handleChange={handleChangeInput}
              questionInputTittleStyle={true}
              errMsg={errMsg.zipOrPostalCode}
            />
          </RowContainer>
        </>
      )}

      <QuestionWithInput
        title={t('community-engagement-profile-question-relationship')}
        isSelect={true}
        haveNode={true}
        nodeTitle={'Select all that apply to you.'}
      >
        <CheckboxContainer>
          {relationshipList(t).map((item, idx) => (
            <Checkbox
              key={item.type}
              id={item.type}
              name="PaymentDue"
              value={item.type}
              onChange={(e) => handleOnToggleSelect(item.type)}
              checked={checkExist(item.type, listSelected)}
            >
              {item.value}
            </Checkbox>
          ))}
          {errMsg.relationship && (
            <p className="error-validation">
              <SvgWarning />
              {errMsg.relationship}
            </p>
          )}
          {otherRelationship && (
            <InputText
              className="not-listed"
              value={relationshipsOther}
              disabled={!listSelected.includes(NOT_LISTED_HERE)}
              errorMsg={!listSelected.includes(NOT_LISTED_HERE) ? '' : errMsg.relationshipsOther}
              onChange={(e) => handleChange(e.target?.value, 'relationshipsOther', true)}
              active={active === 'relationshipsOther'}
              onClick={() => setActive('relationshipsOther')}
              onLeave={() => setActive('')}
            />
          )}
        </CheckboxContainer>
      </QuestionWithInput>
      <QuestionWithInput title={t('community-engagement-profile-question-gender')} isSelect={true}>
        <DropDown
          index={2}
          data={genders(t)}
          value={gender}
          type={'gender'}
          handleChange={handleChange}
          isHavingOtherOption={true}
          handleSelectOption={handleSelectOption}
        />
        {handleSelectGender === t('community-engagement-profile-input-language-not-listed') && (
          <InputText
            className="not-listed"
            value={genderOther}
            errorMsg={errMsg.genderOther}
            onChange={(e) => handleChange(e.target?.value, 'genderOther', true)}
            onClick={() => setActive('genderOther')}
            onLeave={() => setActive('')}
            active={active === 'genderOther'}
          />
        )}
        {errMsg.gender && (
          <p className="error-validation">
            <SvgWarning />
            {errMsg.gender}
          </p>
        )}
      </QuestionWithInput>
      <QuestionWithInput
        title={t('community-engagement-profile-question-year-born')}
        value={birthYear}
        errMsg={errMsg.birthYear}
        type={'birthYear'}
        handleChange={handleChange}
        placeHolder={'YYYY'}
      />
      <QuestionWithInput
        title={t('community-engagement-profile-question-main-language')}
        isSelect={true}
      >
        <DropDown
          index={1}
          data={languages(t)}
          value={languageSpokenAtHome}
          type={'languageSpokenAtHome'}
          handleChange={handleChange}
          isHavingOtherOption={true}
          handleSelectOption={handleSelectOption}
        />
        {handleSelectLanguage === t('community-engagement-profile-input-language-not-listed') && (
          <InputText
            className="text-field"
            value={languageSpokenAtHomeOther}
            errorMsg={errMsg.languageSpokenAtHomeOther}
            onChange={(e) => handleChange(e.target?.value, 'languageSpokenAtHomeOther', true)}
            active={active === 'languageSpokenAtHomeOther'}
            onClick={() => setActive('languageSpokenAtHomeOther')}
            onLeave={() => setActive('')}
          />
        )}
        {errMsg.languageSpokenAtHome && (
          <p className="error-validation">
            <SvgWarning />
            {errMsg.languageSpokenAtHome}
          </p>
        )}
      </QuestionWithInput>
    </>
  );
};

export default Individual;
