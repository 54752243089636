import React from 'react';
import useScript from '_utils/hooks/useScript';
import { withEditMode, withEditModeNotice } from '_containers/BaseComponent';
import { compose } from 'ramda';
import { Wrap, Container, WidgetContainer, RichTextHtml, GridContainer } from './StyledEmbedWidget';

const ContainerWithNotice = withEditModeNotice(Container);

type EmbedlyCardProps = {
  rendering: any;
  editMode: boolean;
};

const getBackground = (color: string) => {
  // default color
  let background = 'transparent';

  if (color?.toLowerCase() === 'light beige') {
    background = 'secondary';
  }

  if (color?.toLowerCase() === 'white') {
    background = 'primary';
  }

  if (color?.toLowerCase() === 'grey') {
    background = 'tertiary';
  }

  return background;
};

const Embed = ({ editMode, fields }) => {
  let scriptsField = fields?.url?.jss?.value || '';
  let htmlFieldValue = fields?.html?.jss?.value || '';

  // Perform Token replacements if Tokens available
  const tokens = !!fields?.tokens?.value ? fields?.tokens?.value.split('&') : [];
  tokens.forEach((token) => {
    const t = token.split('=');
    if (t.length === 2) {
      const tokenName = t[0];
      const tokenValue = t[1];
      scriptsField = scriptsField.split(`{${tokenName}}`).join(tokenValue);
      htmlFieldValue = htmlFieldValue.split(`{${tokenName}}`).join(tokenValue);
    }
  });

  const htmlField = {
    value: htmlFieldValue
  };

  const background = fields?.backgroundColour?.jss?.value || '';
  const scriptUrls = scriptsField.split('\r\n') || '';
  const htmlRender = htmlField || '';

  // load upto 3 script urls
  const scriptUrl1 = scriptUrls[0] || '';
  const scriptUrl2 = scriptUrls[1] || '';
  const scriptUrl3 = scriptUrls[2] || '';
  useScript(scriptUrl1, true);
  useScript(scriptUrl2, true);
  useScript(scriptUrl3, true);

  let noticeMessage = null;

  if (editMode) {
    const hasValidItems = htmlFieldValue;

    if (!fields) {
      noticeMessage = 'EmbedWidget component has no fields';
    }

    if (!hasValidItems) {
      noticeMessage = 'EmbedWidget component has no valid HTML value and will not be displayed.';
    }
  }

  return (
    <ContainerWithNotice
      noticeEnabled={noticeMessage !== null}
      noticeLevel="warning"
      noticeMessage={noticeMessage}
    >
      <WidgetContainer background={getBackground(background)}>
        <GridContainer>{htmlRender && <RichTextHtml field={htmlRender} />}</GridContainer>
      </WidgetContainer>
    </ContainerWithNotice>
  );
};

export const EmbedWidget: React.FC<EmbedlyCardProps> = ({ rendering, editMode }) => {
  const fields = {
    ...rendering?.fields?.data?.datasource,
    ...rendering?.fields?.data?.tokensConfig
  };

  return (
    <Wrap>
      <Embed editMode={editMode} fields={fields}></Embed>
    </Wrap>
  );
};

export default compose(withEditMode)(EmbedWidget);
