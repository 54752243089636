import styled from 'styled-components';
import { spacing } from '_utils/props';
import { heading_m, mq, text_m, linkStyle, color, svg } from '_utils/styles';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { externalLinkStyle } from "_components/Corporate/ContentRichText/StyledContentRichText";

// INFO: Announcement
export const ServiceAnnouncementWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: ${color('all.deepBlue')};
  background: ${({ type }) => type.backgroundColor};
  max-width: ${({ theme }) => theme.grid.containerMaxWidth}px;
  ${({ theme }) => spacing({ theme, p: 3, mx: 'auto', my: 0 })};
  & + & {
    ${({ theme }) => spacing({ theme, mt: 3 })};
  }

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Icon = styled.span`
  ${svg(24, 24)}
  width: 24px;
  flex-shrink: 0;

  ${mq('md')} {
    ${svg(32, 32)}
    width: 32px;
  }
`;

export const ContentAnnouncement = styled.div`
  ${({ theme }) => spacing({ theme, ml: 2 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};
  }
`;

export const TitleAnnouncement = styled.div`
  font-weight: bold;
  ${heading_m}
  ${({ theme }) => spacing({ theme, m: 0, mb: 1 })};
`;

export const BodyAnnouncement = styled(RichText)`
  a {
    ${({ theme }) => linkStyle(theme.colors.link.secondary)}
  }
  br {
    display: block;
    content: '';
    ${({ theme }) => spacing({ theme, mb: 2 })};
  }

  ${mq('md')} {
    br {
      display: block;
      ${({ theme }) => spacing({ theme, mb: 1 })};
      content: '';
    }
  }
  ${text_m};
  ${({ theme }) => spacing({ theme, m: 0 })};

  .external-link {
    ${externalLinkStyle}
  }
`;
