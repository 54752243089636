import React from 'react';
import { withSvg } from '../Svg';

const SvgPencil = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.2573 4.6927C17.5823 5.0177 17.5823 5.5427 17.2573 5.8677L15.7323 7.3927L12.6073 4.2677L14.1323 2.7427C14.288 2.58666 14.4994 2.49896 14.7198 2.49896C14.9402 2.49896 15.1516 2.58666 15.3073 2.7427L17.2573 4.6927ZM2.49896 17.0844V14.551C2.49896 14.4344 2.54063 14.3344 2.62396 14.251L11.7156 5.15937L14.8406 8.28437L5.74063 17.376C5.66563 17.4594 5.5573 17.501 5.44896 17.501H2.91563C2.6823 17.501 2.49896 17.3177 2.49896 17.0844Z"
    fill="#4E5A5F"
  />
);

export default withSvg(20, 20)(SvgPencil);
