import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import StatusTag from '_utils/components/CityConnect/StatusTag';
import SvgCross from '_utils/icons/common/Cross';
import withErrorBoundary from '_containers/ErrorBoundary';
import {
  AmountDueContent,
  AmountDueHeader,
  ColumnLabel,
  AmountDueWrapper,
  BalanceContent,
  BalanceHeader,
  BalanceWrapper,
  CardTitle,
  CardBody,
  CardWrapper,
  DateDueContent,
  DateDueHeader,
  StatusWrapper,
  PayButton,
  Container,
  CardFooter,
  DueNow,
  TotalContent,
  ArrearsContent,
  CardNote,
  CardMessage,
  Grid,
  FooterGridRow,
  FooterGridItemTotal,
  FooterGridItemArrears,
  FooterGridItemPay,
  Icon,
  Announcement,
  RefreshWrapper,
  LoadingContent,
  SvgWrapper
} from './StyledRatesSummaryCard';
import { getRatesSummaryCardData } from '_services/ratesSummaryCardService';
import ServiceAnnouncement from '_components/CityConnect/ServiceAnnouncement';
import { IRatesSummary } from './definitions';
import { Refresh } from '_utils/icons';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

const PROCESSING_STATUS = 'processing';

const RatesSummaryCard: React.FC = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const [ratesSummary, setRatesSummary] = useState<IRatesSummary>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const ratesSummaryId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const isPayRates = parseFloat(ratesSummary?.totalOutstanding?.replace('$', '')) > 0;

  //Get ratesSummary from API
  useEffect(() => {
    getRatesSummaryCardData(ratesSummaryId, getRateSummarySuccess, (res) => setError(res));
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const getRateSummarySuccess = (data) => {
    setRatesSummary(data?.data?.property?.rates);
    setIsLoading(false);
  };

  return (
    <div>
      <Container>
        <CardWrapper isFallback={!ratesSummary?.items}>
          <CardTitle>{t('rates-summary-heading')}</CardTitle>
          {/* card body */}
          {isLoading ? (
            <LoadingContent>
              {t('dashboard-loading-information')}
              <RefreshWrapper>
                <Refresh ariaHidden={false} />
              </RefreshWrapper>
            </LoadingContent>
          ) : (
            <CardBody>
              {ratesSummary?.items ? (
                <>
                  {/* table head */}
                  <Grid.Row className="hidden-xs table-header-row">
                    <Grid.Item>
                      <DateDueHeader>{t('rates-summary-due-date')}</DateDueHeader>
                    </Grid.Item>
                    <Grid.Item>
                      <AmountDueHeader>{t('rates-summary-amount')}</AmountDueHeader>
                    </Grid.Item>
                    <Grid.Item>
                      <BalanceHeader>
                        <RichText field={{ value: t('rates-summary-balance') }} />
                      </BalanceHeader>
                    </Grid.Item>
                    <Grid.Item></Grid.Item>
                  </Grid.Row>
                  {/* table body */}
                  {ratesSummary?.items?.map((item, idx) => (
                    <Grid.Row className="table-content-row" key={idx}>
                      <Grid.Item>
                        <DateDueContent role="heading" aria-level="3">
                          {item?.dueDate}
                        </DateDueContent>
                      </Grid.Item>
                      <Grid.Item>
                        <AmountDueWrapper>
                          <ColumnLabel role="heading" aria-level="3">
                            {t('rates-summary-amount')}
                          </ColumnLabel>
                          <AmountDueContent role="presentation">{item?.amount}</AmountDueContent>
                        </AmountDueWrapper>
                      </Grid.Item>
                      <Grid.Item>
                        <BalanceWrapper>
                          <ColumnLabel>
                            <RichText
                              field={{ value: t('rates-summary-balance') }}
                              role="heading"
                              aria-level="3"
                            />
                          </ColumnLabel>
                          <BalanceContent role="presentation">{item?.balance}</BalanceContent>
                        </BalanceWrapper>
                      </Grid.Item>
                      <Grid.Item>
                        {item?.status && item.status.toLowerCase() !== PROCESSING_STATUS && (
                          <StatusWrapper>
                            <StatusTag text={item.status.toLowerCase()} />
                          </StatusWrapper>
                        )}
                      </Grid.Item>
                    </Grid.Row>
                  ))}
                </>
              ) : (
                <CardMessage>{t('rates-summary-non-rateable')}</CardMessage>
              )}
            </CardBody>
          )}
        </CardWrapper>
      </Container>

      {/* card note */}
      {ratesSummary?.items && (
        <CardNote>
          <RichText field={{ value: t('rates-summary-note') }} />
        </CardNote>
      )}

      {/* card footer */}
      {(ratesSummary?.paymentLink || ratesSummary?.amountDue) && (
        <CardFooter>
          <FooterGridRow>
            <FooterGridItemTotal>
              {ratesSummary?.amountDue && (
                <>
                  {ratesSummary.amountDue.includes('-') ? (
                    <TotalContent>
                      {ratesSummary.amountDue.split('-').join('')} {t('rates-summary-credit')}
                    </TotalContent>
                  ) : (
                    <TotalContent>
                      {ratesSummary.amountDue} {t('rates-summary-due-now')}
                    </TotalContent>
                  )}
                </>
              )}
            </FooterGridItemTotal>
            <FooterGridItemArrears>
              {ratesSummary?.arrears && (
                <ArrearsContent>
                  {t('rates-summary-includes')} {ratesSummary?.arrears} {t('rates-summary-arrears')}
                </ArrearsContent>
              )}
            </FooterGridItemArrears>
            <FooterGridItemPay>
              {ratesSummary?.paymentLink && isPayRates && (
                <PayButton
                  href={ratesSummary?.paymentLink}
                  className="button-primary button-slim"
                  target="_blank"
                >
                  {t('rates-summary-pay-button')}
                  <SvgWrapper>
                    <SvgExternal />
                  </SvgWrapper>
                </PayButton>
              )}
            </FooterGridItemPay>
          </FooterGridRow>
        </CardFooter>
      )}
    </div>
  );
};

const RatesSummaryCardFallback: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Container>
      <CardWrapper isFallback={true}>
        <CardTitle>{t('rates-summary-heading')}</CardTitle>
        <CardBody>
          <CardMessage>{t('dashboard-information-is-currently-unavailable')}</CardMessage>
        </CardBody>
      </CardWrapper>
    </Container>
  );
};

export default withErrorBoundary(RatesSummaryCard, RatesSummaryCardFallback);
