import React from 'react';
import {withSvg} from "../Svg";

const Completed = () => (
    <>
        <circle cx="12" cy="12.3333" r="12" fill="#4E5A5F"/>
        <path d="M13.1749 12.3332L16.7583 8.75824C16.9152 8.60132 17.0034 8.3885 17.0034 8.16658C17.0034 7.94466 16.9152 7.73183 16.7583 7.57491C16.6014 7.41799 16.3885 7.32983 16.1666 7.32983C15.9447 7.32983 15.7319 7.41799 15.5749 7.57491L11.9999 11.1582L8.42495 7.57491C8.26803 7.41799 8.0552 7.32983 7.83328 7.32983C7.61136 7.32983 7.39854 7.41799 7.24162 7.57491C7.0847 7.73183 6.99654 7.94466 6.99654 8.16658C6.99654 8.3885 7.0847 8.60132 7.24162 8.75824L10.8249 12.3332L7.24162 15.9082C7.16351 15.9857 7.10151 16.0779 7.05921 16.1794C7.0169 16.281 6.99512 16.3899 6.99512 16.4999C6.99512 16.6099 7.0169 16.7188 7.05921 16.8204C7.10151 16.9219 7.16351 17.0141 7.24162 17.0916C7.31908 17.1697 7.41125 17.2317 7.5128 17.274C7.61435 17.3163 7.72327 17.3381 7.83328 17.3381C7.94329 17.3381 8.05221 17.3163 8.15376 17.274C8.25531 17.2317 8.34748 17.1697 8.42495 17.0916L11.9999 13.5082L15.5749 17.0916C15.6524 17.1697 15.7446 17.2317 15.8461 17.274C15.9477 17.3163 16.0566 17.3381 16.1666 17.3381C16.2766 17.3381 16.3855 17.3163 16.4871 17.274C16.5886 17.2317 16.6808 17.1697 16.7583 17.0916C16.8364 17.0141 16.8984 16.9219 16.9407 16.8204C16.983 16.7188 17.0048 16.6099 17.0048 16.4999C17.0048 16.3899 16.983 16.281 16.9407 16.1794C16.8984 16.0779 16.8364 15.9857 16.7583 15.9082L13.1749 12.3332Z" fill="#041C2C"/>
    </>

);

export default withSvg(24, 24)(Completed);