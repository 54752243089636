import React from 'react';

export const SvgChevronRight = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71502 7.70865L1.71562 13.7044C1.62267 13.7981 1.51208 13.8724 1.39023 13.9231C1.26839 13.9739 1.13769 14 1.0057 14C0.873698 14 0.743005 13.9739 0.621158 13.9231C0.499311 13.8724 0.388722 13.7981 0.295769 13.7044C0.20205 13.6115 0.127663 13.501 0.0768999 13.3792C0.0261363 13.2574 -5.73792e-07 13.1268 -5.68026e-07 12.9949C-5.62259e-07 12.863 0.0261363 12.7324 0.0768999 12.6106C0.127664 12.4888 0.20205 12.3783 0.295769 12.2854C2.09612 10.5873 3.85394 8.84334 5.50025 6.99506L0.295769 1.71288C0.107485 1.52471 0.00170702 1.2695 0.00170703 1.00338C0.00170704 0.737269 0.107485 0.482055 0.295769 0.293884C0.484054 0.105713 0.739422 -3.1737e-07 1.0057 -3.05731e-07C1.13754 -2.99968e-07 1.2681 0.0259533 1.38991 0.076378C1.51172 0.126803 1.6224 0.200711 1.71562 0.293884L7.71502 6.28965C7.80605 6.38469 7.8774 6.49675 7.92499 6.61942C8.025 6.86271 8.025 7.13559 7.92499 7.37888C7.8774 7.50154 7.80605 7.61361 7.71502 7.70865Z"
      fill="#041C2C"
    />
  </svg>
);
