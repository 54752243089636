import keyByValue from '_utils/helpers/keyByValue';

export default function getTypeFromValue<T>(
  object,
  value: string | object,
  key: string = 'type'
): T {
  if (!object || !value) {
    return undefined;
  }

  if (typeof value === 'string') {
    return object[keyByValue(object, value)];
  }

  if (value && value.hasOwnProperty(key)) {
    return object[keyByValue(object, value[key])];
  }
}
