import styled from 'styled-components';
import { Section } from '_utils/components/Corporate';
import { Container } from '../Banner/StyledBanner';
import { section as sectionCSS } from '_utils/styles';
import { spacing } from '_utils/props';

export const TwoColumnsCurvedBorderSection = styled(Section)`
  ${({ theme, sectionTheme }) => sectionCSS({ theme, sectionTheme })};

  position: relative;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;

  ${Container} + & {
    ${({ isShowCurve }) => (isShowCurve ? 'padding-top: 0 !important;' : '')};
  }

  #main-content > &:last-child {
    ${({ theme }) => spacing({ theme, mb: 7 })}
  }
`;
