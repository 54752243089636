export const createSitecoreLink = (href: string, text: string) => {
    return {
        value: {
            href: href,
            text: text,
            anchor: "",
            linktype: "internal",
            class: "",
            title: "",
            target: "_blank",
            querystring: "",
            id: ""
        }
    }
}

export const calculateRate = (total: number, number: number) => {
    return Math.round((number / total) * 100);
}