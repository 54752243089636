import React, { useRef, createRef, useState, useEffect, useCallback, forwardRef } from 'react';
import { usePrevious, useTimer } from '_utils/hooks';
import { compose } from 'ramda';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Nav, List, Item, Label, Link, Divider, Description } from './StyledMainMenu';
import { withMenuContext, MenuContextProps } from '_containers/MenuContext';
import { enableBodyScroll, disableBodyScroll } from '_utils/helpers';
import NavItem, { LinkProps } from '../NavItem';

export const TRANSITION_DURATION = 200;

// https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html

type MenuItem = {
  label: any;
  description: any;
  link: LinkProps;
};

type MenuItemProps = {
  menuActive: boolean;
  onItemClick: (e) => void;
  onFocus?: (e) => void;
  onBlur?: (e) => void;
  item: MenuItem;
};

type MenuItemHandlerProps = {
  tabIndex?: number;
  'aria-hidden': boolean;
  onClick?: any;
  onFocus?: any;
  onBlur?: any;
};

export type Ref = HTMLElement;

const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
  ({ item, menuActive, onItemClick, onFocus, onBlur }, ref) => {
    if (!item) {
      return null;
    }

    const label = item.label?.value || '';
    const description = item.description?.value || '';

    // nullify links without urls
    const link = item && item.link && item.link.url ? item.link : null;

    // only create handlers if we have a link
    let handlers: MenuItemHandlerProps = link
      ? {
          tabIndex: menuActive ? 0 : -1,
          'aria-hidden': menuActive ? false : true,
          onClick: onItemClick,
          onFocus: onFocus,
          onBlur: onBlur
        }
      : null;

    return (
      <Item>
        <NavItem {...handlers} description={description} ref={ref} link={link} StyledLink={Link}>
          {label && <Label>{label}</Label>}
          {description && <Description>{description}</Description>}
        </NavItem>
      </Item>
    );
  }
);

interface MenuProps extends MenuContextProps {
  fields: {
    data: {
      datasource: {
        primaryNavigation: any;
        secondaryNavigation: any;
      };
    };
  };
  sitecoreContext: any;
  onMenuItemFocus: (e: Event, itemIndex: number) => void;
}

const MenuItemList = ({
  menuActive,
  onItemClick,
  enabledItemRefs,
  onItemFocus,
  onItemBlur,
  getEnabledItemsIndex,
  menuItems
}) => {
  return menuItems.map((item, index) => {
    let enabledItemsIndex = getEnabledItemsIndex(item);
    let enabledItemProps =
      enabledItemsIndex > -1
        ? {
            ref: enabledItemRefs.current[enabledItemsIndex],
            onFocus: (e) => {
              onItemFocus(e, enabledItemsIndex);
            },
            onBlur: onItemBlur
          }
        : {};
    return (
      <MenuItem
        key={index}
        item={item}
        menuActive={menuActive}
        onItemClick={onItemClick}
        {...enabledItemProps}
      />
    );
  });
};

const MainMenu: React.FC<MenuProps> = ({ fields, menuActive, setMenuActive }) => {
  const datasource = fields?.data?.datasource;

  const primaryNavigation = datasource?.primaryNavigation?.[0] || [];
  const secondaryNavigation = datasource?.secondaryNavigation?.[0] || [];

  const primaryItems = primaryNavigation?.links || [];
  const secondaryItems = secondaryNavigation?.links || [];

  const containerRef = useRef();
  const prevMenuActive = usePrevious(menuActive);
  const [focusItemIndex, setFocusItemIndex] = useState(-1);
  const { setTimer, cancelTimer } = useTimer();
  // const timerRef = useRef(null);
  const [transitioning, setTransitioning] = useState(false);

  // return all menu items that have a valid links
  const getAllEnabledItems = useCallback(() => {
    const enabledPrimaryItems = primaryItems.filter((item) => item.link && item.link.url);
    const enabledSecondaryItems = secondaryItems.filter((item) => item.link && item.link.url);
    return enabledPrimaryItems.concat(enabledSecondaryItems);
  }, [primaryItems, secondaryItems]);

  // store array of enabled menu item refs inside another ref
  const enabledItemRefs = useRef(getAllEnabledItems().map(() => createRef()));

  const focusFirstItem = () => {
    if (document.body && document.body.classList.contains('safe-focus-removal')) {
      // dont highlight item if menu was opened with mouse
      return;
    }
    if (enabledItemRefs.current && enabledItemRefs.current.length) {
      let first = enabledItemRefs.current[0];
      if (first && first.current) {
        first.current.focus();
      }
    }
  };

  // focus listener effect:
  // 1. auto focus first menu item when menu opened
  // 2. close menu if focused element is outside of menu
  useEffect(
    () => {
      // const menuButton = document.getElementById('menu-button');
      const container = containerRef.current;

      const focusListener = (e) => {
        let target: HTMLElement = e.target;

        if (target && target.id === 'menu-button') {
          return;
        }

        // iterate through parents until we find menu container or no more
        while (target) {
          if (target === container) {
            // found menu container, bail
            return;
          }
          // move up to next parent
          target = target.parentNode as HTMLElement;
        }
        // focus outside, close menu
        setMenuActive(false);
      };

      // add/remove listener when menu opened/closed
      if (menuActive && !prevMenuActive) {
        setTransitioning(true);
        setTimer(() => {
          document.body.addEventListener('focus', focusListener, true);
          setTransitioning(false);
          focusFirstItem();
        }, TRANSITION_DURATION + 10);

        disableBodyScroll(container, {
          reserveScrollBarGap: true
        });
        // without setImmediate effect clean up runs immediately
      } else if (!menuActive && prevMenuActive) {
        document.body.removeEventListener('focus', focusListener, true);
        enableBodyScroll(container);
        setTransitioning(true);

        setTimer(() => {
          setTransitioning(false);
        }, TRANSITION_DURATION + 10);
      }

      // clean up
      return () => {
        cancelTimer();
        enableBodyScroll(container);
        document.body.removeEventListener('focus', focusListener, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuActive]
  );

  const onItemClick = () => {
    setMenuActive(false);
  };

  const onItemFocus = (e, enabledItemsIndex) => {
    if (transitioning) {
      return;
    }
    if (!menuActive) {
      setMenuActive(true);
    }
    setFocusItemIndex(enabledItemsIndex);
  };

  const onItemBlur = (e) => {
    setFocusItemIndex(-1);
  };

  const onKeyDown = (e, focusItemIndex, enabledItemElems) => {
    if (!menuActive || focusItemIndex === -1 || !enabledItemElems || !enabledItemElems.length) {
      return;
    }

    let prevIndex = focusItemIndex === 0 ? enabledItemElems.length - 1 : focusItemIndex - 1;
    let nextIndex = focusItemIndex === enabledItemElems.length - 1 ? 0 : focusItemIndex + 1;

    if (e && e.keyCode === 35) {
      // END
      if (enabledItemElems[enabledItemElems.length - 1]) {
        e.preventDefault();
        enabledItemElems[enabledItemElems.length - 1].focus();
      }
    } else if (e && e.keyCode === 36) {
      // HOME
      if (enabledItemElems[0]) {
        e.preventDefault();
        enabledItemElems[0].focus();
      }
    } else if (e && e.keyCode === 38) {
      // UP
      if (enabledItemElems[prevIndex]) {
        e.preventDefault();
        enabledItemElems[prevIndex].focus();
      }
    } else if (e && e.keyCode === 40) {
      // DOWN
      if (enabledItemElems[nextIndex]) {
        e.preventDefault();
        enabledItemElems[nextIndex].focus();
      }
    }
  };

  const getEnabledItemsIndex = (item) => {
    return getAllEnabledItems().indexOf(item);
  };

  if (!datasource) {
    return null;
  }

  return (
    <Nav
      tabIndex={menuActive ? 0 : -1}
      className={menuActive ? 'active' : ''}
      ref={containerRef}
      onKeyDown={(e) => {
        const enabledItemElems = enabledItemRefs.current
          ? enabledItemRefs.current.map(({ current }) => current)
          : [];
        onKeyDown(e, focusItemIndex, enabledItemElems);
      }}
    >
      <List>
        <MenuItemList
          menuActive={menuActive}
          onItemClick={onItemClick}
          enabledItemRefs={enabledItemRefs}
          onItemFocus={onItemFocus}
          onItemBlur={onItemBlur}
          getEnabledItemsIndex={getEnabledItemsIndex}
          menuItems={primaryItems}
        />
        {secondaryItems && secondaryItems.length && (
          <>
            <Divider />
            <MenuItemList
              menuActive={menuActive}
              onItemClick={onItemClick}
              enabledItemRefs={enabledItemRefs}
              onItemFocus={onItemFocus}
              onItemBlur={onItemBlur}
              getEnabledItemsIndex={getEnabledItemsIndex}
              menuItems={secondaryItems}
            />
          </>
        )}
      </List>
    </Nav>
  );
};

export default compose(withSitecoreContext(), withMenuContext)(MainMenu);
