import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import {
  RateTransactionsWrapper,
  TableHead,
  ProcessedDate,
  ColumnGroup,
  AccordionTransactionWrapper,
  AccordionTransactionContainer,
  RateTransactionHeader,
  ReteTransactionDescription,
  TableBottom,
  TableBottomWrapper,
  TotalOutstanding,
  TableContent
} from './StyledRatesDetailTransaction';
import TransactionItem from "./TransactionItem";
import {TransactionProps} from "./definition";

interface RateDetailTransactionsProps {
  data: TransactionProps[];
  total: string;
  date: string;
};

const RateDetailTransactions: React.FC<RateDetailTransactionsProps> = ({ data, total, date }) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState([]);

  const handleOpenTab = (id: number) => {
    let newArr = [...isOpen];
    if(isOpen.includes(id)){
      newArr = isOpen.filter((item) => item !== id);
    }else {
      newArr.push(id);
    }
    setIsOpen(newArr);
  }

  return (
      <RateTransactionsWrapper>
        <RateTransactionHeader>
          <ReteTransactionDescription dataExist={!!date}>{date ? t('rates-financial-details-at-date') + " " + date: ""}</ReteTransactionDescription>
        </RateTransactionHeader>
        <TableContent role='table'>
          <TableHead role='row'>
            <ProcessedDate role='columnheader'>{t('rates-payment-description')}</ProcessedDate>
            <ColumnGroup role='columnheader'>
              <div>{t('rates-payment-amount')}</div>
            </ColumnGroup>
          </TableHead>
          <AccordionTransactionWrapper>
            {
              data?.map((item, index) => {
                return (
                  <AccordionTransactionContainer key={index} role='row'>
                    <TransactionItem item={item} handleOpenTab={handleOpenTab} isOpen={isOpen} index={index}/>
                  </AccordionTransactionContainer>
                )
              })
            }
          </AccordionTransactionWrapper>
          <TableBottomWrapper>
            <TableBottom>
              <TotalOutstanding>{t('rates-financial-total-outstanding')}</TotalOutstanding>
              <ColumnGroup>
                <div>{total || '$0'}</div>
              </ColumnGroup>
            </TableBottom>
          </TableBottomWrapper>
        </TableContent>
      </RateTransactionsWrapper>
  );
}

export default RateDetailTransactions;