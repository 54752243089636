import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Metadata, { MetadataThemeConfig } from '_utils/components/CityConnect/Metadata';
import NavItem from '_components/Corporate/NavItem';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import { Grid } from '_utils/components/Corporate';
import SvgVector from '_utils/icons/Vector';
import {
  CardSection,
  MainInformation,
  ServiceRequestCardWrapper,
  SubItem,
  SubItemDescription,
  SubItemImage,
  SubItemTitle,
  SubItemWrapper,
  SubTypeContainer,
  SubTypeUpdatedTime,
  TitleWrapper
} from './StyledServiceRequestCard';
import { greyOutStatus } from '../../definitions';

type ServiceRequestCardProps = {
  data?: any;
};

const ServiceRequestCard: React.FC<ServiceRequestCardProps> = ({ data }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const renderRequestTypeDescription = (metadata) => {
    return (
      metadata?.length > 0 &&
      metadata.map((data, index) => (
        <Metadata
          key={index}
          text={data.value}
          metadataType={data.type}
          metadataTheme={MetadataThemeConfig.Default}
          className="request-type-card__description"
        />
      ))
    );
  };

  const renderSubItemDescription = (metadata) => {
    return (
      metadata?.length > 0 &&
      metadata.map((data, index) => (
        <Metadata
          key={index}
          text={data.value}
          metadataType={data.type}
          metadataTheme={MetadataThemeConfig.Default}
          className="request-type-card__description"
        />
      ))
    );
  };

  const renderSubItems = () => {
    const subItems = data?.subitems;
    return (
      subItems?.length > 0 &&
      subItems?.map((item, index) => (
        <SubItem key={index} status={item?.status}>
          {item?.image && (
            <SubItemImage src={item?.image?.src} alt={item?.image?.alt}></SubItemImage>
          )}
          <Grid.Row config={{ gutters: [] }} style={{ flex: 1, width: '100%' }}>
            <Grid.Item config={{ col: { xs: 12, md: 8 }, gutters: [] }} pr={1}>
              {item?.title && (
                <SubItemTitle role="heading" aria-level="4">
                  {item?.title}
                </SubItemTitle>
              )}
              {item?.metadata && (
                <SubItemDescription
                  isSubItemImg={Boolean(item?.image)}
                  isSubItemTitle={Boolean(item?.title)}
                >
                  {renderSubItemDescription(item?.metadata)}
                </SubItemDescription>
              )}
            </Grid.Item>
            <Grid.Item config={{ col: { xs: 12, md: 4 }, gutters: [] }} pr={1}>
              <SubTypeContainer>
                <StatusTag
                  text={item?.status?.toLowerCase()}
                  displayStatusText={item?.displayStatus}
                />
                <SubTypeUpdatedTime>{item?.lastUpdated}</SubTypeUpdatedTime>
              </SubTypeContainer>
            </Grid.Item>
          </Grid.Row>
        </SubItem>
      ))
    );
  };

  const handleClickOnCard = (e) => {
    e.preventDefault();
    if (data?.link) {
      history.push(data.link);
    }
  };
  return (
    <ServiceRequestCardWrapper
      isGray={greyOutStatus.includes(data?.status?.toLowerCase())}
      onClick={handleClickOnCard}
    >
      <CardSection>
        <MainInformation.ReferenceText>
          {t('tab-requests-and-applications-reference')} {data?.referenceNumber}
        </MainInformation.ReferenceText>
        <TitleWrapper role="heading" aria-level="3">
          <NavItem
            link={{
              url: data?.link || ''
            }}
            className="card-title"
            StyledLink={MainInformation.Title}
            description={data?.title}
          >
            <span>{data?.title}</span>
            <SvgVector />
          </NavItem>
        </TitleWrapper>
        {data?.metadata && (
          <MainInformation.Address>
            {renderRequestTypeDescription(data?.metadata)}
          </MainInformation.Address>
        )}
        <MainInformation.SubmissionTime>
          {t('tab-requests-and-applications-submitted')} {data?.submitted}
        </MainInformation.SubmissionTime>

        <SubItemWrapper>{renderSubItems()}</SubItemWrapper>
      </CardSection>
    </ServiceRequestCardWrapper>
  );
};

export default ServiceRequestCard;
