import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';
import { heading_m, heading_s, heading_xl, heading_xxl, text_m, text_s } from '_utils/styles/';
import { color, mq } from '_utils/styles';

export const Container = styled.div`
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

export const UpperBanner = styled.div`
  ${({ theme }) => spacing({ theme, px: 6, py: 8 })};
  background-color: ${color('all.blue')};
`;

export const UpperTitle = styled.p`
  ${heading_xxl};
  color: #ffffff;
`;

export const UpperDescription = styled.p`
  ${text_m}
  ${({ theme }) => spacing({ theme, my: 2 })};
  font-weight: 400;
  color: #ffffff;
`;

export const UpperStatus = styled.div`
  ${text_s}
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #ffffff;
  ${({ theme }) => spacing({ theme, m: 0 })};
  & svg {
    width: 24px;
    ${({ theme }) => spacing({ theme, mr: 1 })};
  }
`;

export const LowerBanner = styled.div`
  background-color: white;
  ${({ theme }) => spacing({ theme, px: 9, py: 5 })};
  ${mq.lessThan('lg')} {
    ${({ theme }) => spacing({ theme, px: 6, py: 5 })};
  }
`;

export const LowerQuestion = styled.p`
  ${heading_m};
  ${mq.lessThan('md')}{
    line-height: 24px;
    ${(props) => (props.isEmpty ? heading_xl : '')}
    margin-bottom: ${(props) => (props.isEmpty ? '16px' : '')}
  }
  color: #041C2C;
  ${({ theme }) => spacing({ theme, mb: 4 })};
`;

export const LowerOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContinueButton = styled.a`
  text-decoration: none;
  border: none;
  background: #188838;
  border-radius: 28px;
  cursor: pointer;
  ${({ theme }) => spacing({ theme, px: 2, py: 1 })};
  margin-top: ${(props) => (props.isEdit ? '32px !important;' : '40px !important;')};
  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, px: 2, py: 1 })};
    margin-top: ${(props) => (props.isEdit ? '24px !important;' : '32px !important;')};
  }
  text-align: center;
  ${heading_s};
  color: #ffffff;
  display: flex;
  align-items: center;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
      cursor: auto;
    `}
`;

export const AddNewWrapper = styled.button`
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  ${heading_s};
  color: #188838;
  cursor: pointer;
  & svg {
    ${({ theme }) => spacing({ theme, mr: 1 })};
  }
  margin-top: ${(props) => (props.isEdit ? '32px !important;' : '40px !important;')};
  ${mq.lessThan('md')} {
    margin-top: ${(props) => (props.isEdit ? '24px !important;' : '32px !important;')};
  }
`;

export const LoadingContent = styled.div`
  background: #f9f9f9;
  border: 1px solid #9095a1;
  border-radius: 8px;
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshWrapper = styled.span`
  margin-left: 8px;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.card.action};
  }
  svg {
    ${RotateCss};
    overflow: visible;
    ${mq('md')} {
      width: 24px;
    }
  }
`;
