import { spacing } from '_utils/props';
import {
  color,
  cssInlineSvg,
  heading_l,
  listReset,
  mq,
  text_s,
  text_xscap,
  svg,
  heading_s,
  text_m,
  button,
  safeFocusRemoval,
  buttonReset,
  focusOutline,
  heading_xs
} from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { Grid } from '_utils/components/Corporate';
import { FONT_FAMILY_TEXT } from '_containers/Theme/theme';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { externalLinkStyle } from "_components/Corporate/ContentRichText/StyledContentRichText";

export const Container = styled.div`
  width: 100%;
  background-color: ${color('all.white')};
  border-top: 1px solid ${color('all.deepBlueTint15')};
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const CreatePaymentDescription = styled.div`
  ${text_m}
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, m: 0 })}
`;

export const SvgWrapper = styled.span`
  ${svg(20, 20)}
  ${({ theme }) => spacing({ theme, ml: 1 })};
  width: 20px;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  path {
    fill: #188838;
  }
`;

export const CreatePaymentButton = styled.button`
  ${buttonReset};
  ${button('default', 'outline')};
  padding: 6px 0px;
  ${({ theme }) => spacing({ theme, px: 2 })};
  ${safeFocusRemoval};
  ${heading_xs};
  margin: 0;
  &:hover {
    ${SvgWrapper} {
      path {
        fill: #005a0b;
      }
    }
  }
`;

export const CardTitle = styled.h2`
  ${heading_l};
  ${({ theme }) => spacing({ theme, m: 0, mt: 7 })}
`;

export const ReferenceText = styled(ContentWrapper)`
  ${text_xscap}
  ${({ theme }) => spacing({ theme, mb: 0 })}
  color: ${color('all.deepBlueTint75')};
`;

export const PaymentDetailsContainer = styled(Grid.Row)`
  /* display: flex; */
`;

export const ItemContainer = styled(Grid.Item)`
  ${({ theme }) => spacing({ theme, mt: 3 })}
  display: flex;

  ${mq('sm')} {
    &:nth-child(2n + 1) {
      padding-right: 16px;
    }

    &:nth-child(2n) {
      padding-left: 16px;
    }
  }
`;

export const ItemIconContainer = styled.div``;

export const ItemIcon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  & path {
    fill: ${color('all.deepBlueTint75')};
  }

  ${mq('md')} {
    ${svg(24, 24)}
    width: 24px;
  }
`;

export const ItemContent = styled.div`
  flex-grow: 1;
  ${({ theme }) => spacing({ theme, ml: 2 })}
`;

export const ItemLabel = styled.div`
  ${heading_s}
  font-family: ${FONT_FAMILY_TEXT};
`;

export const ItemValue = styled.div`
  ${text_s}
  color: ${color('all.deepBlueTint75')};
  margin: 0;
`;

export const ValueRichText = styled(RichText)`
  span {
    margin-right: 16px;
  }

  .external-link {
    ${externalLinkStyle}
  }
`;

export const CardBottom = styled(ContentWrapper)`
  border-top: 1px solid ${color('all.deepBlueTint15')};
  border-bottom: 1px solid ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, py: 3 })};
`;

export const BottomDivider = styled.hr`
  height: 1px;
  border: initial;
  background-color: ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, m: 0, mt: 7 })};
`;

export const LinkButton = styled.button`
  ${buttonReset};
  ${text_s};
  margin: 0;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: #188838;
  }

  &:visited {
    color: #6009a0;
  }

  &:focus {
    ${focusOutline};
  }
`;

export const LinkList = styled.ul`
  ${listReset}
  display: flex;
  width: 100%;
  flex-direction: column;

  ${mq('sm')} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    position: relative;
    padding-left: 24px;
    display: flex;
    ${({ theme }) => spacing({ theme, pr: 2 })};

    a {
      display: flex;
    }

    ${mq.lessThan('sm')} {
      &:not(:last-child) {
        ${({ theme }) => spacing({ theme, mb: 2 })};
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      background-image: ${cssInlineSvg(
        `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7667 5.68328C11.727 5.58099 11.6675 5.48754 11.5917 5.40828L6.59166 0.408284C6.51396 0.330586 6.42172 0.268952 6.3202 0.226902C6.21868 0.184851 6.10987 0.163208 5.99999 0.163208C5.77807 0.163208 5.56524 0.251365 5.40832 0.408284C5.33062 0.485983 5.26899 0.578225 5.22694 0.679743C5.18489 0.781262 5.16325 0.890068 5.16325 0.999951C5.16325 1.22187 5.2514 1.4347 5.40832 1.59162L8.99166 5.16662H0.99999C0.778976 5.16662 0.567015 5.25442 0.410734 5.4107C0.254454 5.56698 0.166656 5.77894 0.166656 5.99995C0.166656 6.22096 0.254454 6.43293 0.410734 6.58921C0.567015 6.74549 0.778976 6.83328 0.99999 6.83328H8.99166L5.40832 10.4083C5.33022 10.4858 5.26822 10.5779 5.22591 10.6795C5.18361 10.781 5.16182 10.8899 5.16182 10.9999C5.16182 11.11 5.18361 11.2189 5.22591 11.3204C5.26822 11.422 5.33022 11.5141 5.40832 11.5916C5.48579 11.6697 5.57796 11.7317 5.67951 11.774C5.78106 11.8163 5.88998 11.8381 5.99999 11.8381C6.11 11.8381 6.21892 11.8163 6.32047 11.774C6.42202 11.7317 6.51419 11.6697 6.59166 11.5916L11.5917 6.59162C11.6675 6.51236 11.727 6.41891 11.7667 6.31662C11.85 6.11373 11.85 5.88617 11.7667 5.68328Z" fill="#188838"/></svg>
        `
      )};
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;
