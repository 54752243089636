import React from 'react';
import { withSvg } from '../Svg';

const SvgRightArrow = () => (
  <path
    d="M18.92 11.6199C18.8724 11.4972 18.801 11.385 18.71 11.2899L12.71 5.28994C12.6168 5.1967 12.5061 5.12274 12.3842 5.07228C12.2624 5.02182 12.1319 4.99585 12 4.99585C11.7337 4.99585 11.4783 5.10164 11.29 5.28994C11.1968 5.38318 11.1228 5.49387 11.0723 5.61569C11.0219 5.73751 10.9959 5.86808 10.9959 5.99994C10.9959 6.26624 11.1017 6.52164 11.29 6.70994L15.59 10.9999H6C5.73478 10.9999 5.48043 11.1053 5.29289 11.2928C5.10536 11.4804 5 11.7347 5 11.9999C5 12.2652 5.10536 12.5195 5.29289 12.707C5.48043 12.8946 5.73478 12.9999 6 12.9999H15.59L11.29 17.2899C11.1963 17.3829 11.1219 17.4935 11.0711 17.6154C11.0203 17.7372 10.9942 17.8679 10.9942 17.9999C10.9942 18.132 11.0203 18.2627 11.0711 18.3845C11.1219 18.5064 11.1963 18.617 11.29 18.7099C11.383 18.8037 11.4936 18.8781 11.6154 18.9288C11.7373 18.9796 11.868 19.0057 12 19.0057C12.132 19.0057 12.2627 18.9796 12.3846 18.9288C12.5064 18.8781 12.617 18.8037 12.71 18.7099L18.71 12.7099C18.801 12.6148 18.8724 12.5027 18.92 12.3799C19.02 12.1365 19.02 11.8634 18.92 11.6199Z"
    fill="#188838"
  />
);

export default withSvg(20, 20)(SvgRightArrow);
