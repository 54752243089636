import React from 'react';
import { withSvg } from '../Svg';

const SvgLinkedIn = () => (
  <path
    d="M18.4701 0.000139831H1.53006C1.33964 -0.00250479 1.15056 0.0323873 0.973624 0.102824C0.796689 0.17326 0.635362 0.27786 0.498856 0.410652C0.36235 0.543443 0.25334 0.701824 0.178051 0.876749C0.102761 1.05167 0.062667 1.23972 0.0600586 1.43014V18.5701C0.062667 18.7606 0.102761 18.9486 0.178051 19.1235C0.25334 19.2985 0.36235 19.4568 0.498856 19.5896C0.635362 19.7224 0.796689 19.827 0.973624 19.8975C1.15056 19.9679 1.33964 20.0028 1.53006 20.0001H18.4701C18.6605 20.0028 18.8496 19.9679 19.0265 19.8975C19.2034 19.827 19.3648 19.7224 19.5013 19.5896C19.6378 19.4568 19.7468 19.2985 19.8221 19.1235C19.8974 18.9486 19.9375 18.7606 19.9401 18.5701V1.43014C19.9375 1.23972 19.8974 1.05167 19.8221 0.876749C19.7468 0.701824 19.6378 0.543443 19.5013 0.410652C19.3648 0.27786 19.2034 0.17326 19.0265 0.102824C18.8496 0.0323873 18.6605 -0.00250479 18.4701 0.000139831ZM6.09006 16.7401H3.09006V7.74014H6.09006V16.7401ZM4.59006 6.48014C4.17632 6.48014 3.77953 6.31578 3.48697 6.02323C3.19442 5.73067 3.03006 5.33388 3.03006 4.92014C3.03006 4.5064 3.19442 4.10961 3.48697 3.81705C3.77953 3.5245 4.17632 3.36014 4.59006 3.36014C4.80975 3.33522 5.03224 3.35699 5.24293 3.42402C5.45363 3.49105 5.6478 3.60183 5.81272 3.7491C5.97763 3.89637 6.10958 4.07682 6.19993 4.27862C6.29028 4.48043 6.33698 4.69904 6.33698 4.92014C6.33698 5.14124 6.29028 5.35985 6.19993 5.56166C6.10958 5.76346 5.97763 5.94391 5.81272 6.09118C5.6478 6.23845 5.45363 6.34923 5.24293 6.41626C5.03224 6.48329 4.80975 6.50505 4.59006 6.48014ZM16.9101 16.7401H13.9101V11.9101C13.9101 10.7001 13.4801 9.91014 12.3901 9.91014C12.0527 9.91261 11.7242 10.0184 11.4489 10.2133C11.1735 10.4082 10.9645 10.6828 10.8501 11.0001C10.7718 11.2352 10.7379 11.4827 10.7501 11.7301V16.7301H7.75006C7.75006 16.7301 7.75006 8.55014 7.75006 7.73014H10.7501V9.00014C11.0226 8.52725 11.419 8.13766 11.8965 7.87334C12.374 7.60902 12.9146 7.47999 13.4601 7.50014C15.4601 7.50014 16.9101 8.79014 16.9101 11.5601V16.7401Z"
    fill="white"
  />
);

export default withSvg(20, 20)(SvgLinkedIn);
