import React from 'react';
import { withSvg } from '../Svg';

const SvgEmail = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8334 3.33337H4.16675C3.50371 3.33337 2.86782 3.59677 2.39898 4.06561C1.93014 4.53445 1.66675 5.17033 1.66675 5.83337V14.1667C1.66675 14.8297 1.93014 15.4656 2.39898 15.9345C2.86782 16.4033 3.50371 16.6667 4.16675 16.6667H15.8334C16.4965 16.6667 17.1323 16.4033 17.6012 15.9345C18.07 15.4656 18.3334 14.8297 18.3334 14.1667V5.83337C18.3334 5.17033 18.07 4.53445 17.6012 4.06561C17.1323 3.59677 16.4965 3.33337 15.8334 3.33337V3.33337ZM4.16675 5.00004H15.8334C16.0544 5.00004 16.2664 5.08784 16.4227 5.24412C16.579 5.4004 16.6667 5.61236 16.6667 5.83337L10.0001 9.90004L3.33341 5.83337C3.33341 5.61236 3.42121 5.4004 3.57749 5.24412C3.73377 5.08784 3.94573 5.00004 4.16675 5.00004V5.00004ZM16.6667 14.1667C16.6667 14.3877 16.579 14.5997 16.4227 14.756C16.2664 14.9122 16.0544 15 15.8334 15H4.16675C3.94573 15 3.73377 14.9122 3.57749 14.756C3.42121 14.5997 3.33341 14.3877 3.33341 14.1667V7.73337L9.56675 11.5417C9.69343 11.6148 9.83713 11.6534 9.98341 11.6534C10.1297 11.6534 10.2734 11.6148 10.4001 11.5417L16.6667 7.73337V14.1667Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgEmail);
