import React from 'react';
import { withSvg } from '../Svg';

const SvgSortAmountDown = () => (
  <path
    d="M13.2392 2H1.50438C1.23917 2 0.984812 1.89464 0.797276 1.70711C0.609739 1.51957 0.504383 1.26522 0.504383 1C0.504383 0.734784 0.609739 0.48043 0.797276 0.292893C0.984812 0.105357 1.23917 0 1.50438 0H13.2392C13.5044 0 13.7587 0.105357 13.9463 0.292893C14.1338 0.48043 14.2392 0.734784 14.2392 1C14.2392 1.26522 14.1338 1.51957 13.9463 1.70711C13.7587 1.89464 13.5044 2 13.2392 2ZM1.50438 5H9.50438C9.76959 5 10.0239 5.10536 10.2115 5.29289C10.399 5.48043 10.5044 5.73478 10.5044 6C10.5044 6.26522 10.399 6.51957 10.2115 6.70711C10.0239 6.89464 9.76959 7 9.50438 7H1.50438C1.23917 7 0.984812 6.89464 0.797276 6.70711C0.609739 6.51957 0.504383 6.26522 0.504383 6C0.504383 5.73478 0.609739 5.48043 0.797276 5.29289C0.984812 5.10536 1.23917 5 1.50438 5ZM1.50438 10H6.50438C6.7696 10 7.02395 10.1054 7.21148 10.2929C7.39902 10.4804 7.50438 10.7348 7.50438 11C7.50438 11.2652 7.39902 11.5196 7.21148 11.7071C7.02395 11.8946 6.7696 12 6.50438 12H1.50438C1.23917 12 0.984812 11.8946 0.797276 11.7071C0.609739 11.5196 0.504383 11.2652 0.504383 11C0.504383 10.7348 0.609739 10.4804 0.797276 10.2929C0.984812 10.1054 1.23917 10 1.50438 10Z"
    fill="black"
  />
);

export default withSvg(15, 12)(SvgSortAmountDown);
