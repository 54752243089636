import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { listReset, heading_l, text_s, depth, color, text_m, button, svg, mq } from '_utils/styles';
import { spacing } from '_utils/props';
import { TRANSITION_DURATION } from '.';

export const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.menu.container.background};
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 48px);
  height: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(100%);
  transition: transform ${() => TRANSITION_DURATION + 'ms'} cubic-bezier(0.42, 0, 0.59, 1);
  z-index: ${depth.MENU};
  pointer-events: auto;
  overflow-y: auto;
  color: ${color('all.white')};

  &.active {
    transform: translateX(0);
  }

  @media (min-width: 524px) {
    width: 476px;
  }
`;

export const UserContainer = styled.nav`
  width: 100%;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => spacing({ theme, pt: 6, px: 6, pb: 3 })};
`;

export const UserIconContainer = styled.span`
  ${svg(24, 24)}
  width: 24px;
  & svg {
    fill: ${color('all.white')};
  }
  ${mq('md')} {
    ${svg(32, 32)};
    width: 32px;
  }
`;

const EllipsisText = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FullName = styled(EllipsisText)`
  ${heading_l}
  ${({ theme }) => spacing({ theme, mt: 1 })};
  ${EllipsisText}
`;

export const Email = styled(EllipsisText)`
  ${text_m}
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};
  ${EllipsisText}
`;

export const LogoutButton = styled.button`
  ${button('default', 'primary')};
  ${({ theme }) => spacing({ theme, mt: 3 })};
`;

export const List = styled.ul`
  ${listReset};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 476px;
  height: auto;
`;

export const Item = styled.li`
  color: ${({ theme }) => theme.colors.menu.item.color};
  display: block;
  flex: none;
`;

export const Divider = styled.li`
  background-color: ${({ theme }) => theme.colors.menu.divider};
  ${({ theme }) => spacing({ theme, my: 2 })};
  height: 1px;
  flex: none;
`;

export const Link = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  ${({ theme }) => spacing({ theme, py: 2, px: 6 })};
  outline: 0;
  display: block;

  body:not(.safe-focus-removal) &:focus {
    background-color: ${({ theme }) => theme.colors.menu.item.backgroundActive};
  }

  &[href] {
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.colors.menu.item.backgroundActive};
    }
    transition: background-color 0.1s linear;
  }
`;

export const Label = styled.span`
  ${heading_l};
  line-height: 1.2 !important;
  display: block;
  overflow: hidden;

  ${Link}[href]:active &,
  ${Link}[href]:hover & {
    color: ${({ theme }) => theme.colors.menu.item.colorActive};
  }
`;

export const Description = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.menu.item.description};
  ${text_s}
  overflow: hidden;
  
  ${({ theme }) => spacing({ theme, mt: 1, mb: 0 })};
  &:first-of-type {
    margin-top: 0;
  }

  body:not(.safe-focus-removal) ${Link}[href]:focus & {
    color: ${({ theme }) => theme.colors.menu.item.descriptionActive};
  }

  ${Link}[href]:active &,
  ${Link}[href]:hover & {
    color: ${({ theme }) => theme.colors.menu.item.descriptionActive};
  }
`;

export const LoginOrRegisterContainer = styled.div`
  width: 100%;
  & iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
`;

export const DisplayNone = styled.div`
  height: 0;
  overflow: hidden;
  & iframe {
    display: none;
  }
`;
