import styled from 'styled-components';
import { spacing } from '_utils/props';
import { mq, text_s, heading_m } from '_utils/styles';

export const RateHistoryWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};
`;

export const RateDetailHistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #dddee1;
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};

  &:nth-child(2n) {
    background: #f0f1f2;
  }

  ${mq.lessThan('md')} {
    display: block;
  }
`;

export const RateDetailHistoryText = styled.div`
  ${() => text_s}
  font-style: normal;
  font-weight: normal;
  margin: 0;
  max-width: 70%;
  ${({ theme }) => spacing({ theme, mr: 3 })};
  ${mq.lessThan('md')} {
    max-width: 100%;
    margin-right: 0;
    padding-bottom: 4px;
  }
`;

export const RateDetailHistoryAmount = styled.div`
  ${() => heading_m}
  font-style: normal;
  font-weight: bold;
  margin: 0;

  ${mq.lessThan('md')} {
    text-align: right;
  }
`;
