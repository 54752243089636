import React from 'react';
import { Grid } from '_utils/components/Corporate';
import { Primary, Secondary } from './StyledTwoColumnsFrame';
import { withCurve } from '_utils/components/CityConnect/SublayoutWithCurve';

type TwoColumnsFrameProps = {
  LeftComponent?: any;
  RightComponent?: any;
  showCurved?: boolean;
};

const Container: React.FC<any> = ({ LeftComponent, RightComponent }) => {
  return (
    <Grid.Container>
      <Grid.Row config={{ gutters: ['left', 'right'] }}>
        <Primary
          config={{ col: { xs: 12, md: 8, lg: 8 }, gutters: ['left', 'right'] }}
          mb={{ xs: LeftComponent ? 4 : 0, md: 0 }}
        >
          {LeftComponent}
        </Primary>
        <Secondary config={{ col: { xs: 12, md: 4, lg: 4 }, gutters: ['left', 'right'] }}>
          {RightComponent}
        </Secondary>
      </Grid.Row>
    </Grid.Container>
  );
};

const TwoColumnsFrame: React.FC<TwoColumnsFrameProps> = (props) => {
  const WithCurvedBorder = withCurve(Container);

  return <>{props.showCurved ? <WithCurvedBorder {...props} /> : <Container {...props} />}</>;
};

export default TwoColumnsFrame;
