import { spacing } from '_utils/props';
import { mq } from '_utils/styles';
import styled from 'styled-components';

export const RegistrationPageWrapper = styled.div`
  .RegistrationPage__RightComponent {
    display: flex;
    flex-direction: column;

    & > a {
      ${({ theme }) => spacing({ theme, mt: 4 })};
    }
  }
`;

export const Section = styled.div`
  ${({ theme }) => spacing({ theme, pb: 7 })};
`;

export const PermitListItems = styled.div`
  padding-top: 0;
`;
