import React from 'react';

const SvgCurve = () => (
  <svg
    width="1920"
    height="160"
    viewBox="0 0 1920 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path d="M960 160C1516.7 160 1968 88.3656 1968 0H-48C-48 88.3656 403.297 160 960 160Z" />
  </svg>
);

export default SvgCurve;
