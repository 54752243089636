import React from "react";
import {
    ItemContainer,
    RadioText,
    CollapsedWrapper
} from "./StyledTab";
import { Radio } from "_utils/components/Corporate";
import { simpleTabProps } from "../definition";

const SimpleTab:React.FC<simpleTabProps> = ({selectedValue, handleChange, item}) => {
    const styleActive = {
        background: '#DAF5E4',
        border: '2px solid #2DB84B'
      }

    return(
        <ItemContainer onClick={() => handleChange(item.value)} 
        style={selectedValue === item.value ? styleActive : {}}>
            <CollapsedWrapper>
                <Radio
                    id={'sort-item' + item.value}
                    name={`option-${item.value}`}
                    value={item.value}
                    onChange={() => handleChange(item.value)}
                    checked={selectedValue === item.value}
                >
                    <RadioText>{item.label}</RadioText>
                </Radio>
            </CollapsedWrapper>
        </ItemContainer>
    )
}

export default SimpleTab;