import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';

import { Arrow } from '_utils/icons';
import Metadata, { MetadataThemeConfig } from '_utils/components/CityConnect/Metadata';
import {
  // INFO: Card Wrapper
  CardWrapper,
  CardHeader,
  ImageWrapper,
  Header,
  ArrowWrapper,
  // INFO: Card Content: Reference Number, Metadata and Category Link
  CardContent,
  ReferenceNumber,
  MetadataWrapper,
  CategoryLink
} from './StyledPropertyCard';

const PropertyCard: React.FC<any> = (data) => {
  let { link, metadata, image, referenceNumber, streetAddress, suburb } = data.data;
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <CardWrapper onClick={() => history.push(link)}>
      <CardHeader>
        <ImageWrapper>
          <Image field={image} />
        </ImageWrapper>
        <Header>
          <CategoryLink name={streetAddress} role="link" value={streetAddress} tabIndex={0}>
            <Text field={{ value: `${streetAddress}, ${suburb}` }} />
          </CategoryLink>
          <ArrowWrapper>
            <Arrow ariaHidden={false} />
          </ArrowWrapper>
        </Header>
      </CardHeader>
      <CardContent>
        <ReferenceNumber>{`${t(
          'tab-rates-and-properties-reference-number'
        )} ${referenceNumber}`}</ReferenceNumber>
        {metadata?.map((item, index) => {
          return (
            <MetadataWrapper key={index}>
              <Metadata
                metadataType={item.type}
                text={item.value}
                metadataTheme={MetadataThemeConfig.Default}
              />
            </MetadataWrapper>
          );
        })}
      </CardContent>
    </CardWrapper>
  );
};

export default PropertyCard;
