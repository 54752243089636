import styled from 'styled-components';
import { spacing } from "_utils/props";
import { mq } from "_utils/styles";
import {
  heading_l,
  text_m
} from "_utils/styles";
import { Text } from "@sitecore-jss/sitecore-jss-react";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #F2ECE7;
    ${({ theme }) => spacing({ theme, px: 6, py: 7 })}
`

export const Title = styled(Text)`
  ${heading_l}
  ${({ theme }) => spacing({ theme, mb: 3 })}
  ${mq.lessThan('md')}{
    line-height: 24px;
  }
`

export const Description = styled(Text)`
  ${text_m}
  ${({ theme }) => spacing({ theme, mb: 3, mt: 0 })}
`

export const MapContainer = styled.div`
  width: 100%;
  ${mq('xs')}{
     height: 397.15px;
  }
  ${mq('sm')}{
     height: 768.59px;
  }
  ${mq('md')}{
     height: 600px;
  }
  ${mq('lg')}{
     height: 600px;
  }
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  div.gm-style-iw{
    max-height: unset !important;
  }
  div.gm-style-iw-d{
    max-height: unset !important;
    overflow: hidden !important;
  }
  div.gm-style-iw-chr{
    display: none !important;
  }
`;

export const LocationButton = styled.button`
  width: 40px;
  cursor: pointer;
  height: 40px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => spacing({ theme, mt: 2, p: 1, mr: 3 })}
  border: 1px solid #041C2C;
  border-radius: 8px;
  & svg {
   width: 24px;
  }
`
export const ZoomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => spacing({ theme, mt: 3, mr: 3 })}
`;

export const ZoomButton = styled.button`
  width: 40px;
  cursor: pointer;
  height: 40px;
  background: #FFFFFF;
  justify-content: center;
  align-items: center;
  ${({ theme }) => spacing({ theme, p: 1 })}
  border: 1px solid #041C2C;
  & svg {
   width: 24px;
  }
  &:first-child{
     border-top-left-radius: 8px;
     border-top-right-radius: 8px;
     border-bottom: none;
  }
  &:last-child{
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
  }
`

