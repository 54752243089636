import React, { Component, ReactNode, FC } from 'react';

interface Props {
  children: ReactNode;
  fallbackUI: FC;
  fallbackData: object;
}

interface IErrorBoundary {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, IErrorBoundary> {
  public state: IErrorBoundary = {
    hasError: false
  };

  public static getDerivedStateFromError(error: Error): IErrorBoundary {
    return { hasError: true };
  }

  public render() {
    const FallbackUI = this.props.fallbackUI;
    if (!this.state.hasError) {
      return this.props.children;
    } else {
      return <FallbackUI {...this.props.fallbackData} />;
    }
  }
}

// HOC wrapping the passed component
export default function withErrorBoundary(WrappedComponent: FC<any>, FallbackUI: FC<any>) {
  const withErrorBoundary = (props) => (
    <ErrorBoundary fallbackUI={FallbackUI} fallbackData={props}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
  return withErrorBoundary;
}
