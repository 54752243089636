import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DetailItem,
  SearchAddressContainer,
  SearchItem,
  SearchItemContainer,
  SearchPanel
} from './StyledLgaAddress';
import { getLgaAddress } from '_services/ratesCalculatorService';
import ClickAwayListener from '_utils/components/CityConnect/ClickAwayListener';

type LgaAddressCardProps = {
  inputName: string;
  clearValue: boolean;
  isError: boolean;
  disabled?: boolean;
  callbackFn: (propertyKey: string) => void;
  setClearValue: (clear: boolean) => void;
};

type SearchResultItem = {
  propertyKey: number;
  spatialKey: number;
  fullAddress: string;
};

const LgaAddressCard: React.FC<LgaAddressCardProps> = ({
  inputName,
  clearValue,
  isError,
  disabled = false,
  setClearValue,
  callbackFn
}) => {
  const [t] = useTranslation();

  const [formValue, setFormValue] = useState<string>('');
  const [isSearchAble, setIsSearchAble] = useState<boolean>(false);
  const [isShowSearchResult, setIsShowSearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResultItem[]>([]);

  // trigger search and remove trigger
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSearchAble) {
        calculateSearchAddress(formValue);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [formValue]);

  useEffect(() => {
    if(clearValue){
      setFormValue('');
      setSearchResult([]);
      setClearValue(false);
    }
  }, [clearValue]);

  // INFO: Handle state change of input
  const handleChange = (e) => {
    callbackFn('');
    const value = e.target.value;
    setFormValue(value);
  };

  // handle focus on input
  const handleFocus = () => {
    setIsSearchAble(true);
    setIsShowSearchResult(true);
  };

  // hide search result panel after focus out
  const handleBlur = () => {
    setIsSearchAble(false);
    if (isShowSearchResult) {
      setTimeout(() => setIsShowSearchResult(false), 200);
    }
  };

  // calculate search or do not search
  const calculateSearchAddress = (keyword: string) => {
    if (keyword.trim().length >= 4) {
      setIsShowSearchResult(true);
      getLgaAddress(
        keyword,
        (res) => setSearchResult(res?.data?.matchingProperties),
        () => {}
      );
    } else {
      setIsShowSearchResult(false);
      setSearchResult([]);
    }
  };

  // select a address item
  const selectAddressItem = (address) => {
    setFormValue(address.fullAddress);
    setIsShowSearchResult(false);
    callbackFn(address.propertyKey);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsShowSearchResult(false)}>
        <SearchAddressContainer>
          <DetailItem.TextField
            name={inputName}
            value={formValue}
            disabled={disabled}
            className={isError ? 'error' : ''}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {searchResult && searchResult.length > 0 && (
            <SearchPanel showPanel={isShowSearchResult}>
              <SearchItemContainer>
                {searchResult.map((address, index) => (
                  <SearchItem
                    key={index}
                    onClick={() => selectAddressItem(address)}
                    title={address.fullAddress}
                  >
                    <div>{address.fullAddress}</div>
                  </SearchItem>
                ))}
              </SearchItemContainer>
            </SearchPanel>
          )}
        </SearchAddressContainer>
      </ClickAwayListener>
    </>
  );
};

export default React.memo(LgaAddressCard);
