import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withEditMode } from '_containers/BaseComponent';
import { Section, Grid } from '_utils/components/Corporate';
import { BackgroundColors } from '_utils/components/Corporate/Section';

import { Primary, Secondary } from './StyledTwoColumn';

const TwoColumn = ({ rendering, params, editMode }) => {
  // Default
  let backgroundColor = BackgroundColors.White;

  const primaryChildren = rendering?.placeholders?.['jss-two-column-primary'] || [];

  const secondaryChildren = rendering?.placeholders?.['jss-two-column-secondary'] || [];

  const isEmpty = !editMode && primaryChildren.length === 0 && secondaryChildren.length === 0;

  try {
    backgroundColor = BackgroundColors[params.backgroundColour];
  } catch {}

  return (
    <Section className={isEmpty ? 'is-empty' : ''} sectionTheme={backgroundColor}>
      <Grid.Container>
        <Grid.Row config={{ gutters: ['left', 'right'] }}>
          <Secondary
            spacingUnit={3}
            config={{ order: { md: 2 }, col: { xs: 12, md: 5, lg: 4 }, gutters: ['left', 'right'] }}
            mb={{ xs: 7, md: 0 }}
          >
            <Placeholder name="jss-two-column-secondary" rendering={rendering} />
          </Secondary>
          <Primary
            config={{ order: { md: 1 }, col: { xs: 12, md: 7, lg: 8 }, gutters: ['left', 'right'] }}
          >
            <Placeholder name="jss-two-column-primary" rendering={rendering} />
          </Primary>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export default withEditMode(TwoColumn);
