import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NavItem from '_components/Corporate/NavItem';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import { Grid } from '_utils/components/Corporate';
import SvgVector from '_utils/icons/Vector';
import {
    CardSection,
    MainInformation,
    ServiceRequestCardWrapper,
    SubItem,
    CommunityIdeaSubItem,
    CommunityIdeaSubItemRow,
    SubItemTitle,
    SubItemWrapper,
    TitleWrapper,
    SubItemDes,
    TimeLeft,
    TitleContainer,
    Dot
} from './StyledConsultationsCard';
import { Clock } from '_utils/icons';
import { ConsultationIcon } from '_utils/components/CityConnect/ConsultationIcon/ConsultationIcon';
import OpinionCard from './OpinionCard';

type ConsultationsCardProps = {
    data?: any;
};

const ConsultationsCard: React.FC<ConsultationsCardProps> = ({ data }) => {
    const [t] = useTranslation();
    const history = useHistory();
    const url = data.link.replace(window.location.origin, '');
    const subItems = data?.subitems;

    const compareDates = (a, b) => {
        const dateA = new Date(a.lastUpdatedIso);
        const dateB = new Date(b.lastUpdatedIso);
        return dateB.getTime() - dateA.getTime();
    };
    const communityIdeaItems = subItems
        .filter((i) => i.type === 'community-ideas')
        .sort(compareDates);
    const restItems = subItems.filter((i) => i.type !== 'community-ideas').sort(compareDates);

    const renderSubItems = () => {
        return (
            <>
                {restItems.map((item, index) => (
                    <SubItem key={index}>
                        <Grid.Row
                            config={{ gutters: [] }}
                            style={{ flex: 1, alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <Grid.Item config={{ col: { xs: 12, sm: 8, md: 8 }, gutters: [] }} pr={1}>
                                {item?.title && (
                                    <SubItemTitle>
                                        <ConsultationIcon icon={item?.type} />
                                        {item?.title}
                                    </SubItemTitle>
                                )}
                                <MainInformation.SubmissionTime>
                                    {t('tab-consultations-submitted')}
                                    {' ' + t('tab-consultations-by') + ' ' + item.name}
                                    {item?.organizationName && ' ' + t('tab-consultations-on-behalf-of') + ' ' + item.organizationName}
                                    <Dot />
                                    {item.lastUpdated}
                                </MainInformation.SubmissionTime>
                                <SubItemDes>{item.description}</SubItemDes>
                            </Grid.Item>
                            <Grid.Item config={{ col: { xs: 12, sm: 4, md: 4 }, gutters: [] }} pr={1}>
                                {item.metadata && item.metadata.length > 0 && <OpinionCard item={item} />}
                            </Grid.Item>
                        </Grid.Row>
                    </SubItem>
                ))}

                {communityIdeaItems.length > 0 && (
                    <CommunityIdeaSubItem>
                        <Grid.Row
                            config={{ gutters: [] }}
                            style={{ flex: 1, alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <Grid.Item config={{ col: { xs: 12, sm: 8, md: 8 }, gutters: [] }} pr={1}>
                                {communityIdeaItems[0].title && (
                                    <SubItemTitle>
                                        <ConsultationIcon icon={communityIdeaItems[0].type} />
                                        {communityIdeaItems[0].title}
                                    </SubItemTitle>
                                )}
                            </Grid.Item>
                        </Grid.Row>

                        {communityIdeaItems.map((item, index) => (
                            <CommunityIdeaSubItemRow
                                key={index}
                                config={{ gutters: [] }}
                                style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'space-between' }}
                            >
                                <Grid.Item config={{ col: { xs: 12, sm: 8, md: 8 }, gutters: [] }} pr={1}>
                                    <MainInformation.CommunityIdeaSubmissionTime>
                                        {t('tab-consultations-submitted')}
                                        {' ' + t('tab-consultations-by') + ' ' + item.name}
                                        {item?.organizationName && ' ' + t('tab-consultations-on-behalf-of') + ' ' + item.organizationName}
                                        <Dot />
                                        {item.lastUpdated}
                                    </MainInformation.CommunityIdeaSubmissionTime>
                                    <SubItemDes>{item.description}</SubItemDes>
                                </Grid.Item>
                                <Grid.Item config={{ col: { xs: 12, sm: 4, md: 4 }, gutters: [] }} pr={1}>
                                    {item.metadata && item.metadata.length > 0 && <OpinionCard item={item} />}
                                </Grid.Item>
                            </CommunityIdeaSubItemRow>
                        ))}
                    </CommunityIdeaSubItem>
                )}
            </>
        );
    };

    const renderTitle = (data: any) => {
        return (
            <TitleContainer>
                {data?.status && (
                    <StatusTag
                        text={data?.status.toLowerCase()}
                        displayStatusText={data?.displayStatus}
                        isConsultation={true}
                    />
                )}
                <TitleWrapper role="heading" aria-level="3">
                    <NavItem
                        link={{
                            url,
                            type: url.startsWith('http') ? 'external' : 'internal'
                        }}
                        className="card-title"
                        StyledLink={MainInformation.Title}
                        description={data?.title}
                    >
                        <span>{data?.title}</span>
                        <SvgVector />
                    </NavItem>
                </TitleWrapper>
                {data?.metadata[0]?.value && data?.metadata[0]?.type === 'days-left' && (
                    <TimeLeft>
                        <Clock color="#4E5A5F" />
                        {data?.metadata[0]?.value === '1'
                            ? `${data.metadata[0].value} ${t('tab-consultations-day-left')}`
                            : `${data.metadata[0].value} ${t('tab-consultations-days-left')}`}
                    </TimeLeft>
                )}
            </TitleContainer>
        );
    };

    return (
        <ServiceRequestCardWrapper>
            <CardSection>
                {renderTitle(data)}
                <SubItemWrapper>{renderSubItems()}</SubItemWrapper>
            </CardSection>
        </ServiceRequestCardWrapper>
    );
};

export default ConsultationsCard;
