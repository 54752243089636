import React, { useRef } from 'react';
import ProfileCard from '../ProfileCard';

type ProfileCardProps = {
  data: any;
};

const ProfileCardList: React.FC<ProfileCardProps> = ({ data }) => {
  const isMultipleProfiles = useRef(data?.length > 1);

  return (
    <div>
      {data &&
        data.map((item, index) => (
          <ProfileCard key={index} data={item} multipleProfiles={isMultipleProfiles.current} />
        ))}
    </div>
  );
};

export default ProfileCardList;
