import { cssResponsiveSvg } from '_utils/styles/svg';
import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import { mq, text_m } from '_utils/styles';
import { FONT_FAMILY_DISPLAY } from '_containers/Theme/theme';

export const TabHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${({ colour, theme }) => theme.colors.all[colour]};
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;

  ${mq('md')} {
    height: 48px;
  }
`;

export const IconWrapper = styled.div`
  ${({ theme }) => spacing({ theme, ml: 3 })}
  ${({ iconConfig }) => {
    const { width, height } = iconConfig;
    return cssResponsiveSvg(width, height);
  }}
`;

export const TabBody = styled.div`
  border: 2px solid #dddee1;
  border-top: none;
  border-bottom: none;
  width: 100%;
  flex-grow: 1;
  min-height: 101px;

  ${mq('md')} {
    min-height: 117px;
  }
`;

export const Heading = styled.h2`
  font-family: ${FONT_FAMILY_DISPLAY};
  font-weight: bold;
  ${({ theme }) => spacing({ theme, m: 0, ml: 3, mr: 3, pt: 3 })}
  margin-bottom: 5px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.all.black};
  ${mq('md')} {
    font-size: 28px;
    ${({ theme }) => spacing({ theme, pt: 2 })};
  }
`;

export const PreCount = styled.span`
  width: 12px;
  display: inline-block;
`;

export const Count = styled.span`
  ${text_m};
  margin: 0;
  display: inline-block;
`;

const TabFooterCss = css`
  height: 32px;
  opacity: 0.1;

  position: absolute;
  bottom: 2px;
  right: -12px;
  left: -12px;
  z-index: 1;

  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #000000 100%);
`;

const TabFooterHoverCss = css`
  background: transparent;
  opacity: 1;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const TabFooter = styled.div`
  ${TabFooterCss}
`;

export const Line = styled.div`
  position: absolute;
  height: 0;
  bottom: 0;
  right: -12px;
  left: -12px;

  border-bottom: 2px solid #dddee1;
`;

const CurveLine = css`
  content: '';
  display: block;
  height: 34px;
  width: 14px;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
  border-radius: 0px 0px 20px 0px;
  border: 2px solid #dddee1;
  border-top: none;
  border-left: none;

  position: absolute;
  bottom: 0;
  z-index: 2;
  box-shadow: 0 14px 0 #fff;
`;

export const TabWrapper = styled.button`
  position: relative;
  width: 200px;
  background-color: #ffffff;
  flex-shrink: 0;
  border: none;
  padding: 0;
  text-align: start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover,
  &.active {
    ${Line} {
      display: none;
    }

    ${TabFooter} {
      ${TabFooterHoverCss}
    }

    ${TabBody} {
    }
  }

  &:hover::before,
  &.active::before {
    ${CurveLine}
    left: -12px;
  }

  &:hover::after,
  &.active::after {
    ${CurveLine}
    transform: matrix(-1, 0, 0, 1, 0, 0);
    right: -12.1px;
  }

  &:focus {
    outline: none;

    ${Line} {
      z-index: -1;
    }
  }

  &:focus-visible {
    ${TabHeader},
    ${TabBody} {
      border: 3px solid #4060ff;
    }
    ${TabHeader} {
      border-bottom: none;
    }

    ${TabBody} {
      border-top: none;
    }

    &::before,
    &::after {
      z-index: -2;
    }
  }

  ${mq('md')} {
    width: 262px;
  }
`;
