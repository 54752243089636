import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getPaymentsDueList } from '_services/paymentsDueListService';
import PaymentsSort from './PaymentsSort';
import PaymentDue from './PaymentDue';
import { IResponseTemplate } from './definitions';
import { IPaymentDueLoaded } from './PaymentDue/definitions';
import ShowMore from '_components/CityConnect/ShowMore';
import TextMessage from '../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
// import icons
import SvgSortDueFirst from '_utils/icons/PaymentsDueList/SortDueFirst';
import SortDueLast from '_utils/icons/PaymentsDueList/SortDueLast';
import SortAmountArrowDown from '_utils/icons/PaymentsDueList/SortAmountArrowDown';
import SortAmountDownAlt from '_utils/icons/PaymentsDueList/SortAmountDownAlt';
import SortAmountDown from '_utils/icons/PaymentsDueList/SortAmountDown';
// import styled
import {
  PaymentsDueListWrapper,
  PDL_ListAction,
  PDL_Divider,
  PDL_List,
  PDL_LoadingOrNoData,
  PDL_ShowMore,
  RefreshWrapper
} from './StyledPaymentsDueList';
import { Refresh } from '_utils/icons';

const DEFAULT_PAGE_SIZE = 10;
const SORT_TYPE = {
  DUE_FIRST: 'due-first',
  DUE_LAST: 'due-last',
  HIGHEST_AMOUNT: 'highest-amount',
  LEAST_AMOUNT: 'least-amount'
};
const INITIAL_RESPONSE_DATA = {
  sort: [],
  results: {
    totalItems: '0',
    currentPage: '1',
    pageSize: '10',
    nextPage: '',
    items: []
  },
  actions: []
};

const PaymentsDueList: React.FC = () => {
  const [t] = useTranslation();
  const [responseData, setResponseData] = useState<IResponseTemplate>(INITIAL_RESPONSE_DATA);
  const [listPaymentDueLoaded, setListPaymentDueLoaded] = useState<IPaymentDueLoaded[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const LIST_DATA_SORT = [
    {
      value: SORT_TYPE.DUE_FIRST,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.DUE_FIRST)?.link || '',
      label: t('payments-due-sort-options-due-first'),
      icon: <SvgSortDueFirst />
    },
    {
      value: SORT_TYPE.DUE_LAST,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.DUE_LAST)?.link || '',
      label: t('payments-due-sort-options-due-last'),
      icon: <SortDueLast />
    },
    {
      value: SORT_TYPE.LEAST_AMOUNT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.LEAST_AMOUNT)?.link || '',
      label: t('payments-due-sort-options-least-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDownAlt />
        </React.Fragment>
      )
    },
    {
      value: SORT_TYPE.HIGHEST_AMOUNT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.HIGHEST_AMOUNT)?.link || '',
      label: t('payments-due-sort-options-highest-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDown />
        </React.Fragment>
      )
    }
  ];

  // get data from api
  useEffect(() => {
    getPaymentsDueListFromAPI('');
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // update loaded payment due list
  useEffect(() => {
    const loadedMore: Array<IPaymentDueLoaded> = responseData.results.items;
    // check load more or load new
    if (responseData.results.currentPage === '1') {
      setListPaymentDueLoaded(loadedMore);
    } else {
      setListPaymentDueLoaded(listPaymentDueLoaded.concat(loadedMore));
    }
  }, [responseData]);

  // get payments due list from api
  const getPaymentsDueListFromAPI = (link: string) => {
    getPaymentsDueList(link, getPaymentsDueListSuccess, (err) => setError(err));
  };

  // get payments due list from api success
  const getPaymentsDueListSuccess = (response) => {
    const paymentsDue = response?.data?.paymentsDue;
    if (paymentsDue) {
      const results = paymentsDue.results;
      setResponseData({
        ...responseData,
        sort: paymentsDue.sort,
        results: {
          totalItems: results.totalItems?.toString(),
          currentPage: results.currentPage?.toString(),
          pageSize: results.pageSize?.toString(),
          nextPage: results.nextPage,
          items: results.items
        },
        actions: paymentsDue.actions
      });
    }
    setIsLoading(false);
    // reset show more text
    setIsLoadingMore(false);
  };

  // handle click on show more button
  const handleClickShowMore = () => {
    // call api to load more
    getPaymentsDueListFromAPI(responseData.results.nextPage);
    // set button show more with text loading...
    setIsLoadingMore(true);
  };

  // handle event change sort value
  const handleChangeSort = (link: string) => {
    // call api to get sorted data
    if (link) {
      getPaymentsDueListFromAPI(link);
    }
  };

  // render each payment due
  const renderPaymentsDue = () => {
    const data = listPaymentDueLoaded;
    return (
      data.length &&
      data.map((payment, index) => {
        return <PaymentDue key={index} payment={payment} isPaymentHistory={false} />;
      })
    );
  };

  // check conditions to render corresponding content
  const handleShowPaymentDueContent = () => {
    return isLoading ? (
      <PDL_LoadingOrNoData>
        {t('dashboard-loading-information')}
        <RefreshWrapper>
          <Refresh ariaHidden={false} />
        </RefreshWrapper>
      </PDL_LoadingOrNoData>
    ) : listPaymentDueLoaded.length ? (
      renderPaymentsDue()
    ) : (
      <PDL_LoadingOrNoData>{t('payments-due-no-payments-due')}.</PDL_LoadingOrNoData>
    );
  };

  return (
    <PaymentsDueListWrapper>
      <PDL_ListAction>
        <PaymentsSort data={LIST_DATA_SORT} onChangeSort={handleChangeSort} />
      </PDL_ListAction>
      <PDL_Divider />
      <PDL_List>{handleShowPaymentDueContent()}</PDL_List>
      <PDL_ShowMore>
        {parseInt(responseData.results.totalItems) > DEFAULT_PAGE_SIZE &&
          responseData.results.nextPage && (
            <ShowMore
              labelTransKeys={{
                showLabel: 'payments-due-pagination-showing',
                ofLabel: 'payments-due-pagination-of',
                itemLabel: 'payments-due-pagination-payments',
                showMoreLabel: 'payments-due-pagination-show-more',
                loadingLabel: 'payments-due-pagination-loading'
              }}
              numbers={{
                showingNumber: '' + listPaymentDueLoaded.length,
                totalNumber: '' + responseData.results.totalItems
              }}
              isLoading={isLoadingMore}
              handleClickShowMore={handleClickShowMore}
              className="PDL_ShowMoreContainer"
            />
          )}
      </PDL_ShowMore>
    </PaymentsDueListWrapper>
  );
};

const PaymentsDueListFallback: React.FC = () => {
  const [t] = useTranslation();

  const LIST_DATA_SORT = [
    {
      value: SORT_TYPE.DUE_FIRST,
      link: '',
      label: t('payments-due-sort-options-due-first'),
      icon: <SvgSortDueFirst />
    },
    {
      value: SORT_TYPE.DUE_LAST,
      link: '',
      label: t('payments-due-sort-options-due-last'),
      icon: <SortDueLast />
    },
    {
      value: SORT_TYPE.LEAST_AMOUNT,
      link: '',
      label: t('payments-due-sort-options-least-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDownAlt />
        </React.Fragment>
      )
    },
    {
      value: SORT_TYPE.HIGHEST_AMOUNT,
      link: '',
      label: t('payments-due-sort-options-highest-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDown />
        </React.Fragment>
      )
    }
  ];
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <PaymentsDueListWrapper>
      <PDL_ListAction>
        <PaymentsSort data={LIST_DATA_SORT} onChangeSort={() => {}} />
      </PDL_ListAction>
      <PDL_Divider />
      <PDL_List>
        <TextMessage fields={errorMessage} />
      </PDL_List>
    </PaymentsDueListWrapper>
  );
};

export default withErrorBoundary(PaymentsDueList, PaymentsDueListFallback);
