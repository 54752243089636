import React from 'react';
import { withSvg } from '../Svg';
import PropTypes from "prop-types";

const SvgApproved = (props) => (
  <path d="M15.5917 6.00834C15.5142 5.93023 15.422 5.86824 15.3205 5.82593C15.2189 5.78362 15.11 5.76184 15 5.76184C14.89 5.76184 14.7811 5.78362 14.6795 5.82593C14.578 5.86824 14.4858 5.93023 14.4083 6.00834L8.19999 12.225L5.59166 9.60834C5.51123 9.53064 5.41627 9.46955 5.31223 9.42854C5.20818 9.38754 5.09708 9.36743 4.98526 9.36937C4.87345 9.3713 4.7631 9.39524 4.66054 9.43982C4.55797 9.4844 4.46519 9.54874 4.38749 9.62917C4.30979 9.70961 4.2487 9.80456 4.2077 9.9086C4.16669 10.0127 4.14658 10.1238 4.14852 10.2356C4.15045 10.3474 4.17439 10.4577 4.21897 10.5603C4.26355 10.6629 4.32789 10.7556 4.40833 10.8333L7.60833 14.0333C7.6858 14.1114 7.77796 14.1734 7.87951 14.2157C7.98106 14.2581 8.08998 14.2798 8.19999 14.2798C8.31 14.2798 8.41893 14.2581 8.52047 14.2157C8.62202 14.1734 8.71419 14.1114 8.79166 14.0333L15.5917 7.23334C15.6762 7.1553 15.7438 7.06059 15.7899 6.95518C15.8361 6.84976 15.8599 6.73592 15.8599 6.62084C15.8599 6.50575 15.8361 6.39192 15.7899 6.2865C15.7438 6.18108 15.6762 6.08637 15.5917 6.00834Z"
        fill={props.color || "#005084"}/>
);

SvgApproved.prototype = {
    color: PropTypes.string
}

export default withSvg(20, 20)(SvgApproved);
