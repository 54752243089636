import React from 'react';
import {withSvg} from "../Svg";
import PropTypes from 'prop-types'

const SvgBin = (props) => (
    <path d="M10 18.9717C10.2652 18.9717 10.5196 18.8663 10.7071 18.6788C10.8946 18.4913 11 18.2369 11 17.9717V11.9717C11 11.7065 10.8946 11.4521 10.7071 11.2646C10.5196 11.077 10.2652 10.9717 10 10.9717C9.73478 10.9717 9.48043 11.077 9.29289 11.2646C9.10536 11.4521 9 11.7065 9 11.9717V17.9717C9 18.2369 9.10536 18.4913 9.29289 18.6788C9.48043 18.8663 9.73478 18.9717 10 18.9717ZM20 6.97168H16V5.97168C16 5.17603 15.6839 4.41297 15.1213 3.85036C14.5587 3.28775 13.7956 2.97168 13 2.97168H11C10.2044 2.97168 9.44129 3.28775 8.87868 3.85036C8.31607 4.41297 8 5.17603 8 5.97168V6.97168H4C3.73478 6.97168 3.48043 7.07704 3.29289 7.26457C3.10536 7.45211 3 7.70646 3 7.97168C3 8.2369 3.10536 8.49125 3.29289 8.67879C3.48043 8.86632 3.73478 8.97168 4 8.97168H5V19.9717C5 20.7673 5.31607 21.5304 5.87868 22.093C6.44129 22.6556 7.20435 22.9717 8 22.9717H16C16.7956 22.9717 17.5587 22.6556 18.1213 22.093C18.6839 21.5304 19 20.7673 19 19.9717V8.97168H20C20.2652 8.97168 20.5196 8.86632 20.7071 8.67879C20.8946 8.49125 21 8.2369 21 7.97168C21 7.70646 20.8946 7.45211 20.7071 7.26457C20.5196 7.07704 20.2652 6.97168 20 6.97168ZM10 5.97168C10 5.70646 10.1054 5.45211 10.2929 5.26457C10.4804 5.07704 10.7348 4.97168 11 4.97168H13C13.2652 4.97168 13.5196 5.07704 13.7071 5.26457C13.8946 5.45211 14 5.70646 14 5.97168V6.97168H10V5.97168ZM17 19.9717C17 20.2369 16.8946 20.4913 16.7071 20.6788C16.5196 20.8663 16.2652 20.9717 16 20.9717H8C7.73478 20.9717 7.48043 20.8663 7.29289 20.6788C7.10536 20.4913 7 20.2369 7 19.9717V8.97168H17V19.9717ZM14 18.9717C14.2652 18.9717 14.5196 18.8663 14.7071 18.6788C14.8946 18.4913 15 18.2369 15 17.9717V11.9717C15 11.7065 14.8946 11.4521 14.7071 11.2646C14.5196 11.077 14.2652 10.9717 14 10.9717C13.7348 10.9717 13.4804 11.077 13.2929 11.2646C13.1054 11.4521 13 11.7065 13 11.9717V17.9717C13 18.2369 13.1054 18.4913 13.2929 18.6788C13.4804 18.8663 13.7348 18.9717 14 18.9717Z"
          fill={props.color || "#188838"}/>
);

SvgBin.prototype = {
    color: PropTypes.string
}

export default withSvg(24, 25)(SvgBin);