import { BackgroundColors } from '_utils/components/Corporate/Section';
import { spacing } from '_utils/props';
import { color, mq, buttonReset, safeFocusRemoval, focusOutline } from '_utils/styles';
import { handleSectionTheme } from '_utils/styles/section';
import styled, { css, keyframes } from 'styled-components';

// INFO: Certificate Section
export const CerUpdatedTime = styled.span`
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  flex-grow: 1;
  color: ${color('all.deepBlueTint75')};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

const UpdateActiveCss = css`
  .cer-update-balance {
    span {
      color: #6009a0;
      text-decoration: underline;
    }

    svg {
      ${RotateCss}
      overflow: visible;
    }
  }
`;

const UpdateDeactiveCss = css`
  .cer-update-balance {
    span {
      text-decoration: underline;
    }
  }
`;

export const CertificateActions = styled.div`
  display: flex;
  width: auto;
  justify-content: ${({ isUpdateBalance }) => (isUpdateBalance ? 'space-between' : 'flex-end')};
  flex-grow: 1;
  align-items: center;

  ${mq('md')} {
    justify-content: flex-end;
  }

  .cer-update-balance {
    svg {
      path {
        fill: #188838;
      }
    }

    span:hover {
      color: #188838;
      text-decoration: none;
    }

    span:focus {
      color: #041c2c;
      border: 2px solid #8ca0ff;
    }
  }

  ${({ loading }) => (loading ? UpdateActiveCss : UpdateDeactiveCss)}
`;

export const UpdateBalance = styled.button`
  ${buttonReset}
  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mr: 2 })};
  }
`;

export const DownloadWrapper = styled.button`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => spacing({ theme, ml: '4px' })};

  svg:first-child {
    margin-right: 7px;
  }

  svg:last-child:hover {
    path {
      fill: #1b5928;
    }
  }

  /* &:focus {
    border: 2px solid #8ca0ff;
  } */
  ${buttonReset}
  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }
`;

const DotCss = css`
  content: '.';
  position: relative;
  font-size: 18px;
  ${({ theme }) => spacing({ theme, mx: 1 })};
`;

export const CertificateBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${CerUpdatedTime} {
    ${({ theme }) => spacing({ theme, my: 1 })};
  }

  ${mq('md')} {
    flex-direction: row;
    align-items: center;

    ${CerUpdatedTime}::before {
      ${DotCss}
      bottom: 3px;
    }

    ${CerUpdatedTime} {
      ${({ theme }) => spacing({ theme, my: 0, mt: '1px' })};
    }
  }
`;

export const Certificate = styled.div`
  display: flex;
  flex-direction: ${({ isAmount }) => (isAmount ? 'column' : 'row')};
  ${CertificateBottom} {
    ${({ theme, isAmount }) => (isAmount ? spacing({ theme, mt: 1 }) : '')};
  }
  flex-wrap: wrap;
  ${({ theme }) => spacing({ theme, py: 1 })};

  ${mq('lg')} {
    flex-direction: row;
    align-items: center;
    ${CertificateBottom} {
      ${({ theme }) => spacing({ theme, mt: 0 })};
    }
    ${CertificateBottom}:before {
      ${({ isAmount }) => (isAmount ? DotCss : '')};
      bottom: 4px;
    }
  }

  .certificate {
    svg {
      padding-left: 1.3px;
    }
  }
`;

// INFO: Card Wrapper
export const CardWrapper = styled.div`
  width: 100%;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  overflow: hidden;
  ${({ theme }) => spacing({ theme, mb: 4 })};
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);

  &:focus {
    border: 3px solid #8ca0ff;
  }
`;

export const CardSection = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => spacing({ theme, p: 3 })};
  ${() => handleSectionTheme(BackgroundColors['white'])};
`;

// INFO: Main Information

export const TitleWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mb: 1 })};
`;

const Title = styled.a`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-right: 13px;

  &:hover {
    cursor: text;
  }

  ${mq('md')} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const StyledDot = `
  &:not(:last-child) {
    position: relative;
    margin-right: 25px;
    margin-bottom: 8px;

    ${mq('md')} {
      ${({ theme }) => spacing({ theme, mb: 0 })};
    }

    &:after {
      ${mq('md')} {
        content: '.';
        font-weight: bold;
        position: absolute;
        top: -5px;
        right: -12.5px;
      }
    }
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  ${({ theme }) => spacing({ theme, mb: 1 })};

  .request-type-card__description {
    ${StyledDot}
  }
`;

const SubmissionTime = styled.span`
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${color('all.deepBlueTint75')};
`;

export const MainInformation = {
  Title,
  Address,
  SubmissionTime
};

// INFO: Sub-items Information
export const SubItemWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  ${({ theme }) => spacing({ theme, mt: 2 })};
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 8px;
  ${({ theme }) => spacing({ theme, p: 1 })};

  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 2 })};
  }

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, p: 2 })};
  }
`;

export const SubItemTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  ${mq('md')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const SubItemsBody = styled.div`
  ${Certificate}:not(:last-child) {
    border-bottom: 1px dashed #dddee1;
  }
`;

export const SubItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  ${SubItemTitle}:first-child {
    ${({ theme }) => spacing({ theme, mb: 1 })};
  }

  ${mq('md')} {
    flex-direction: row;
    ${SubItemTitle}:first-child {
      ${({ theme }) => spacing({ theme, mb: 0 })};
    }
  }
`;
