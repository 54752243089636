import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Metadata, { MetadataThemeConfig } from '_utils/components/CityConnect/Metadata';
import { logInDev } from '_utils/helpers/dev';
import NavItem from '_components/Corporate/NavItem';
import SvgDownload from '_utils/icons/PropertyCertificateCard/Download';
import SvgFile from '_utils/icons/PropertyCertificateCard/File';
import { IPropertyCertificateCardProps, ICertificate, ISubItemCertificate } from './definitions';
import { getUpdatedBalance } from '_services/dashboardTabsService';
import {
  CardSection,
  CardWrapper,
  Certificate,
  CertificateActions,
  UpdateBalance,
  CertificateBottom,
  CerUpdatedTime,
  DownloadWrapper,
  MainInformation,
  SubItem,
  SubItemHeader,
  SubItemsBody,
  SubItemTitle,
  SubItemWrapper,
  TitleWrapper
} from './styledPropertyCertificateCard';

const SubItemCertificate: React.FC<ISubItemCertificate> = ({ certificateData }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<ICertificate>(certificateData);
  const type = certificate?.type;
  const amount = certificate?.outstandingAmount;
  const updated = certificate?.lastUpdated;
  const updateBalanceApi = certificate?.updateBalanceLink;
  const downloadCerApi = certificate?.downloadCertificateLink;
  const isValidCertificate = type || amount || updated || downloadCerApi ? true : false;

  const handleClickUpdate = (updateBalanceApi) => {
    setLoading(true);
    //call api
    getUpdatedBalance(updateBalanceApi, updateBalanceSuccess, (res) => {
      setLoading(false);
      logInDev(res);
    });
  };

  const updateBalanceSuccess = (res) => {
    setLoading(false);
    const responseData = res?.data?.s603Certificate;
    setCertificate({
      ...certificate,
      lastUpdated: responseData?.lastUpdatedOn,
      outstandingAmount: responseData?.outstandingAmount
    });
  };

  const handleClickDownload = (downloadCerApi) => {
    //call api
    logInDev(`Call api: ${downloadCerApi}`);
  };

  const renderCertificateDownload = (downloadCerApi) => {
    return (
      downloadCerApi && (
        <DownloadWrapper
          className="cer-download"
          onClick={() => handleClickDownload(downloadCerApi)}
          name="download-button"
          role="button"
          aria-label="Download file"
        >
          <SvgFile />
          <SvgDownload />
        </DownloadWrapper>
      )
    );
  };

  const renderCertificateActions = (updateBalanceApi, downloadCerApi) => {
    return (
      <CertificateActions loading={loading ? 1 : 0} isUpdateBalance={updateBalanceApi}>
        {updateBalanceApi && (
          <UpdateBalance role="button" name="update-balance-button" value="update">
            <Metadata
              text={`${t('tab-property-certificates-update-balance')}`}
              metadataType="update-balance"
              metadataTheme={MetadataThemeConfig.Default}
              className="cer-update-balance"
              handleClick={() => handleClickUpdate(updateBalanceApi)}
            />
          </UpdateBalance>
        )}
        {renderCertificateDownload(downloadCerApi)}
      </CertificateActions>
    );
  };

  return (
    isValidCertificate && (
      <Certificate isAmount={amount ? true : false}>
        {type && (
          <Metadata
            text={type}
            metadataType="property-certificate"
            metadataTheme={MetadataThemeConfig.Default}
            className="certificate"
          />
        )}
        <CertificateBottom>
          {amount && (
            <Metadata
              text={`${amount} ${t('tab-property-certificates-outstanding')}`}
              metadataType="amount"
              metadataTheme={MetadataThemeConfig.Default}
            />
          )}
          {updated && (
            <CerUpdatedTime>
              {t('tab-property-certificates-updated')} {updated}
            </CerUpdatedTime>
          )}
          {renderCertificateActions(updateBalanceApi, downloadCerApi)}
        </CertificateBottom>
      </Certificate>
    )
  );
};

const PropertyCertificateCard: React.FC<IPropertyCertificateCardProps> = ({ data, className }) => {
  const [t] = useTranslation();
  const subItems = data?.subitems;

  const renderSubItems = () => {
    return (
      !!subItems?.length &&
      subItems?.map((item, idxSubItem) => (
        <SubItem key={idxSubItem}>
          <SubItemHeader>
            <SubItemTitle>{item.address}</SubItemTitle>
          </SubItemHeader>
          <SubItemsBody>
            {item?.certificates?.map((certificate, idxCer) => (
              <SubItemCertificate key={idxCer} certificateData={certificate} />
            ))}
          </SubItemsBody>
        </SubItem>
      ))
    );
  };

  return (
    <CardWrapper className={className}>
      <CardSection>
        <TitleWrapper>
          <NavItem
            link={{
              url: '#'
            }}
            className="card-title"
            StyledLink={MainInformation.Title}
          >
            {t('tab-property-certificates-reference')}
            {data.referenceNumber}
          </NavItem>
        </TitleWrapper>
        <MainInformation.SubmissionTime>
          {t('tab-property-certificates-submitted')} {data.submitted}
        </MainInformation.SubmissionTime>

        <SubItemWrapper>{renderSubItems()}</SubItemWrapper>
      </CardSection>
    </CardWrapper>
  );
};

export default PropertyCertificateCard;
