import {
  color,
  heading_m,
  mq,
  button,
  heading_s,
  safeFocusRemoval,
  text_m,
  svg
} from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { spacing } from '_utils/props';

// INFO: Wrapper Content
export const PaymentsDueListWrapper = styled.div`
  width: 100%;
  ${({ theme }) => spacing({ theme, mt: 7 })};
  background-color: ${color('all.white')};
`;

export const PDL_ListAction = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => spacing({ theme, px: 3 })};

  ${mq.lessThan('sm')} {
    padding: 0;
  }
`;

export const PDL_SelectAll = styled.div`
  ${({ theme }) => spacing({ theme, mr: 1 })};
  font-weight: ${({ selectAll }) => (selectAll ? '700' : 'normal')};
  color: ${({ disabled }) => (disabled ? color('all.deepBlueTint45') : color('all.deepBlue'))};
`;

export const PDL_Divider = styled.hr`
  height: 1px;
  border: initial;
  background-color: ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};
`;

export const PDL_List = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;

export const PDL_LoadingOrNoData = styled.div`
  margin: 0;
  ${({ theme }) => spacing({ theme, mt: 4, p: 3 })};
  background-color: #f9f9f9;
  color: ${color('all.deepBlueTint75')};
  ${text_m};
  border-radius: 8px;
  border: 1px solid ${color('all.deepBlueTint15')};
`;

export const PDL_ShowMore = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4, mb: 7 })};

  > div.PDL_ShowMoreConainer > button {
    border-color: ${color('all.greenTint110')};
    box-shadow: initial;
    &:before {
      display: block;
    }
  }
`;
export const PDL_StickyFooter = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 50;
  background-color: ${color('all.deepBlue')};
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: '';
    background-color: ${color('all.deepBlue')};
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc((100vw - 100%) / 2);
  }

  &::before {
    left: calc((100vw - 100%) / -2);
  }

  &::after {
    right: calc((100vw - 100%) / -2);
  }
`;

export const PDL_TotalityContainer = styled.div`
  width: 100%;
  ${({ theme }) => spacing({ theme, my: 6, p: 3 })};
  background-color: ${color('all.white')};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mq.lessThan('sm')} {
    flex-direction: column;
  }
`;

export const PDL_TotalityText = styled.div`
  ${heading_m}
  font-weight: bold;
  color: ${color('all.deepBlue')};
  font-family: 'Helvetica Now Display', 'Helvetica Now Text', Helvetica, sans-serif;
  font-weight: 700;
`;

export const PDL_TotalityButton = styled(NavLink)`
  ${button('default', 'primary')}
  ${safeFocusRemoval}
  ${heading_s}
  ${({ theme }) => spacing({ theme, mt: 2 })};
  font-weight: 700;

  &:focus {
    outline: initial;
  };

  ${mq('sm')} {
    ${({ theme }) => spacing({ theme, m: 0, ml: 2 })};
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshWrapper = styled.span`
  margin-left: 8px;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.card.action};
  }
  svg {
    ${RotateCss};
    overflow: visible;
    ${mq('md')} {
      width: 24px;
    }
  }
`;
