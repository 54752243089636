import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import {
  mq,
  text_s,
  heading_m,
  svg,
  color,
  buttonReset,
  heading_s,
  heading_xs,
  text_xs
} from '_utils/styles';

export const GeneratedDate = styled.div`
  font-size: 10px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1, mb: 3 })};
`;

export const YearTab = styled.div`
  display: flex;
  border-bottom: 1px solid ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, mb: 3 })};
`;

const TabActiveCss = css`
  ${heading_s};
  color: ${color('all.deepBlue')};
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 4px;
    width: 100%;
    background-color: ${color('all.green')};
  }
`;

export const TabItem = styled.button`
  ${buttonReset}
  text-align: left;
  color: ${color('all.deepBlueTint75')};
  position: relative;
  font-size: 16px;
  line-height: 20px;
  ${mq('md')} {
    font-size: 18px;
    line-height: 24px;
  }

  ${({ isActive }) => (isActive ? TabActiveCss : null)};
  ${({ theme }) => spacing({ theme, m: 0, mr: 4, pb: 2 })};
  &:last-child {
    margin-right: 0;
  }
`;

export const CurrentYearTitle = styled.div`
  ${heading_s};
  text-align: left;
  position: relative;
  color: ${color('all.deepBlue')};

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  ${mq('md')} {
    font-size: 18px;
    line-height: 24px;
  }

  ${({ theme }) => spacing({ theme, m: 0, pt: 2, pb: 2 })};
`;

export const Period = styled.span`
  display: inline-block;
`;

//INFO: Rate calculation item
export const CalculationItem = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};

  &.dash-border {
    border-bottom: 1px dashed #dddee1;
  }

  ${mq.lessThan('md')} {
    flex-direction: column;
  }
`;

export const CalculationContainerList = styled.div``

export const CalculationName = styled.div`
  margin: 0;
  max-width: 70%;
  flex-grow: 1;
  display: flex;
  ${({ theme }) => spacing({ theme, mr: 3 })};
  ${mq.lessThan('md')} {
    width: 100%;
    max-width: initial;
    margin-right: 0;
    padding-bottom: 4px;
  }
`;

export const CalculationText = styled.div`
  ${() => text_s}
  margin: 0;
  font-weight: normal;
  font-size: 14px;
`;

export const CalculationData = styled.div`
  display: flex;
  align-items: center;

  ${mq.lessThan('md')} {
    flex-direction: column;
    align-items: end;
  }
`;

export const CalculationAmount = styled.div`
  ${() => heading_m}
  font-style: normal;
  font-weight: bold;
  margin: 0;
`;

export const PercentageLabelContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3, px: 3 })};

  ${mq.lessThan('md')} {
    display: none;
  }
`;

export const PercentageChangeLabel = styled.div`
  ${() => text_s}
  margin: 0;
  text-align: right;
  display: none;
  color: ${color('all.deepBlueTint75')};

  ${mq.lessThan('md')} {
    ${() => text_xs};
    ${({ theme }) => spacing({ theme, m: 0, mt: '4px' })};
    display: block;
  }

  ${mq.lessThan('sm')} {
    ${({ theme }) => spacing({ theme, mt: 1 })};
  }

  ${PercentageLabelContainer} & {
    display: block;
  }
`;

export const PercentageChangeContainer = styled.div`
  width: 77px;
  text-align: right;
  ${({ theme }) => spacing({ theme, ml: 3 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, ml: 0, mt: '4px' })};
  }
`;

export const PercentageChange = styled.span`
  ${() => heading_xs};
  ${({ theme }) => spacing({ theme, m: 0 })};
  color: ${color('all.deepBlueTint75')};
  position: relative;
  padding: 4px 10px;
  display: inline-block;

  &.increase {
    &:before {
      content: '+';
      position: absolute;
      left: 0;
    }
  }

  &.decrease {
    &:before {
      content: '-';
      position: absolute;
      left: 0;
    }
  }

  ${mq.lessThan('md')} {
    padding: 0 0 0 10px;
  }
`;

//INFO: Total section
export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f0f1f2;
  font-size: 24px;
  ${mq.lessThan('md')} {
    font-size: 18px;
  }
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};
`;

export const TotalText = styled.div`
  font-style: normal;
  font-weight: bold;
  ${({ theme }) => spacing({ theme, pr: 1 })};
`;

export const TotalAmount = styled.div`
  font-style: normal;
  font-weight: bold;
`;

//INFO: description richtext
export const Description = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};

  * {
    color: ${color('all.deepBlue')};
  }

  h4 {
    ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
  }

  p {
    margin: 0;
  }
`;

export const IconWrapper = styled.span`
  ${svg(24, 24)}
  width: 24px;
  height: 24px;
  ${({ theme }) => spacing({ theme, mr: 3 })};
  ${mq.lessThan('md')} {
    width: 20px;
    height: 20px;
  }
`;
