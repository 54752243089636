import React from 'react';
import { withSvg } from '../Svg';

const SvgTwitter = () => (
  <path
    fill="#fff"
    d="M11.902 8.465L19.348 0h-1.766l-6.465 7.348L5.957 0H0l7.809 11.113L0 19.988h1.766l6.824-7.761 5.453 7.761H20zM9.484 11.21l-.789-1.106-6.297-8.804H5.11l5.082 7.105.79 1.106 6.605 9.238h-2.711zm0 0"
  ></path>
);

export default withSvg(20, 20)(SvgTwitter);
