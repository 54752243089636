import React, {
    useState,
    useLayoutEffect
} from 'react'
import {
    GoogleMap,
    Marker,
    InfoWindow
} from "@react-google-maps/api";
import { useJsApiLoader } from "@react-google-maps/api";
import Info from "./Info";
import {
    LocationButton,
    ZoomButton,
    ZoomWrapper,
    MapContainer,
    Container,
    Title,
    Description
} from "./StyledRelatedContentMap";
import SvgGoogleCrossHair from "_utils/icons/Map/GoogleCrosshair";
import SvgZoomIn from "_utils/icons/Map/ZoomIn";
import SvgZoomOut from "_utils/icons/Map/ZoomOut";
import {
    logoIcon,
    activeLogoIcon,
    zoomTypes,
    mapProps,
    viewports, mapItemProps
} from "./definition";
import MapControl from "./MapControl";

const mapContainerStyle = {
    width: "100%",
    height: "100%"
}

const renderTextField = (field, editMode, markup) => {
    return editMode === false && !field?.value ? null : markup;
};

const RelatedContentMap: React.FC<mapProps> = ({ fields , editMode = false}) => {
    const [activeMarker, setActiveMarker] = useState('');
    const markers: mapItemProps[] = fields?.data?.datasource?.items || [];
    const title = fields?.data?.datasource?.heading?.jss;
    const description = fields?.data?.datasource?.description?.jss;
    const apiKey = fields?.data?.apiKey?.googleMapsApiKey?.value || '';

    const handleViewPort = () => {
        let viewport = '';
        if(typeof window !== 'undefined' && window.innerWidth < 767){
            viewport = viewports.SMALL
        }
        else {
            viewport = ''
        }
        return viewport;
    }

    const [smallViewPort, setSmallViewPort] = useState(handleViewPort());
    const [map, setMap] = React.useState(null);
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey
    })

    useLayoutEffect(() => {
        typeof window !== 'undefined' && window.addEventListener("resize", () => {
            setSmallViewPort(handleViewPort());
        });
    })


    const handleActiveMarker = (marker: string) => {
        if(!marker){
            return;
        }
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        map.setZoom(15);
        map.setCenter({
            lat: parseFloat(markers[0]?.latitude) || -33.873235,
            lng: parseFloat(markers[0]?.longitude) || 151.206323
        });
        map.setOptions({
            scrollwheel: true,
            panControl: false,
            zoomControl: false,
            scaleControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        });
        setMap(map);
    };

    const handleLocateCurrentPosition = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                map.setCenter(latlng);
                setMap(map)
            });
        }
    }

    const handleChangeZoom = (type: string) => {
        if(type === zoomTypes.IN){
            map.setZoom(map.getZoom() + 1);
        }else {
            map.setZoom(map.getZoom() - 1);
        }
    }

    return isLoaded && (
        <Container>
            {(renderTextField(title, editMode, <Title field={title} tag={'ti'}/>))}
            {(renderTextField(description, editMode, <Description field={description} tag={'des'}/>))}
            <MapContainer>
                <GoogleMap
                    onLoad={handleOnLoad}
                    onUnmount={() => setMap(null)}
                    onClick={() => setActiveMarker(null)}
                    mapContainerStyle={mapContainerStyle}
                >
                    <MapControl position="RIGHT_TOP">
                        {
                            smallViewPort !== viewports.SMALL ? (
                                <ZoomWrapper>
                                    <ZoomButton onClick={() => handleChangeZoom(zoomTypes.IN)}>
                                        <SvgZoomIn/>
                                    </ZoomButton>
                                    <ZoomButton onClick={() => handleChangeZoom(zoomTypes.OUT)}>
                                        <SvgZoomOut/>
                                    </ZoomButton>
                                </ZoomWrapper>
                            ): null
                        }
                        <LocationButton onClick={() => handleLocateCurrentPosition()}>
                            <SvgGoogleCrossHair/>
                        </LocationButton>
                    </MapControl>
                    {markers.map((mark, idx) => (
                        <Marker
                            icon={{
                                url: activeMarker === mark.id ? activeLogoIcon: logoIcon,
                            }}
                            key={idx}
                            position={{lat: parseFloat(mark.latitude), lng: parseFloat(mark.longitude)}}
                            onClick={() => handleActiveMarker(mark.id)}
                        >
                            {activeMarker === mark.id ? (
                                <InfoWindow
                                    onCloseClick={() => setActiveMarker(null)}
                                    options={{minWidth: 270}}
                                >
                                    <Info item={mark}/>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    ))}
                </GoogleMap>
            </MapContainer>
        </Container>
    );
}

export default RelatedContentMap;