import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Progress,
  ResultLink,
  SubItemRightSide,
  SubItemRightSideRow,
  TextRate
} from './StyledConsultationsCard';
import { calculateRate, createSitecoreLink } from '_utils/helpers/consultationFunctions';
import { DashBoardRequestUser } from '_utils/icons';
import { ConsultationOpinions } from '_utils/components/CityConnect/ConsultationIcon/ConsultationIcon';

interface metaType {
  type: string;
  value: any;
}

interface opinionProps {
  item: {
    type: string;
    metadata: metaType[];
  };
}

const OpinionCard: React.FC<opinionProps> = ({ item }) => {
  const [t] = useTranslation();
  return (
    <>
      {item.type !== 'community-ideas' ? (
        <ResultLink field={createSitecoreLink(item.metadata[0].value, t('tab-consultations-' + item.metadata[0].type))} />
      ) : (
        <SubItemRightSide>
          {item.metadata.map((meta) => (
            <SubItemRightSideRow>
              {meta.type === 'person' ? (
                <>
                  <TextRate>{meta.value}</TextRate>
                  <DashBoardRequestUser />
                </>
              ) : (
                <>
                  <Progress
                    color={ConsultationOpinions(meta.type).color}
                    width={meta.value.split('%')[0]}
                  />
                  <TextRate color={ConsultationOpinions(meta.type).color}>
                    {meta.value}
                  </TextRate>
                  {ConsultationOpinions(meta.type).icon}
                </>
              )}
            </SubItemRightSideRow>
          ))}
        </SubItemRightSide>
      )}
    </>
  );
};

export default OpinionCard;
