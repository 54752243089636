import React from 'react';

export const SvgRates = () => (
  <svg viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9819 6.26748L10.9883 1.01311C10.4389 0.521686 9.7276 0.25 8.99045 0.25C8.25331 0.25 7.54203 0.521686 6.99259 1.01311L0.999017 6.26748C0.681725 6.55125 0.428528 6.89936 0.256284 7.28864C0.0840408 7.67791 -0.00329666 8.0994 8.70633e-05 8.52507V17.2557C8.70633e-05 18.0505 0.315819 18.8128 0.877826 19.3748C1.43983 19.9368 2.20208 20.2525 2.99688 20.2525H14.984C15.7788 20.2525 16.5411 19.9368 17.1031 19.3748C17.6651 18.8128 17.9808 18.0505 17.9808 17.2557V8.51508C17.9828 8.09109 17.8948 7.67152 17.7226 7.28408C17.5504 6.89663 17.2979 6.55014 16.9819 6.26748ZM10.9883 18.2546H6.99259V13.26C6.99259 12.9951 7.09784 12.741 7.28517 12.5536C7.47251 12.3663 7.72659 12.2611 7.99152 12.2611H9.98938C10.2543 12.2611 10.5084 12.3663 10.6957 12.5536C10.8831 12.741 10.9883 12.9951 10.9883 13.26V18.2546ZM15.983 17.2557C15.983 17.5206 15.8777 17.7747 15.6904 17.9621C15.503 18.1494 15.249 18.2546 14.984 18.2546H12.9862V13.26C12.9862 12.4652 12.6704 11.7029 12.1084 11.1409C11.5464 10.5789 10.7842 10.2632 9.98938 10.2632H7.99152C7.19673 10.2632 6.43448 10.5789 5.87247 11.1409C5.31047 11.7029 4.99474 12.4652 4.99474 13.26V18.2546H2.99688C2.73194 18.2546 2.47786 18.1494 2.29053 17.9621C2.10319 17.7747 1.99795 17.5206 1.99795 17.2557V8.51508C1.99813 8.37324 2.02851 8.23308 2.08707 8.1039C2.14563 7.97472 2.23103 7.85949 2.33758 7.76588L8.33116 2.5215C8.51345 2.36135 8.74781 2.27303 8.99045 2.27303C9.2331 2.27303 9.46745 2.36135 9.64975 2.5215L15.6433 7.76588C15.7499 7.85949 15.8353 7.97472 15.8938 8.1039C15.9524 8.23308 15.9828 8.37324 15.983 8.51508V17.2557Z"
      fill="white"
    />
  </svg>
);
