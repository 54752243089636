import styled, {css} from 'styled-components';
import { spacing } from '_utils/props';
import {
  mq,
  text_s,
  heading_m,
  text_m,
  color,
  text_xs
} from '_utils/styles';

const ACCORDION_ICON_WIDTH_MD_UP = 56;
const ACCORDION_ICON_WIDTH_SM_DOWN = 24;

const tableHeadStyle = () => css`
  width: 100%;
  ${text_m}
  display: flex;
  align-items: center;
  color: ${color('all.deepBlueTint75')};
`

export const RateTransactionsWrapper = styled.div`
  width: calc(
    100% - 48px
  );

  ${mq.lessThan('md')} {
    width: 100%;
  }
`;

export const RateTransactionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ReteTransactionDescription = styled.div`
  ${() => text_xs}
  ${({ theme, dataExist }) => spacing({ theme, mx: 0, mb: 3, mt: dataExist? 1: 0 })};
  color: #4E5A5F;
`;

export const TableHead = styled.div`
  ${tableHeadStyle}
  ${({ theme }) => spacing({ theme, m: 0, px: 3, py: 2 })};
  width: calc(100% - ${ACCORDION_ICON_WIDTH_MD_UP}px);
  ${mq.lessThan('md')} {
    align-items: start;
    width: calc(
      100% - ${({ theme }) => theme.responsiveSpacing.xs['2'] + ACCORDION_ICON_WIDTH_SM_DOWN}px
    );
  }
`;

export const TableContent = styled.div``;

export const SubTableHead = styled.div`
  ${tableHeadStyle}
  border-bottom: 1px dashed #dddee1;
  ${({ theme }) => spacing({ theme, m: 0, px: 3, py: 2 })};
  ${mq.lessThan('md')} {
    align-items: start;
  }
`;

export const TableBottomWrapper = styled.div`
  display: flex;
  background: #F0F1F2;
  ${({ theme }) =>
    spacing({
      theme,
      mx: 0,
      mb: 0,
      mt: 3,
      px: 3,
      py: 2
    })};
`

export const TableBottom = styled.div`
  ${heading_m}
  display: flex;
  align-items: center;
  width: calc(100% - ${ACCORDION_ICON_WIDTH_MD_UP}px);
  ${mq.lessThan('md')} {
    flex-direction: column;
    align-items: start;
    width: calc(
      100% - ${({ theme }) => theme.responsiveSpacing.xs['2'] + ACCORDION_ICON_WIDTH_SM_DOWN}px
    );
  }
`;

export const TotalOutstanding = styled.div`
  width: 40%;
  ${mq.lessThan('md')} {
    width: 100%;
  }
`;

export const PaymentTransactionsItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${color('all.deepBlue')};
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};

  &:not(:last-child) {
    border-bottom: 1px dashed #dddee1;
  }
  
  ${mq.lessThan('md')} {
    align-items: start;
  }
`;

export const ProcessedDate = styled.div`
  ${() => text_s}
  margin: 0;
  min-width: 40%;
  max-width: 40%;

  ${TableHead} & {
    ${text_m}
    margin: 0;
  }
  
  ${mq.lessThan('sm')} {
    ${({ theme }) => spacing({ theme, pr: 1 })};
  }
`;

export const Description = styled.div`
  ${() => text_s}
  margin: 0;
  min-height: 100%;

  ${mq.lessThan('sm')} {
    margin-bottom: 4px;
  }
`;

export const ColumnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  ${mq.lessThan('md')} {
    width: 100%;
    justify-content: end;
  }
`;

export const SubColumnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${mq.lessThan('md')} {
    flex-direction: column;
    align-items: start;
  }
`;

export const TitleAmount = styled.div`
  ${text_s}
  margin: 0;
  ${mq.lessThan('md')} {
    display: none;
  }
`;

export const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 56px;

  ${mq.lessThan('md')} {
    margin-left: 0px;
  }
`;

export const Amount = styled.div`
  ${() => text_m}
  margin: 0;
  font-weight: bold;
  ${mq.lessThan('md')} {
    display: flex;
    width: 100%;
    justify-content: end;
  }
`;

export const AccordionTransactionWrapper = styled.div`
  background: #ffffff;
  border-bottom: 1px dashed #dddee1;
  border-top: 1px dashed #dddee1;
`;

export const AccordionTransactionContainer = styled.div`
  background: #ffffff;
  &:not(:last-child) {
    border-bottom: 1px dashed #dddee1;
  }
  
  &:nth-child(2n + 1) {
    background: #F9F9F9;
  }
`;

export const AccordionTransactionItem = styled.div`
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};
  &:not(:last-child) {
    border-bottom: 1px dashed #dddee1;
  }
  
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.background.quaternary};
  }
  cursor: pointer;
`;

export const AccordionTransactionContentContainer = styled.div`
  width: calc(100% - ${ACCORDION_ICON_WIDTH_MD_UP}px);

  ${mq.lessThan('md')} {
    width: calc(
      100% - ${({ theme }) => theme.responsiveSpacing.xs['2'] + ACCORDION_ICON_WIDTH_SM_DOWN}px
    );
  }
`;

export const AccordionTransactionTitle = styled.div`
  ${heading_m()}
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mq.lessThan('md')} {
    flex-direction: column;
    align-items: start;
  }
`;

export const AccordionTransactionTitleText = styled.div`
  flex-grow: 1;
  ${text_s};
  ${({ theme }) => spacing({ theme, my: 0 })};
  color: #041c2c;
`;

export const AccordionTransactionAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mq.lessThan('md')} {
    width: 100%;
    justify-content: end;
  }
`;
