import React from 'react';
import { withSvg } from '../Svg';

const SvgTag = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.2501 4.99997C6.00287 4.99997 5.76119 5.07328 5.55563 5.21064C5.35007 5.34799 5.18986 5.54321 5.09525 5.77162C5.00064 6.00003 4.97588 6.25136 5.02411 6.49384C5.07235 6.73631 5.1914 6.95904 5.36621 7.13386C5.54103 7.30867 5.76376 7.42772 6.00623 7.47596C6.24871 7.52419 6.50004 7.49943 6.72845 7.40482C6.95686 7.31021 7.15208 7.15 7.28943 6.94444C7.42678 6.73887 7.5001 6.4972 7.5001 6.24997C7.5001 5.91845 7.3684 5.60051 7.13398 5.36609C6.89956 5.13167 6.58162 4.99997 6.2501 4.99997ZM17.6001 8.92497L10.5918 1.90831C10.5139 1.83107 10.4215 1.76997 10.32 1.7285C10.2185 1.68703 10.1098 1.66601 10.0001 1.66664H2.5001C2.27908 1.66664 2.06712 1.75444 1.91084 1.91072C1.75456 2.067 1.66676 2.27896 1.66676 2.49997V9.99997C1.66613 10.1096 1.68715 10.2184 1.72862 10.3199C1.77009 10.4214 1.83119 10.5138 1.90843 10.5916L8.9251 17.6C9.39385 18.0681 10.0293 18.3311 10.6918 18.3311C11.3543 18.3311 11.9897 18.0681 12.4584 17.6L17.6001 12.5C18.0683 12.0312 18.3312 11.3958 18.3312 10.7333C18.3312 10.0708 18.0683 9.43539 17.6001 8.96664V8.92497ZM16.4251 11.275L11.2751 16.4166C11.119 16.5719 10.9078 16.659 10.6876 16.659C10.4674 16.659 10.2562 16.5719 10.1001 16.4166L3.33343 9.65831V3.33331H9.65843L16.4251 10.1C16.5023 10.1778 16.5634 10.2702 16.6049 10.3717C16.6464 10.4732 16.6674 10.582 16.6668 10.6916C16.6658 10.9103 16.579 11.1198 16.4251 11.275Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgTag);
