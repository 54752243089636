import React from 'react';
import { withSvg } from '../Svg';
import PropTypes from "prop-types";

const SvClosed = (props) => (
  <path
    d="M19 5.99999H15C14.7348 5.99999 14.4804 6.10535 14.2929 6.29289C14.1054 6.48042 14 6.73478 14 6.99999C14 7.26521 14.1054 7.51956 14.2929 7.7071C14.4804 7.89464 14.7348 7.99999 15 7.99999H19C19.2652 7.99999 19.5196 8.10535 19.7071 8.29289C19.8946 8.48042 20 8.73478 20 8.99999V18.72L18.43 17.27C18.2456 17.0973 18.0026 17.0009 17.75 17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10535 16.5196 8 16.2652 8 16V15C8 14.7348 7.89464 14.4804 7.7071 14.2929C7.51957 14.1054 7.26521 14 7 14C6.73478 14 6.48043 14.1054 6.29289 14.2929C6.10535 14.4804 6 14.7348 6 15V16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19H17.36L20.36 21.73C20.5342 21.8936 20.7613 21.9894 21 22C21.1372 21.9985 21.2728 21.9714 21.4 21.92C21.5789 21.8419 21.731 21.7132 21.8376 21.5497C21.9442 21.3863 22.0007 21.1952 22 21V8.99999C22 8.20434 21.6839 7.44128 21.1213 6.87867C20.5587 6.31606 19.7956 5.99999 19 5.99999ZM10.54 10.54C11.3589 9.72314 11.8693 8.64763 11.9842 7.49669C12.0991 6.34575 11.8114 5.19057 11.1701 4.22794C10.5289 3.26532 9.57369 2.5548 8.46733 2.21742C7.36096 1.88005 6.17184 1.93669 5.10255 2.37769C4.03326 2.8187 3.14994 3.61679 2.60307 4.63601C2.0562 5.65523 1.87962 6.83253 2.10339 7.96734C2.32717 9.10215 2.93747 10.1243 3.83032 10.8596C4.72317 11.5949 5.84333 11.9979 7 12C7.65705 12.0012 8.30788 11.8728 8.9153 11.6223C9.52272 11.3718 10.0748 11.004 10.54 10.54ZM4 6.99999C4 6.20434 4.31607 5.44128 4.87868 4.87867C5.44129 4.31606 6.20435 3.99999 7 3.99999C7.4436 4.00412 7.88076 4.10658 8.28 4.29999L4.28 8.29999C4.09059 7.89306 3.9949 7.44882 4 6.99999ZM9.7 5.70999C9.89485 6.11227 9.99735 6.55301 10 6.99999C9.99778 7.50444 9.86839 8.00018 9.6238 8.44137C9.37921 8.88256 9.02732 9.25494 8.60067 9.52409C8.17402 9.79324 7.68639 9.95046 7.18287 9.9812C6.67936 10.0119 6.17622 9.91523 5.72 9.69999L9.7 5.70999Z"
    fill={props.color || "black"}
  />
);

SvClosed.prototype = {
  color: PropTypes.string
}

export default withSvg(24, 24)(SvClosed);
