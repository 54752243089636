import React from 'react';

const SvgKey = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7099 6.53006L20.2899 5.12006L21.7099 3.71006C21.8982 3.52176 22.0039 3.26636 22.0039 3.00006C22.0039 2.73376 21.8982 2.47837 21.7099 2.29006C21.5216 2.10176 21.2662 1.99597 20.9999 1.99597C20.7336 1.99597 20.4782 2.10176 20.2899 2.29006L9.74985 12.8301C8.71589 12.1479 7.46552 11.8738 6.24102 12.061C5.01652 12.2481 3.90507 12.8831 3.12204 13.8429C2.33901 14.8027 1.94014 16.0191 2.00273 17.2562C2.06532 18.4933 2.5849 19.6632 3.46081 20.5391C4.33671 21.415 5.50657 21.9346 6.74371 21.9972C7.98084 22.0598 9.19717 21.6609 10.157 20.8779C11.1168 20.0948 11.7518 18.9834 11.939 17.7589C12.1261 16.5344 11.852 15.284 11.1699 14.2501L16.0499 9.36006L17.4599 10.7801C17.5533 10.8727 17.6641 10.9461 17.7859 10.9958C17.9078 11.0456 18.0382 11.0708 18.1699 11.0701C18.3015 11.0708 18.4319 11.0456 18.5538 10.9958C18.6756 10.9461 18.7864 10.8727 18.8799 10.7801C18.9736 10.6871 19.048 10.5765 19.0987 10.4546C19.1495 10.3328 19.1757 10.2021 19.1757 10.0701C19.1757 9.93805 19.1495 9.80735 19.0987 9.68549C19.048 9.56363 18.9736 9.45303 18.8799 9.36006L17.4599 8.00006L18.8799 6.58006L20.2899 8.00006C20.3833 8.09274 20.4941 8.16607 20.6159 8.21584C20.7378 8.2656 20.8682 8.29082 20.9999 8.29006C21.1315 8.29082 21.2619 8.2656 21.3838 8.21584C21.5056 8.16607 21.6164 8.09274 21.7099 8.00006C21.8114 7.90642 21.8924 7.79277 21.9478 7.66627C22.0032 7.53977 22.0318 7.40316 22.0318 7.26506C22.0318 7.12696 22.0032 6.99036 21.9478 6.86386C21.8924 6.73736 21.8114 6.62371 21.7099 6.53006ZM6.99985 20.0001C6.40651 20.0001 5.82649 19.8241 5.33314 19.4945C4.83979 19.1648 4.45528 18.6963 4.22821 18.1481C4.00115 17.5999 3.94174 16.9967 4.0575 16.4148C4.17325 15.8328 4.45897 15.2983 4.87853 14.8787C5.29809 14.4592 5.83264 14.1735 6.41458 14.0577C6.99652 13.942 7.59972 14.0014 8.1479 14.2284C8.69608 14.4555 9.16462 14.84 9.49426 15.3334C9.82391 15.8267 9.99985 16.4067 9.99985 17.0001C9.99985 17.7957 9.68378 18.5588 9.12117 19.1214C8.55856 19.684 7.7955 20.0001 6.99985 20.0001Z"
        fill="#041C2C"
      />
    </svg>
  );
};

export default SvgKey;
