import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { spacing } from '_utils/props';
import { Grid } from '_utils/components/Corporate';
import { linkWithoutVisited, heading_xxl, text_m, mq, heading_xs, svg } from '_utils/styles';
import { warnInDev } from '_utils/helpers/dev';

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.element.white};
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  @media print {
    color: ${({ theme }) => theme.colors.element.black};
    background-color: ${({ theme }) => theme.colors.background.primary};
  }
`;

export const CategoryLinkContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
`;

export const CategoryLink = styled(Link)`
  ${({ theme }) => linkWithoutVisited({ theme })}
`;

export const Header = styled.h1`
  /* FIXME wierd extra fn call required
  somehow caused by importing above Container 
  class into _utils/styles/section/index !? */
  ${() => heading_xxl}
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
`;

export const Blurb = styled.p`
  ${() => text_m}
`;

const imageTypes = {
  publication: (theme) => css`
    ${spacing({ theme, pb: 8 })};

    ${mq('md')} {
      width: 100%;
      ${spacing({ theme, py: 8 })};
    }
  `,
  focus: (theme) => css`
    ${spacing({ theme, pb: 8 })};
    width: 50%;

    ${mq('sm')} {
      width: 100%;
    }

    ${mq('md')} {
      ${spacing({ theme, py: 8 })};
    }
  `,
  simple: (theme) => css`
    /* counter grid container to give 'full bleed' */
    ${mq.lessThan('md')} {
      ${({ theme }) => spacing({ mx: -theme.grid.margin.xs + 'px' })}
    }

    ${mq('md')} {
      width: calc(100% + ${({ theme }) => theme.grid.gutter.size.md}px);
    }

    ${mq('lg')} {
      width: 150%;
    }
  `
};

const handleImageTypes = ({ theme, imageType }) => {
  if (typeof imageTypes[imageType] !== 'undefined') {
    return imageTypes[imageType](theme);
  }

  warnInDev(
    `The specifed imageType (${imageType}) hasn't been configure. Fallback back to simple imageType.`
  );

  return imageTypes['simple'](theme);
};

export const ImageWrap = styled.div`
  /* Edit mode height blowing out, so need to keep it in check */
  max-height: ${({ editMode }) => (editMode ? '640px' : 'none')};
  height: 100%;
  display: flex;
  align-items: center;

  ${handleImageTypes}
`;

export const AttributeRow = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export const PrimaryCellItem = styled(Grid.Item)`
  @media print {
    ${({ theme }) => spacing({ theme, py: 2, px: 3 })};
    flex: 0 0 60%;
    max-width: 60%;
  }
`;
export const SecondaryCellItem = styled(Grid.Item)`
  @media print {
    margin-left: 0;
    flex: 0 0 40%;
    max-width: 40%;
  }
`;

export const SvgWrapper = styled.span`
  display: ${({ isInLine }) => (isInLine ? 'flex' : 'inline')};
  text-align: center;
  align-items: center;
  ${({ theme }) => spacing({ theme, ml: 1 })};
  ${svg(20, 20)}
  width: 20px;
  height: 20px;
  path {
    fill: ${({ isInLine }) => (isInLine ? '#4E5A5F' : '#FFF')};
  }
  ${mq.lessThan('md')} {
    width: ${({ isSlim }) => (isSlim ? '20px' : '18px')};
    height: ${({ isSlim }) => (isSlim ? '20px' : '18px')};
  }
`;

export const CTAButton = styled.a`
  ${heading_xs()}
  display: flex !important;
  align-items: center;
  width: fit-content;
  padding: 6px 16px !important;
  border: none !important;

  ${mq.lessThan('md')} {
    line-height: 18px !important;
    padding: 7px 16px !important;
    margin-top: 24px !important;
  }

  margin-top: 48px !important;

  &:focus {
    outline: none;
    background: #005a0b;
  }

  &:focus:before {
    left: -4px !important;
    top: -4px !important;
    width: calc(100% + 8px) !important;
    height: calc(100% + 8px) !important;
  }
`;