import styled, { keyframes } from 'styled-components';
import { spacing } from '_utils/props';
import {
  svg,
  buttonReset,
  inputReset,
  text_m,
  mq,
  hideOnPrint,
  iconColorOnPrint,
  color,
  depth,
  focusOutline,
  safeFocusRemoval
} from '_utils/styles';
import { Container as HomepageBannerContainer } from '_components/Corporate/HomepageBanner/StyledHomepageBanner';

const formAnimation = keyframes`
  from {    
    width: 40px;
  }
  to {
    width: 100%;
  }
`;

const formAnimationClose = keyframes`
  from {    
    width: 100%;
    visibility: visible;
  }
  to {
    width: 40px;
    visibility: visible;
  }
`;

const closeButtonAnimation = keyframes`
  from {
    width: auto;
  }
  to {
    width: auto;
  }
`;

const searchContainerAnimation = keyframes`
  from {
    width: calc(100% - 87px);
    z-index: ${depth.MENU_BUTTON + 1};
    position: absolute;
  }
  to {
    width: calc(100% - 87px);
    z-index: ${depth.MENU_BUTTON + 1};
    position: absolute;
  }
`;

const searchContainerMdUpAnimation = keyframes`
  from {
    width: calc(100% - 321px);
    z-index: ${depth.MENU_BUTTON + 1};
    position: absolute;
  }
  to {
    width: calc(100% - 321px);
    z-index: ${depth.MENU_BUTTON + 1};
    position: absolute;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;

  &.absolute {
    z-index: ${depth.MENU_BUTTON + 1};
    position: absolute;
    /* calculate width base on header padding, logo button, search margin */
    ${mq.between('md', 'lg')} {
      left: 297px;
      width: calc(100% - 321px);
    }
    ${mq.lessThan('md')} {
      left: 71px;
      width: calc(100% - 87px);
    }
  }

  &.static {
    position: static;
    ${mq.between('md', 'lg')} {
      animation: ${searchContainerMdUpAnimation} 0.2s linear;
    }
    ${mq.lessThan('md')} {
      animation: ${searchContainerAnimation} 0.2s linear;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.2s linear;
  width: 0;

  ${SearchContainer}.absolute & {
    background-color: rgba(255, 255, 255, 1);
  }

  ${SearchContainer}.static & {
    background-color: rgba(255, 255, 255, 0);
  }
`;

export const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.form.input.background};
  border-radius: 8px;
  visibility: hidden;
  position: relative;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &.search--focused {
    border: 2px solid ${({ theme }) => theme.colors.search.focus};
  }
  ${hideOnPrint}

  ${HomepageBannerContainer} & {
    border: 4px solid ${({ theme }) => theme.colors.element.secondary};

    &.search--focused {
      border: 4px solid ${({ theme }) => theme.colors.search.focus};
    }
  }

  ${SearchContainer}.static & {
    animation: ${formAnimationClose} 0.2s linear;
  }

  ${SearchContainer}.absolute & {
    visibility: visible;
    width: 100%;
    animation: ${formAnimation} 0.2s linear;
  }

  ${mq.between('sm', 'lg')} {
    visibility: ${({ isLoggedIn }) => (isLoggedIn ? 'visible' : 'hidden')};
  }

  ${mq('lg')} {
    visibility: visible;
  }
`;

export const Input = styled.input`
  ${inputReset};
  ${text_m}
  margin: 0;
  flex-grow: 1;
  outline: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;

  ${({ theme }) => spacing({ theme, pl: 2, pr: '50px' })}

  ${HomepageBannerContainer} & {
    ${({ theme }) => spacing({ theme, pr: '58px' })}

    ${mq('md')} {
      ${({ theme }) => spacing({ theme, pr: '64px' })}
      font-size: 22px;
    }
  }
`;

export const Submit = styled.button`
  ${buttonReset}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.search.submit.background};

  position: absolute;
  top: -2px;
  right: -2px;
  width: 42px;
  height: calc(100% + 4px);

  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  outline: 0;

  ${HomepageBannerContainer} & {
    top: 0;
    right: 0;
    height: 100%;
    width: 46px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;

    ${mq('md')} {
      width: 54px;
    }
  }

  /* when input has focus */
  :hover,
  .search--focused & {
    background-color: ${({ theme }) => theme.colors.search.focus};
  }

  /* when button has focus */
  body:not(.safe-focus-removal) &:focus {
    &:before {
      content: '';
      position: absolute;
      left: -4px;
      top: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  body:not(.safe-focus-removal) ${HomepageBannerContainer} &:focus {
    &:before {
      top: -8px;
      width: calc(100% + 12px);
      height: calc(100% + 16px);
    }
  }
`;

export const Icon = styled.span`
  ${svg(17, 17)}
  width: 17px;
  margin: 0 auto;

  /* ie11 cant do margin auto in flex */
  @media all and (-ms-high-contrast: none) {
    margin: 0;
  }

  ${HomepageBannerContainer} & {
    ${mq('md')} {
      width: 22px;
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.element.primary};
  }

  ${Submit}:hover &,
  .search--focused & {
    path {
      fill: ${({ theme }) => theme.colors.element.white};
      ${iconColorOnPrint}
    }
  }
`;

export const CloseButton = styled.button`
  ${buttonReset};
  width: 0;
  background-color: ${color('all.white')};
  display: flex;
  align-items: center;

  &:focus-visible {
    ${safeFocusRemoval};
    ${focusOutline};
  }

  &[aria-expanded='true'] {
    width: auto;
    margin-left: 6px;

    ${mq('md')} {
      margin-left: 14px;
    }
  }

  &[aria-expanded='false'] {
    animation: ${closeButtonAnimation} 0.2s linear;
  }
`;

export const CloseIcon = styled.span`
  margin: 10px;
  width: 20px;
  height: 11px;
  position: relative;
  display: block;
`;

export const Bar = styled.span`
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0px;
  top: 0px;
  background-color: ${({ theme }) => theme.colors.element.primary};
  opacity: 1;
  border-radius: 1px;
  transition: all 0.2s linear;

  &:nth-child(1) {
    ${CloseButton} [aria-expanded='true'] & {
      transform: translate3d(0, 5px, 0) rotate(45deg);
    }
  }
  &:nth-child(2) {
    transform: translate(0, 5px);
    ${CloseButton} [aria-expanded='true'] & {
      opacity: 0;
    }
  }
  &:nth-child(3) {
    transform: translate3d(0, 10px, 0);

    ${CloseButton} [aria-expanded='true'] & {
      transform: translate3d(0, 5px, 0) rotate(-45deg);
    }
  }
`;

export const SearchIcon = styled.button`
  ${buttonReset}
  background-color: ${color('all.white')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:focus-visible {
    ${safeFocusRemoval};
    ${focusOutline};
  }

  ${mq('lg')} {
    width: 0;
    visibility: hidden;
  }
  
  ${mq.between('sm', 'lg')} {
    width: ${({ isLoggedIn }) => (!isLoggedIn ? 'auto' : '0')};
    visibility: ${({ isLoggedIn }) => (!isLoggedIn ? 'visible' : 'hidden')};
  }

  &[aria-hidden='true'] {
    width: 0;
    visibility: hidden;
  }
`;
