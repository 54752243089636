import React from 'react';
import { withCurve } from '_utils/components/CityConnect/SublayoutWithCurve';
import Grid from '_utils/components/Corporate/Grid';

import { Primary } from './StyledSingleColumnFrame';

type SingleColumnsFrameProps = {
  ChildComponent?: any;
  showCurved?: boolean;
  isNarrow?: boolean;
};

const Container: React.FC<any> = ({ ChildComponent, isNarrow }) => {
  const primaryConfig = {
    gutters: ['left', 'right'],
    ...(isNarrow && { col: { md: 8 }, offset: { md: 2 }, push: { md: 2 } })
  };

  return (
    <Grid.Container>
      <Grid.Row justifyContent="center" config={{ gutters: ['left', 'right'] }}>
        <Primary config={primaryConfig}>{ChildComponent}</Primary>
      </Grid.Row>
    </Grid.Container>
  );
};

const SingleColumnFrame: React.FC<SingleColumnsFrameProps> = (props) => {
  const WithCurvedBorder = withCurve(Container);

  return <>{props.showCurved ? <WithCurvedBorder {...props} /> : <Container {...props} />}</>;
};

export default SingleColumnFrame;
