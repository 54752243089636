import React, { useEffect, useState } from 'react';
import { compose } from 'ramda';
import { useLocation } from 'react-router';
import { withSitecoreContext, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { Helmet } from 'react-helmet';
import { isDev } from '_utils/helpers/dev';
import { canUseDOM } from 'exenv';

declare global {
  interface Window {
    dataLayer: any;
  }
}

// data layer is not immediately available
// call callback(dataLayer) when it is
function getDataLayer(callback, attempts = 0) {
  if (!canUseDOM) {
    callback(null);
    return;
  }

  const { dataLayer } = window;

  if (dataLayer) {
    callback(dataLayer);
    return;
  } else {
    requestAnimationFrame(() => {
      if (attempts < 100) {
        // keep trying
        getDataLayer(callback, attempts + 1);
      } else {
        //give up
        callback(null);
      }
    });
  }
}

const Head = (props) => {
  const [dataLayer, setDataLayer] = useState(null);

  const { sitecoreContext, rendering } = props;

  const route = sitecoreContext?.route || null;
  const fields = (route && route.fields) || {};
  const seoTitle: string = getFieldValue(fields, 'seoTitle') || '';
  const seoDescription: string = getFieldValue(fields, 'seoDescription') || '';
  const pageTitle: string = getFieldValue(fields, 'pageTitle') || '';
  const pageDescription: string = getFieldValue(fields, 'pageDescription') || '';
  const listingImage: any = getFieldValue(fields, 'listingImage') || null;
  const socialSharingImage: any = getFieldValue(fields, 'socialSharingImage') || null;
  const noFollow: boolean = getFieldValue(fields, 'noFollow') || false;

  const siteUrl: string = sitecoreContext?.siteInfo?.siteBaseUrl || null;
  const canonicalUrl: string = sitecoreContext?.canonicalUrl || null;

  const dataSource = rendering?.fields?.data?.datasource || null;
  const gtmKey: string = dataSource?.gtmConfigurationItem?.jss?.fields?.GTMKey?.value || null;
  const titleSiteName: string = dataSource?.titleSiteName?.value || null;
  const ogSiteName: string = dataSource?.ogSiteName?.value || null;
  const twitterCreator: string = dataSource?.twitterCreator?.value || null;
  const twitterSite: string = dataSource?.twitterSite?.value || null;

  let title = seoTitle || pageTitle;
  title = title === titleSiteName ? title : title + ' - ' + titleSiteName;

  let description = seoDescription || pageDescription;

  let image = socialSharingImage?.src;
  if (!image && listingImage?.src) image = listingImage.src;
  if (image) image = siteUrl + image;

  const { pathname, search } = useLocation();

  // track page views (for SPA)
  useEffect(() => {
    if (!dataLayer) {
      return;
    }

    dataLayer.push({
      event: 'pageview',
      pagePath: pathname + search,
      pageTitle: title
    });

    // Ignore changes to pathname it happens before title
    // has changed, instead wait for title to change before
    // tracking page view. Assumes no two page titles are the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, dataLayer]);

  useEffect(() => {
    if (!dataLayer) {
      return;
    }

    if (window.location.pathname !== '/search') {
      return;
    }
    dataLayer.push({
      event: 'pageview',
      pagePath: pathname + search,
      pageTitle: title
    });
  }, [search]);

  useEffect(() => {
    getDataLayer((dataLayer) => setDataLayer(dataLayer));
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {noFollow && <meta name="robots" content="noindex, follow" />}
        <meta property="og:title" content={title} />
        {description && <meta property="og:description" content={description} />}
        {image && <meta property="og:image" content={image} />}
        {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
        {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
        <meta property="og:type" content="website" />
        {image && <meta name="twitter:card" content="summary_large_image" />}
        {!image && <meta name="twitter:card" content="summary" />}
        <meta name="twitter:title" content={title} />
        {description && <meta name="twitter:description" content={description} />}
        {image && <meta name="twitter:image" content={image} />}
        {twitterSite && <meta name="twitter:site" content={twitterSite} />}
        {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {/* <!-- Google Tag Manager --> */}
        {!isDev && gtmKey && (
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmKey}');`}</script>
        )}
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>
      {/* <!-- Google Tag Manager (noscript) --> */}
      {!isDev && gtmKey && (
        <noscript>
          <iframe
            title="gtm-no-script"
            src={`https://www.googletagmanager.com/ns.html?id=${gtmKey}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      )}
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
};

export default compose(withSitecoreContext())(Head);
