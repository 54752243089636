import React from 'react';
import { useTranslation } from 'react-i18next';
// Additional icon of metadata
import * as MetadataIcon from '_utils/icons/metadata';
import { StyledMetadata } from './StyledMetadata';

type MetadataProps = {
  metadataType: string;
  text: string;
  metadataTheme?: MetadataThemeConfig;
  className?: string;
  handleClick?: () => void;
};

export enum MetadataThemeConfig {
  Default = 'default',
  BlueWhite = 'blueWhite'
}

// INFO: Metadata configs with icon and prefix suffix
const METADATA_CONFIGS = {
  default: {
    icon: <MetadataIcon.NotePad />,
    prefix: '',
    suffix: ''
  },
  details: {
    icon: <MetadataIcon.NotePad />,
    prefix: '',
    suffix: ''
  },
  name: {
    icon: <MetadataIcon.User />,
    prefix: '',
    suffix: ''
  },
  ratepayer: {
    icon: <MetadataIcon.User />,
    prefix: 'dashboard-metadata-ratepayer',
    suffix: ''
  },
  recipient: {
    icon: <MetadataIcon.User />,
    prefix: 'dashboard-metadata-recipient',
    suffix: ''
  },
  'care-for': {
    icon: <MetadataIcon.User />,
    prefix: 'dashboard-metadata-carer-for',
    suffix: ''
  },
  type: {
    icon: <MetadataIcon.Star />,
    prefix: '',
    suffix: ''
  },
  duration: {
    icon: <MetadataIcon.Clock />,
    prefix: '',
    suffix: ''
  },
  validity: {
    icon: <MetadataIcon.Clock />,
    prefix: 'dashboard-metadata-validity',
    suffix: ''
  },
  fee: {
    icon: <MetadataIcon.Cost />,
    prefix: '',
    suffix: ''
  },
  'total-cost': {
    icon: <MetadataIcon.Cost />,
    prefix: 'dashboard-metadata-total-cost',
    suffix: ''
  },
  due: {
    icon: <MetadataIcon.Cost />,
    prefix: '',
    suffix: 'dashboard-metadata-due'
  },
  outstanding: {
    icon: <MetadataIcon.Cost />,
    prefix: '',
    suffix: 'dashboard-metadata-outstanding'
  },
  permit: {
    icon: <MetadataIcon.Permit />,
    prefix: '',
    suffix: ''
  },
  registration: {
    icon: <MetadataIcon.Permit />,
    prefix: 'dashboard-metadata-registration',
    suffix: ''
  },
  'permit-pack': {
    icon: <MetadataIcon.Permit />,
    prefix: 'dashboard-metadata-permit-pack',
    suffix: ''
  },
  'permit-number': {
    icon: <MetadataIcon.Permit />,
    prefix: 'dashboard-metadata-permit-number',
    suffix: ''
  },
  date: {
    icon: <MetadataIcon.Calendar />,
    prefix: '',
    suffix: ''
  },
  'missed-date': {
    icon: <MetadataIcon.Calendar />,
    prefix: 'dashboard-metadata-missed-date',
    suffix: ''
  },
  'scheduled-date': {
    icon: <MetadataIcon.Calendar />,
    prefix: 'dashboard-metadata-scheduled-date',
    suffix: ''
  },
  'last-trading-day': {
    icon: <MetadataIcon.Calendar />,
    prefix: 'dashboard-metadata-last-trading-day',
    suffix: ''
  },
  'start-date': {
    icon: <MetadataIcon.Calendar />,
    prefix: 'dashboard-metadata-start-date',
    suffix: ''
  },
  'expiry-date': {
    icon: <MetadataIcon.Calendar />,
    prefix: 'dashboard-metadata-expiry-date',
    suffix: ''
  },
  address: {
    icon: <MetadataIcon.Pin />,
    prefix: '',
    suffix: ''
  },
  'area-info': {
    icon: <MetadataIcon.LocationInfo />,
    prefix: '',
    suffix: ''
  },
  description: {
    icon: <MetadataIcon.Form />,
    prefix: '',
    suffix: ''
  },
  document: {
    icon: <MetadataIcon.File />,
    prefix: '',
    suffix: ''
  },
  gipa: {
    icon: <MetadataIcon.File />,
    prefix: 'dashboard-metadata-gipa',
    suffix: ''
  },
  'file-reference': {
    icon: <MetadataIcon.File />,
    prefix: 'dashboard-metadata-file-reference',
    suffix: ''
  },
  mail: {
    icon: <MetadataIcon.Email />,
    prefix: '',
    suffix: ''
  },
  contact: {
    icon: <MetadataIcon.Email />,
    prefix: 'dashboard-metadata-contact',
    suffix: ''
  },
  business: {
    icon: <MetadataIcon.Business />,
    prefix: '',
    suffix: ''
  },
  tag: {
    icon: <MetadataIcon.Tag />,
    prefix: '',
    suffix: ''
  },
  vehicle: {
    icon: <MetadataIcon.Car />,
    prefix: '',
    suffix: ''
  },
  'parking-permit': {
    icon: <MetadataIcon.Car />,
    prefix: 'dashboard-metadata-parking-permit',
    suffix: ''
  },
  zone: {
    icon: <MetadataIcon.Zone />,
    prefix: '',
    suffix: ''
  },
  area: {
    icon: <MetadataIcon.Zone />,
    prefix: 'dashboard-metadata-area',
    suffix: ''
  },
  'property-certificate': {
    icon: <MetadataIcon.PropertyCertificate />,
    prefix: '',
    suffix: ''
  },
  issue: {
    icon: <MetadataIcon.ReportIssue />,
    prefix: '',
    suffix: ''
  },
  'issue-remove': {
    icon: <MetadataIcon.ReportIssue />,
    prefix: 'dashboard-metadata-issue-remove',
    suffix: ''
  },
  'issue-clean': {
    icon: <MetadataIcon.ReportIssue />,
    prefix: 'dashboard-metadata-issue-clean',
    suffix: ''
  },
  replace: {
    icon: <MetadataIcon.ReportIssue />,
    prefix: 'Replace:',
    suffix: ''
  },
  info: {
    icon: <MetadataIcon.Info />,
    prefix: '',
    suffix: ''
  },
  'certificate-number': {
    icon: <MetadataIcon.Info />,
    prefix: 'dashboard-metadata-certificate-number',
    suffix: ''
  },
  attention: {
    icon: <MetadataIcon.WarningCircle />,
    prefix: '',
    suffix: ''
  },
  collection: {
    icon: <MetadataIcon.Truck />,
    prefix: '',
    suffix: ''
  },
  bin: {
    icon: <MetadataIcon.Bin />,
    prefix: '',
    suffix: ''
  },
  'bin-number': {
    icon: <MetadataIcon.Bin />,
    prefix: 'dashboard-metadata-bin-number',
    suffix: ''
  },
  'new-bin-size': {
    icon: <MetadataIcon.Bin />,
    prefix: 'dashboard-metadata-new-bin-size',
    suffix: ''
  },
  warning: {
    icon: <MetadataIcon.WarningTriangle />,
    prefix: '',
    suffix: ''
  },
  escalated: {
    icon: <MetadataIcon.WarningTriangle />,
    prefix: 'dashboard-metadata-escalated',
    suffix: ''
  },
  accident: {
    icon: <MetadataIcon.WarningTriangle />,
    prefix: 'dashboard-metadata-accident',
    suffix: ''
  },
  'business-type': {
    icon: <MetadataIcon.Briefcase />,
    prefix: '',
    suffix: ''
  },
  'total-outstanding': {
    icon: <MetadataIcon.TotalOutstanding />,
    prefix: '',
    suffix: 'dashboard-metadata-total-outstanding'
  },
  view: {
    icon: <MetadataIcon.View />,
    prefix: '',
    suffix: ''
  },
  mobile: {
    icon: <MetadataIcon.Mobile />,
    prefix: '',
    suffix: ''
  },
  url: {
    icon: <MetadataIcon.Url />,
    prefix: '',
    suffix: ''
  },
  bookmark: {
    icon: <MetadataIcon.Bookmark />,
    prefix: '',
    suffix: ''
  },
  phone: {
    icon: <MetadataIcon.Phone />,
    prefix: '',
    suffix: ''
  },
  camera: {
    icon: <MetadataIcon.Camera />,
    prefix: '',
    suffix: ''
  },
  receipt: {
    icon: <MetadataIcon.Receipt />,
    prefix: '',
    suffix: ''
  },
  card: {
    icon: <MetadataIcon.Card />,
    prefix: '',
    suffix: ''
  },
  calculation: {
    icon: <MetadataIcon.Calculation />,
    prefix: '',
    suffix: ''
  },
  cat: {
    icon: <MetadataIcon.Cat />,
    prefix: '',
    suffix: ''
  },
  dog: {
    icon: <MetadataIcon.Dog />,
    prefix: '',
    suffix: ''
  },
  group: {
    icon: <MetadataIcon.Group />,
    prefix: '',
    suffix: ''
  },
  'update-balance': {
    icon: <MetadataIcon.SvgRefresh />,
    prefix: '',
    suffix: ''
  },
  lot: {
    icon: <MetadataIcon.SvgHome />,
    prefix: '',
    suffix: ''
  },
  amount: {
    icon: <MetadataIcon.Cost />,
    prefix: '',
    suffix: ''
  }
};

const Metadata: React.FC<MetadataProps> = ({
  metadataType,
  text,
  className,
  metadataTheme,
  handleClick
}) => {
  const [t] = useTranslation();

  if (!metadataType || typeof metadataType !== 'string') return null;

  // INFO: Consistent type to compare string
  const type = metadataType.toLowerCase();

  return (
    <StyledMetadata metadataTheme={metadataTheme} className={className} onClick={handleClick}>
      {METADATA_CONFIGS[type] ? METADATA_CONFIGS[type]?.icon : METADATA_CONFIGS['default'].icon}
      <span style={{ wordBreak: 'break-word' }}>{`${
        METADATA_CONFIGS[type]
          ? `${METADATA_CONFIGS[type]?.prefix ? `${t(METADATA_CONFIGS[type]?.prefix)} ` : ''}`
          : METADATA_CONFIGS['default'].prefix
      }${text}${
        METADATA_CONFIGS[type]
          ? `${METADATA_CONFIGS[type]?.suffix ? ` ${t(METADATA_CONFIGS[type]?.suffix)}` : ''}`
          : METADATA_CONFIGS['default'].suffix
      }`}</span>
    </StyledMetadata>
  );
};

Metadata.defaultProps = {
  metadataTheme: MetadataThemeConfig.Default
};

export default Metadata;
