import { color, mq, text_m } from '_utils/styles';
import styled from 'styled-components';
import { spacing } from '_utils/props';

const NUMBER_ITEMS_LARGE_VIEWPORT = 8;
const NUMBER_ITEMS_SMALL_VIEWPORT = 4;
const ITEMS_HEIGHT_LARGE_VIEWPORT = 28;
const ITEMS_HEIGHT_SMALL_VIEWPORT = 24;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// INFO: Wrapper Content
export const SelectCountryContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const SelectButton = styled.input`
  ${text_m}
  margin: 0;
  cursor: pointer;
  border: 1px solid #dddee1;
  border-radius: 8px;
  padding: 10px 24px;
  height: 48px;
  width: 100%;
  position: relative;
  background: ${color('all.white')};
  display: flex;
  align-items: center;
  background-position: bottom 17px right 19px;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%228%22%20viewBox%3D%220%200%2014%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6.29135%207.71501L0.295591%201.71562C0.201929%201.62267%200.127585%201.51208%200.0768524%201.39023C0.0261198%201.26839%205.99922e-07%201.13769%206.11461e-07%201.0057C6.23001e-07%200.873697%200.0261199%200.743004%200.0768525%200.621157C0.127585%200.49931%200.201929%200.388722%200.295591%200.295768C0.388488%200.202049%200.499011%200.127663%200.620784%200.0768993C0.742557%200.0261357%200.873171%20-1.14758e-06%201.00509%20-1.13605e-06C1.13701%20-1.12452e-06%201.26762%200.0261358%201.38939%200.0768994C1.51117%200.127663%201.62169%200.202049%201.71459%200.295768C3.41268%202.09612%205.15666%203.85394%207.00494%205.50025L12.2871%200.295769C12.4753%200.107485%2012.7305%200.00170697%2012.9966%200.00170699C13.2627%200.00170701%2013.5179%200.107485%2013.7061%200.295769C13.8943%200.484054%2014%200.739422%2014%201.0057C14%201.13754%2013.974%201.2681%2013.9236%201.38991C13.8732%201.51172%2013.7993%201.6224%2013.7061%201.71562L7.71035%207.71501C7.61531%207.80605%207.50325%207.8774%207.38058%207.92499C7.13729%208.025%206.86441%208.025%206.62112%207.92499C6.49846%207.8774%206.38639%207.80605%206.29135%207.71501Z%22%20fill%3D%22%23041C2C%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  background-size: 14px 8px;
  background-repeat: no-repeat;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-style: italic;
    color: ${color('all.deepBlueTint75')};
  }
`;

export const SelectButtonLabel = styled.div`
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectPanel = styled.div`
  display: ${({ showPanel }) => (showPanel ? 'block' : 'none')};
  position: absolute;
  ${({ theme }) => spacing({ theme, mt: 1, py: 3 })};
  top: calc(100%);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: ${color('all.white')};
  width: 100%;
  z-index: 10;
`;

export const SearchItemContainer = styled.div`
  overflow-y: auto;
  ${({ theme }) => spacing({ theme, px: 3 })};
  height: ${NUMBER_ITEMS_SMALL_VIEWPORT * (ITEMS_HEIGHT_SMALL_VIEWPORT + 16)}px;

  ${mq('md')} {
    height: ${NUMBER_ITEMS_LARGE_VIEWPORT * (ITEMS_HEIGHT_LARGE_VIEWPORT + 16)}px;
  }
`;

export const SelectItem = styled.div`
  height: ${ITEMS_HEIGHT_SMALL_VIEWPORT}px;
  ${({ theme }) => spacing({ theme, mt: 2 })};

  ${mq('md')} {
    height: ${ITEMS_HEIGHT_LARGE_VIEWPORT}px;
  }

  &:first-child {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }

  > div > label {
    ${({ theme }) => spacing({ theme, ml: 1 })};
    padding-left: 20px;

    ${mq('md')} {
      padding-left: 24px;
    }
  }
  > div > input:checked + label {
    font-weight: 700;
    font-family: 'Helvetica Now Text', Helvetica, sans-serif;
  }
  > div > div:last-child {
    border-color: ${color('all.deepBlueTint45')};

    ${mq.lessThan('md')} {
      width: 20px;
      height: 20px;
    }
  }
`;
