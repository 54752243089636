import React from 'react';
import { withSvg } from '../Svg';

const SvgStar = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3332 8.05845C18.2806 7.90603 18.1849 7.77214 18.0577 7.67304C17.9305 7.57394 17.7772 7.51387 17.6166 7.50012L12.8749 6.80845L10.7499 2.50012C10.6817 2.35923 10.5751 2.24041 10.4425 2.15727C10.3098 2.07413 10.1564 2.03003 9.9999 2.03003C9.84335 2.03003 9.68997 2.07413 9.55733 2.15727C9.42468 2.24041 9.31814 2.35923 9.2499 2.50012L7.1249 6.80012L2.38323 7.50012C2.229 7.52204 2.084 7.58676 1.96469 7.68692C1.84538 7.78708 1.75653 7.91868 1.70823 8.06679C1.66402 8.21152 1.66005 8.36555 1.69676 8.51237C1.73346 8.65918 1.80945 8.79323 1.91657 8.90012L5.35823 12.2335L4.5249 16.9668C4.49515 17.123 4.51073 17.2845 4.56979 17.4322C4.62886 17.5798 4.72894 17.7075 4.85823 17.8001C4.98425 17.8902 5.13288 17.9434 5.28744 17.9537C5.442 17.964 5.59638 17.931 5.73323 17.8585L9.9999 15.6335L14.2499 17.8668C14.3669 17.9328 14.4989 17.9672 14.6332 17.9668C14.8098 17.9674 14.9819 17.912 15.1249 17.8085C15.2542 17.7158 15.3543 17.5882 15.4133 17.4405C15.4724 17.2928 15.488 17.1314 15.4582 16.9751L14.6249 12.2418L18.0666 8.90845C18.1869 8.80652 18.2758 8.67254 18.323 8.52208C18.3702 8.37162 18.3738 8.21085 18.3332 8.05845ZM13.2082 11.3918C13.1105 11.4863 13.0374 11.6033 12.9953 11.7326C12.9531 11.8619 12.9433 11.9995 12.9666 12.1335L13.5666 15.6251L10.4332 13.9585C10.3127 13.8943 10.1782 13.8607 10.0416 13.8607C9.90497 13.8607 9.77047 13.8943 9.6499 13.9585L6.51657 15.6251L7.11657 12.1335C7.13985 11.9995 7.12999 11.8619 7.08787 11.7326C7.04575 11.6033 6.97263 11.4863 6.8749 11.3918L4.3749 8.89179L7.88323 8.38345C8.01823 8.36468 8.14656 8.31307 8.25698 8.23316C8.3674 8.15326 8.45653 8.04749 8.51657 7.92512L9.9999 4.75012L11.5666 7.93345C11.6266 8.05582 11.7157 8.16159 11.8262 8.2415C11.9366 8.32141 12.0649 8.37301 12.1999 8.39179L15.7082 8.90012L13.2082 11.3918Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgStar);
