import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgArrowDown from '_utils/icons/ArrowDown';
import { getPropertyRatesData } from '_services/propertyRatesService';
import RateDetailCurrent from './RateDetailCurrent';
import RateDetailTransactions from "./RatesDetailTransaction";
import RateDetailHistory from './RateDetailHistory';
import TextMessage from '../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import {
  PropertyAccordionWrapper,
  SubSection,
  PropertyTitle,
  AccordionWrapper,
  AccordionItem,
  AccordionTitle,
  AccordionContentContainer,
  AccordionTitleText,
  ArrowWrapper,
  ContentUnavailable,
} from './StyledPropertyRates';

const PropertyRates: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState({
    rateableCalculation: false,
    paymentTransactions: false,
    rateableValue: false
  });
  const [t] = useTranslation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const propertyRatesId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    getPropertyRatesData(
      propertyRatesId,
      (data) => setData(data.data),
      (res) => setError(res)
    );
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  if (!data?.rates || Object.keys(data.rates).length === 0) return <></>;

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  return (
    <PropertyAccordionWrapper>
      <SubSection>
        <PropertyTitle>{t('rates-details-heading')}</PropertyTitle>
      </SubSection>
      <AccordionWrapper>
        {/* Rateable calculation */}
        {data?.rates?.current?.items?.length > 0 && (
          <AccordionItem
            tabIndex={0}
            role="button"
            aria-label="Details"
            aria-expanded={isOpen.rateableCalculation}
            onKeyDown={(e) => handleKeyDown(e, () => setIsOpen({ ...isOpen, rateableCalculation: !isOpen.rateableCalculation }))}
            onClick={() =>
              setIsOpen({ ...isOpen, rateableCalculation: !isOpen.rateableCalculation })
            }
          >
            <AccordionTitle className={isOpen.rateableCalculation ? 'active' : ''}>
              <AccordionTitleText>{t('rates-details-current')}</AccordionTitleText>
              <ArrowWrapper className={isOpen.rateableCalculation ? 'show' : ''}>
                <SvgArrowDown ariaHidden={false} />
              </ArrowWrapper>
            </AccordionTitle>
            {isOpen.rateableCalculation && (
              <AccordionContentContainer>
                <RateDetailCurrent
                  dateCalculated={data?.rates?.date}
                  currentRate={data?.rates?.current}
                  tips={data?.rates?.tips}
                  isResidentialHouses={true} //now just mocking and waiting for update property type task
                />
              </AccordionContentContainer>
            )}
          </AccordionItem>
        )}

        {/* Financial details summary */}
        <AccordionItem
          tabIndex={0}
          role="button"
          aria-label="Details"
          aria-expanded={isOpen.paymentTransactions}
          onKeyDown={(e) => handleKeyDown(e, () => setIsOpen({ ...isOpen, paymentTransactions: !isOpen.paymentTransactions }))}
        >
          <AccordionTitle
            className={isOpen.paymentTransactions ? 'active' : ''}
            onClick={() => setIsOpen({ ...isOpen, paymentTransactions: !isOpen.paymentTransactions })}
          >
            <AccordionTitleText>{t('rates-financial-details-summary')}</AccordionTitleText>
            <ArrowWrapper className={isOpen.paymentTransactions ? 'show' : ''}>
              <SvgArrowDown ariaHidden={false} />
            </ArrowWrapper>
          </AccordionTitle>
          {isOpen.paymentTransactions && (
            <>
              {data?.rates.transactions?.length ? (
                <RateDetailTransactions
                    data={data?.rates.transactions}
                    total={data?.rates?.totalOutstanding}
                    date={data?.rates?.date}
                />
              ) : (
                <ContentUnavailable>{t('rates-details-no-transactions')}</ContentUnavailable>
              )}
            </>
          )}
        </AccordionItem>

        {/* Rateable value */}
        <AccordionItem
          tabIndex={0}
          role="button"
          aria-label="Details"
          aria-expanded={isOpen.rateableValue}
          onKeyDown={(e) => handleKeyDown(e, () => setIsOpen({ ...isOpen, rateableValue: !isOpen.rateableValue }))}
          onClick={() => setIsOpen({ ...isOpen, rateableValue: !isOpen.rateableValue })}
        >
          <AccordionTitle className={isOpen.rateableValue ? 'active' : ''}>
            <AccordionTitleText>{t('rates-details-rateable-value')}</AccordionTitleText>
            <ArrowWrapper className={isOpen.rateableValue ? 'show' : ''}>
              <SvgArrowDown ariaHidden={false} />
            </ArrowWrapper>
          </AccordionTitle>
          {isOpen.rateableValue && (
              <AccordionContentContainer>
                {data?.rates.history?.length ? (
                    <RateDetailHistory data={data?.rates.history} />
                ) : (
                    <ContentUnavailable>
                      {t('dashboard-information-is-currently-unavailable')}
                    </ContentUnavailable>
                )}
              </AccordionContentContainer>
          )}
        </AccordionItem>
      </AccordionWrapper>
    </PropertyAccordionWrapper>
  );
};

const PropertyRatesFallback: React.FC = () => {
  const [t] = useTranslation();

  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <PropertyAccordionWrapper>
      <SubSection>
        <PropertyTitle>{t('rates-details-heading')}</PropertyTitle>
      </SubSection>
      <TextMessage fields={errorMessage} />
    </PropertyAccordionWrapper>
  );
};

export default withErrorBoundary(PropertyRates, PropertyRatesFallback);
