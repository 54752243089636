import React, { useState } from 'react';
import { ArrowLeft } from '_utils/icons';
import { Container, BackButton, RichTextContent, Error, RefreshIcon } from './StyledQuestions';
import SvgRefresh from '_utils/icons/common/Refresh';
import { AddNewWrapper, ButtonWrapper, ContinueButton } from '../StyledProfile';
import SvgBin from '_utils/icons/Profile/Bin';
import QuestionWithInput from './QuestionWithInput';
import { useTranslation } from 'react-i18next';
import {
  definition,
  actions,
  handleCheckInput,
  checkProperties
} from '_utils/helpers/profileHelper';
import Individual from './Individual';
import Organisation from './Organisation';
import { consultationProps, itemState, errors, NOT_LISTED_HERE } from '../definition';

const handleIfOther = (value: string, t: any) => {
  if (value) {
    if (
      value === t('community-engagement-profile-input-language-not-listed') ||
      value === NOT_LISTED_HERE
    ) {
      return t('community-engagement-profile-input-language-not-listed');
    } else {
      return value;
    }
  }
  return '';
};

const Questions: React.FC<consultationProps> = ({
  handleChange,
  item,
  handleDialog,
  type,
  handleUpdate
}) => {
  const [t] = useTranslation();
  const [consultation, setConsultation] = useState<itemState>({
    id: item.id || '',
    type: type,
    email: item.email || '',
    relationships: item.relationships || [],
    birthYear: item.birthYear || '',
    gender: handleIfOther(item.gender, t),
    genderOther: item.genderOther || '',
    suburb: item.suburb || '',
    city: item.city || '',
    organisationName: item.organisationName || '',
    organisationType: item.organisationType || '',
    role: item.role || '',
    organisationABNorACN: item.organisationABNorACN || '',
    address: item.address || '',
    addressLine1: item.addressLine1 || '',
    addressLine2: item.addressLine2 || '',
    addressLine3: item.addressLine3 || '',
    phone: item.phone || '',
    outsideOfAustralia: item.outsideOfAustralia || false,
    relationshipsOther: item.relationshipsOther || '',
    languageSpokenAtHome: handleIfOther(item.languageSpokenAtHome, t),
    languageSpokenAtHomeOther: item.languageSpokenAtHomeOther || '',
    receiveMonthlyNewsletter: item.receiveMonthlyNewsletter || false,
    stateOrProvince: item.stateOrProvince || '',
    zipOrPostalCode: item.zipOrPostalCode || '',
    country: item.country || '',
    defaultProfile: item.defaultProfile || false
  });

  const [errorMsg, setErrorMsg] = useState({} as any);
  const { individual } = definition;
  const { updateProfile, addNewProfile } = actions;
  const dataPolicy = {
    value: t('community-engagement-profile-policy-content')
  };
  const [relationshipFirstRender, setRelationshipFirstRender] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleReceiveLetter = () => {
    let newConsultation = { ...consultation };
    newConsultation.receiveMonthlyNewsletter = !consultation.receiveMonthlyNewsletter;
    setConsultation({ ...newConsultation });
  };

  const handleChangeInput = (value: string, cases: string, isCheck?: boolean) => {
    let itemCopy = consultation;
    let error = errorMsg;
    itemCopy[cases] = value;
    setConsultation({ ...itemCopy });
    if (isCheck) {
      if (!handleCheckInput(value, cases)) {
        error[cases] = errors(t)[cases];
      } else {
        error[cases] = '';
      }
    } else {
      error[cases] = '';
    }
    setErrorMsg({ ...error });
  };

  const validateInput = (field: string) => {
    return handleCheckInput(consultation[field], field) ? '' : errors(t)[field];
  };

  const triggerError = () => {
    let error = {} as any;
    if (type === individual) {
      error = {
        email: validateInput('email'),
        birthYear: validateInput('birthYear'),
        suburb: consultation.outsideOfAustralia ? '' : validateInput('suburb'),
        gender: validateInput('gender'),
        languageSpokenAtHome: validateInput('languageSpokenAtHome'),
        country: consultation.outsideOfAustralia ? validateInput('country') : '',
        addressLine1: consultation.outsideOfAustralia ? validateInput('addressLine1') : '',
        zipOrPostalCode: consultation.outsideOfAustralia ? validateInput('zipOrPostalCode') : ''
      };

      if (consultation.gender === t('community-engagement-profile-input-language-not-listed')) {
        error.genderOther = validateInput('genderOther');
      }

      if (
        consultation.languageSpokenAtHome ===
        t('community-engagement-profile-input-language-not-listed')
      ) {
        error.languageSpokenAtHomeOther = validateInput('languageSpokenAtHomeOther');
      }

      if (consultation.relationships.includes(NOT_LISTED_HERE)) {
        error.relationshipsOther = validateInput('relationshipsOther');
      }
      //  check relationship for submit
      if (consultation.relationships.length === 0) {
        error.relationship = validateInput('relationship');
      }
    } else {
      error = {
        email: validateInput('email'),
        organisationName: validateInput('organisationName'),
        organisationType: validateInput('organisationType'),
        role: validateInput('role'),
        country: consultation.outsideOfAustralia ? validateInput('country') : '',
        addressLine1: consultation.outsideOfAustralia ? validateInput('addressLine1') : '',
        zipOrPostalCode: consultation.outsideOfAustralia ? validateInput('zipOrPostalCode') : ''
      };
    }
    setErrorMsg({ ...error });
    return error;
  };

  const handleChangeCheckbox = (arr: any, isOverSea: boolean) => {
    let newConsultation = { ...consultation };
    newConsultation.relationships = [...arr];
    let err = { ...errorMsg };
    if (!arr.includes(NOT_LISTED_HERE)) {
      newConsultation.relationshipsOther = '';
      err.relationshipsOther = '';
    }

    if (!relationshipFirstRender) {
      setRelationshipFirstRender(true);
      return;
    }

    if (newConsultation.relationships.length === 0) {
      err.relationship = errors(t).relationship;
    } else {
      err.relationship = '';
    }

    if (isOverSea) {
      err.suburb = '';
      newConsultation.suburb = '';
    }
    newConsultation.outsideOfAustralia = isOverSea;
    setErrorMsg({ ...err });
    setConsultation({ ...newConsultation });
  };

  const renderIndividual = () => {
    return (
      <Individual
        email={consultation.email}
        suburb={consultation.suburb}
        city={consultation.city}
        relationships={consultation.relationships}
        relationshipsOther={consultation.relationshipsOther}
        birthYear={consultation.birthYear}
        languageSpokenAtHome={consultation.languageSpokenAtHome}
        languageSpokenAtHomeOther={consultation.languageSpokenAtHomeOther}
        errMsg={errorMsg}
        isOutsideOfAustralia={consultation.outsideOfAustralia}
        gender={consultation.gender}
        genderOther={consultation.genderOther}
        handleRelationship={handleChangeCheckbox}
        addressLine1={consultation.addressLine1}
        stateOrProvince={consultation.stateOrProvince}
        zipOrPostalCode={consultation.zipOrPostalCode}
        country={consultation.country}
        handleChange={handleChangeInput}
        setConsultation={setConsultation}
      />
    );
  };

  const renderOrganisation = () => {
    return (
      <Organisation
        email={consultation.email}
        organisationName={consultation.organisationName}
        role={consultation.role}
        organisationABNorACN={consultation.organisationABNorACN}
        phone={consultation.phone}
        address={consultation.address}
        addressLine1={consultation.addressLine1}
        addressLine2={consultation.addressLine2}
        addressLine3={consultation.addressLine3}
        organisationType={consultation.organisationType}
        errMsg={errorMsg}
        handleChange={handleChangeInput}
        isOutsideOfAustralia={consultation.outsideOfAustralia}
        city={consultation.city}
        stateOrProvince={consultation.stateOrProvince}
        zipOrPostalCode={consultation.zipOrPostalCode}
        country={consultation.country}
        setConsultation={setConsultation}
      />
    );
  };

  const handleSubmit = (action: string) => {
    const errors = triggerError();
    if (checkProperties(errors)) {
      setIsUpdating(true);

      let finalConsultation = consultation;
      const notListText = t('community-engagement-profile-input-language-not-listed');
      finalConsultation.gender =
        finalConsultation.gender === notListText ? NOT_LISTED_HERE : consultation.gender;
      finalConsultation.languageSpokenAtHome =
        finalConsultation.languageSpokenAtHome === notListText
          ? NOT_LISTED_HERE
          : consultation.languageSpokenAtHome;
      handleUpdate(finalConsultation, type, action);
    } else {
      // scroll to top of form
      const firstFormField = document.getElementById('form-container');
      if (firstFormField) {
        firstFormField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const renderBottom = () => {
    return (
      <>
        {item.id ? (
          <ButtonWrapper>
            <ContinueButton isEdit={true} onClick={() => handleSubmit(updateProfile)}>
              {isUpdating && (
                <RefreshIcon>
                  <SvgRefresh />
                </RefreshIcon>
              )}
              Update
            </ContinueButton>
            {!consultation.defaultProfile && (
              <AddNewWrapper
                isEdit={true}
                onClick={() => handleDialog(true, item.id)}
              >
                <SvgBin />
                Delete profile
              </AddNewWrapper>
            )}
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <ContinueButton isEdit={true} onClick={() => handleSubmit(addNewProfile)}>
              {isUpdating && (
                <RefreshIcon>
                  <SvgRefresh />
                </RefreshIcon>
              )}
              Continue
            </ContinueButton>
          </ButtonWrapper>
        )}
      </>
    );
  };

  return (
    <Container id="form-container">
      <BackButton onClick={handleChange}>
        <ArrowLeft />
        Back
      </BackButton>
      {type === individual ? <>{renderIndividual()}</> : <>{renderOrganisation()}</>}
      <QuestionWithInput title={t('community-engagement-profile-policy')} isSelect={true}>
        <RichTextContent field={dataPolicy} />
      </QuestionWithInput>
      {renderBottom()}
    </Container>
  );
};

export default Questions;
