import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PropertyCertificateCard from './PropertyCertificateCard';
import {
  CertificateOrderWrapper,
  MainContent,
  ShowMoreContainer,
  ContentSection,
  UnAvailableSection
} from './styledPropertyCertificatesTab';
import FilterTabs from '../FilterTabs';
import { DashboardTabsContext } from '..';
import ShowMore from '../../ShowMore';
import TextMessage from '../../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { PROPERTY_CERTIFICATES_TAB } from '../constant';
import TipBox from '_components/CityConnect/RatesCalculator/RatesInput/TipBox';

interface IPropertyCertificatesTabProps {
  tabData: any;
  filters: any;
}

const PropertyCertificatesTab: React.FC<IPropertyCertificatesTabProps> = ({ tabData, filters }) => {
  const {
    activeTab,
    currentFilter,
    changeFilter,
    showMoreData,
    loading,
    error,
    description,
    notificationMsg,
    unavailableMessage,
    handleGetMoreItems
  } = useContext(DashboardTabsContext);

  useEffect(() => {
    if (error && activeTab === PROPERTY_CERTIFICATES_TAB) {
      throw error;
    }
  }, [error]);

  const formatTextMessageFields = (value: string) => {
    return {
      data: {
        datasource: {
          message: {
            jss: {
              value: value
            }
          }
        }
      }
    };
  };

  if (unavailableMessage) {
    return <>
      {/* notification message */}
      {notificationMsg && <TipBox tipDescription={notificationMsg} />}
      <UnAvailableSection>
        {/* Unavailable message */}
        <TextMessage fields={formatTextMessageFields(unavailableMessage)} />
      </UnAvailableSection>
    </>
  }

  return (
    <>
      {/* notification message */}
      {notificationMsg && <TipBox tipDescription={notificationMsg} />}
      {/* filters */}
      {filters.length > 0 && (
        <ContentSection>
          <FilterTabs
            filters={filters}
            changeFilter={changeFilter}
            currentFilter={currentFilter[activeTab]}
            className="filter"
          />
        </ContentSection>
      )}
      {/* main content */}
      <MainContent>
        {tabData && tabData.length > 0 ? (
          <>
            <CertificateOrderWrapper>
              {tabData?.map((data, idx) => {
                return (
                  <PropertyCertificateCard className="certificateOrderCard" key={idx} data={data} />
                );
              })}
            </CertificateOrderWrapper>
            {/* show more */}
            {showMoreData.nextPage && (
              <ShowMoreContainer>
                <ShowMore
                  labelTransKeys={{
                    showLabel: 'tab-pagination-showing',
                    ofLabel: 'tab-pagination-of',
                    itemLabel: showMoreData.tabName ? 'tab-pagination-' + showMoreData.tabName : '',
                    showMoreLabel: 'tab-pagination-show-more',
                    loadingLabel: 'tab-pagination-loading'
                  }}
                  numbers={{
                    showingNumber: String(showMoreData.showRequest),
                    totalNumber: showMoreData.totalItems
                  }}
                  handleClickShowMore={handleGetMoreItems}
                  isLoading={showMoreData.showMoreLoading}
                />
              </ShowMoreContainer>
            )}
          </>
        ) : (
          <TextMessage fields={formatTextMessageFields(description)} loading={loading} />
        )}
      </MainContent>
    </>
  );
};

const PropertyCertificatesTabFallback: React.FC = () => {
  const [t] = useTranslation();
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return <TextMessage fields={errorMessage} />;
};

export default withErrorBoundary(PropertyCertificatesTab, PropertyCertificatesTabFallback);
