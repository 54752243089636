import React from 'react';
import { withSvg } from '../Svg';

const SvgChevronDown = () => (
  <path
    d="M5.40914 6.92902L0.412667 1.92952C0.334615 1.85206 0.272663 1.7599 0.230385 1.65837C0.188108 1.55683 0.166341 1.44792 0.166341 1.33792C0.166341 1.22792 0.188108 1.11901 0.230385 1.01747C0.272663 0.91593 0.334615 0.823773 0.412667 0.746312C0.490081 0.668213 0.582183 0.606224 0.683661 0.563921C0.785139 0.521618 0.893983 0.499837 1.00392 0.499837C1.11385 0.499837 1.22269 0.521618 1.32417 0.563921C1.42565 0.606224 1.51775 0.668213 1.59516 0.746312C3.01024 2.24661 4.46355 3.71146 6.00379 5.08338L10.4056 0.746312C10.5624 0.589409 10.7751 0.501261 10.9969 0.501261C11.2186 0.501261 11.4313 0.589409 11.5881 0.746313C11.7449 0.903216 11.833 1.11602 11.833 1.33792C11.833 1.44779 11.8114 1.55659 11.7694 1.65809C11.7273 1.7596 11.6657 1.85183 11.5881 1.92953L6.59163 6.92902C6.51244 7.00488 6.41905 7.06434 6.31683 7.104C6.11409 7.18734 5.88668 7.18734 5.68394 7.104C5.58172 7.06434 5.48833 7.00488 5.40914 6.92902Z"
    fill="#041C2C"
  />
);

export default withSvg(12, 8)(SvgChevronDown);
