import React from "react";
import {
    Dot,
    Info,
    RowWrapperContainer,
    InfoText
} from "./StyledTab";
import ProfileIcon from "_utils/components/CommunityEngagement/ProfileIcon";

interface rowProps {
    items: any
}

const RowWrapper: React.FC<rowProps> = ({items}) => {
    return (
        <RowWrapperContainer>
      {items
        .filter((item) => item.value.length > 0)
        .map((item, idx) => (
          <Info key={idx}>
            <ProfileIcon icon={item.type} />
            <InfoText>{item.value}</InfoText>
            </Info>
        ))}
        </RowWrapperContainer>
  );
};

export default RowWrapper;