import styled from 'styled-components';
import { spacing } from '_utils/props';
import { mq } from '_utils/styles';

// INFO: container style
export const Container = styled.div`
  width: 100%;
  min-height: 360px;
  color: ${({ theme }) => theme.colors.element.white};
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  @media print {
    color: ${({ theme }) => theme.colors.element.black};
    background-color: ${({ theme }) => theme.colors.background.primary};
  }

  ${mq.between('md', 'lg')} {
    min-height: 340px;
  }

  ${mq.between('sm', 'md')} {
    min-height: 220px;
  }

  ${mq.lessThan('sm')} {
    min-height: 204px;
  }

  .Banner-container {
    ${({ theme }) => spacing({ theme, py: 8 })}
  }

  .hidden-xs {
    ${mq.lessThan('sm')} {
      display: none;
    }
  }
`;

export const ImageWrap = styled.div`
  width: 100%;
  height: auto;
  & img {
    object-fit: contain;
    max-height: 200px;

    ${mq.between('md', 'lg')} {
      max-height: 180px;
    }

    ${mq.between('sm', 'md')} {
      max-height: 140px;
    }
  }
`;
