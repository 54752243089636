import getTypeFromValue from '_utils/helpers/getTypeFromValue';

// note! enum values match keys sent by server
export enum StatusType {
  book = 'book',
  underReview = 'under-review',
  open = 'open',
  inProgress = 'in-progress',
  planned = 'planned',
  closed = 'closed',
  completed = 'completed'
}

export enum TimelineStatusType {
  underReview = 'under-review',
  openForFeedback = 'open-for-feedback',
  inProgress = 'in-progress',
  planned = 'planned',
  closed = 'closed',
  completed = 'completed',
  onExhibition = 'on-exhibition',
  reportingBack = 'reporting-back',
  open = 'open'
}

export enum TimelineStageStatusType {
  completed = 'completed',
  inProgress = 'in-progress',
  notStarted = 'not-started'
}

/**
 * @param target either a string representation of a content type or an object containing a content type
 * @param key optional used to retrieve contentType value (if passing an object)
 */
export const getStatusType = (value: string | object, key: string = 'type'): StatusType => {
  return getTypeFromValue<StatusType>(StatusType, value, key);
};

export const getTimelineStatusType = (value: string | object, key: string = 'type'): TimelineStatusType => {
  return getTypeFromValue<TimelineStatusType>(TimelineStatusType, value, key);
};

export const getTimelineStageStatusType = (value: string | object, key: string = 'type'): TimelineStageStatusType => {
  return getTypeFromValue<TimelineStageStatusType>(TimelineStageStatusType, value, key);
};