import { fetchApi, get } from './genericService';

export const getDashboardTabContentData = (endPoint, config) => {
  let tabEndPoint;
  if (typeof endPoint === 'string') {
    tabEndPoint = endPoint;
  } else if (typeof endPoint === 'object') {
    const { activeTab, activeFilter } = endPoint;
    tabEndPoint = '/api/v1/account/dashboard/tabs/' + activeTab;
    if (activeFilter) tabEndPoint += '/' + activeFilter;
  }

  return fetchApi(tabEndPoint, config);
};

export function getDashboardTabsNavData(success, failed) {
  return get('/api/v1/account/dashboard/tabs', success, failed);
}

export function getUpdatedBalance(link, success, failed) {
  // '/api/v1/account/property-certificates/:certificateId'
  return get(link, success, failed);
}
