import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getPaymentsDueList } from '_services/paymentsDueListService';
import PaymentsSort from '../PaymentsDueList/PaymentsSort';
import PaymentDue from './PaymentDue';
import Checkbox from '_utils/components/Corporate/Checkbox';
import { PDL_CheckboxContainer } from './PaymentDue/StyledPaymentDue';
import NavItem from '_components/Corporate/NavItem';
import { IResponseTemplate } from './definitions';
import { IPaymentDueLoaded } from './PaymentDue/definitions';
import ShowMore from '_components/CityConnect/ShowMore';
// import icons
import SvgSortDueFirst from '_utils/icons/PaymentsDueList/SortDueFirst';
import SortDueLast from '_utils/icons/PaymentsDueList/SortDueLast';
import SortAmountArrowDown from '_utils/icons/PaymentsDueList/SortAmountArrowDown';
import SortAmountDownAlt from '_utils/icons/PaymentsDueList/SortAmountDownAlt';
import SortAmountDown from '_utils/icons/PaymentsDueList/SortAmountDown';
// import styled
import {
  PaymentsDueListWrapper,
  PDL_ListAction,
  PDL_SelectAll,
  PDL_Divider,
  PDL_List,
  PDL_LoadingOrNoData,
  PDL_ShowMore,
  PDL_StickyFooter,
  PDL_TotalityContainer,
  PDL_TotalityText,
  PDL_TotalityButton,
  RefreshWrapper
} from './StyledPaymentDueMutiplePay';
import { Refresh } from '_utils/icons';

const DEFAUL_PAGE_SIZE = 10;
const SORT_TYPE = {
  DUE_FIRST: 'due-first',
  DUE_LAST: 'due-last',
  HIGHEST_AMOUNT: 'highest-amount',
  LEAST_AMOUNT: 'least-amount'
};
const INITIAL_RESPONSE_DATA = {
  sort: [],
  results: {
    totalItems: '0',
    currentPage: '1',
    pageSize: '10',
    nextPage: '',
    items: []
  },
  actions: []
};

const PaymentDueMutiplePay: React.FC = () => {
  const [t] = useTranslation();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [responseData, setResponseData] = useState<IResponseTemplate>(INITIAL_RESPONSE_DATA);
  const [listPaymentDueLoaded, setListPaymentDueLoaded] = useState<IPaymentDueLoaded[]>([]);
  const [totalPay, setTotalPay] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const LIST_DATA_SORT = [
    {
      value: SORT_TYPE.DUE_FIRST,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.DUE_FIRST)?.link || '',
      label: t('payments-due-sort-options-due-first'),
      icon: <SvgSortDueFirst />
    },
    {
      value: SORT_TYPE.DUE_LAST,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.DUE_LAST)?.link || '',
      label: t('payments-due-sort-options-due-last'),
      icon: <SortDueLast />
    },
    {
      value: SORT_TYPE.LEAST_AMOUNT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.LEAST_AMOUNT)?.link || '',
      label: t('payments-due-sort-options-least-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDownAlt />
        </React.Fragment>
      )
    },
    {
      value: SORT_TYPE.HIGHEST_AMOUNT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.HIGHEST_AMOUNT)?.link || '',
      label: t('payments-due-sort-options-highest-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDown />
        </React.Fragment>
      )
    }
  ];

  // get data from api
  useEffect(() => {
    getPaymentsDueListFromAPI('');
  }, []);

  // update loaded payment due list
  useEffect(() => {
    const loadedMore: Array<IPaymentDueLoaded> = responseData.results.items.map(
      (paymentDue, index) => {
        return {
          ...paymentDue,
          isSelected: false
        };
      }
    );
    // check load more or load new
    if (responseData.results.currentPage === '1') {
      setListPaymentDueLoaded(loadedMore);
    } else {
      setListPaymentDueLoaded(listPaymentDueLoaded.concat(loadedMore));
    }
  }, [responseData]);

  // trigger loaded new page data, then re-calculate
  useEffect(() => {
    if (listPaymentDueLoaded.length) {
      calculateTotalPayAndIsSelectAll();
    }
  }, [listPaymentDueLoaded]);

  // get payments due list from api
  const getPaymentsDueListFromAPI = (link) => {
    getPaymentsDueList(link, getPaymentsDueListSuccess, (data) => console.log(data.error));
  };

  // get payments due list from api success
  const getPaymentsDueListSuccess = (response) => {
    const paymentsDue = response?.data?.paymentsDue;
    if (paymentsDue) {
      const results = paymentsDue.results;
      setResponseData({
        ...responseData,
        sort: paymentsDue.sort,
        results: {
          totalItems: results.totalItems?.toString(),
          currentPage: results.currentPage?.toString(),
          pageSize: results.pageSize?.toString(),
          nextPage: results.nextPage,
          items: results.items
        },
        actions: paymentsDue.actions
      });
    }
    setIsLoading(false);
    // reset show more text
    setIsLoadingMore(false);
  };

  // calculate again total pay and is select all
  const calculateTotalPayAndIsSelectAll = () => {
    let temp: number = 0;
    let selectAll: boolean = true;
    listPaymentDueLoaded.forEach((paymentDue) => {
      // ignore payment with status is processing
      if (paymentDue.status.toLowerCase() !== 'payment-processing') {
        if (paymentDue.isSelected) {
          temp += parseFloat(paymentDue.amount.slice(1));
        } else {
          selectAll = false;
        }
      }
    });
    setTotalPay(temp.toFixed(2));
    setIsSelectAll(selectAll);
  };

  // handle click on select all
  const handleToggleSelectAll = (value: boolean) => {
    let temp = [...listPaymentDueLoaded];
    temp.forEach((paymentDue) => {
      // ignore payment with status is processing
      if (paymentDue.status.toLowerCase() !== 'payment-processing') {
        paymentDue.isSelected = value;
      }
    });
    setListPaymentDueLoaded(temp);
  };

  // handle click on show more button
  const handleClickShowMore = () => {
    // call api to load more
    getPaymentsDueListFromAPI(responseData.results.nextPage);
    // set button show more with text loading...
    setIsLoadingMore(true);
  };

  // handle event change sort value
  const handleChangeSort = (link: string) => {
    // call api to get sorted data
    if (link) {
      getPaymentsDueListFromAPI(link);
    }
  };

  // update isSelected
  const handleSelectPaymentDue = (index: number, value: boolean) => {
    let temp = [...listPaymentDueLoaded];
    temp[index].isSelected = value;
    setListPaymentDueLoaded(temp);
  };

  // trigger script when click Pay selected button
  const handleClickPaySelected = () => {
    // todo trigger some script
  };

  // render each payment due
  const renderPaymentsDue = () => {
    const data = listPaymentDueLoaded;
    return (
      data.length &&
      data.map((payment, index) => {
        return (
          <PaymentDue
            key={index}
            payment={payment}
            listIndex={index}
            isReadOnly={false}
            onSelect={handleSelectPaymentDue}
          />
        );
      })
    );
  };

  // check conditions to render corresponding content
  const handleShowPaymentDueContent = () => {
    return isLoading ? (
      <PDL_LoadingOrNoData>
        {t('dashboard-loading-information')}
        <RefreshWrapper>
          <Refresh ariaHidden={false} />
        </RefreshWrapper>
      </PDL_LoadingOrNoData>
    ) : listPaymentDueLoaded.length ? (
      renderPaymentsDue()
    ) : (
      <PDL_LoadingOrNoData>{t('payments-due-no-payments-due')}.</PDL_LoadingOrNoData>
    );
  };

  return (
    <PaymentsDueListWrapper>
      <PDL_ListAction>
        <PaymentsSort data={LIST_DATA_SORT} onChangeSort={handleChangeSort} />
        <PDL_CheckboxContainer disabled={listPaymentDueLoaded.length === 0}>
          <Checkbox
            id="PDL_SelectAll"
            name="filterOption"
            value={true}
            onChange={(event) => handleToggleSelectAll(!isSelectAll)}
            checked={isSelectAll}
          >
            <PDL_SelectAll selectAll={isSelectAll} disabled={listPaymentDueLoaded.length === 0}>
              {t('payments-due-select-all')} ({listPaymentDueLoaded.length})
            </PDL_SelectAll>
          </Checkbox>
        </PDL_CheckboxContainer>
      </PDL_ListAction>
      <PDL_Divider />
      <PDL_List>{handleShowPaymentDueContent()}</PDL_List>
      <PDL_ShowMore>
        {parseInt(responseData.results.totalItems) > DEFAUL_PAGE_SIZE &&
          responseData.results.nextPage && (
            <ShowMore
              labelTransKeys={{
                showLabel: 'payments-due-pagination-showing',
                ofLabel: 'payments-due-pagination-of',
                itemLabel: 'payments-due-pagination-payments',
                showMoreLabel: 'payments-due-pagination-show-more',
                loadingLabel: 'payments-due-pagination-loading'
              }}
              numbers={{
                showingNumber: '' + listPaymentDueLoaded.length,
                totalNumber: '' + responseData.results.totalItems
              }}
              isLoading={isLoadingMore}
              handleClickShowMore={handleClickShowMore}
              className="PDL_ShowMoreConainer"
            />
          )}
      </PDL_ShowMore>
      {totalPay && totalPay > 0 && (
        <PDL_StickyFooter>
          <PDL_TotalityContainer>
            <PDL_TotalityText>
              {t('payments-due-total-selected')}: ${totalPay}
            </PDL_TotalityText>
            {responseData.actions?.length > 0 && responseData.actions[0].link && (
              <NavItem
                link={{
                  url: responseData.actions[0].link,
                  type: 'internal'
                }}
                StyledLink={PDL_TotalityButton}
                additionalStyledLinkProps={{ onClick: handleClickPaySelected }}
              >
                {t('payments-due-pay-selected-button')}
              </NavItem>
            )}
          </PDL_TotalityContainer>
        </PDL_StickyFooter>
      )}
    </PaymentsDueListWrapper>
  );
};

export default PaymentDueMutiplePay;
