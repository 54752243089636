import React from "react";
import { compose } from 'ramda';
import {
    Container,
    Title,
    Description,
    ButtonContainer,
    LinkButton,
    ButtonTitle,
    ButtonDescription,
    ButtonTitleText,
    CTAButton,
    EditorDiv,
    ButtonDiv
} from "./StyledLinkButtons";
import {
    linkItem,
    multiCTAProps
} from "./definition";
import { withEditMode, withEditModeNotice } from "_containers/BaseComponent";
import LinkButtonIcon from "_utils/components/Corporate/LinkButtonIcon";

const colors = {
    "darlinghurstPurple": {
        color: "#6009A0",
        background: "#E8DAF1",
        hover: "#B790D3"
    },
    "annandaleOrange": {
        color: "#C33D14",
        background: "#FFE6D7",
        hover: "#FFB485"
    },
    "newtownFuchsia": {
        color: "#821846",
        background: "#F8DDE6",
        hover: "#EE9AB4"
    },
    "pymontBlue": {
        color: "#005084",
        background: "#E0F6FD",
        hover: "#9FE5F6"
    }
}

const EditorText = compose(withEditModeNotice)(EditorDiv);

const renderLinks = (links, editMode) => {

    if (links.length === 1) {
        // button style
        return (
            <ButtonContainer>
                <CTAButton field={links[0]?.ctaLink?.jss} linkTheme="none" />
            </ButtonContainer>
        );
    }

    return (
        <ButtonContainer>
            {links.map((link: linkItem, idx: number) =>
                editMode ? (
                    <ButtonDiv key={idx} color={colors[link?.colour?.value] || ''}>
                        <ButtonTitle>
                            <LinkButtonIcon status={link?.icon?.value} />
                            <ButtonTitleText
                                color={colors[link?.colour?.value] || ''}
                                field={link?.title?.jss}
                                tag={'h3'}
                            />
                        </ButtonTitle>
                        <ButtonDescription field={link?.description?.jss} tag={'h4'} />
                    </ButtonDiv>
                ) : (
                    <LinkButton key={idx} color={colors[link?.colour?.value] || ''} field={link?.ctaLink?.jss}>
                        <ButtonTitle>
                            <LinkButtonIcon status={link?.icon?.value} />
                            <ButtonTitleText
                                color={colors[link?.colour?.value] || ''}
                                field={link?.title?.jss}
                                tag={'h3'}
                            />
                        </ButtonTitle>
                        <ButtonDescription field={link?.description?.jss} tag={'h4'} />
                    </LinkButton>
                )
            )}
        </ButtonContainer>
    );
};

const renderTextField = (field, editMode, markup) => {
    return editMode === false && !field?.jss?.value ? null : markup;
};

const LinkButtons: React.FC<multiCTAProps> = ({ fields, editMode }) => {
    if (fields.data.datasource === null) {
        if (editMode) {
            return (
                <EditorText
                    noticeEnabled
                    noticeLevel="warning"
                    noticeMessage={`This component needs a datasource to render.`}
                />
            );
        }

        return null;
    }

    const title = fields?.data?.datasource?.title?.jss || null;
    const description = fields?.data?.datasource?.description?.jss || null;
    const links = fields?.data?.datasource?.links || null;

    const validatedLinks = links.filter((l) => editMode || l?.ctaLink?.jss?.value?.href !== '');
    let noticeEnabled = (validatedLinks.length === 0) && editMode ? true : false;

    const component = (
        <Container isSingle={validatedLinks.length > 1 ? false: true}>
            {renderTextField(title, editMode, <Title field={title} tag={'h2'}/>)}
            {renderTextField(description, editMode, <Description field={description} tag={'h3'}/>)}
            {renderLinks(validatedLinks, editMode)}
        </Container>
    );

    return editMode ? (
        <EditorText
            noticeEnabled={noticeEnabled}
            noticeMessage={'This component has no valid links or bottom link and will not be displayed.'}
        >
            {component}
        </EditorText>
    ) : (
        component
    );
}

export default compose(withEditMode)(LinkButtons);