import styled from 'styled-components';
import { mq } from '_utils/styles';
import { spacing } from '_utils/props';

// INFO: Tab wrapper styling
export const PropertyTabWrapper = styled.div`
  width: 100%;

  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const ShowMoreContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const ContentSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const UnAvailableSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;
