import styled, { css } from 'styled-components';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { warnInDev } from '_utils/helpers/dev';

const imageTypes = {
  publication: (theme) => css`
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.dna.default};
      border-radius: 50%;
      @media print {
        background-color: transparent;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      &.portrait {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: auto;
      }

      &.landscape {
        top: 50%;
        left: 0;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
      }
    }
  `,
  focus: (theme) => css`
    height: 100%;

    img {
      object-fit: cover;
      font-family: 'object-fit: cover';
    }
  `,
  simple: (theme) => css`
    height: 100%;

    img {
      object-fit: cover;
      font-family: 'object-fit: cover';
    }
  `
};

/* 
  Applying img styles here as Sitecore image component doesn't like custom props.
  Throws: Warning: React does not recognize the `imageType` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `imagetype` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
*/
const handleImageTypesContainer = ({ theme, imageType }) => {
  if (typeof imageTypes[imageType] !== 'undefined') {
    return imageTypes[imageType](theme);
  }

  warnInDev(
    `The specifed imageType (${imageType}) hasn't been configured. Fallback back to simple imageType.`
  );

  return imageTypes['simple'](theme);
};

const imageStyles = css`
  width: 100%;
  height: 100%;
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  z-index: 5;
`;

export const EditorImage = styled(SCImage)`
  ${imageStyles}
`;

export const Image = styled.img`
  ${imageStyles}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  ${handleImageTypesContainer}
`;
