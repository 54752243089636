import { css } from 'styled-components';
import { spacing } from '_utils/props';

import color from '_utils/styles/color';
import { BackgroundColors } from '_utils/components/Corporate/Section';

const sectionThemeColors = {
  [BackgroundColors.White]: color('all.white'),
  [BackgroundColors.Grey]: color('all.deepBlueTint07'),
  [BackgroundColors['Light beige']]: color('all.light'),
  [BackgroundColors.Lavender]: color('all.blueTint7')
};

export const handleSectionTheme = (sectionTheme) => css`
  background-color: ${sectionThemeColors[sectionTheme]};

  /* swallow padding if section follows another of same colour */
  .${sectionTheme} + & {
    padding-top: 0 !important;
  }
`;

export default ({ theme, sectionTheme = 'white' }) => css`
  ${spacing({ theme, py: 7 })}
  ${handleSectionTheme(sectionTheme)};
`;
