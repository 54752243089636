import React from 'react';
import { withSvg } from '../Svg';

const SvgPermit = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.46 7.65906C16.4513 7.58249 16.4346 7.50706 16.41 7.43403V7.35902C16.3699 7.27332 16.3165 7.19455 16.2516 7.12566V7.12566L11.251 2.12503C11.1821 2.0602 11.1033 2.00675 11.0177 1.96668C10.9928 1.96314 10.9675 1.96314 10.9426 1.96668C10.858 1.91812 10.7645 1.88695 10.6676 1.875H5.62531C4.96219 1.875 4.32622 2.13843 3.85732 2.60732C3.38843 3.07622 3.125 3.71219 3.125 4.37531V16.0434C3.125 16.7066 3.38843 17.3425 3.85732 17.8114C4.32622 18.2803 4.96219 18.5438 5.62531 18.5438H13.9597C14.6228 18.5438 15.2588 18.2803 15.7277 17.8114C16.1966 17.3425 16.46 16.7066 16.46 16.0434V7.70906C16.46 7.70906 16.46 7.70906 16.46 7.65906ZM11.4594 4.71702L13.618 6.87563H12.2928C12.0718 6.87563 11.8598 6.78782 11.7035 6.63152C11.5472 6.47522 11.4594 6.26323 11.4594 6.04219V4.71702ZM14.7931 16.0434C14.7931 16.2645 14.7053 16.4765 14.549 16.6328C14.3927 16.7891 14.1807 16.8769 13.9597 16.8769H5.62531C5.40427 16.8769 5.19228 16.7891 5.03598 16.6328C4.87968 16.4765 4.79187 16.2645 4.79187 16.0434V4.37531C4.79187 4.15427 4.87968 3.94228 5.03598 3.78598C5.19228 3.62968 5.40427 3.54188 5.62531 3.54188H9.7925V6.04219C9.7925 6.70531 10.0559 7.34128 10.5248 7.81018C10.9937 8.27908 11.6297 8.5425 12.2928 8.5425H14.7931V16.0434ZM11.7011 10.4511L8.95906 13.2014L7.88393 12.1179C7.72699 11.961 7.51413 11.8728 7.29219 11.8728C7.07024 11.8728 6.85739 11.961 6.70045 12.1179C6.54351 12.2749 6.45534 12.4877 6.45534 12.7097C6.45534 12.9316 6.54351 13.1445 6.70045 13.3014L8.36732 14.9683C8.4448 15.0464 8.53698 15.1084 8.63854 15.1507C8.7401 15.193 8.84904 15.2148 8.95906 15.2148C9.06909 15.2148 9.17802 15.193 9.27958 15.1507C9.38114 15.1084 9.47332 15.0464 9.5508 14.9683L12.8846 11.6346C13.0415 11.4776 13.1297 11.2648 13.1297 11.0428C13.1297 10.8209 13.0415 10.608 12.8846 10.4511C12.7276 10.2941 12.5148 10.206 12.2928 10.206C12.0709 10.206 11.858 10.2941 11.7011 10.4511V10.4511Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgPermit);
