import React, { ReactNode, forwardRef } from 'react';

import { Container, Input, Label, Check } from '../Radio/StyledRadio';

type CheckProps = {
  id: string;
  className?: string,
  name: string;
  value: any;
  checked: boolean;
  onChange: (e) => void;
  children: ReactNode;
  checkboxOrganisationStyle?: string
};

export type Ref = HTMLElement;

const Checkbox = forwardRef<Ref, CheckProps>(
  ({ id, className, name, value ,checked, children, onChange, checkboxOrganisationStyle }, ref) => (
    <Container className={className}>
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        ref={ref}
        role={name}
      />
      <Label htmlFor={id}>{children}</Label>
      <Check type="checkbox" name={name} role="presentation" aria-hidden="true" />
    </Container>
  )
);

export default Checkbox;
