import styled from 'styled-components';
import { buttonReset, svg, focusOutline } from '_utils/styles';
import depth from '_utils/styles/depth';
import mq from '_utils/styles/mq';
import { Grid } from '_utils/components/Corporate';

export const Dialog = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${depth.MODAL};
  background-color: ${({ theme }) => theme.colors.modal.container.background};
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

export const Layout = styled.div`
  ${mq('lg')} {
    margin-top: 64px;
  }
`;

export const CloseContainer = styled.div`
  width: 100%;
  height: 64px;
  flex: none;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mq('lg')} {
    width: calc(100% - 15px);
    position: fixed;
    left: 0;
    top: 0;
  }
`;

export const CloseButton = styled.button`
  ${() => buttonReset()}

  &:focus {
    ${({ theme }) => focusOutline({ theme })}
    .safe-focus-removal & {
      outline: 0;
    }
  }

  position: relative;
  padding: 10px;
  display: inline-block;
  margin-right: 8px;
`;

export const CrossWrapper = styled.span`
  ${() => svg(28, 28)};
  width: 28px;
  flex: none;
  path {
    fill: ${({ theme }) => theme.colors.modal.close.fill};
  }

  ${CloseButton}:hover & {
    path {
      fill: ${({ theme }) => theme.colors.modal.close.fillHover};
    }
  }
`;

export const ContentContainer = styled(Grid.Container)``;
