import React, { useEffect, useRef } from 'react';
import { TextArea } from './StyledTextareaAutoResize';

interface ITextareaAutoResizeProps {
  id: string;
  value: any;
  onChange: (e) => void;
  disabled?: boolean;
  onFocus?: (e) => void;
  onBlur?: (e) => void;
  className?: string;
  isPersonalInfo?: boolean;
  rows?: number;
  maxLength?: number;
}

const TextareaAutoResize: React.FC<ITextareaAutoResizeProps> = ({
  id,
  value,
  onChange,
  isPersonalInfo = false,
  ...rest
}) => {
  const inputEl = useRef(null);
  useEffect(() => {
    const tx = document.getElementById(id);
    tx.setAttribute('style', 'height:' + tx.scrollHeight + 'px;overflow-y:hidden;');
    tx.addEventListener('input', OnInput, false);

    return () => {
      tx.removeEventListener('input', OnInput, false);
    };
  }, []);

  useEffect(() => {
    OnInput();
  }, [value]);

  const OnInput = () => {
    inputEl.current.style.height = 'auto';
    inputEl.current.style.height = inputEl.current.scrollHeight + 'px';
  };

  return (
    <TextArea
      id={id}
      value={value}
      name={`item-${id}`}
      role="textArea"
      onChange={onChange}
      isPersonalInfo={isPersonalInfo}
      {...rest}
      ref={inputEl}
    />
  );
};

export default TextareaAutoResize;
