import React from 'react';
import {
    Clipboard,
    DashBoardRequestCheck,
    DashBoardRequestClose,
    DashBoardRequestCorner,
    LinkButtonCommentExclaim,
    Pin
} from '_utils/icons';

export enum ConsultationType {
    survey = 'survey',
    'community-ideas' = 'community-ideas',
    'interactive-map' = 'interactive-map',
}

const cases = {
    "yes": {
        color: '#188838',
        icon: <DashBoardRequestCheck/>
    },
    "no": {
        color: '#DA1E5B',
        icon: <DashBoardRequestClose/>
    },
    "unsure": {
        color: "#4E5A5F",
        icon: <DashBoardRequestCorner/>
    }
}

const icons = {
    [ConsultationType.survey]: <Clipboard />,
    [ConsultationType['community-ideas']]: <LinkButtonCommentExclaim />,
    [ConsultationType['interactive-map']]: <Pin color={"#F05F00"}/>,
};

export function ConsultationIcon({ icon }) {
    if (icons[icon]) {
        return icons[icon];
    }

    return null;
}

export function ConsultationOpinions(opinion: string) {
    if (cases[opinion]) {
        return cases[opinion];
    }

    return null;
}
