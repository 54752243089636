import React from 'react';
import { withSvg } from '../Svg';
import colors from '_containers/Theme/colors';

const PDF = () => (
  <React.Fragment>
    <path
      fill={colors.pottsPointRedTint110}
      d="M4.62031 12.8427H4.01587V11.9438H4.62031C4.75237 11.9438 4.86412 11.9831 4.94539 12.0618C5.02666 12.1404 5.07237 12.2472 5.07237 12.3933C5.07237 12.5393 5.02666 12.6517 4.94539 12.7303C4.86412 12.809 4.75237 12.8427 4.62031 12.8427Z"
    />
    <path
      fill={colors.pottsPointRedTint110}
      d="M7.99685 14.0562H7.45336V11.9438H7.99685C8.18478 11.9438 8.34224 11.9888 8.46415 12.0674C8.58605 12.1517 8.67748 12.2697 8.73843 12.4213C8.79938 12.5787 8.83494 12.7697 8.83494 13C8.83494 13.236 8.79938 13.427 8.73843 13.5787C8.67748 13.736 8.58605 13.8539 8.46415 13.9326C8.34224 14.0169 8.18478 14.0562 7.99685 14.0562Z"
    />
    <path
      fill={colors.pottsPointRedTint110}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.94 6.67C15.9695 6.75763 15.9896 6.84813 16 6.94V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H9.05C9.16622 0.0143442 9.27841 0.0517412 9.38 0.11C9.40985 0.10576 9.44015 0.10576 9.47 0.11C9.57282 0.158081 9.66734 0.222216 9.75 0.3L15.75 6.3C15.8278 6.38267 15.8919 6.47718 15.94 6.58V6.67ZM12.59 6L10 3.41V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H12.59ZM13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17V16H2V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071ZM2 10V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8V5C8 5.79565 8.31607 6.55871 8.87868 7.12132C9.44129 7.68393 10.2044 8 11 8H14V10H2ZM4.6965 11H3V15H4.01587V13.7865H4.6965C5.12317 13.7865 5.46348 13.6629 5.71237 13.4101C5.95618 13.1573 6.08316 12.8202 6.08316 12.3933C6.08316 11.9719 5.95618 11.6348 5.71237 11.382C5.46348 11.1292 5.12317 11 4.6965 11ZM8.01716 11H6.43749V15H8.01716C8.327 15 8.60129 14.9551 8.84002 14.8596C9.07875 14.764 9.27684 14.6292 9.42922 14.4494C9.5816 14.2697 9.69843 14.0618 9.77462 13.8146C9.85081 13.5674 9.89144 13.2978 9.89144 13C9.89144 12.7079 9.85081 12.4382 9.77462 12.191C9.69843 11.9438 9.5816 11.736 9.42922 11.5562C9.27684 11.3764 9.07875 11.2416 8.84002 11.1461C8.60129 11.0506 8.327 11 8.01716 11ZM13 11V12H11.2629V12.5225H12.8883V13.4663H11.2629V15H10.247V11H13Z"
    />
  </React.Fragment>
);

export default withSvg(16, 20)(PDF);
