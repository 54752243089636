import { get } from './genericService';

export function getBannerData(page, success, failed) {
  if (!page) {
    return failed('missing page parameter for banner api.');
  }

  return get(`/api/v1/account/banner/${page}`, success, failed);
}

export function getBannerDataById(page, id, success, failed) {
  if (!page) {
    return failed('missing page parameter for banner api.');
  }

  return get(`/api/v1/account/banner/${page}/${id || 'xxxx'}`, success, failed);
}
