import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import { Arrow } from '_utils/icons';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import Metadata from '_utils/components/CityConnect/Metadata';
import {
  CardWrapper,
  CardHeader,
  ImageWrapper,
  Header,
  ArrowWrapper,
  CardContent,
  Status,
  MetadataWrapperItem,
  CategoryLink
} from './StyledRegistrationItem';

const RegistrationItem: React.FC<any> = (data) => {
  let { link, status, metadata, image, title, color } = data.data;
  const history = useHistory();

  return (
    <CardWrapper onClick={() => history.push(link)}>
      <CardHeader inputColor={color}>
        <ImageWrapper>
          <Image field={image} alt={image?.alt} />
        </ImageWrapper>
        <Header>
          <CategoryLink name={title} role="link" value={title} tabIndex={0}>
            <Text field={{ value: title }} />
          </CategoryLink>
          <ArrowWrapper>
            <Arrow ariaHidden={false} />
          </ArrowWrapper>
        </Header>
      </CardHeader>
      <CardContent>
        <Status>
          <StatusTag text={status} />
        </Status>
        {metadata?.map((item, index) => {
          return (
            <MetadataWrapperItem key={index}>
              <Metadata key={index} metadataType={item.type} text={item.value} />
            </MetadataWrapperItem>
          );
        })}
      </CardContent>
    </CardWrapper>
  );
};

export default RegistrationItem;
