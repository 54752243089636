import React from 'react';

import {
  RateHistoryWrapper,
  RateDetailHistoryItem,
  RateDetailHistoryText,
  RateDetailHistoryAmount
} from './StyledRateDetailHistory';

type RateDetailHistoryProps = {
  data: any;
};

const RateDetailHistory: React.FC<RateDetailHistoryProps> = ({ data }) => {
  return (
    <RateHistoryWrapper role='list'>
      {data?.map((item, index) => {
        return (
          <RateDetailHistoryItem key={index} role='listitem'>
            <RateDetailHistoryText>{item.period}</RateDetailHistoryText>
            <RateDetailHistoryAmount>{item.amount}</RateDetailHistoryAmount>
          </RateDetailHistoryItem>
        );
      })}
    </RateHistoryWrapper>
  );
};

export default RateDetailHistory;
