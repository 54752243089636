import React from 'react';
import { withSvg } from '../Svg';

const SvgPaymentCard = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6759 2.08333H13.7259C14.5072 2.08333 15.1128 2.33345 15.5212 2.75763C15.9092 3.16071 16.0521 3.65328 16.0521 4.03153V4.58333C16.0521 5.04357 15.678 5.41667 15.2165 5.41667C14.7549 5.41667 14.3808 5.04357 14.3808 4.58333V4.03153C14.3808 4.03153 14.3807 4.03189 14.3802 4.02986C14.3777 4.01853 14.3657 3.96396 14.3155 3.91185C14.2781 3.87303 14.1475 3.75911 13.7715 3.75051L4.5718 5.03176C4.44148 5.07255 4.23277 5.17224 4.05978 5.33958C3.88609 5.50759 3.75463 5.73379 3.75463 6.06997L3.75595 15.5966C3.75595 15.5966 3.75595 15.5997 3.75663 15.606C3.7573 15.6122 3.75854 15.6207 3.76075 15.6312C3.76522 15.6524 3.77311 15.6792 3.7862 15.7097C3.81195 15.7696 3.85714 15.8421 3.93523 15.9142C4.08107 16.0489 4.41912 16.2498 5.15621 16.2498H15.2178C15.3946 16.2498 15.6784 16.19 15.894 16.0352C16.0671 15.911 16.2454 15.6967 16.2454 15.225V8.94124C16.2454 8.76491 16.1855 8.48186 16.0302 8.26684C15.9057 8.09432 15.6907 7.91646 15.2178 7.91646H5.52886C5.06735 7.91646 4.69322 7.54337 4.69322 7.08313C4.69322 6.62289 5.06735 6.2498 5.52886 6.2498H15.2178C16.2354 6.2498 16.9521 6.69132 17.3865 7.293C17.7903 7.8522 17.9167 8.4982 17.9167 8.94124V15.225C17.9167 16.2399 17.4739 16.9546 16.8706 17.3878C16.3098 17.7904 15.662 17.9165 15.2178 17.9165H5.15621C4.10458 17.9165 3.32468 17.6219 2.79975 17.1372C2.28734 16.664 2.08467 16.0679 2.08467 15.5966L2.08334 6.06997C2.08334 4.32196 3.54294 3.56894 4.20691 3.40341L4.25787 3.3907L13.6759 2.08333Z"
      fill="#B23914"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7836 12.7081C14.1298 12.7081 14.4104 12.4283 14.4104 12.0831C14.4104 11.738 14.1298 11.4581 13.7836 11.4581C13.4375 11.4581 13.1569 11.738 13.1569 12.0831C13.1569 12.4283 13.4375 12.7081 13.7836 12.7081ZM13.7836 13.7498C14.7067 13.7498 15.4549 13.0036 15.4549 12.0831C15.4549 11.1627 14.7067 10.4165 13.7836 10.4165C12.8606 10.4165 12.1124 11.1627 12.1124 12.0831C12.1124 13.0036 12.8606 13.7498 13.7836 13.7498Z"
      fill="#B23914"
    />
  </>
);

export default withSvg(18, 18)(SvgPaymentCard);
