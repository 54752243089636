import styled, { css } from 'styled-components';
import {
  button,
  buttonReset,
  mq,
  safeFocusRemoval,
  depth,
  hideOnPrint,
  color,
  focusOutline
} from '_utils/styles';
import { spacing } from '_utils/props';

const OpenDropdownCSS = css`
  ${buttonReset};

  &:focus-visible {
    ${safeFocusRemoval};
    ${focusOutline};
  }

  padding: 0 4px;

  & svg {
    fill: ${color('all.deepBlue')};
  }
  ${hideOnPrint};
`;

const LoggedInCSS = css`
  ${buttonReset};
  ${safeFocusRemoval};
  padding: 0 4px;

  & svg {
    fill: ${color('all.deepBlue')};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }

  &:focus-visible {
    ${safeFocusRemoval}
    position: relative;
    outline-color: ${({ theme }) => theme.colors.element.focused};
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
  }
  ${hideOnPrint};
`;

const LoginRegisterCSS = css`
  ${button('default', 'outline')};
  ${safeFocusRemoval};
  flex-shrink: 0;
  padding: 8px 16px;

  & svg {
    fill: ${({ theme }) => theme.colors.button.outline.color};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.button.outline.colorHover};
    }
  }

  body:not(.safe-focus-removal) &:focus {
    &:before {
      content: none;
    }
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -6px;
      top: -6px;
      content: '';
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
  font-family: 'Helvetica Now Text', Helvetica, sans-serif;
  font-weight: 700;
`;

export const UserButtonWrapper = styled.button`
  flex-shrink: 0;
  height: 40px;
  ${({ theme }) => spacing({ theme, mr: 2 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mr: '6px' })};
  }
  ${({ isLogged, isOpenDropdown }) =>
    isOpenDropdown ? OpenDropdownCSS : isLogged ? LoggedInCSS : LoginRegisterCSS}
  height: 100%;
  display: flex;
  align-items: center;
  /* make user dropdown button can't clickable while menu is opening */
  z-index: ${({ isOpenDropdown }) =>
    isOpenDropdown ? depth.MENU_BUTTON + 2 : depth.MENU_BLOCK - 1};
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const UserNameLabel = styled.span`
  margin-left: 10px;
`;

export const CloseLabel = styled(UserNameLabel)`
  display: none;

  ${mq('lg')} {
    display: block;
  }
`;
