import styled, { css } from 'styled-components';
import { Text, Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import ContentRichText from '../ContentRichText';
import { spacing } from '_utils/props';
import {
  mq,
  heading_xxxl,
  heading_m,
  buttonReset,
  text_xscap,
  svg,
  depth,
  text_s,
  hideOnPrint,
  iconColorOnPrint
} from '_utils/styles';
import { MainContainer } from '_layouts/DefaultLayout';

const forceWhiteLinkStyles = () => css`
  a {
    box-shadow: none;
    text-decoration: underline;
    &:visited {
      color: inherit;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  ${mq('lg')} {
    overflow: hidden;
  }

  ${MainContainer} > &:last-child {
    ${({ theme }) => spacing({ theme, mb: 9 })}
  }
`;

export const Heading = styled(Text)`
  ${heading_xxxl}
`;

export const Description = styled(ContentRichText)`
  ${heading_m}

  &&& {
    line-height: 24px;

    ${mq('md')} {
      line-height: 36px;
    }

    ${forceWhiteLinkStyles};
  }

  &:not(:first-child) {
    ${({ theme }) => spacing({ theme, mt: 4 })};
  }
`;

export const SearchContainer = styled.div`
  &:not(:first-child) {
    ${({ theme }) => spacing({ theme, mt: 4 })};
  }

  height: 48px;

  ${mq('md')} {
    height: 56px;
  }

  ${hideOnPrint}
`;

export const IntroColumn = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.element.white};
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  width: 100%;

  ${({ theme }) => spacing({ theme, py: 9, px: 5 })};

  ${mq('lg')} {
    ${({ theme }) => spacing({ theme, px: 6 })};
    width: 50%;
  }

  ${mq('xl')} {
    ${({ theme }) => spacing({ theme, px: 8 })};
    width: 36%;
  }
  z-index: 2;

  @media print {
    ${({ theme }) => spacing({ theme, py: 2, px: 3 })};
  }
`;

export const TextContainer = styled.div`
  position: relative;
  z-index: 3;
`;

export const Circle = styled.div`
  ${mq.lessThan('lg')} {
    display: none;
  }

  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 171%;
  width: 2048px;

  img {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.background.tertiary};
    border-radius: 9999px;
    height: 100%;
    width: auto;
    right: 0;
    transform: translateX(9.6%);
  }
`;

export const ImageColumn = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  ${mq('sm')} {
    padding-top: 56.25%;
  }

  ${mq('lg')} {
    padding-top: 0;
    width: 50%;
  }

  ${mq('xl')} {
    width: 64%;
  }
  @media print {
    padding-top: 35%;
  }
  z-index: 1;
`;

export const Figure = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
  @media print {
    width: 50%;
    height: 50%;
  }
`;

export const Image = styled(SCImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  @media print {
    width: 50%;
    left: 25%;
  }
`;

const captionBox = ({ theme }) => css`
  color: ${theme.colors.element.white};
  background-color: ${theme.colors.background.octonary};
  border-radius: 4px;
`;

export const Caption = styled(ContentRichText)``;

export const CaptionButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  line-height: 1;
  max-width: calc(100% - 16px);

  ${mq('md')} {
    right: 16px;
    bottom: 16px;
    max-width: calc(100% - 64px);
  }
  @media print {
    right: 25%;
  }
`;

export const CaptionButton = styled.button`
  ${buttonReset}
  ${text_xscap}
  ${captionBox}
  margin: 0;
  max-width: 100%;
  ${({ theme }) => spacing({ theme, px: '8px', py: '4px' })}
  outline: 0;

  &:disabled {
    cursor: auto;
  }

  body:not(.safe-focus-removal) &:focus {
    /* psuedo focus outline */
    &:before {
      position: absolute;
      left: -4px;
      top: 0px;
      content: '';
      width: calc(100% + 8px);
      height: calc(100% + 4px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 6px;
    }
  }
`;

export const CaptionButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  /* allows text inside to be truncated @see https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
`;

export const CameraWrapper = styled.span`
  ${svg(16, 16)};
  width: 13px;
  flex: none;

  path {
    fill: ${({ theme }) => theme.colors.element.white};
    ${iconColorOnPrint}
  }
`;

export const CaptionButtonTitle = styled(Text)`
  margin-left: 9px;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

export const CaptionContainer = styled.figcaption`
  ${captionBox}
  ${text_s}
  position: absolute;
  margin: 0;
  right: 8px;
  bottom: 2px;
  transform: translateY(100%);
  max-width: calc(100% - 16px);

  /* use visibility to prevent reflow when toggling and a11y*/
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  ${({ theme }) => spacing({ theme, p: 2 })}

  ${mq('sm')} {
    max-width: 305px;
  }

  ${mq('md')} {
    transform: none;
    right: 16px;
    bottom: 44px;
  }

  &&& {
    ${forceWhiteLinkStyles};
  }

  z-index: ${depth.CAPTION_POPUP};
`;

export const Credit = styled(Text)`
  ${text_xscap}
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
