import styled from 'styled-components';
import { spacing } from '_utils/props';
import { color, svg, mq } from '_utils/styles';

// INFO: item styling
export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  box-shadow: 0px 3px 12px 0px #00000012;
  cursor: pointer;

  ${mq.lessThan('sm')} {
    height: auto;
  }

  &:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  span:focus {
    outline: none;
  }

  &:focus-within,
  &:focus {
    border: 3px solid #4060ff;
  }
`;

export const CardHeader = styled.div`
  ${({ theme }) => spacing({ theme, px: 2, py: 2 })};
  background-color: ${(props) => color(props.inputColor || 'palevioletred')};
  min-height: 168px;

  ${mq.lessThan('md')} {
    min-height: 136px;
  }
`;

export const ImageWrapper = styled.div`
  width: 64px;
  height: 64px;

  ${mq.lessThan('md')} {
    width: 48px;
    height: 48px;
  }

  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
`;

export const Header = styled.h2`
  color: white;
  font-size: 24px;
  ${({ theme }) => spacing({ theme, mt: 2, mb: 0 })};
  ${mq.lessThan('md')} {
    font-size: 20px;
    ${({ theme }) => spacing({ theme, mt: 1, mb: 0 })};
  }
`;

export const CardContent = styled.div`
  ${({ theme }) => spacing({ theme, mx: 2, my: 2 })};
`;

export const Status = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
  font-weight: bold;
  font-size: 14px;
`;

export const MetadataWrapperItem = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ArrowWrapper = styled.span`
  ${svg(14, 14)}
  width: 14px;
  margin-left: 8px;
  margin-right: 10px;

  path {
    fill: #2db84b;
  }
`;

export const CategoryLink = styled.span`
  color: white;

  ${CardWrapper}:hover & {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }
`;
