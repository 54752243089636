import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  WhatYouNeedToDoCardWrapper,
  WhatYouNeedToDoCardContent,
  TextWrapper,
  CardTitle,
  ActionDescription,
  ListActionContainer,
  ActionContainer,
  CallToAction,
  ActivityDescription,
  IconWrapper,
  DownloadSection,
  DownloadFileName,
  DownloadWrapper,
  DownloadIcon,
  DownloadDetails,
  DownloadDescription
} from './StyledWhatYouNeedToDoCard';
import SvgBook from '_utils/icons/RequestTracker/Book';
import SvgDownload from '_utils/icons/Download';
import SvgBan from '_utils/icons/common/Ban';
import SvgPDF from '_utils/icons/common/PDF';
import { byteConverter, dateConverter } from '_utils/helpers';
import { TAction } from '../definitions';
import { ACTION_LIST } from '../../Registration';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import { TRANSLATION_KEYS_SET } from '..';
import { SvgWrapper } from '_components/CityConnect/RequestTracker/RequestTrackerCard/StyledRequestTrackerCard';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

type WhatYouNeedToDoCardProps = {
  data: any;
};

const WhatYouNeedToDoCard: React.FC<WhatYouNeedToDoCardProps> = ({ data }) => {
  const [t] = useTranslation();
  const calloutData = data;
  const { heading, subheadingText, description, actions, documents } = calloutData || {};

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dialogCallbackUrl, setDialogCallbackUrl] = useState<string>();

  const handleClickAction = (action: TAction) => {
    if (action.type === ACTION_LIST.cancelApplication) {
      setIsOpenDialog(true);
      setDialogCallbackUrl(action.link);
    } else if(action.type === ACTION_LIST.redirectExternalLink) {
      window.open(action.link, '_blank');
    } else {
      window.open(action.link, '_self');
    }
  };

  const confirmAction = () => {
    setIsOpenDialog(false);
    window.open(dialogCallbackUrl, '_self');
  };

  return (
    <WhatYouNeedToDoCardWrapper>
      {heading && <ActivityDescription>{heading}</ActivityDescription>}
      <WhatYouNeedToDoCardContent>
        <IconWrapper>
          <SvgBook />
        </IconWrapper>
        <TextWrapper>
          <CardTitle>{subheadingText || t('dashboard-callout-subheading-text')}</CardTitle>
          <ActionDescription
            dangerouslySetInnerHTML={{ __html: description || t('dashboard-callout-description') }}
          ></ActionDescription>
          {/* Call To Action  */}
          {actions?.length > 0 && (
            <ListActionContainer>
              {actions.map((action: TAction, index) => (
                <ActionContainer key={index} className="row">
                  <CallToAction
                    isExternal={action.type === ACTION_LIST.redirectExternalLink}
                    buttonType={
                      action.type === ACTION_LIST.cancelApplication ? 'outline' : 'primary'
                    }
                    onClick={() => handleClickAction(action)}
                  >
                    {action.type === ACTION_LIST.cancelApplication && <SvgBan />}
                    {action.text}
                    {action.type === ACTION_LIST.redirectExternalLink && (
                      <SvgWrapper>
                        <SvgExternal />
                      </SvgWrapper>
                    )}
                  </CallToAction>
                </ActionContainer>
              ))}
              {actions.find((action: TAction) => action.type === ACTION_LIST.cancelApplication) && (
                <DialogueBox
                  translationKeys={TRANSLATION_KEYS_SET}
                  type="ban"
                  actionCallbackFn={confirmAction}
                  cancelCallbackFn={() => setIsOpenDialog(false)}
                  isOpen={isOpenDialog}
                />
              )}
            </ListActionContainer>
          )}
          {/* Download documents */}
          {documents?.length > 0 && (
            <DownloadSection className={actions?.length ? 'haveActions' : null}>
              {documents &&
                documents?.map((doc, index) => {
                  const { size, unit } = byteConverter(doc.fileSize);
                  const convertedDate = dateConverter(doc.lastModified);
                  return (
                    <DownloadWrapper key={index}>
                      <DownloadIcon>
                        <SvgPDF />
                      </DownloadIcon>
                      <DownloadDetails>
                        <DownloadFileName href={doc.url}>
                          {doc.text}
                          <SvgDownload />
                        </DownloadFileName>
                        <DownloadDescription className="text-white">
                          {doc.type.toUpperCase()} • {`${size}${unit.ShortForm}`} •{' '}
                          {t('request-tracker-last-updated')} {convertedDate?.visualDate}
                        </DownloadDescription>
                      </DownloadDetails>
                    </DownloadWrapper>
                  );
                })}
            </DownloadSection>
          )}
        </TextWrapper>
      </WhatYouNeedToDoCardContent>
    </WhatYouNeedToDoCardWrapper>
  );
};

export default WhatYouNeedToDoCard;
