import { mq } from '_utils/styles';
import styled from 'styled-components';

export const TilesContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${mq('md')} {
    gap: 24px;
  }

  ${mq('lg')} {
    gap: 32px;
  }

  ${mq.lessThan('sm')} {
    grid-template-columns: 1fr;
  }
`;
