import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
  mq,
  svg,
  color,
  heading_m,
  text_m,
  text_xscap,
  text_s,
  heading_l,
  buttonReset,
  heading_s
} from '_utils/styles';
import ContentRichText from '../ContentRichText';
import { FONT_FAMILY_TEXT } from '_containers/Theme/theme';

export const CalloutMessageBoxContainer = styled.div``;

export const CalloutHeader = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${color('all.white')};
  border-radius: 8px 8px 0px 0px;
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

export const CalloutTitleContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.span`
  ${svg(24, 24)}
  width: 24px;
  height: 24px;
  flex: none;
  path {
    fill: ${color('all.white')};
  }
  ${({ theme }) => spacing({ theme, mr: 1 })};

  ${mq('md')} {
    ${svg(40, 40)}
    width: 40px;
    height: 40px;
    ${({ theme }) => spacing({ theme, mr: 2 })};
  }
`;

export const Title = styled.h2`
  ${heading_l};
  margin: 0;
`;

export const CalloutDescription = styled.div`
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0, mt: 3 })};
`;

export const WhatYouNeedToDo = styled.div`
  background-color: ${color('all.deepBlueTint07')};
  color: ${color('all.deepBlue')};
  border-radius: 0px 0px 8px 8px;
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

export const WhatYouNeedToDoTitle = styled.div`
  ${heading_m};
  ${({ theme }) => spacing({ theme, m: 0, mb: 1 })};

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, m: 0, mb: 2 })};
  }
`;

export const WhatYouNeedToDoDescription = styled.div`
  ${text_m};
  margin: 0;
`;

export const RichText = styled(ContentRichText)`
  p {
    ${mq.lessThan('md')} {
      ${({ theme }) => spacing({ theme, my: 1 })};
    }
  }
`;

export const WhatYouNeedToDoActionLink = styled.div`
  ${({ theme }) => spacing({ theme, mt: 1 })};

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mt: 2 })};
  }
`;

export const WhatYouNeedToDoTextButton = styled.button`
  ${buttonReset};
  ${heading_s};
  font-family: ${FONT_FAMILY_TEXT};
  color: ${color('all.greenTint110')};
  display: flex;
  ${({ theme }) => spacing({ theme, m: 0, py: 1 })};
`;

export const TextButtonIconContainer = styled.div`
  width: 20px;
  height: 20px;
  ${({ theme }) => spacing({ theme, ml: 1 })};

  ${mq('md')} {
    width: 24px;
    height: 24px;
  }
`;

export const TextButtonIcon = styled.span`
  ${svg(16, 16)}
  width: 16px;
  height: 16px;
  path {
    fill: ${color('all.greenTint110')};
  }

  ${mq('md')} {
    ${svg(18, 18)}
    width: 18px;
    height: 18px;
  }
`;
