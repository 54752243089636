import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';

import { withEditMode } from '_containers/BaseComponent';

import { Grid, ListingImage } from '_utils/components/Corporate';
import { getTimeEstimate, getCostEstimate } from '_utils/helpers';
import {
  Container,
  CategoryLink,
  CategoryLinkContainer,
  Header,
  Blurb,
  ImageWrap,
  AttributeRow,
  PrimaryCellItem,
  SecondaryCellItem,
  SvgWrapper,
  CTAButton
} from './StyledPageBanner';
import SvgExternal from '_utils/icons/PaymentsDueList/External';
import TagList, { validTag } from '_components/Corporate/TagList';

import { renderDate } from './helpers';
import {
  TimeEstimate,
  LastUpdated,
  RelatesTo,
  CostEstimate,
  InstallationPeriod
} from '_utils/components/Corporate';
import { dateIsDefined } from '_utils/data/';
import { useTranslation } from 'react-i18next';

const getValidTags = (targetItems) => {
  return targetItems?.filter(validTag) || [];
};

export const PageBanner = (props) => {
  const [t] = useTranslation();

  const { sitecoreContext, rendering, editMode } = props;

  const contextFields = sitecoreContext?.route?.fields || {};
  const renderingPageFields = rendering?.fields?.data?.pageFields || {};

  let { pageTitle, pageDescription, listingImage: pageImage } = contextFields || {};

  const primaryCategory = contextFields?.primaryCategory?.fields || null;
  const pageTags = getValidTags(renderingPageFields?.pageTags?.targetItems);
  const dataSourceTags = getValidTags(renderingPageFields?.tags?.targetItems) || [];
  const coreRelations = renderingPageFields?.coreRelations?.targetItems;
  const categoryUrl = renderingPageFields?.category?.targetItem?.categoryPageUrl || null;

  const dataExternaButton = rendering?.fields?.data?.dataSource?.buttonLink?.jss?.value || null;

  const type = renderingPageFields?.__typename?.toLowerCase() || '';

  let timeEstimateValue;
  let costEstimateValue;
  let installationValue;

  try {
    const timeSpecificity = renderingPageFields?.timeSpecificity?.targetItem?.label?.value || '';
    const timeUnits = renderingPageFields?.timeUnits?.targetItem?.label?.value || '';
    const timeEstimate = contextFields?.timeEstimate?.value || '';
    const timeDescription = contextFields?.timeDescription?.value || '';

    timeEstimateValue = getTimeEstimate(timeSpecificity, timeUnits, timeEstimate, timeDescription);
  } catch {
    timeEstimateValue = null;
  }

  try {
    const costSpecificity = renderingPageFields?.costSpecificity?.targetItem?.label?.value || '';
    const costEstimate = contextFields?.costEstimate?.value || '';
    const costDescription = contextFields?.costDescription?.value || '';

    costEstimateValue = getCostEstimate(costSpecificity, costEstimate, costDescription);
  } catch {
    costEstimateValue = null;
  }

  try {
    const installedFrom = renderingPageFields?.installedFrom?.date || '';
    const installedTo = renderingPageFields?.installedTo?.date || '';
    const installedDatesShowYearOnly = renderingPageFields?.installedDatesShowYearOnly?.value || '';

    const showYearOnly = installedDatesShowYearOnly === '1';

    if (installedFrom && dateIsDefined(installedFrom)) {
      installationValue = renderDate(installedFrom, showYearOnly);
    }

    if (
      installedFrom &&
      dateIsDefined(installedFrom) &&
      installedTo &&
      dateIsDefined(installedTo)
    ) {
      installationValue =
        t('page-banner-from-conjunction') +
        ' ' +
        renderDate(installedFrom, showYearOnly) +
        ' ' +
        t('page-banner-to-conjunction') +
        ' ' +
        renderDate(installedTo, showYearOnly);
    }
  } catch {
    installationValue = null;
  }

  let imageType;
  let showImage;
  let systemDateUpdated = renderingPageFields?.systemDateUpdated?.date;

  if (dateIsDefined(renderingPageFields?.lastUpdated?.date)) {
    systemDateUpdated = renderingPageFields?.lastUpdated?.date;
  }

  systemDateUpdated = { value: systemDateUpdated };

  let imageWidth = pageImage?.value?.width;
  let imageHeight = pageImage?.value?.height;

  imageWidth = parseInt(imageWidth, 10) || 0;
  imageHeight = parseInt(imageHeight, 10) || 0;

  let imageAspect: 'portrait' | 'landscape' = imageHeight > imageWidth ? 'portrait' : 'landscape';

  try {
    const showImageParam = Boolean(props?.params?.showImage);

    // if we have an image
    if (typeof pageImage?.value?.src !== 'undefined' && pageImage?.value?.src !== '') {
      showImage = showImageParam;
    }
  } catch {
    showImage = false;
  }

  let tags = [];

  if (dataSourceTags.length) {
    tags = dataSourceTags;
  } else if (pageTags.length) {
    tags = pageTags;
  }

  const components = sitecoreContext?.route?.placeholders['jss-main'];

  let pageIntroComponents = components.filter(
    (component) => component.componentName === 'PageIntro'
  );

  const categoryName = primaryCategory?.displayName || null;

  //check if its space or place
  if (pageIntroComponents.length > 0) {
    pageDescription = null;
  }
  if (!primaryCategory && !pageTitle && !pageDescription) {
    return null;
  }
  // enable publication to see styles applied
  switch (type) {
    case 'publicationpage':
      imageType = 'publication';
      break;
    case 'focuspage':
      imageType = 'focus';
      break;
    default:
      imageType = 'simple';
      break;
  }

  let primaryCellConfig;

  switch (type) {
    // no image for service
    case 'servicepage':
      primaryCellConfig = { col: 12, gutters: ['left', 'right'] };
      break;
    default:
      primaryCellConfig = showImage
        ? { col: { xs: 12, md: 6 }, gutters: ['left', 'right'] }
        : { col: 12, gutters: ['left', 'right'] };
      break;
  }

  const imageCellConfig =
    imageType === 'publication'
      ? { col: { xs: 12, md: 5 }, offset: { md: 1 }, gutters: ['left', 'right'] }
      : { col: { xs: 12, md: 6 }, gutters: ['left', 'right'] };

  return (
    <Container>
      <Grid.Container>
        <Grid.Row alignItems="stretch" config={{ gutters: ['left', 'right'] }}>
          <PrimaryCellItem config={primaryCellConfig} py={8} alignSelf="center">
            {primaryCategory && (
              <CategoryLinkContainer>
                <CategoryLink to={categoryUrl}>
                  <Text field={categoryName} editable={false} />
                </CategoryLink>
              </CategoryLinkContainer>
            )}
            {pageTitle && (
              <Header>
                <Text field={pageTitle} />
              </Header>
            )}
            {pageDescription && (
              <Blurb>
                <Text field={pageDescription} />
              </Blurb>
            )}
            <Attributes
              type={type}
              systemDateUpdated={systemDateUpdated}
              coreRelations={coreRelations}
              timeEstimate={timeEstimateValue}
              costEstimate={costEstimateValue}
              installationPeriod={installationValue}
            />
            <TagList tags={tags} />
            {dataExternaButton && dataExternaButton.url && (
              <CTAButton
                href={dataExternaButton.url}
                aria-label={dataExternaButton.text}
                className="button-primary"
                target="_blank"
              >
                {dataExternaButton.text}
                <SvgWrapper isInLine={false} isSlim={true}>
                  <SvgExternal />
                </SvgWrapper>
              </CTAButton>
            )}
          </PrimaryCellItem>
          {showImage && (
            <SecondaryCellItem config={imageCellConfig}>
              <ImageWrap imageType={imageType} editMode={editMode}>
                <ListingImage image={pageImage} imageType={imageType} imageAspect={imageAspect} />
              </ImageWrap>
            </SecondaryCellItem>
          )}
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
};

export default compose(withSitecoreContext(), withEditMode)(PageBanner);

function Attributes({
  type,
  systemDateUpdated,
  coreRelations,
  timeEstimate,
  costEstimate,
  installationPeriod
}) {
  return (
    <>
      {coreRelations?.length > 0 && (
        <AttributeRow>
          <RelatesTo items={coreRelations} />
        </AttributeRow>
      )}
      {type === 'publicationpage' && systemDateUpdated && (
        <AttributeRow>
          <LastUpdated systemDateUpdated={systemDateUpdated} />
        </AttributeRow>
      )}
      {type === 'servicepage' && timeEstimate && (
        <AttributeRow>
          <TimeEstimate estimate={timeEstimate} />
        </AttributeRow>
      )}
      {type === 'servicepage' && costEstimate && (
        <AttributeRow>
          <CostEstimate estimate={costEstimate} />
        </AttributeRow>
      )}
      {type === 'attractionpage' && installationPeriod && (
        <AttributeRow>
          <InstallationPeriod installationPeriod={installationPeriod} />
        </AttributeRow>
      )}
    </>
  );
}
