export enum LinkTypes {
  External = 'external',
  Internal = 'internal'
}

export enum LinkTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  None = 'none'
}

export enum LinkSize {
  Default = 'default',
  Small = 's'
}

export type LinkProps = {
  editMode: boolean;
  className: string;
  field: {
    value: {
      anchor: string;
      class: string;
      href: string;
      id: string;
      linktype: LinkTypes;
      querystring: string;
      text: string;
      title: string;
      target: string;
    };
  };
  linkTheme: LinkTheme;
  linkSize?: LinkSize;
  tag: string;
  description?: string;
};
