import { color, heading_s, mq, text_s, linkInText } from '_utils/styles';
import styled from 'styled-components';
import { spacing } from '_utils/props';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

export const TipBoxContainer = styled.div`
  border: 1px solid ${color('all.deepBlueTint45')};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${text_s};
  margin: 0;

  ${({ theme }) => spacing({ theme, p: 3 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: 4 })};
  }
  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const TipTitle = styled.div`
  ${heading_s}
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

export const InstructionContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

export const Instruction = styled(RichText)`
  br{
    display: block;
    ${({ theme }) => spacing({ theme, mb: 1 })};
    content: "";
  }
  
  ${linkInText()}
  color: ${color('all.deepBlueTint75')};
`;

export const Example = styled.div`
  ${text_s};
  font-style: italic;
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};
`;

export const ExampleImageContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;
