import {
  button,
  buttonAsLinkReset,
  buttonReset,
  color,
  focusOutline,
  heading_l,
  heading_s,
  heading_xs,
  mq,
  safeFocusRemoval,
  svg,
  text_m,
  text_s
} from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';

export const CardWrapper = styled.div``;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;

  ${mq.between('xs', 'sm')} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${mq.between('md', 'lg')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CardTitle = styled.h1`
  flex-grow: 1;
  ${heading_l};
  ${({ theme }) => spacing({ theme, m: 0 })};
`;

export const EmailSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => spacing({ theme, ml: 2 })};

  ${mq.between('xs', 'sm')} {
    ${({ theme }) => spacing({ theme, ml: 0, mt: 2 })};
  }

  ${mq.between('md', 'lg')} {
    ${({ theme }) => spacing({ theme, ml: 0, mt: 2 })};
  }
`;

export const SwitchLabel = styled.div`
  ${({ theme }) => spacing({ theme, ml: 2 })};
`;

export const DescriptionText = styled.div`
  ${text_s()}
  margin: 0px;
  color: #4e5a5f;
  font-style: italic;
`;
export const RowContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: 5 })};
  }
`;

export const OptionTitle = styled.div`
  ${heading_s};
  color: ${(props) => (props.isGreyOut ? '#959B9E' : '')};
  ${({ theme }) => spacing({ theme, m: 0 })};
`;

export const OptionValueContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

export const OptionValue = styled.div`
  flex-grow: 1;
  ${text_m};
  color: ${(props) => (props.isGreyOut ? '#959B9E' : '')};
  ${({ theme }) => spacing({ theme, m: 0 })};
`;

const ButtonLinkCss = css`
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0 })};
  text-decoration: underline;
  display: flex;
  align-items: center;
  &:hover {
    color: ${color('all.greenTint110')};
    text-decoration: underline;
    path {
      fill: ${color('all.greenTint110')};
    }
  }

  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }
`;

export const EditLink = styled.a`
  ${buttonAsLinkReset}
  ${ButtonLinkCss};
  width: 58px;

  ${mq('md')} {
    line-height: 22px;
  }

  ${mq.lessThan('md')} {
    line-height: 18px;
  }
`;

export const InputContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 2 })};
`;

export const ErrorMessage = styled.div`
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};
  color: ${color('all.pottsPointRedTint110')};
  display: flex;
  align-items: center;
`;

export const ErrorIcon = styled.span`
  ${svg(20, 20)}
  width: 20px;
  flex-shrink: 0;
  ${({ theme }) => spacing({ theme, mr: 1 })};
`;

export const InputActionsContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => spacing({ theme, mt: 2 })};
`;

export const ButtonLink = styled.button`
  ${buttonReset}
  ${ButtonLinkCss};
  width: 58px;
`;

export const ButtonUpdate = styled.button`
  ${button('default', 'primary')}
  ${safeFocusRemoval}
  ${heading_xs}
  ${({ theme }) => spacing({ theme, m: 0, mr: 3 })};
  &:focus {
    background-color: #1B5928;
    border-color: #1B5928;
  }
  display: flex;
  align-items: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;
export const RefreshIcon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  margin-right: 10px;
  ${RotateCss}
  path {
    fill: ${color('all.white')};
  }
`;

export const Disclaimer = styled.div`
  ${text_s}
  ${({ theme }) => spacing({ theme, m: 0 })};
  font-style: italic;
  color: ${color('all.deepBlueTint75')};
  div > p > a:focus {
    outline: 2px solid #4060FF !important;
  }
`;

export const Divider = styled.hr`
  height: 1px;
  border: initial;
  background-color: ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, m: 0, mt: 7, mb: 4 })};

  ${mq('md')} {
    display: none;
  }
`;

export const FallbackMessageContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};
`;

export const SvgWrapper = styled.span`
  display: ${({ isInLine }) => (isInLine ? 'flex' : 'inline')};
  text-align: center;
  align-items: center;
  ${({ theme }) => spacing({ theme, ml: '6px' })};
  ${svg(18, 18)}
  width: 18px;
  height: 18px;
  path {
    fill: ${({ isInLine }) => (isInLine ? '#4E5A5F' : '#FFF')};
  }
  ${mq.lessThan('md')} {
    width: 16px;
    height: 16px;
  }
  svg {
    top: inherit;
    bottom: 0px;
  }
`;
