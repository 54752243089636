import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withEditMode } from '_containers/BaseComponent';
import { BackgroundColors } from '_utils/components/Corporate/Section';
import TwoColumnsFrame from './TwoColumnsFrame';
import { TwoColumnsCurvedBorderSection } from './StyledTwoColumnsCurvedBorder';

type TwoColumnsCurvedBorderProps = {
  rendering?: any;
  params?: any;
  editMode?: boolean;
};

const TwoColumnsCurvedBorder: React.FC<TwoColumnsCurvedBorderProps> = ({
  rendering,
  params,
  editMode
}) => {
  // Default
  let backgroundColor = BackgroundColors.White;
  let isShowCurve = false;

  const primaryChildren = rendering?.placeholders?.['jss-two-column-curved-primary'] || [];

  const secondaryChildren = rendering?.placeholders?.['jss-two-column-curved-secondary'] || [];

  const isEmpty = !editMode && primaryChildren.length === 0 && secondaryChildren.length === 0;

  try {
    backgroundColor = BackgroundColors[params.backgroundColour];
    isShowCurve = params.showCurvedBorder === '1' ? true : false;
  } catch {}

  return (
    <TwoColumnsCurvedBorderSection
      className={isEmpty ? 'is-empty' : ''}
      isShowCurve={isShowCurve}
      sectionTheme={backgroundColor}
    >
      <TwoColumnsFrame
        LeftComponent={
          rendering ? (
            <Placeholder name="jss-two-column-curved-primary" rendering={rendering} />
          ) : null
        }
        RightComponent={
          rendering ? (
            <Placeholder name="jss-two-column-curved-secondary" rendering={rendering} />
          ) : null
        }
        showCurved={isShowCurve}
      />
    </TwoColumnsCurvedBorderSection>
  );
};

export default withEditMode(TwoColumnsCurvedBorder);
