import styled, { css } from 'styled-components';
import {
  Primary as PrimaryContainer,
  Secondary as SecondaryContainer
} from '_components/Corporate/TwoColumn/StyledTwoColumn';
import { Link } from '_utils/components/Corporate';
import { spacing } from '_utils/props';
import {
  button,
  color,
  heading_l,
  heading_m,
  linkStyle,
  listReset,
  mq,
  text_s
} from '_utils/styles';
import { cssInlineSvg } from '_utils/styles/svg';

export const QuickLink = styled.article`
  ${({ theme }) => spacing({ theme, p: 3 })};
  color: ${({ theme }) => theme.callToAction.color};
  border-radius: ${({ theme }) => theme.callToAction.borderRadius}px;
  background-color: ${({ theme }) => theme.callToAction.backgroundColor};

  ${SecondaryContainer} & {
    display: block;
  }

  .multiLink {
    display: flex;
  }

  .bottomLink {
    a {
      text-decoration: none !important;
      border-bottom: 2px solid #fff;
    }

    a:hover,
    a:focus {
      border-bottom: none;
    }
  }
`;

export const Heading = styled.h2`
  ${heading_m};
  margin: 0;

  ${PrimaryContainer} & {
    ${heading_l}
  }
`;

export const Description = styled.p`
  ${text_s};
  margin: 0;
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

export const TextWrapper = styled.div``;

export const LinkItem = styled(Link)`
  ${({ theme }) => spacing({ theme, my: 2 })};

  a {
    ${({ theme }) => linkStyle(theme.colors.link.white)};

    &:not(.button-primary):not(.button-outline) {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    path {
      fill: ${color('all.white')};
    }
  }

  ${mq('sm')} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const LinkButton = styled(Link)`
  a {
    ${button('default', 'white')};
    transition: 200ms;
    min-width: 140px;

    span {
      top: 1px;
    }

    path {
      fill: ${color('all.greenTint110')};
    }

    &:focus {
      outline: none;
    }
  }
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};
  color: ${color('all.white')};
  ${LinkItem}:first-child {
    margin-bottom: 0;
  }
  ${LinkItem}:last-child {
    margin-bottom: 0;
  }

  ${PrimaryContainer} & {
    ${({ theme }) => spacing({ theme, mt: 1 })};

    ${({ multiLinks }) => {
      if (multiLinks === false) {
        return css`
          ${mq('sm')} {
            ${({ theme }) => spacing({ theme, my: 3, ml: 3 })};
          }
        `;
      }
    }}
  }
`;

export const LinkList = styled.ul`
  ${listReset}

  ${mq('sm')} {
    display: flex;
    flex-wrap: wrap;

    ${SecondaryContainer} & {
      display: block;
    }
  }

  li {
    position: relative;
    padding-left: 24px;

    & + & {
      ${({ theme }) => spacing({ theme, mt: 2 })}
    }

    ${mq('sm')} {
      flex: 0 0 50%;
      max-width: 50%;
      ${({ theme }) => spacing({ theme, mt: 2 })};

      &:nth-child(2n + 1) {
        ${({ theme }) => spacing({ theme, pr: 3 })};
      }

      &:first-child,
      &:first-child + li {
        margin-top: 0;
      }

      ${SecondaryContainer} & {
        max-width: 100%;
        padding-right: 0;
      }
    }

    ${mq('md')} {
      flex: 0 1 100%;
      max-width: 100%;

      &:first-child + li {
        ${({ theme }) => spacing({ theme, mt: 2 })};
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      background-image: ${cssInlineSvg(
        `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7667 5.68333C11.727 5.58104 11.6675 5.48759 11.5917 5.40833L6.59166 0.408334C6.51396 0.330635 6.42172 0.269001 6.3202 0.226951C6.21868 0.184901 6.10987 0.163258 5.99999 0.163258C5.77807 0.163258 5.56524 0.251414 5.40832 0.408334C5.33062 0.486033 5.26899 0.578275 5.22694 0.679793C5.18489 0.781311 5.16325 0.890118 5.16325 1C5.16325 1.22192 5.2514 1.43475 5.40832 1.59167L8.99166 5.16667H0.99999C0.778976 5.16667 0.567015 5.25447 0.410734 5.41075C0.254454 5.56703 0.166656 5.77899 0.166656 6C0.166656 6.22101 0.254454 6.43298 0.410734 6.58926C0.567015 6.74554 0.778976 6.83333 0.99999 6.83333H8.99166L5.40832 10.4083C5.33022 10.4858 5.26822 10.578 5.22591 10.6795C5.18361 10.7811 5.16182 10.89 5.16182 11C5.16182 11.11 5.18361 11.2189 5.22591 11.3205C5.26822 11.422 5.33022 11.5142 5.40832 11.5917C5.48579 11.6698 5.57796 11.7318 5.67951 11.7741C5.78106 11.8164 5.88998 11.8382 5.99999 11.8382C6.11 11.8382 6.21892 11.8164 6.32047 11.7741C6.42202 11.7318 6.51419 11.6698 6.59166 11.5917L11.5917 6.59167C11.6675 6.51241 11.727 6.41896 11.7667 6.31667C11.85 6.11378 11.85 5.88622 11.7667 5.68333Z" fill="#fff"/></svg>`
      )};
      @media print {
        background-image: ${cssInlineSvg(
          `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7667 5.68333C11.727 5.58104 11.6675 5.48759 11.5917 5.40833L6.59166 0.408334C6.51396 0.330635 6.42172 0.269001 6.3202 0.226951C6.21868 0.184901 6.10987 0.163258 5.99999 0.163258C5.77807 0.163258 5.56524 0.251414 5.40832 0.408334C5.33062 0.486033 5.26899 0.578275 5.22694 0.679793C5.18489 0.781311 5.16325 0.890118 5.16325 1C5.16325 1.22192 5.2514 1.43475 5.40832 1.59167L8.99166 5.16667H0.99999C0.778976 5.16667 0.567015 5.25447 0.410734 5.41075C0.254454 5.56703 0.166656 5.77899 0.166656 6C0.166656 6.22101 0.254454 6.43298 0.410734 6.58926C0.567015 6.74554 0.778976 6.83333 0.99999 6.83333H8.99166L5.40832 10.4083C5.33022 10.4858 5.26822 10.578 5.22591 10.6795C5.18361 10.7811 5.16182 10.89 5.16182 11C5.16182 11.11 5.18361 11.2189 5.22591 11.3205C5.26822 11.422 5.33022 11.5142 5.40832 11.5917C5.48579 11.6698 5.57796 11.7318 5.67951 11.7741C5.78106 11.8164 5.88998 11.8382 5.99999 11.8382C6.11 11.8382 6.21892 11.8164 6.32047 11.7741C6.42202 11.7318 6.51419 11.6698 6.59166 11.5917L11.5917 6.59167C11.6675 6.51241 11.727 6.41896 11.7667 6.31667C11.85 6.11378 11.85 5.88622 11.7667 5.68333Z" fill="#000"/></svg>`
        )};
      }
    }
  }
`;

export const EditorDiv = styled.div``;
