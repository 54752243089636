import React from 'react';
import {withSvg} from "../Svg";
import PropTypes from 'prop-types'

const SvgGlobe = (props) => (
        <path d="M17.8417 7.20043C17.8417 7.20043 17.8417 7.20043 17.8417 7.15876C17.2546 5.55562 16.1892 4.1715 14.7897 3.19365C13.3901 2.2158 11.724 1.69141 10.0167 1.69141C8.30946 1.69141 6.64336 2.2158 5.24384 3.19365C3.84432 4.1715 2.77892 5.55562 2.19175 7.15876C2.19175 7.15876 2.19175 7.15876 2.19175 7.20043C1.53602 9.00951 1.53602 10.9913 2.19175 12.8004C2.19175 12.8004 2.19175 12.8004 2.19175 12.8421C2.77892 14.4452 3.84432 15.8294 5.24384 16.8072C6.64336 17.7851 8.30946 18.3095 10.0167 18.3095C11.724 18.3095 13.3901 17.7851 14.7897 16.8072C16.1892 15.8294 17.2546 14.4452 17.8417 12.8421C17.8417 12.8421 17.8417 12.8421 17.8417 12.8004C18.4975 10.9913 18.4975 9.00951 17.8417 7.20043ZM3.55008 11.6671C3.2611 10.5748 3.2611 9.42608 3.55008 8.33376H5.10008C4.96677 9.44088 4.96677 10.56 5.10008 11.6671H3.55008ZM4.23341 13.3338H5.40008C5.59568 14.077 5.87526 14.7955 6.23341 15.4754C5.41616 14.9187 4.733 14.1871 4.23341 13.3338ZM5.40008 6.6671H4.23341C4.72581 5.81633 5.40024 5.08498 6.20841 4.52543C5.85888 5.20647 5.5877 5.92495 5.40008 6.6671ZM9.16675 16.4171C8.14322 15.666 7.42437 14.5715 7.14175 13.3338H9.16675V16.4171ZM9.16675 11.6671H6.78341C6.62791 10.5614 6.62791 9.43943 6.78341 8.33376H9.16675V11.6671ZM9.16675 6.6671H7.14175C7.42437 5.4294 8.14322 4.33486 9.16675 3.58376V6.6671ZM15.7667 6.6671H14.6001C14.4045 5.9239 14.1249 5.20538 13.7667 4.52543C14.584 5.08215 15.2672 5.81371 15.7667 6.6671ZM10.8334 3.58376C11.8569 4.33486 12.5758 5.4294 12.8584 6.6671H10.8334V3.58376ZM10.8334 16.4171V13.3338H12.8584C12.5758 14.5715 11.8569 15.666 10.8334 16.4171ZM13.2167 11.6671H10.8334V8.33376H13.2167C13.3723 9.43943 13.3723 10.5614 13.2167 11.6671ZM13.7917 15.4754C14.1499 14.7955 14.4295 14.077 14.6251 13.3338H15.7917C15.2922 14.1871 14.609 14.9187 13.7917 15.4754ZM16.4501 11.6671H14.9001C14.9679 11.1141 15.0013 10.5575 15.0001 10.0004C15.001 9.44335 14.9676 8.88674 14.9001 8.33376H16.4501C16.7391 9.42608 16.7391 10.5748 16.4501 11.6671Z"
              fill={props.color || "#4E5A5F"}/>
);

SvgGlobe.prototype = {
    color: PropTypes.string
}

export default withSvg(20, 20)(SvgGlobe);
