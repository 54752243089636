import styled from 'styled-components';
import { buttonReset, mq, safeFocusRemoval, depth, hideOnPrint } from '_utils/styles';

export const Button = styled.button`
  ${buttonReset};
  ${safeFocusRemoval}
  display: flex;
  align-items: center;
  z-index: ${depth.MENU_BUTTON};

  &:focus {
    ${safeFocusRemoval}
    position: relative;
    outline-color: ${({ theme }) => theme.colors.element.focused};
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
  }
  ${hideOnPrint}
`;

export const Icon = styled.span`
  margin: 10px;
  width: 20px;
  height: 11px;
  position: relative;
  display: block;
`;

export const Bar = styled.span`
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0px;
  top: 0px;
  background-color: ${({ theme }) => theme.colors.element.primary};
  opacity: 1;
  transition: all 0.2s linear;
  border-radius: 1px;

  &:nth-child(1) {
    [aria-expanded='true'] & {
      transform: translate3d(0, 5px, 0) rotate(45deg);
    }
  }
  &:nth-child(2) {
    transform: translate(0, 5px);
    [aria-expanded='true'] & {
      opacity: 0;
    }
  }
  &:nth-child(3) {
    transform: translate3d(0, 10px, 0);

    [aria-expanded='true'] & {
      transform: translate3d(0, 5px, 0) rotate(-45deg);
    }
  }
`;

export const Label = styled.span`
  display: none;
  padding-right: 4px;

  ${mq('lg')} {
    display: block;
  }
`;
