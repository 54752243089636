import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { linkStyle } from '_utils/styles/'; // these import fine
import svg from '_utils/styles/svg'; // but svg isn't ...
import { text_s } from '_utils/styles/text'; // but text_s isn't ...
import color from '_utils/styles/color'; // but color isn't ...

import { LinkTheme, LinkSize } from './definitions';

const handleLinkTheme = ({ linkTheme, theme }) => {
  if (linkTheme === LinkTheme.None) {
    return null;
  }

  return css`
    ${linkStyle(theme.colors.link[linkTheme])}
  `;
};

export const LinkIconWrapper = styled.span`
  white-space: nowrap;
  line-height: 0;
`;

export const SvgWrapper = styled.span`
  ${svg(13, 13)}
  width: 13px;
  margin-left: 8px;
  margin-right: 2px;
  position: relative;

  path {
    fill: ${color('all.deepBlueTint75')};
  }
`;

// Need to remove extra props getting sent to the dom
// https://github.com/styled-components/styled-components/issues/135
export const StyledLink = styled(({ linkTheme, linkSize, ...rest }) => <NavLink {...rest} />)`
  ${handleLinkTheme}
  ${({ linkSize }) => (linkSize === LinkSize.Small ? text_s() : null)};
  display: inline;
  margin: 0;
`;

export const EditorDiv = styled.div``;
