export const getRandomId = () =>
  Math.random()
    .toString()
    .substr(2);

export const normalizeId = (id: string | string[]) => {
  if (!id) {
    return id;
  }
  if (Array.isArray(id)) {
    return id.map(normalizeId);
  }
  if (typeof id !== 'string') {
    return id;
  }
  return id.replace(/-/g, '').toLowerCase();
};

export const equalIds = (id1: string, id2: string): boolean => {
  let s1 = normalizeId(id1);
  let s2 = normalizeId(id2);
  if (s1 === '' && s2 === '') {
    return true;
  }
  if (!s1 || !s2) {
    return false;
  }
  return s1 === s2;
};

export const includesId = (array, id: string): boolean => {
  if (!id || !Array.isArray(array)) {
    return false;
  }
  return array.filter((value) => equalIds(value, id)).length > 0;
};
