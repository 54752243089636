import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonCancel,
  ButtonUpdate,
  DetailItem,
  Label,
  RefreshIcon,
  RowContainer,
  SearchAddressContainer,
  SearchItem,
  SearchItemContainer,
  SearchPanel,
  Title
} from './StyledAutoCompleteAddress';
import { PersonalInfoContext } from '..';
import { updatePostalAddress, updateResidentialAddress } from '_services/personalInfoService';
import { getAddressDetails, getListAddressByKeyword } from '_services/personalInfoService';
import ClickAwayListener from '_utils/components/CityConnect/ClickAwayListener';
import TextareaAutoResize from '_utils/components/CityConnect/TextareaAutoResize';
import Checkbox from '_utils/components/Corporate/Checkbox';
import SelectCountry from './SelectCountry';
import { ICountry } from './SelectCountry';
import SvgExclamationTriangle from '_utils/icons/announcement/ExclamationTriangle';
import SvgRefresh from '_utils/icons/common/Refresh';
import {
  AutoCompleteAddressProps,
  DEFAULT_COUNTRY,
  IAddressData,
  IAddressDetails,
  SearchResultItem
} from './definitions';
import { handleCheckInput, transformAddress } from '_utils/helpers/personalInfohelper';
import { getRefreshToken } from '_services/genericService';

const AutoCompleteAddress: React.FC<AutoCompleteAddressProps> = ({
  inputLabelDictionary,
  inputName,
  inputValue
}) => {
  const [t] = useTranslation();
  const [formValue, setFormValue] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSearchAble, setIsSearchAble] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<string>('');
  const [formError, setFormError] = useState<string>('');
  const [isShowSearchResult, setIsShowSearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResultItem[]>([]);
  const personalInfo = useContext(PersonalInfoContext)?.personalInfo;
  const setPersonalInfo = useContext(PersonalInfoContext)?.setPersonalInfo;
  const listCountry = useContext(PersonalInfoContext)?.listCountry;
  const sitecoreContext = useContext(PersonalInfoContext)?.sitecoreContext;
  const history = useContext(PersonalInfoContext)?.history;
  const currentPathname = useContext(PersonalInfoContext)?.currentPathname;
  const sameAsResidentialAddress = personalInfo?.sameAsResidentialAddress;
  const [isSameAsResidentialAddress, setIsSameAsResidentialAddress] = useState<boolean>(false);
  const [isOutsideAddress, setIsOutsideAddress] = useState<boolean>(false);
  const [country, setCountry] = useState<ICountry>();
  const [error, setError] = useState();
  const [isOpenManualAddress, setIsOpenManualAddress] = useState<boolean>(false);
  const [manualAddress, setManualAddress] = useState<string>('');
  const [manualSuburbTownCity, setManualSuburbTownCity] = useState<string>('');
  const [manualStateProvinceRegion, setManualStateProvinceRegion] = useState<string>('');
  const [manualZipPostalCode, setManualZipPostalCode] = useState<string>('');
  const [addressDetails, setAddressDetails] = useState<IAddressDetails>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [sessionApi, setSessionApi] = useState<string>('');
  const [isRefreshSessionApi, setIsRefreshSessionApi] = useState<boolean>(false);
  const isPostalAddress = inputName === 'postalAddress';
  const [key, setKey] = useState<string>('');
  const [isDisableUpdate, setIsDisableUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // clear input and make it not editable
  useEffect(() => {
    if (isOutsideAddress) {
      setFormValue('');
    } else {
      // setFormValue(inputValue);addressError
      setCountry(null);
    }
  }, [isOutsideAddress]);

  // trigger search and remove trigger
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSearchAble) {
        calculateSearchAddress(formValue);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [formValue]);

  // reset form manual address
  useEffect(() => {
    setManualAddress('');
    setManualStateProvinceRegion('');
    setManualSuburbTownCity('');
    setManualZipPostalCode('');
    setAddressError('');
    setFormError('');
  }, [isOpenManualAddress]);

  useEffect(() => {
    setIsOpenManualAddress(false);
    setAddressError('');
    setFormError('');
  }, [isSameAsResidentialAddress]);

  // disable outside address if check sameAsResidentialAddress
  const handleToggleSameAsResidentialAddress = () => {
    if (isSameAsResidentialAddress) {
      setIsSameAsResidentialAddress(false);
    } else {
      setIsSameAsResidentialAddress(true);
      setIsOutsideAddress(false);
    }
  };

  //hide error input address input when handle change is outside Australia
  useEffect(() => {
    setAddressError('');
    setFormValue('');
    setFormError('');
    if (isOutsideAddress) {
      setIsOpenManualAddress(true);
    } else {
      setIsOpenManualAddress(false);
    }
  }, [isOutsideAddress]);

  useEffect(() => {
    setSearchResult([]);
    setIsShowSearchResult(false);
  }, [country]);

  // INFO: Handle state change of input
  const handleChange = (e) => {
    const value = e.target.value;
    setFormValue(value);
  };

  // handle focus on input
  const handleFocus = () => {
    setIsSearchAble(true);
    setIsShowSearchResult(true);
  };

  // hide search result panel after focus out
  const handleBlur = () => {
    setIsSearchAble(false);
    if (isShowSearchResult) {
      setTimeout(() => setIsShowSearchResult(false), 200);
    }
  };

  // calculate search or do not search
  const calculateSearchAddress = (keyword: string) => {
    if (keyword.trim().length >= 4) {
      setIsShowSearchResult(true);
      getListAddressByKeyword(
        isOutsideAddress ? country.iso3 : DEFAULT_COUNTRY.iso3,
        keyword,
        isRefreshSessionApi ? sessionApi : '',
        searchAddressSuccessfully,
        (res) => searchAddressError(res)
      );
    } else {
      setIsShowSearchResult(false);
      setSearchResult([]);
    }
  };

  const searchAddressError = (res: any) => {
    setAddressError('personal-info-address-search-is-unavailable');
  };

  // search address successfully
  const searchAddressSuccessfully = (res: any) => {
    const count = res?.data?.count;
    const session = res?.data?.session;
    setIsRefreshSessionApi(true);
    setSearchResult(res?.data?.suggestions);
    if (count === 0 || count === '0') {
      setAddressError('personal-info-please-enter-a-valid-address');
    } else {
      setAddressError('');
    }
    if (session !== '') {
      setSessionApi(session);
    } else {
      setSessionApi('');
    }
  };

  const getAddressData = () => {
    // update by select address
    if (!isOpenManualAddress) {
      return {
        line1: addressDetails?.address_line_1 || '',
        line2: addressDetails?.address_line_2 || '',
        line3: addressDetails?.address_line_3 || '',
        city: addressDetails?.locality || '',
        postcode: addressDetails?.postal_code || '',
        state: addressDetails?.region || '',
        country: addressDetails?.country || ''
      };
    } else {
      // update address manual
      return {
        line1: manualAddress.trim(),
        city: manualSuburbTownCity.trim(),
        postcode: manualZipPostalCode.trim(),
        state: manualStateProvinceRegion.trim(),
        country: isOutsideAddress ? country.name : DEFAULT_COUNTRY.name
      };
    }
  };

  const checkInputsError = () => {
    if (isOutsideAddress && !country) {
      return t('personal-info-please-fill-in-required-fields');
    }
    if (isOpenManualAddress) {
      const checkValidate = validateForm();
      if (checkValidate) {
        return '';
      }
      return t('personal-info-please-fill-in-required-fields');
    } else {
      if (!isInvalidAddress()) {
        return '';
      }
      return t('personal-info-please-enter-a-valid-address');
    }
  };

  const handleEditKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsEdit(true);
    }
  };

  const handleCancelKeyPress = (e) => {
    if (e.key === 'Enter') {
      cancelEdit();
    }
  };

  const validateForm = () => {
    const checkManualAddress = handleCheckInput(manualAddress, 'address');
    const checkManualSuburb = isOutsideAddress
      ? handleCheckInput(manualSuburbTownCity, 'optional')
      : handleCheckInput(manualSuburbTownCity, 'city');
    const checkManualState = isOutsideAddress
      ? handleCheckInput(manualStateProvinceRegion, 'optional')
      : handleCheckInput(manualStateProvinceRegion, 'state');
    const checkManualZip = isOutsideAddress
      ? handleCheckInput(manualZipPostalCode, 'optional')
      : handleCheckInput(manualZipPostalCode, 'zip/postal');
    return checkManualAddress && checkManualSuburb && checkManualState && checkManualZip;
  };

  // check input value equal option of searchResult
  const isInvalidAddress = () => {
    if (isPostalAddress && isSameAsResidentialAddress && personalInfo.residentialAddress) {
      return false;
    } else {
      if (key) {
        return !searchResult.some((item) => item.text === formValue);
      }
      return true;
    }
  };

  // update address
  const updateAddress = async () => {
    if (checkInputsError() === '') {
      setIsDisableUpdate(true);
      const refreshToken = await getRefreshToken(
        currentPathname,
        sitecoreContext.language,
        history,
        currentPathname
      );
      const headers = {
        headers: {
          requestVerificationToken: refreshToken
        }
      };
      setAddressError('');
      setFormError('');
      setIsUpdating(true);
      // update postal address
      if (isPostalAddress) {
        const addressObj: IAddressData = {
          personalInfo: {
            sameAsResidentialAddress: isSameAsResidentialAddress,
            postalAddress: !isSameAsResidentialAddress ? getAddressData() : ''
          }
        };
        updatePostalAddress(
          addressObj,
          updateAddressSuccessfully,
          updateAddressUnsuccessfully,
          headers
        );
      } else {
        // update residential address
        const addressObj: IAddressData = {
          personalInfo: {
            residentialAddress: getAddressData()
          }
        };
        updateResidentialAddress(
          addressObj,
          updateAddressSuccessfully,
          updateAddressUnsuccessfully,
          headers
        );
      }
    } else {
      if (isOpenManualAddress) {
        setFormError(checkInputsError());
      } else {
        setAddressError(checkInputsError());
      }
    }
  };

  const updateAddressSuccessfully = (res) => {
    setIsDisableUpdate(false);
    setSearchResult([]);
    setIsEdit(false);
    setIsSameAsResidentialAddress(false);
    setIsOpenManualAddress(false);
    setAddressDetails(null);
    setAddressError('');
    setFormError('');
    setIsUpdating(false);
    setIsOutsideAddress(false);
    setFormValue('');
    setKey('');
    setCountry(null);
    setPersonalInfo(res.data.personalInfo);
  };

  const updateAddressUnsuccessfully = (res) => {
    setIsDisableUpdate(false);
    setAddressError('personal-info-update-failure-description');
    setIsUpdating(false);
    setError(res);
  };

  // select a address item
  const selectAddressItem = (address: SearchResultItem) => {
    setIsDisableUpdate(true);
    setFormValue(address.text);
    setKey(address.key);
    setIsShowSearchResult(false);
    getAddressDetails(
      address.key,
      isOutsideAddress ? country.iso3 : DEFAULT_COUNTRY.iso3,
      sessionApi,
      getAddressDetailsSuccess,
      getAddressDetailsFailure
    );
    setIsRefreshSessionApi(false);
  };

  const getAddressDetailsSuccess = (res) => {
    setAddressDetails(res.data);
    setAddressError('');
    setIsDisableUpdate(false);
  };

  const getAddressDetailsFailure = (res) => {
    setAddressError('personal-info-please-enter-a-valid-address');
    setIsDisableUpdate(false);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setIsOpenManualAddress(false);
    setIsSameAsResidentialAddress(false);
    setAddressError('');
    setFormError('');
    setIsOutsideAddress(false);
    setCountry(null);
    setKey('');
    setFormValue('');
  };

  return (
    <DetailItem.Wrapper>
      <DetailItem.Text>
        <span>{t(inputLabelDictionary)}</span>
      </DetailItem.Text>
      {/* INFO: editing mode */}
      {isEdit ? (
        <div className="editable-block">
          {isPostalAddress && (
            <RowContainer>
              <Checkbox
                id={`id_sameAsResidentialAddress`}
                name="sameAsResidentialAddress"
                value={isSameAsResidentialAddress}
                onChange={handleToggleSameAsResidentialAddress}
                checked={isSameAsResidentialAddress}
              >
                <Label>
                  <Title className={isSameAsResidentialAddress ? 'selected' : ''}>
                    {t('personal-info-same-as-residential-address')}
                  </Title>
                </Label>
              </Checkbox>
            </RowContainer>
          )}
          {isOpenManualAddress && !isOutsideAddress ? (
            <></>
          ) : (
            !(isSameAsResidentialAddress && isPostalAddress) && (
              <RowContainer>
                <Checkbox
                  id={'id_isOutsideAddress' + inputName}
                  name={'isOutsideAddress' + inputName}
                  value={isOutsideAddress}
                  onChange={() => setIsOutsideAddress(!isOutsideAddress)}
                  checked={isOutsideAddress}
                >
                  <Label>
                    <Title className={isOutsideAddress ? 'selected' : ''}>
                      {t('personal-info-the-address-is-outside-of-australia')}
                    </Title>
                  </Label>
                </Checkbox>
              </RowContainer>
            )
          )}
          {/* INFO: select country */}
          {isOutsideAddress && (
            <RowContainer>
              <SelectCountry
                visibility={isOutsideAddress}
                listCountry={listCountry}
                onChangeCountry={(selectedCountry) => setCountry(selectedCountry)}
              />
            </RowContainer>
          )}
          {/* INFO: search address */}
          {isOpenManualAddress || isOutsideAddress ? (
            <></>
          ) : (
            <RowContainer>
              <DetailItem.TextFieldLabel>
                {t('personal-info-address-finder')}
              </DetailItem.TextFieldLabel>
              <ClickAwayListener onClickAway={() => setIsShowSearchResult(false)}>
                <SearchAddressContainer>
                  <TextareaAutoResize
                    id={inputName}
                    value={
                      isPostalAddress && isSameAsResidentialAddress
                        ? transformAddress(personalInfo.residentialAddress)
                        : formValue
                    }
                    disabled={
                      (isPostalAddress && isSameAsResidentialAddress) ||
                      (isOutsideAddress && !country) ||
                      isOpenManualAddress
                    }
                    onChange={(e) => handleChange(e)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={addressError && !isOpenManualAddress ? 'error' : ''}
                    rows={1}
                  />
                  {searchResult && searchResult.length > 0 && (
                    <SearchPanel showPanel={isShowSearchResult}>
                      <SearchItemContainer>
                        {searchResult.map((address, index) => (
                          <SearchItem
                            key={index}
                            onClick={() => selectAddressItem(address)}
                            title={address.text}
                          >
                            <div>{address.text}</div>
                          </SearchItem>
                        ))}
                      </SearchItemContainer>
                    </SearchPanel>
                  )}
                </SearchAddressContainer>
              </ClickAwayListener>
            </RowContainer>
          )}
          {addressError && !isOpenManualAddress && (
            <RowContainer>
              <DetailItem.ErrorMessage role="alert">
                <DetailItem.Icon>
                  <SvgExclamationTriangle />
                </DetailItem.Icon>
                {t(addressError)}
              </DetailItem.ErrorMessage>
            </RowContainer>
          )}
          {/* INFO: can't find my address */}
          {((!isPostalAddress && !isOutsideAddress) ||
            (isPostalAddress && !isSameAsResidentialAddress && !isOutsideAddress)) && (
            <RowContainer>
              <DetailItem.LinkButton
                name={
                  isOpenManualAddress
                    ? t('personal-info-switch-back-to-address-finder')
                    : t('personal-info-i-cant-find-my-address')
                }
                onClick={() => setIsOpenManualAddress(!isOpenManualAddress)}
              >
                {isOpenManualAddress
                  ? t('personal-info-switch-back-to-address-finder')
                  : t('personal-info-i-cant-find-my-address')}
              </DetailItem.LinkButton>
            </RowContainer>
          )}
          {(!isPostalAddress || (isPostalAddress && !isSameAsResidentialAddress)) &&
            isOpenManualAddress && (
              <>
                {/* INFO: address */}
                <RowContainer>
                  <DetailItem.TextFieldLabel>
                    {t('personal-info-address')}
                  </DetailItem.TextFieldLabel>
                  <TextareaAutoResize
                    id="manualAddress"
                    value={manualAddress}
                    isPersonalInfo={true}
                    onChange={(e) => setManualAddress(e.target.value)}
                    rows={1}
                    maxLength={250}
                  />
                </RowContainer>
                {/* INFO: Suburd or Town or City */}
                <RowContainer>
                  <DetailItem.TextFieldLabel>
                    {t('personal-info-suburb-town-city')}
                    {isOutsideAddress && (
                      <DetailItem.TextFieldSubLabel>
                        {t('personal-info-if-applicable')}
                      </DetailItem.TextFieldSubLabel>
                    )}
                  </DetailItem.TextFieldLabel>
                  <TextareaAutoResize
                    id="manualSuburbTownCity"
                    value={manualSuburbTownCity}
                    isPersonalInfo={true}
                    onChange={(e) => setManualSuburbTownCity(e.target.value)}
                    rows={1}
                    maxLength={80}
                  />
                </RowContainer>
                {/* INFO: State / Province / Region */}
                <RowContainer>
                  <DetailItem.TextFieldLabel>
                    {t('personal-info-state-province-region')}
                    {isOutsideAddress && (
                      <DetailItem.TextFieldSubLabel>
                        {t('personal-info-if-applicable')}
                      </DetailItem.TextFieldSubLabel>
                    )}
                  </DetailItem.TextFieldLabel>
                  <TextareaAutoResize
                    id="manualStateProvinceRegion"
                    value={manualStateProvinceRegion}
                    isPersonalInfo={true}
                    onChange={(e) => setManualStateProvinceRegion(e.target.value)}
                    rows={1}
                    maxLength={50}
                  />
                </RowContainer>
                {/* INFO: Zip or Postsal code */}
                <RowContainer>
                  <DetailItem.TextFieldLabel>
                    {t('personal-info-zip-or-postal-code')}
                    {isOutsideAddress && (
                      <DetailItem.TextFieldSubLabel>
                        {t('personal-info-if-applicable')}
                      </DetailItem.TextFieldSubLabel>
                    )}
                  </DetailItem.TextFieldLabel>
                  <TextareaAutoResize
                    id="manualZipPostalCode"
                    value={manualZipPostalCode}
                    isPersonalInfo={true}
                    onChange={(e) => setManualZipPostalCode(e.target.value)}
                    rows={1}
                    maxLength={20}
                  />
                </RowContainer>
              </>
            )}
          {/* INFO: update or cancel */}
          <RowContainer>
            {formError && (
              <DetailItem.ErrorMessage>
                <DetailItem.Icon>
                  <SvgExclamationTriangle />
                </DetailItem.Icon>
                {t(formError)}
              </DetailItem.ErrorMessage>
            )}
          </RowContainer>
          <DetailItem.ButtonGroups>
            <ButtonUpdate
              onClick={updateAddress}
              disabled={isDisableUpdate}
              type="submit"
              name="update-button"
              role="button"
              value="update"
              aria-label="Update"
            >
              {isUpdating && (
                <RefreshIcon>
                  <SvgRefresh />
                </RefreshIcon>
              )}
              {t('personal-info-update')}
            </ButtonUpdate>
            <ButtonCancel
              tabIndex={0}
              onClick={cancelEdit}
              onKeyPress={(e) => handleCancelKeyPress(e)}
              name="cancel-link"
              role="button"
              aria-label="Cancel"
            >
              {t('personal-info-cancel')}
            </ButtonCancel>
          </DetailItem.ButtonGroups>
        </div>
      ) : (
        <div className="description-block">
          {/* INFO: display mode */}
          <DetailItem.Description
            className={
              !(isPostalAddress && sameAsResidentialAddress) && !inputValue ? 'italic' : ''
            }
          >
            {isPostalAddress && sameAsResidentialAddress
              ? t('personal-info-same-as-residential-address')
              : inputValue || 'Not supplied'}
          </DetailItem.Description>
          <DetailItem.EditButton
            tabIndex={0}
            onClick={() => setIsEdit(true)}
            onKeyPress={(e) => handleEditKeyPress(e)}
          >
            {t('personal-info-edit')}
          </DetailItem.EditButton>
        </div>
      )}
    </DetailItem.Wrapper>
  );
};

export default React.memo(AutoCompleteAddress);
