import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PermitWrapper,
  PermitHeader,
  PermitTitle,
  PermitContent,
  PermitDescription,
  PermitHeaderLeft,
  PermitHeaderRight,
  ActivityDescription,
  WhatYouNeedToDoDescription,
  WhatYouNeedToDoTitle,
  WhatYouNeedToDo,
  ListActionContainer,
  ActionContainer,
  CallToAction
} from './StyledPermitStatusCard';
import Metadata, { MetadataThemeConfig } from '_utils/components/CityConnect/Metadata';
import StatusTag, { STATUS_CONFIGS } from '_utils/components/CityConnect/StatusTag';
import {
  DownloadSection,
  DownloadWrapper,
  DownloadIcon,
  DownloadFileName,
  DownloadDescription
} from '_components/CityConnect/RequestTracker/WhatYouNeedToDoCard/StyledWhatYouNeedToDoCard';
import SvgDownload from '_utils/icons/Download';
import SvgPDF from '_utils/icons/common/PDF';
import SvgBan from '_utils/icons/common/Ban';
import { byteConverter, dateConverter } from '_utils/helpers';
import { TAction, ACTION_LIST, TRANSLATION_KEYS_SET } from '..';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import { SvgWrapper } from '_components/CityConnect/RequestTracker/RequestTrackerCard/StyledRequestTrackerCard';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

type PermitStatusCardProps = {
  data: any;
};

const PermitStatusCard: React.FC<PermitStatusCardProps> = ({ data }) => {
  const [t] = useTranslation();
  const {
    title,
    metadata,
    details,
    description,
    status,
    displayStatus,
    documents,
    actions,
    subtitle
  } = data;
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dialogCallbackUrl, setDialogCallbackUrl] = useState<string>();

  const renderMetadata = () => {
    return (
      metadata &&
      metadata.map((item, index) => (
        <Metadata
          key={index}
          metadataType={item.type}
          text={item.value}
          metadataTheme={MetadataThemeConfig.Default}
          className="permit-detail-metadata"
        />
      ))
    );
  };

  const handleClickAction = (action: TAction) => {
    if (action.type === ACTION_LIST.cancelApplication) {
      setIsOpenDialog(true);
      setDialogCallbackUrl(action.link);
    } else if(action.type === ACTION_LIST.redirectExternalLink) {
      window.open(action.link, '_blank');
    } else {
      window.open(action.link, '_self');
    }
  };

  const confirmAction = () => {
    setIsOpenDialog(false);
    window.open(dialogCallbackUrl, '_self');
  };

  return (
    <PermitWrapper>
      {/* Permit Detail Card Header  */}
      <PermitHeader>
        <PermitHeaderLeft>
          <PermitTitle>{title}</PermitTitle>
          <PermitDescription>{renderMetadata()}</PermitDescription>
        </PermitHeaderLeft>
        <PermitHeaderRight>
          <div className="permit-detail-status-tag">
            <StatusTag text={status} displayStatusText={displayStatus} />
          </div>
        </PermitHeaderRight>
      </PermitHeader>
      {/* Permit Detail Card Content  */}
      <PermitContent>
        {/* Permit Detail Content Title */}
        {details && <ActivityDescription dangerouslySetInnerHTML={{ __html: details }} />}
        {/* Permit Detail Content What you need to do  */}
        {(documents?.length > 0 || subtitle || description || actions?.length > 0) && (
          <WhatYouNeedToDo>
            {subtitle && (
              <WhatYouNeedToDoTitle
                dangerouslySetInnerHTML={{ __html: subtitle }}
                haveDescription={Boolean(description)}
              />
            )}
            {description && (
              <WhatYouNeedToDoDescription
                dangerouslySetInnerHTML={{ __html: description }}
                haveActions={Boolean(actions?.length > 0 || documents?.length > 0)}
              />
            )}
            {/* Permit Detail Content Call to Action  */}
            {actions?.length > 0 && (
              <ListActionContainer>
                {actions.map((action: TAction, index) => (
                  <ActionContainer key={index} className="row">
                    <CallToAction
                      isExternal={action.type === ACTION_LIST.redirectExternalLink}
                      buttonType={
                        action.type === ACTION_LIST.cancelApplication ? 'outline' : 'primary'
                      }
                      onClick={() => handleClickAction(action)}
                    >
                      {action.type === ACTION_LIST.cancelApplication && <SvgBan />}
                      {action.text}
                      {action.type === ACTION_LIST.redirectExternalLink && (
                        <SvgWrapper>
                          <SvgExternal />
                        </SvgWrapper>
                      )}
                    </CallToAction>
                  </ActionContainer>
                ))}
                {actions.find(
                  (action: TAction) => action.type === ACTION_LIST.cancelApplication
                ) && (
                  <DialogueBox
                    translationKeys={TRANSLATION_KEYS_SET}
                    type="ban"
                    actionCallbackFn={confirmAction}
                    cancelCallbackFn={() => setIsOpenDialog(false)}
                    isOpen={isOpenDialog}
                  />
                )}
              </ListActionContainer>
            )}
            {/* Download documents */}
            {documents?.length > 0 && (
              <DownloadSection className={actions?.length ? 'haveActions' : null}>
                {documents.map((doc, index) => {
                  const { size, unit } = byteConverter(doc.fileSize);
                  const convertedDate = dateConverter(doc.lastModified);
                  return (
                    <DownloadWrapper key={index}>
                      <DownloadIcon>
                        <SvgPDF />
                      </DownloadIcon>
                      <div>
                        <DownloadFileName
                          className="request-tracker-card-document-name"
                          href={doc.url}
                        >
                          {doc.text}
                          <SvgDownload />
                        </DownloadFileName>
                        <DownloadDescription>
                          {doc.type.toUpperCase()} • {`${size}${unit.ShortForm}`} •{' '}
                          {t('registration-details-last-updated')} {convertedDate?.visualDate}
                        </DownloadDescription>
                      </div>
                    </DownloadWrapper>
                  );
                })}
              </DownloadSection>
            )}
          </WhatYouNeedToDo>
        )}
      </PermitContent>
    </PermitWrapper>
  );
};

export default React.memo(PermitStatusCard);
