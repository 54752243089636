import { color, mq, text_m } from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';
import { spacing } from '_utils/props';

// INFO: Wrapper Content
export const PaymentsDueListWrapper = styled.div`
  width: 100%;
  ${({ theme }) => spacing({ theme, mt: 7 })};
  background-color: ${color('all.white')};
`;

export const PDL_ListAction = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => spacing({ theme, px: 3 })};

  ${mq.lessThan('sm')} {
    padding: 0;
  }
`;

export const PDL_Divider = styled.hr`
  height: 1px;
  border: initial;
  background-color: ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};
`;

export const PDL_List = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;

export const PDL_LoadingOrNoData = styled.div`
  margin: 0;
  ${({ theme }) => spacing({ theme, mt: 4, p: 3 })};
  background-color: #f9f9f9;
  color: ${color('all.deepBlueTint75')};
  ${text_m};
  border-radius: 8px;
  border: 1px solid ${color('all.deepBlueTint15')};
`;

export const PDL_ShowMore = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4, mb: 7 })};

  > div.PDL_ShowMoreContainer > button {
    border-color: ${color('all.greenTint110')};
    box-shadow: initial;
    &:before {
      display: block;
    }
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshWrapper = styled.span`
  margin-left: 8px;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.card.action};
  }
  svg {
    ${RotateCss};
    overflow: visible;
    ${mq('md')} {
      width: 24px;
    }
  }
`;
