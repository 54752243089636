import { spacing } from "_utils/props";
import styled from "styled-components";
import { text_m } from "_utils/styles";

export const OptionWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 43px;
  background: #FFFFFF;
  border: 1px solid #DDDEE1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => spacing({ theme, mt: 1 })};
  max-height: 300px;
  transition: max-height 0.3s, border-width 0.3s;
  overflow: scroll;
  overflow-x: hidden;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ theme }) => spacing({ theme, mt: 1 })};
  
  .active{
    outline: 2px solid #2DB84B;
  }
  z-index: ${props => props.index? props.index: ''};
`

export const Select = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border: 1px solid #DDDEE1;
  border-radius: 8px;
  background: #FFFFFF;
  width: 100%;
  height: 48px;
  padding: 10px 24px;
  &:focus-visible {
    outline: 2px solid #8CA0FF;
  } 
`

export const TextValue = styled.p`
  ${text_m}
  text-align: left;
  ${({ theme }) => spacing({ theme, m: 0 })};
`

export const Option = styled.button`
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  ${text_m}
  cursor: pointer;
  text-align: left;
  padding: 10px 24px;
  ${({ theme }) => spacing({ theme, m: 0 })};
  &:hover {
    background: #DAF5E4;
  };
`


