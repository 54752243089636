import React, { useState, useEffect } from 'react';
import { Form, Input, Submit, Icon } from './StyledMainSearch';
import { Search } from '_utils/icons';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { buildSearchUrl } from '_utils/helpers';
import { useTranslation } from 'react-i18next';
import ScreenReader from '_utils/components/Corporate/ScreenReader';

const MainSearch = ({ placeholder = undefined }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [focused, setFocused] = useState(false);
  const { search, pathname } = location || {};
  const searchParams = new URLSearchParams(search);
  const [term, setTerm] = useState(searchParams.get('term') || '');

  if (!placeholder) {
    placeholder = t('search-placeholder-text');
  }

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    history.push(buildSearchUrl({ term, page: 1 }));
  };

  useEffect(() => {
    if (pathname !== buildSearchUrl({})) {
      setTerm('');
    }
  }, [pathname]);

  return (
    <Form role="search" className={focused ? 'search--focused' : ''} onSubmit={onSubmit}>
      <Input
        type="search"
        placeholder={placeholder}
        onChange={(e) => setTerm(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        aria-label="Search"
        value={term}
        aria-required="true"
      ></Input>
      <Submit type="submit">
        <ScreenReader>{t('search-accessible-search-button-label')}</ScreenReader>
        <Icon>
          <Search ariaHidden={true} />
        </Icon>
      </Submit>
    </Form>
  );
};

export default MainSearch;
