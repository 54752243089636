import { get, post } from './genericService';

export function getPersonalInfo(success, failed) {
  return get('/api/v1/account/personal-info', success, failed);
}

export function getListAddressByKeyword(countryISO, keyword, sessionApi, success, failed) {
  let urlPath = '/api/v1/account/address/suggestions?';
  if (keyword) urlPath += 'keyword=' + keyword + '&';
  if (sessionApi) urlPath += 'session=' + sessionApi + '&';
  if (countryISO) urlPath += 'countryISO=' + countryISO;
  return get(urlPath, success, failed);
}

export function getAddressDetails(addressKey, countryISO, sessionApi, success, failed) {
  let urlPath = '/api/v1/account/address/details?';
  if (addressKey) urlPath += 'key=' + addressKey + '&';
  if (sessionApi) urlPath += 'session=' + sessionApi + '&';
  if (countryISO) urlPath += 'countryISO=' + countryISO;
  return get(urlPath, success, failed);
}

export function getListCountry(success, failed) {
  return get('/api/v1/account/address/countries', success, failed);
}

export function updateResidentialAddress(params, success, failed, config = {}) {
  return post('/api/v1/account/personal-info/update', params, success, failed, config);
}

export function updatePostalAddress(params, success, failed, config = {}) {
  return post('/api/v1/account/personal-info/update', params, success, failed, config);
}

export function updateNotificationReminder(params, success, failed, config = {}) {
  return post('/api/v1/account/personal-info/update', params, success, failed, config);
}
