import { button, mq, safeFocusRemoval } from '_utils/styles';
import styled, { css } from 'styled-components';

const ScrollButton = css`
  ${button()}
  ${safeFocusRemoval}
  width: 40px;
  height: 40px;
  border-radius: 28px;

  position: absolute;
  z-index: 4;
  bottom: 50%;
  transform: translateY(50%);

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: #ffffff;
    }
  }

  body:not(.safe-focus-removal) &:focus:before {
    content: none;
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -6px;
      top: -6px;
      content: '';
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
`;

export const ScrollButtonLeft = styled.button`
  ${ScrollButton};
  left: -13px;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};

  ${mq('lg')} {
    left: -26px;
  }
`;

export const ScrollButtonRight = styled.button`
  ${ScrollButton};
  right: -13px;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};

  ${mq('lg')} {
    right: -26px;
  }
`;

export const WrapperInSide = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  left: ${({ moveToRight }) => `-${moveToRight}px`};

  transition: 0.5s linear;

  .tabNav {
    margin: 0 12px;
  }
`;

export const WrapperOutSide = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1120px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1120px;
`;
