export interface ScheduledPaymentsProps {
  sitecoreContext: any;
}

export interface IPaymentDetailsProps {
  data: any;
  isEdit: boolean;
}

export const DATE_REG = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

export const PAYMENT_STATUSES = {
  active: 'active',
  cancellationRequested: 'cancellation-requested',
  expired: 'expired'
};
