import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { spacing } from '_utils/props';
import { color, heading_m, mq, text_m, text_s, text_xs } from '_utils/styles';

export const PaymentDueWrapper = styled.div`
  ${({ theme }) => spacing({ theme, px: 3, py: 2 })};
  &:nth-child(2n) {
    background-color: #f9f9f9;
    border-top: 1px dashed ${color('all.deepBlueTint15')};
    border-bottom: 1px dashed ${color('all.deepBlueTint15')};
  }

  ${mq('lg')} {
    > div {
      margin-left: -12px;
      margin-right: -12px;
    }
    > div > div {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;

export const PD_DateStatusTagContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PD_StatusTagContainer = styled.div`
  margin-bottom: 4px;
  > div > div:first-child {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PD_Date = styled.div`
  ${text_s};
  color: ${color('all.deepBlueTint75')};
  margin: 0;
`;

export const PD_TypeDescriptionContainer = styled.div`
  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: 1 })};
  }
`;

export const PD_Type = styled.div`
  ${text_xs};
  color: ${color('all.deepBlueTint75')};
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
`;

export const PD_Description = styled(NavLink)`
  ${text_m};
  cursor: pointer;
  color: ${color('all.deepBlue')};
  text-decoration-line: underline;
  display: block;
  margin: 0;
  margin-top: 4px;
  text-transform: initial;

  &:hover {
    color: ${color('all.green')};
  }
  &:visited {
    color: ${color('all.darlinghurstPurple')} !important;
  }
  &:focus {
    color: ${color('all.deepBlue')};
  }
`;

export const PD_AmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, pt: 1 })};
  }
`;

export const PDL_CheckboxContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
  }
  > div > label {
    padding: 0;
    padding-right: 20px;

    ${mq('md')} {
      padding-right: 24px;
    }
  }
  > div > div:last-child {
    right: 0;
    left: initial;
    border-color: ${({ disabled }) =>
      disabled ? color('all.deepBlueTint15') : color('all.deepBlueTint45')};
    background-color: ${({ disabled }) => (disabled ? color('all.deepBlueTint07') : 'transparent')};

    ${mq('md')} {
      width: 24px;
      height: 24px;
    }
  }
  > div > input[type='checkbox']:focus ~ div:last-child:after {
    display: none;
  }
`;

export const PD_Amount = styled.div`
  ${heading_m};
  color: ${color('all.deepBlue')};
  font-weight: 700;
`;

export const PD_AmountWithCheckBox = styled(PD_Amount)`
  ${({ theme }) => spacing({ theme, mr: 1 })};

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mr: 3 })};
  }
`;
