import React from 'react';
import { withSvg } from '../Svg';

const SvgCost = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6758 2.08325H13.7258C14.5071 2.08325 15.1127 2.33337 15.5211 2.75755C15.9091 3.16063 16.052 3.6532 16.052 4.03145V4.58325C16.052 5.04349 15.6779 5.41659 15.2164 5.41659C14.7549 5.41659 14.3807 5.04349 14.3807 4.58325V4.03145C14.3807 4.03145 14.3806 4.03181 14.3801 4.02978C14.3776 4.01845 14.3656 3.96388 14.3154 3.91177C14.2781 3.87295 14.1474 3.75903 13.7714 3.75043L4.57171 5.03168C4.44139 5.07247 4.23268 5.17216 4.05969 5.3395C3.886 5.50751 3.75453 5.73371 3.75453 6.06989L3.75586 15.5966C3.75586 15.5966 3.75586 15.5997 3.75654 15.6059C3.75721 15.6122 3.75845 15.6206 3.76066 15.6311C3.76513 15.6523 3.77302 15.6791 3.78611 15.7096C3.81185 15.7695 3.85705 15.842 3.93514 15.9141C4.08098 16.0488 4.41902 16.2497 5.15612 16.2497H15.2177C15.3945 16.2497 15.6783 16.19 15.894 16.0351C16.067 15.9109 16.2453 15.6966 16.2453 15.2249V8.94116C16.2453 8.76483 16.1854 8.48178 16.0301 8.26676C15.9056 8.09424 15.6906 7.91638 15.2177 7.91638H5.52877C5.06726 7.91638 4.69313 7.54329 4.69313 7.08305C4.69313 6.62281 5.06726 6.24972 5.52877 6.24972H15.2177C16.2353 6.24972 16.952 6.69124 17.3864 7.29292C17.7902 7.85212 17.9166 8.49812 17.9166 8.94116V15.2249C17.9166 16.2398 17.4738 16.9545 16.8705 17.3877C16.3097 17.7903 15.662 17.9164 15.2177 17.9164H5.15612C4.10449 17.9164 3.32458 17.6218 2.79966 17.1371C2.28725 16.6639 2.08457 16.0679 2.08457 15.5966L2.08325 6.06989C2.08325 4.32188 3.54285 3.56886 4.20682 3.40333L4.25778 3.39062L13.6758 2.08325Z"
      fill="#4E5A5F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7836 12.708C14.1297 12.708 14.4103 12.4282 14.4103 12.083C14.4103 11.7379 14.1297 11.458 13.7836 11.458C13.4374 11.458 13.1568 11.7379 13.1568 12.083C13.1568 12.4282 13.4374 12.708 13.7836 12.708ZM13.7836 13.7497C14.7066 13.7497 15.4548 13.0035 15.4548 12.083C15.4548 11.1626 14.7066 10.4164 13.7836 10.4164C12.8605 10.4164 12.1123 11.1626 12.1123 12.083C12.1123 13.0035 12.8605 13.7497 13.7836 13.7497Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgCost);
