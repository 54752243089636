import moment from 'moment-mini';

export const isValidDate = (d) => d.toString().toLowerCase() !== 'invalid date';

type DateData = {
  attrDate: string; // used for <time datetime="" />
  visualDate: string;
};

export default (value = null, options = null, dateFormat = 'YYYYMMDDTHHmmss'): DateData => {
  try {
    if (value === null) {
      return null;
    }

    if (options && options.serverDate) {
      value = value + 'Z';
      dateFormat = dateFormat + 'Z';
    }

    let date = moment.parseZone(value, dateFormat, true);

    if (!date.isValid()) {
      return null;
    }

    if (options && options.yearOnly) {
      return {
        attrDate: date.format('YYYY-MM-DD'),
        visualDate: date.format('YYYY')
      };
    }

    if (options && options.short) {
      return {
        attrDate: date.format('YYYY-MM-DD'),
        visualDate: date.format('DD/MM/YYYY')
      };
    }

    return {
      attrDate: date.format('YYYY-MM-DD'),
      visualDate: date.format('D MMMM YYYY')
    };
  } catch {
    return null;
  }
};
