import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '_utils/components/Corporate';
import NavItem from '../NavItem';
import { useTranslation } from 'react-i18next';

import {
  Container1,
  Container2,
  Container3,
  List,
  ListItem,
  TertiaryList,
  TertiaryListItem,
  PrimaryLink,
  SecondaryLink,
  TertiaryLink,
  LogoWrapper,
  LockupWrapper,
  SocialLink,
  SocialListItem,
  SocialTitle,
  SubscribeLink,
  Divider,
  QuaternaryNavigation,
  QuaternaryNavigationDescription,
  QuaternaryNavigationLink,
  QuaternaryNavigationSpan
} from './StyledPageFooter';
import { compose } from 'ramda';
// import mockFooter from './mockFooter.json';

// import { Logo, Lockup, Facebook, Instagram, LinkedIn, Youtube, Twitter } from '_utils/icons';
import ScreenReader from '_utils/components/Corporate/ScreenReader';

import asyncComponent from '_containers/AsyncComponent';

const AsyncLogo: any = asyncComponent(() =>
  import(/* webpackChunkName: "Logo" */ '_utils/icons/Logo')
);
const AsyncLockup: any = asyncComponent(() =>
  import(/* webpackChunkName: "Lockup" */ '_utils/icons/Lockup')
);
const AsyncFacebook: any = asyncComponent(() =>
  import(/* webpackChunkName: "Facebook" */ '_utils/icons/social/Facebook')
);
const AsyncInstagram: any = asyncComponent(() =>
  import(/* webpackChunkName: "Instagram" */ '_utils/icons/social/Instagram')
);
const AsyncLinkedIn: any = asyncComponent(() =>
  import(/* webpackChunkName: "LinkedIn" */ '_utils/icons/social/LinkedIn')
);
const AsyncYoutube: any = asyncComponent(() =>
  import(/* webpackChunkName: "Youtube" */ '_utils/icons/social/Youtube')
);
const AsyncTwitter: any = asyncComponent(() =>
  import(/* webpackChunkName: "Twitter" */ '_utils/icons/social/Twitter')
);
const AsyncThreads: any = asyncComponent(() =>
  import(/* webpackChunkName: "Threads" */ '_utils/icons/social/Threads')
);

const socialLinkMap = {
  facebook: {
    component: AsyncFacebook,
    id: 'footer-facebook',
    title: 'facebook',
    link: {
      type: 'External',
      url: 'https://facebook.com/cityofsydney',
      target: '_blank'
    }
  },
  instagram: {
    component: AsyncInstagram,
    id: 'footer-instagram',
    title: 'instagram',
    link: {
      type: 'External',
      url: 'https://www.instagram.com/cityofsydney',
      target: '_blank'
    }
  },
  linkedIn: {
    component: AsyncLinkedIn,
    id: 'footer-linkedin',
    title: 'linked-in',
    link: {
      type: 'External',
      url: 'https://www.linkedin.com/company/cityofsydney',
      target: '_blank'
    }
  },
  twitter: {
    component: AsyncTwitter,
    id: 'footer-twitter',
    title: 'twitter',
    link: {
      type: 'External',
      url: 'https://twitter.com/cityofsydney',
      target: '_blank'
    }
  },
  youTube: {
    component: AsyncYoutube,
    id: 'footer-youtube',
    title: 'youtube',
    link: {
      type: 'External',
      url: 'https://www.youtube.com/cityofsydney',
      target: '_blank'
    }
  },
  threads: {
    component: AsyncThreads,
    id: 'footer-theads',
    title: 'threads',
    link: {
      type: 'External',
      url: 'https://www.threads.net/@cityofsydney',
      target: '_blank'
    }
  }
};

function SocialLinks({ activeSocialKeys }) {
  const [t] = useTranslation();

  if (!activeSocialKeys || !activeSocialKeys.length) {
    return null;
  }

  return activeSocialKeys.map((key, index) => {
    let item = socialLinkMap[key];

    if (item) {
      const Icon = item.component;
      return (
        <SocialListItem key={index}>
          <NavItem link={item.link} StyledLink={SocialLink} description={key}>
            <ScreenReader>{t(`page-footer-accessible-social-label-${item.title}`)}</ScreenReader>
            <Icon ariaHidden={true} />
          </NavItem>
        </SocialListItem>
      );
    }

    return null;
  });
}

function QuaternaryItems({ quaternaryItems }) {
  if (!quaternaryItems.length) {
    return null;
  }

  return quaternaryItems.map((item, index) => {
    if (!item?.description?.value && !item?.label?.value) {
      return null;
    }
    return (
      <Container3 key={index}>
        <QuaternaryNavigation>
          <QuaternaryNavigationDescription>
            {item?.description?.value}
          </QuaternaryNavigationDescription>
          {item?.link?.url ? (
            <NavItem
              description={item?.label?.value}
              link={item?.link}
              children={item?.label?.value}
              StyledLink={QuaternaryNavigationLink}
            />
          ) : (
            <QuaternaryNavigationSpan>{item?.label?.value}</QuaternaryNavigationSpan>
          )}
        </QuaternaryNavigation>
      </Container3>
    );
  });
}

export const PageFooter = ({ fields, sitecoreContext }) => {
  const [t] = useTranslation();

  const datasource = fields?.data?.datasource || null;

  if (!datasource) {
    return null;
  }

  const primaryNavigation = datasource?.primaryNavigation?.[0] || [];
  const secondaryNavigation = datasource?.secondaryNavigation?.[0] || [];
  const tertiaryNavigation = datasource.tertiaryNavigation?.[0] || [];
  const socialMediaLinks = datasource?.socialMediaLinks?.[0] || [];

  const primaryItems = primaryNavigation?.links || [];
  const secondaryItems = secondaryNavigation?.links || [];
  const tertiaryItems = tertiaryNavigation?.links || [];
  const quaternaryItems = datasource?.quaternaryNavigation || [];

  const activeSocialKeys = Object.keys(socialMediaLinks).filter(
    (key) => key !== 'subscribeButtonLink' && socialMediaLinks[key]
  );

  const subscribeLink = socialMediaLinks.subscribeButtonLink;

  return (
    <footer>
      <Container1>
        <Grid.Container>
          <Grid.Row config={{ gutters: ['left', 'right'] }}>
            <Grid.Item
              config={{ col: 12 }}
              order={{ xs: 0 }}
              textAlign={{ xs: 'left', lg: 'center' }}
              height={{ xs: 'auto', lg: '42px' }}
            >
              <LogoWrapper aria-hidden={true}>
                <AsyncLogo fill="#fff" />
              </LogoWrapper>
              <LockupWrapper aria-hidden={true}>
                <AsyncLockup />
              </LockupWrapper>
            </Grid.Item>
            <Grid.Item config={{ col: { xs: 12, sm: 6, md: 4 } }} order={{ xs: 1 }}>
              <nav aria-label="footer primary">
                <List>
                  {primaryItems.map((item, index) => (
                    <ListItem key={index}>
                      <NavItem
                        children={item.label.value}
                        link={item.link}
                        StyledLink={PrimaryLink}
                        description={item?.label?.value}
                      />
                    </ListItem>
                  ))}
                </List>
              </nav>
            </Grid.Item>
            <Grid.Item config={{ col: { xs: 12, sm: 6, md: 4 } }} order={{ xs: 2, sm: 3, md: 2 }}>
              <nav aria-label="footer secondary">
                <List>
                  {secondaryItems.map((item, index) => (
                    <ListItem key={index}>
                      <NavItem
                        children={item.label.value}
                        link={item.link}
                        StyledLink={SecondaryLink}
                        description={item?.label?.value}
                      />
                    </ListItem>
                  ))}
                </List>
              </nav>
            </Grid.Item>
            <Grid.Item config={{ col: { xs: 12, sm: 6, md: 4 } }} order={{ xs: 3, sm: 2, md: 3 }}>
              {subscribeLink && (
                <>
                  <SocialTitle>{t('page-footer-subscribe-title')}</SocialTitle>
                  <NavItem
                    children={t('page-footer-subscribe-label')}
                    link={subscribeLink}
                    StyledLink={SubscribeLink}
                    description={t('page-footer-subscribe-label')}
                  />
                </>
              )}
              {activeSocialKeys && activeSocialKeys.length > 0 && (
                <>
                  <SocialTitle>{t('page-footer-social-title')}</SocialTitle>
                  <SocialLinks activeSocialKeys={activeSocialKeys} />
                </>
              )}
            </Grid.Item>
          </Grid.Row>
        </Grid.Container>
      </Container1>
      <Divider />
      <Container2>
        <Grid.Container>
          <Grid.Row config={{ gutters: ['left', 'right'] }}>
            <Grid.Item config={{ col: 12 }}>
              <nav aria-label="footer tertiary">
                <TertiaryList>
                  {tertiaryItems.map((item, index) => (
                    <TertiaryListItem key={index}>
                      <NavItem
                        children={item.label.value}
                        link={item.link}
                        StyledLink={TertiaryLink}
                        description={item?.label?.value}
                      />
                    </TertiaryListItem>
                  ))}
                </TertiaryList>
              </nav>
            </Grid.Item>
          </Grid.Row>
        </Grid.Container>
      </Container2>
      <QuaternaryItems quaternaryItems={quaternaryItems}></QuaternaryItems>
    </footer>
  );
};

export default compose(withSitecoreContext())(PageFooter);
