import styled from 'styled-components';

import { safeFocusRemoval } from '_utils/styles';
import svg from '../../../styles/svg';

import mq from '../../../styles/mq';

import { button } from '_utils/styles/button';

import { spacing } from '_utils/props';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const FlickityWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;

  ${({ theme }) => spacing({ theme, py: 3 })}

  .flickity-viewport {
    width: 100%;
    overflow: visible;
  }
`;

export const PrevNextPosition = styled.div`
  position: absolute;
  z-index: 1;
  overflow: visible;
  top: 50%;
  transition: transform 0.4s ${({ theme }) => theme.motion.ease};

  &.prev {
    left: 0;
    transform: translate(-50%, -50%);

    ${mq('xl')} {
      transform: translate(-150%, -50%);
    }
  }

  &.next {
    right: 0;
    transform: translate(50%, -50%);

    ${mq('xl')} {
      transform: translate(150%, -50%);
    }
  }
`;

export const PrevNextButton = styled.button`
  ${button()}
  ${safeFocusRemoval}
  width: 32px;
  height: 32px;
  display: none;

  ${mq('md')} {
    width: 40px;
    height: 40px;
  }
  opacity: 1;
  transition: opacity 0.2s linear;

  &:disabled {
    opacity: 0;
    pointer-events: none;
    cursor: auto;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowWrapper = styled.span`
  flex: none;
  ${() => svg(7, 12)};
  width: 8px;
  path {
    fill: ${({ theme }) => theme.colors.element.white};
  }

  .prev & {
    transform: rotate(180deg);
  }
`;
