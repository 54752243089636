import styled, { css } from 'styled-components';
import {
  color,
  mq,
  text_l,
  text_m,
  heading_s,
  heading_m,
  text_xs,
  button,
  heading_xs
} from '_utils/styles';
import { spacing } from '_utils/props';

export const WhatYouNeedToDoCardWrapper = styled.div`
  background-color: ${color('all.deepBlue')};
  color: ${color('all.white')};
  border-radius: 8px;
  ${({ theme }) => spacing({ theme, p: 3 })};

  svg {
    path {
      fill: ${color('all.white')};
    }
  }
`;

export const ActivityDescription = styled.p`
  ${text_l}
  font-family: "Helvetica Now Display", "Helvetica Now Text", Helvetica, sans-serif;
  font-weight: 400;
  line-height: 28px;

  ${mq('md')} {
    font-family: 'Helvetica Now Display', 'Helvetica Now Text', Helvetica, sans-serif;
  }

  ${({ theme }) => spacing({ theme, mt: 0, pb: 3, mb: 3 })};
  border-bottom: 1px solid ${color('all.deepBlueTint15')};
`;

export const WhatYouNeedToDoCardContent = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const IconWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mb: 1 })};
  flex: 0 0 24px;

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mr: 2, mb: 0 })};
    flex: 0 0 32px;
  }

  svg {
    width: 24px;
    height: 24px;

    ${mq('md')} {
      width: 32px;
      height: 32px;
    }
  }
`;

export const TextWrapper = styled.div``;

export const CardTitle = styled.h2`
  ${({ theme }) => spacing({ theme, mb: 2 })};
  ${heading_m}
`;

export const ActionDescription = styled.div`
  ${text_m}
  ${({ theme }) => spacing({ theme, mb: 3 })};
  &:last-child {
    margin-bottom: 0;
  }

  p {
    ${({ theme }) => spacing({ theme, m: 0, mb: 2 })};

    ${mq.lessThan('md')} {
      ${({ theme }) => spacing({ theme, mb: 1 })};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 2px solid ${color('all.green')};

    &:visited {
      color: inherit;
    }

    &:hover,
    &:focus {
      border: 0;
      background: ${color('all.green')};
      color: ${color('all.deepBlue')};
    }
  }
`;

export const ListActionContainer = styled.div`
  display: flex;

  ${mq.lessThan('md')} {
    flex-direction: column;
  }
`;

export const ActionContainer = styled.div`
  ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};

  &:first-child {
    ${({ theme }) => spacing({ theme, m: 0 })};
  }

  ${mq('md')} {
    &.row {
      ${({ theme }) => spacing({ theme, m: 0 })};
      display: inline-block;
    }

    & ~ &.row {
      ${({ theme }) => spacing({ theme, ml: 2 })};
    }
  }
`;

const OutlineCss = css`
  outline: 2px solid #4e5a5f;
  outline-offset: -2px;
  color: #4e5a5f;
  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    path {
      fill: #4e5a5f;
    }
  }

  &:hover {
    outline-color: #000000;
    color: #000000;

    svg {
      path {
        fill: #000000;
      }
    }
  }

  ${mq('md')} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CallToAction = styled.button`
  ${({ buttonType }) => button('default', buttonType)};
  display: ${({ isExternal }) => isExternal ? 'inline': 'flex'};
  align-items: center;
  border: none;
  ${heading_s};
  ${({ theme }) => spacing({ theme, m: 0, px: 2, py: 1 })};

  &:hover {
    border: none;
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, py: '10px' })};
  }
  ${({ buttonType }) => (buttonType === 'outline' ? OutlineCss : null)};

  body:not(.safe-focus-removal) &:focus:before {
    content: none;
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -4px;
      top: -4px;
      content: '';
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
`;

// INFO: Download block

export const DownloadSection = styled.div`
  &.haveActions {
    ${({ theme }) => spacing({ theme, mt: 3 })};
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 2 })};
  }

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const DownloadIcon = styled.div`
  ${({ theme }) => spacing({ theme, mr: 2 })};
  svg {
    path {
      fill: ${color('all.pottsPointRedTint110')};
    }
  }

  ${mq.lessThan('md')} {
    flex-direction: row;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const DownloadDetails = styled.div`
  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: 1 })};
  }
`;

export const DownloadFileName = styled.a`
  ${heading_s}
  color: ${color('all.white')};
  text-decoration: none;

  &:hover {
      color: ${color('all.greenTint110')};
  }

  svg {
    margin-left: 12px;
    path {
      fill: ${color('all.greenTint110')};
    }
  }
`;

export const DownloadDescription = styled.div`
  ${text_xs};
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, m: 0, mt: 1 })};

  &.text-white {
    color: ${color('all.white')};
  }
`;
