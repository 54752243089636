import React, {
  useRef,
  useEffect,
  cloneElement,
  ReactElement,
  HTMLAttributes,
  FunctionComponent
} from 'react';

type Events = MouseEvent;
interface Props extends HTMLAttributes<HTMLElement> {
  onClickAway: (event: Events) => void;
  children: ReactElement<any>;
}

const ClickAwayListener: FunctionComponent<Props> = ({ children, onClickAway }) => {
  const node = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleEvents = (event: Events): void => {
      if (node.current && !node.current.contains(event.target as Node)) {
        onClickAway(event);
      }
    };

    document.addEventListener('click', handleEvents);

    return () => {
      document.removeEventListener('click', handleEvents);
    };
  }, [onClickAway]);

  return React.Children.only(
    cloneElement(children as ReactElement<any>, {
      ref: node
    })
  );
};

ClickAwayListener.displayName = 'ClickAwayListener';

export default ClickAwayListener;
