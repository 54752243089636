import React from 'react';
import {withSvg} from "../Svg";
import PropTypes from 'prop-types'

const SvgEmail = (props) => (
        <path d="M15.8334 3.33301H4.16675C3.50371 3.33301 2.86782 3.5964 2.39898 4.06524C1.93014 4.53408 1.66675 5.16997 1.66675 5.83301V14.1663C1.66675 14.8294 1.93014 15.4653 2.39898 15.9341C2.86782 16.403 3.50371 16.6663 4.16675 16.6663H15.8334C16.4965 16.6663 17.1323 16.403 17.6012 15.9341C18.07 15.4653 18.3334 14.8294 18.3334 14.1663V5.83301C18.3334 5.16997 18.07 4.53408 17.6012 4.06524C17.1323 3.5964 16.4965 3.33301 15.8334 3.33301ZM4.16675 4.99967H15.8334C16.0544 4.99967 16.2664 5.08747 16.4227 5.24375C16.579 5.40003 16.6667 5.61199 16.6667 5.83301L10.0001 9.89967L3.33341 5.83301C3.33341 5.61199 3.42121 5.40003 3.57749 5.24375C3.73377 5.08747 3.94573 4.99967 4.16675 4.99967ZM16.6667 14.1663C16.6667 14.3874 16.579 14.5993 16.4227 14.7556C16.2664 14.9119 16.0544 14.9997 15.8334 14.9997H4.16675C3.94573 14.9997 3.73377 14.9119 3.57749 14.7556C3.42121 14.5993 3.33341 14.3874 3.33341 14.1663V7.73301L9.56675 11.5413C9.69343 11.6145 9.83713 11.653 9.98341 11.653C10.1297 11.653 10.2734 11.6145 10.4001 11.5413L16.6667 7.73301V14.1663Z"
              fill={props.color || "#4E5A5F"}/>
);

SvgEmail.prototype = {
    color: PropTypes.string
}

export default withSvg(20, 20)(SvgEmail);
