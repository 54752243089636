import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getPaymentHistory } from '_services/paymentHistoryService';
import PaymentsSort from '_components/CityConnect/PaymentsDueList/PaymentsSort';
import PaymentDue from '_components/CityConnect/PaymentsDueList/PaymentDue';
import { IResponseTemplate } from './definitions';
import { IPaymentDueLoaded } from '_components/CityConnect/PaymentsDueList/PaymentDue/definitions';
import ShowMore from '_components/CityConnect/ShowMore';
import TextMessage from '../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
// import icons
import SvgSortDueFirst from '_utils/icons/PaymentsDueList/SortDueFirst';
import SortDueLast from '_utils/icons/PaymentsDueList/SortDueLast';
import SortAmountArrowDown from '_utils/icons/PaymentsDueList/SortAmountArrowDown';
import SortAmountDownAlt from '_utils/icons/PaymentsDueList/SortAmountDownAlt';
import SortAmountDown from '_utils/icons/PaymentsDueList/SortAmountDown';
// import styled
import {
  PaymentHistoryWrapper,
  PH_ContentContainer,
  PH_ListAction,
  PH_Divider,
  PH_List,
  PH_LoadingOrNoData,
  PH_ShowMore,
  RefreshWrapper
} from './StyledPaymentHistory';
import { Refresh } from '_utils/icons';

const DEFAUL_PAGE_SIZE = 10;
const SORT_TYPE = {
  MOST_RECENT: 'most-recent',
  OLDEST: 'oldest',
  LEAST_AMOUNT: 'least-amount',
  HIGHEST_AMOUNT: 'highest-amount'
};
const INITIAL_RESPONSE_DATA = {
  sort: [],
  results: {
    totalItems: '0',
    currentPage: '1',
    pageSize: '10',
    nextPage: '',
    items: []
  },
  actions: []
};

const PaymentHistory: React.FC = () => {
  const [t] = useTranslation();
  const [responseData, setResponseData] = useState<IResponseTemplate>(INITIAL_RESPONSE_DATA);
  const [listPaymentDueLoaded, setListPaymentDueLoaded] = useState<IPaymentDueLoaded[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState();

  const LIST_DATA_SORT = [
    {
      value: SORT_TYPE.MOST_RECENT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.MOST_RECENT)?.link || '',
      label: t('payment-history-most-recent'),
      icon: <SvgSortDueFirst />
    },
    {
      value: SORT_TYPE.OLDEST,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.OLDEST)?.link || '',
      label: t('payment-history-oldest'),
      icon: <SortDueLast />
    },
    {
      value: SORT_TYPE.LEAST_AMOUNT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.LEAST_AMOUNT)?.link || '',
      label: t('payment-history-least-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDownAlt />
        </React.Fragment>
      )
    },
    {
      value: SORT_TYPE.HIGHEST_AMOUNT,
      link: responseData.sort.find((sort) => sort.type === SORT_TYPE.HIGHEST_AMOUNT)?.link || '',
      label: t('payment-history-highest-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDown />
        </React.Fragment>
      )
    }
  ];

  // get data from api
  useEffect(() => {
    getPaymentHistoryFromAPI('');
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // update loaded payment history
  useEffect(() => {
    const loadedMore: Array<IPaymentDueLoaded> = responseData.results.items;
    // check load more or load new
    if (responseData.results.currentPage === '1') {
      setListPaymentDueLoaded(loadedMore);
    } else {
      setListPaymentDueLoaded(listPaymentDueLoaded.concat(loadedMore));
    }
  }, [responseData]);

  // get payments history from api
  const getPaymentHistoryFromAPI = (link: string) => {
    getPaymentHistory(link, getPaymentHistorySuccess, (res) => setError(res));
  };

  // get payments history from api success
  const getPaymentHistorySuccess = (response) => {
    const paymentHistory = response?.data?.paymentHistory;
    if (paymentHistory) {
      const results = paymentHistory.results;
      setResponseData({
        ...responseData,
        sort: paymentHistory.sort,
        results: {
          totalItems: results.totalItems?.toString(),
          currentPage: results.currentPage?.toString(),
          pageSize: results.pageSize?.toString(),
          nextPage: results.nextPage,
          items: results.items
        },
        actions: paymentHistory.actions
      });
    }
    setIsLoading(false);
    // reset show more text
    setIsLoadingMore(false);
  };

  // handle click on show more button
  const handleClickShowMore = () => {
    // call api to load more
    getPaymentHistoryFromAPI(responseData.results.nextPage);
    // set button show more with text loading...
    setIsLoadingMore(true);
  };

  // handle event change sort value
  const handleChangeSort = (link: string) => {
    // call api to get sorted data
    if (link) {
      getPaymentHistoryFromAPI(link);
    }
  };

  // render each payment due
  const renderPaymentsDue = () => {
    const data = listPaymentDueLoaded;
    return (
      data.length &&
      data.map((payment, index) => {
        return <PaymentDue key={index} payment={payment} isPaymentHistory={true} />;
      })
    );
  };

  // check conditions to render corresponding content
  const handleShowPaymentDueContent = () => {
    return isLoading ? (
      <PH_LoadingOrNoData>
        {t('dashboard-loading-information')}
        <RefreshWrapper>
          <Refresh ariaHidden={false} />
        </RefreshWrapper>
      </PH_LoadingOrNoData>
    ) : listPaymentDueLoaded.length ? (
      renderPaymentsDue()
    ) : (
      <PH_LoadingOrNoData>{t('payment-history-no-payments')}</PH_LoadingOrNoData>
    );
  };

  return (
    <PaymentHistoryWrapper>
      <PH_ContentContainer>
        <PH_ListAction>
          <PaymentsSort data={LIST_DATA_SORT} onChangeSort={handleChangeSort} />
        </PH_ListAction>
        <PH_Divider />
        <PH_List>{handleShowPaymentDueContent()}</PH_List>
        <PH_ShowMore>
          {parseInt(responseData.results.totalItems) > DEFAUL_PAGE_SIZE && (
            <ShowMore
              labelTransKeys={{
                showLabel: 'payment-history-pagination-showing',
                ofLabel: 'payment-history-pagination-of',
                itemLabel: 'payment-history-pagination-payments',
                showMoreLabel: 'payment-history-pagination-show-more',
                loadingLabel: 'payment-history-pagination-loading'
              }}
              numbers={{
                showingNumber: '' + listPaymentDueLoaded.length,
                totalNumber: '' + responseData.results.totalItems
              }}
              isLoading={isLoadingMore}
              handleClickShowMore={handleClickShowMore}
              className="PH_ShowMoreConainer"
            />
          )}
        </PH_ShowMore>
      </PH_ContentContainer>
    </PaymentHistoryWrapper>
  );
};

const PaymentHistoryFallback: React.FC = () => {
  const [t] = useTranslation();

  const LIST_DATA_SORT = [
    {
      value: SORT_TYPE.MOST_RECENT,
      link: '',
      label: t('payment-history-most-recent'),
      icon: <SvgSortDueFirst />
    },
    {
      value: SORT_TYPE.OLDEST,
      link: '',
      label: t('payment-history-oldest'),
      icon: <SortDueLast />
    },
    {
      value: SORT_TYPE.LEAST_AMOUNT,
      link: '',
      label: t('payment-history-least-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDownAlt />
        </React.Fragment>
      )
    },
    {
      value: SORT_TYPE.HIGHEST_AMOUNT,
      link: '',
      label: t('payment-history-highest-amount'),
      icon: (
        <React.Fragment>
          <SortAmountArrowDown />
          <SortAmountDown />
        </React.Fragment>
      )
    }
  ];
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <PaymentHistoryWrapper>
      <PH_ContentContainer>
        <PH_ListAction>
          <PaymentsSort data={LIST_DATA_SORT} onChangeSort={() => {}} />
        </PH_ListAction>
        <PH_Divider />
        <PH_List>
          <TextMessage fields={errorMessage} />
        </PH_List>
      </PH_ContentContainer>
    </PaymentHistoryWrapper>
  );
};

export default withErrorBoundary(PaymentHistory, PaymentHistoryFallback);
