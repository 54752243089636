import React from 'react';
import { canUseDOM } from 'exenv';
import { useTranslation } from 'react-i18next';
import { Text, TextMessageWrapper, RefreshWrapper } from './StyledTextMessage';
import { useHistory } from 'react-router-dom';
import { Refresh } from '_utils/icons';

type TextMessageProps = {
  fields: any;
  loading?: boolean;
  className?: string;
  textOnly?: boolean;
};

const TextMessage: React.FC<TextMessageProps> = ({ fields, loading, className, textOnly }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const value = fields?.data?.datasource?.message?.jss.value;

  const contentText = loading ? t('dashboard-loading-information') : value;

  const handleClick = (e) => {
    if (!canUseDOM) {
      return;
    }

    const { target } = e;
    const { href } = target;

    // exclude pay rates link from preventDefault
    if (target.className === 'pay-rates-link') {
      return;
    }

    // handle clink on a link
    if (target.tagName.toLowerCase() === 'a' && href) {
      try {
        const targetUrl = new URL(href);
        const { pathname, hash, search, origin } = targetUrl;
        const isExternalURL = origin !== window.location.origin;
        const isDownload = pathname.startsWith('/-/media/');
        // handle internal link
        if (!isExternalURL && !isDownload) {
          e.preventDefault();
          const { location } = window;
          // handle link is an anchor
          const isAnchor =
            origin.toLowerCase() === location.origin.toLowerCase() &&
            pathname.toLowerCase() === location.pathname.toLowerCase() &&
            search.toLowerCase() === location.search.toLowerCase() &&
            hash;
          if (isAnchor) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({
                behavior: 'smooth'
              });
              return;
            }
          }
          // route to internal link
          history.push(pathname + search + hash);
        }
      } catch (error) {
        // link href fail to create new URL
        return;
      }
    }
  };

  const renderTextMessage = (displayText) => {
    return <Text.NormalText field={{ value: displayText }} onClick={handleClick} />;
  };

  if (loading) {
    return (
      <TextMessageWrapper>
        {t('dashboard-loading-information')}
        <RefreshWrapper>
          <Refresh ariaHidden={false} />
        </RefreshWrapper>
      </TextMessageWrapper>
    );
  }

  return (
    <>
      {contentText ? (
        textOnly ? (
          renderTextMessage(contentText)
        ) : (
          <TextMessageWrapper className={className}>
            {renderTextMessage(contentText)}
          </TextMessageWrapper>
        )
      ) : (
        ''
      )}
    </>
  );
};

export default TextMessage;
