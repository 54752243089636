import LayoutMap from './temp/layoutMap.js';
import * as layouts from './layouts';

class LayoutFactory {
  layoutMap: Map<string, ({ route: any }) => JSX.Element>;
  layoutIdMap: Map<string, ({ route: any }) => JSX.Element>;

  constructor() {
    // disconnected mode
    this.layoutMap = LayoutMap;
    // connected mode
    this.layoutIdMap = new Map();
    this.layoutIdMap.set('0000bd36-64db-4552-91c8-6f9542ff7019', layouts.DefaultLayout);
  }

  resolveLayout(contextData: any): any {
    // console.log('layoutMap', this.layoutMap);
    const layoutId =
      contextData && contextData.layoutId ? contextData.layoutId.toLowerCase() : null;
    const layoutName = contextData && contextData.layoutName ? contextData.layoutName : null;

    let layout;

    if (layoutId) {
      layout = this.layoutIdMap.get(layoutId);
    }

    if (!layout && layoutName) {
      layout = this.layoutMap.get(layoutName);
    }

    return layout || this.layoutMap.get('DefaultLayout');
  }
}

const layoutFactory = new LayoutFactory();

Object.freeze(layoutFactory);

export default layoutFactory;
