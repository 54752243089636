import styled from 'styled-components';
import { color, mq } from '_utils/styles';

export const CurveContainer = styled.div`
  position: relative;
  min-height: 160px;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    background-color: ${color('all.blue')};
    border-radius: 50%;
    transform-origin: left;
    transform: translateX(-50%);
    width: 110vw;
    height: 320px;
    top: -160px;

    ${mq.lessThan('md')} {
      height: 160px;
      top: -80px;
    }
  }
`;
