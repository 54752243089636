import {BackgroundColors} from '_utils/components/Corporate/Section';
import {spacing} from '_utils/props';
import {color, heading_m, heading_s, linkWithoutVisited, mq} from '_utils/styles';
import {handleSectionTheme} from '_utils/styles/section';
import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';
import { Grid } from '_utils/components/Corporate';
import {Image as SCImage} from '@sitecore-jss/sitecore-jss-react';
import {Link} from "_utils/components/Corporate";

const comment_text = (size: number, weight: number, lineHeight: number) => `
    font-style: normal;
    font-size: ${size}px;
    font-weight: ${weight};
    line-height: ${lineHeight}px;
`

// INFO: Card Wrapper
export const ServiceRequestCardWrapper = styled.div`
  width: 100%;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
  ${({theme}) => spacing({theme, mb: 4})};
  background-color: #FFFFFF;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .card-title {
      color: ${color('all.greenTint110')};
      text-decoration: underline;
    }
  }

  &:focus {
    border: 3px solid #8ca0ff;
  }
`;

export const CardSection = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({theme}) => spacing({theme, p: 3})};
  ${() => handleSectionTheme(BackgroundColors['white'])};
`;

// INFO: Main Information
const ReferenceText = styled.div`
  font-family: "Helvetica Now Micro", "Helvetica Now Text", Helvetica, sans-serif;
  ${comment_text(10, 700, 16)}
  letter-spacing: 0em;
  text-align: left;
  color: ${color('all.deepBlueTint75')};
  text-transform: uppercase;
  ${({theme}) => spacing({theme, mb: 1})};
`;

export const TitleContainer = styled.div`
    ${({theme}) => spacing({theme, mb: 2})};
`

export const TitleWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({theme}) => spacing({theme, mb: 1, mt: 1})};

  span {    
    ${({theme}) => spacing({theme, mr: 1})};
  }
  svg {
    display: inline;
  }

  ${mq('md')} {
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const TimeLeft = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #4E5A5F;
  & svg{
    margin-right: 10px;
  };
  ${mq.lessThan('md')}{
    font-size: 14px;
  }
`

const Title = styled(NavLink)`
  ${heading_m}
  margin-right: 13px;
  color: ${color('all.deepBlue')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  ${mq.lessThan('md')}{
    line-height: 24px;
  }
`;

const StyledDot = css`
  &:after {
    content: '.';
    font-weight: bold;
    position: absolute;
    top: -5px;
    left: -13.5px;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;

  & > .request-type-card__description:first-child {
    ${({theme}) => spacing({theme, mb: 1})};
  }

  & > .request-type-card__description:not(:first-child) {
    position: relative;
    ${({theme}) => spacing({theme, mb: 1})};

    ${mq('lg')} {
      ${StyledDot}
    }
  }

  ${mq('lg')} {
    flex-direction: row;
    & > .request-type-card__description {
      ${({theme}) => spacing({theme, mr: 3})};
    }
  }
`;

const SubmissionTime = styled.span`
  font-family: "Helvetica Now Micro", "Helvetica Now Text", Helvetica, sans-serif;
  ${comment_text(10, 400, 16)}
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  ${({theme}) => spacing({theme, mt: 1, mb: 1})};
  color: ${color('all.deepBlueTint75')};

  ${mq.lessThan('md')}{
    display: inline-block;

    div {
      display: inline-block;
      margin: 6px 6px 3px 6px;
    }
  }
`;

const CommunityIdeaSubmissionTime = styled.span`
  font-family: "Helvetica Now Micro", "Helvetica Now Text", Helvetica, sans-serif;
  ${comment_text(10, 400, 16)}
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  ${({theme}) => spacing({theme, mb: 1})};
  color: ${color('all.deepBlueTint75')};

  ${mq.lessThan('md')}{
    display: inline-block;

    div {
      display: inline-block;
      margin: 6px 6px 3px 6px;
    }
  }
`;

export const MainInformation = {
    ReferenceText,
    Title,
    Address,
    SubmissionTime,
    CommunityIdeaSubmissionTime
};

// INFO: Sub-items Information
export const SubItemWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #F9F9F9;
  border-radius: 8px;
  ${({theme}) => spacing({theme, p: 2})};
  
  &:not(:last-child) {
    ${({theme}) => spacing({theme, mb: 2})};
  }

  ${mq('sm')} {
    flex-direction: row;
  }
`;

export const CommunityIdeaSubItem = styled.div`
  background: #F9F9F9;
  border-radius: 8px;
  ${({theme}) => spacing({theme, p: 2})};
  
  &:not(:last-child) {
    ${({theme}) => spacing({theme, mb: 2})};
  }

  ${mq('sm')} {
    flex-direction: row;
  }
`;

export const CommunityIdeaSubItemRow = styled(Grid.Row)`
  background: #fff;
  border-radius: 8px;
  ${({theme}) => spacing({theme, p: 2})};
  ${({theme}) => spacing({theme, mt: 2})};

  ${mq('sm')} {
    flex-direction: row;
  }
`;

export const SubItemTitle = styled.div`
  ${heading_s}
  display: flex;
  align-items: center;
  & svg{
    fill: #005084;
    margin-right: 10px;
    max-width: 23px;
  }
`;

export const SubItemDes = styled.p`
  ${comment_text(16, 400, 22)}
  color: #041C2C;
  margin: 0;
  ${mq.lessThan('md')}{
    font-size: 14px;
  }
`

export const Dot = styled.div`
    width: 2px;
    height: 2px;
    background: black;
    margin: 6px;
    border-radius: 50%;
`

export const Figure = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
  ${({theme}) => spacing({theme, mb: 2})};
  ${mq.lessThan('lg')}{
    & img{
        border-radius: 50%;
        height: ${props => props.height ? props.height + 'px' : ''}
    }
  }
  @media print {
    width: 50%;
    height: 50%;
  }
`;

export const Image = styled(SCImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  @media print {
    width: 50%;
    left: 25%;
  }
  border-radius: 4px;
`;

export const ResultLink = styled(Link)`
  text-align: end;
  line-height: 24px;
  a {
    font-size: 16px;
    ${({theme}) => linkWithoutVisited({theme})}
  }
  ${mq.lessThan('md')} {
    line-height: 16px;
      a {
        font-size: 14px;
      }
    margin-top: 6px;
  }
  ${mq.lessThan('sm')}{
    text-align: start;
  }
`;

export const SubItemRightSide = styled.div`
    display: flex;
    flex-direction: column;
`

export const SubItemRightSideRow = styled.div`
    display: flex;
    height: 18px;
    justify-content: flex-end;
    align-items: center;
    color: ${props => props.color ? props.color : ''};
    & svg {
        margin-left: 10px;
    }
`
export const Progress = styled.div`
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    background: ${props => props.color ? props.color : ''};
    width: ${props => props.width ? props.width + 'px' : ''};
`

export const TextRate = styled.p`
    color: ${props => props.color ? props.color : ''};
    ${comment_text(10, 700, 16)}
    text-align: right;
    text-transform: uppercase;
    width: 30px;
    margin-right: 5px;
`