import styled, { css } from 'styled-components';
import {
  color,
  mq,
  heading_s,
  heading_m,
  heading_l,
  text_m,
  text_s,
  buttonReset,
  safeFocusRemoval,
  focusOutline,
  svg,
  heading_xs,
  button
} from '_utils/styles';
import { spacing } from '_utils/props';
import { BackgroundColors } from '_utils/components/Corporate/Section';

export const RequestTrackerListItems = styled.div`
  ${({ theme }) => {
    return spacing({ theme, mt: 7 });
  }};
`;

export const RequestTrackerWrapper = styled.div`
  background-color: ${BackgroundColors.White};
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;

  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 4 })};
  }

  .request-tracker-card-document-name {
    color: inherit;

    &:hover {
      color: ${color('all.greenTint110')};
    }
  }
`;

const StyledDot = `
  & {
    position: relative;
    margin-right: 25px;
    margin-bottom: 8px;

    &:not(:first-child):after {
      ${mq('sm')} {
        content: '.';
        font-weight: bold;
        position: absolute;
        top: -5px;
        left: -13px;
      }
    }
  }
`;

// INFO: Card header
export const RequestTrackerHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${({ theme }) => spacing({ theme, pt: 3, px: 3, pb: 2 })};
  background-color: ${color('all.deepBlueTint03')};

  ${mq('md')} {
    flex-direction: row;
  }

  .request-tracker-metadata {
    float: left;
    ${StyledDot}
  }

  .request-tracker-status-tag {
    margin-left: -5px;
    margin-right: -5px;
  }
`;

export const RequestTrackerHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => spacing({ theme, mb: 1 })};

  ${mq('md')} {
    flex-grow: 1;
    ${({ theme }) => spacing({ theme, mb: 0, pr: 1 })};
  }
`;

export const RequestTrackerHeaderRight = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('md')} {
    align-items: flex-end;
    ${({ theme }) => spacing({ theme, pl: 1 })};
  }
`;

export const RequestTitle = styled.h3`
  ${heading_m}
  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
`;

export const RequestDescription = styled.div`
  overflow: hidden;
`;

export const RequestUpdatedTime = styled.div`
  font-family: Helvetica Now Micro;
  font-size: 10px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

// INFO: Card content
export const RequestTrackerContent = styled.div`
  ${({ theme }) => spacing({ theme, p: 3 })};

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const ActivityDescription = styled.div`
  ${heading_l}
  font-weight: normal;
`;

export const Divider = styled.hr`
  height: 1px;
  border: initial;
  background-color: ${color('all.deepBlueTint15')};
  ${({ theme }) => spacing({ theme, m: 0, my: 3 })};
`;

export const WhatYouNeedToDo = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const WhatYouNeedToDoTitle = styled.div`
  ${heading_s}
  ${({ theme, haveDescription }) => spacing({ theme, m: 0, mb: haveDescription ? 2 : 3 })};
`;

export const WhatYouNeedToDoDescription = styled.div`
  ${text_m}
  ${({ theme, haveActions }) => spacing({ theme, m: 0, mb: haveActions ? 3 : 0 })};

  &:last-child {
    ${({ theme }) => spacing({ theme, mb: 0 })};
  }

  p {
    ${({ theme }) => spacing({ theme, m: 0, mb: 2 })};

    ${mq.lessThan('md')} {
      ${({ theme }) => spacing({ theme, mb: 1 })};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ListActionContainer = styled.div`
  display: flex;

  ${mq.lessThan('md')} {
    flex-direction: column;
  }

  &.center {
    align-items: center;
    ${({ theme }) => spacing({ theme, mb: 1 })};
  }
`;

export const ActionContainer = styled.div`
  ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};

  &:first-child {
    ${({ theme }) => spacing({ theme, m: 0 })};
  }

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, m: 0, mt: 4 })};
    &.row {
      ${({ theme }) => spacing({ theme, m: 0 })};
      display: inline-block;
    }

    & ~ &.row {
      ${({ theme }) => spacing({ theme, ml: 2 })};
    }
  }
`;

const OutlineCss = css`
  outline: 2px solid #4e5a5f;
  outline-offset: -2px;
  color: #4e5a5f;
  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    path {
      fill: #4e5a5f;
    }
  }

  &:hover {
    outline-color: #000000;
    color: #000000;

    svg {
      path {
        fill: #000000;
      }
    }
  }

  ${mq('md')} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CallToAction = styled.button`
  ${({ buttonType }) => button('default', buttonType)};
  display: ${({ isExternal }) => isExternal ? 'inline': 'flex'};
  align-items: center;
  border: none;
  ${heading_s};
  ${({ theme }) => spacing({ theme, m: 0, px: 2, py: 1 })};

  &:hover {
    border: none;
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, py: '10px' })};
  }
  ${({ buttonType }) => (buttonType === 'outline' ? OutlineCss : null)};

  body:not(.safe-focus-removal) &:focus:before {
    content: none;
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -4px;
      top: -4px;
      content: '';
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
`;

export const SvgWrapper = styled.span`
  ${svg(20, 20)}
  ${({ theme }) => spacing({ theme, ml: 1 })};
  width: 24px;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  path {
    fill: #FFF;
  }
  ${mq.lessThan('md')} {
    width: 20px;
  }
`;

// INFO: Show Hide previous update
export const PastActivityWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};

  &.open {
    ${({ theme }) => spacing({ theme, mb: -1 })};
  }
`;

export const PastActivityToggle = styled.button`
  ${buttonReset}
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0 })};
  box-shadow: inset 0 -2px ${color('all.green')};
  display: flex;
  align-items: center;

  &:hover {
    background: ${color('all.green')};
    color: ${color('all.deepBlue')};

    svg {
      path {
        fill: ${color('all.deepBlue')};
      }
    }
  }

  &:focus-visible {
    ${safeFocusRemoval}
    ${focusOutline}
  }

  svg {
    path {
      fill: ${color('all.deepBlue')};
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Icon = styled.span`
  ${svg(20, 20)}
  width: 20px;
  margin-left: 4px;
`;

export const PastActivityListItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => spacing({ theme, pt: 3, mx: -3 })};

  ${mq('md')} {
    align-items: flex-start;
  }
`;

export const PastActivityItemWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  ${mq('md')} {
    flex-direction: row;
    align-items: center;
    background-color: transparent;
  }
`;

export const PastActivityStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 194px;
  margin-top: 4px;

  ${mq('md')} {
    position: relative;
    background-color: #ffffff;
    margin-bottom: 4px;
  }
`;

export const DashTimeline = styled.div`
  overflow: hidden;
  height: 16px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
    height: 22px;
    border-right: 2px dashed ${color('all.deepBlueTint45')};
  }
`;

export const PastActivityItem = styled.div`
  position: relative;
  width: 100%;
  ${({ theme }) => spacing({ theme, px: 3 })};

  &:nth-child(2n + 1) {
    background-color: #f9f9f9;
    ${PastActivityItemWrap} {
      background-color: #f9f9f9;
      ${mq('md')} {
        background-color: transparent;
      }
    }
    ${PastActivityStatusWrapper} {
      ${mq('md')} {
        background-color: #f9f9f9;
      }
    }
  }

  &:last-child {
    ${DashTimeline}:last-child {
      display: none;
    }
  }
`;

export const PastActivityDescription = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 0 })};
  text-align: center;

  ${mq('md')} {
    flex-grow: 1;
    text-align: left;
    padding-right: 12px;

    ${DashTimeline} {
      display: none;
    }
  }

  .activity-download-icon {
    ${mq.lessThan('md')} {
      ${({ theme }) => spacing({ theme, mr: 0 })};
    }
  }
`;

export const ActivityTitle = styled.div`
  ${text_m}
  ${({ theme, isMarginBottom }) => spacing({ theme, m: 0, mb: isMarginBottom ? 1 : 0 })};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: '4px', mb: 1 })};
  }
`;

export const PastActivityStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq('md')} {
    max-width: 210px;
    padding-left: 12px;
    align-items: flex-end;
  }

  ${mq.lessThan('md')} {
    & ${DashTimeline}:first-child {
      display: none;
    }
  }
`;

export const PastActivityUpdatedTime = styled.div`
  font-family: Helvetica Now Micro;
  font-size: 10px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 1 })};
`;

export const ButtonGroups = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;
