import React, { FC, useEffect, useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  Container,
  UpperBanner,
  UpperTitle,
  UpperDescription,
  UpperStatus,
  LowerBanner,
  LowerOptions,
  LowerQuestion,
  ContinueButton,
  ButtonWrapper,
  AddNewWrapper,
  LoadingContent,
  RefreshWrapper
} from './StyledProfile';
import SvgPlus from '_utils/icons/Profile/Plus';
import { Refresh } from '_utils/icons';
import SvgStopWatch from '_utils/icons/Profile/StopWatch';
import Questions from './Questions';
import Tab from './Tab';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import SimpleTab from './Tab/SimpleTab';
import { definition, intersection } from '_utils/helpers/profileHelper';
import { getRefreshToken } from '_services/genericService';
import {
  getCommunityEngagementProfiles,
  updateCommunityEngagementProfile
} from '_services/communityEngagementProfileService';
import {
  getConsultationSurveyUrl,
  getConsultationSocialPinpointUrl,
  getNextPageUrl
} from '_services/consultationsService';
import { defaultOptions, individualObject, organisationObject, itemState } from './definition';
import { DEFAULT_COUNTRY } from '_components/CityConnect/PersonalInfo/AutoCompleteAddress/definitions';

interface CommunityEngagementProfile {
  sitecoreContext: any;
}

const CommunityEngagementProfile: FC<CommunityEngagementProfile> = ({ sitecoreContext }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [continueUrl, setContinueUrl] = useState('');
  const [page, setPage] = useState('original');
  const [itemSelected, setItemSelected] = useState({} as any);
  const [isOpen, setIsOpen] = useState(false);
  const [itemDelete, setItemDelete] = useState('');
  const [hasIndividualProfile, setHasIndividualProfile] = useState(false);
  const { original, individual, organisation } = definition;
  const [error, setError] = useState(null);
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const surveyId = queryParams.get('survey');
  const socialpinpointId = queryParams.get('socialpinpoint');
  const nextPage = queryParams.get('next-page');

  const getContinueUrl = (value) => {
    if (surveyId) {
      return getConsultationSurveyUrl(surveyId, value);
    } else if (socialpinpointId) {
      return getConsultationSocialPinpointUrl(socialpinpointId, value);
    } else if (nextPage) {
      return getNextPageUrl(nextPage, value);
    }

    return '/404';
  };

  // set profiles sort order
  // - individual profile on top
  // - organisation profile sorted by name
  const setProfilesSorted = (profiles) => {
    const individualProfiles = profiles.filter((profile) => profile.type === 'Individual');
    const organisationProfiles = profiles
      .filter((profile) => profile.type === 'Organisation')
      .sort((a, b) => a.organisationName.localeCompare(b.organisationName));

    const sortedProfiles = [...individualProfiles, ...organisationProfiles];
    setItems(sortedProfiles);
  };

  const selectOption = (value: string) => {
    setSelectedValue(value);
    setContinueUrl(getContinueUrl(value));
  };

  const handleClickNext = (itemValue: any) => {
    if (items.length > 0) {
      const item = items.filter((item) => item.id === itemValue.id);
      // pls add check logic here
      setItemSelected(item[0]);
      setPage(itemValue.type);
    } else if (selectedValue) {
      setPage(selectedValue);
    }
  };

  const handleReturn = () => {
    setPage(original);
  };

  const handleDialog = (bol: boolean, itemValue: string) => {
    setIsOpen(bol);
    setItemDelete(itemValue);
  };

  const handleAddNewOrg = () => {
    setPage(organisation);
    setItemSelected({});
  };

  const handleAddNewIndividual = () => {
    setPage(individual);
    setItemSelected({});
  };

  const handleRemove = async () => {
    if (items) {
      const refreshToken = await getRefreshToken(
        location.pathname,
        sitecoreContext.language,
        history,
        location.pathname
      );
      const headers = {
        headers: {
          requestVerificationToken: refreshToken
        }
      };

      const newArr = items.filter((item) => item.id !== itemDelete);
      const dataToUpdate = {
        communityEngagementProfiles: newArr
      };
      updateCommunityEngagementProfile(
        dataToUpdate,
        (res) => {
          setProfilesSorted(res.data.communityEngagementProfiles);
          handleReturn();
        },
        (err) => setError(err),
        headers
      );

      if (itemSelected && itemSelected.id === itemDelete) {
        setItemSelected({});
      }
    }
    setIsOpen(false);
    handleReturn();
  };

  const handleUpdate = async (itemUpdate: itemState, type: string, action: string) => {
    const addressManualObject = {
      addressLine1: itemUpdate.addressLine1.trim(),
      suburb: itemUpdate.suburb.trim(),
      city: itemUpdate.city.trim(),
      zipOrPostalCode: itemUpdate.zipOrPostalCode.trim(),
      stateOrProvince: itemUpdate.stateOrProvince.trim(),
      country: itemUpdate.outsideOfAustralia ? itemUpdate.country : DEFAULT_COUNTRY.name
    };
    const addressSearchFinder = {
      addressLine1: itemUpdate.addressLine1 || '',
      addressLine2: itemUpdate.addressLine2 || '',
      addressLine3: itemUpdate.addressLine3 || '',
      suburb: itemUpdate.suburb || '',
      zipOrPostalCode: itemUpdate.zipOrPostalCode || '',
      stateOrProvince: itemUpdate.stateOrProvince || '',
      country: itemUpdate.country || ''
    };

    let updateFields =
      type === individual
        ? intersection(itemUpdate, individualObject, action)
        : (intersection(itemUpdate, organisationObject, action) as any);
    const filteredItem = items.filter((it) => it.id !== updateFields.id);
    if (itemUpdate.outsideOfAustralia) {
      updateFields = { ...updateFields, ...addressManualObject };
    } else {
      updateFields = { ...updateFields, ...addressSearchFinder };
    }

    filteredItem.push(updateFields);

    const dataToUpdate = {
      communityEngagementProfiles: filteredItem
    };
    const refreshToken = await getRefreshToken(
      location.pathname,
      sitecoreContext.language,
      history,
      location.pathname
    );
    const headers = {
      headers: {
        requestVerificationToken: refreshToken
      }
    };

    updateCommunityEngagementProfile(
      dataToUpdate,
      (res) => {
        setProfilesSorted(res.data.communityEngagementProfiles);

        // find the newly created profile details from response
        const newlyCreatedProfile =
          itemUpdate.type === organisation
            ? res.data.communityEngagementProfiles.find(
              (profile) =>
                profile.email === itemUpdate.email &&
                profile.organisationName === itemUpdate.organisationName
            )
            : res.data.communityEngagementProfiles.find(
              (profile) =>
                profile.email === itemUpdate.email && profile.suburb === itemUpdate.suburb
            );

        if (action === 'addNew' && newlyCreatedProfile?.id) {
          const url = getContinueUrl(newlyCreatedProfile.id);
          if (url) {
            window.location.href = url;
          } else {
            handleReturn();
          }
        } else {
          handleReturn();
        }
      },
      (err) => setError(err),
      headers
    );
  };

  useEffect(() => {
    getCommunityEngagementProfiles(
      (res) => {
        // set items
        setIsLoading(false);
        setProfilesSorted(res.data.communityEngagementProfiles);
      },
      (err) => setError(err)
    );
  }, []);

  useEffect(() => {
    if (items.length === 0) {
      setItemSelected({});
    }
    setHasIndividualProfile(items.filter((i) => i.type === individual).length > 0);
  }, [items]);

  const renderOption = () => {
    return (
      <>
        <LowerQuestion isEmpty={!items || items.length === 0}>
          Who will you be providing this feedback as?
        </LowerQuestion>
        {isLoading ? (
          <LoadingContent>
            {t('dashboard-loading-information')}
            <RefreshWrapper>
              <Refresh ariaHidden={false} />
            </RefreshWrapper>
          </LoadingContent>
        ) : (
          <>
            {items && items.length > 0 ? (
              <>
                <LowerOptions>
                  {items?.map((item) => (
                    <Tab
                      key={item.id}
                      item={item}
                      selectedValue={selectedValue}
                      handleChange={selectOption}
                      handleClickNext={handleClickNext}
                    />
                  ))}
                </LowerOptions>
                {!hasIndividualProfile && (
                  <ButtonWrapper>
                    <AddNewWrapper onClick={() => handleAddNewIndividual()}>
                      <SvgPlus />
                      Add individual details
                    </AddNewWrapper>
                  </ButtonWrapper>
                )}
                <ButtonWrapper>
                  <AddNewWrapper onClick={() => handleAddNewOrg()}>
                    <SvgPlus />
                    Add new organisation
                  </AddNewWrapper>
                </ButtonWrapper>
                <ButtonWrapper>
                  <ContinueButton href={continueUrl}>Continue</ContinueButton>
                </ButtonWrapper>
              </>
            ) : (
              <>
                <LowerOptions>
                  {defaultOptions.map((item, idx) => (
                    <SimpleTab
                      key={idx}
                      selectedValue={selectedValue}
                      handleChange={selectOption}
                      item={item}
                    />
                  ))}
                </LowerOptions>
                <ButtonWrapper>
                  <ContinueButton onClick={handleClickNext} disabled={!selectedValue}>
                    Continue
                  </ContinueButton>
                </ButtonWrapper>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const renderBanner = () => {
    return (
      <>
        {page === original ? (
          <>
            <UpperTitle>{t('community-engagement-profile-consultation-page-header')}</UpperTitle>
            {!items || items.length === 0 ? (
              <>
                <UpperDescription>
                  {t('community-engagement-profile-consultation-description')}
                </UpperDescription>
                <UpperStatus>
                  <SvgStopWatch />
                  About 30 seconds to complete
                </UpperStatus>
              </>
            ) : (
              <UpperDescription>
                {t('community-engagement-profile-consultation-description-return-user')}
              </UpperDescription>
            )}
          </>
        ) : (
          <>
            <UpperTitle>
              {t('community-engagement-profile-consultation-page-header-detail')}
            </UpperTitle>
            <UpperDescription>
              {t('community-engagement-profile-consultation-page-description-detail')}
            </UpperDescription>
          </>
        )}
      </>
    );
  };

  const renderConsultation = () => {
    switch (page) {
      case original:
        return renderOption();
      case individual:
        return (
          <Questions
            handleChange={handleReturn}
            item={itemSelected}
            handleDialog={handleDialog}
            type={individual}
            handleUpdate={handleUpdate}
            surveyId={surveyId}
          />
        );
      case organisation:
        return (
          <Questions
            handleChange={handleReturn}
            item={itemSelected}
            handleDialog={handleDialog}
            type={organisation}
            handleUpdate={handleUpdate}
            surveyId={surveyId}
          />
        );
    }
  };

  return (
    <Container>
      <UpperBanner>{renderBanner()}</UpperBanner>
      <LowerBanner>{renderConsultation()}</LowerBanner>
      <DialogueBox
        translationKeys={{
          title: 'community-engagement-profile-delete-title',
          description: 'community-engagement-profile-delete-description',
          cancel: 'community-engagement-profile-delete-no',
          apply: 'community-engagement-profile-delete-yes'
        }}
        type="warning"
        actionCallbackFn={() => {
          handleRemove();
        }}
        cancelCallbackFn={() => {
          setIsOpen(false);
        }}
        isFixed={false}
        isOpen={isOpen}
      />
    </Container>
  );
};

export default compose(withSitecoreContext())(CommunityEngagementProfile);
