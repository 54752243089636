import React from 'react';
import { withSvg } from '../Svg';

const SvgResidential = () => (
  <path
    d="M18.05 8.54173L10.55 1.87506C10.3979 1.74146 10.2024 1.66779 10 1.66779C9.7976 1.66779 9.60209 1.74146 9.45002 1.87506L1.95002 8.54173C1.82255 8.65449 1.73266 8.80358 1.69243 8.96894C1.65221 9.13431 1.66358 9.30802 1.72502 9.46673C1.78545 9.62335 1.89177 9.75806 2.03006 9.85322C2.16835 9.94839 2.33215 9.99957 2.50002 10.0001H3.33335V17.5001C3.33335 17.7211 3.42115 17.933 3.57743 18.0893C3.73371 18.2456 3.94567 18.3334 4.16668 18.3334H15.8334C16.0544 18.3334 16.2663 18.2456 16.4226 18.0893C16.5789 17.933 16.6667 17.7211 16.6667 17.5001V10.0001H17.5C17.6679 9.99957 17.8317 9.94839 17.97 9.85322C18.1083 9.75806 18.2146 9.62335 18.275 9.46673C18.3365 9.30802 18.3478 9.13431 18.3076 8.96894C18.2674 8.80358 18.1775 8.65449 18.05 8.54173V8.54173ZM10.8334 16.6667H9.16668V14.1667C9.16668 13.9457 9.25448 13.7338 9.41076 13.5775C9.56704 13.4212 9.779 13.3334 10 13.3334C10.221 13.3334 10.433 13.4212 10.5893 13.5775C10.7456 13.7338 10.8334 13.9457 10.8334 14.1667V16.6667ZM15 16.6667H12.5V14.1667C12.5 13.5037 12.2366 12.8678 11.7678 12.399C11.2989 11.9301 10.6631 11.6667 10 11.6667C9.33698 11.6667 8.70109 11.9301 8.23225 12.399C7.76341 12.8678 7.50002 13.5037 7.50002 14.1667V16.6667H5.00002V10.0001H15V16.6667ZM4.69168 8.3334L10 3.61673L15.3084 8.3334H4.69168Z"
    fill="#4E5A5F"
  />
);

export default withSvg(20, 20)(SvgResidential);
