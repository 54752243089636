import React from 'react';
import { withSvg } from '../Svg';

const SvgBan = () => (
  <path
    d="M12 2C10.0222 2 8.08879 2.58649 6.44429 3.6853C4.7998 4.78412 3.51808 6.3459 2.7612 8.17317C2.00433 10.0004 1.80629 12.0111 2.19214 13.9509C2.578 15.8907 3.5304 17.6725 4.92893 19.0711C6.32745 20.4696 8.10928 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9996 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84343 19.1571 6.34314 17.6569C4.84285 16.1566 4 14.1217 4 12C3.99779 10.2233 4.59301 8.49755 5.69 7.1L16.9 18.31C15.5025 19.407 13.7767 20.0022 12 20ZM18.31 16.9L7.1 5.69C8.49754 4.59302 10.2233 3.99779 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20.0022 13.7767 19.407 15.5025 18.31 16.9Z"
    fill="black"
  />
);

export default withSvg(24, 24)(SvgBan);
