import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import WhatYouNeedToDoCard from '_components/CityConnect/RequestTracker/WhatYouNeedToDoCard';
import PermitStatusCard from './PermitStatusCard';
import {
  CallToActionContainer,
  ActionContainer,
  CallToAction
} from './PermitStatusCard/StyledPermitStatusCard';
import SvgBan from '_utils/icons/common/Ban';
import { getRegistrationPageData } from '_services/registrationPageService';
import { PermitListItems, RegistrationPageWrapper, Section } from './StyledPermit';
import ProfileCardList from '../RequestTracker/ProfileCardList';
import { Grid } from '_utils/components/Corporate';
import DialogueBox from '_utils/components/CityConnect/DialogueBox';
import TextMessage from '../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { SvgWrapper } from '_components/CityConnect/RequestTracker/RequestTrackerCard/StyledRequestTrackerCard';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

export const ACTION_LIST = {
  cancelApplication: 'cancel-application',
  redirect: 'redirect',
  downloadPdf: 'download-pdf',
  redirectExternalLink: 'redirect-external-link'
};

export type TAction = {
  type: string;
  text: string;
  link: string;
};

export const TRANSLATION_KEYS_SET = {
  title: 'registration-details-dialogue-box-edit-booking-title',
  description: 'registration-details-dialogue-box-edit-booking-description',
  cancel: 'registration-details-dialogue-box-cancel',
  apply: 'registration-details-dialogue-box-cancel-permit'
};

const Registration: React.FC = () => {
  const location = useLocation();
  const [permitData, setPermitData] = useState(null);
  const [error, setError] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dialogCallbackUrl, setDialogCallbackUrl] = useState<string>();
  const permitId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const registrationActions = permitData?.registration?.actions;

  const renderPermitStatusCard = (listItems: any[]) => {
    return (
      listItems && listItems.map((item, index) => <PermitStatusCard key={index} data={item} />)
    );
  };

  useEffect(() => {
    getRegistrationPageData(
      permitId,
      (res) => setPermitData(res?.data),
      (err) => setError(err)
    );
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const handleClickAction = (action: TAction) => {
    if (action.type === ACTION_LIST.cancelApplication) {
      setIsOpenDialog(true);
      setDialogCallbackUrl(action.link);
    } else if(action.type === ACTION_LIST.redirectExternalLink) {
      window.open(action.link, '_blank');
    } else {
      window.open(action.link, '_self');
    }
  };

  const confirmAction = () => {
    setIsOpenDialog(false);
    window.open(dialogCallbackUrl, '_self');
  };

  return (
    <RegistrationPageWrapper>
      <Section>
        <Grid.Row config={{ gutters: ['left', 'right'] }}>
          <Grid.Item
            config={{ col: { xs: 12, md: 8, lg: 8 }, gutters: ['left', 'right'] }}
            mb={{ xs: 2, md: 0 }}
          >
            {permitData && <WhatYouNeedToDoCard data={permitData?.registration?.callout} />}
          </Grid.Item>
          <Grid.Item config={{ col: { xs: 12, md: 4, lg: 4 }, gutters: ['left', 'right'] }}>
            <div className="RegistrationPage__RightComponent">
              {permitData && <ProfileCardList data={permitData?.registration?.help} />}
              <CallToActionContainer>
                {registrationActions &&
                  registrationActions.map((action: TAction, index: number) => (
                    <ActionContainer key={index}>
                      <CallToAction
                        isExternal={action.type === ACTION_LIST.redirectExternalLink}
                        buttonType={
                          action.type === ACTION_LIST.cancelApplication ? 'outline' : 'primary'
                        }
                        onClick={() => handleClickAction(action)}
                      >
                        {action.type === ACTION_LIST.cancelApplication && <SvgBan />}
                        {action.text}
                        {action.type === ACTION_LIST.redirectExternalLink && (
                          <SvgWrapper>
                            <SvgExternal />
                          </SvgWrapper>
                        )}
                      </CallToAction>
                    </ActionContainer>
                  ))}
                {registrationActions &&
                  registrationActions.find(
                    (action) => action.type === ACTION_LIST.cancelApplication
                  ) && (
                    <DialogueBox
                      translationKeys={TRANSLATION_KEYS_SET}
                      type="ban"
                      actionCallbackFn={confirmAction}
                      cancelCallbackFn={() => setIsOpenDialog(false)}
                      isOpen={isOpenDialog}
                    />
                  )}
              </CallToActionContainer>
            </div>
          </Grid.Item>
        </Grid.Row>
      </Section>
      <Grid.Row config={{ gutters: ['left', 'right'] }}>
        <Grid.Item config={{ col: { xs: 12 }, gutters: ['left', 'right'] }}>
          <PermitListItems>
            {renderPermitStatusCard(permitData?.registration?.items)}
          </PermitListItems>
        </Grid.Item>
      </Grid.Row>
    </RegistrationPageWrapper>
  );
};

const RegistrationFallback: React.FC = () => {
  const [t] = useTranslation();
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return <TextMessage fields={errorMessage} />;
};

export default withErrorBoundary(Registration, RegistrationFallback);
