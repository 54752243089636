import React from 'react';
import {withSvg} from "../Svg";

const SvgCornerDownRight = () => (
    <>
        <g clip-path="url(#clip0_5734_3633)">
            <path d="M17.4333 10.5167C17.3936 10.4144 17.3342 10.3209 17.2583 10.2417L14.7583 7.74167C14.6014 7.58475 14.3885 7.49659 14.1666 7.49659C13.9447 7.49659 13.7319 7.58475 13.575 7.74167C13.418 7.89859 13.3299 8.11142 13.3299 8.33333C13.3299 8.55525 13.418 8.76808 13.575 8.925L14.6583 10H6.66663C6.44561 10 6.23365 9.9122 6.07737 9.75592C5.92109 9.59964 5.83329 9.38768 5.83329 9.16667V5.83333C5.83329 5.61232 5.74549 5.40036 5.58921 5.24408C5.43293 5.0878 5.22097 5 4.99996 5C4.77895 5 4.56698 5.0878 4.4107 5.24408C4.25442 5.40036 4.16663 5.61232 4.16663 5.83333V9.16667C4.16663 9.82971 4.43002 10.4656 4.89886 10.9344C5.3677 11.4033 6.00358 11.6667 6.66663 11.6667H14.6583L13.575 12.7417C13.4969 12.8191 13.4349 12.9113 13.3925 13.0129C13.3502 13.1144 13.3285 13.2233 13.3285 13.3333C13.3285 13.4433 13.3502 13.5523 13.3925 13.6538C13.4349 13.7554 13.4969 13.8475 13.575 13.925C13.6524 14.0031 13.7446 14.0651 13.8461 14.1074C13.9477 14.1497 14.0566 14.1715 14.1666 14.1715C14.2766 14.1715 14.3856 14.1497 14.4871 14.1074C14.5887 14.0651 14.6808 14.0031 14.7583 13.925L17.2583 11.425C17.3342 11.3457 17.3936 11.2523 17.4333 11.15C17.5166 10.9471 17.5166 10.7196 17.4333 10.5167Z" fill="#4E5A5F"/>
        </g>
        <defs>
            <clipPath id="clip0_5734_3633">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </>
);

export default withSvg(20, 20)(SvgCornerDownRight);