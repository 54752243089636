import React from 'react';
import { LinkButtonIconType } from '../../../helpers/linkButtonIconType';
import {
  LinkButtonChart,
  LinkButtonCommentExclaim,
  LinkButtonClipBoard,
  LinkButtonPin
} from '_utils/icons';

const icons = {
  [LinkButtonIconType.chart]: <LinkButtonChart />,
  [LinkButtonIconType.clipboard]: <LinkButtonClipBoard />,
  [LinkButtonIconType.commentExclaim]: <LinkButtonCommentExclaim />,
  [LinkButtonIconType.pin]: <LinkButtonPin />
};

export default function LinkButtonIcon({ status }) {
  if (icons[status]) {
    return icons[status];
  }

  return null;
}
