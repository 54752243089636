import React, { createContext, useState, useEffect } from 'react';
import { usePrevious } from '_utils/hooks';
import { Optionalize } from '_utils/types';
import { ChildrenPropTypes } from '_types';

export interface MenuContextProps {
  menuActive: boolean;
  setMenuActive: (active: boolean) => void;
}

const MenuContext = createContext({
  menuActive: false,
  setMenuActive: null
});

function MenuProvider({ children }) {
  // state
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const prevMenuActive = usePrevious(menuActive);

  useEffect(() => {
    if (menuActive && !prevMenuActive) {
      document.body.classList.add('menu-active');
    } else if (!menuActive && prevMenuActive) {
      document.body.classList.remove('menu-active');
    }

    return () => {
      document.body.classList.remove('menu-active');
    };
  }, [menuActive, prevMenuActive]);

  return (
    <MenuContext.Provider
      value={{
        menuActive,
        setMenuActive
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

MenuProvider.propTypes = {
  children: ChildrenPropTypes
};

export default MenuProvider;

export const MenuConsumer = MenuContext.Consumer;

export function withMenuContext<T extends MenuContextProps = MenuContextProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withMenuContext: React.FC<Optionalize<T, MenuContextProps>> = (props) => {
    return (
      <MenuContext.Consumer>
        {(value) => <WrappedComponent {...value} {...(props as T)} />}
      </MenuContext.Consumer>
    );
  };

  withMenuContext.displayName = `withMenuContext(${displayName})`;

  return withMenuContext;
}
