import React from 'react';
import { useTranslation } from 'react-i18next';
// INFO: status tag icon
import SvgDanger from '_utils/icons/Danger';
import SvgApproved from '_utils/icons/StatusTag/Approved';
import SvgCollection from '_utils/icons/StatusTag/Collection';
import SvgDeclined from '_utils/icons/common/Declined';
import SvgInProgress from '_utils/icons/StatusTag/InProgress';
import SvgPaymentCard from '_utils/icons/ScheduledPayments/PaymentCard';
import SvgPencil from '_utils/icons/StatusTag/Pencil';
import SvgSchedule from '_utils/icons/StatusTag/Schedule';
import SvgResidential from '_utils/icons/StatusTag/Residential';
import SvgWarningTriangle from '_utils/icons/metadata/WarningTriangle';
import SvgMail from '_utils/icons/StatusTag/Mail';
import SvgPaymentProcessing from '_utils/icons/PaymentsDueList/Processing';
import SvgRecycle from '_utils/icons/ScheduledPayments/Recycle';
import { Icon, StyledStatusTag, Text } from './StyledStatusTag';
import { UnderReview, OpenForFeedback, InProgress, ConsultationsPlanned, Closed, OnExhibition, ReportingBack } from "../../../icons";

type StatusTagProps = {
  text: string;
  displayStatusText?: string;
  isConsultation?: boolean;
};

const STATUS_COLOR = {
  DEFAULT: '#F9F9F9',
  BLACK: '#4E5A5F',
  YELLOW: '#FEFADD',
  ORANGE: '#FFE6D7',
  LIGHT_PURPLE: '#F8DDE6',
  BLUE: '#E0F6FD',
  PURPLE: '#E8DAF1',
  GREEN: '#DAF5E4',
  COMPLETED: '#F0F1F2',
  RED: '#FFD8D9',
  TRANSPARENT: 'transparent'
};

const TEXT_COLOR = {
  DEFAULT: '#4E5A5F',
  WHITE: '#FFF',
  YELLOW: '#7A570E',
  ORANGE: '#B23914',
  LIGHT_PURPLE: '#821846',
  BLUE: '#005084',
  PURPLE: '#37266A',
  GREEN: '#157932',
  GREEN_TEXT: '#188838',
  RED: '#B00020',
  PINK: '#DA1E5B',
};

export const STATUS_CONFIGS = {
  draft: {
    text: 'draft',
    color: TEXT_COLOR.DEFAULT,
    backgroundColor: STATUS_COLOR.DEFAULT,
    icon: <SvgPencil />
  },
  expired: {
    text: 'expired',
    color: TEXT_COLOR.WHITE,
    backgroundColor: STATUS_COLOR.BLACK,
    icon: <SvgDeclined />
  },
  cancelled: {
    text: 'cancelled',
    color: TEXT_COLOR.WHITE,
    backgroundColor: STATUS_COLOR.BLACK,
    icon: <SvgDeclined />
  },
  closed: {
    text: 'closed',
    color: TEXT_COLOR.WHITE,
    backgroundColor: STATUS_COLOR.BLACK,
    icon: <SvgDeclined />
  },
  duplicate: {
    text: 'duplicate',
    color: TEXT_COLOR.WHITE,
    backgroundColor: STATUS_COLOR.BLACK,
    icon: <SvgDeclined />
  },
  submitted: {
    text: 'submitted',
    color: TEXT_COLOR.YELLOW,
    backgroundColor: STATUS_COLOR.YELLOW,
    icon: <SvgApproved />
  },
  processing: {
    text: 'processing',
    color: TEXT_COLOR.YELLOW,
    backgroundColor: STATUS_COLOR.YELLOW,
    icon: <SvgInProgress />
  },
  scheduled: {
    text: 'scheduled',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgSchedule />
  },
  rescheduled: {
    text: 'rescheduled',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgSchedule />
  },
  'assessment-scheduled': {
    text: 'assessment-scheduled',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgSchedule />
  },
  'in-review': {
    text: 'in-review',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgInProgress />
  },
  'in-progress': {
    text: 'in-progress',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgInProgress />
  },
  'awaiting-payment': {
    text: 'awaiting-payment',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgPaymentCard />
  },
  'ready-for-payment': {
    text: 'ready-for-payment',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgPaymentCard />
  },
  'to-be-rescheduled': {
    text: 'to-be-rescheduled',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgInProgress />
  },
  pending: {
    text: 'pending',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgInProgress />
  },
  'cancellation-requested': {
    text: 'cancellation-requested',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgRecycle />
  },
  approved: {
    text: 'approved',
    color: TEXT_COLOR.BLUE,
    backgroundColor: STATUS_COLOR.BLUE,
    icon: <SvgApproved />
  },
  'ready-for-collection': {
    text: 'ready-for-collection',
    color: TEXT_COLOR.BLUE,
    backgroundColor: STATUS_COLOR.BLUE,
    icon: <SvgCollection />
  },
  paid: {
    text: 'paid',
    color: TEXT_COLOR.PURPLE,
    backgroundColor: STATUS_COLOR.PURPLE,
    icon: <SvgApproved />
  },
  'payment-received': {
    text: 'payment-received',
    color: TEXT_COLOR.PURPLE,
    backgroundColor: STATUS_COLOR.PURPLE,
    icon: <SvgApproved />
  },
  'payment-processing': {
    text: 'processing',
    color: TEXT_COLOR.YELLOW,
    backgroundColor: STATUS_COLOR.YELLOW,
    icon: <SvgPaymentProcessing />
  },
  completed: {
    text: 'completed',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.GREEN,
    icon: <SvgApproved />
  },
  'report-received': {
    text: 'report-received',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.GREEN,
    icon: <SvgApproved />
  },
  received: {
    text: 'received',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.GREEN,
    icon: <SvgApproved />
  },
  active: {
    text: 'active',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.GREEN,
    icon: <SvgApproved />
  },
  declined: {
    text: 'declined',
    color: TEXT_COLOR.RED,
    backgroundColor: STATUS_COLOR.RED,
    icon: <SvgDeclined />
  },
  'could-not-complete': {
    text: 'could-not-complete',
    color: TEXT_COLOR.RED,
    backgroundColor: STATUS_COLOR.RED,
    icon: <SvgDanger />
  },
  'on-hold': {
    text: 'on-hold',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgDanger />
  },
  overdue: {
    text: 'overdue',
    color: TEXT_COLOR.RED,
    backgroundColor: STATUS_COLOR.RED,
    icon: <SvgDanger />
  },
  due: {
    text: 'due',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgDanger />
  },
  'expiring-soon': {
    text: 'expiring-soon',
    color: TEXT_COLOR.RED,
    backgroundColor: STATUS_COLOR.RED,
    icon: <SvgDanger />
  },
  saved: {
    text: 'saved',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.TRANSPARENT,
    icon: <SvgApproved />
  },
  missed: {
    text: 'missed',
    color: TEXT_COLOR.RED,
    backgroundColor: STATUS_COLOR.RED,
    icon: <SvgDanger />
  },
  reported: {
    text: 'reported',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgWarningTriangle />
  },
  'status-unknown': {
    text: 'status-unknown',
    color: TEXT_COLOR.DEFAULT,
    backgroundColor: STATUS_COLOR.DEFAULT,
    icon: <SvgInProgress />
  },
  'awaiting-information': {
    text: 'awaiting-information',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <SvgInProgress />
  },
  escalated: {
    text: 'escalated',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgWarningTriangle />
  },
  'pending-sale': {
    text: 'pending-sale',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgResidential />
  },
  suspended: {
    text: 'suspended',
    color: TEXT_COLOR.LIGHT_PURPLE,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <SvgDeclined />
  },
  mailed: {
    text: 'mailed',
    color: TEXT_COLOR.BLUE,
    backgroundColor: STATUS_COLOR.BLUE,
    icon: <SvgMail />
  },
  'information-received': {
    text: 'information-received',
    color: TEXT_COLOR.PURPLE,
    backgroundColor: STATUS_COLOR.PURPLE,
    icon: <SvgApproved />
  },
  'information-provided': {
    text: 'information-provided',
    color: TEXT_COLOR.PURPLE,
    backgroundColor: STATUS_COLOR.PURPLE,
    icon: <SvgApproved />
  },
  attempted: {
    text: 'attempted',
    color: TEXT_COLOR.RED,
    backgroundColor: STATUS_COLOR.RED,
    icon: <SvgDanger />
  }
};


export const STATUS_CONSULTATION_CONFIGS = {
  'open-for-feedback': {
    text: 'open-for-feedback',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.GREEN,
    icon: <OpenForFeedback color={TEXT_COLOR.GREEN_TEXT}/>
  },
  closed: {
    text: 'closed',
    color: TEXT_COLOR.PINK,
    backgroundColor: STATUS_COLOR.LIGHT_PURPLE,
    icon: <Closed color={TEXT_COLOR.PINK}/>
  },
  completed: {
    text: 'completed',
    color: TEXT_COLOR.GREEN,
    backgroundColor: STATUS_COLOR.GREEN,
    icon: <SvgApproved />
  },
  'under-review': {
    text: 'under-review',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <UnderReview />
  },
  planned: {
    text: 'planned',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <ConsultationsPlanned />
  },
  'in-progress': {
    text: 'in-progress',
    color: TEXT_COLOR.ORANGE,
    backgroundColor: STATUS_COLOR.ORANGE,
    icon: <InProgress color={TEXT_COLOR.ORANGE}/>
  },
  'reporting-back': {
    text: 'reporting-back',
    color: TEXT_COLOR.BLUE,
    backgroundColor: STATUS_COLOR.BLUE,
    icon: <ReportingBack />
  },
  'on-exhibition': {
    text: 'on-exhibition',
    color: TEXT_COLOR.BLUE,
    backgroundColor: STATUS_COLOR.BLUE,
    icon: <OnExhibition />
  }
};

const StatusTag: React.FC<StatusTagProps> = ({ text, displayStatusText, isConsultation = false }) => {
  const [t] = useTranslation();
  const statusText = text?.toLowerCase();
  const STATUS_TAGS = isConsultation ? STATUS_CONSULTATION_CONFIGS : STATUS_CONFIGS;
  return (
    <StyledStatusTag
      type={{
        backgroundColor: STATUS_TAGS[statusText]
          ? STATUS_TAGS[statusText]?.backgroundColor
          : STATUS_TAGS['draft']?.backgroundColor,
        textColor: STATUS_TAGS[statusText]
          ? STATUS_TAGS[statusText]?.color
          : STATUS_TAGS['draft']?.color
      }}
    >
      <Icon>
        {STATUS_TAGS[statusText]
          ? STATUS_TAGS[statusText]?.icon
          : STATUS_TAGS['draft']?.icon}
      </Icon>
      <Text>{displayStatusText ? displayStatusText : t(`dashboard-status-${statusText}`)}</Text>
    </StyledStatusTag>
  );
};

export default StatusTag;
