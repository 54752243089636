import React from 'react';
import { withSvg } from '../Svg';
import colors from '_containers/Theme/colors';

const Zip = () => (
  <React.Fragment>
    <path
      fill={colors.newtownFuchsiaTint110}
      d="M10.7005 12.8427H11.3728C11.5197 12.8427 11.644 12.809 11.7344 12.7303C11.8248 12.6517 11.8757 12.5393 11.8757 12.3933C11.8757 12.2472 11.8248 12.1404 11.7344 12.0618C11.644 11.9831 11.5197 11.9438 11.3728 11.9438H10.7005V12.8427Z"
    />
    <path
      fill={colors.newtownFuchsiaTint110}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.94 6.67C15.9695 6.75763 15.9896 6.84813 16 6.94V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H9.05C9.16622 0.0143442 9.27841 0.0517412 9.38 0.11C9.40985 0.10576 9.44015 0.10576 9.47 0.11C9.57282 0.158081 9.66734 0.222216 9.75 0.3L15.75 6.3C15.8278 6.38267 15.8919 6.47718 15.94 6.58V6.67ZM12.59 6L10 3.41V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H12.59ZM13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17V16H2V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071ZM2 10V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8V5C8 5.79565 8.31607 6.55871 8.87868 7.12132C9.44129 7.68393 10.2044 8 11 8H14V10H2ZM6.64417 14L5.47463 14.0112C5.75148 13.7191 6.00573 13.4438 6.23173 13.1854L7.42386 11.8539V11H4.0565V12H4.77404L5.94358 11.9888C5.66108 12.2865 5.41248 12.5618 5.18649 12.8146L4 14.1348V15H7.45211V14H6.64417ZM7.87661 15H9.0066V11H7.87661V15ZM9.57049 11H11.4576C11.9322 11 12.3107 11.1292 12.5876 11.382C12.8588 11.6348 13 11.9719 13 12.3933C13 12.8202 12.8588 13.1573 12.5876 13.4101C12.3107 13.6629 11.9322 13.7865 11.4576 13.7865H10.7005V15H9.57049V11Z"
    />
  </React.Fragment>
);

export default withSvg(16, 20)(Zip);
