import React from 'react';

import { SectionWrapper } from './StyledSection';

export enum BackgroundColors {
  White = 'white',
  Grey = '',
  'Light beige' = 'beige',
  Lavender = 'lavender'
}

type SectionProps = {
  className?: string;
  children: React.ReactNode;
  sectionTheme?: BackgroundColors;
};

const Section: React.FC<SectionProps> = ({ className, children, sectionTheme, ...rest }) => {
  return (
    <SectionWrapper
      className={`${className} ${sectionTheme}`}
      sectionTheme={sectionTheme}
      {...rest}
    >
      {children}
    </SectionWrapper>
  );
};

Section.defaultProps = {
  sectionTheme: BackgroundColors.White
};

export default Section;
