import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardWrapper,
  CardSection,
  DetailSection,
  DetailItem,
  CardTitle,
  CardDescription
} from './StyledPersonalDetailsCard';
import { PersonalInfoContext } from '..';
import NavItem from '_components/Corporate/NavItem';
import AutoCompleteAddress from '../AutoCompleteAddress';
import TextMessage from '../../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { transformAddress } from '_utils/helpers/personalInfohelper';

type PersonalDetailsCardProps = {
  datasource: any;
};

const PersonalDetailsCard: React.FC<PersonalDetailsCardProps> = ({ datasource }) => {
  const [t] = useTranslation();
  const { error, personalInfo } = useContext(PersonalInfoContext);
  const { name, residentialAddress } = personalInfo || {};

  // Get fields jss value
  const personalDetailsTitle = datasource?.personalDetailsTitle?.jss?.value;
  const personalDetailsDescription = datasource?.personalDetailsDescription?.jss?.value;
  const nameEditLink = datasource?.nameEditLink?.jss?.value;

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return (
    <CardWrapper>
      {personalDetailsTitle && <CardTitle>{personalDetailsTitle}</CardTitle>}
      {personalDetailsDescription && (
        <CardDescription
          dangerouslySetInnerHTML={{ __html: personalDetailsDescription }}
        ></CardDescription>
      )}
      <CardSection>
        <DetailSection>
          {/* INFO: Name */}
          <DetailItem.Wrapper>
            <DetailItem.Text>{t(`personal-info-name`)}</DetailItem.Text>
            <div className="description-block">
              <DetailItem.Description className={!name ? 'italic' : ''}>
                {name || 'Not supplied'}
              </DetailItem.Description>
              {/* INFO: Get Name Edit link from fields */}
              {nameEditLink && (
                <NavItem
                  link={{
                    url: nameEditLink.href,
                    type: nameEditLink.linktype,
                    target: nameEditLink.target
                  }}
                  StyledLink={DetailItem.EditButton}
                  description={nameEditLink?.text}
                >
                  {nameEditLink.text}
                </NavItem>
              )}
            </div>
          </DetailItem.Wrapper>
          {/* INFO: Residential Address */}
          <AutoCompleteAddress
            inputLabelDictionary="personal-info-residential-address"
            inputName="residentialAddress"
            inputValue={transformAddress(residentialAddress)}
          />
        </DetailSection>
      </CardSection>
    </CardWrapper>
  );
};

const PersonalDetailsCardFallback: React.FC<PersonalDetailsCardProps> = ({ datasource }) => {
  const [t] = useTranslation();
  const personalDetailsTitle = datasource?.personalDetailsTitle?.jss?.value;
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <CardWrapper>
      {personalDetailsTitle && <CardTitle>{personalDetailsTitle}</CardTitle>}
      <TextMessage className="removeStyle" fields={errorMessage} />
    </CardWrapper>
  );
};
export default withErrorBoundary(PersonalDetailsCard, PersonalDetailsCardFallback);
