import React from 'react';

export const SvgRequest = () => (
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.279 0.25C8.96567 0.25 7.66521 0.508679 6.45185 1.01127C5.2385 1.51385 4.13602 2.25051 3.20736 3.17917C1.33184 5.05468 0.278189 7.59843 0.278189 10.2508C0.269446 12.5601 1.06904 14.7997 2.53837 16.5813L0.53821 18.5815C0.399441 18.7221 0.305437 18.9007 0.26806 19.0947C0.230683 19.2887 0.251607 19.4894 0.328193 19.6716C0.411257 19.8515 0.545914 20.0027 0.715071 20.106C0.884227 20.2093 1.08025 20.26 1.27827 20.2516H10.279C12.9314 20.2516 15.4751 19.198 17.3506 17.3224C19.2262 15.4469 20.2798 12.9032 20.2798 10.2508C20.2798 7.59843 19.2262 5.05468 17.3506 3.17917C15.4751 1.30365 12.9314 0.25 10.279 0.25ZM10.279 18.2515H3.68846L4.61854 17.3214C4.8048 17.134 4.90936 16.8805 4.90936 16.6163C4.90936 16.3521 4.8048 16.0986 4.61854 15.9113C3.30902 14.6032 2.49354 12.8816 2.31104 11.0397C2.12855 9.19777 2.59032 7.34958 3.61769 5.80997C4.64505 4.27036 6.17446 3.13459 7.94532 2.59616C9.71619 2.05773 11.619 2.14996 13.3295 2.85712C15.04 3.56429 16.4523 4.84265 17.326 6.47439C18.1997 8.10614 18.4805 9.99033 18.1208 11.8059C17.761 13.6216 16.7828 15.2563 15.3529 16.4315C13.9231 17.6068 12.1299 18.25 10.279 18.2515ZM13.2792 9.25073H11.2791V7.25057C11.2791 6.98533 11.1737 6.73095 10.9862 6.5434C10.7986 6.35585 10.5442 6.25048 10.279 6.25048C10.0138 6.25048 9.75938 6.35585 9.57183 6.5434C9.38428 6.73095 9.27892 6.98533 9.27892 7.25057V9.25073H7.27875C7.01352 9.25073 6.75914 9.35609 6.57159 9.54364C6.38404 9.7312 6.27867 9.98557 6.27867 10.2508C6.27867 10.516 6.38404 10.7704 6.57159 10.958C6.75914 11.1455 7.01352 11.2509 7.27875 11.2509H9.27892V13.2511C9.27892 13.5163 9.38428 13.7707 9.57183 13.9582C9.75938 14.1458 10.0138 14.2511 10.279 14.2511C10.5442 14.2511 10.7986 14.1458 10.9862 13.9582C11.1737 13.7707 11.2791 13.5163 11.2791 13.2511V11.2509H13.2792C13.5445 11.2509 13.7989 11.1455 13.9864 10.958C14.174 10.7704 14.2793 10.516 14.2793 10.2508C14.2793 9.98557 14.174 9.7312 13.9864 9.54364C13.7989 9.35609 13.5445 9.25073 13.2792 9.25073Z"
      fill="white"
    />
  </svg>
);
