import React from 'react';
import moment from 'moment-mini';
import { useTranslation } from 'react-i18next';

import Checkbox from '_utils/components/Corporate/Checkbox';
import { Grid } from '_utils/components/Corporate';
import NavItem from '_components/Corporate/NavItem';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import { IPaymentDueProps } from './definitions';
// import styled
import {
  PaymentDueWrapper,
  PD_DateStatusTagContainer,
  PD_StatusTagContainer,
  PD_Date,
  PD_TypeDescriptionContainer,
  PD_Type,
  PD_Description,
  PD_AmountContainer,
  PDL_CheckboxContainer,
  PD_Amount,
  PD_AmountWithCheckBox
} from './StyledPaymentDue';

const PAYMENT_TYPE = {
  ASSESSMENT: 'assessment',
  INVOICE: 'invoice'
};

const PaymentDue: React.FC<IPaymentDueProps> = ({ payment, listIndex, isReadOnly, onSelect }) => {
  const [t] = useTranslation();
  const isDisabled = !isReadOnly ? payment.status.toLowerCase() === 'processing' : undefined;

  const renderReferenceTextByNumAndType = (refNo: string, refType: string) => {
    let prefix = '';
    switch (refType.toLowerCase()) {
      case PAYMENT_TYPE.ASSESSMENT.toLowerCase():
        prefix = t('payments-due-assessment-number');
        break;
      case PAYMENT_TYPE.INVOICE.toLowerCase():
        prefix = t('payments-due-invoice-number');
        break;
      default:
        prefix = t('payments-due-reference-number');
        break;
    }
    return `${prefix} ${refNo}`;
  };

  const handleOnToggleSelect = () => {
    if (!isDisabled) {
      onSelect(listIndex, !payment.isSelected);
    }
  };

  return (
    <PaymentDueWrapper>
      <Grid.Row config={{ gutters: ['left', 'right'] }}>
        <Grid.Item config={{ col: { xs: 12, md: 3 }, gutters: ['left', 'right'] }}>
          <PD_DateStatusTagContainer>
            {!isReadOnly && payment.status && (
              <PD_StatusTagContainer>
                <StatusTag text={payment.status.toLowerCase()} />
              </PD_StatusTagContainer>
            )}
            {!isReadOnly
              ? payment.dueDate && (
                  <PD_Date>{moment(payment.dueDate).format('DD MMMM YYYY')}</PD_Date>
                )
              : payment.date && <PD_Date>{moment(payment.date).format('DD MMMM YYYY')}</PD_Date>}
          </PD_DateStatusTagContainer>
        </Grid.Item>
        <Grid.Item config={{ col: { xs: 12, md: 6 }, gutters: ['left', 'right'] }}>
          <PD_TypeDescriptionContainer>
            <PD_Type>
              {payment.referenceNumber &&
                payment.paymentType &&
                renderReferenceTextByNumAndType(payment.referenceNumber, payment.paymentType)}
            </PD_Type>
            {payment.description && payment.link && (
              <NavItem
                link={{
                  url: payment.link,
                  type: 'internal'
                }}
                StyledLink={PD_Description}
              >
                {payment.description.map((descItem, index) => (
                  <div key={index}>{descItem}</div>
                ))}
              </NavItem>
            )}
          </PD_TypeDescriptionContainer>
        </Grid.Item>
        <Grid.Item config={{ col: { xs: 12, md: 3 }, gutters: ['left', 'right'] }}>
          <PD_AmountContainer>
            {!isReadOnly ? (
              <PDL_CheckboxContainer disabled={isDisabled}>
                <Checkbox
                  id={'PaymentDue' + listIndex}
                  name="PaymentDue"
                  value={true}
                  onChange={handleOnToggleSelect}
                  checked={payment.isSelected}
                >
                  {payment.amount && (
                    <PD_AmountWithCheckBox> {payment.amount} </PD_AmountWithCheckBox>
                  )}
                </Checkbox>
              </PDL_CheckboxContainer>
            ) : (
              payment.amount && <PD_Amount> {payment.amount} </PD_Amount>
            )}
          </PD_AmountContainer>
        </Grid.Item>
      </Grid.Row>
    </PaymentDueWrapper>
  );
};

export default PaymentDue;
