import { canUseDOm } from 'exenv';
import { useState, useEffect } from 'react';

export default function() {
  function getSize() {
    return {
      width: canUseDOm ? window.innerWidth : undefined,
      height: canUseDOm ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
