import React from 'react';
import { Link as SCLink } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';

import { withEditMode, withEditModeNotice } from '_containers/BaseComponent';

import { External } from '_utils/icons';

import { StyledLink, LinkIconWrapper, SvgWrapper, EditorDiv } from './StyledLink';
import { LinkProps, LinkTheme, LinkTypes, LinkSize } from './definitions';
import NavItem from '_components/Corporate/NavItem';
import { getLink } from '../../../helpers/link';
import { useTranslation } from 'react-i18next';

const EditorText = compose(withEditModeNotice)(EditorDiv);

const Link: React.FC<LinkProps> = ({
  editMode,
  linkTheme,
  description,
  linkSize,
  className,
  field,
  tag
}) => {
  const [t] = useTranslation();
  const externalLinkText = t('accessibility-link-opens-in-a-new-tab');
  if (editMode) {
    const { href, text } = field?.value || {};

    return (
      <div className={className}>
        <EditorText
          noticeEnabled={href && !text}
          noticeMessage={`No description for this link. The following text will be displayed for users: '${href}'`}
        >
          <StyledLink as="span" linkTheme={linkTheme} linkSize={linkSize}>
            <SCLink field={field} />
          </StyledLink>
        </EditorText>
      </div>
    );
  }

  const linkObj = getLink(field.value);

  return (
    <div className={className}>
      <NavItem
        StyledLink={StyledLink}
        link={linkObj}
        additionalStyledLinkProps={{ linkTheme, linkSize }}
        description={linkObj.type === LinkTypes.External ? description + ', ' + externalLinkText: description}
      >
        {linkObj.text || linkObj.url}
        {linkObj.type === LinkTypes.External && (
          <LinkIconWrapper>
            &#65279;
            <SvgWrapper>
              <External />
            </SvgWrapper>
          </LinkIconWrapper>
        )}
      </NavItem>
    </div>
  );
};

Link.defaultProps = {
  tag: 'a',
  linkTheme: LinkTheme.Primary,
  linkSize: LinkSize.Default
};

export default compose(withEditMode)(Link);
