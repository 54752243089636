import React from 'react';
import { withSvg } from '../Svg';

const SvgPDF = () => (
  <>
    <path
      d="M11.4939 19.7903H10.688V18.5918H11.4939C11.67 18.5918 11.819 18.6442 11.9273 18.7491C12.0357 18.8539 12.0967 18.9963 12.0967 19.191C12.0967 19.3858 12.0357 19.5356 11.9273 19.6405C11.819 19.7453 11.67 19.7903 11.4939 19.7903Z"
      fill="#B00020"
    />
    <path
      d="M15.996 21.4082H15.2713V18.5918H15.996C16.2465 18.5918 16.4565 18.6517 16.619 18.7566C16.7816 18.8689 16.9035 19.0262 16.9847 19.2285C17.066 19.4382 17.1134 19.6929 17.1134 20C17.1134 20.3146 17.066 20.5693 16.9847 20.7715C16.9035 20.9813 16.7816 21.1386 16.619 21.2435C16.4565 21.3558 16.2465 21.4082 15.996 21.4082Z"
      fill="#B00020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5868 11.56C26.6261 11.6768 26.6529 11.7975 26.6668 11.92V25.3333C26.6668 26.3942 26.2454 27.4116 25.4953 28.1618C24.7451 28.9119 23.7277 29.3333 22.6668 29.3333H9.3335C8.27263 29.3333 7.25521 28.9119 6.50507 28.1618C5.75492 27.4116 5.3335 26.3942 5.3335 25.3333V6.66667C5.3335 5.60581 5.75492 4.58839 6.50507 3.83824C7.25521 3.0881 8.27263 2.66667 9.3335 2.66667H17.4002C17.5551 2.6858 17.7047 2.73566 17.8402 2.81334C17.88 2.80769 17.9204 2.80769 17.9602 2.81334C18.0973 2.87745 18.2233 2.96296 18.3335 3.06667L26.3335 11.0667C26.4372 11.1769 26.5227 11.3029 26.5868 11.44V11.56ZM22.1202 10.6667L18.6668 7.21334V9.33334C18.6668 9.68696 18.8073 10.0261 19.0574 10.2761C19.3074 10.5262 19.6465 10.6667 20.0002 10.6667H22.1202ZM23.6096 26.2761C23.8597 26.0261 24.0002 25.687 24.0002 25.3333V24H8.00016V25.3333C8.00016 25.687 8.14064 26.0261 8.39069 26.2761C8.64074 26.5262 8.97987 26.6667 9.3335 26.6667H22.6668C23.0205 26.6667 23.3596 26.5262 23.6096 26.2761ZM8.00016 16V6.66667C8.00016 6.31305 8.14064 5.97391 8.39069 5.72386C8.64074 5.47381 8.97987 5.33334 9.3335 5.33334H16.0002V9.33334C16.0002 10.3942 16.4216 11.4116 17.1717 12.1618C17.9219 12.9119 18.9393 13.3333 20.0002 13.3333H24.0002V16H8.00016ZM11.5955 17.3333H9.3335V22.6667H10.688V21.0487H11.5955C12.1644 21.0487 12.6181 20.8839 12.95 20.5468C13.2751 20.2097 13.4444 19.7603 13.4444 19.191C13.4444 18.6292 13.2751 18.1798 12.95 17.8427C12.6181 17.5056 12.1644 17.3333 11.5955 17.3333ZM16.023 17.3333H13.9168V22.6667H16.023C16.4362 22.6667 16.8019 22.6067 17.1202 22.4794C17.4385 22.3521 17.7026 22.1723 17.9058 21.9326C18.109 21.6929 18.2647 21.4157 18.3663 21.0861C18.4679 20.7566 18.5221 20.397 18.5221 20C18.5221 19.6105 18.4679 19.2509 18.3663 18.9214C18.2647 18.5918 18.109 18.3146 17.9058 18.0749C17.7026 17.8352 17.4385 17.6554 17.1202 17.5281C16.8019 17.4008 16.4362 17.3333 16.023 17.3333ZM22.6668 17.3333V18.6667H20.3506V19.3633H22.5178V20.6217H20.3506V22.6667H18.9962V17.3333H22.6668Z"
      fill="#B00020"
    />
  </>
);

export default withSvg(32, 32)(SvgPDF);
