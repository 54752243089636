import { ITabNav } from './DashboardTabsHeader/definitions';

export interface IDashboardTabsProps {
  fields: { data: { datasource: { tabs: ITab[] } } };
  className?: string;
}

export interface ITab extends ITabNav {
  description: {
    value: string;
  };
  notificationMessage: {
    value: string;
  };
  unavailableMessage: {
    value: string;
  };
}

export const greyOutStatus = [
  'completed',
  'cancelled',
  'duplicate'
]
