import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFilter, IFilterTabsProps } from './definitions';
import { FilterItem, FilterWrapper } from './StyledFilterTabs';

const FilterTabs: React.FC<IFilterTabsProps> = ({
  filters,
  currentFilter,
  changeFilter,
  className
}) => {
  const [t] = useTranslation();

  const renderFilterItem = filters?.map((item: IFilter, idx: number) => {
    const { type, link } = item;
    const filterName = `tab-${type}`;
    const filterByText = t('tab-requests-and-applications-filter-by');

    const handleEnter = (e) => {
      if (e.key === 'Enter') {
        changeFilter(link, type);
      }
    };

    return (
      <FilterItem
        tabIndex={0}
        key={idx}
        role='button'
        aria-label={filterByText + t(filterName)}
        aria-pressed={currentFilter === type}
        className={currentFilter === type ? 'active' : ''}
        onClick={() => changeFilter(link, type)}
        onKeyPress={(e) => handleEnter(e)}
      >
        {t(filterName)}
      </FilterItem>
    );
  });

  return <FilterWrapper className={className}>{renderFilterItem}</FilterWrapper>;
};

export default FilterTabs;
