import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    RequestAndApplicationWrapper,
    MainContent,
    ShowMoreContainer,
    UnAvailableSection
} from './StyledConsultationsTab';
import ShowMore from "../../ShowMore";
import TextMessage from "../../TextMessage";
import withErrorBoundary from '_containers/ErrorBoundary';
import TipBox from '_components/CityConnect/RatesCalculator/RatesInput/TipBox';
import ConsultationsCard from "./ConsultationsCard";
import { DashboardTabsContext } from "../index";
import { CONSULTATIONS_TAB } from "../constant";

interface IConsultationTabProps {
    tabData: any;
    filters: any;
}

const renderConsultationCard = (listItem) => {
    return (
        listItem?.length > 0 &&
        listItem?.map((item, idx) => (
            <ConsultationsCard key={idx} data={item} />
        ))
    );
};

const ConsultationTab: React.FC<IConsultationTabProps> = ({ tabData }) => {
    const { activeTab, showMoreData, loading, error, description, notificationMsg, unavailableMessage, handleGetMoreItems } = useContext(DashboardTabsContext);

    useEffect(() => {
        if (error && activeTab === CONSULTATIONS_TAB) {
            throw error;
        }
    }, [error])

    const formatTextMessageFields = (value: string) => {
        return {
            data: {
                datasource: {
                    message: {
                        jss: {
                            value: value
                        }
                    }
                }
            }
        };
    };

    if (unavailableMessage) {
        return <>
            {/* notification message */}
            {notificationMsg && <TipBox tipDescription={notificationMsg} />}
            <UnAvailableSection>
                {/* Unavailable message */}
                <TextMessage fields={formatTextMessageFields(unavailableMessage)} />
            </UnAvailableSection>
        </>
    }


    return (
        <>
            {notificationMsg && <TipBox tipDescription={notificationMsg} />}
            {/*{notificationMsg && <TipBox tipDescription={notificationMsg}/>}*/}
            <MainContent>
                {(tabData && tabData.length > 0)
                    ? (<>
                        <RequestAndApplicationWrapper>{renderConsultationCard(tabData)}</RequestAndApplicationWrapper>
                        {/* show more */}
                        {showMoreData.nextPage && (
                            <ShowMoreContainer>
                                <ShowMore
                                    labelTransKeys={{
                                        showLabel: "tab-pagination-showing",
                                        ofLabel: "tab-pagination-of",
                                        itemLabel: showMoreData.tabName ? "tab-pagination-" + showMoreData.tabName : "",
                                        showMoreLabel: "tab-pagination-show-more",
                                        loadingLabel: "tab-pagination-loading"
                                    }}
                                    numbers={{
                                        showingNumber: String(showMoreData.showRequest),
                                        totalNumber: showMoreData.totalItems
                                    }}
                                    handleClickShowMore={handleGetMoreItems}
                                    isLoading={showMoreData.showMoreLoading}
                                />
                            </ShowMoreContainer>
                        )}
                    </>)
                    : (<TextMessage
                        fields={formatTextMessageFields(description)}
                        loading={loading}
                    />)
                }
            </MainContent>
        </>
    );
};

const ConsultationTabFallback: React.FC = () => {
    const [t] = useTranslation();
    const errorMessage = { data: { datasource: { message: { jss: { value: t('dashboard-information-is-currently-unavailable') } } } } };

    return (
        <TextMessage fields={errorMessage} />
    );
};

export default withErrorBoundary(ConsultationTab, ConsultationTabFallback);
