import React, { FC } from 'react';
import TilesCard from './TilesCard';
import { TilesContainer } from './styledTiles';

interface ITilesProps {
  fields: any;
}

const Tiles: FC<ITilesProps> = ({ fields }) => {
  const tiles = fields?.data?.datasource?.tiles;
  return tiles?.length > 0 ? (
    <TilesContainer>
      {tiles.map((tile, idx) => {
        const { icon, title, description, link } = tile;
        return (
          <TilesCard
            key={idx}
            className="TilesCard"
            icon={icon?.value}
            title={title?.value}
            description={description?.value}
            link={link.jss}
          />
        );
      })}
    </TilesContainer>
  ) : null;
};

export default Tiles;
