import React from 'react';
import { withSvg } from '../Svg';

const SVGNSWGovernmentLevies = () => (
  <path
    d="M17.5 8.33337C17.6094 8.3334 17.7178 8.31186 17.8189 8.27C17.92 8.22813 18.0119 8.16675 18.0893 8.08936C18.1667 8.01197 18.228 7.92009 18.2699 7.81897C18.3118 7.71786 18.3333 7.60948 18.3333 7.50004V5.00004C18.3334 4.82518 18.2784 4.65475 18.1761 4.51291C18.0739 4.37107 17.9295 4.26503 17.7636 4.20984L10.2636 1.70984C10.0925 1.65287 9.90745 1.65287 9.73628 1.70984L2.23628 4.20984C2.07037 4.26503 1.92605 4.37107 1.8238 4.51291C1.72155 4.65475 1.66655 4.82518 1.66663 5.00004V7.50004C1.6666 7.60948 1.68813 7.71786 1.73 7.81897C1.77187 7.92009 1.83325 8.01197 1.91064 8.08936C1.98803 8.16675 2.07991 8.22813 2.18102 8.27C2.28214 8.31186 2.39052 8.3334 2.49996 8.33337H3.33329V14.3203C2.84724 14.4915 2.4261 14.8089 2.12768 15.2291C1.82927 15.6492 1.66821 16.1514 1.66663 16.6667V18.3334C1.6666 18.4428 1.68813 18.5512 1.73 18.6523C1.77187 18.7534 1.83325 18.8453 1.91064 18.9227C1.98803 19.0001 2.07991 19.0615 2.18102 19.1033C2.28214 19.1452 2.39052 19.1667 2.49996 19.1667H17.5C17.6094 19.1667 17.7178 19.1452 17.8189 19.1033C17.92 19.0615 18.0119 19.0001 18.0893 18.9227C18.1667 18.8453 18.228 18.7534 18.2699 18.6523C18.3118 18.5512 18.3333 18.4428 18.3333 18.3334V16.6667C18.3317 16.1514 18.1707 15.6492 17.8722 15.2291C17.5738 14.8089 17.1527 14.4915 16.6666 14.3203V8.33337H17.5ZM16.6666 17.5H3.33329V16.6667C3.33351 16.4458 3.42138 16.2339 3.57761 16.0777C3.73385 15.9215 3.94568 15.8336 4.16663 15.8334H15.8333C16.0542 15.8336 16.2661 15.9215 16.4223 16.0777C16.5785 16.2339 16.6664 16.4458 16.6666 16.6667V17.5ZM4.99996 14.1667V8.33337H6.66663V14.1667H4.99996ZM8.33329 14.1667V8.33337H11.6666V14.1667H8.33329ZM13.3333 14.1667V8.33337H15V14.1667H13.3333ZM3.33329 6.66671V5.60062L9.99996 3.37812L16.6666 5.60062V6.66671H3.33329Z"
    fill="#DA1E5B"
  />
);

export default withSvg(20, 20)(SVGNSWGovernmentLevies);
