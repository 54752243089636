import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';

import { CopyTextProps } from './definitions';
import { Trigger, TriggerText, Status } from './StyledCopyText';

const CopyText: React.FC<CopyTextProps> = ({
  displayText,
  toBeCopied,
  success,
  failed,
  tabIndex
}) => {
  const [successVisible, setSuccessVisible] = useState(false);
  const [failureVisible, setFailureVisible] = useState(false);

  useEffect(() => {
    let timer;

    if (successVisible || failureVisible) {
      timer = setTimeout(() => {
        setSuccessVisible(false);
        setFailureVisible(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  });

  const handleTriggerClick = (toBeCopied, onCopy) => {
    const result = copy(toBeCopied);

    onCopy(result);
  };

  const onCopy = (copied: boolean) => {
    if (copied) {
      setSuccessVisible(true);
    } else {
      setFailureVisible(true);
    }
  };

  return (
    <Trigger
      type="button"
      onClick={handleTriggerClick.bind(null, toBeCopied, onCopy)}
      tabIndex={tabIndex}
    >
      <TriggerText hide={successVisible || failureVisible}>{displayText}</TriggerText>
      <Status aria-hidden={!successVisible} type="success">
        {success}
      </Status>
      <Status aria-hidden={!failureVisible} type="failed">
        {failed}
      </Status>
    </Trigger>
  );
};

CopyText.defaultProps = {
  displayText: 'Copy',
  success: 'Copied!',
  failed: 'Failed!',
  tabIndex: null
};

export default CopyText;
