import React, {
    useEffect,
    useState
} from 'react';
import {
    TextArea,
    Wrapper
} from './StyledInputText';
import SvgWarning from "_utils/icons/Profile/Warning";

interface IInputTextProps {
    id?: string;
    className?: string;
    onChange?: (e) => void;
    value?: any;
    errorMsg?: any;
    placeHolder?: string;
    disabled?: boolean;
    active?: boolean;
    onClick?: any;
    onLeave?: any;
}

const TextAreaField:React.FC<IInputTextProps> = ({
                                                     id,
                                                     className,
                                                     onChange,
                                                     value,
                                                     errorMsg,
                                                     placeHolder,
                                                     disabled,
                                                     active,
                                                     onClick,
                                                     onLeave
                                                 }) => {
    const [prefill, setPrefill] = useState(value ? true: false);

    const handleChangeInput = (e) => {
        onChange(e);
        setPrefill(false);
    }

    useEffect(() => {
        if(!value){
            setPrefill(false);
        }
    }, [value]);

    return (
        <Wrapper className={className}>
            <TextArea
                className={`${active ? 'active' : ''} ${prefill ? 'text-bold' : ''}  ${errorMsg ? 'error' : ''}`}
                onClick={onClick}
                onBlur={onLeave}
                id={id}
                value={value}
                onChange={(e) => handleChangeInput(e)}
                placeholder={placeHolder}
                disabled={disabled} />
            {errorMsg && <p className="error-validation"><SvgWarning />{errorMsg}</p>}
        </Wrapper>
    )
};

export default TextAreaField;
