import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import SvgArrowDown from '_utils/icons/ArrowDown';
import SvgResidentialHouse from '_utils/icons/RatesCalculator/ResidentialHouse';
import SvgResidentialUnit from '_utils/icons/RatesCalculator/ResidentialUnit';
import SvgBusiness from '_utils/icons/RatesCalculator/Business';
import SvgCarSpace from '_utils/icons/RatesCalculator/CarSpace';
import SvgOther from '_utils/icons/RatesCalculator/Other';
import RateDetailCurrent from '../../PropertyRates/RateDetailCurrent';
import RateDetailHistory from '../../PropertyRates/RateDetailHistory';
import ServiceAnnouncement, {
  ANNOUNCEMENT_TYPES
} from '_components/CityConnect/ServiceAnnouncement';

import {
  RateTypeGraphic,
  GraphicContainer,
  RateTypeAddress,
  RateTypeRatingCategory,
  RateTypeRatingCategorySpan
} from './StyledRatesCalculation';

import {
  PropertyAccordionWrapper,
  SubSection,
  PropertyTitle,
  AccordionWrapper,
  AccordionItem,
  AccordionTitle,
  AccordionContentContainer,
  AccordionTitleText,
  ArrowWrapper
} from '../../PropertyRates/StyledPropertyRates';

type RatesCalculationProps = {
  calculationData: any;
  rendering?: any;
  isShowNextYearRates: boolean;
};

const RATE_TYPE_GRAPHICS = {
  'residential-unit': <SvgResidentialUnit />,
  'residential-house': <SvgResidentialHouse />,
  business: <SvgBusiness />,
  'car-space': <SvgCarSpace />,
  other: <SvgOther />
};
const RatesCalculation = forwardRef<HTMLElement, RatesCalculationProps>(
  ({ calculationData, rendering, isShowNextYearRates }, ref) => {
    const [showRateDetailCurrent, setShowDetailCurrent] = useState(true);
    const [showRateDetailHistory, setShowDetailHistory] = useState(false);
    const [t] = useTranslation();
    const dateCalculated = calculationData?.rates?.date;
    const calculationCurrent = calculationData?.rates?.current;
    const calculationNextYear = calculationData?.rates?.nextYear;
    const calculationHistory = calculationData?.rates?.history;
    const calculationPropertyType = calculationData?.rates?.property;
    const calculationPropertyTips = calculationData?.rates?.tips;
    const calculationNotification = calculationData?.rates?.notification;
    const notificationFields = () => {
      return {
        data: {
          datasource: {
            title: {
              value: calculationCurrent ? calculationNotification?.title: (calculationNotification?.title || t('rates-calculator-non-rateable-title'))
            },
            description: {
              value: calculationCurrent ? calculationNotification?.description: (calculationNotification?.description || t('rates-calculator-non-rateable-description'))
            },
            type: {
              value: ANNOUNCEMENT_TYPES.Warning
            }
          }
        }
      }
    }


    return (
      <PropertyAccordionWrapper ref={ref}>
        <SubSection>
          <PropertyTitle>{t('rates-calculator-rates-calculation')}</PropertyTitle>
        </SubSection>
        {rendering && calculationCurrent && calculationHistory && (
          <SubSection>
            <Placeholder name="jss-rates-calculator-announcement" rendering={rendering} />
          </SubSection>
        )}
        {(calculationNotification && calculationCurrent) && (
          <SubSection>
            <ServiceAnnouncement fields={notificationFields()} />
          </SubSection>
        )}
        <SubSection>
          <RateTypeGraphic>
            {calculationPropertyType?.type && (
                <GraphicContainer>
                  {RATE_TYPE_GRAPHICS[calculationPropertyType.type]}
                </GraphicContainer>
            )}
            {calculationPropertyType?.address && (
                <RateTypeAddress>{calculationPropertyType.address}</RateTypeAddress>
            )}
            {calculationPropertyType?.ratingCategory && (
              <RateTypeRatingCategory>
                {t('rates-details-rating-category')}:
                <RateTypeRatingCategorySpan>
                  {calculationPropertyType.ratingCategory}
                </RateTypeRatingCategorySpan>
              </RateTypeRatingCategory>
            )}
          </RateTypeGraphic>
        </SubSection>
        {calculationCurrent ? (
          // with data
          <AccordionWrapper>
            <AccordionItem>
              <AccordionTitle
                className={showRateDetailCurrent ? 'active' : ''}
                onClick={() => setShowDetailCurrent(!showRateDetailCurrent)}
              >
                <AccordionTitleText>{t('rates-details-current')}</AccordionTitleText>
                <ArrowWrapper className={showRateDetailCurrent ? 'show' : ''}>
                  <SvgArrowDown ariaHidden={false} />
                </ArrowWrapper>
              </AccordionTitle>
              {showRateDetailCurrent && (
                <AccordionContentContainer>
                  <RateDetailCurrent
                    dateCalculated={dateCalculated}
                    currentRate={calculationCurrent}
                    nextYearRate={calculationNextYear}
                    tips={calculationPropertyTips}
                    isResidentialHouses={true} //now just mocking and waiting for update property type task
                    isShowNextYearRates={isShowNextYearRates}
                  />
                </AccordionContentContainer>
              )}
            </AccordionItem>
            {calculationHistory && (
              <AccordionItem>
                <AccordionTitle
                  className={showRateDetailHistory ? 'active' : ''}
                  onClick={() => setShowDetailHistory(!showRateDetailHistory)}
                >
                  <AccordionTitleText>{t('rates-details-rateable-value')}</AccordionTitleText>
                  <ArrowWrapper className={showRateDetailHistory ? 'show' : ''}>
                    <SvgArrowDown ariaHidden={false} />
                  </ArrowWrapper>
                </AccordionTitle>
                {showRateDetailHistory && (
                  <AccordionContentContainer>
                    <RateDetailHistory data={calculationHistory} />
                  </AccordionContentContainer>
                )}
              </AccordionItem>
            )}
          </AccordionWrapper>
        ) : (
          // without data
          <ServiceAnnouncement fields={notificationFields()} />
        )}
      </PropertyAccordionWrapper>
    );
  }
);

export default RatesCalculation;
