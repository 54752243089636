import { spacing } from '_utils/props';
import { button, color, safeFocusRemoval, svg, text_s } from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';

export const TextInfo = styled.p`
  ${text_s};
  text-align: center;
  margin-top: 0;
  ${({ theme }) => spacing({ theme, mb: 3 })};
`;

export const Count = styled.span`
  font-weight: 700;
  font-family: 'Helvetica Now Text', Helvetica, sans-serif;
`;

export const ShowMoreButton = styled.button`
  ${button('default', 'outline')}
  ${safeFocusRemoval}
  display: flex;
  align-items: center;

  body:not(.safe-focus-removal) &:focus {
    &:before {
      content: none;
    }
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -6px;
      top: -6px;
      content: '';
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
  font-family: 'Helvetica Now Text', Helvetica, sans-serif;
  font-weight: 700;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshIcon = styled.span`
  ${svg(20, 20)};
  width: 20px;
  margin-right: 10px;
  ${RotateCss}
  path {
    fill: ${color('all.greenTint110')};
  }

  ${ShowMoreButton}:hover & {
    path {
      fill: ${({ theme }) => theme.colors.button.outline.colorHover};
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
