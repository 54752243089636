import React from 'react';
import { withSvg } from '../Svg';

const SvgSortAmountArrowDown = () => (
  <path
    d="M4.29409 8.29L4.00409 8.59V1C4.00409 0.734784 3.89873 0.48043 3.7112 0.292893C3.52366 0.105357 3.26931 0 3.00409 0C2.73888 0 2.48452 0.105357 2.29699 0.292893C2.10945 0.48043 2.00409 0.734784 2.00409 1V8.59L1.71409 8.29C1.52579 8.1017 1.27039 7.99591 1.00409 7.99591C0.73779 7.99591 0.482395 8.1017 0.294092 8.29C0.105788 8.4783 0 8.7337 0 9C0 9.2663 0.105788 9.5217 0.294092 9.71L2.29409 11.71C2.3892 11.801 2.50134 11.8724 2.62409 11.92C2.74379 11.9729 2.87322 12.0002 3.00409 12.0002C3.13496 12.0002 3.26439 11.9729 3.38409 11.92C3.50684 11.8724 3.61899 11.801 3.71409 11.71L5.71409 9.71C5.80733 9.61676 5.88129 9.50607 5.93175 9.38425C5.98221 9.26243 6.00818 9.13186 6.00818 9C6.00818 8.86814 5.98221 8.73757 5.93175 8.61575C5.88129 8.49393 5.80733 8.38324 5.71409 8.29C5.62085 8.19676 5.51016 8.1228 5.38834 8.07234C5.26652 8.02188 5.13595 7.99591 5.00409 7.99591C4.87223 7.99591 4.74166 8.02188 4.61984 8.07234C4.49802 8.1228 4.38733 8.19676 4.29409 8.29Z"
    fill="#041C2C"
  />
);

export default withSvg(7, 12)(SvgSortAmountArrowDown);
