import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  CardWrapper,
  CardSection,
  DetailSection,
  DetailItem,
  CardTitle,
  CardDescription
} from '../PersonalDetailsCard/StyledPersonalDetailsCard';
import { PersonalInfoContext } from '..';
import NavItem from '_components/Corporate/NavItem';
import AutoCompleteAddress from '../AutoCompleteAddress';
import TextMessage from '../../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { transformAddress } from '_utils/helpers/personalInfohelper';

type ContactDetailsCardProps = {
  datasource: any;
};

const ContactDetailsCard: React.FC<ContactDetailsCardProps> = ({ datasource }) => {
  const [t] = useTranslation();
  const { error, personalInfo } = useContext(PersonalInfoContext);
  const { email, mobile, postalAddress } = personalInfo || {};

  // Get fields jss value
  const contactDetailsTitle = datasource?.contactDetailsTitle?.jss?.value;
  const contactetailsDescription = datasource?.contactetailsDescription?.jss?.value;
  const emailEditLink = datasource?.emailEditLink?.jss?.value;
  const mobileEditLink = datasource?.mobileEditLink?.jss?.value;

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return (
    <CardWrapper>
      {contactDetailsTitle && <CardTitle>{contactDetailsTitle}</CardTitle>}
      {contactetailsDescription && (
        <CardDescription
          dangerouslySetInnerHTML={{ __html: contactetailsDescription }}
        ></CardDescription>
      )}
      <CardSection>
        <DetailSection>
          {/* INFO: Email */}
          <DetailItem.Wrapper>
            <DetailItem.Text>{t(`personal-info-residential-email`)}</DetailItem.Text>
            <div className="description-block">
              <DetailItem.Description className={!email ? 'italic' : ''}>
                {email || 'Not supplied'}
              </DetailItem.Description>
              {/* INFO: Get Email Edit link from fields */}
              {emailEditLink && (
                <NavItem
                  link={{
                    url: emailEditLink.href,
                    type: emailEditLink.linktype,
                    target: emailEditLink.target
                  }}
                  StyledLink={DetailItem.EditButton}
                  description={emailEditLink?.text}
                >
                  {emailEditLink.text}
                </NavItem>
              )}
            </div>
          </DetailItem.Wrapper>
          {/* INFO: Mobile */}
          <DetailItem.Wrapper>
            <DetailItem.Text>{t(`personal-info-mobile`)}</DetailItem.Text>
            <div className="description-block">
              <DetailItem.Description className={!mobile ? 'italic' : ''}>
                {mobile || 'Not supplied'}
              </DetailItem.Description>
              {/* INFO: Get Mobile Edit link from fields */}
              {mobileEditLink && (
                <NavItem
                  link={{
                    url: mobileEditLink.href,
                    type: mobileEditLink.linktype,
                    target: mobileEditLink.target
                  }}
                  StyledLink={DetailItem.EditButton}
                  description={mobileEditLink?.text}
                >
                  {mobileEditLink.text}
                </NavItem>
              )}
            </div>
          </DetailItem.Wrapper>
          {/* INFO: Postal Address is different from email and mobile rows */}
          <AutoCompleteAddress
            inputLabelDictionary="personal-info-postal-address"
            inputName="postalAddress"
            inputValue={transformAddress(postalAddress)}
          />
        </DetailSection>
      </CardSection>
    </CardWrapper>
  );
};

const ContactDetailsCardFallback: React.FC<ContactDetailsCardProps> = ({ datasource }) => {
  const [t] = useTranslation();
  const contactDetailsTitle = datasource?.contactDetailsTitle?.jss?.value;
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <CardWrapper>
      {contactDetailsTitle && <CardTitle>{contactDetailsTitle}</CardTitle>}
      <TextMessage className="removeStyle" fields={errorMessage} />
    </CardWrapper>
  );
};
export default withErrorBoundary(ContactDetailsCard, ContactDetailsCardFallback);
