import { get } from './genericService';

export function getRatesByPropertyNumber(id, showNextYear, success, failed) {
  return get('/api/v1/public/rates/property/' + (id || 'xxxx') + `?showNextYear=${showNextYear}`, success, failed);
}

export function getRatesByAssessmentNumber(id, showNextYear, success, failed) {
  return get('/api/v1/public/rates/assessment/' + (id || 'xxxx') + `?showNextYear=${showNextYear}`, success, failed);
}

export function getLgaAddress(keyword, success, failed) {
  return get('/api/v1/public/lga/address/rates?address=' + keyword, success, failed);
}
