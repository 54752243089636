import styled from 'styled-components';
import { mq, section } from '_utils/styles';

import { Container as PageBannerContainer } from '_components/Corporate/PageBanner/StyledPageBanner';

export const CurveContainer = styled.div`
  position: absolute;
  left: 50%;
  top: -1px; /* prevent 'crack' between this and banner */
  height: 50%;
  transform: translateX(-50%);
  width: 130%;

  ${mq('sm')} {
    width: 116%;
  }

  ${mq('md')} {
    width: 112.5%;
  }

  ${mq('lg')} {
    width: 109%;
  }

  ${mq('xl')} {
    width: 105%;
  }
`;

export const Container = styled.div`
  ${({ theme, sectionTheme }) => section({ theme, sectionTheme })};

  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;

  ${PageBannerContainer} + & {
    padding-top: 0 !important;

    ${CurveContainer} {
      display: block;
    }
  }
`;

export const Curve = styled.span`
  width: 100%;

  svg {
    max-width: none;
    width: 100%;
    height: 100%;
    position: absolute;
    max-height: 160px;
  }

  path {
    fill: ${({ theme }) => theme.colors.background.tertiary};
  }
  @media print {
    path {
      fill: ${({ theme }) => theme.colors.background.primary};
    }
  }
`;
