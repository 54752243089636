import React from 'react';

import { AttributeRow, AttributeIcon, AttributeValue } from './StyledCost';
import { Cost } from '_utils/icons';

const CostEstimate = ({ estimate }) => {
  return (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <Cost ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>{estimate}</AttributeValue>
    </AttributeRow>
  );
};

export default CostEstimate;
