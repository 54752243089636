export default {
  MODAL: 1200,
  MENU: 1000,
  MENU_BUTTON: 950,
  MENU_COVER: 900,
  MENU_BLOCK: 899,
  CAPTION_POPUP: 800,
  QUICK_CLOSE: 5,
  LOADING_SCREEN: 9999
};
