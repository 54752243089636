import React, { useState, useEffect, useRef } from 'react';
import { canUseDOM } from 'exenv';
import { compose } from 'ramda';

import { getPortal, Portals } from '_utils/helpers';
import MainMenu from '_components/Corporate/MainMenu';
import MainSearch from '../MainSearch';
import MenuButton from '../MenuButton';

import { withMenuContext, MenuContextProps } from '_containers/MenuContext';

import {
  SkipContainer,
  SkipLink,
  StyledPageHeader,
  LogoWrapper,
  LockupWrapper,
  LogoLink,
  LockupLink,
  MenuContainer,
  SearchContainer,
  DimContent,
  MenuActiveBlockInteraction
} from './StyledPageHeader';
import { withSitecoreContext, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useTranslation } from 'react-i18next';

import asyncComponent from '_containers/AsyncComponent';
const AsyncLogo: any = asyncComponent(() =>
  import(/* webpackChunkName: "Logo" */ '_utils/icons/Logo')
);
const AsyncLockup: any = asyncComponent(() =>
  import(/* webpackChunkName: "Lockup" */ '_utils/icons/Lockup')
);

interface HeaderProps extends MenuContextProps {
  fields: any;
  sitecoreContext: SitecoreContext;
}

const pageHasHomepageBanner = (sitecoreContext): boolean => {
  if (!sitecoreContext) {
    return false;
  }

  const placeholders = sitecoreContext?.route?.placeholders?.['jss-main'];

  if (!placeholders || !Array.isArray(placeholders)) {
    return false;
  }

  return Boolean(placeholders.find(({ componentName }) => componentName === 'HomepageBanner'));
};

export const PageHeader: React.FC<HeaderProps> = ({
  fields,
  sitecoreContext,
  menuActive,
  setMenuActive
}) => {
  const [t] = useTranslation();
  const portalRef = useRef<HTMLElement>(null);
  const [skipActive, setSkipActive] = useState(false);
  const [beforeRootSpace, setBeforeRootSpace] = useState(0);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    if (portalRef.current === null) {
      portalRef.current = getPortal(Portals.before);
    }

    setBeforeRootSpace(portalRef.current?.getBoundingClientRect().height);
  }, [menuActive]);

  return (
    <>
      <SkipContainer className={skipActive ? 'skipActive' : ''}>
        <SkipLink
          href="#main-content"
          onClick={() => {
            try {
              let mainContent = document.getElementById('main-content');
              mainContent.focus();
            } catch (err) {
              console.warn('SkipToContent: could not element with id main-content');
            }
          }}
          onFocus={() => {
            if (window) {
              window.scrollTo(0, 0);
            }
            setSkipActive(true);
          }}
          onBlur={() => setSkipActive(false)}
        >
          {t('page-header-skip-to-content-label')}
        </SkipLink>
      </SkipContainer>
      <StyledPageHeader
        onKeyDown={(e) => {
          // ESCAPE
          if (e.keyCode === 27 && menuActive) {
            setMenuActive(false);
          }
        }}
      >
        <LogoLink to="/" aria-label={t('page-header-home-link-title')}>
          <LogoWrapper>
            <AsyncLogo ariaHidden={true} fill={null} />
          </LogoWrapper>
        </LogoLink>
        <LockupLink to="/" aria-label={t('page-header-home-link-title')}>
          <LockupWrapper>
            <AsyncLockup ariaHidden={true} />
          </LockupWrapper>
        </LockupLink>
        <SearchContainer>
          {!pageHasHomepageBanner(sitecoreContext) && <MainSearch />}
        </SearchContainer>
        <MenuButton />
        {menuActive && (
          <MenuActiveBlockInteraction
            className={menuActive ? 'menuActive' : ''}
            onClick={() => setMenuActive(false)}
          />
        )}
        <MenuContainer beforeRootSpace={beforeRootSpace} className={skipActive ? 'skipActive' : ''}>
          {menuActive && <DimContent className={menuActive ? 'menuActive' : ''} />}
          <MainMenu fields={fields} />
        </MenuContainer>
      </StyledPageHeader>
    </>
  );
};

export default compose(withSitecoreContext(), withMenuContext)(PageHeader);
