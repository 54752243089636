import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment-mini';
import { useTranslation } from 'react-i18next';

import { Grid } from '_utils/components/Corporate';
import withErrorBoundary from '_containers/ErrorBoundary';
import NavItem from '_components/Corporate/NavItem';
import SvgVector from '_utils/icons/Vector';
import StatusTag from '_utils/components/CityConnect/StatusTag';
import { getPaymentsDueCard } from '_services/paymentsDueCardService';

import {
  // INFO: Card Wrapper, Content and Footer
  PaymentsDueCardWrapper,
  CardSection,
  CardFooter,
  CardSectionHeader,
  // INFO: Payment Card Title
  TitleWrapper,
  DueCount,
  // INFO: All styles of Payment Due Items (Date, Reference Text and Amount)
  ListItems,
  PaymentText,
  PaymentDateWrapper,
  PaymentButton,
  PaymentAmountWrapper,
  // INFO: Payment Quick Link styles
  QuickLinkWrapper,
  QuickLink,
  ListItemsWrapper,
  RefreshWrapper,
  SvgWrapper
} from './StyledPaymentsDueCard';
import { Refresh } from '_utils/icons';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

interface PaymentsDueCardProps {
  fields: any;
}

interface IPaymentsDue {
  dueDate: string;
  status: string;
  paymentType: string;
  referenceNumber: string;
  description: Array<string>;
  amount: string;
  link: string;
  paymentLink: string;
}

interface IPaymentsDueResponse {
  total: string;
  items: Array<IPaymentsDue>;
}

const PAYMENT_TYPE = {
  RATES: 'rates'
};

const PROCESSING = 'payment-processing';

const PaymentsDueCard: React.FC<PaymentsDueCardProps> = ({ fields }) => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentsDueData, setPaymentsDueData] = useState<IPaymentsDueResponse>();
  const count = paymentsDueData?.total || '';

  useEffect(() => {
    getPaymentsDueCard(getPaymentsDueSuccess, (err) => setError(err));
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const getPaymentsDueSuccess = (data) => {
    setPaymentsDueData(data.data.paymentsDue);
    setIsLoading(false);
  };

  const renderDescription = (description, link) => {
    return description?.length ? (
      <NavItem
        link={{
          url: link,
          type: 'internal'
        }}
        StyledLink={link ? PaymentText.DescriptionLink : PaymentText.Description}
        description={description}
      >
        {description.map((des, index) => (
          <div key={index}>{des}</div>
        ))}
      </NavItem>
    ) : (
      <></>
    );
  };

  const renderQuickLinks = () => {
    const allLinks = fields.data.datasource;
    const quickLinkArr = [];
    Object.keys(allLinks).forEach((linkKey) => {
      if (linkKey !== 'showAllLink') {
        quickLinkArr.push(allLinks[linkKey]?.jss?.value);
      }
    });
    return (
      quickLinkArr.length &&
      quickLinkArr.map((link, index) => {
        return (
          link?.text && (
            <QuickLinkWrapper key={index}>
              <SvgVector />
              <NavItem
                link={{
                  url: link.href,
                  type: link.linktype,
                  target: link.target
                }}
                StyledLink={QuickLink}
                description={link.text}
              >
                {link?.text}
                {link?.linktype === 'external' && (
                  <SvgWrapper isInLine={true}>
                    <SvgExternal />
                  </SvgWrapper>
                )}
              </NavItem>
            </QuickLinkWrapper>
          )
        );
      })
    );
  };

  const renderReferenceTextByNumAndType = (refNo: string, paymentType: string) => {
    let prefix = '';
    switch (paymentType.toLowerCase()) {
      case '':
        prefix = '';
        break;
      case PAYMENT_TYPE.RATES.toLowerCase():
        prefix = t('payments-due-assessment-number');
        break;
      default:
        prefix = t('payments-due-reference-number');
        break;
    }
    return `${prefix} ${refNo}`;
  };

  const renderPaymentsDue = () => {
    const data = paymentsDueData.items;
    return (
      data.length &&
      data.map((payment, index) => {
        return (
          <ListItems key={index} role="listitem">
            <Grid.Row config={{ gutters: ['left', 'right'] }}>
              <Grid.Item
                config={{
                  col: { xs: 12, lg: 3 },
                  gutters: ['left', 'right']
                }}
              >
                <PaymentDateWrapper>
                  <PaymentText.DateTitle>{payment.dueDate}</PaymentText.DateTitle>
                  {payment.status && <StatusTag text={payment.status.toLowerCase()} />}
                </PaymentDateWrapper>
              </Grid.Item>
              <Grid.Item config={{ col: { xs: 12, lg: 6 }, gutters: ['left', 'right'] }}>
                <PaymentText.ReferenceText>
                  {renderReferenceTextByNumAndType(payment.referenceNumber, payment.paymentType)}
                </PaymentText.ReferenceText>
                {renderDescription(payment.description, payment.link)}
              </Grid.Item>
              <Grid.Item config={{ col: { xs: 12, lg: 3 }, gutters: ['left', 'right'] }}>
                <PaymentAmountWrapper>
                  <PaymentText.Amount>{payment.amount}</PaymentText.Amount>
                  {payment.status.toLocaleLowerCase() !== PROCESSING && payment.paymentLink && (
                    <PaymentButton
                      href={payment.paymentLink}
                      aria-label={t('payments-due-pay-button')}
                      className="button-primary button-slim"
                      target="_blank"
                    >
                      {t('payments-due-pay-button')}
                      <SvgWrapper isInLine={false} isSlim={true}>
                        <SvgExternal />
                      </SvgWrapper>
                    </PaymentButton>
                  )}
                </PaymentAmountWrapper>
              </Grid.Item>
            </Grid.Row>
          </ListItems>
        );
      })
    );
  };

  const handleShowPaymentItems = () => {
    return (
      <>
        {paymentsDueData.items.length ? (
          renderPaymentsDue()
        ) : (
          <PaymentText.Normal>{t('payments-due-no-payments-due')}</PaymentText.Normal>
        )}
      </>
    );
  };

  return (
    <PaymentsDueCardWrapper>
      <CardSection>
        <CardSectionHeader>
          <TitleWrapper>
            <h2>
              <Text field={{ value: t('payments-due-heading') }} editable={false} />
            </h2>
          </TitleWrapper>
          <NavItem
            link={{
              url: fields?.data?.datasource?.showAllLink?.jss?.value?.href,
              type: 'internal'
            }}
            StyledLink={DueCount}
            description={fields?.data?.datasource?.showAllLink?.jss?.value?.text}
          >
            {fields?.data?.datasource?.showAllLink?.jss?.value?.text} ({!isLoading ? count : '...'})
          </NavItem>
        </CardSectionHeader>
        <ListItemsWrapper role="list">
          {!isLoading ? (
            handleShowPaymentItems()
          ) : (
            <PaymentText.Normal>
              {t('dashboard-loading-information')}
              <RefreshWrapper>
                <Refresh ariaHidden={false} />
              </RefreshWrapper>
            </PaymentText.Normal>
          )}
        </ListItemsWrapper>
      </CardSection>
      <CardFooter>{renderQuickLinks()}</CardFooter>
    </PaymentsDueCardWrapper>
  );
};

const PaymentsDueCardFallback: React.FC<PaymentsDueCardProps> = ({ fields }) => {
  const [t] = useTranslation();

  const allLinks = fields.data.datasource;
  const quickLinkArr = [];
  Object.keys(allLinks).forEach((linkKey) => {
    if (linkKey !== 'showAllLink') {
      quickLinkArr.push(allLinks[linkKey]?.jss?.value);
    }
  });

  return (
    <PaymentsDueCardWrapper>
      <CardSection>
        <CardSectionHeader>
          <TitleWrapper>
            <h2>
              <Text field={{ value: t('payments-due-heading') }} editable={false} />
            </h2>
          </TitleWrapper>
          <NavItem
            link={{
              url: fields?.data?.datasource?.showAllLink?.jss?.value?.href,
              type: 'internal'
            }}
            StyledLink={DueCount}
          >
            {fields?.data?.datasource?.showAllLink?.jss?.value?.text} ({'...'})
          </NavItem>
        </CardSectionHeader>
        <ListItemsWrapper>
          <PaymentText.Normal>
            {t('dashboard-information-is-currently-unavailable')}
          </PaymentText.Normal>
        </ListItemsWrapper>
      </CardSection>
      <CardFooter>
        {quickLinkArr.length &&
          quickLinkArr.map((link, index) => {
            return (
              link?.text && (
                <QuickLinkWrapper key={index}>
                  <SvgVector />
                  <NavItem
                    link={{
                      url: link.href,
                      type: 'internal'
                    }}
                    StyledLink={QuickLink}
                  >
                    {link?.text}
                  </NavItem>
                </QuickLinkWrapper>
              )
            );
          })}
      </CardFooter>
    </PaymentsDueCardWrapper>
  );
};

export default withErrorBoundary(PaymentsDueCard, PaymentsDueCardFallback);
