import React from 'react';
import {useTranslation} from "react-i18next";

import {
  AccordionTransactionAmountWrapper,
  AccordionTransactionContentContainer,
  AccordionTransactionItem,
  AccordionTransactionTitle,
  AccordionTransactionTitleText,
  Amount,
  Description,
  PaymentTransactionsItem,
  ProcessedDate,
  SubColumnGroup,
  SubItemsContainer,
  SubTableHead, TitleAmount
} from "../StyledRatesDetailTransaction";
import {ArrowWrapper} from "../../StyledPropertyRates";
import SvgArrowDown from "_utils/icons/ArrowDown";
import {TransactionProps, TransactionItemProps} from "../definition";

interface RateDetailHistoryProps{
  item: TransactionProps;
  handleOpenTab: (id: number) => void;
  isOpen: number[];
  index: number;
};

interface PaymentItemProps{
  item: TransactionItemProps;
};

const PaymentItem: React.FC<PaymentItemProps> = ({ item }) => {
  return (
      <>
        <ProcessedDate role='column'>{item.date || ""}</ProcessedDate>
        <SubColumnGroup>
          <Description role='column'>{item.description || ""}</Description>
          <Amount role='column'>{item.amount || ""}</Amount>
        </SubColumnGroup>
      </>
  )
}

const TransactionItem: React.FC<RateDetailHistoryProps> = ({ item, handleOpenTab, isOpen, index }) => {
  const [t] = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleOpenTab(index);
    }
  };

  return (
      <>
        <AccordionTransactionItem
          tabIndex={0}
          role="button"
          aria-label="Details"
          aria-expanded={isOpen.includes(index)}
          onClick={() => handleOpenTab(index)}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          <AccordionTransactionTitle className={isOpen.includes(index) ? 'active' : ''}>
            <AccordionTransactionTitleText role='column'>
              {item?.description || ""}
            </AccordionTransactionTitleText>
            <AccordionTransactionAmountWrapper role='column'>
              {item?.amount || ""}
              <ArrowWrapper className={isOpen.includes(index) ? 'show' : ''}>
                <SvgArrowDown ariaHidden={false} />
              </ArrowWrapper>
            </AccordionTransactionAmountWrapper>
          </AccordionTransactionTitle>
        </AccordionTransactionItem>
        {isOpen.includes(index) && <AccordionTransactionContentContainer>
          {
            item?.items?.length > 0 && (
                <SubItemsContainer role='table'>
                  <SubTableHead role='row'>
                    <ProcessedDate role='column'>{t('rates-payment-processed-date')}</ProcessedDate>
                    <SubColumnGroup>
                      <Description role='column'>{t('rates-payment-description')}</Description>
                      <TitleAmount role='column'>{t('rates-payment-amount')}</TitleAmount>
                    </SubColumnGroup>
                  </SubTableHead>
                  {
                    item.items.map((it, index) => {
                      return (
                          <PaymentTransactionsItem key={index} role='row'>
                            <PaymentItem item={it}/>
                          </PaymentTransactionsItem>
                      )
                    })
                  }
                </SubItemsContainer>
            )
          }
        </AccordionTransactionContentContainer>
        }
      </>
  );
}

export default TransactionItem;