import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import TextMessage from '../TextMessage';
import { EMAIL, POST, EMAIL_PATTERN } from './constant';
import {
  getRatesNoticeDeliveryData,
  updateRatesNoticeDelivery
} from '_services/ratesNoticeDeliveryService';
import withErrorBoundary from '_containers/ErrorBoundary';
import Toggle from '_utils/components/CityConnect/Toggle';
import TextareaAutoResize from '_utils/components/CityConnect/TextareaAutoResize';
import TipBox from '../RatesCalculator/RatesInput/TipBox';
import ContentRichText from '_components/Corporate/ContentRichText';
import SvgExclamationTriangle from '_utils/icons/announcement/ExclamationTriangle';
import {
  CardWrapper,
  CardHeader,
  CardTitle,
  EmailSwitchContainer,
  SwitchLabel,
  RowContainer,
  OptionTitle,
  OptionValueContainer,
  OptionValue,
  EditLink,
  InputContainer,
  ErrorMessage,
  ErrorIcon,
  InputActionsContainer,
  ButtonLink,
  ButtonUpdate,
  Disclaimer,
  Divider,
  DescriptionText,
  FallbackMessageContainer,
  RefreshIcon,
  SvgWrapper
} from './StyledRatesNoticeDeliveryCard';
import { compose } from 'ramda';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getRefreshToken } from '_services/genericService';
import SvgRefresh from '_utils/icons/common/Refresh';
import SvgExternal from '_utils/icons/PaymentsDueList/External';

interface RatesNoticeDeliveryCard {
  sitecoreContext: any;
}

interface IRatesNotice {
  ratesNoticePreference: {
    multipleOwners?: boolean;
    preference?: string;
    email?: string;
    postalAddress?: string;
    postalAddressEditLink?: string;
    receiveByPost?: boolean;
  };
}

const RatesNoticeDeliveryCard: FC<RatesNoticeDeliveryCard> = ({ sitecoreContext }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState(false);
  const [data, setData] = useState<IRatesNotice>({ ratesNoticePreference: {} });
  const [isReceiveByEmail, setIsReceiveByEmail] = useState<boolean>(false);
  const [isProcessingEmail, setIsProcessingEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
  const [currentPathname, setCurrentPathname] = useState<string>(location.pathname);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const ratesNoticeDeliveryId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const isEmail =
    data.ratesNoticePreference.preference?.toLowerCase() === EMAIL.toLocaleLowerCase();
  const isMultipleOwner = data.ratesNoticePreference?.multipleOwners;
  const receiveByPost = data.ratesNoticePreference?.receiveByPost;

  const isGreyOut = () => {
    if (isMultipleOwner) {
      return !receiveByPost;
    } else {
      return isEmail;
    }
  };

  //Info: handle call api get initial Data
  useEffect(() => {
    getRatesNoticeDeliveryData(
      ratesNoticeDeliveryId,
      (res) => setData(res?.data),
      (res) => setError(res)
    );
    const routeListener = history.listen((location) => {
      setCurrentPathname(location.pathname);
    });

    return () => {
      routeListener();
    };
  }, []);

  // on receive data
  useEffect(() => {
    // update data
    if (Object.keys(data.ratesNoticePreference).length > 0) {
      setIsReceiveByEmail(
        data.ratesNoticePreference.preference?.toLowerCase() === EMAIL.toLocaleLowerCase()
      );
      setEmail(data.ratesNoticePreference.email || '');
    }
    // revert to origin state
    setIsProcessingEmail(false);
    setIsEditingEmail(false);
  }, [data.ratesNoticePreference]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    setIsErrorEmail(!EMAIL_PATTERN.test(email));
  }, [email]);

  // toggle email
  const handleToggleEmail = () => {
    setIsProcessingEmail(true);
    setIsReceiveByEmail(!isReceiveByEmail);
    if (isErrorEmail) {
      const resetEmail = data.ratesNoticePreference.email || '';
      setEmail(resetEmail);
      updateRatesNoticeDeliveryFc(isReceiveByEmail ? POST : EMAIL, resetEmail);
    } else {
      updateRatesNoticeDeliveryFc(isReceiveByEmail ? POST : EMAIL, email);
    }
  };

  const handleUpdate = () => {
    if (!isErrorEmail) {
      updateRatesNoticeDeliveryFc(isReceiveByEmail ? EMAIL : POST, email);
    }
  };

  // update rates notice delivery
  const updateRatesNoticeDeliveryFc = async (preference: string, email: string) => {
    setIsUpdating(true);
    const refreshToken = await getRefreshToken(
      currentPathname,
      sitecoreContext.language,
      history,
      currentPathname
    );
    updateRatesNoticeDelivery(
      ratesNoticeDeliveryId,
      {
        ratesNoticePreference: {
          preference,
          email
        }
      },
      (res) => {
        setData(res?.data);
        setIsUpdating(false);
      },
      (res) => {
        setError(res);
        setIsUpdating(false);
      },
      {
        headers: {
          requestVerificationToken: refreshToken
        }
      }
    );
  };

  const cancelEditing = () => {
    setIsEditingEmail(false);
    setEmail(data.ratesNoticePreference.email);
  };

  if (Object.keys(data.ratesNoticePreference).length === 0) return <></>;

  return (
    <CardWrapper>
      <CardHeader>
        <CardTitle>{t('property-rates-notice-delivery-heading')}</CardTitle>
        <EmailSwitchContainer>
          <Toggle
            id="RatesNoticeDeliveryEmail"
            name="RatesNoticeDeliveryEmail"
            value={isReceiveByEmail}
            onChange={handleToggleEmail}
            checked={isReceiveByEmail}
            isProcessing={isProcessingEmail}
          />
          <SwitchLabel>{t(`property-rates-notice-delivery-receive-by-email`)}</SwitchLabel>
        </EmailSwitchContainer>
      </CardHeader>
      <RowContainer>
        <DescriptionText>{t('property-rates-notice-delivery-description')}</DescriptionText>
      </RowContainer>
      {/* multiple owners tip */}
      <RowContainer>
        <TipBox
          tipTitle={
            data.ratesNoticePreference?.multipleOwners
              ? t('property-rates-notice-delivery-multiple-owner-heading')
              : t('property-rates-notice-delivery-single-owner-heading')
          }
          tipDescription={
            data.ratesNoticePreference?.multipleOwners
              ? t('property-rates-notice-delivery-multiple-owner-description')
              : t('property-rates-notice-delivery-single-owner-description')
          }
        />
      </RowContainer>
      {/* receive by email on */}
      {isEmail && (
        <>
          <RowContainer>
            <OptionTitle>{t('property-rates-notice-delivery-email')}</OptionTitle>
          </RowContainer>
          {isEditingEmail ? (
            <InputContainer>
              <TextareaAutoResize
                id="ratesNoticeEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={isErrorEmail ? 'error' : ''}
                rows={1}
              />
              {isErrorEmail && (
                <ErrorMessage>
                  <ErrorIcon>
                    <SvgExclamationTriangle />
                  </ErrorIcon>
                  {t('property-rates-notice-delivery-email-invalid')}
                </ErrorMessage>
              )}
              <InputActionsContainer>
                <ButtonUpdate onClick={() => handleUpdate()}>
                  {isUpdating && (
                    <RefreshIcon>
                      <SvgRefresh />
                    </RefreshIcon>
                  )}
                  {t('property-rates-notice-delivery-update')}
                </ButtonUpdate>
                <ButtonLink onClick={cancelEditing}>
                  {t('property-rates-notice-delivery-cancel')}
                </ButtonLink>
              </InputActionsContainer>
            </InputContainer>
          ) : (
            <OptionValueContainer>
              <OptionValue>{data.ratesNoticePreference?.email}</OptionValue>
              <ButtonLink onClick={() => setIsEditingEmail(true)}>
                {t('property-rates-notice-delivery-edit')}
              </ButtonLink>
            </OptionValueContainer>
          )}
        </>
      )}
      {/* receive by post */}
      <RowContainer>
        <OptionTitle isGreyOut={isGreyOut()}>
          {t('property-rates-notice-delivery-post')}
        </OptionTitle>
        <OptionValueContainer>
          <OptionValue isGreyOut={isGreyOut()}>
            {data.ratesNoticePreference?.postalAddress}
          </OptionValue>
          <EditLink href={data.ratesNoticePreference?.postalAddressEditLink} target="_blank">
            {t('property-rates-notice-delivery-edit')}
            <SvgWrapper isInLine={true} >
              <SvgExternal />
            </SvgWrapper>
          </EditLink>
        </OptionValueContainer>
      </RowContainer>
      <RowContainer>
        <Disclaimer>
          <ContentRichText field={{ value: t('property-rates-notice-delivery-note') }} />
        </Disclaimer>
      </RowContainer>
      <Divider />
    </CardWrapper>
  );
};

const RatesNoticeDeliveryCardFallback: FC = () => {
  const [t] = useTranslation();
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <CardWrapper>
      <CardTitle>{t('property-rates-notice-delivery-heading')}</CardTitle>
      <FallbackMessageContainer>
        <TextMessage fields={errorMessage} textOnly={true} />
      </FallbackMessageContainer>
    </CardWrapper>
  );
};

export default compose(withSitecoreContext())(
  withErrorBoundary(RatesNoticeDeliveryCard, RatesNoticeDeliveryCardFallback)
);

export const RatesNoticeDeliveryCardStorybook = withErrorBoundary(
  RatesNoticeDeliveryCard,
  RatesNoticeDeliveryCardFallback
);
