import {
    individualObj,
    itemState,
    organisationObj
} from "_components/CommunityEngagement/CommunityEngagementProfile/definition";

const iconIndividual = [
    "email",
    "suburb",
    "relationships",
    "gender",
    "birthYear",
    "languageSpokenAtHome"
];

const iconOrganisation = [
    "email",
    "organisationName",
    "organisationABNorACN",
    "organisationType",
    "role",
    "phone",
    "address"
]

export const definition = {
    original: "original",
    individual: "Individual",
    organisation: "Organisation"
}

export const actions = {
    updateProfile: "update",
    addNewProfile: "addNew",
    deleteProfile: "delete"
};


export const convertToArray = (obj: any) => {
    let newArr = [];
    const arrayIcon = obj.type === definition.individual ? iconIndividual : iconOrganisation;
    const filteredArray = Object.keys(obj).filter(value => arrayIcon.includes(value));
    filteredArray.map((key, idx) => {
        if(typeof obj[key] === "object"){
            obj[key].map((value) => {
                newArr.push({
                    type: key,
                    value: value
                })
            })
        }else {
            newArr.push({
                type: key,
                value: obj[key]
            })
        }
    });
    return newArr;
}

export const handleComplex = (item: any) => {
    if(item && convertToArray(item).length > 0){
        return convertToArray(item);
    }
    return []
}

export const checkExist = (value: string, arr: any) => {
    const existed = arr.filter((item) => item === value);
    if(existed.length > 0){
        return true;
    }
    return false;
}

export const intersection = (o1: itemState, o2: individualObj | organisationObj, action: string) => {
    let newObj = {};
    const arrBindings = Object.keys(o1).concat(Object.keys(o2)).sort().reduce(function (r, a, i, aa) {
        if (i && aa[i - 1] === a) {
            r.push(a);
        }
        return r;
    }, []);
    arrBindings.forEach((key) => {
        newObj[key] = o1[key]
    })
    if(action === actions.addNewProfile){
        delete newObj['id']
    }
    return newObj;
}

export const checkProperties = (obj) => {
    for (let key in obj) {
        if (obj[key] !== null && obj[key] != "")
            return false;
    }
    return true;
}

const checkEmpty = (value: string) => {
    if(!value || value === ""){
        return true;
    }
    return false;
}

const checkEmail = (value: string) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return value.toLowerCase().match(regex);
}

const checkPhoneNumber = (value: string) => {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return value.toLowerCase().match(regex);
}

const checkOnlyNumber = (value: string) => {
    return /^\d+$/.test(value);
}

const checkYear = (value: number) => {
    return (value >= 1900 && value <= new Date().getFullYear());
}

const checkSpecialCharacter = (value: string) => {
    const specialChars =
        /[`!@#$%^&*_+\=\[\]{}\\|<>\/?~]/;
    return !specialChars.test(value);
}

export const handleCheckInput = (value: string, type: string) => {
    switch (type){
        case 'email':
            if(!checkEmpty(value) && checkEmail(value)){
                return true;
            }
            break;
        case 'phone':
            if(!checkEmpty(value) && checkPhoneNumber(value)){
                return true;
            }
            break;
        case 'birthYear':
            if(!checkEmpty(value) && checkOnlyNumber(value) && checkYear(parseInt(value))){
                return true;
            }
            break;
        default: if(!checkEmpty(value) && checkSpecialCharacter(value)){
            return true;
        }
    }
    return false;
}