import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from '_containers/Theme/colors';
import SvgCheckOutline from '_utils/icons/CalloutMessageBox/CheckOutline';
import SvgCalculator from '_utils/icons/ScheduledPayments/Calculator';
import WarningOutLine from '_utils/icons/announcement/WarningOutLine';
import SvgInfoOutLine from '_utils/icons/announcement/InfoOutLine';
import SvgRightArrow from '_utils/icons/CalloutMessageBox/RightArrow';

import {
  CalloutMessageBoxContainer,
  CalloutHeader,
  CalloutTitleContainer,
  IconContainer,
  Title,
  CalloutDescription,
  WhatYouNeedToDo,
  WhatYouNeedToDoTitle,
  WhatYouNeedToDoDescription,
  RichText,
  WhatYouNeedToDoTextButton,
  TextButtonIconContainer,
  TextButtonIcon,
  WhatYouNeedToDoActionLink
} from './StyledCalloutMessageBox';

type CalloutMessageBoxProps = {
  fields: any;
  params: any;
};

const CALL_OUT_MESSAGE_BOX_TYPES = {
  confirmation: {
    icon: <SvgCheckOutline />,
    backgroundColor: Colors.greenTint110
  },
  results: {
    icon: <SvgCalculator />,
    backgroundColor: Colors.greenTint110
  },
  warning: {
    icon: <WarningOutLine />,
    backgroundColor: Colors.annandaleOrangeTint110
  },
  notice: {
    icon: <SvgInfoOutLine />,
    backgroundColor: Colors.blueTint75
  }
};

const CalloutMessageBox: React.FC<CalloutMessageBoxProps> = ({ fields, params }) => {
  const [t] = useTranslation();
  const dataSource = fields?.data?.datasource;
  const { type, headingText, headingDescription, description, link } = dataSource || {};
  const { showSubheading } = params || {};
  const showWhatYouNeedToDo =
    showSubheading === '1' || description?.value || link?.jss?.value?.href;

  return type?.value ? (
    <CalloutMessageBoxContainer>
      <CalloutHeader
        backgroundColor={CALL_OUT_MESSAGE_BOX_TYPES[type.value.toLowerCase()].backgroundColor}
      >
        <CalloutTitleContainer>
          <IconContainer>{CALL_OUT_MESSAGE_BOX_TYPES[type.value.toLowerCase()].icon}</IconContainer>
          {headingText?.value && <Title>{headingText?.value}</Title>}
        </CalloutTitleContainer>
        {headingDescription?.value && (
          <CalloutDescription>
            <RichText field={headingDescription} />
          </CalloutDescription>
        )}
      </CalloutHeader>
      {/* What you need to do */}
      {showWhatYouNeedToDo && (
        <WhatYouNeedToDo>
          {showSubheading === '1' && (
            <WhatYouNeedToDoTitle>
              {t('callout-message-box-what-you-need-to-do')}
            </WhatYouNeedToDoTitle>
          )}
          {description?.value && (
            <WhatYouNeedToDoDescription>
              <RichText field={description} />
            </WhatYouNeedToDoDescription>
          )}
          {link?.jss?.value?.href && (
            <WhatYouNeedToDoActionLink>
              <WhatYouNeedToDoTextButton>
                {link.jss.value?.text}
                <TextButtonIconContainer>
                  <TextButtonIcon>
                    <SvgRightArrow />
                  </TextButtonIcon>
                </TextButtonIconContainer>
              </WhatYouNeedToDoTextButton>
            </WhatYouNeedToDoActionLink>
          )}
        </WhatYouNeedToDo>
      )}
    </CalloutMessageBoxContainer>
  ) : null;
};

export default CalloutMessageBox;
