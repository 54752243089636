import React, { useState, useEffect } from 'react';
import PersonalDetailsCard from '../PersonalInfo/PersonalDetailsCard';
import { CardWrapper } from './StyledPersonalInfo';
import ContactDetailsCard from '../PersonalInfo/ContactDetailsCard';
import NotificationsRemindersCard from '../PersonalInfo/NotificationsRemindersCard';
import { getPersonalInfo, getListCountry } from '_services/personalInfoService';
import { ICountry } from './AutoCompleteAddress/SelectCountry';
import { DEFAULT_COUNTRY } from './AutoCompleteAddress/definitions';
import { compose } from 'ramda';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation, useHistory } from 'react-router-dom';

type PersonalInfoProps = {
  fields: any;
  rendering: any;
  sitecoreContext: any;
};

interface IPersonalInfoContext {
  name: string;
  residentialAddress: string;
  email: string;
  mobile: string;
  sameAsResidentialAddress: boolean;
  postalAddress: string;
  emailNotification: boolean;
  mobileTextNotification: boolean;
}

export const PersonalInfoContext = React.createContext(null);

const PersonalInfo: React.FC<PersonalInfoProps> = ({ fields, rendering, sitecoreContext }) => {
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [personalInfo, setPersonalInfo] = useState<IPersonalInfoContext>(null);
  const [listCountry, setListCountry] = useState<ICountry[]>([]);
  const [currentPathname, setCurrentPathname] = useState<string>(location.pathname);
  const datasource = fields?.data?.datasource;

  const contextValue = {
    error,
    personalInfo,
    setPersonalInfo,
    listCountry,
    sitecoreContext,
    history,
    currentPathname
  };

  // Call API to update Personal Info
  useEffect(() => {
    getPersonalInfo(
      (res) => setPersonalInfo(res.data.personalInfo),
      (err) => setError(err)
    );

    // get list country
    getListCountry(
      (res) =>
        setListCountry(
          res?.data.filter(
            (country: ICountry) =>
              country.iso3.toLocaleLowerCase() !== DEFAULT_COUNTRY.iso3.toLocaleLowerCase()
          )
        ),
      (res) => setError(res)
    );
    const routeListener = history.listen((location) => {
      setCurrentPathname(location.pathname);
    });

    return () => {
      routeListener();
    };
  }, []);

  return (
    <PersonalInfoContext.Provider value={contextValue}>
      <div>
        <CardWrapper>
          <PersonalDetailsCard datasource={datasource} />
        </CardWrapper>
        <CardWrapper>
          <ContactDetailsCard datasource={datasource} />
        </CardWrapper>
        <CardWrapper>
          <NotificationsRemindersCard datasource={datasource} rendering={rendering} />
        </CardWrapper>
      </div>
    </PersonalInfoContext.Provider>
  );
};

export default compose(withSitecoreContext())(PersonalInfo);

export const PersonalInfoStorybook = PersonalInfo;
