export type AutoCompleteAddressProps = {
  inputLabelDictionary: string;
  inputName: string;
  inputValue: string;
};

export type SearchResultItem = {
  key: string;
  text: string;
};

export type SuburbSearchResultItem = {
  postcode: string;
  state: string;
  suburb: string;
};

export interface IAddressDetails {
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  locality: string;
  region: string;
  postal_code: string;
  country: string;
}

export interface IAddress {
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
}

export interface IAddressData {
  personalInfo: {
    sameAsResidentialAddress?: boolean;
    postalAddress?: IAddress | string;
    residentialAddress?: IAddress | string;
  };
}

export const DEFAULT_COUNTRY = {
  iso3: 'AUS',
  iso2: 'AU',
  name: 'Australia'
};
