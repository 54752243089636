import React from 'react';
import { compose, mergeDeepRight } from 'ramda';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { withEditMode, withEditModeNotice } from '_containers/BaseComponent';

import { QuickLinksProps, Link } from './definitions';
import {
  QuickLink,
  Heading,
  Description,
  TextWrapper,
  LinkWrapper,
  LinkButton,
  LinkItem,
  LinkList,
  EditorDiv
} from './StyledQuickLinks';
import { warnInDev } from '_utils/helpers/dev';

const EditorText = compose(withEditModeNotice)(EditorDiv);

const renderTextField = (field, editMode, markup) => {
  return editMode === false && !field?.jss?.value ? null : markup;
};

const renderLinks = (links: Link[]) => {
  const formattedLinks = links.map((l) => {
    if (l.ctaLink.jss.value.anchor !== '') {
      const anchorLink = l.ctaLink.jss.value.href + l.ctaLink.jss.value.anchor
      return mergeDeepRight(l, { ctaLink: { jss: { value: { href: anchorLink } } } })
    } return l
  });
  if (links.length === 1) {
    // button style
    return (
      <LinkWrapper multiLinks={false}>
        <LinkButton field={links[0].ctaLink.jss} linkTheme="none" linkSize="s" />
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper multiLinks={true}>
      <LinkList>
        {formattedLinks.map((l) => (
          <li key={l.id}>
            <LinkItem
              field={l.ctaLink.jss}
              linkTheme="secondary"
              linkSize="s"
              className="multiLink"
              description={l?.ctaLink?.jss?.value?.text}
            />
          </li>
        ))}
      </LinkList>
    </LinkWrapper>
  );
};

const renderBottomLink = (link) => {
  if (!link?.jss?.value?.href) {
    warnInDev('bottom link is null');
    return null;
  }

  return (
    <LinkWrapper multiLinks={false}>
      <LinkItem
        field={link?.jss}
        description={link?.jss?.value?.text}
        linkTheme="secondary"
        linkSize="s"
        className="bottomLink"
      />
    </LinkWrapper>
  );
};

export const QuickLinks: React.FC<QuickLinksProps> = ({ fields, editMode }) => {
  if (fields.data.datasource === null) {
    if (editMode) {
      return (
        <EditorText
          noticeEnabled
          noticeLevel="warning"
          noticeMessage={`This component needs a datasource to render.`}
        />
      );
    }

    return null;
  }

  const { title, description, links, link } = fields.data.datasource;

  const validatedLinks = links.filter((l) => editMode || l?.ctaLink?.jss?.value?.href !== '');
  const validatedBottomLink = link?.jss?.value?.href !== '';

  let noticeEnabled =
    (validatedLinks.length === 0 || !validatedBottomLink) && editMode ? true : false;

  const component = (
    <QuickLink multiLinks={validatedLinks.length > 1}>
      <TextWrapper>
        {renderTextField(
          title,
          editMode,
          <Heading>
            <Text field={title.jss} />
          </Heading>
        )}
        {renderTextField(
          description,
          editMode,
          <Description>
            <Text field={description.jss} />
          </Description>
        )}
      </TextWrapper>
      {renderLinks(validatedLinks)}
      {renderBottomLink(link)}
    </QuickLink>
  );

  return editMode ? (
    <EditorText
      noticeEnabled={noticeEnabled}
      noticeMessage={'This component has no valid links or bottom link and will not be displayed.'}
    >
      {component}
    </EditorText>
  ) : (
      component
    );
};

export default compose(withEditMode)(QuickLinks);
