import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WarningOutLine from '_utils/icons/announcement/WarningOutLine';
import SvgDeclined from '_utils/icons/common/Declined';
import ContentRichText from '_components/Corporate/ContentRichText';

import {
  DialogueBackground,
  DialogueContainer,
  DialogueBody,
  IconContainer,
  Content,
  Title,
  Description,
  DialogueFooter,
  CancelButton,
  ApplyButton
} from './StyledDialogueBox';

interface ITranslationKeys {
  title: string;
  description: string;
  cancel?: string;
  apply: string;
}

interface DialogueBoxProps {
  isOpen: boolean;
  translationKeys: ITranslationKeys;
  type: string;
  actionCallbackFn: () => void;
  cancelCallbackFn: () => void;
  isFixed?: boolean;
}

const DIALOGUE_TYPES = {
  default: {
    icon: <WarningOutLine />
  },
  warning: {
    icon: <WarningOutLine />
  },
  ban: {
    icon: <SvgDeclined />
  }
};

const DialogueBox: React.FC<DialogueBoxProps> = ({
  isOpen,
  translationKeys,
  type,
  actionCallbackFn,
  cancelCallbackFn,
  isFixed
}) => {
  const [t] = useTranslation();
  const [isOpenDialogue, setIsOpenDialogue] = useState<boolean>(false);

  // open dialog by property
  useEffect(() => {
    setIsOpenDialogue(isOpen);
  }, [isOpen]);

  // click outside to cancel
  const handleClickOutside = () => {
    if (!isFixed) cancelCallbackFn();
  };

  return (
    <>
      {isOpenDialogue && (
        <>
          <DialogueBackground onClick={handleClickOutside} />
          <DialogueContainer>
            <DialogueBody>
              <IconContainer>
                {DIALOGUE_TYPES[type]?.icon || DIALOGUE_TYPES['default'].icon}
              </IconContainer>
              <Content>
                <Title>{t(translationKeys.title)}</Title>
                <Description>
                  <ContentRichText field={{ value: t(translationKeys.description) }} />
                </Description>
              </Content>
            </DialogueBody>
            <DialogueFooter>
              {translationKeys.cancel &&
                <CancelButton onClick={cancelCallbackFn}>{t(translationKeys.cancel)}</CancelButton>
              }
              <ApplyButton isOnlyOneButton={!!translationKeys.cancel} onClick={actionCallbackFn}>{t(translationKeys.apply)}</ApplyButton>
            </DialogueFooter>
          </DialogueContainer>
        </>
      )}
    </>
  );
};

DialogueBox.displayName = 'DialogueBox';

export default DialogueBox;
