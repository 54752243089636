import styled, { css } from 'styled-components';
import { color, mq, buttonReset, safeFocusRemoval, focusOutline, svg } from '_utils/styles';
import { BackgroundColors } from '_utils/components/Corporate/Section';
import { spacing } from '_utils/props';
import { heading_s, text_s } from '_utils/styles/text/index';
import { NavLink } from 'react-router-dom';

export const ProfileCardWrapper = styled.div`
  background-color: ${BackgroundColors.White};
  border: 1px solid ${color('all.deepBlueTint15')};
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:not(:first-child) {
    border-top: none;
  }
`;

export const SingleItem = styled.div`
  ${({ theme }) => spacing({ theme, p: 3 })};

  &:not(:last-child) {
    border-bottom: 1px solid ${color('all.deepBlueTint15')};
  }
`;

export const Name = styled.h3`
  ${heading_s}
  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
`;

export const Role = styled.p`
  ${({ theme }) => spacing({ theme, mb: 1 })};
`;

export const Description = styled.p`
  ${text_s}
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mt: 0, mb: 2 })};
`;

export const InformationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 2 })};
  }
`;

const InformationTextCSS = css`
  ${text_s}
  ${({ theme }) => spacing({ theme, my: 0, ml: 1 })};
  color: ${color('all.deepBlue')};
  flex: 1;
`;

const InformationLinkCSS = css`
  ${InformationTextCSS}
  text-decoration-line: underline;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InformationText = styled.span`
  ${InformationTextCSS}
`;

export const InformationLink = styled.a`
  ${InformationLinkCSS}
`;

export const InformationWebsite = styled(NavLink)`
  ${InformationLinkCSS}
`;

export const InformationIcon = styled.div`
  position: relative;
  flex: 0 0 20px;

  ${mq('md')} {
    flex: 0 0 24px;
  }

  svg {
    width: 20px;
    height: 20px;
    position: absolute;

    ${mq('md')} {
      top: 5px;
    }

    path {
      fill: ${color('all.deepBlueTint75')};
    }
  }
`;

export const CopyEmailAddress = styled.div`
  color: ${color('all.greenTint110')};
  text-transform: uppercase;
  font-weight: bold;
  font-family: Helvetica Now Micro;
  font-size: 10px;
  line-height: 16px;
  text-decoration: none;
  flex: 0 0 100%;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 28px;

  ${mq('md')} {
    margin-left: 32px;
  }
`;

export const ShowMoreProfileCardLink = styled.button`
  ${buttonReset}
  ${text_s};
  ${({ theme }) => spacing({ theme, m: 0 })};
  box-shadow: inset 0 -2px ${color('all.green')};
  display: flex;
  align-items: center;

  &:hover {
    background: ${color('all.green')};
    color: ${color('all.deepBlue')};
    transition: background-color 0.2s, color 0.2s;
    svg {
      path {
        fill: ${color('all.deepBlue')};
      }
    }
  }

  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }

  svg {
    path {
      fill: ${color('all.deepBlue')};
    }
  }
`;

export const Icon = styled.span`
  ${svg(20, 20)}
  width: 20px;
`;
