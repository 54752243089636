import React from 'react';
import { withSvg } from '../Svg';

const SvgRefresh = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.09 13.51H6.62C6.88522 13.51 7.13957 13.6154 7.32711 13.8029C7.51464 13.9904 7.62 14.2448 7.62 14.51C7.62 14.7752 7.51464 15.0296 7.32711 15.2171C7.13957 15.4046 6.88522 15.51 6.62 15.51H4.22C5.32306 16.6627 6.7456 17.4593 8.30484 17.7974C9.86408 18.1355 11.4888 17.9996 12.9702 17.4072C14.4517 16.8149 15.7221 15.7931 16.6184 14.4732C17.5147 13.1532 17.9958 11.5955 18 10C18 9.73478 18.1054 9.48043 18.2929 9.29289C18.4804 9.10536 18.7348 9 19 9C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10C19.9947 11.9528 19.4178 13.8613 18.3404 15.49C17.263 17.1187 15.7323 18.3964 13.9373 19.1652C12.1422 19.9341 10.1613 20.1605 8.23898 19.8166C6.3167 19.4727 4.53717 18.5735 3.12 17.23V19C3.12 19.2652 3.01464 19.5196 2.82711 19.7071C2.63957 19.8946 2.38522 20 2.12 20C1.85478 20 1.60043 19.8946 1.41289 19.7071C1.22536 19.5196 1.12 19.2652 1.12 19V14.5C1.12247 14.2416 1.22484 13.9943 1.40567 13.8097C1.5865 13.6251 1.83174 13.5177 2.09 13.51ZM10 0C12.5636 0.00731219 15.0265 0.998914 16.88 2.77V1C16.88 0.734784 16.9854 0.48043 17.1729 0.292893C17.3604 0.105357 17.6148 0 17.88 0C18.1452 0 18.3996 0.105357 18.5871 0.292893C18.7746 0.48043 18.88 0.734784 18.88 1V5.5C18.88 5.76522 18.7746 6.01957 18.5871 6.20711C18.3996 6.39464 18.1452 6.5 17.88 6.5H13.38C13.1148 6.5 12.8604 6.39464 12.6729 6.20711C12.4854 6.01957 12.38 5.76522 12.38 5.5C12.38 5.23478 12.4854 4.98043 12.6729 4.79289C12.8604 4.60536 13.1148 4.5 13.38 4.5H15.78C14.6775 3.34787 13.2559 2.5515 11.6976 2.21311C10.1393 1.87472 8.51541 2.00975 7.03439 2.60086C5.55338 3.19198 4.2828 4.21221 3.38577 5.5306C2.48873 6.849 2.00616 8.40539 2 10C2 10.2652 1.89464 10.5196 1.70711 10.7071C1.51957 10.8946 1.26522 11 1 11C0.734784 11 0.480429 10.8946 0.292892 10.7071C0.105356 10.5196 0 10.2652 0 10C0 8.68678 0.258657 7.38642 0.761204 6.17317C1.26375 4.95991 2.00035 3.85752 2.92893 2.92893C3.85752 2.00035 4.95991 1.26375 6.17317 0.761205C7.38642 0.258658 8.68678 0 10 0Z"
      fill="white"
    />
  </svg>
);

export default withSvg(20, 20)(SvgRefresh);
