import React, { useState } from "react";
import { Grid } from "_utils/components/Corporate";
import { imagePlaceholderSrc } from "_utils/styles";
import {
    convertSrcJss,
    createSrcSet
} from "_utils/media";
import { OpenForFeedback } from "_utils/icons";
import Arrow from "_utils/icons/Map/Arrow";
import NavLink from "_components/Corporate/NavItem";
import {
    Container,
    LeftContainer,
    RightContainer,
    Figure,
    Image,
    TitleWrapper,
    HeaderWrapper,
    ArrowBannerWrapper,
    LinkIconBannerContainer,
    TitleBanner,
    TitleIconWrapper
} from "./StyledInfo";
import {
    getLink,
    isValidLinkObject
} from "_utils/helpers/link";

interface infoProps{
    item: any
}
const Info:React.FC<infoProps> = ({ item }) => {
    const [col, setCol] = useState(5);

    window.addEventListener("resize", () => {
        if(window.innerWidth < 575){
            setCol(5)
        }else {
            setCol(4)
        }
    });

    const linkObject = getLink(item.link);

    return (
        <Container>
            <Grid.Row config={{ gutters: [] }} style={{ flex: 1, alignItems: "flex-start" }}>
                <Grid.Item config={{ col: { xs: col, sm: 4, md: 4 }, gutters: [] }}>
                    <LeftContainer>
                        <Figure id="imagecolumn">
                            <Image
                                className="lazyload"
                                draggable={false}
                                field={
                                    {
                                        value: {
                                            src: imagePlaceholderSrc,
                                            alt: item?.image?.value?.alt || '',
                                            'data-src': convertSrcJss(item?.image?.value?.src) + '?mw=1200',
                                            'data-srcset': createSrcSet(item?.image?.value?.src, [640, 1200, 1600])
                                        }
                                    }
                                }
                            />
                        </Figure>
                    </LeftContainer>
                </Grid.Item>
                <Grid.Item config={{ col: { xs: 12 - col, sm: 8, md: 8 }, gutters: [] }}>
                    <RightContainer>
                        <TitleWrapper>
                            <OpenForFeedback color={"#4E5A5F"}/>
                            {item.title}
                        </TitleWrapper>
                        <HeaderWrapper>
                            <NavLink StyledLink={LinkIconBannerContainer} link={linkObject}>
                                <TitleBanner>
                                    {item.description}
                                    {isValidLinkObject(linkObject) && (
                                        <TitleIconWrapper>
                                            <ArrowBannerWrapper>
                                                <Arrow ariaHidden={true} />
                                            </ArrowBannerWrapper>
                                        </TitleIconWrapper>
                                    )}
                                </TitleBanner>
                            </NavLink>
                        </HeaderWrapper>
                    </RightContainer>
                </Grid.Item>
            </Grid.Row>
        </Container>
    )
}

export default Info