import { spacing } from "_utils/props";
import {
    buttonReset,
    heading_m,
    hideOnPrint,
    mq,
    svg,
    text_s
} from "_utils/styles";
import styled from "styled-components";

export const ItemContainer = styled.div`
  ${({theme}) => spacing({theme, p: 3, mb: 4})};
  border: 1px solid #DDDEE1;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  
  &:first-child {
    border-top-left-radius: ${({theme}) => theme.accordion.borderRadius}px;
    border-top-right-radius: ${({theme}) => theme.accordion.borderRadius}px;
  }
  
  &:last-child {
    border-bottom-left-radius: ${({theme}) => theme.accordion.borderRadius}px;
    border-bottom-right-radius: ${({theme}) => theme.accordion.borderRadius}px;
    ${({theme}) => spacing({theme, mb: 0})};
  }
  
`

export const RadioText = styled.div`
  ${heading_m};
  ${mq.lessThan('md')}{
    line-height: 24px;
  }
  color: #041C2C;
`

export const Button = styled.button`
  ${buttonReset}
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  position: relative;
  outline: 0;

  ${ItemContainer}:first-child &:before {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  ${ItemContainer}:last-child &:before {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${ItemContainer}:last-child.expanded &:before {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const CollapsedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  & > div > label{
    ${({theme}) => spacing({theme, pl: 6})}
  }
  & > div > div > label{
    ${({theme}) => spacing({theme, pl: 6})}
  }
  
  ${mq.lessThan('md')}{
    & > div > div > label{
      ${({theme}) => spacing({theme, pl: 8})}
    };
    & > div > label{
      ${({theme}) => spacing({theme, pl: 8})}
    }
  }
`

export const ExpandedWrapper = styled.div`
  ${({theme}) => spacing({theme, pl: 6})}
  ${mq.lessThan('md')}{
    ${({theme}) => spacing({theme, pl: 8, pt: 1})}
  }
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  ${text_s};
  font-weight: 400;
  color: #4E5A5F;
  
  ${({theme}) => spacing({theme, mr: 1, mt: 1, mb: 0})};
  & svg {
   min-width: 20px;
   max-width: 20px;
   ${({theme}) => spacing({theme, mr: 1})}
  }
  
  ${mq.lessThan('md')}{
    align-items: start;
    width: 100%;
  }
`

export const InfoText = styled.div`
  ${mq.lessThan('sm')}{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`

export const Dot = styled.div`
  max-width: 2px;
  min-width: 2px;
  min-height: 2px;
  max-height: 2px;
  ${({theme}) => spacing({theme, ml: 1})};
  background: #4E5A5F;
  ${mq.lessThan('md')}{
      display: none;
  }
`

export const RowWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  ${mq.lessThan('md')}{
     flex-direction: column;
     align-items: flex-start;
  }
`

export const Content = styled.div`
  overflow: hidden;

  transition-property: height, padding;
  transition-duration: ${({theme}) => theme.motion.timing.fast};
  transition-timing-function: ${({theme}) => theme.motion.ease};
  ${({theme}) => spacing({theme, pl: 3})}

  padding-right: 56px;

  ${mq('md')} {
    padding-right: 72px;
  }

  @media print {
    height: auto !important;
  }
`;

export const EditButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${text_s}
  font-weight: 400;
  ${({theme}) => spacing({theme, m: 0})}
  & svg {
    ${({theme}) => spacing({theme, mr: 2})}
  }
  text-decoration: underline;
  background-color: ${(props) => props.isExpand ? '#DAF5E4': '#FFFFFF'};
  border: none;
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const RightWrapper = styled.div`
  ${mq.lessThan('sm')}{
      max-width: 60%;
  }
`

export const ChevronWrapper = styled.button`
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none;
  ${svg(24, 24)}
  ${({theme}) => spacing({theme, ml: 2})}

  width: 24px;

  ${mq('md')} {
    width: 32px;
    h5 ~ &,
    h6 ~ & {
      width: 24px;
    }
  }

  flex: none;

  @media (hover: hover) {
    ${Button}:hover & {
      path {
        fill: ${({theme}) => theme.accordion.actionHover};
      }
    }
  }

  path {
    fill: ${({theme}) => theme.accordion.action};
  }

  transition-property: transform;
  transition-duration: ${({theme}) => theme.motion.timing.fast};
  transition-timing-function: ${({theme}) => theme.motion.ease};

  ${ItemContainer}.expanded & {
    transform: rotate(180deg);
  }

  ${ItemContainer}.edit-mode & {
    transform: rotate(0);
  }
  ${hideOnPrint}
`;