import React, { useState } from "react";
import {
    Note,
    Question,
    Optional,
    InputWrapper
} from "../StyledQuestions";
import {
    DetailItem,
} from '../StyledOrganisation';
import InputText from "_utils/components/CommunityEngagement/InputText";
import { questionProps } from "../../definition";
import { useTranslation } from "react-i18next";

const QuestionWithInput: React.FC<questionProps> = (
    {
        id,
        className,
        title,
        haveNode = false,
        nodeTitle,
        value,
        type,
        errMsg,
        handleChange,
        isSelect = false,
        isOptional,
        placeHolder,
        children,
        disabled,
        questionInputTittleStyle,
        questionInputTitleStyleCapable
    }) => {
    const [t] = useTranslation();
    const [active, setActive] = useState(false);

    const renderTitle = () => {
        if (questionInputTittleStyle) {
            return <DetailItem.TextFieldLabel>
                {title}
                {
                    questionInputTitleStyleCapable && <DetailItem.TextFieldSubLabel>
                        {t('personal-info-if-applicable')}
                    </DetailItem.TextFieldSubLabel>
                }
            </DetailItem.TextFieldLabel>
        }
        else {
            return <Question>
                {title} &nbsp;
                {
                    isOptional && (
                        <Optional>
                            {t("community-engagement-profile-input-optional")}
                        </Optional>
                    )
                }
            </Question>
        }
    }
    
    return (
        <>
            {title && renderTitle()}
            {
                haveNode && (
                    <Note>{nodeTitle}</Note>
                )
            }
            {
                !isSelect ? (
                    <InputText
                        id={id}
                        value={value}
                        onChange={(e) => handleChange(e.target?.value, type, true)}
                        errorMsg={errMsg}
                        placeHolder={placeHolder}
                        active={active}
                        disabled={disabled}
                        className={className}
                        onClick={() => setActive(true)}
                        onLeave={() => setActive(false)}
                    />
                ) : (
                    <InputWrapper>{children}</InputWrapper>
                )
            }
        </>
    )
}

export default QuestionWithInput;