import React from 'react';
import { withSvg } from './Svg';

const SvgCheveron = () => (
  <path
    d="M6.29135 7.71501L0.295591 1.71562C0.201929 1.62267 0.127585 1.51208 0.0768524 1.39023C0.0261198 1.26839 5.99922e-07 1.13769 6.11461e-07 1.0057C6.23001e-07 0.873697 0.0261199 0.743004 0.0768525 0.621157C0.127585 0.49931 0.201929 0.388722 0.295591 0.295768C0.388488 0.202049 0.499011 0.127663 0.620784 0.0768993C0.742557 0.0261357 0.873171 -1.14758e-06 1.00509 -1.13605e-06C1.13701 -1.12452e-06 1.26762 0.0261358 1.38939 0.0768994C1.51117 0.127663 1.62169 0.202049 1.71459 0.295768C3.41268 2.09612 5.15666 3.85394 7.00494 5.50025L12.2871 0.295769C12.4753 0.107485 12.7305 0.00170697 12.9966 0.00170699C13.2627 0.00170701 13.5179 0.107485 13.7061 0.295769C13.8943 0.484054 14 0.739422 14 1.0057C14 1.13754 13.974 1.2681 13.9236 1.38991C13.8732 1.51172 13.7993 1.6224 13.7061 1.71562L7.71035 7.71501C7.61531 7.80605 7.50325 7.8774 7.38058 7.92499C7.13729 8.025 6.86441 8.025 6.62112 7.92499C6.49846 7.8774 6.38639 7.80605 6.29135 7.71501Z"
    fill="#041C2C"
  />
);

export default withSvg(14, 8)(SvgCheveron);
