import React, { useContext } from 'react';
import { SvgCertificate } from '_utils/icons/DashboardTabs/Certificate';
import { SvgRegistration } from '_utils/icons/DashboardTabs/Registration';
import { SvgRates } from '_utils/icons/DashboardTabs/Rates';
import { SvgRequest } from '_utils/icons/DashboardTabs/Request';
import { SvgConsultation } from "_utils/icons/DashboardTabs/Consultation";
import { DashboardTabsContext } from '../..';
import { IDashboardTabNavProps } from './definitions';
import {
  Count,
  PreCount,
  Heading,
  IconWrapper,
  Line,
  TabBody,
  TabFooter,
  TabHeader,
  TabWrapper
} from './StyledDashboardTabNav';
import {
  PROPERTIES_TAB,
  PROPERTY_CERTIFICATES_TAB,
  REGISTRATIONS_TAB,
  SERVICE_REQUESTS_TAB,
  CONSULTATIONS_TAB
} from '../../constant';

const TAB_HEADER_ICON = {
  request: {
    iconSvg: <SvgRequest />,
    config: {
      width: { xs: 20, md: 27 },
      height: { xs: 20, md: 27 }
    }
  },
  registration: {
    iconSvg: <SvgRegistration />,
    config: {
      width: { xs: 16, md: 21 },
      height: { xs: 20, md: 27 }
    }
  },
  rates: {
    iconSvg: <SvgRates />,
    config: {
      width: { xs: 18, md: 24 },
      height: { xs: 20, md: 27 }
    }
  },
  certificate: {
    iconSvg: <SvgCertificate />,
    config: {
      width: { xs: 19, md: 25 },
      height: { xs: 20, md: 27 }
    }
  },
  consultations: {
    iconSvg: <SvgConsultation />,
    config: {
      width: { xs: 19, md: 32 },
      height: { xs: 20, md: 30 }
    }
  }
};

const LIST_TAB_NAMES = {
  'service-requests': SERVICE_REQUESTS_TAB,
  registrations: REGISTRATIONS_TAB,
  properties: PROPERTIES_TAB,
  'property-certificates': PROPERTY_CERTIFICATES_TAB,
  consultations: CONSULTATIONS_TAB
};

const DashboardTabNav: React.FC<IDashboardTabNavProps> = ({
  tabName,
  colour,
  icon,
  title,
  count,
  active,
  className,
  tabIndex,
  updateScroll
}) => {
  const dashboardContext = useContext(DashboardTabsContext);
  const changeActiveTab = useContext(DashboardTabsContext)?.changeActiveTab;
  const activeTab = useContext(DashboardTabsContext)?.activeTab;

  const { iconSvg, config } = TAB_HEADER_ICON[icon];

  const handleClickOnTab = () => {
    if (tabName.toLocaleLowerCase() !== activeTab.toLowerCase()) {
      changeActiveTab(tabName);
    }
    updateScroll(tabIndex);
  };

  return (
    <TabWrapper
      className={active ? `${className} active` : className}
      onClick={handleClickOnTab}
      aria-selected={active}
      type="button"
      name="tab-navigation"
      role="tab"
    >
      <TabHeader colour={colour}>
        <IconWrapper iconConfig={config}>{iconSvg}</IconWrapper>
      </TabHeader>
      <TabBody>
        <Heading>
          {title}
          <PreCount />
          <Count>
            (
            {dashboardContext?.error && activeTab === LIST_TAB_NAMES[tabName]
              ? '...'
              : count ?? '...'}
            )
          </Count>
        </Heading>
      </TabBody>
      <TabFooter />
      <Line />
    </TabWrapper>
  );
};

export default DashboardTabNav;
