import React from 'react';
import { withSvg } from '../Svg';

const SvgNotepad = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1667 3.33333C14.1667 2.8731 13.7936 2.5 13.3333 2.5C12.8731 2.5 12.5 2.8731 12.5 3.33333V4.16667H10.8333V3.33333C10.8333 2.8731 10.4602 2.5 10 2.5C9.53976 2.5 9.16667 2.8731 9.16667 3.33333V4.16667H7.5V3.33333C7.5 2.8731 7.1269 2.5 6.66667 2.5C6.20643 2.5 5.83333 2.8731 5.83333 3.33333V4.16667H5C3.61929 4.16667 2.5 5.28595 2.5 6.66667V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V6.66667C17.5 5.28595 16.3807 4.16667 15 4.16667H14.1667V3.33333ZM9.16667 5.83333H7.5V6.66667C7.5 7.1269 7.1269 7.5 6.66667 7.5C6.20643 7.5 5.83333 7.1269 5.83333 6.66667V5.83333H5C4.53976 5.83333 4.16667 6.20643 4.16667 6.66667V15C4.16667 15.4602 4.53976 15.8333 5 15.8333H15C15.4602 15.8333 15.8333 15.4602 15.8333 15V6.66667C15.8333 6.20643 15.4602 5.83333 15 5.83333H14.1667V6.66667C14.1667 7.1269 13.7936 7.5 13.3333 7.5C12.8731 7.5 12.5 7.1269 12.5 6.66667V5.83333H10.8333V6.66667C10.8333 7.1269 10.4602 7.5 10 7.5C9.53976 7.5 9.16667 7.1269 9.16667 6.66667V5.83333ZM13.3333 10.8333C13.7936 10.8333 14.1667 10.4602 14.1667 10C14.1667 9.53976 13.7936 9.16667 13.3333 9.16667H6.66667C6.20643 9.16667 5.83333 9.53976 5.83333 10C5.83333 10.4602 6.20643 10.8333 6.66667 10.8333H13.3333ZM12.5 13.3333C12.5 13.7936 12.1269 14.1667 11.6667 14.1667H6.66667C6.20643 14.1667 5.83333 13.7936 5.83333 13.3333C5.83333 12.8731 6.20643 12.5 6.66667 12.5H11.6667C12.1269 12.5 12.5 12.8731 12.5 13.3333Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgNotepad);
