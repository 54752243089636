import React from 'react';
import { CurveContainer } from './StyledSublayoutWithCurve';

export const withCurve = (WrappedComponent) => {
  const withCurve = (props) => {
    return (
      <CurveContainer>
        <WrappedComponent {...props} />
      </CurveContainer>
    );
  };
  return withCurve;
};
