import React from 'react';

import { AttributeRow, AttributeIcon, AttributeValue } from './StyledTimeEstimate';
import { Time } from '_utils/icons';

const TimeEstimate = ({ estimate }) => {
  return (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <Time ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>{estimate}</AttributeValue>
    </AttributeRow>
  );
};

export default TimeEstimate;
