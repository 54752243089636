import React from 'react';
import { withSvg } from '../Svg';

const SvgBriefcase = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8307 5.41634H13.3307V4.58301C13.3307 3.91997 13.0673 3.28408 12.5985 2.81524C12.1297 2.3464 11.4938 2.08301 10.8307 2.08301H9.16406C8.50102 2.08301 7.86514 2.3464 7.3963 2.81524C6.92745 3.28408 6.66406 3.91997 6.66406 4.58301V5.41634H4.16406C3.50102 5.41634 2.86514 5.67973 2.3963 6.14857C1.92745 6.61742 1.66406 7.2533 1.66406 7.91634V15.4163C1.66406 16.0794 1.92745 16.7153 2.3963 17.1841C2.86514 17.6529 3.50102 17.9163 4.16406 17.9163H15.8307C16.4938 17.9163 17.1297 17.6529 17.5985 17.1841C18.0673 16.7153 18.3307 16.0794 18.3307 15.4163V7.91634C18.3307 7.2533 18.0673 6.61742 17.5985 6.14857C17.1297 5.67973 16.4938 5.41634 15.8307 5.41634ZM8.33073 4.58301C8.33073 4.36199 8.41853 4.15003 8.57481 3.99375C8.73109 3.83747 8.94305 3.74967 9.16406 3.74967H10.8307C11.0517 3.74967 11.2637 3.83747 11.42 3.99375C11.5763 4.15003 11.6641 4.36199 11.6641 4.58301V5.41634H8.33073V4.58301ZM16.6641 15.4163C16.6641 15.6374 16.5763 15.8493 16.42 16.0056C16.2637 16.1619 16.0517 16.2497 15.8307 16.2497H4.16406C3.94305 16.2497 3.73109 16.1619 3.57481 16.0056C3.41853 15.8493 3.33073 15.6374 3.33073 15.4163V11.208H5.83073V12.083C5.83073 12.304 5.91853 12.516 6.07481 12.6723C6.23109 12.8285 6.44305 12.9163 6.66406 12.9163C6.88508 12.9163 7.09704 12.8285 7.25332 12.6723C7.4096 12.516 7.4974 12.304 7.4974 12.083V11.208H12.4974V12.083C12.4974 12.304 12.5852 12.516 12.7415 12.6723C12.8978 12.8285 13.1097 12.9163 13.3307 12.9163C13.5517 12.9163 13.7637 12.8285 13.92 12.6723C14.0763 12.516 14.1641 12.304 14.1641 12.083V11.208H16.6641V15.4163ZM16.6641 9.58301H3.33073V7.91634C3.33073 7.69533 3.41853 7.48337 3.57481 7.32709C3.73109 7.17081 3.94305 7.08301 4.16406 7.08301H15.8307C16.0517 7.08301 16.2637 7.17081 16.42 7.32709C16.5763 7.48337 16.6641 7.69533 16.6641 7.91634V9.58301Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgBriefcase);
