import { NavLink } from 'react-router-dom';
import { spacing } from '_utils/props';
import { heading_m, mq, text_m, linkInText } from '_utils/styles';
import { cssResponsiveSvg } from '_utils/styles/svg';
import styled from 'styled-components';

export const IconWrapperHeader = styled.div`
  ${({ config }) => {
    const { width, height } = config;
    return cssResponsiveSvg(width, height);
  }}
`;

export const IconWrapperBottom = styled.div``;

export const Title = styled.h2`
  ${heading_m}
  color: #041C2C;
`;

export const Description = styled.div`
  ${text_m}
  flex-grow: 1;
  color: #4e5a5f;
  margin-top: 0;

  ${linkInText()}
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  &:focus {
    outline: 3px solid #4060FF;
    outline-offset: 0px;
    border-radius: 6px;
  }
  &:focus{
    margin-top: 3px;
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  border: 1px solid #DDDEE1;
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
  ${({ theme }) => spacing({ theme, p: 3 })}

  ${IconWrapperHeader},
  ${Description} {
    ${({ theme }) => spacing({ theme, mb: 3 })};
  }

  ${Title} {
    ${({ theme }) => spacing({ theme, mb: 1 })};
  }

  ${IconWrapperBottom} {
    svg {
      path {
        fill: #188838;
      }
    }
  }

  &:hover {
    ${Title} {
      color: #188838;
      text-decoration: underline;
    }
  }

  &:focus {
    border: 3px solid #4060FF;
  }
`;
