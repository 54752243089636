import styled from 'styled-components';
import { spacing } from '_utils/props';
import { Text } from "@sitecore-jss/sitecore-jss-react";
import {
  heading_l,
  text_m
} from "_utils/styles";

export const Wrap = styled.div`
  margin: 0;
  overflow: hidden;

  ${({ theme }) => spacing({ theme, my: 2 })}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled(Text)`
  ${heading_l}
  ${({ theme }) => spacing({ theme, mb: 3 })}
`

export const Description = styled(Text)`
  ${text_m}
  ${({ theme }) => spacing({ theme, mb: 3, mt: 0 })}
`

export const FrameWrapper = styled.div`
  width: 100%;
`

export const Frame = styled.iframe`
  width: 100%;
  height: 100vh;
  border-radius: 8px;
`
