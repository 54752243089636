import React from 'react';

import { AttributeRow, AttributeIcon, AttributeValue } from './StyledLastUpdated';

import { dateConverter } from '_utils/helpers';
import { Calendar } from '_utils/icons';
import { warnInDev } from '_utils/helpers/dev';
import { useTranslation } from 'react-i18next';

function LastUpdated({ systemDateUpdated }) {
  const [t] = useTranslation();
  const { value } = systemDateUpdated;
  const output = dateConverter(value);

  if (!output) {
    warnInDev(`LastUpdated : dateConverter failed to convert ${value}`);
    return null;
  }

  const { attrDate, visualDate } = output;

  return (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <Calendar ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>
        {t('publication-page-banner-last-updated-prefix')}{' '}
        <time dateTime={attrDate}>{visualDate}</time>{' '}
      </AttributeValue>
    </AttributeRow>
  );
}

export default LastUpdated;
