import React, { useRef } from 'react'
import useAccordion from '_utils/hooks/useAccordion'
import { Chevron } from '_utils/icons'
import { Radio } from '_utils/components/Corporate'
import {
  ItemContainer,
  Button,
  ChevronWrapper,
  RadioText,
  CollapsedWrapper,
  ExpandedWrapper,
  LeftWrapper,
  RightWrapper,
  EditButton
} from './StyledTab'
import RowWrapper from './RowWrapper'
import { definition } from '_utils/helpers/profileHelper'
import { handleComplex } from '_utils/helpers/profileHelper'
import { tabProps } from '../definition'

const Tab: React.FC<tabProps> = ({ item, selectedValue, handleChange, handleClickNext }) => {
  const contentRef = useRef(null)
  const { individual, organisation } = definition
  const styleActive = {
    background: '#DAF5E4',
    border: '2px solid #2DB84B'
  }
  const { expanded, setExpanded } = useAccordion(contentRef, false)
  const buttonProps = (id) => {
    return {
      'aria-expanded': expanded,
      'aria-controls': `content-${id}`
    }
  }
  const rowItems = handleComplex(item);

  const handleExpand = (e: any) => {
    e.stopPropagation()
    setExpanded((expand) => !expand)
  }

  const handleTitle = (item: any) => {
    switch (item.type) {
      case individual:
        return item.type
      case organisation:
        return item.organisationName
      default:
        return item.type
    }
  }

  const onHandleChange = (item) => {
    handleChange(item.id)
  }

  const handleClickEdit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    handleClickNext(item)
  }

  return (
    <>
      <ItemContainer
        className={`${expanded ? 'expanded' : ''}`}
        style={selectedValue === item.id ? styleActive : {}}
      >
        <Button {...buttonProps(item.id)} onClick={() => onHandleChange(item)}>
          <CollapsedWrapper>
            <RightWrapper>
              <Radio
                id={'sort-item-' + item.id}
                name={`option-${item.id}`}
                value={item.id}
                onChange={() => handleChange(item.id)}
                checked={selectedValue === item.id}
              >
                <RadioText>{handleTitle(item)}</RadioText>
              </Radio>
            </RightWrapper>
            <LeftWrapper>
              <EditButton
                onClick={(e) => handleClickEdit(e)}
                isExpand={selectedValue === item.id}
              >
                Edit
              </EditButton>
              <ChevronWrapper onClick={(e) => handleExpand(e)}>
                <Chevron ariaHidden={false} />
              </ChevronWrapper>
            </LeftWrapper>
          </CollapsedWrapper>
          <ExpandedWrapper>
            {!expanded ? (
              <RowWrapper items={[rowItems[0],rowItems[1]] } />
            ) : (
              <RowWrapper items={rowItems } />
            )}
          </ExpandedWrapper>
        </Button>
      </ItemContainer>
    </>
  )
}

export default Tab
