import React, { useState } from 'react';
// import { isValidMedia, isValidLink } from '_utils/validationChecks/';
import {
  StyledComponentWrapper,
  StyledNotice,
  StyledComponentHighlight
} from './StyledBaseComponent';

import { warnInDev } from '_utils/helpers/dev';
import { getDisplayName } from '_utils/helpers';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
// HOC to wrap component with editMode provider
// (ex BaseComponent instance method 'isEditMode')
export const withEditMode = (Component) => {
  const withEditMode = (props) => {
    return <Component {...props} editMode={isExperienceEditorActive()} />;
  };
  return withEditMode;
};

// HOC to wrap component with disableable render
// (ex BaseComponent instance method componentRender)
//
// this is an inversion of the orig BaseComponent func 'componentRender'
// approach, instead component may *opt-in* to disable render by calling
// props.setRenderDisabled() with bool indicating whether to proceed with
// render or just return null
//
// Note: to get meaningfull debug messages in dev about when render was disabled,
// make withDisableRender right-most function in composition, e.g:
// export default compose( withSitecoreContext(), withFieldValidation, withDisableRender )(ArticleHeaderImage);
//
export const withDisableRender = (Component) => {
  const WithDisableRender = (props) => {
    const editMode = isExperienceEditorActive();

    // wrapped components can set this with a truthy value (debug msg) to prevent render
    const [renderDisabled, setRenderDisabled] = useState();

    // TODO this may get a bit noisy - give options to turn down/off warnings
    if (renderDisabled && !editMode) {
      warnInDev(`${getDisplayName(Component)} was not rendered (${renderDisabled})`);
      return null;
    }

    return (
      <StyledComponentWrapper>
        <Component {...props} editMode={editMode} setRenderDisabled={setRenderDisabled} />
      </StyledComponentWrapper>
    );
  };

  return WithDisableRender;
};

// HOC to wrap component with field validation methods:
// 'isValidField' and 'maybeRenderField' (ex BaseComponent instance methods)
// export const withFieldValidation = (Component) => {
//   const isValidField = (fieldType, field) => {
//     switch (fieldType) {
//       case 'image':
//         return isValidMedia(field);
//       case 'video':
//         return isValidMedia(field);
//       case 'text':
//         return isValidField(field);
//       case 'link':
//         return isValidLink(field);
//       default:
//         warnInDev(`Validation is not configured for field type [${fieldType}]`);
//         return false;
//     }
//   };

// const maybeRenderFld = (editMode, type, field, markup) => {
//   if (editMode) {
//     return markup;
//   }

//   if (isValidField(type, field)) {
//     return markup;
//   }

//   warnInDev(`${getDisplayName(Component)} field ${field} of type ${type} was not rendered `);

//   return null;
// };

//   const withFieldValidation = (props) => {
//     const editMode = isEditMode(props);

//     const maybeRenderField = (type, field, markup) => {
//       maybeRenderFld(editMode, type, field, markup);
//     };

//     return <Component {...props} maybeRenderField={maybeRenderField} isValidField={isValidField} />;
//   };

//   return withFieldValidation;
// };

// HOC to wrap component with editor Notice (ex BaseComponent instance methods)
export const withEditModeNotice = (Component) => {
  const withEditModeNotice = (props) => {
    const editMode = isExperienceEditorActive();

    const { noticeEnabled, noticeMessage = '', noticeLevel = 'warning' } = props;

    if (!editMode || !noticeEnabled) {
      return <Component {...props} />;
    }

    return (
      <div>
        <StyledComponentHighlight noticeLevel={noticeLevel}>
          <Component {...props} />
        </StyledComponentHighlight>
        <StyledNotice noticeLevel={noticeLevel}>{noticeMessage}</StyledNotice>
      </div>
    );
  };
  return withEditModeNotice;
};
