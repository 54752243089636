import styled from 'styled-components';
import { spacing } from '_utils/props';
import { svg, heading_l, heading_m, mq, color } from '_utils/styles';

const ACCORDION_ICON_WIDTH_MD_UP = 32;
const ACCORDION_ICON_WIDTH_SM_DOWN = 24;

//INFO: Property accordion wrapper style
export const PropertyAccordionWrapper = styled.div`
  width: 100%;
  background: #F0F1F2;
  ${({ theme }) => spacing({ theme, py: 7 })}
  position: relative;

  &::before,
  &::after {
    content: '';
    background-color: ${color('all.deepBlueTint07')};
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc((100vw - 100%) / 2);
  }

  &::before {
    left: calc((100vw - 100%) / -2);
  }

  &::after {
    right: calc((100vw - 100%) / -2);
  }
`;

export const SubSection = styled.div`
  ${({ theme }) => spacing({ theme, mb: 6 })}
`;

export const PropertyTitle = styled.h1`
  ${() => heading_l}
`;

export const AccordionWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #dddee1;
`;

export const AccordionItem = styled.div`
  ${({ theme }) => spacing({ theme, px: 3, py: 3 })};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #dddee1;
  }
  
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.background.quaternary};
  }
`;

export const AccordionTitle = styled.div`
  ${() => heading_m}
  display: flex;
  justify-content: space-between;

  &.active {
    color: #188838;
  }

  ${AccordionItem}:hover & {
    color: #188838;
  }
`;

export const AccordionContentContainer = styled.div`
  width: calc(
    100% - ${({ theme }) => theme.responsiveSpacing.md['3'] + ACCORDION_ICON_WIDTH_MD_UP}px
  );

  ${mq.lessThan('md')} {
    width: 100%;
  }
`;

export const AccordionTitleText = styled.div`
  flex-grow: 1;
`;

export const ArrowWrapper = styled.div`
    ${({ theme }) => spacing({ theme, ml: 3 })};
    ${svg(ACCORDION_ICON_WIDTH_MD_UP, ACCORDION_ICON_WIDTH_MD_UP)}
    width: ${ACCORDION_ICON_WIDTH_MD_UP}px;
    display: inline-block;

    ${mq.lessThan('md')} {
        width: ${ACCORDION_ICON_WIDTH_SM_DOWN}px;
    }

    path {
        fill: ${({ theme }) => theme.card.action};
    }

    &.show {
        transform: rotate(180deg);
    }
`;

export const ContentUnavailable = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};
  font-size: 16px;
  line-height: 22px;
  color: ${color('all.deepBlueTint75')};
`;
