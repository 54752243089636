import styled, { css, keyframes } from 'styled-components';
import { color, mq, svg } from '_utils/styles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;

  ${mq('md')} {
    width: 60px;
    height: 32px;
  }

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }
`;

// hidden input (but keep accessible)
export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${color('all.greenTint110')};
  }

  &:checked + .slider:hover {
    background-color: ${({ disabled }) => (disabled ? color('all.greenTint110') : '#1B5928')};
  }

  & + .slider:hover svg {
    path {
      fill: ${color('all.deepBlueTint45')};
    }
  }

  &:checked + .slider:hover svg {
    path {
      fill: ${({ disabled }) => (disabled ? color('all.greenTint110') : '#1B5928')};
    }
  }

  &:checked + .slider svg {
    path {
      fill: ${color('all.greenTint110')};
    }
  }
`;

export const IconContainer = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-transition: margin-left 0.4s;
  transition: margin-left 0.4s;
  border-radius: 50%;
  border: 2px solid #9095A1;

  &.right {
    margin-left: calc(100% - 22px);
  }

  ${mq('md')} {
    width: 32px;
    height: 32px;

    &.right {
      margin-left: calc(100% - 30px);
    }
  }
`;
const RefreshIconWidthCss = css`
  ${svg(14, 14)}
  width: 14px;

  ${mq('md')} {
    ${svg(18, 18)}
    width: 18px;
  }
`;

export const SliderRound = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddee1;
  border: 2px solid #9095A1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#dddee1' : '#4E5A5F')};
    border-color: #4E5A5F;
    ${IconContainer}{
      border-color: #4E5A5F;
    }
    &.active {
      border-color: #1B5928;
      ${IconContainer}{
        border-color: #1B5928;
      }
    }
  }

  ${Input}:disabled ~ & {
    cursor: default;
    &:hover {
      border-color: #9095A1;
      ${IconContainer}{
        border-color: #9095A1;
      }
    }
  }
  
  &.active {
    border-color: ${color('all.greenTint110')};
    ${IconContainer}{
      border-color: ${color('all.greenTint110')};
    }
  }

  svg {
    path {
      fill: #9095A1;
    }

    ${mq('md')} {
      width: 24px;
    }
  }
`;

export const Icon = styled.span`
  ${({ isProcessing }) => (isProcessing ? RotateCss : null)}
  ${svg(20, 20)}
  width: 20px;

  ${mq('md')} {
    ${svg(28, 28)}
    width: 28px;
  }
  ${({ isProcessing }) => (isProcessing ? RefreshIconWidthCss : null)}
`;
