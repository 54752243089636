import React from 'react';
import { withSvg } from './Svg';
import PropTypes from 'prop-types'

const Download = (props) => (
  <path
    fill={props.color || "black"}
    d="M15 16H1C0.734784 16 0.48043 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16ZM7.29 13.71C7.3851 13.801 7.49725 13.8724 7.62 13.92C7.7397 13.9729 7.86913 14.0002 8 14.0002C8.13087 14.0002 8.2603 13.9729 8.38 13.92C8.50275 13.8724 8.6149 13.801 8.71 13.71L13.71 8.71C13.8983 8.5217 14.0041 8.2663 14.0041 8C14.0041 7.7337 13.8983 7.4783 13.71 7.29C13.5217 7.1017 13.2663 6.99591 13 6.99591C12.7337 6.99591 12.4783 7.1017 12.29 7.29L9 10.59V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1V10.59L3.71 7.29C3.61676 7.19676 3.50607 7.1228 3.38425 7.07234C3.26243 7.02188 3.13186 6.99591 3 6.99591C2.86814 6.99591 2.73757 7.02188 2.61575 7.07234C2.49393 7.1228 2.38324 7.19676 2.29 7.29C2.19676 7.38324 2.1228 7.49393 2.07234 7.61575C2.02188 7.73757 1.99591 7.86814 1.99591 8C1.99591 8.13186 2.02188 8.26243 2.07234 8.38425C2.1228 8.50607 2.19676 8.61676 2.29 8.71L7.29 13.71Z"
  />
);

Download.prototype = {
  color: PropTypes.string
}
export default withSvg(16, 18)(Download);
