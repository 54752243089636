import styled from 'styled-components';
import { text_s, iconColorOnPrint, mq } from '_utils/styles';
import svg from '../../../styles/svg';

export const AttributeRow = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const AttributeValue = styled.span`
  ${text_s}
  flex: 1 1 auto;
  margin: 0;
`;

export const AttributeIcon = styled.span`
  ${svg(24, 24)}
  width: 20px;
  flex: none;
  margin: 0px 8px 0 0px;

  ${mq('md')} {
    margin: 2px 8px 0 0px;
  }

  path {
    fill: ${({ theme }) => theme.colors.element.white};
    ${iconColorOnPrint}
  }
`;
