import React from 'react';
import {withSvg} from "../Svg";
import PropTypes from 'prop-types'

const SvgWorkBag = (props) => (
        <path d="M15.8334 5.41634H13.3334V4.58301C13.3334 3.91997 13.07 3.28408 12.6012 2.81524C12.1323 2.3464 11.4965 2.08301 10.8334 2.08301H9.16675C8.50371 2.08301 7.86782 2.3464 7.39898 2.81524C6.93014 3.28408 6.66675 3.91997 6.66675 4.58301V5.41634H4.16675C3.50371 5.41634 2.86782 5.67973 2.39898 6.14857C1.93014 6.61741 1.66675 7.2533 1.66675 7.91634V15.4163C1.66675 16.0794 1.93014 16.7153 2.39898 17.1841C2.86782 17.6529 3.50371 17.9163 4.16675 17.9163H15.8334C16.4965 17.9163 17.1323 17.6529 17.6012 17.1841C18.07 16.7153 18.3334 16.0794 18.3334 15.4163V7.91634C18.3334 7.2533 18.07 6.61741 17.6012 6.14857C17.1323 5.67973 16.4965 5.41634 15.8334 5.41634ZM8.33342 4.58301C8.33342 4.36199 8.42121 4.15003 8.57749 3.99375C8.73377 3.83747 8.94573 3.74967 9.16675 3.74967H10.8334C11.0544 3.74967 11.2664 3.83747 11.4227 3.99375C11.579 4.15003 11.6667 4.36199 11.6667 4.58301V5.41634H8.33342V4.58301ZM16.6667 15.4163C16.6667 15.6374 16.579 15.8493 16.4227 16.0056C16.2664 16.1619 16.0544 16.2497 15.8334 16.2497H4.16675C3.94573 16.2497 3.73377 16.1619 3.57749 16.0056C3.42121 15.8493 3.33341 15.6374 3.33341 15.4163V10.833C5.4553 11.6748 7.71732 12.1074 10.0001 12.108C12.2828 12.1066 14.5446 11.674 16.6667 10.833V15.4163ZM16.6667 9.00801C14.5654 9.93115 12.2953 10.4078 10.0001 10.4078C7.70491 10.4078 5.43475 9.93115 3.33341 9.00801V7.91634C3.33341 7.69533 3.42121 7.48337 3.57749 7.32709C3.73377 7.17081 3.94573 7.08301 4.16675 7.08301H15.8334C16.0544 7.08301 16.2664 7.17081 16.4227 7.32709C16.579 7.48337 16.6667 7.69533 16.6667 7.91634V9.00801Z"
              fill={props.color || "#4E5A5F"}/>
);

SvgWorkBag.prototype = {
    color: PropTypes.string
}

export default withSvg(20, 20)(SvgWorkBag);