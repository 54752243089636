import moment from 'moment-mini';

const DATE_UNDEFINED = '00010101T000000'; // also default date

export const dateIsDefined = (dateStr) => {
  if (!dateStr || dateStr === DATE_UNDEFINED) {
    return false;
  }

  const date = moment.parseZone(dateStr, 'YYYYMMDDTHHmmss', true);

  if (!date.isValid()) {
    return false;
  }

  return date.format('YYYYMMDDTHH:mm') !== '0001010100:00';
};
