/**
 * Helper to get Time estimate for service content type
 *
 */

import handleSpecificity from '../handleSpecificity';

export const plurise = (word: string, value: string | number) =>
  Number(value) === 1 ? word : `${word}s`;

export default (
  specificity: string = '',
  units: string = '',
  estimate: string = '',
  description: string = ''
) => {
  // Need this field to show data
  if (estimate === '') {
    // still return description if valid
    if (description !== '') {
      return `${description.charAt(0).toUpperCase()}${description.slice(1)}`;
    }

    return null;
  }

  // estimate needs units and specificity
  if (estimate !== '' && (units === '' || specificity === '')) {
    // still return description if valid
    if (description !== '') {
      return `${description.charAt(0).toUpperCase()}${description.slice(1)}`;
    }

    return null;
  }

  const parts = [];

  parts.push(handleSpecificity(specificity));
  parts.push(estimate);
  parts.push(plurise(units, estimate).toLowerCase());
  parts.push(description.toLowerCase());

  return parts.filter(Boolean).join(' ');
};
