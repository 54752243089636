import styled from 'styled-components';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import {
    heading_s,
    mq,
    text_s
} from '_utils/styles';
import { spacing } from "_utils/props";
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: unset;
  width: 428px;
  ${mq.lessThan('md')}{
   width: 312px;
  }
  ${mq.lessThan('sm')}{
   width: 240px;
  }
  overflow:hidden;
  margin: 16px 16px 16px 4px;
`;

export const LeftContainer = styled.div`
   width: 100%;
`;

export const RightContainer = styled.div`
   width: 100%;
   margin-left: 4px;
   ${mq.between('sm', 'md')}{
     margin-left: 0px;
   }
`;

export const Figure = styled.figure`
  display: block;
  height: 100%;
  & img{
      border-radius: 50%;
  }
  margin: 0;
  @media print {
    width: 50%;
    height: 50%;
  }
`;

export const Image = styled(SCImage)`
  width: 128px;
  height: 128px;
  ${mq.lessThan('md')}{
    width: 88px;
    height: 88px;
  }
  object-fit: cover;
  font-family: 'object-fit: cover;';
  @media print {
    width: 50%;
    left: 25%;
  }
  border-radius: 4px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${text_s}
  color: #4E5A5F;
  ${({theme}) => spacing({theme, mx: 0, mt: 0, mb: 1})}
  & svg {
    min-width: 24px;
    max-width: 24px;
    ${({theme}) => spacing({theme, mr: 1})}
  }
  
  ${mq.lessThan('md')}{
    align-items: flex-start;
    & svg {
      min-width: 20px;
      max-width: 20px;
    }
  }
`

export const HeaderWrapper = styled.div`
  ${heading_s};
  & svg {
    min-width: 24px;
    max-width: 24px;
    ${({theme}) => spacing({theme, ml: 1})}
    fill: #188838;
    ${mq.lessThan('md')}{
      min-width: 20px;
      max-width: 20px;
    }
  }
`

export const LinkIconBannerContainer = styled(NavLink)`
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${({theme}) => theme.card.action};
  }
  
  &:focus {
    outline: 0;
    border: 0;
  }
  
  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.colors.element.focused};
  }
`;

export const TitleBanner = styled.h1`
  ${heading_s}
`

export const TitleIconWrapper = styled.span`
  white-space: nowrap;
  line-height: 0;
`;

export const ArrowBannerWrapper = styled.span`
  display: inline-block;
  vertical-align: center;
`;