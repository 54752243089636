import { spacing } from '_utils/props';
import styled from 'styled-components';

export const CertificateOrderWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const MainContent = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;

export const ShowMoreContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const ContentSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
`;

export const UnAvailableSection = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4 })};
`;