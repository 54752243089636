import styled from 'styled-components';
import { mq } from '_utils/styles';
import { spacing } from '_utils/props';

// INFO: item styling
export const CardWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })};
  &:first-child {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }
`;
