import styled from 'styled-components';
import { spacing } from '_utils/props';
export const StyledComponentWrapper = styled.div`
  padding-bottom: 50px;
`;

export const StyledNotice = styled.div`
  display: inline-block;
  padding: ${({ theme }) => spacing({ theme, p: 1 })};
  background-color: ${({ theme, noticeLevel = 'info' }) => theme.colors.status[noticeLevel]};
  color: ${({ theme }) => theme.colors.element.primary};
  font-size: 10px;
  line-height: 1.6;

  ul {
    list-style: inside disc;
    padding-left: ${({ theme }) => spacing({ theme, pl: 1 })};
  }

  small {
    font-size: 10px;
  }

  em {
    font-style: italic;
  }
`;

export const StyledComponentHighlight = styled.div`
  border: 3px dashed ${({ theme, noticeLevel = 'info' }) => theme.colors.status[noticeLevel]};
  min-height: 30px;

  ${StyledNotice} + & {
    margin-top: 16px;
  }
`;
