import { range } from 'ramda';

function getItems(pageIndex: number, numPages: number, buffer: number): any[] {
  const maxItems = buffer * 2 + 1;

  // easy case numPages < max
  if (numPages <= maxItems) {
    return range(1, numPages + 1);
  }

  let start: number = pageIndex - buffer;
  let end: number = pageIndex + buffer;

  // keep items within bounds
  if (start < 1) {
    start = 1;
    end = start + (maxItems - 1);
  }

  if (end >= numPages) {
    end = numPages;
    start = end - (maxItems - 1);
  }

  // create items
  let items = range(start, end + 1);

  // also add first/last if they are not in items
  // null used as placeholder for ellipsis indicator
  if (start > 1) {
    if (start > 2) items.unshift(null);
    items.unshift(1);
  }

  if (end < numPages) {
    if (end < numPages - 1) items.push(null);
    items.push(numPages);
  }

  return items;
}

export default function usePagination({
  pageSize,
  pageIndex,
  count,
  buffer
}): { items: number[]; prevEnabled: boolean; nextEnabled: boolean } {
  const numPages = Math.ceil(count / pageSize);
  return {
    prevEnabled: pageIndex > 1,
    items: getItems(pageIndex, numPages, buffer),
    nextEnabled: pageIndex < numPages
  };
}
