import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import { text_m } from '_utils/styles';

const ActiveCss = css`
  color: ${({ theme }) => theme.colors.all.deepBlue};
  font-weight: 700;
  border-bottom: 4px solid;
  border-bottom-color: ${({ theme }) => theme.colors.all.green};
`;

export const FilterItem = styled.p`
  cursor: pointer;
  ${text_m};
  margin: 0;
  ${({ theme }) => spacing({ theme, mr: 3, pb: 2 })};
  transition: all 0.2s ease-in;

  &:hover {
    color: ${({ theme }) => theme.colors.all.greenTint110};
  }

  &.active {
    ${ActiveCss}
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dddee1;
  color: ${({ theme }) => theme.colors.all.deepBlueTint75};
`;
