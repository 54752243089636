import React, { useState, useEffect } from 'react';
import { compose } from 'ramda';
import { withMenuContext, MenuContextProps } from '_containers/MenuContext';
import { useTimer } from '_utils/hooks';
import { useTranslation } from 'react-i18next';
import { withUserDropdownContext, UserDropdownContextProps } from '_containers/UserDropdownContext';

import { Button, Icon, Bar, Label } from './StyledMenuButton';

const TRANSITION_DURATION = 200;

interface MenuButtonProps extends MenuContextProps, UserDropdownContextProps {}

const MenuButton: React.FC<MenuButtonProps> = ({
  menuActive,
  setMenuActive,
  userDropdownActive,
  setUserDropdownActive
}) => {
  const [t] = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const { setTimer, cancelTimer } = useTimer();

  // clean up
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cancelTimer(), []);

  const handleClick = () => {
    if (!enabled) {
      return;
    }

    setMenuActive(!menuActive);

    if (userDropdownActive) {
      setUserDropdownActive(false);
    }

    setEnabled(false);

    setTimer(() => {
      setEnabled(true);
    }, TRANSITION_DURATION + 10);
  };

  const handleFocus = () => {
    if (!enabled) {
      return;
    }
    if (menuActive) {
      setEnabled(false);
      setMenuActive(false);
    }

    setTimer(() => {
      setEnabled(true);
    }, TRANSITION_DURATION + 10);
  };

  return (
    <Button
      // uncomment to debug 'enabled state'
      // style={{ backgroundColor: !enabled ? 'red' : 'transparent' }}
      id="menu-button"
      aria-expanded={menuActive}
      aria-labelledby="menu-button-label"
      onClick={handleClick}
      onFocus={handleFocus}
    >
      <Icon>
        <Bar />
        <Bar />
        <Bar />
      </Icon>
      <Label id="menu-button-label">{menuActive ? t('menu-close-label') : t('menu-label')}</Label>
    </Button>
  );
};

export default compose(withMenuContext, withUserDropdownContext)(MenuButton);
