import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Radio } from '_utils/components/Corporate';
import LgaAddress from './LgaAddress';
import TipBox from './TipBox';
import SvgAssessmentNumberExample from '_utils/icons/RatesCalculator/AssessmentNumberExample';
import SvgRefresh from '_utils/icons/common/Refresh';
import SvgWarning from '_utils/icons/announcement/ExclamationTriangle';
import {
  AddressContainer,
  AddressContainerHeader,
  AddressValue,
  AddressValueContainer,
  CalculateButton,
  CalculateButtonContainer,
  ClearButton,
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
  InputLabel,
  OptionLabel,
  OptionsContainer,
  RadioOption,
  RatesInputContainer,
  RefreshIcon,
  SearchContainer,
  SearchInput,
  SearchLabel,
  SearchLabelDescription
} from './StyledRatesInput';

interface IRatesInputProps {
  isCalculationSuccess: boolean;
  isError: boolean;
  calculationData: any;
  handleCalculate?: (bol: boolean) => void;
  handleClear?: () => void;
  onChangeOption: () => void;
  setError: (bol: boolean) => void;
  calculateByPropertyNumber: (propertyRatesId: string) => void;
  calculateByAssessmentNumber: (assessmentNumber: string) => void;
}
interface IRadioOption {
  label: string;
  value: string;
}

const ASSESSMENT_NUMBER = 'assessment-number';
const ADDRESS = 'address';
const INPUT_OPTIONS: Array<IRadioOption> = [
  {
    label: 'rates-calculator-search-by-assessment-number',
    value: ASSESSMENT_NUMBER
  },
  {
    label: 'rates-calculator-search-by-address',
    value: ADDRESS
  }
];

const RatesInput: React.FC<IRatesInputProps> = ({
  calculationData,
  isCalculationSuccess,
  isError,
  handleCalculate,
  handleClear,
  onChangeOption,
  calculateByPropertyNumber,
  calculateByAssessmentNumber,
  setError
}) => {
  const [t] = useTranslation();

  const [optionSelected, setOptionSelected] = useState<string>();
  const [assessmentNumber, setAssessmentNumber] = useState<string>('');
  const [clearAssessmentAddress, setClearAssessmentAddress] = useState<boolean>(false);
  const [calculateValue, setCalculateValue] = useState<string>('');
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [isNotSelectedAdress, setIsNotSelectedAdress] = useState<boolean>(false);

  useEffect(() => {
    setIsCalculating(false);
  }, [isCalculationSuccess]);

  // handle click on calculate button
  const calculate = () => {
    if (calculateValue) {
      setIsCalculating(true);
      if (optionSelected === ADDRESS) {
        setIsNotSelectedAdress(false);
        calculateByPropertyNumber(calculateValue);
      } else if (optionSelected === ASSESSMENT_NUMBER) {
        calculateByAssessmentNumber(calculateValue);
      }
      if (calculationData) {
        handleCalculate(true);
      }
    } else {
      setError(true);
      setIsNotSelectedAdress(true);
    }
  };

  // handle change search by assessment number
  const handleChangeAssessmentNumber = (event) => {
    const value = event.target.value;
    setAssessmentNumber(value);
    setCalculateValue(value);
  };

  // handle change search by address
  const handleChangeAddress = (propertyKey: string) => {
    if (propertyKey) {
      setError(false);
    }
    setCalculateValue(propertyKey);
  };

  // handle change option
  const handleClickRadioBtn = (event) => {
    const value = event.target.value;
    if (value === ASSESSMENT_NUMBER) setAssessmentNumber('');
    setOptionSelected(value);
    onChangeOption();
  };

  const handleClearData = () => {
    setAssessmentNumber('');
    setCalculateValue('');
    setClearAssessmentAddress(true);
    handleClear();
  };

  return (
    <RatesInputContainer>
      <Grid.Row alignItems="stretch" config={{ gutters: ['left', 'right'] }}>
        <Grid.Item config={{ col: { xs: 12, md: 8 }, gutters: ['left', 'right'] }}>
          <InputLabel>{t('rates-calculator-description')}</InputLabel>
          <OptionsContainer>
            {INPUT_OPTIONS.map((option, index) => (
              <RadioOption key={index}>
                <Radio
                  id={'RatesCalculatorInput' + index}
                  name="RatesCalculatorInput"
                  value={option.value}
                  disabled={calculationData ? true : false}
                  checked={optionSelected === option.value}
                  onChange={handleClickRadioBtn}
                >
                  <OptionLabel selected={optionSelected === option.value}>
                    {t(option.label)}
                  </OptionLabel>
                </Radio>
              </RadioOption>
            ))}
          </OptionsContainer>
          {/* search by assessment number */}
          {optionSelected === ASSESSMENT_NUMBER && (
            <SearchContainer>
              <SearchLabel>{t('rates-calculator-enter-number')}</SearchLabel>
              <SearchLabelDescription>
                {t('rates-calculator-enter-number-description')}
              </SearchLabelDescription>
              <SearchInput
                name=""
                type="number"
                className={isError ? 'error' : ''}
                value={assessmentNumber}
                disabled={calculationData ? true : false}
                placeholder={t('rates-calculator-example-placeholder')}
                onChange={handleChangeAssessmentNumber}
              />
            </SearchContainer>
          )}
          {/* search by address */}
          {optionSelected === ADDRESS && (
            <SearchContainer>
              <SearchLabel>{t('rates-calculator-enter-address')}</SearchLabel>
              <LgaAddress
                inputName="address"
                clearValue={clearAssessmentAddress}
                setClearValue={setClearAssessmentAddress}
                isError={isError}
                disabled={calculationData ? true : false}
                callbackFn={handleChangeAddress}
              />
            </SearchContainer>
          )}
          {calculationData && (
            <AddressContainer>
              <AddressContainerHeader>{t('rates-calculator-address')}</AddressContainerHeader>
              <AddressValueContainer>
                <AddressValue>{calculationData?.rates?.property?.address || ''}</AddressValue>
                <ClearButton onClick={() => handleClearData()}>
                  {t('rates-calculator-clear')}
                </ClearButton>
              </AddressValueContainer>
            </AddressContainer>
          )}
          {isError && (
            <ErrorContainer>
              <ErrorIcon>
                <SvgWarning />
              </ErrorIcon>
              <ErrorMessage>
                {optionSelected === ASSESSMENT_NUMBER
                  ? t('rates-calculator-search-by-assessment-number-error')
                  : isNotSelectedAdress
                  ? t('rates-calculator-no-assessment-number')
                  : t('rates-calculator-search-by-address-error')}
              </ErrorMessage>
            </ErrorContainer>
          )}
          <CalculateButtonContainer>
            <CalculateButton onClick={calculate}>
              {calculationData ? (
                <>{t('rates-calculator-calculate')}</>
              ) : (
                <>
                  {isCalculating && (
                    <RefreshIcon>
                      <SvgRefresh />
                    </RefreshIcon>
                  )}
                  {t('rates-calculator-next')}
                </>
              )}
            </CalculateButton>
          </CalculateButtonContainer>
        </Grid.Item>
        <Grid.Item config={{ col: { xs: 12, md: 4 }, gutters: ['left', 'right'] }}>
          <TipBox
            tipDescription={
              optionSelected === ADDRESS
                ? t('rates-calculator-search-by-address-instruction')
                : t('rates-calculator-search-by-assessment-number-instruction')
            }
            isShowExample={optionSelected !== ADDRESS}
            Image={SvgAssessmentNumberExample}
          />
        </Grid.Item>
      </Grid.Row>
    </RatesInputContainer>
  );
};

export default RatesInput;
