import getTypeFromValue from '_utils/helpers/getTypeFromValue';

// note! enum values match keys sent by server
export enum LinkButtonIconType {
  chart = 'chart',
  clipboard = 'clipboard',
  commentExclaim = 'comment-exclaim',
  pin = 'pin'
}

/**
 * @param target either a string representation of a content type or an object containing a content type
 * @param key optional used to retrieve contentType value (if passing an object)
 */
export const getCtaType = (value: string | object, key: string = 'type'): LinkButtonIconType => {
  return getTypeFromValue<LinkButtonIconType>(LinkButtonIconType, value, key);
};
