import React from 'react';

export const SvgRegistration = () => (
  <svg viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.752 7.19087C16.7416 7.09899 16.7215 7.00848 16.692 6.92083V6.83082C16.6439 6.72799 16.5798 6.63346 16.502 6.55079L10.5012 0.550037C10.4185 0.472244 10.324 0.408101 10.2212 0.360014C10.1913 0.355773 10.161 0.355773 10.1312 0.360014C10.0296 0.301748 9.91736 0.264346 9.80112 0.25H3.75037C2.95462 0.25 2.19146 0.56611 1.62878 1.12879C1.0661 1.69147 0.749992 2.45463 0.749992 3.25038V17.2521C0.749992 18.0479 1.0661 18.811 1.62878 19.3737C2.19146 19.9364 2.95462 20.2525 3.75037 20.2525H13.7516C14.5474 20.2525 15.3105 19.9364 15.8732 19.3737C16.4359 18.811 16.752 18.0479 16.752 17.2521V7.25088C16.752 7.25088 16.752 7.25087 16.752 7.19087ZM10.7512 3.66043L13.3416 6.25075H11.7514C11.4861 6.25075 11.2317 6.14538 11.0442 5.95782C10.8566 5.77026 10.7512 5.51587 10.7512 5.25063V3.66043ZM14.7517 17.2521C14.7517 17.5174 14.6464 17.7718 14.4588 17.9593C14.2713 18.1469 14.0169 18.2523 13.7516 18.2523H3.75037C3.48512 18.2523 3.23073 18.1469 3.04317 17.9593C2.85561 17.7718 2.75024 17.5174 2.75024 17.2521V3.25038C2.75024 2.98513 2.85561 2.73074 3.04317 2.54318C3.23073 2.35562 3.48512 2.25025 3.75037 2.25025H8.75099V5.25063C8.75099 6.04637 9.0671 6.80953 9.62978 7.37221C10.1925 7.93489 10.9556 8.251 11.7514 8.251H14.7517V17.2521ZM11.0413 10.5413L7.75087 13.8417L6.46071 12.5415C6.27238 12.3532 6.01695 12.2474 5.75062 12.2474C5.48428 12.2474 5.22886 12.3532 5.04053 12.5415C4.8522 12.7299 4.7464 12.9853 4.7464 13.2516C4.7464 13.518 4.8522 13.7734 5.04053 13.9617L7.04078 15.962C7.13375 16.0557 7.24437 16.1301 7.36624 16.1809C7.48812 16.2317 7.61884 16.2578 7.75087 16.2578C7.8829 16.2578 8.01362 16.2317 8.13549 16.1809C8.25737 16.1301 8.36798 16.0557 8.46096 15.962L12.4615 11.9615C12.6498 11.7731 12.7556 11.5177 12.7556 11.2514C12.7556 10.985 12.6498 10.7296 12.4615 10.5413C12.2731 10.353 12.0177 10.2472 11.7514 10.2472C11.485 10.2472 11.2296 10.353 11.0413 10.5413Z"
      fill="white"
    />
  </svg>
);
