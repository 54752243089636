import React, { useEffect, useState } from 'react';
import {
  GeneratedDate,
  YearTab,
  TabItem,
  Period,
  CalculationItem,
  CalculationName,
  CalculationText,
  CalculationData,
  CalculationAmount,
  PercentageLabelContainer,
  PercentageChangeLabel,
  PercentageChangeContainer,
  PercentageChange,
  Total,
  TotalText,
  TotalAmount,
  IconWrapper,
  Description,
  CalculationContainerList,
  CurrentYearTitle
} from './StyledRateDetailCurrent';
import TipBox from '../../RatesCalculator/RatesInput/TipBox';
import { useTranslation } from 'react-i18next';
import SVGStormWaterCharge from '_utils/icons/PropertyRates/StormWaterCharge';
import SVGBusinessRate from '_utils/icons/PropertyRates/BusinessRate';
import SVGDomesticWaste from '_utils/icons/PropertyRates/DomesticWaste';
import SVGNSWGovernmentLevies from '_utils/icons/PropertyRates/NSWGovernmentLevies';

type RateDetailCurrentProps = {
  dateCalculated: any;
  currentRate: any;
  nextYearRate?: any;
  tips?: any;
  isResidentialHouses?: boolean;
  isShowNextYearRates?: boolean;
};

const PROPERTY_CONFIGS = {
  'general-rate': {
    icon: <SVGBusinessRate />
  },
  'domestic-waste-management': {
    icon: <SVGDomesticWaste />
  },
  'stormwater-management': {
    icon: <SVGStormWaterCharge />
  },
  'nsw-government-levies': {
    icon: <SVGNSWGovernmentLevies />
  }
};

const getBackgroundColor = (type) => {
  if (type.includes('waste')) {
    return '#938071';
  }
  if (type.includes('stormwater')) {
    return '#4060FF';
  }
  if (type.includes('rate')) {
    return '#8747B9';
  }
};

const ratesTabList = [
  {
    tabId: 'current-year',
    tabLabel: 'rates-details-current-year'
  },
  {
    tabId: 'next-year',
    tabLabel: 'rates-details-next-year'
  }
];

const RateDetailCurrent: React.FC<RateDetailCurrentProps> = ({
  dateCalculated,
  currentRate,
  nextYearRate,
  isResidentialHouses,
  tips,
  isShowNextYearRates
}) => {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(ratesTabList[0].tabId);
  const [data, setData] = useState(currentRate);
  const isShowNextYear = Boolean(isShowNextYearRates && nextYearRate);

  useEffect(() => {
    if (activeTab === ratesTabList[0].tabId) {
      setData(currentRate);
    } else if (activeTab === ratesTabList[1].tabId) {
      setData(nextYearRate);
    }
  }, [activeTab]);

  return (
    <>
      {dateCalculated && (
        <GeneratedDate>
          {t('rates-details-rate-calculated')} {dateCalculated}
        </GeneratedDate>
      )}
      {/* tab filter */}
      {isShowNextYear && (
        <YearTab>
          {ratesTabList.map((tab, index) => (
            <TabItem
              key={index}
              onClick={() => setActiveTab(tab.tabId)}
              isActive={activeTab === tab.tabId}
            >
              {t(tab.tabLabel)} {index === 0 && <Period>{`(${currentRate.period})`}</Period>}
            </TabItem>
          ))}
        </YearTab>
      )}

      {/* Current year title */}
      {/*{!isShowNextYear && (*/}
      {/*  <YearTab>*/}
      {/*    <CurrentYearTitle>*/}
      {/*      {t('rates-details-current-year')} <Period>{`(${currentRate.period})`}</Period>*/}
      {/*    </CurrentYearTitle>*/}
      {/*  </YearTab>*/}
      {/*)}*/}

      {/* percentage change */}
      {isShowNextYear && activeTab === ratesTabList[1].tabId && (
        <PercentageLabelContainer>
          <PercentageChangeLabel>{`% ${t('rates-details-change')}`}</PercentageChangeLabel>
        </PercentageLabelContainer>
      )}
      {/* rate items */}
      <CalculationContainerList role="list">
        {data.items.map((item, index) => {
          return (
            <CalculationItem
              key={index}
              role="listitem"
              className={index !== data.items.length - 1 ? 'dash-border' : ''}
            >
              <CalculationName>
                <IconWrapper>{PROPERTY_CONFIGS[item.type].icon}</IconWrapper>
                <CalculationText>{item.description}</CalculationText>
              </CalculationName>
              <CalculationData>
                <CalculationAmount>{item.amount}</CalculationAmount>
                {isShowNextYear && activeTab === ratesTabList[1].tabId && (
                  <>
                    <PercentageChangeLabel>{`% ${t('rates-details-change')}`}</PercentageChangeLabel>
                    <PercentageChangeContainer>
                      <PercentageChange
                        className={
                          item.percentageChange > 0
                            ? 'increase'
                            : item.percentageChange < 0
                              ? 'decrease'
                              : ''
                        }
                      >
                        {`${Math.abs(item.percentageChange).toFixed(2)}%`}
                      </PercentageChange>
                    </PercentageChangeContainer>
                  </>
                )}
              </CalculationData>
            </CalculationItem>
          );
        })}
        {/* total */}
        <Total role="listitem">
          <TotalText>{t('rates-details-total')}</TotalText>
          <CalculationData>
            <TotalAmount>{data.total}</TotalAmount>
            {isShowNextYear && activeTab === ratesTabList[1].tabId && (
              <>
                <PercentageChangeLabel>{`% ${t('rates-details-change')}`}</PercentageChangeLabel>
                <PercentageChangeContainer>
                  <PercentageChange
                    className={
                      data.percentageChange > 0
                        ? 'increase'
                        : data.percentageChange < 0
                          ? 'decrease'
                          : ''
                    }
                  >
                    {`${Math.abs(data.percentageChange).toFixed(2)}%`}
                  </PercentageChange>
                </PercentageChangeContainer>
              </>
            )}
          </CalculationData>
        </Total>
      </CalculationContainerList>
      {/* tip */}
      {isResidentialHouses && tips && (
        <Description>
          <TipBox tipTitle={tips.title} tipDescription={tips.description} />
        </Description>
      )}
    </>
  );
};

export default RateDetailCurrent;
