import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import {
  CardWrapper,
  CardSection,
  DetailSection,
  DetailItem,
  CardTitle,
  CardDescription
} from '../PersonalDetailsCard/StyledPersonalDetailsCard';
import { PersonalInfoContext } from '..';
import Toggle from '_utils/components/CityConnect/Toggle';
import TextMessage from '../../TextMessage';
import withErrorBoundary from '_containers/ErrorBoundary';
import { updateNotificationReminder } from '_services/personalInfoService';
import SvgExclamationTriangle from '_utils/icons/announcement/ExclamationTriangle';
import { getRefreshToken } from '_services/genericService';

type NotificationsRemindersCardProps = {
  datasource: any;
  rendering?: any;
};

const NOTIFICATION_TYPES = {
  email: 'email',
  mobileText: 'mobileText'
};

const NotificationsRemindersCard: React.FC<NotificationsRemindersCardProps> = ({
  datasource,
  rendering
}) => {
  const [t] = useTranslation();
  const {
    error,
    personalInfo,
    setPersonalInfo,
    sitecoreContext,
    history,
    currentPathname
  } = useContext(PersonalInfoContext);
  const { mobile, emailNotification, mobileTextNotification } = personalInfo || {};
  const [isEmailNotification, setIsEmailNotification] = useState<boolean>(false);
  const [isProcessingEmail, setIsProcessingEmail] = useState<boolean>(false);
  const [isMobileTextNotification, setIsMobileTextNotification] = useState<boolean>(false);
  const [isProcessingMobileText, setIsProcessingMobileText] = useState<boolean>(false);

  const refreshToken = useCallback(async () => {
    const token = await getRefreshToken(
      currentPathname,
      sitecoreContext.language,
      history,
      currentPathname
    );

    return token;
  }, [currentPathname, sitecoreContext, history]);

  // Get fields jss value
  const notificationsRemindersTitle = datasource?.notificationsRemindersTitle?.jss?.value;
  const notificationsRemindersDescription =
    datasource?.notificationsRemindersDescription?.jss?.value;

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    setIsEmailNotification(Boolean(emailNotification));
  }, [emailNotification]);

  useEffect(() => {
    setIsMobileTextNotification(Boolean(mobileTextNotification));
  }, [mobileTextNotification]);

  // toggle email
  const handleToggleEmail = async () => {
    setIsEmailNotification(!isEmailNotification);
    setIsProcessingEmail(true);
    updateNotificationReminder(
      {
        personalInfo: {
          emailNotification: !isEmailNotification
        }
      },
      (res) => updateNotificationSuccessfully(res, NOTIFICATION_TYPES.email),
      (res) => setIsProcessingEmail(false),
      {
        headers: {
          requestVerificationToken: await refreshToken()
        }
      }
    );
  };

  // toggle mobile text
  const handleToggleMobileText = async () => {
    if (mobile) {
      setIsMobileTextNotification(!isMobileTextNotification);
      setIsProcessingMobileText(true);
      updateNotificationReminder(
        {
          personalInfo: {
            mobileTextNotification: !isMobileTextNotification
          }
        },
        (res) => updateNotificationSuccessfully(res, NOTIFICATION_TYPES.mobileText),
        (res) => setIsProcessingMobileText(false),
        {
          headers: {
            requestVerificationToken: await refreshToken()
          }
        }
      );
    }
  };

  const updateNotificationSuccessfully = (res: any, type: string) => {
    setPersonalInfo({ ...personalInfo, ...res?.data?.personalInfo });
    if (type === NOTIFICATION_TYPES.email) {
      setIsProcessingEmail(false);
    } else if (type === NOTIFICATION_TYPES.mobileText) {
      setIsProcessingMobileText(false);
    }
  };

  return (
    <CardWrapper>
      {notificationsRemindersTitle && <CardTitle>{notificationsRemindersTitle}</CardTitle>}
      {notificationsRemindersDescription && (
        <CardDescription
          dangerouslySetInnerHTML={{ __html: notificationsRemindersDescription }}
        ></CardDescription>
      )}
      <CardSection>
        <DetailSection>
          {/* INFO: Email Notification */}
          <DetailItem.Wrapper>
            <div className="description-block">
              <DetailItem.Text>{t(`personal-info-email-notification`)}</DetailItem.Text>
              <Toggle
                id={`id_emailNotification`}
                name="emailNotification"
                value={isEmailNotification}
                onChange={handleToggleEmail}
                checked={isEmailNotification}
                isProcessing={isProcessingEmail}
                label={'Email'}
              />
            </div>
          </DetailItem.Wrapper>
          {/* INFO: Mobile Notification */}
          <DetailItem.Wrapper>
            <div className="description-block">
              <DetailItem.Text>{t(`personal-info-mobile-text-notification`)}</DetailItem.Text>
              <DetailItem.ToggleControl>
                <Toggle
                  id={`id_mobileTextNotification`}
                  name="mobileTextNotification"
                  value={isMobileTextNotification}
                  onChange={handleToggleMobileText}
                  checked={isMobileTextNotification}
                  isProcessing={isProcessingMobileText}
                  disabled={!mobile}
                  label={'Mobile'}
                />
              </DetailItem.ToggleControl>
            </div>
            {!mobile && (
              <DetailItem.ErrorMessage>
                <DetailItem.Icon>
                  <SvgExclamationTriangle />
                </DetailItem.Icon>
                {t('personal-info-please-provide-a-mobile-number')}
              </DetailItem.ErrorMessage>
            )}
            <DetailItem.Description>
              {t('personal-info-only-available-on-some-services')}
            </DetailItem.Description>
          </DetailItem.Wrapper>
        </DetailSection>
      </CardSection>
      {/* INFO: Announcement */}
      <CardSection>
        <Placeholder name="jss-personal-info-announcement" rendering={rendering} />
      </CardSection>
    </CardWrapper>
  );
};

const NotificationsRemindersCardFallback: React.FC<NotificationsRemindersCardProps> = ({
  datasource,
  rendering
}) => {
  const [t] = useTranslation();
  const notificationsRemindersTitle = datasource?.notificationsRemindersTitle?.jss?.value;
  const errorMessage = {
    data: {
      datasource: {
        message: { jss: { value: t('dashboard-information-is-currently-unavailable') } }
      }
    }
  };

  return (
    <CardWrapper>
      {notificationsRemindersTitle && <CardTitle>{notificationsRemindersTitle}</CardTitle>}
      <TextMessage className="removeStyle" fields={errorMessage} />
      {/* INFO: Announcement */}
      <CardSection>
        <Placeholder name="jss-personal-info-announcement" rendering={rendering} />
      </CardSection>
    </CardWrapper>
  );
};
export default withErrorBoundary(NotificationsRemindersCard, NotificationsRemindersCardFallback);
