import styled from 'styled-components';
import { spacing } from '_utils/props';
import { color, heading_m, svg, mq } from '_utils/styles';

// INFO: Card Wrapper
export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid ${color('all.deepBlueTint15')};
  border-radius: 8px;
  box-shadow: 0px 3px 12px 0px #00000012;
  cursor: pointer;

  ${mq.lessThan('sm')} {
    height: auto;
  }

  &:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }

  span:focus {
    outline: none;
  }

  &:focus-within,
  &:focus {
    border: 3px solid #4060FF;
  }
`;

export const CardHeader = styled.div`
  ${({ theme }) => spacing({ theme, px: 2, py: 2 })};
  background-color: ${color('all.white')};
  height: calc(100% - 100px);
  border-bottom: 1px solid ${color('all.deepBlueTint15')};

  ${mq('md')} {
    height: calc(100% - 108px);
  }
`;

export const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })}

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, mt: 0, mb: 2 })}
    width: 64px;
    height: 64px;
  }
`;

export const Header = styled.h2`
  /* FIXME wierd extra fn call required
  somehow caused by importing above Container 
  class into _utils/styles/section/index !? */
  color: white;
  ${() => heading_m}
`;

// INFO: Card Content: Reference Number, Metadata and Category Link
export const CardContent = styled.div`
  ${({ theme }) => spacing({ theme, px: 2, py: 2 })};
`;

export const ReferenceNumber = styled.div`
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, mb: 1 })};
`;

export const MetadataWrapper = styled.div`
  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ArrowWrapper = styled.span`
  ${svg(14, 14)}
  width: 16px;
  margin-left: 8px;
  display: inline-block;
  margin-right: 10px;

  path {
    fill: ${({ theme }) => theme.card.action};
  }
`;

export const CategoryLink = styled.span`
  color: ${color('all.deepBlue')};
  word-break: break-word;
  white-space: break-spaces;
  font-size: 20px;

  ${CardWrapper}:hover & {
    text-decoration: underline;
    color: ${color('all.greenTint110')};
  }

  ${mq('md')} {
    font-size: 24px;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }
`;
