import React, { useRef, createRef, useState, useEffect, useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { dataApi } from '@sitecore-jss/sitecore-jss-react';
import { useLocation, useHistory } from 'react-router-dom';

import { usePrevious, useTimer } from '_utils/hooks';
import { withUserDropdownContext } from '_containers/UserDropdownContext';
import { withUserProfileContext } from '_containers/UserProfileContext';
import { enableBodyScroll, disableBodyScroll } from '_utils/helpers';
import { MenuItemProps, MenuItemHandlerProps, UserProfileProps, IUserData } from './definitions';
import UserIcon from '_utils/icons/User';
import NavItem from '_components/Corporate/NavItem';
import config from '../../../temp/config';
import { dataFetcher } from '../../../dataFetcher';

import {
  DropdownContainer,
  UserContainer,
  ProfileInfo,
  UserIconContainer,
  FullName,
  Email,
  LogoutButton,
  List,
  Item,
  Label,
  Link,
  Divider,
  Description,
  LoginOrRegisterContainer,
  DisplayNone
} from './StyledUserProfile';

export const TRANSITION_DURATION = 200;

const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
  ({ item, menuActive, onItemClick, onFocus, onBlur }, ref) => {
    if (!item) {
      return null;
    }

    const label = item.label?.value || '';
    const description = item.description?.value || '';

    // nullify links without urls
    const link = item && item.link && item.link.url ? item.link : null;

    // only create handlers if we have a link
    let handlers: MenuItemHandlerProps = link
      ? {
          tabIndex: menuActive ? 0 : -1,
          'aria-hidden': menuActive ? false : true,
          onClick: onItemClick,
          onFocus: onFocus,
          onBlur: onBlur
        }
      : null;

    return (
      <Item>
        <NavItem {...handlers} ref={ref} link={link} StyledLink={Link}>
          {label && <Label>{label}</Label>}
          {description && <Description>{description}</Description>}
        </NavItem>
      </Item>
    );
  }
);

const MenuItemList = ({
  menuActive,
  onItemClick,
  enabledItemRefs,
  onItemFocus,
  onItemBlur,
  getEnabledItemsIndex,
  menuItems
}) => {
  return menuItems.map((item, index) => {
    let enabledItemsIndex = getEnabledItemsIndex(item);
    let enabledItemProps =
      enabledItemsIndex > -1
        ? {
            ref: enabledItemRefs.current[enabledItemsIndex],
            onFocus: (e) => {
              onItemFocus(e, enabledItemsIndex);
            },
            onBlur: onItemBlur
          }
        : {};
    return (
      <MenuItem
        key={index}
        item={item}
        menuActive={menuActive}
        onItemClick={onItemClick}
        {...enabledItemProps}
      />
    );
  });
};

const UserProfile: React.FC<UserProfileProps> = ({
  userDropdownActive,
  setUserDropdownActive,
  userProfile,
  setUserProfile,
  sitecoreContext,
  fields
}) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const containerRef = useRef();
  const prevUserDropdownActive = usePrevious(userDropdownActive);
  const [focusItemIndex, setFocusItemIndex] = useState(-1);
  const { setTimer, cancelTimer } = useTimer();
  const [transitioning, setTransitioning] = useState(false);
  const [userData, setUserData] = useState<IUserData>({
    userDisplayName: '',
    userFullName: '',
    email: '',
    isAuthenticated: false,
    signInUrl: '',
    signOutUrl: '',
    language: ''
  });
  const [userMenuItems, setUserMenuItems] = useState([]);
  const [currentPathname, setCurrentPathname] = useState<string>(location.pathname);

  useEffect(() => {
    const datasource = fields?.data?.datasource;
    const profileNavigation = datasource?.profileNavigation?.[0] || null;
    if (profileNavigation?.links) {
      setUserMenuItems(profileNavigation.links);
    }
  }, [fields]);

  useEffect(() => {
    // set user data from sitecore context
    setUserData({
      ...userData,
      ...sitecoreContext
    });

    // listen iframe message
    window.addEventListener('message', handleIframeMessages);
    // listen window focus
    window.addEventListener('focus', handleWindowFocus);
    // listen route change
    const routeListener = history.listen((location) => {
      setCurrentPathname(location.pathname);
    });

    return () => {
      // remove listeners
      window.removeEventListener('message', handleIframeMessages);
      window.removeEventListener('focus', handleWindowFocus);
      routeListener();
    };
  }, []);

  // return all menu items that have a valid links
  const getAllEnabledItems: any = useCallback(() => {
    return userMenuItems.filter((item) => item.link && item.link.url);
  }, [userMenuItems]);

  // store array of enabled menu item refs inside another ref
  const enabledItemRefs = useRef(getAllEnabledItems().map(() => createRef()));

  const focusFirstItem = () => {
    if (document.body && document.body.classList.contains('safe-focus-removal')) {
      // dont highlight item if menu was opened with mouse
      return;
    }
    if (enabledItemRefs.current && enabledItemRefs.current.length) {
      let first = enabledItemRefs.current[0];
      if (first && first.current) {
        first.current.focus();
      }
    }
  };

  // focus listener effect:
  // 1. auto focus first menu item when menu opened
  // 2. close menu if focused element is outside of menu
  useEffect(
    () => {
      // const menuButton = document.getElementById('menu-button');
      const container = containerRef.current;

      const focusListener = (e) => {
        let target: HTMLElement = e.target;

        if (target && target.id === 'user-button') {
          return;
        }

        // iterate through parents until we find menu container or no more
        while (target) {
          if (target === container) {
            // found menu container, bail
            return;
          }
          // move up to next parent
          target = target.parentNode as HTMLElement;
        }
        // focus outside, close menu
        setUserDropdownActive(false);
      };

      // add/remove listener when menu opened/closed
      if (userDropdownActive && !prevUserDropdownActive) {
        setTransitioning(true);
        setTimer(() => {
          document.body.addEventListener('focus', focusListener, true);
          setTransitioning(false);
          focusFirstItem();
        }, TRANSITION_DURATION + 10);

        disableBodyScroll(container, {
          reserveScrollBarGap: true
        });
        // without setImmediate effect clean up runs immediately
      } else if (!userDropdownActive && prevUserDropdownActive) {
        document.body.removeEventListener('focus', focusListener, true);
        enableBodyScroll(container);
        setTransitioning(true);

        setTimer(() => {
          setTransitioning(false);
        }, TRANSITION_DURATION + 10);
      }

      // clean up
      return () => {
        cancelTimer();
        enableBodyScroll(container);
        document.body.removeEventListener('focus', focusListener, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDropdownActive]
  );

  const onItemClick = () => {
    setUserDropdownActive(false);
  };

  const onItemFocus = (e, enabledItemsIndex) => {
    if (transitioning) {
      return;
    }
    if (!userDropdownActive) {
      setUserDropdownActive(true);
    }
    setFocusItemIndex(enabledItemsIndex);
  };

  const onItemBlur = (e) => {
    setFocusItemIndex(-1);
  };

  const onKeyDown = (e, focusItemIndex, enabledItemElems) => {
    if (
      !userDropdownActive ||
      focusItemIndex === -1 ||
      !enabledItemElems ||
      !enabledItemElems.length
    ) {
      return;
    }

    let prevIndex = focusItemIndex === 0 ? enabledItemElems.length - 1 : focusItemIndex - 1;
    let nextIndex = focusItemIndex === enabledItemElems.length - 1 ? 0 : focusItemIndex + 1;

    if (e && e.keyCode === 35) {
      // END
      if (enabledItemElems[enabledItemElems.length - 1]) {
        e.preventDefault();
        enabledItemElems[enabledItemElems.length - 1].focus();
      }
    } else if (e && e.keyCode === 36) {
      // HOME
      if (enabledItemElems[0]) {
        e.preventDefault();
        enabledItemElems[0].focus();
      }
    } else if (e && e.keyCode === 38) {
      // UP
      if (enabledItemElems[prevIndex]) {
        e.preventDefault();
        enabledItemElems[prevIndex].focus();
      }
    } else if (e && e.keyCode === 40) {
      // DOWN
      if (enabledItemElems[nextIndex]) {
        e.preventDefault();
        enabledItemElems[nextIndex].focus();
      }
    }
  };

  const getEnabledItemsIndex = (item) => {
    return getAllEnabledItems().indexOf(item);
  };

  // handle log out
  const handleLogout = () => {
    if (userData.isAuthenticated) {
      var logoutIframe = document.getElementById('logout-iframe') as HTMLIFrameElement;
      logoutIframe.src = decodeURI(userData.signOutUrl);
      setUserDropdownActive(false);
    }
  };

  // handle receive iframe message
  const handleIframeMessages = (e) => {
    const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
    if (data && data.hasOwnProperty('isAuthenticated')) {
      updateSitecoreContextData();
    }
  };

  // handle window focus
  const handleWindowFocus = () => {
    if (!userData.isAuthenticated) {
      var loginIframe = document.getElementById('login-iframe') as HTMLIFrameElement;
      if (loginIframe) {
        loginIframe.src = decodeURI(sitecoreContext.signInUrl);
      }
    }
  };

  const updateSitecoreContextData = async () => {
    // get the route data for the new route
    getRouteData(currentPathname, sitecoreContext.language).then((routeData) => {
      if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
        // set user data
        setUserData({
          ...userData,
          ...routeData.sitecore.context
        });
        // update user profile context
        setUserProfile({
          ...userProfile,
          ...routeData.sitecore.context,
          canTrust: true
        });
        // update menu
        const jssPageHeaderWithLogin = routeData.sitecore.route.placeholders['jss-header'].find(
          (jss) => jss.componentName === 'PageHeaderWithLoginIframe'
        );
        if (jssPageHeaderWithLogin) {
          setUserMenuItems(
            jssPageHeaderWithLogin.fields.data.datasource.profileNavigation[0].links
          );
        }
      }
    });
  };

  if (!fields?.data?.datasource) {
    return null;
  }

  return (
    <DropdownContainer
      className={userDropdownActive ? 'active' : ''}
      ref={containerRef}
      onKeyDown={(e) => {
        const enabledItemElems = enabledItemRefs.current
          ? enabledItemRefs.current.map(({ current }) => current)
          : [];
        onKeyDown(e, focusItemIndex, enabledItemElems);
      }}
    >
      {(userProfile.canTrust ? (
        userProfile.isAuthenticated
      ) : (
        userData.isAuthenticated
      )) ? (
        <UserContainer>
          <ProfileInfo>
            <UserIconContainer>
              <UserIcon />
            </UserIconContainer>
            <FullName>{userData.userFullName}</FullName>
            <Email>{userData.email}</Email>
            <LogoutButton tabIndex={userDropdownActive ? 0 : -1} onClick={handleLogout}>
              {t('page-header-with-login-logout')}
            </LogoutButton>
            <DisplayNone>
              <iframe id="logout-iframe" src="" />
            </DisplayNone>
          </ProfileInfo>
          {userMenuItems && userMenuItems.length > 0 && (
            <List>
              <Divider />
              <MenuItemList
                menuActive={userDropdownActive}
                onItemClick={onItemClick}
                enabledItemRefs={enabledItemRefs}
                onItemFocus={onItemFocus}
                onItemBlur={onItemBlur}
                getEnabledItemsIndex={getEnabledItemsIndex}
                menuItems={userMenuItems}
              />
            </List>
          )}
        </UserContainer>
      ) : (
        <LoginOrRegisterContainer>
          <iframe
            id="login-iframe"
            src={decodeURI(sitecoreContext.signInUrl)}
            tabIndex={userDropdownActive ? 0 : -1}
          />
        </LoginOrRegisterContainer>
      )}
    </DropdownContainer>
  );
};

export default compose(withUserProfileContext, withUserDropdownContext)(UserProfile);

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
async function getRouteData(route, language) {
  const querystringParams = {
    sc_lang: language,
    sc_apikey: config.sitecoreApiKey
  };
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: querystringParams,
    fetcher: dataFetcher
  };
  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error.response && error.response.status === 404 && error.response.data) {
      return error.response.data;
    }
    console.error('Route data fetch error', error, error.response);
    return null;
  });
}
