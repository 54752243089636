import React, { useState, useEffect, useRef, useContext } from 'react';
import { Search } from '_utils/icons';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { buildSearchUrl } from '_utils/helpers';
import { useTranslation } from 'react-i18next';
import ScreenReader from '_utils/components/Corporate/ScreenReader';
import { PageHeaderWithLoginContext } from '..';
// import style
import {
  SearchContainer,
  FormContainer,
  Form,
  Input,
  Submit,
  SearchIcon,
  Icon,
  CloseButton,
  CloseIcon,
  Bar
} from './StyledMainSearch';

interface MainSearchProps {
  placeholder?: string;
  isLoggedIn: boolean;
}

const MainSearch: React.FC<MainSearchProps> = ({ placeholder = undefined, isLoggedIn = false }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [focused, setFocused] = useState(false);
  const { isAbsoluteSearch, setIsAbsoluteSearch } = useContext(PageHeaderWithLoginContext);
  const { search, pathname } = location || {};
  const searchParams = new URLSearchParams(search);
  const [term, setTerm] = useState(searchParams.get('term') || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const searchIconRef = useRef<HTMLElement>(null);

  if (!placeholder) {
    placeholder = t('search-placeholder-text');
  }

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    history.push(buildSearchUrl({ term, page: 1 }));
  };

  useEffect(() => {
    if (pathname !== buildSearchUrl({})) {
      setTerm('');
    }
  }, [pathname]);

  useEffect(() => {
    if (isAbsoluteSearch) {
      // trigger search input focus
      inputRef.current.focus();
    } else if (isAbsoluteSearch === false) {
      searchIconRef.current.focus();
    }
  }, [isAbsoluteSearch]);

  return (
    <SearchContainer
      className={isAbsoluteSearch ? 'absolute' : isAbsoluteSearch === undefined ? '' : 'static'}
    >
      <FormContainer>
        <Form
          role="search"
          className={focused ? 'search--focused' : ''}
          onSubmit={onSubmit}
          isLoggedIn={isLoggedIn}
        >
          <Input
            type="search"
            placeholder={placeholder}
            onChange={(e) => setTerm(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={(e) => {
              if (e.keyCode === 27 && isAbsoluteSearch) {
                setIsAbsoluteSearch(false);
              }
            }}
            aria-label="Search"
            value={term}
            ref={inputRef}
          />
          <Submit type="submit">
            <ScreenReader>{t('search-accessible-search-button-label')}</ScreenReader>
            <Icon>
              <Search ariaHidden={true} />
            </Icon>
          </Submit>
        </Form>
      </FormContainer>
      <CloseButton
        aria-expanded={isAbsoluteSearch}
        tabIndex={isAbsoluteSearch ? 0 : -1}
        onClick={() => setIsAbsoluteSearch(false)}
        aria-label="Close"
      >
        <CloseIcon>
          <Bar />
          <Bar />
          <Bar />
        </CloseIcon>
      </CloseButton>
      <SearchIcon
        aria-hidden={isAbsoluteSearch}
        isLoggedIn={isLoggedIn}
        onClick={() => setIsAbsoluteSearch(true)}
        aria-label="Close"
        ref={searchIconRef}
      >
        <ScreenReader>{t('search-accessible-search-button-label')}</ScreenReader>
        <Icon>
          <Search ariaHidden={true} />
        </Icon>
      </SearchIcon>
    </SearchContainer>
  );
};

export default MainSearch;
