import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavItem from '_components/Corporate/NavItem';
import {
  ProfileCardWrapper,
  Name,
  Description,
  InformationWrapper,
  InformationIcon,
  InformationText,
  InformationLink,
  InformationWebsite,
  CopyEmailAddress,
  SingleItem,
  ShowMoreProfileCardLink,
  Icon
} from './StyledProfileCard';
import SvgPin from '_utils/icons/Pin';
import SvgPhone from '_utils/icons/Phone';
import SvgMail from '_utils/icons/Mail';
import SvgExternal from '_utils/icons/RequestTracker/External';
import SvgArrowDown from '_utils/icons/ArrowDown';

type ProfileCardProps = {
  data: any;
  multipleProfiles: boolean;
};

const ProfileCard: React.FC<ProfileCardProps> = ({ data, multipleProfiles }) => {
  const [t] = useTranslation();
  const profile = data;
  const [isShowMoreProfileCard, setIsShowMoreProfileCard] = useState(!multipleProfiles);
  const returnAddress = (addressData) => {
    return `${addressData.addressLine1 ? `${addressData.addressLine1}, ` : ''}
      ${addressData.addressLine2 ? `${addressData.addressLine2}, ` : ''}
      ${addressData.suburb ? `${addressData.suburb}, ` : ''}
      ${addressData.postCode}`;
  };

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email);
  };

  const renderProfileDetails = () => {
    return (
      <SingleItem>
        <Name>{profile.heading}</Name>
        <Description dangerouslySetInnerHTML={{ __html: profile.description }}></Description>
        {!isShowMoreProfileCard && (
          <ShowMoreProfileCardLink
            onClick={() => {
              setIsShowMoreProfileCard(!isShowMoreProfileCard);
            }}
          >
            {t('request-tracker-show-details')}
            <Icon>
              <SvgArrowDown />
            </Icon>
          </ShowMoreProfileCardLink>
        )}
        {isShowMoreProfileCard && (
          <>
            {/* Address */}
            {(profile.addressLine1 ||
              profile.addressLine2 ||
              profile.suburb ||
              profile.postCode) && (
              <InformationWrapper>
                <InformationIcon>
                  <SvgPin />
                </InformationIcon>
                <InformationText>{returnAddress(profile)}</InformationText>
              </InformationWrapper>
            )}
            {/* Phone Number */}
            {profile.phone && (
              <InformationWrapper>
                <InformationIcon>
                  <SvgPhone />
                </InformationIcon>
                <InformationLink href={`tel:${profile.phone}`}>{profile.phone}</InformationLink>
              </InformationWrapper>
            )}
            {/* Email */}
            {profile.email && (
              <>
                <InformationWrapper>
                  <InformationIcon>
                    <SvgMail />
                  </InformationIcon>
                  <InformationLink href={`mailto:${profile.email}`}>
                    {profile.email}
                  </InformationLink>
                  <CopyEmailAddress
                    onClick={() => {
                      copyToClipboard(profile.email);
                    }}
                  >
                    {t('request-tracker-copy-email-address')}
                  </CopyEmailAddress>
                </InformationWrapper>
              </>
            )}
            {/* Website */}
            {profile.website?.text && (
              <InformationWrapper>
                <InformationIcon>
                  <SvgExternal />
                </InformationIcon>
                <NavItem link={profile.website} StyledLink={InformationWebsite}>
                  {profile.website?.text}
                </NavItem>
              </InformationWrapper>
            )}
          </>
        )}
      </SingleItem>
    );
  };

  return <ProfileCardWrapper>{renderProfileDetails()}</ProfileCardWrapper>;
};

export default ProfileCard;
