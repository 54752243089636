export default function keyByValue(object, value) {
  if (!object) {
    return undefined;
  }

  const keys = Object.keys(object);

  for (let i = 0; i < keys.length; i++) {
    if (object[keys[i]] === value) {
      return keys[i];
    }
  }

  return undefined;
}
