import React from 'react';
import { withSvg } from '../Svg';

const SvgTotalOutstanding = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33329 10.8333H2.49996C2.27895 10.8333 2.06698 10.9211 1.9107 11.0774C1.75442 11.2337 1.66663 11.4457 1.66663 11.6667V17.5C1.66663 17.721 1.75442 17.933 1.9107 18.0893C2.06698 18.2455 2.27895 18.3333 2.49996 18.3333H8.33329C8.55431 18.3333 8.76627 18.2455 8.92255 18.0893C9.07883 17.933 9.16663 17.721 9.16663 17.5V11.6667C9.16663 11.4457 9.07883 11.2337 8.92255 11.0774C8.76627 10.9211 8.55431 10.8333 8.33329 10.8333ZM7.49996 16.6667H3.33329V12.5H7.49996V16.6667ZM17.5 1.66666H11.6666C11.4456 1.66666 11.2337 1.75446 11.0774 1.91074C10.9211 2.06702 10.8333 2.27898 10.8333 2.5V8.33333C10.8333 8.55434 10.9211 8.76631 11.0774 8.92259C11.2337 9.07887 11.4456 9.16666 11.6666 9.16666H17.5C17.721 9.16666 17.9329 9.07887 18.0892 8.92259C18.2455 8.76631 18.3333 8.55434 18.3333 8.33333V2.5C18.3333 2.27898 18.2455 2.06702 18.0892 1.91074C17.9329 1.75446 17.721 1.66666 17.5 1.66666V1.66666ZM16.6666 7.5H12.5V3.33333H16.6666V7.5ZM17.5 10.8333H11.6666C11.4456 10.8333 11.2337 10.9211 11.0774 11.0774C10.9211 11.2337 10.8333 11.4457 10.8333 11.6667V17.5C10.8333 17.721 10.9211 17.933 11.0774 18.0893C11.2337 18.2455 11.4456 18.3333 11.6666 18.3333H17.5C17.721 18.3333 17.9329 18.2455 18.0892 18.0893C18.2455 17.933 18.3333 17.721 18.3333 17.5V11.6667C18.3333 11.4457 18.2455 11.2337 18.0892 11.0774C17.9329 10.9211 17.721 10.8333 17.5 10.8333ZM16.6666 16.6667H12.5V12.5H16.6666V16.6667ZM8.33329 1.66666H2.49996C2.27895 1.66666 2.06698 1.75446 1.9107 1.91074C1.75442 2.06702 1.66663 2.27898 1.66663 2.5V8.33333C1.66663 8.55434 1.75442 8.76631 1.9107 8.92259C2.06698 9.07887 2.27895 9.16666 2.49996 9.16666H8.33329C8.55431 9.16666 8.76627 9.07887 8.92255 8.92259C9.07883 8.76631 9.16663 8.55434 9.16663 8.33333V2.5C9.16663 2.27898 9.07883 2.06702 8.92255 1.91074C8.76627 1.75446 8.55431 1.66666 8.33329 1.66666V1.66666ZM7.49996 7.5H3.33329V3.33333H7.49996V7.5Z"
      fill="#4E5A5F"
    />
  </svg>
);

export default withSvg(20, 20)(SvgTotalOutstanding);
