import styled from 'styled-components';
import { spacing } from '_utils/props';
import { color, mq, text_m, text_s } from '_utils/styles';

const NUMBER_ITEMS_LARGE_VIEWPORT = 8;
const NUMBER_ITEMS_SMALL_VIEWPORT = 4;
const ITEMS_HEIGHT_LARGE_VIEWPORT = 44;
const ITEMS_HEIGHT_SMALL_VIEWPORT = 40;

// INFO: Detail Items
const Wrapper = styled.div`
  &:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 3 })};
  }
  .description-block {
    display: flex;
    align-items: center;
  }
  .editable-block {
    display: flex;
    flex-direction: column;
    margin-top: -8px;

    &.postal-address {
      ${({ theme }) => spacing({ theme, mt: 2 })};
    }
  }
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  flex: 1;
  ${({ theme }) => spacing({ theme, mb: 1 })};
  display: flex;
  align-items: center;

  ${mq('md')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const HelpText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${color('all.deepBlueTint75')};

  ${mq('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Description = styled.div`
  flex: 1;
  ${text_m};
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, my: 0 })};
`;

const EditButton = styled.a`
  flex: 0 0 30px;
  cursor: pointer;
  text-decoration: underline;
  ${text_s};
  ${({ theme }) => spacing({ theme, ml: 3, my: 0, mr: 0 })};

  &:-webkit-any-link {
    color: inherit;
  }

  &:hover {
    color: ${color('all.greenTint110')};
  }
`;

const TextField = styled.textarea`
  vertical-align: top;
  background: #f9f9f9;
  border: 1px solid #dddee1;
  border-radius: 4px;
  padding: 12px 16px;
  height: 96px;
  resize: none;
  width: 100%;
  outline: none;

  &:focus,
  &:active {
    border: 2px solid ${color('all.green')};
  }

  &:disabled {
    background: #f0f1f2;
    border: 1px solid #dddee1;
    color: ${color('all.deepBlueTint45')};
  }

  &.error {
    background: #feedf3;
    color: ${color('all.pottsPointRedTint110')};
  }

  ${mq('md')} {
    height: 48px;
    padding: 10px 16px;
  }
`;

const ButtonGroups = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ToggleControl = styled.div`
  flex: 0 0 45px;

  ${mq('md')} {
    flex: 0 0 60px;
  }
`;

export const DetailItem = {
  Wrapper,
  Text,
  HelpText,
  Description,
  EditButton,
  TextField,
  ButtonGroups,
  ToggleControl
};

export const SearchAddressContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const SearchPanel = styled.div`
  display: ${({ showPanel }) => (showPanel ? 'block' : 'none')};
  position: absolute;
  ${({ theme }) => spacing({ theme, mt: 1, py: 3 })};
  top: calc(100%);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: ${color('all.white')};
  width: 100%;
  z-index: 10;
`;

export const SearchItemContainer = styled.div`
  overflow-y: auto;
  height: auto;
  ${({ theme }) => spacing({ theme, px: 3 })};
  max-height: ${NUMBER_ITEMS_SMALL_VIEWPORT * ITEMS_HEIGHT_SMALL_VIEWPORT}px;

  ${mq('md')} {
    max-height: ${NUMBER_ITEMS_LARGE_VIEWPORT * ITEMS_HEIGHT_LARGE_VIEWPORT}px;
  }
`;

export const SearchItem = styled.div`
  height: ${ITEMS_HEIGHT_SMALL_VIEWPORT}px;
  display: flex;
  align-items: center;

  ${mq('md')} {
    height: ${ITEMS_HEIGHT_LARGE_VIEWPORT}px;
  }

  &:first-child {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }

  > div {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    font-weight: 700;
    font-family: 'Helvetica Now Text', Helvetica, sans-serif;
    cursor: pointer;
  }
`;
