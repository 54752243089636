import React, { createContext, useState, useEffect } from 'react';
import { usePrevious } from '_utils/hooks';
import { Optionalize } from '_utils/types';
import { ChildrenPropTypes } from '_types';

export interface UserDropdownContextProps {
  userDropdownActive: boolean;
  setUserDropdownActive: (active: boolean) => void;
}

const UserDropdownContext = createContext({
  userDropdownActive: false,
  setUserDropdownActive: null
});

function UserDropdownProvider({ children }) {
  // state
  const [userDropdownActive, setUserDropdownActive] = useState<boolean>(false);
  const prevUserDropdownActive = usePrevious(userDropdownActive);

  useEffect(() => {
    if (userDropdownActive && !prevUserDropdownActive) {
      document.body.classList.add('menu-active');
    } else if (!userDropdownActive && prevUserDropdownActive) {
      document.body.classList.remove('menu-active');
    }

    return () => {
      document.body.classList.remove('menu-active');
    };
  }, [userDropdownActive, prevUserDropdownActive]);

  return (
    <UserDropdownContext.Provider
      value={{
        userDropdownActive,
        setUserDropdownActive
      }}
    >
      {children}
    </UserDropdownContext.Provider>
  );
}

UserDropdownProvider.propTypes = {
  children: ChildrenPropTypes
};

export default UserDropdownProvider;

export const UserDropdownConsumer = UserDropdownContext.Consumer;

export function withUserDropdownContext<
  T extends UserDropdownContextProps = UserDropdownContextProps
>(WrappedComponent: React.ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withUserDropdownContext: React.FC<Optionalize<T, UserDropdownContextProps>> = (props) => {
    return (
      <UserDropdownContext.Consumer>
        {(value) => <WrappedComponent {...value} {...(props as T)} />}
      </UserDropdownContext.Consumer>
    );
  };

  withUserDropdownContext.displayName = `withUserDropdownContext(${displayName})`;

  return withUserDropdownContext;
}
