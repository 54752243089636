import { spacing } from '_utils/props';
import { ContentRichText } from '_components/Corporate/ContentRichText';
import { heading_s, text_m } from '_utils/styles';
import styled from 'styled-components';

export const ContainerPayment = styled.div`
  display: flex;
  flex-direction: column;
  background: #F9F9F9;
  border: 1px solid #9095A1;
  border-radius: 10px;
  ${({ theme }) => spacing({ theme, p: 3 })};
`;

export const Header = styled.div`
  ${heading_s};
  color: #4E5A5F;
`;

export const RichTextDescription = styled(ContentRichText)`
  color: #4E5A5F;
  ${text_m};
  ${({ theme }) => spacing({ theme, mb: 0 })};
`;
