enum Unit {
  B = 'Bytes',
  KB = 'Kilobytes',
  MB = 'Megabyte',
  GB = 'Gigabyte'
}

enum Abbreviation {
  B = 'B',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB'
}

type FileSizeData = {
  size: number;
  unit: {
    LongForm: Unit;
    ShortForm: Abbreviation;
  };
};

const defaultConvert = {
  size: 0,
  unit: {
    LongForm: Unit.B,
    ShortForm: Abbreviation.B
  }
};

//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export default (size: string | number, decimals: number = 2): FileSizeData => {
  try {
    const convertedSize: number = typeof size === 'number' ? size : parseInt(size);

    if (convertedSize === 0 || isNaN(convertedSize)) {
      return defaultConvert;
    }

    const k = 1024;

    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ['B', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(convertedSize) / Math.log(k));

    return {
      size: parseFloat((convertedSize / Math.pow(k, i)).toFixed(dm)),
      unit: {
        LongForm: Unit[sizes[i]],
        ShortForm: Abbreviation[sizes[i]]
      }
    };
  } catch {
    return defaultConvert;
  }
};
