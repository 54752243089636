import { spacing } from '_utils/props';
import styled from 'styled-components';

// INFO: Card Wrapper
export const DashboardTabsWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DashboardTabContentWrapper = styled.div`
  ${({ theme }) =>
    spacing({
      theme,
      px: { xs: 0, sm: '16px', md: '24px' }
    })};
  width: 100%;
`;

export const DashboardTabContentContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 6 })};
  min-height: ${(props) => (props.isLoading ? '100vh' : '')};
`;
