import { color, text_m } from '_utils/styles';
import styled from 'styled-components';
import { spacing } from '_utils/props';

export const TextArea = styled.textarea`
  background: #f9f9f9;
  border: 1px solid #dddee1;
  border-radius: 4px;
  padding: 10px 16px;
  resize: none;
  width: 100%;
  outline: none;
  display: block;
  box-sizing: border-box;
  color: ${color('all.deepBlue')};
  overflow-y: ${(props) => (props.isPersonalInfo ? 'hidden' : '')};
  ${text_m};
  ${({ theme }) => spacing({ theme, m: 0 })};

  &:focus,
  &:active {
    border: 2px solid ${color('all.green')};
  }

  &:disabled {
    background: #f0f1f2;
    border: 1px solid #dddee1;
    color: ${color('all.deepBlueTint45')};
  }

  &::placeholder {
    font-style: italic;
    color: ${color('all.deepBlueTint75')};
  }

  &.error {
    background: #feedf3;
    color: ${color('all.pottsPointRedTint110')};
  }
`;
