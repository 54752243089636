import moment from "moment-mini";

export interface TimelineItem {
    period: {
        jss: {
            value: string;
        }
    },
    icon: {
        value: string;
    },
    title: {
        jss: {
            value: string;
        }
    },
    link: {
        jss: {
            value: {
                href: string,
                text: string,
                anchor: string,
                linktype: string,
                class: string,
                title: string,
                target: string,
                querystring: string,
                id: string
            }
        }
    }
}

export interface TimelineProps {
    fields: {
        data: {
            datasource: {
                icon: {
                    value: string;
                },
                status: {
                    jss: {
                        value: string;
                    }
                },
                description: {
                    jss: {
                        value: string;
                    }
                },
                timeline: TimelineItem[]
            }
        }
    },
    sitecoreContext?: any;
}

export const timelineItem = (time: string, title: string) => {
    return {
        period: {
            jss: {
                value: moment(time).format('DD MMM YYYY')
            }
        },
        title: {
            jss: {
                value: title
            }
        },
        link: {
            jss: {
                value: {
                    href: "",
                }
            }
        }
    }
}