import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { spacing } from '_utils/props';
import { linkWithoutVisited, heading_xxl, mq, text_xs } from '_utils/styles';

//INFO: style for link
export const CategoryLinkContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
`;

export const CategoryLink = styled(Link)`
  ${({ theme }) => linkWithoutVisited({ theme })}
`;

//INFO: style for header & description
export const Header = styled.h1`
  /* FIXME wierd extra fn call required
  somehow caused by importing above Container 
  class into _utils/styles/section/index !? */
  ${() => heading_xxl}
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
`;

const DashboardWelcomeCss = css`
  /* calculate to make welcome text center to the image */
  padding-top: calc((200px - 36px) / 2 - 76px);
  ${mq.between('md', 'lg')} {
    padding-top: calc((180px - 36px) / 2 - 76px);
  }
  ${mq.between('sm', 'md')} {
    padding-top: calc((140px - 28px) / 2 - 48px);
  }
  ${mq.lessThan('sm')} {
    padding-top: 0;
  }
`;

export const Description = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
  & > div > h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

    ${mq('md')} {
      font-size: 32px;
      line-height: 36px;
    }
  }
  ${({ isDashboard }) => (isDashboard ? DashboardWelcomeCss : null)}
`;

// INFO: style for metadata
export const AttributeContainer = styled.div`
  color: white;
  div {
    font-size: 18px;
    ${mq.lessThan('md')} {
      font-size: 16px;
    }
  }
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })};
`;

export const MetadataWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 1 })};
  > div {
    line-height: 24px;
    ${mq('md')} {
      line-height: 28px;
    }
  }
  > div > svg {
    ${mq('md')} {
      width: 24px;
      height: 24px;
      margin-top: 0;
    }
  }
`;

// INFO: text and richtext style
export const TextWrapper = styled.div`
  ${text_xs}
  font-weight: 700;
  margin: 0;
  font-family: 'Helvetica Now Micro', 'Helvetica Now Text', Helvetica, sans-serif;
  text-transform: uppercase;
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
`;

export const AccountInfoText = styled.div`
  font-family: Helvetica Now Micro;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;

  &.mb-5 {
    ${({ theme }) => spacing({ theme, mt: 0, mb: 5 })}

    ${mq('sm')} {
      margin-bottom: 0;
    }
  }
`;

export const RichTextWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 3 })}
`;
