import React from 'react';
import {withSvg} from "../Svg";
import PropTypes from 'prop-types'

const SvgZoomOut = (props) => (
    <>
        <path d="M4.9289 10.9959L11.0029 11.0029H12.997L19.071 10.9959C19.203 10.9953 19.3339 11.0209 19.4559 11.0712C19.578 11.1215 19.6889 11.1954 19.7822 11.2887C19.8756 11.3821 19.9495 11.493 19.9998 11.6151C20.0501 11.7371 20.0757 11.8679 20.0751 11.9999C20.0757 12.132 20.0501 12.2628 19.9998 12.3848C19.9495 12.5069 19.8756 12.6178 19.7822 12.7112C19.6889 12.8045 19.578 12.8784 19.4559 12.9287C19.3339 12.979 19.203 13.0046 19.071 13.004L12.997 12.997H11.0029L4.9289 13.004C4.66259 13.004 4.4072 12.8983 4.2189 12.7099C4.03059 12.5216 3.9248 12.2663 3.9248 11.9999C3.9248 11.7336 4.03059 11.4783 4.2189 11.2899C4.4072 11.1016 4.66259 10.9959 4.9289 10.9959Z"
              fill={props.color || "#041C2C"}/>
    </>

);

SvgZoomOut.prototype = {
    color: PropTypes.string
}
export default withSvg(24, 24)(SvgZoomOut);
