import React, { useEffect, useState } from 'react';
import QuestionWithInput from './QuestionWithInput';
import { useTranslation } from 'react-i18next';
import { itemState, orgProps, organisationTypes } from '../definition';
import SvgWarning from '_utils/icons/Profile/Warning';
import DropDown from '_utils/components/CommunityEngagement/DropDown';

import { DetailItem, Label, Optional, Title } from './StyledOrganisation';
import {
  RowContainer,
  SearchAddressContainer,
  SearchItem,
  SearchItemContainer,
  SearchPanel
} from './StyledAddressLookup';
import Checkbox from '_utils/components/Corporate/Checkbox';
import SelectCountry, {
  ICountry
} from '_components/CityConnect/PersonalInfo/AutoCompleteAddress/SelectCountry';
import {
  getListCountry,
  getListAddressByKeyword,
  getAddressDetails
} from '_services/communityEngagementProfileService';
import {
  DEFAULT_COUNTRY,
  SearchResultItem
} from '_components/CityConnect/PersonalInfo/AutoCompleteAddress/definitions';
import ClickAwayListener from '_utils/components/CityConnect/ClickAwayListener';
import TextareaAutoResize from '_utils/components/CityConnect/TextareaAutoResize';

const Organisation: React.FC<orgProps> = ({
  email,
  role,
  organisationName,
  organisationABNorACN,
  organisationType,
  phone,
  isOutsideOfAustralia,
  address,
  addressLine1,
  city,
  zipOrPostalCode,
  stateOrProvince,
  country,
  errMsg,
  handleChange,
  setConsultation
}) => {
  const [t] = useTranslation();
  const [isOutsideAddress, setIsOutsideAddress] = useState<boolean>(isOutsideOfAustralia);
  const [listCountry, setListCountry] = useState([]);
  const [error, setError] = useState(null);
  const [countryInput, setCountryInput] = useState<ICountry>();
  const [addressInput, setAddressInput] = useState(address);
  const [searchResult, setSearchResult] = useState<SearchResultItem[]>([]);
  const [isShowSearchResult, setIsShowSearchResult] = useState<boolean>(false);
  const [isSearchAble, setIsSearchAble] = useState<boolean>(false);
  const isPostalAddress = 'postalAddress';
  const [isOpenManualAddress, setIsOpenManualAddress] = useState<boolean>(isOutsideOfAustralia);
  const [manualAddress, setManualAddress] = useState<string>(
    isOutsideOfAustralia ? addressLine1 : ''
  );
  const [manualSuburbTownCity, setManualSuburbTownCity] = useState<string>(
    isOutsideOfAustralia ? city : ''
  );
  const [manualStateProvinceRegion, setManualStateProvinceRegion] = useState<string>(
    isOutsideOfAustralia ? stateOrProvince : ''
  );
  const [manualZipPostalCode, setManualZipPostalCode] = useState<string>(
    isOutsideOfAustralia ? zipOrPostalCode : ''
  );
  const [isSameAsResidentialAddress, setIsSameAsResidentialAddress] = useState<boolean>(false);
  const [sessionApi, setSessionApi] = useState<string>('');
  const [isRefreshSessionApi, setIsRefreshSessionApi] = useState<boolean>(false);

  useEffect(() => {
    getListCountry(
      (res) => {
        const countries = res?.data.filter(
          (countryItem: ICountry) =>
            countryItem.iso3.toLocaleLowerCase() !== DEFAULT_COUNTRY.iso3.toLocaleLowerCase()
        );
        setListCountry(countries);
        if (isOutsideOfAustralia && country) {
          const existingCountry = countries.find(
            (countryItem: ICountry) => countryItem.name === country
          );
          setCountryInput(existingCountry);
        }
      },
      (res) => setError(res)
    );
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // clear input and make it not editable
  useEffect(() => {
    if (isOutsideAddress) {
      setAddressInput('');
    } else {
      setCountryInput(null);
    }
  }, [isOutsideAddress]);

  // trigger search and remove trigger
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSearchAble) {
        calculateSearchAddress(addressInput);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [addressInput]);

  // reset form manual address
  useEffect(() => {
    if (!isOpenManualAddress) {
      setManualAddress('');
      setManualSuburbTownCity('');
      setManualStateProvinceRegion('');
      setManualZipPostalCode('');
    }
  }, [isOpenManualAddress]);

  //hide error input address input when handle change is outside Australia
  useEffect(() => {
    if (isOutsideAddress) {
      setAddressInput('');
      if (!isOpenManualAddress) {
        setIsOpenManualAddress(true);
      }
    } else {
      setIsOpenManualAddress(false);
    }
  }, [isOutsideAddress]);

  useEffect(() => {
    setSearchResult([]);
    setIsShowSearchResult(false);
  }, [countryInput]);

  // calculate search or do not search
  const calculateSearchAddress = (keyword: string) => {
    if (keyword.trim().length >= 4) {
      setIsShowSearchResult(true);
      getListAddressByKeyword(
        isOutsideAddress ? countryInput.iso3 : DEFAULT_COUNTRY.iso3,
        keyword,
        isRefreshSessionApi ? sessionApi : '',
        searchAddressSuccessfully,
        (res) => setError(res)
      );
    } else {
      setIsShowSearchResult(false);
      setSearchResult([]);
    }
  };

  // search address successfully
  const searchAddressSuccessfully = (res: any) => {
    setIsRefreshSessionApi(true);
    setSearchResult(res?.data?.suggestions);
  };

  // INFO: Handle state change of input
  const handleChangeInputSearch = (e) => {
    const value = e.target.value;
    setAddressInput(value);
  };

  // handle focus on input
  const handleFocus = () => {
    setIsSearchAble(true);
    setIsShowSearchResult(true);
  };

  // hide search result panel after focus out
  const handleBlur = () => {
    setIsSearchAble(false);
    if (isShowSearchResult) {
      setTimeout(() => setIsShowSearchResult(false), 200);
    }
  };

  // Select a address item
  const selectAddressItem = (address: SearchResultItem) => {
    setAddressInput(address?.text);
    setIsShowSearchResult(false);
    getAddressDetails(
      address.key,
      isOutsideAddress ? countryInput.iso3 : DEFAULT_COUNTRY.iso3,
      sessionApi,
      getAddressDetailsSuccess,
      getAddressDetailsFailure
    );
    setIsRefreshSessionApi(false);
  };

  const getAddressDetailsSuccess = (res) => {
    if (res.data !== undefined) {
      setConsultation((prevState: itemState) => {
        return {
          ...prevState,
          addressLine1: res.data?.address_line_1 || '',
          addressLine2: res.data?.address_line_2 || '',
          addressLine3: res.data?.address_line_3 || '',
          suburb: res.data?.locality || '',
          stateOrProvince: res.data?.region || '',
          zipOrPostalCode: res.data?.postal_code || '',
          country: res.data?.country || ''
        };
      });
    }
  };

  const getAddressDetailsFailure = (res) => { };

  const onChangeIsOutsideAu = (isOutside) => {
    setIsOutsideAddress(isOutside);
    setConsultation((prevState: itemState) => {
      return {
        ...prevState,
        outsideOfAustralia: isOutside
      };
    });
  };

  const onChangeSetCountry = (selectedCountry) => {
    setCountryInput(selectedCountry);
    setConsultation((prevState: itemState) => {
      return {
        ...prevState,
        country: selectedCountry.name
      };
    });
    handleChange(selectedCountry.name, 'country', true);
  };

  const handleChangeInput = (value: string, cases: string, isCheck?: boolean) => {
    switch (cases) {
      case 'addressLine1':
        setManualAddress(value);
        handleChange(value, 'addressLine1', true);
        break;
      case 'city':
        setManualSuburbTownCity(value);
        break;
      case 'stateOrProvince':
        setManualStateProvinceRegion(value);
        break;
      case 'zipOrPostalCode':
        setManualZipPostalCode(value);
        handleChange(value, 'zipOrPostalCode', true);
        break;
      default:
        break;
    }
    setConsultation((prevState: any) => {
      return {
        ...prevState,
        [cases]: value
      };
    });
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (isSearchAble) {
        calculateSearchAddress(addressInput);
      }
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [addressInput]);

  return (
    <>
      <QuestionWithInput
        id={'text-bold'}
        title={t('community-engagement-profile-question-email-address')}
        value={email}
        errMsg={errMsg.email}
        type={'email'}
        haveNode={true}
        nodeTitle={t('community-engagement-profile-question-email-note')}
        handleChange={handleChange}
      />
      <QuestionWithInput
        title={t('community-engagement-profile-question-organisation-name')}
        value={organisationName}
        errMsg={errMsg.organisationName}
        type={'organisationName'}
        handleChange={handleChange}
      />
      <QuestionWithInput
        title={t('community-engagement-profile-question-organisation-type')}
        isSelect={true}
      >
        <DropDown
          data={organisationTypes(t)}
          value={organisationType}
          type={'organisationType'}
          handleChange={handleChange}
        />
        {errMsg.organisationType && (
          <p className="error-validation">
            <SvgWarning />
            {errMsg.organisationType}
          </p>
        )}
      </QuestionWithInput>
      <QuestionWithInput
        title={t('community-engagement-profile-question-role')}
        value={role}
        errMsg={errMsg.role}
        type={'role'}
        handleChange={handleChange}
      />
      <QuestionWithInput
        title={t('community-engagement-profile-question-organisation-abn')}
        value={organisationABNorACN}
        type={'organisationABNorACN'}
        handleChange={handleChange}
        isOptional={true}
      />
      <QuestionWithInput
        title={t('community-engagement-profile-question-organisation-number')}
        value={phone}
        type={'phone'}
        handleChange={handleChange}
        isOptional={true}
      />

      <DetailItem.Wrapper>
        <DetailItem.Text>
          <span>{t('community-engagement-profile-question-organisation-address')}</span>
          <Optional>{t('community-engagement-profile-input-optional')}</Optional>
        </DetailItem.Text>
        <div className="editable-block">
          {isOpenManualAddress && !isOutsideAddress ? (
            <></>
          ) : (
            !(isSameAsResidentialAddress && isPostalAddress) && (
              <RowContainer>
                <Checkbox
                  id={'id_isOutsideAddress'}
                  name={'isOutsideAddress'}
                  value={isOutsideAddress}
                  onChange={() => onChangeIsOutsideAu(!isOutsideAddress)}
                  checked={isOutsideAddress}
                  checkboxOrganisationStyle="checkboxOrganisationStyle"
                >
                  <Label>
                    <Title className={isOutsideAddress ? 'selected' : ''}>
                      {t('personal-info-the-address-is-outside-of-australia')}
                    </Title>
                  </Label>
                </Checkbox>
              </RowContainer>
            )
          )}
          {/* INFO: select country */}
          {isOutsideAddress && (
            <RowContainer>
              <QuestionWithInput isSelect={true}>
                <DetailItem.TextFieldLabel>{t('personal-info-country')}</DetailItem.TextFieldLabel>
                <SelectCountry
                  listCountry={listCountry}
                  visibility={isOutsideAddress}
                  country={countryInput}
                  onChangeCountry={(selectedCountry: any) => onChangeSetCountry(selectedCountry)}
                />
                {errMsg.country && (
                  <p className="error-validation">
                    <SvgWarning />
                    {errMsg.country}
                  </p>
                )}
              </QuestionWithInput>
            </RowContainer>
          )}

          {/* INFO: search address */}
          {isOpenManualAddress || isOutsideAddress ? (
            <></>
          ) : (
            <RowContainer>
              <DetailItem.TextFieldLabel>
                {t('personal-info-address-finder')}
              </DetailItem.TextFieldLabel>
              <ClickAwayListener onClickAway={() => setIsShowSearchResult(false)}>
                <SearchAddressContainer>
                  <TextareaAutoResize
                    id={isPostalAddress}
                    value={addressInput}
                    disabled={
                      (isPostalAddress && isSameAsResidentialAddress) ||
                      (isOutsideAddress && !countryInput) ||
                      isOpenManualAddress
                    }
                    onChange={(e) => handleChangeInputSearch(e)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    rows={1}
                  // className={addressError && !isOpenManualAddress ? 'error' : ''}
                  />
                  {searchResult && searchResult.length > 0 && (
                    <SearchPanel showPanel={isShowSearchResult}>
                      <SearchItemContainer>
                        {searchResult.map((address, index) => (
                          <SearchItem
                            key={index}
                            onClick={() => selectAddressItem(address)}
                            title={address?.text}
                          >
                            <div>{address?.text}</div>
                          </SearchItem>
                        ))}
                      </SearchItemContainer>
                    </SearchPanel>
                  )}
                </SearchAddressContainer>
              </ClickAwayListener>
            </RowContainer>
          )}
          {((!isPostalAddress && !isOutsideAddress) ||
            (isPostalAddress && !isSameAsResidentialAddress && !isOutsideAddress)) && (
              <RowContainer>
                <DetailItem.LinkButton
                  name={
                    isOpenManualAddress
                      ? t('personal-info-switch-back-to-address-finder')
                      : t('personal-info-i-cant-find-my-address')
                  }
                  onClick={() => setIsOpenManualAddress(!isOpenManualAddress)}
                >
                  {isOpenManualAddress
                    ? t('personal-info-switch-back-to-address-finder')
                    : t('personal-info-i-cant-find-my-address')}
                </DetailItem.LinkButton>
              </RowContainer>
            )}
          {(!isPostalAddress || (isPostalAddress && !isSameAsResidentialAddress)) &&
            isOpenManualAddress && (
              <>
                {/* INFO: address */}
                <RowContainer>
                  <QuestionWithInput
                    title={t('personal-info-address')}
                    value={manualAddress}
                    type={'addressLine1'}
                    handleChange={handleChangeInput}
                    questionInputTittleStyle={true}
                    errMsg={errMsg.addressLine1}
                  />
                </RowContainer>
                {/* INFO: Suburb or Town or City */}
                <RowContainer>
                  <QuestionWithInput
                    title={t('personal-info-suburb-town-city')}
                    value={manualSuburbTownCity}
                    type={'city'}
                    handleChange={handleChangeInput}
                    questionInputTittleStyle={true}
                    questionInputTitleStyleCapable={true}
                  />
                </RowContainer>
                {/* INFO: State / Province / Region */}
                <RowContainer>
                  <QuestionWithInput
                    title={t('personal-info-state-province-region')}
                    value={manualStateProvinceRegion}
                    type={'stateOrProvince'}
                    handleChange={handleChangeInput}
                    questionInputTittleStyle={true}
                    questionInputTitleStyleCapable={true}
                  />
                </RowContainer>
                {/* INFO: Zip or Postal code */}
                <RowContainer>
                  <QuestionWithInput
                    title={t('personal-info-zip-or-postal-code')}
                    value={manualZipPostalCode}
                    type={'zipOrPostalCode'}
                    handleChange={handleChangeInput}
                    questionInputTittleStyle={true}
                    errMsg={errMsg.zipOrPostalCode}
                  />
                </RowContainer>
              </>
            )}
        </div>
      </DetailItem.Wrapper>
    </>
  );
};

export default Organisation;
