import { get, post } from './genericService';

export function getCommunityEngagementProfiles(success, failed) {
    return get('/api/v1/account/community-engagement-profiles', success, failed)
}

export function updateCommunityEngagementProfile(params, success, failed, config = {}) {
    return post('/api/v1/account/community-engagement-profiles/update', params, success, failed, config)
}

export function getListCountry(success, failed) {
    return get('/api/v1/account/address/countries', success, failed)
}

export function getListAddressByKeyword(countryISO, keyword, sessionApi, success, failed) {
    let urlPath = '/api/v1/account/address/suggestions?';
    if (keyword) urlPath += 'keyword=' + keyword + '&';
    if (sessionApi) urlPath += 'session=' + sessionApi + '&';
    if (countryISO) urlPath += 'countryISO=' + countryISO;
    return get(urlPath, success, failed);
  }

  export function getAddressDetails(
    addressKey,
    countryISO,
    sessionApi,
    success,
    failed
  ) {
    let urlPath = '/api/v1/account/address/details?';
    if (addressKey) urlPath += 'key=' + addressKey + '&';
    if (sessionApi) urlPath += 'session=' + sessionApi + '&';
    if (countryISO) urlPath += 'countryISO=' + countryISO;
    return get(urlPath, success, failed);
  }

  export function getListSuburbByKeyword(keyword, success, failed) {
    const urlPath = `/api/v1/public/suburbs/suggestions?keyword=${keyword}&numberOfRecords=5`;
    return get(urlPath, success, failed);
  }