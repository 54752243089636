import { filter, mergeLeft } from 'ramda';

export const addressClean = (address) => {
  const isValid = (value) =>
    value !== false && value !== undefined && value !== null && value !== '';

  return filter(isValid, address);
};

export const addressMerge = (address1, address2) => {
  if (!address2) {
    return address1;
  }

  if (!address1) {
    return address2;
  }

  // remove empty values in space so they dont override those in place
  return mergeLeft(addressClean(address1), address2);
};
