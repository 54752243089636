import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgInfoOutLine from '_utils/icons/announcement/InfoOutLine';
import {
  TipBoxContainer,
  IconContainer,
  TipTitle,
  InstructionContainer,
  Instruction,
  Example,
  ExampleImageContainer
} from './StyledTipBox';

interface ITipBoxProps {
  tipTitle?: string;
  tipDescription?: string;
  isShowExample?: boolean;
  Image?: React.FC;
}

const TipBox: React.FC<ITipBoxProps> = ({ tipTitle, tipDescription, isShowExample, Image }) => {
  const [t] = useTranslation();

  return tipTitle || tipDescription || Image ? (
    <TipBoxContainer>
      <IconContainer>
        <SvgInfoOutLine />
      </IconContainer>
      {tipTitle && <TipTitle>{tipTitle}</TipTitle>}
      {tipDescription && (
        <InstructionContainer>
          <Instruction
            field={{
              value: tipDescription
            }}
          />
        </InstructionContainer>
      )}
      {isShowExample && (
        <>
          <Example>{t('rates-calculator-example')}</Example>
          {Image && (
            <ExampleImageContainer>
              <Image />
            </ExampleImageContainer>
          )}
        </>
      )}
    </TipBoxContainer>
  ) : null;
};

export default TipBox;
