import React, { useEffect, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import { useLocation } from 'react-router-dom';

import { withEditMode } from '_containers/BaseComponent';
import withErrorBoundary from '_containers/ErrorBoundary';
import BannerContent from './BannerContent';
import { Grid, ListingImage } from '_utils/components/Corporate';
import { getBannerData, getBannerDataById } from '_services/bannerService';
import { IBannerProps, IBannerResponse } from './definitions';
import { Container, ImageWrap } from './StyledBanner';

const DISPLAY_NAME = '{displayName}';
const LAST_UPDATED = '{lastUpdatedOnText}';
const LIST_PAGES_USING_ID = ['service-request', 'registration', 'property'];
const DASHBOARD_PAGE = 'dashboard';

const Banner: React.FC<IBannerProps> = ({ fields }) => {
  let { greeting, message, accountInformation, accountPending, link, pageName } =
    fields?.data?.datasource || {};

  const [bannerData, setBannerData] = useState<IBannerResponse>();
  const [bannerImage, setBannerImage] = useState({ src: '' });
  const [pageTitle, setPageTitle] = useState({ value: '' });
  const [accountInfo, setAccountInfo] = useState({ value: '' });
  const [error, setError] = useState(false);
  const location = useLocation();
  const itemId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    setBannerData(undefined);
    // get Banner data
    if (LIST_PAGES_USING_ID.includes(pageName?.value.toLowerCase())) {
      getBannerDataById(
        pageName?.value,
        itemId,
        (data) => setBannerData(data.data.banner),
        (err) => setError(err)
      );
    } else {
      getBannerData(
        pageName?.value,
        (data) => setBannerData(data.data.banner),
        (err) => setError(err)
      );
    }
  }, [pageName]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    if (pageTitle.value) {
      const titleSiteName = document.title
        .split('-')
        .pop()
        ?.trim();
      document.title = `${pageTitle.value} - ${titleSiteName}`;
    }
  }, [pageTitle.value]);

  useEffect(() => {
    if (fields && bannerData) {
      let selectedAcountInfo = bannerData.pageData?.lastUpdatedOnText
        ? {
            value: accountInformation.value.replace(
              LAST_UPDATED,
              bannerData.pageData.lastUpdatedOnText
            )
          }
        : accountPending;
      setAccountInfo(selectedAcountInfo);

      let selectedTitle = bannerData.pageData?.heading
        ? bannerData.pageData.heading
        : greeting.value.replace(DISPLAY_NAME, bannerData.displayName);
      setPageTitle({ value: selectedTitle });

      let selectedImage = bannerData.pageData?.image
        ? bannerData.pageData.image
        : fields.data.datasource.image?.jss.value;
      setBannerImage(selectedImage);
    }
  }, [bannerData]);

  return (
    <Container>
      <Grid.Container className="Banner-container">
        <Grid.Row alignItems="stretch" config={{ gutters: ['left', 'right'] }}>
          <Grid.Item
            config={{ col: { xs: 12, sm: bannerImage?.src ? 8 : 12 }, gutters: ['left', 'right'] }}
          >
            <BannerContent
              link={link}
              pageTitle={pageTitle}
              message={message}
              subheading={bannerData?.pageData?.subheading}
              metadata={bannerData?.pageData?.metadata}
              accountInfo={accountInfo}
              withImage={Boolean(bannerImage?.src)}
              isDashboard={pageName?.value?.toLowerCase() === DASHBOARD_PAGE.toLowerCase()}
            />
          </Grid.Item>
          {bannerImage?.src && (
            <Grid.Item
              className="hidden-xs"
              config={{ col: { xs: 6, sm: 4 }, gutters: ['left', 'right'] }}
            >
              <ImageWrap imageType="simple">
                <ListingImage image={{ value: bannerImage }} imageType="simple" />
              </ImageWrap>
            </Grid.Item>
          )}
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
};

const BannerFallback: React.FC<IBannerProps> = ({ fields }) => {
  let { greeting, message, link, image } = fields?.data?.datasource || {};

  return (
    <Container>
      <Grid.Container className="Banner-container">
        <Grid.Row alignItems="stretch" config={{ gutters: ['left', 'right'] }}>
          <Grid.Item
            config={{
              col: { xs: 12, sm: image?.jss.value?.src ? 8 : 12 },
              gutters: ['left', 'right']
            }}
          >
            <BannerContent
              link={link}
              pageTitle={{ value: greeting.value.replace(`, ${DISPLAY_NAME}`, '') }}
              message={message}
              subheading=""
              metadata=""
              accountInfo=""
              withImage={false}
              isDashboard={false}
            />
          </Grid.Item>
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
};

export default compose(
  withSitecoreContext(),
  withEditMode
)(withErrorBoundary(Banner, BannerFallback));
