import React from 'react';

export const SvgChevronLeft = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.284984 6.29135L6.28437 0.29559C6.37733 0.201928 6.48792 0.127584 6.60976 0.0768516C6.73161 0.0261189 6.8623 -2.99961e-07 6.9943 -3.05731e-07C7.1263 -3.11501e-07 7.25699 0.0261189 7.37884 0.0768515C7.50069 0.127584 7.61128 0.201928 7.70423 0.29559C7.79795 0.388487 7.87234 0.49901 7.9231 0.620783C7.97386 0.742556 8 0.87317 8 1.00509C8 1.13701 7.97386 1.26762 7.9231 1.38939C7.87234 1.51117 7.79795 1.62169 7.70423 1.71459C5.90388 3.41268 4.14606 5.15666 2.49975 7.00494L7.70423 12.2871C7.89252 12.4753 7.99829 12.7305 7.99829 12.9966C7.99829 13.2627 7.89252 13.5179 7.70423 13.7061C7.51595 13.8943 7.26058 14 6.9943 14C6.86246 14 6.7319 13.974 6.61009 13.9236C6.48828 13.8732 6.3776 13.7993 6.28438 13.7061L0.284984 7.71035C0.193953 7.61531 0.122595 7.50325 0.0750056 7.38058C-0.0250023 7.13729 -0.0250023 6.86441 0.0750056 6.62112C0.122595 6.49846 0.193953 6.38639 0.284984 6.29135Z"
      fill="#041C2C"
    />
  </svg>
);
