import axios from 'axios';
import config from '../temp/config';
import { dataFetcher } from '../dataFetcher';
import { dataApi } from '@sitecore-jss/sitecore-jss-react';

export function get(action, success, failed, config = {}) {
  return axios
    .get(`${action}`, {
      params: {}
      // withCredentials: true
    }, config)
    .then((data) => success(data))
    .catch((error) => failed(error));
}

export function fetchApi(url, config) {
  return axios.get(`${url}`, config);
}

export function post(action, params, success, failed, config = {}) {
  return axios
    .post(`${action}`, { ...params }, config)
    .then((data) => success(data))
    .catch((error) => failed(error));
}

export async function getRouteData(route, language, history, currentPathName) {
  const querystringParams = {
    sc_lang: language,
    sc_apikey: config.sitecoreApiKey
  };
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: querystringParams,
    fetcher: dataFetcher
  };
  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error.response.status === 401) {
      history.push('/401?returnUrl=' + currentPathName);
      return null;
    }
    if (error.response && error.response.status === 404 && error.response.data) {
      return error.response.data;
    }
    console.error('Route data fetch error', error, error.response);
    return null;
  });
}

export async function getRefreshToken(route, language, history, currentPathName) {
  try {
    const routeData = await getRouteData(route, language, history, currentPathName);
    // check if isAuthenticated is false show the login Button
    if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
      return routeData.sitecore.context.requestVerificationToken;
    }
    return '';
  } catch (e) {
    return '';
  }
}
