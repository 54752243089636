import React, { ReactNode, forwardRef } from 'react';

/**
 * Internal Link
 */
export type LinkProps = {
  text: string;
  url: string;
};

type LinkComponentProps = {
  to?: string;
  href?: string;
  as?: string;
  ref?: React.Ref<HTMLElement>;
  className?: string;
  onClick?: (event) => void;
};

export type InternalLinkProps = {
  children: ReactNode;
  StyledLink: React.ComponentType<LinkComponentProps>;
  link: LinkProps;
  className?: string;
};

export type Ref = HTMLElement;

const InternalLink = forwardRef<Ref, InternalLinkProps>(
  ({ children, StyledLink, link, className, ...rest }, ref) => {
    if (!children) {
      return null;
    }

    let props: LinkComponentProps = {};

    if (link) {
      props.to = link.url;
      props.onClick = (event) => {
        // behave like a page refresh by scrolling to top
        const scrollOptions: ScrollToOptions = {
          behavior: 'auto',
          left: 0,
          top: 0
        };

        if (window && window.scrollTo) {
          window.scrollTo(scrollOptions);
        }
      };
    } else {
      props.as = '';
    }

    return (
      <StyledLink className={className} ref={ref} {...props}>
        {children}
      </StyledLink>
    );
  }
);

export default InternalLink;
