import { spacing } from '_utils/props';
import {
  color,
  heading_l,
  heading_m,
  heading_xs,
  mq,
  svg,
  text_m,
  text_s,
  text_xs
} from '_utils/styles';
import styled, { css, keyframes } from 'styled-components';

export const CardTitle = styled.h2`
  ${heading_l}
  margin: 0;
`;

export const CardBody = styled.div`
  .table-header-row {
    ${({ theme }) => spacing({ theme, mt: 2 })};
  }
  .table-content-row {
    ${({ theme }) => spacing({ theme, py: 2 })};
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      border-bottom: 1px dashed #dddee1;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
`;

// INFO: Card Content
const Text = styled.p`
  ${text_s}
  margin: 0;
`;

const Header = styled.p`
  ${text_m}
  margin: 0;
  color: #4e5a5f;
`;

const BalancedHeader = styled.div`
  ${text_m}
  margin: 0;
  color: #4e5a5f;
`;

export const DateDueHeader = styled(Header)``;

export const AmountDueHeader = styled(Header)``;

export const BalanceHeader = styled(BalancedHeader)``;

export const Icon = styled.span`
  ${svg(5, 11)}
  width: 5px;
  flex-shrink: 0;
  vertical-align: super;
`;

export const ColumnLabel = styled.div`
  ${text_s}
  margin: 0;
  color: ${color('all.deepBlueTint75')};
  ${mq('sm')} {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const DateDueContent = styled(Text)``;

export const CardMessage = styled(Text)`
  ${({ theme }) => spacing({ theme, py: 3 })};
  color: ${color('all.deepBlueTint75')};
`;

export const StatusWrapper = styled.div`
  ${mq.between('xs', 'sm')} {
    ${({ theme }) => spacing({ theme, mt: 1 })}
  }

  ${mq.between('md', 'lg')} {
    ${({ theme }) => spacing({ theme, mt: 1 })}
  }
`;

const Content = styled.h3`
  ${heading_m}
`;

export const AmountDueContent = styled(Content)``;
export const BalanceContent = styled(Content)``;
export const DueNow = styled(Content)``;
export const TotalContent = styled(Content)``;
export const ArrearsContent = styled.p`
  ${text_s}
  ${({ theme }) => spacing({ theme, mb: '10px' })}

  ${mq.between('md', 'lg')} {
    ${({ theme }) => spacing({ theme, mt: 0, mb: 0 })}
  }

  ${mq.between('sm', 'md')} {
    ${({ theme }) => spacing({ theme, mb: '12px' })}
  }


  ${mq.lessThan('sm')} {
    ${({ theme }) => spacing({ theme, mt: 0, mb: 0 })}
  }
`;

export const AmountDueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.lessThan('sm')} {
    align-items: flex-end;
  }
`;

export const CardFooter = styled.div`
  border-radius: 8px;
  ${({ theme }) => spacing({ theme, px: 3, py: 2, m: 0, mt: 3 })};
  background-color: ${color('all.deepBlueTint07')};
  border: 1px solid #DDDEE1;
`;

export const PayButton = styled.a`
  display: flex !important;
  align-items: center !important;
  width: fit-content;
  ${heading_xs};
  border: none !important;

  &:focus {
    outline: none;
    background: #005a0b;
  }
  
  &:focus:before {
    left: -4px !important;
    top: -4px !important;
    width: calc(100% + 8px) !important;
    height: calc(100% + 8px) !important;
  }

  ${mq.lessThan('md')} {
    padding: 7px 16px !important;
  }
`;

export const SvgWrapper = styled.span`
  display: inline;
  text-align: center;
  align-items: center;
  ${({ theme }) => spacing({ theme, ml: 1 })};
  svg {
    width: 20px;
    height: 20px;
  }
  path {
    fill: #FFF;
  }
`;

export const CardWrapper = styled.div`
  border-radius: 8px 8px 0 0;
  ${({ theme, isFallback }) => spacing({ theme, px: 3, pt: 3, pb: isFallback ? 3 : 0 })};
`;

export const Container = styled.div`
  width: 100%;
  border: 1px solid #dddee1;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const CardNote = styled.div`
  ${text_xs}
  color: ${color('all.deepBlueTint75')};
  ${({ theme }) => spacing({ theme, m: 0, mt: 3 })};
`;

export const Announcement = styled.div`
  ${({ theme }) => spacing({ theme, m: 0, mt: 2 })};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 102px 5fr 5fr 103px;
  grid-template-areas: 'a b c d';
  column-gap: 24px;

  ${mq.between('md', 'lg')} {
    grid-template-columns: 102px 1fr 1fr;
    grid-template-areas: 'a b c' '. . d';
  }

  ${mq.between('sm', 'md')} {
    grid-template-columns: 88px 1fr 1fr 97px;
    grid-template-areas: 'a b c d';
  }

  ${mq.lessThan('sm')} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'a .' 'b c' '. d';
  }

  ${mq.lessThan('md')} {
    column-gap: 16px;
  }

  &.hidden-xs {
    ${mq.lessThan('sm')} {
      display: none;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:first-child {
    justify-content: flex-start;
  }

  ${mq.between('md', 'lg')} {
    &:last-child {
      grid-column-end: span 3;
    }
  }

  ${mq.lessThan('sm')} {
    &:last-child,
    &:first-child {
      grid-column-start: span 2;
      grid-column-end: span 2;
    }

    &:first-child {
      margin-bottom: 8px;
    }

    &:nth-child(2n) {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;

export const Grid = {
  Row: Row,
  Item: Item
};

export const FooterGridRow = styled.div`
  display: grid;
  grid-template-columns: auto auto 90px;
  grid-template-areas: 'total arrears pay';
  column-gap: 16px;

  ${mq.between('md', 'lg')} {
    grid-template-areas:
      'total total pay'
      'arrears arrears pay';
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${mq.between('sm', 'md')} {
    grid-template-areas: 'total arrears pay';
    grid-template-columns: auto auto 180px;
  }

  ${mq.lessThan('sm')} {
    grid-template-areas:
      'total total pay'
      'arrears arrears pay';
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${mq.lessThan('md')} {
    column-gap: 16px;
    row-gap: 8px;
  }
`;

const FooterGridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:last-child {
    justify-content: flex-end;
  }

  ${mq.lessThan('sm')} {
    &:last-child {
      grid-column-start: 2;
    }
  }

  ${FooterGridRow}.isprocessing & {
    ${mq.between('md', 'lg')} {
      align-items: flex-start;
    }

    ${mq.lessThan('sm')} {
      align-items: flex-start;
    }
  }
`;

export const FooterGridItemTotal = styled(FooterGridItem)`
  grid-area: total;
`;

export const FooterGridItemArrears = styled(FooterGridItem)`
  grid-area: arrears;
`;

export const FooterGridItemPay = styled(FooterGridItem)`
  grid-area: pay;
`;

export const LoadingContent = styled.div`
  ${text_m};
  color: ${color('all.deepBlueTint75')};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const RotateCss = css`
  animation: ${rotate} 2s linear infinite;
`;

export const RefreshWrapper = styled.span`
  margin-left: 8px;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.card.action};
  }
  svg {
    ${RotateCss};
    overflow: visible;
    ${mq('md')} {
      width: 24px;
    }
  }
`;
