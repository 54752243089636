import React from 'react';
import { withSvg } from '../Svg';

const SvgResidentialUnit = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="14.769" width="35.5804" height="44.3077" fill="#FADD21" />
    <rect x="35.5804" y="4.47559" width="28.4196" height="54.6014" fill="#F05F00" />
    <g clipPath="url(#clip0_14315_103582)">
      <path
        d="M8.64769 26.9751C8.59256 29.7288 6.35716 31.9456 3.58044 32.0003V26.9751H8.64769Z"
        fill="#EF6223"
      />
      <path
        d="M9.92578 26.9751C9.98091 29.7288 12.2213 31.9456 14.993 32.0003V26.9751H9.92578Z"
        fill="#EF6223"
      />
      <path
        d="M14.993 26.0604C14.9379 23.3117 12.7025 21.0898 9.92578 21.0352V26.0604H14.993Z"
        fill="#EF6223"
      />
      <path
        d="M3.58044 26.0604C3.63558 23.3117 5.87599 21.0898 8.64769 21.0352V26.0604H3.58044Z"
        fill="#EF6223"
      />
    </g>
    <g clipPath="url(#clip1_14315_103582)">
      <path
        d="M8.64769 45.5669C8.59256 48.433 6.35716 50.7403 3.58044 50.7972V45.5669H8.64769Z"
        fill="#EF6223"
      />
      <path
        d="M9.92578 45.5669C9.98091 48.433 12.2213 50.7403 14.993 50.7972V45.5669H9.92578Z"
        fill="#EF6223"
      />
      <path
        d="M14.993 44.6151C14.9379 41.7542 12.7025 39.4417 9.92578 39.3848V44.6151H14.993Z"
        fill="#EF6223"
      />
      <path
        d="M3.58044 44.6151C3.63558 41.7542 5.87599 39.4417 8.64769 39.3848V44.6151H3.58044Z"
        fill="#EF6223"
      />
    </g>
    <g clipPath="url(#clip2_14315_103582)">
      <path
        d="M25.3315 26.9751C25.2775 29.7288 23.0859 31.9456 20.3636 32.0003V26.9751H25.3315Z"
        fill="#EF6223"
      />
      <path
        d="M26.5846 26.9751C26.6386 29.7288 28.8351 31.9456 31.5525 32.0003V26.9751H26.5846Z"
        fill="#EF6223"
      />
      <path
        d="M31.5525 26.0604C31.4984 23.3117 29.3069 21.0898 26.5846 21.0352V26.0604H31.5525Z"
        fill="#EF6223"
      />
      <path
        d="M20.3636 26.0604C20.4177 23.3117 22.6142 21.0898 25.3315 21.0352V26.0604H20.3636Z"
        fill="#EF6223"
      />
    </g>
    <g clipPath="url(#clip3_14315_103582)">
      <path
        d="M25.3315 45.5669C25.2775 48.433 23.0859 50.7403 20.3636 50.7972V45.5669H25.3315Z"
        fill="#EF6223"
      />
      <path
        d="M26.5846 45.5669C26.6386 48.433 28.8351 50.7403 31.5525 50.7972V45.5669H26.5846Z"
        fill="#EF6223"
      />
      <path
        d="M31.5525 44.6151C31.4984 41.7542 29.3069 39.4417 26.5846 39.3848V44.6151H31.5525Z"
        fill="#EF6223"
      />
      <path
        d="M20.3636 44.6151C20.4177 41.7542 22.6142 39.4417 25.3315 39.3848V44.6151H20.3636Z"
        fill="#EF6223"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.1679 46.0979V59.0769H43.1888V46.0979C43.1888 42.5139 46.0943 39.6084 49.6784 39.6084C53.2624 39.6084 56.1679 42.5139 56.1679 46.0979Z"
      fill="#0850A1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.4056 59.0769H49.6783H40.951V46.1962C40.951 41.322 44.8584 37.3706 49.6783 37.3706C54.4983 37.3706 58.4056 41.322 58.4056 46.1962V59.0769ZM56.0469 46.1962C56.0469 42.6393 53.1956 39.7559 49.6783 39.7559C46.1611 39.7559 43.3098 42.6393 43.3098 46.1962V59.0769H56.0469V46.1962Z"
      fill="#FADD21"
    />
    <mask id="path-21-inside-1_14315_103582" fill="white">
      <ellipse cx="49.6783" cy="19.2447" rx="6.93707" ry="6.93706" />
    </mask>
    <ellipse cx="49.6783" cy="19.2447" rx="6.93707" ry="6.93706" fill="#FADD21" />
    <path
      d="M46.6153 19.2447C46.6153 17.5531 47.9867 16.1817 49.6783 16.1817V36.1817C59.0324 36.1817 66.6153 28.5988 66.6153 19.2447H46.6153ZM49.6783 16.1817C51.3699 16.1817 52.7412 17.5531 52.7412 19.2447H32.7412C32.7412 28.5988 40.3242 36.1817 49.6783 36.1817V16.1817ZM52.7412 19.2447C52.7412 20.9363 51.3699 22.3076 49.6783 22.3076V2.30762C40.3242 2.30762 32.7412 9.89059 32.7412 19.2447H52.7412ZM49.6783 22.3076C47.9867 22.3076 46.6153 20.9363 46.6153 19.2447H66.6153C66.6153 9.89059 59.0324 2.30762 49.6783 2.30762V22.3076Z"
      fill="#FADD21"
      mask="url(#path-21-inside-1_14315_103582)"
    />
    <defs>
      <clipPath id="clip0_14315_103582">
        <rect width="11.4126" height="10.965" fill="white" transform="translate(3.58044 21.0352)" />
      </clipPath>
      <clipPath id="clip1_14315_103582">
        <rect
          width="11.4126"
          height="11.4126"
          fill="white"
          transform="translate(3.58044 39.3848)"
        />
      </clipPath>
      <clipPath id="clip2_14315_103582">
        <rect width="11.1888" height="10.965" fill="white" transform="translate(20.3636 21.0352)" />
      </clipPath>
      <clipPath id="clip3_14315_103582">
        <rect
          width="11.1888"
          height="11.4126"
          fill="white"
          transform="translate(20.3636 39.3848)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default withSvg(64, 64)(SvgResidentialUnit);
